import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";
const resetArray = [
  { name: "generalAnxietyDescribeSymptoms", type: "" },
  { name: "generalAnxietyStartDate", type: "" },
  { name: "generalAnxietyTimePeriod", type: "" },
  { name: "generalAnxietyReasons", type: "" },
  { name: "generalAnxietyFactors", type: "" },
  { name: "generalAnxietyThoughts", type: "" },
  { name: "generalAnxietyWhatTriggered", type: "" },
  { name: "generalAnxietySymptomsCommenced", type: "" },
  { name: "generalAnxietyReoccurrencesOfCondition", type: "" },
  { name: "generalAnxietyHowManyReoccurrences", type: "" },
  { name: "generalAnxietyTreatment", type: [] },
  { name: "generalAnxietyCounsellingWhenStart", type: "" },
  { name: "generalAnxietyCounsellingEndDate", type: "" },
  { name: "generalAnxietyCounsellingTherapyDetails", type: "" },
  { name: "generalAnxietyPsychotherapyWhenStart", type: "" },
  { name: "generalAnxietyPsychotherapyEndDate", type: "" },
  { name: "generalAnxietyPsychotherapyTherapyDetails", type: "" },
  { name: "generalAnxietyMedicationDose", type: "" },
  { name: "generalAnxietyMedicationEndDate", type: "" },
  { name: "generalAnxietyConditionMedication", type: "" },
  { name: "generalAnxietyDosageOften", type: "" },
  { name: "generalAnxietyDutiesRestrictionCondition", type: "" },
  { name: "generalAnxietyWorkRestriction", type: "" },
  { name: "generalAnxietyLifeRestriction", type: "" },
  { name: "generalAnxietyDetails", type: "" },
];
export default function GeneralAnxiety({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  const [value, setValue] = useState(null);

  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label:
              "Anxiety including generalised anxiety, panic or phobic disorder?",
            value: "generalAnxiety",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("generalAnxiety") && (
        <AnswerBox
          label="Describe your symptoms"
          name="generalAnxietyDescribeSymptoms"
          isSubForm
        />
      )}
      {values.generalAnxietyDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="generalAnxietyStartDate"
          isSubForm
        />
      )}
      {values.generalAnxietyStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="generalAnxietyTimePeriod"
          isSubForm
        />
      )}
      {values.generalAnxietyTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause of these details?"
          name="generalAnxietyReasons"
          isSubForm
        />
      )}
      {values.generalAnxietyReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="generalAnxietyFactors"
          isSubForm
        />
      )}
      {(values.generalAnxietyReasons === "false" ||
        values.generalAnxietyFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts , threatened to self harm or engaged in self harm?"
          name="generalAnxietyThoughts"
          isSubForm
        />
      )}
      {values.generalAnxietyThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="generalAnxietyWhatTriggered"
          isSubForm
        />
      )}
      {(values.generalAnxietyThoughts === "false" ||
        values.generalAnxietyWhatTriggered) && (
        <div>
          <AnswerBox
            label="What was the date the symptoms commenced?"
            name="generalAnxietySymptomsCommenced"
            isSubForm
          />
        </div>
      )}
      {values.generalAnxietySymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="generalAnxietyReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.generalAnxietyReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="generalAnxietyHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.generalAnxietyReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="generalAnxietyTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.generalAnxietyTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="generalAnxietyCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="generalAnxietyCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="generalAnxietyCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.generalAnxietyReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="generalAnxietyTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}
      {values.generalAnxietyTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="generalAnxietyPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="generalAnxietyPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="generalAnxietyPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.generalAnxietyReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="generalAnxietyTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.generalAnxietyTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="generalAnxietyMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="generalAnxietyMedicationEndDate"
            isSubForm
          />
          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="generalAnxietyConditionMedication"
            isSubForm
          />
          {values.generalAnxietyConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="generalAnxietyDosageOften"
              isSubForm
            />
          )}
          {(values.generalAnxietyDosageOften ||
            values.generalAnxietyConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="generalAnxietyDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.generalAnxietyDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="generalAnxietyWorkRestriction"
              isSubForm
            />
          )}
          {(values.generalAnxietyDutiesRestrictionCondition === "false" ||
            values.generalAnxietyWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind"
              name="generalAnxietyLifeRestriction"
              isSubForm
            />
          )}
          {values.generalAnxietyLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="generalAnxietyDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
