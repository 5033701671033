import React, { Fragment } from "react";
import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const side = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
  {
    label: "Both",
    value: "both",
  },
];

const RheumatoidArthritisJointsItems = [
  {
    label: "Hands",
    value: "hands",
  },
  { label: "Wrists", value: "wrists" },
  {
    label: "Small joints of the feet",
    value: "smallJointsOfTheFeet",
  },
  {
    label: "Shoulders",
    value: "shoulders",
  },
  {
    label: "Elbows",
    value: "elbows",
  },
  {
    label: "Knees",
    value: "knees",
  },
  {
    label: "Ankles",
    value: "ankles",
  },
];

const resetArray = [
  { name: "RheumatoidArthritisJointsList", type: [] },
  { name: "RheumatoidArthritisWhichSide", type: "" },
  { name: "RheumatoidArthritisHowLongArthritis", type: "" },
  { name: "RheumatoidArthritisTreatment", type: "" },
  { name: "RheumatoidArthritisOngoingSymptoms", type: "" },
];

export default function SubFormTow({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Rheumatoid Arthritis", value: "rheumatoidArthritis" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormThirteen.includes("rheumatoidArthritis") && (
        <CheckBox
          question="Which joints were affected?"
          list={RheumatoidArthritisJointsItems}
          name="RheumatoidArthritisJointsList"
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("rheumatoidArthritis") && (
        <RadioMulti
          question="Which side?"
          name={"RheumatoidArthritisWhichSide"}
          items={side}
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
        values.RheumatoidArthritisWhichSide && (
          <AnswerBox
            label="How long have you had arthritis?"
            name="RheumatoidArthritisHowLongArthritis"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
        values.RheumatoidArthritisHowLongArthritis && (
          <AnswerBox
            label="What is the treatment you are receiving?"
            name="RheumatoidArthritisTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
        values.RheumatoidArthritisTreatment && (
          <AnswerBox
            label="What are your ongoing symptoms?"
            name="RheumatoidArthritisOngoingSymptoms"
            isSubForm
          />
        )}
    </Fragment>
  );
}
