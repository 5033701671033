import React, { useState } from "react";

import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { resetPass } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";

// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// import FormikField from "../formikField/FormikField";
import { TextField } from "formik-material-ui";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    height: "100%",
    marginBottom: 200,
  },
  center: {
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "1rem",
  },
  header: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "2rem",
    textAlign: "center",
  },
}));

let emailValidation = Yup.object().shape({
  email: Yup.string().email(),
});

function ForgotPassword(props) {
  let initialValues = {
    email: "",
  };
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.post("/auth/forget-password-request", {
        email: e.email,
      });

      setOpen(true);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Verification message sent to your Email!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Verification message sent to your Email!",
        });

        setOpen(false);
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Unauthorized, Please check your email",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Unauthorized, Please check your email",
          });

          setOpen(false);
        }, 3000);
      } else if (error.response.status === 422) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized, Please check your email",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "Server error",
            message: "",
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "Server error",
            message: "",
          });
          setOpen(false);
        }, 3000);
      }
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xl={6} lg={6} md={8} sm={10} xs={12}>
        <Card className={classes.padding} variant="outlined">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={emailValidation}
          >
            {({ dirty, isValid, values }) => {
              return (
                <Form>
                  <CardContent>
                    <Typography className={classes.header}>
                      Please enter your email address to get recovery
                      instructions!
                    </Typography>
                    <Field
                      name="email"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Overpass, sans-serif" },
                      }}
                      margin="dense"
                    ></Field>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.button}
                      fullWidth
                      disabled={!dirty || !isValid}
                      type="submit"
                    >
                      Send
                    </Button>
                  </CardActions>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default connect(null, { resetPass })(ForgotPassword);
