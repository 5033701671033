import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "paralysisSymptoms", type: "" },
  { name: "paralysisTreatment", type: "" },
  { name: "paralysisOccur", type: "" },
  { name: "paralysisOccupationRestriction", type: "" },
  { name: "paralysisDetails", type: "" },
];

export default function Paralysis({ values, setFieldValue, setFieldTouched  }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Paralysis", value: "paralysis" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("paralysis") && (
        <AnswerBox
          label="What were your symptoms?"
          name="paralysisSymptoms"
          isSubForm
          />
      )}
      {values.paralysisSymptoms && (
        <AnswerBox
          label="What treatment was provided?"
          name="paralysisTreatment"
          isSubForm
        />
      )}
      {values.paralysisTreatment && (
        <AnswerBox
          label="When did this occur?"
          name="paralysisOccur"
          isSubForm
          />
      )}
      {values.paralysisOccur && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="paralysisOccupationRestriction"
          isSubForm
        />
      )}
      {values.paralysisOccupationRestriction === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="paralysisDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
