import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const items = [
  { label: "Less than 3 months ago", value: "lt3MonthsAgo" },
  { label: "3-12 months ago", value: "3to12Months" },
  { label: "Over 12 months ago", value: "over12Months" },
];
const resetArray = [
  { name: "symFreeEndometriosis", type: "" },
  { name: "whenDiagEndometriosis", type: "" },
  { name: "treatEndometriosis", type: "" },
  { name: "isOngTreatEndometriosis", type: "" },
  { name: "detailsEndometriosis", type: "" },
];
export default function FormEndometriosis({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Endometriosis", value: "endometriosis" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("endometriosis") && (
        <Fragment>
          <RadioMulti
            name="symFreeEndometriosis"
            question="How long have you been symptom free?"
            items={items}
            isSubForm
          />
          {values.symFreeEndometriosis === "lt3MonthsAgo" && (
            <Fragment>
              <AnswerBox
                name="whenDiagEndometriosis"
                label="When were you diagnosed?"
                isSubForm
              />
              <AnswerBox
                name="treatEndometriosis"
                label="What was the treatment that was recommended by a medical professional?"
                isSubForm
              />
              <YesNoQuestion
                question="Is there any ongoing treatment"
                name="isOngTreatEndometriosis"
                isSubForm
              />
              {values.isOngTreatEndometriosis === "true" && (
                <AnswerBox
                  name="detailsEndometriosis"
                  label="Please provide details"
                  isSubForm
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
