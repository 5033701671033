import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const MiscTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container id="formview-misc-tabs" defaultActiveKey="#misc-1">
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {/* Speech, sight and hearing defects */}
              {props.formData.formFiffteen.subFormsOfFormFifteen.map(
                (defect, index) =>
                  defect.substring(0, 6) === "defect" ? (
                    <>
                      <ListGroup.Item action href={`#misc-${index + 1}`}>
                        {defect
                          .replace(/([A-Z]+)/g, " $1")
                          .trim()[0]
                          .toUpperCase() +
                          defect
                            .replace(/([A-Z]+)/g, " $1")
                            .trim()
                            .substring(1)}
                      </ListGroup.Item>
                    </>
                  ) : (
                    <></>
                  )
              )}
              {props.formData.formTwentySix.additionalInfo ? (
                <ListGroup.Item action href={`#misc-additional`}>
                  Additional details
                </ListGroup.Item>
              ) : (
                <></>
              )}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {/* Speech, sight and hearing defects */}
              {props.formData.formFiffteen.subFormsOfFormFifteen.map(
                (defect, index) =>
                  defect.substring(0, 6) === "defect" ? (
                    <Tab.Pane eventKey={`#misc-${index + 1}`}>
                      <Table
                        id="form-view-misc-table"
                        striped
                        bordered
                        responsive
                      >
                        <thead>
                          <tr className="border-0">
                            <th
                              id="question-heading"
                              className="border-0 p-0"
                            ></th>
                            <th
                              id="answer-heading"
                              className="border-0 p-0"
                            ></th>
                          </tr>
                        </thead>
                        {defect === "defectInSpeech" ? (
                          <tbody>
                            <tr>
                              <td>Speech defect details</td>
                              <td>
                                {
                                  props.formData.formFiffteen
                                    .comprehensiveDetailsAboutSpeech
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you restricted in your work or daily
                                activities by this condition?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen.anyRestricted
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen.anyRestricted ===
                            "yes" ? (
                              <tr>
                                <td>Restriction details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .DetailsAboutRestricted
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        ) : defect === "defectInSight" ? (
                          <tbody>
                            <tr>
                              <td>What is your condition?</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formFiffteen.yourCondition
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen.yourCondition.includes(
                              "cataract"
                            ) ? (
                              <>
                                <tr>
                                  <td>
                                    Has a cause for the cataract symptoms been
                                    identified?
                                  </td>
                                  <td>
                                    {arrayToFormattedList(
                                      props.formData.formFiffteen
                                        .cataractSymptomsBeenIdentified
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cataract symptom details</td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .detailsForSyncomsBeenIdentified
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Have you had surgical treatment for
                                    cataracts?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .defectInSighAnySurgicalTreatment
                                    )}
                                  </td>
                                </tr>
                                {props.formData.formFiffteen
                                  .defectInSighAnySurgicalTreatment ===
                                "yes" ? (
                                  <tr>
                                    <td>Surgery details</td>
                                    <td>
                                      {
                                        props.formData.formFiffteen
                                          .detailsForSurgicalTreatment
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                                <tr>
                                  <td>
                                    Is your vision now normal with or without
                                    the use of glasses?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .isVisionNormalWithOutGlasses
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen.yourCondition.includes(
                              "glaucoma"
                            ) ? (
                              <>
                                <tr>
                                  <td>
                                    Has a cause for the glaucoma symptoms been
                                    identified?
                                  </td>
                                  <td>
                                    {arrayToFormattedList(
                                      props.formData.formFiffteen
                                        .glaucomaSymptomsBeenIdentified
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Has your vision deteriorated over the past 2
                                    years?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .visionDeterioratedOverThePast2Years
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen.yourCondition.includes(
                              "other"
                            ) ? (
                              <>
                                <tr>
                                  <td>Were you born with a sight defect?</td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .bornWithASightDefect
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Are you restricted in your work or daily
                                    activities by this condition?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .defectInSighRestricted
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Restriction details</td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .detailsForRestricted
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Which eye is affected?</td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .whichEyeISDeflected
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        ) : defect === "defectInHearing" ? (
                          <tbody>
                            <tr>
                              <td>
                                What is the cause of your hearing problem?
                              </td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formFiffteen.causeOfHearing
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen.causeOfHearing.includes(
                              "tumour"
                            ) ||
                            props.formData.formFiffteen.causeOfHearing.includes(
                              "other"
                            ) ? (
                              <tr>
                                <td>Hearing problem details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .causeOfHearingDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>Indicate the ears affected</td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen.whichSideOFEars
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you restricted in your work or daily
                                activities by this condition?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .defectInHearingAnyRestricted
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen
                              .defectInHearingAnyRestricted === "yes" ? (
                              <tr>
                                <td>Restriction details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .defectInHearingAnyRestrictedDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>Is your hearing deteriorating?</td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .isYourHaringDeteriorating
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </Tab.Pane>
                  ) : (
                    <></>
                  )
              )}
              <Tab.Pane eventKey={`#misc-additional`}>
                <Table id="form-view-misc-table" striped bordered responsive>
                  <thead>
                    <tr className="border-0">
                      <th id="question-heading" className="border-0 p-0"></th>
                      <th id="answer-heading" className="border-0 p-0"></th>
                    </tr>
                  </thead>
                  {props.formData.formTwentySix.additionalInfo ? (
                    <tbody>
                      <tr>
                        <td>Additional information</td>
                        <td>{props.formData.formTwentySix.additionalInfo}</td>
                      </tr>
                    </tbody>
                  ) : (
                    <></>
                  )}
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default MiscTab;
