import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";

import { useStyles } from "./classes";
import { Box, Typography, Button } from "@material-ui/core";
import axios from "../../lib/axios";
import Alert from "@material-ui/lab/Alert";

export default function Payment() {
  const classes = useStyles();
  const [packs, setPacks] = useState([]);

  const fetchData = async () => {
    const pack = await axios.get("/package/package-web");
    setPacks(pack.data);
  };

  const newUser = JSON.parse(localStorage.getItem("newuser"));

  const handleSubmit = async (name, id, price) => {
    if (newUser) {
      const apiSetQrcode = `${process.env.REACT_APP_BASE_URL}/paypal/payment`;
      let value = [
        {
          name: newUser.data.firstName,
          description: name,
          quantity: 1,
          price: price,
          tax: "0.0",
          currency: "AUD",
          sku: id,
        },
      ];

      let details = {
        total: price,
        arNumber: newUser.data.arNumber,
      };
      try {
        //console.log(token);
        const response = await fetch(apiSetQrcode, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            value,
            details,
          }),
        });
        //  await //console.log(response);
        if (response.ok) {
          const json = await response.json();
          window.location.assign(json.link);
          console.log("link", json.link);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      history.push("/signup");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const history = useHistory();

  const loginDirect = () => {
    history.push("/login");
  };
  return (
    <>
      <Typography className={classes.header} id="pricing">
        Pay for Pricing Plan and Continue
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Alert severity="success" style={{ textAlign: "center" }}>
          Almost there! Thanks for registering with Pre Assess Me. We are
          super-excited to have you on board. A verification email was sent
          successfully. Please check your Spam or Junk folder
        </Alert>
      </div>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justifycontent="space-around" style={{ padding: 60 }}>
            <Grid item alignself="center">
              <Paper className={classes.paper}>
                <Box className={classes.box}>
                  <Typography className={classes.text}>Free trial*</Typography>
                  <Typography className={classes.paragraph}>
                    Your First 5 Pre-Assessments are on us!
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    1. Create an Account
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    2. Upload your company logo so your pre-assessments are sent
                    from your company
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    3. Submit your first five clients for pre-assessment to see
                    how easy the process is!
                  </Typography>
                  <Typography className={classes.paragraph2}>
                    *1 free trial per AR code
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        width: "50%",
                        height: 30,
                        fontSize: "1rem",
                        backgroundColor: "#004aad",
                        fontFamily: "Overpass, sans-serif",
                        color: "white",
                      }}
                      onClick={loginDirect}
                    >
                      Continue
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Grid>
            {packs.map((pack) => (
              <Grid item key={pack.id}>
                <Paper className={classes.paper}>
                  <Box className={classes.box}>
                    <Typography className={classes.text}>
                      {pack.name}
                    </Typography>
                    <Typography className={classes.paragraph}>
                      {pack.description}
                    </Typography>
                    <Typography className={classes.paragraph3}>
                      ${pack.price}.00 for {pack.numberOfClients} client
                    </Typography>
                    <Typography className={classes.paragraph4}>
                      pre-assessments
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                      }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          width: "50%",
                          height: 30,
                          fontSize: "1rem",
                          backgroundColor: "#004aad",
                          fontFamily: "Overpass, sans-serif",
                          color: "white",
                        }}
                        onClick={() => {
                          handleSubmit(pack.name, pack.id, pack.price);
                        }}
                      >
                        Checkout
                      </Button>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
