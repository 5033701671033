import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import RadioMulti from "./RadioMulti";
import AnswerBox from "./AnswerBox";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";

export default function FormFive() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    geneticTest: "",
    geneticTestYes: "",
    geneticTestReason: "",
    geneticTestOther: "",
  });
  const validationSchema = Yup.object().shape({
    geneticTest: Yup.string().required(),
    geneticTestYes: Yup.string().when("geneticTest", {
      is: "yes",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    geneticTestReason: Yup.string().when("geneticTest", {
      is: "pending",
      then: Yup.string().required("This field is required"),
    }),
    geneticTestOther: Yup.string().when("geneticTestReason", {
      is: "Other",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
  });
  // FORM [2,2]

  const items = [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
    {
      label: "Considering genetic testing or awaiting results",
      value: "pending",
    },
  ];

  const reasons = [
    {
      label: "Ancestry / family tree testing",
      value: "Ancestry / family tree testing",
    },
    {
      label: "Family History : Cancer",
      value: "Family History : Cancer",
    },
    {
      label: "Family History : Coeliac Disease",
      value: "Family History : Coeliac Disease",
    },
    {
      label: "Family history: Haemochromatosis",
      value: "Family history: Haemochromatosis",
    },
    {
      label: "Family Planning / Pregnancy",
      value: "Family Planning / Pregnancy",
    },
    {
      label: "Haemochromatosis",
      value: "Haemochromatosis",
    },
    {
      label: "IVF - Fertility",
      value: "IVF - Fertility",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formfive", {
        formFive: e,
        pointer: 6,
        formid: id,
      });
      dispatch(setPage(6));
    } catch (error) {}
  };

  const resetFoldYesNo = (e, setFieldValue, setFieldTouched) => {
    switch (e.target.value) {
      case "yes":
        setFieldValue("geneticTestReason", "", false);
        setFieldTouched("geneticTestReason", false);
        break;
      case "no":
        setFieldValue("geneticTestReason", "", false);
        setFieldTouched("geneticTestReason", false);
        setFieldValue("geneticTestYes", "", false);
        setFieldTouched("geneticTestYes", false);
        setFieldValue("geneticTestOther", "", false);
        setFieldTouched("geneticTestOther", false);
        break;
      case "pending":
        setFieldValue("geneticTestYes", "", false);
        setFieldTouched("geneticTestYes", false);
        break;
      default:
    }
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched , resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ marginBottom: "6.5rem", padding: "2rem" }}>
                  <CardContent>
                    <RadioMulti
                      question="Have you ever had a genetic test/are you waiting to receive genetic test results/are you considering having a genetic test."
                      name={"geneticTest"}
                      items={items}
                      onClick={(e) =>
                        resetFoldYesNo(e, setFieldValue, setFieldTouched)
                      }
                    />
                    {values.geneticTest === "yes" && (
                      <AnswerBox
                        label="What were the results"
                        name="geneticTestYes"
                        isSubForm
                      />
                    )}
                    {values.geneticTest === "pending" && (
                      <RadioMulti
                        question="What is the reason for the test?"
                        name={"geneticTestReason"}
                        items={reasons}
                        isSubForm
                      />
                    )}
                    {values.geneticTestReason === "Other" && (
                      <AnswerBox
                        label="Please provide details"
                        name="geneticTestOther"
                        isSubForm
                      />
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
