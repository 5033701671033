import React from "react";
import { Fragment } from "react";

import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const papSmearDiag = [
  {
    label: "CIN 1 (mild abnormal/ low grade cell changes)",
    value: "CIN 1 (mild abnormal/ low grade cell changes)",
  },
  {
    label: "CIN 2 (Moderate abnormal cell changes)",
    value: "CIN 2 (Moderate abnormal cell changes)",
  },
  {
    label: "CIN 3 (Sevre Abnormal/high grade cell changes) precancerous",
    value: "CIN 3 (Sevre Abnormal/high grade cell changes) precancerous",
  },
  { label: "Carcinoma in-situ", value: "carcinomaIn-situ" },
  {
    label: "Cancer (invasive Carcinoma)",
    value: "Cancer (invasive Carcinoma)",
  },
  {
    label: "Human Papilloma Virus (HPV)",
    value: "Human Papilloma Virus (HPV)",
  },
  { label: "Result not known", value: "Result not known" },
];

const papSmearTime = [
  { label: "6 months ago or less", value: "6 months ago or less" },
  { label: "7-12 months ago", value: "7-12 months ago" },
  { label: "2-3 years ago", value: "2-3 years ago" },
  { label: "3-9 years ago", value: "3-9 years ago" },
  { label: "10+ years ago", value: "10+ years ago" },
];

const papSmearFollow = [
  { label: "One", value: "one" },
  { label: "2 or more", value: "2 or more" },
  { label: "None", value: "none" },
  { label: "Not had follow up smears", value: "Not had follow up smears" },
];
const resetArray = [
  { name: "diagAbnormalSmear", type: "" },
  { name: "huwLongAbnormalSmear", type: "" },
  { name: "followupAbnormalSmear", type: "" },
];
export default function FormPapSmear({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Abnormal Cervica Smear (papSmear)", value: "papSmear" },
        ]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("papSmear") && (
        <Fragment>
          <RadioMulti
            question="What was the diagnosis of the abnormal smear?"
            name="diagAbnormalSmear"
            items={papSmearDiag}
            isSubForm
          />
          <RadioMulti
            question="How long ago was your last abnormal smear?"
            name="huwLongAbnormalSmear"
            items={papSmearTime}
            isSubForm
          />
          <RadioMulti
            question="How many normal follow up smears have you had?"
            name="followupAbnormalSmear"
            items={papSmearFollow}
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
