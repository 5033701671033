import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const OrgansTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container id="formview-organs-tabs" defaultActiveKey="#organs-1">
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.FormSeventeen.subFormsOfFormSeventeen.map(
                (illness, index) => (
                  <ListGroup.Item action href={`#organs-${index + 1}`}>
                    {illness
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      illness
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1)}
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.FormSeventeen.subFormsOfFormSeventeen.map(
                (illness, index) => (
                  <Tab.Pane eventKey={`#organs-${index + 1}`}>
                    <Table
                      id="form-view-organs-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {illness === "liverDisorder" ? (
                        <tbody>
                          <tr>
                            <td>What were your symptoms?</td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .liverDisorderSymptoms
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>When did this occur?</td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .liverDisorderWhenThisOccur
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              What was the treatment that was recommended by a
                              medical professional?
                            </td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .liverDisorderWhatTreatments
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Is this still an ongoing condition/do you have
                              ongoing symptoms?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .liverDisorderOngoingSymptoms
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .liverDisorderOngoingSymptoms === "yes" ? (
                            <tr>
                              <td>Ongoing symptoms details</td>
                              <td>
                                {
                                  props.formData.FormSeventeen
                                    .liverDisorderOngoingSymptomsDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "pancreasDisorder" ? (
                        <tbody>
                          <tr>
                            <td>
                              Was your pancreas ever diagnosed as pancreatitis?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen.anyPancreasEver
                              )}
                            </td>
                          </tr>{" "}
                          {props.formData.FormSeventeen.anyPancreasEver ===
                          "no" ? (
                            <>
                              <tr>
                                <td>What were your symptoms?</td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .pancreasDisorderSymptoms
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>When did this occur?</td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .pancreasDisorderWhenThisOccur
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What was the treatment that was recommended by
                                  a medical professional?
                                </td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .pancreasDisorderWhatTreatments
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              Is this still an ongoing condition/do you have
                              ongoing symptoms?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .pancreasDisorderOngoingSymptoms
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .pancreasDisorderOngoingSymptoms === "yes" ? (
                            <tr>
                              <td>Ongoing symptoms details</td>
                              <td>
                                {
                                  props.formData.FormSeventeen
                                    .pancreasDisorderOngoingSymptomsDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "prostateDisorder" ? (
                        <tbody>
                          <tr>
                            <td>
                              What was your prostate disorder diagnosed as?
                            </td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.FormSeventeen
                                  .prostateDisorderDiagnosedAs
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen.prostateDisorderDiagnosedAs.includes(
                            "prostateCancer"
                          ) ? (
                            <>
                              <tr>
                                <td>When was the diagnosis?</td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .prostateDisorderWhenThisOccur
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What was the treatment that was recommended by
                                  a medical professional?
                                </td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .prostateDisorderWhatTreatments
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.FormSeventeen.prostateDisorderDiagnosedAs.includes(
                            "other"
                          ) ? (
                            <tr>
                              <td>Other diagnosis details</td>
                              <td>
                                {props.formData.FormSeventeen.otherDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "kidneyDisorder" ? (
                        <tbody>
                          <tr>
                            <td>What were your symptoms?</td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .kidneyDisorderSymptoms
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>When did this occur?</td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .kidneyDisorderWhenThisOccur
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              What was the treatment that was recommended by a
                              medical professional?
                            </td>
                            <td>
                              {
                                props.formData.FormSeventeen
                                  .kidneyDisorderWhatTreatments
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Is this still an ongoing condition/do you have
                              ongoing symptoms?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .kidneyDisorderOngoingSymptoms
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .kidneyDisorderOngoingSymptoms === "yes" ? (
                            <tr>
                              <td>Ongoing symptoms details</td>
                              <td>
                                {
                                  props.formData.FormSeventeen
                                    .kidneyDisorderOngoingSymptomsDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "bladderDisorder" ? (
                        <tbody>
                          <tr>
                            <td>
                              Was the condition diagnosed as an infection only?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen.infectionOnly
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen.infectionOnly ===
                          "no" ? (
                            <>
                              <tr>
                                <td>What were your symptoms?</td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .bladderDisorderSymptoms
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>When did this occur?</td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .bladderDisorderWhenThisOccur
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What was the treatment that was recommended by
                                  a medical professional?
                                </td>
                                <td>
                                  {
                                    props.formData.FormSeventeen
                                      .bladderDisorderWhatTreatments
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.FormSeventeen.infectionOnly ===
                          "yes" ? (
                            <>
                              <tr>
                                <td>
                                  Was the treatment limited to antibiotics?
                                </td>
                                <td>
                                  {formatFormData(
                                    props.formData.FormSeventeen
                                      .treatmentLimitedToAntibiotics
                                  )}
                                </td>
                              </tr>
                              {props.formData.FormSeventeen
                                .treatmentLimitedToAntibiotics === "no" ? (
                                <tr>
                                  <td>Additional treatment details</td>
                                  <td>
                                    {
                                      props.formData.FormSeventeen
                                        .treatmentLimitedToAntibioticsDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              Within the last 2 years did a medical practitioner
                              recommend further investigations?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .withinLast2YearsAnyMedicalInvestigation
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .withinLast2YearsAnyMedicalInvestigation ===
                          "yes" ? (
                            <tr>
                              <td>Investigation details</td>
                              <td>
                                {
                                  props.formData.FormSeventeen
                                    .investigationDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "renalColicOrKidneyStone" ? (
                        <tbody>
                          <tr>
                            <td>
                              Have the stones been totally removed or passed?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .totallyRemovedOrPassed
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .totallyRemovedOrPassed === "yes" ? (
                            <tr>
                              <td>
                                How many episodes have you experienced in the
                                last 2 years?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.FormSeventeen.howManyEpisodes
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              Do you have ongoing problems with your kidneys?
                              eg. blood in urine?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.FormSeventeen
                                  .ongoingProblemsWithKidneys
                              )}
                            </td>
                          </tr>
                          {props.formData.FormSeventeen
                            .ongoingProblemsWithKidneys === "yes" ? (
                            <tr>
                              <td>Ongoing problems details</td>
                              <td>
                                {
                                  props.formData.FormSeventeen
                                    .ongoingProblemsWithKidneysDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default OrgansTab;
