import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
// validation
import { Formik, Form } from 'formik';

import { useDispatch } from 'react-redux';
import { setPage } from '../../store/actions/formActions';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useStyles } from '../video/classes';
import video from './video2.mp4';
import styles from '../../styles/form.module.css';
import axios from '../../lib/axios';

export default function FormOne() {
  const { id, advisor, client } = useParams();
  const classes = useStyles();

  const [sender, setSender] = useState({});
  const [gender, setGender] = useState('');
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    height: '',
    weigth: '',
    disability: '',
    disabilityYes: '',
    claimed: '',
    claimedYes: '',
    smoker: '',
    standardDrinks: '',
    smokerQuantity: '',
    lastSmoke: '',
    illicitDrugs: '',
    illicitDrugsYes: '',
    pregnant: '',
    overseas: '',
    overseasCity: '',
    overseasDuration: '',
    overseasReason: '',
    travelOutside: '',
    travelOutsideYes: '',
  });
  const validationSchema = Yup.object().shape({});

  const validationSchema2 = Yup.object().shape({});
  const dispatch = useDispatch();

  const submit = async (e) => {
    console.log('e');
    await axios.post('/form/add', {
      advisor,
      formOne: '',
      pointer: 2,
      formid: id,
      client,
    });
    try {
      dispatch(setPage(2));
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      const { data } = await axios.post('/auth/me', {
        advisor,
      });
      setSender(data);

      const user = await axios.get(`/client/get/${client}`);
      console.log(user);
      setGender(user.data.gender);
    } catch (error) {}
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={styles.wrapper}>
      <Grid item md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            gender === 'Female' ? validationSchema : validationSchema2
          }
          enableReinitialize
        >
          {({ isValid, dirty, values }) => {
            return (
              <Form>
                <Card style={{ padding: '2rem', paddingTop: 0 }}>
                  <CardContent>
                    <Grid container item xs={12} sm={12} md={12}>
                      <Grid
                        justifycontent='center'
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                        item
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            marginBottom: 10,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '1.5rem',
                              fontWeight: 'bold',
                              marginBottom: 5,
                            }}
                          >
                            {`${sender?.firstName}`}
                          </p>
                          <img
                            width='250'
                            height='250'
                            src={sender?.logo}
                            alt='advisor logo'
                            style={{ objectFit: 'contain' }}
                          />
                        </div>
                      </Grid>
                      <Grid md={6} item sm={12} xs={12}>
                        <div>
                          <h4 style={{ textAlign: 'center' }}>
                            Here is a short 60 second video that you must watch
                            prior to commencing your questionnaire
                          </h4>
                        </div>
                        <div className={classes.youtubes}>
                          <video width='100%' height='100%' controls>
                            <source src={video} type='video/mp4'></source>
                          </video>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                        className={classes.hide}
                      ></Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant='contained'
                      type='submit'
                      className={classes.btn}
                      style={{
                        marginLeft: 5,
                        fontFamily: 'Overpass, sans-serif',
                        fontSize: '1rem',
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
