import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "limitedToTrack", type: "" },
  { name: "vehicleTypes", type: [] },
  { name: "memberDetailsOFRacingClub", type: "" },
  { name: "camsClassification", type: "" },
  { name: "durationOfMotorSport", type: "" },
  { name: "professionalism", type: "" },
  { name: "engagementOfDemo", type: "" },
  { name: "relevantDetails", type: "" },
];

export default function MotorRacing({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Motor Racing", value: "motorRacing" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("motorRacing") && (
        <Grid item md={12} sm={12} xs={12}>
          <CheckBox
            question=" What
            type of
            vehicle
            do you
            race
            with ? "
            list={[
              {
                label: "Car racing",
                value: "Car racing",
              },
              { label: "Motorcycle racing", value: "Motorcycle racing" },
              {
                label: "Offroad Rallying",
                value: "Offroad Rallying",
              },
            ]}
            name="vehicleTypes"
            isSubForm
          />
        </Grid>
      )}

      {values.activities.includes("motorRacing") && (
        <YesNoQuestion
          question="Is your
          racing
          limited to
          track
          experience
          one-off day?"
          name="limitedToTrack"
          isSubForm
        />
      )}

      {values.activities.includes("motorRacing") &&
        values.limitedToTrack === "false" && (
          <div>
            <AnswerBox
              label="What events
            do you
            compete in?"
              name="competedEvents"
              isSubForm
            />
            <AnswerBox
              label="Are you a member of a Motor racing Club? details"
              name="memberDetailsOFRacingClub"
              isSubForm
            />
            <AnswerBox
              label="Do you have a C.A.M.S licence? If yes, what classification?"
              name="camsClassification"
              isSubForm
            />
            <AnswerBox
              label="How long have you been competing in motor sports?"
              name="durationOfMotorSport"
              isSubForm
            />
            <AnswerBox
              label="Do you take part in motor sports as a (professional, semi professional, sponsored driver, unsponsored amateur)?"
              name="professionalism"
              isSubForm
            />
            <AnswerBox
              label="Have you or do you anticipate engaging in any demonstration of test vehicles or accessories?"
              name="engagementOfDemo"
              isSubForm
            />
            <AnswerBox
              label="Please provide any relevant details."
              name="relevantDetails"
              isSubForm
            />
          </div>
        )}
    </Fragment>
  );
}
