import React from 'react';
import { AppBar } from '@material-ui/core';

export default function Footer() {
  const videoDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}privacy-policy/#privacy`;
  };

  return (
    <AppBar
      position='static'
      style={{ backgroundColor: 'black', color: 'white' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        <div>
          <p>
            © {new Date().getFullYear()} PREASSESSME
            <a
              href="/privacy-policy"
              onClick={videoDirect}
              style={{
                textDecoration: 'none',
                color: 'white',
                fontFamily: 'Overpass, sans-serif',
              }}
            >
              &nbsp;(Privacy Policy)
            </a>
          </p>
        </div>
      </div>
    </AppBar>
  );
}
