import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "../../lib/axios";
import { setPage } from "../../store/actions/formActions";

import styles from '../../styles/form.module.css';
import {
  Grid,
  Button,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const Agreement = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const submit = async (e) => {
    try {
      await axios.put("/form/agreement", {
        pointer: 28,
        formid: id,
      });

      dispatch(setPage(28));
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Questionnaire submit failed",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Questionnaire submit failed",
        });
      }, 3000);
    }

    history.push({
      pathname: '/success-message',
      state: {
        msg: 'Thanks for completing the questionnaire. We appreciate you taking the time to answer this questionnaire to ensure you get the most accurate pre assessment possible. Your adviser will be in touch with you soon!',
        btn: 'Thank You!',
      },
    });
  };

  return (
    <Grid className={styles.wrapper}>
      <Card style={{ padding: '2rem' }}>
        <CardContent>
          <Grid container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}
            >
              <div>
                <p style={{ margin: '2rem 1rem' }}>
                  By selecting YES, you acknowledge your Duty of Reasonable
                  Care. Where you have a duty to take reasonable care not to
                  make a misrepresentation.
                </p>
                <p style={{ margin: '1rem' }}>
                  A misrepresentation is a false answer, an answer that is only
                  partially true, or an answer which does not fairly reflect
                  truth. <span style={{ color: 'red' }}>*</span>
                </p>
                <p
                  style={{
                    margin: '1rem',
                    fontStyle: 'italic',
                    fontSize: '0.8rem',
                  }}
                >
                  please note. This is not a insurance application, but
                  information collected here is used to assess your risk. may be
                  used in pre assessment and in conjunction with your insurance
                  application.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginBottom: '1rem' }}>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label=''
                name='gender1'
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value='yes' control={<Radio />} label='YES' />
                <FormControlLabel value='no' control={<Radio />} label='NO' />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant='contained'
              disabled={value === 'yes' ? false : true}
              color='primary'
              onClick={submit}
            >
              Complete
            </Button>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Agreement;
