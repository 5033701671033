import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";

const resetArray = [
  { name: "traumaticStressDescribeSymptoms", type: "" },
  { name: "traumaticStressStartDate", type: "" },
  { name: "traumaticStressTimePeriod", type: "" },
  { name: "traumaticStressReasons", type: "" },
  { name: "traumaticStressFactors", type: "" },
  { name: "traumaticStressThoughts", type: "" },
  { name: "traumaticStressWhatTriggered", type: "" },
  { name: "traumaticStressSymptomsCommenced", type: "" },
  { name: "traumaticStressReoccurrencesOfCondition", type: "" },
  { name: "traumaticStressHowManyReoccurrences", type: "" },
  { name: "traumaticStressTreatment", type: [] },
  { name: "traumaticStressCounsellingWhenStart", type: "" },
  { name: "traumaticStressCounsellingEndDate", type: "" },
  { name: "traumaticStressCounsellingTherapyDetails", type: "" },
  { name: "traumaticStressPsychotherapyWhenStart", type: "" },
  { name: "traumaticStressPsychotherapyEndDate", type: "" },
  { name: "traumaticStressPsychotherapyTherapyDetails", type: "" },
  { name: "traumaticStressMedicationDose", type: "" },
  { name: "traumaticStressMedicationEndDate", type: "" },
  { name: "traumaticStressConditionMedication", type: "" },
  { name: "traumaticStressDosageOften", type: "" },
  { name: "traumaticStressDutiesRestrictionCondition", type: "" },
  { name: "traumaticStressWorkRestriction", type: "" },
  { name: "traumaticStressLifeRestriction", type: "" },
  { name: "traumaticStressDetails", type: "" },
];

export default function TraumaticStress({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Post-traumatic stress", value: "traumaticStress" }]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("traumaticStress") && (
        <AnswerBox
          label="Describe your symptoms"
          name="traumaticStressDescribeSymptoms"
          isSubForm
        />
      )}

      {values.traumaticStressDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="traumaticStressStartDate"
          isSubForm
        />
      )}
      {values.traumaticStressStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="traumaticStressTimePeriod"
          isSubForm
        />
      )}
      {values.traumaticStressTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause if these details?"
          name="traumaticStressReasons"
          isSubForm
        />
      )}
      {values.traumaticStressReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="traumaticStressFactors"
          isSubForm
        />
      )}
      {(values.traumaticStressReasons === "false" ||
        values.traumaticStressFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts, threatened to self-harm or engaged in self-harm?"
          name="traumaticStressThoughts"
          isSubForm
        />
      )}
      {values.traumaticStressThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="traumaticStressWhatTriggered"
          isSubForm
        />
      )}
      {(values.traumaticStressThoughts === "false" ||
        values.traumaticStressWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="traumaticStressSymptomsCommenced"
          isSubForm
        />
      )}
      {values.traumaticStressSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="traumaticStressReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.traumaticStressReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="traumaticStressHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.traumaticStressReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="traumaticStressTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.traumaticStressTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="traumaticStressCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="traumaticStressCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="traumaticStressCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.traumaticStressReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="traumaticStressTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}
      {values.traumaticStressTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="traumaticStressPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="traumaticStressPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="traumaticStressPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.traumaticStressReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="traumaticStressTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.traumaticStressTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="traumaticStressMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="traumaticStressMedicationEndDate"
            isSubForm
          />
          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="traumaticStressConditionMedication"
            isSubForm
          />
          {values.traumaticStressConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="traumaticStressDosageOften"
              isSubForm
            />
          )}
          {(values.traumaticStressDosageOften ||
            values.traumaticStressConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="traumaticStressDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.traumaticStressDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="traumaticStressWorkRestriction"
              isSubForm
            />
          )}
          {(values.traumaticStressDutiesRestrictionCondition === "false" ||
            values.traumaticStressWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="traumaticStressLifeRestriction"
              isSubForm
            />
          )}
          {values.traumaticStressLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="traumaticStressDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
