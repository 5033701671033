import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { storage } from '../../lib/firebase';
import blue from '@material-ui/core/colors/blue';

import axios from '../../lib/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#004AAD',
    fontFamily: 'Overpass, sans-serif',
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function Company(props) {
  const classes = useStyles();

  const [componentType, setComponentType] = useState('create');
  const [callingBackend, setCallingBackend] = useState(false);
  const [defaultImageUpload, setDefaultImageUpload] = useState(false);
  const [open, setOpen] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    address: '',
    logo: '',
    brn: '',
    contactNumber: '',
  });

  const addDefaultSrc = (ev) => {
    ev.target.src = 'some default image url';
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    if (componentType === 'create') {
      try {
        setCallingBackend(true);
        await axios.post('/company', {
          name: e.name,
          email: e.email,
          BRN: e.brn,
          address: e.address,
          logo: e.logo,
          contactNumber: e.contactNumber,
        });
        resetForm();
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Company created successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Company created successfully!',
          });
          setOpen(false);
          const path = '/company';
          props.history.push(path);
        }, 3000);
      } catch (error) {
        if (error.response.status === 403) {
          setOpen(true);
          setAlert({
            showAlert: true,
            severity: 'error',
            message: error.response.data,
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Company already exists!',
            });
            setOpen(false);
          }, 3000);
        } else {
          setOpen(true);
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Company creation failed!',
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Company creation failed!',
            });
            setOpen(false);
          }, 3000);
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        e.BRN = e.brn;
        await axios.put(`/company/${props.location.state.id}`, e);
        props.history.push('/company');
      } catch (error) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Company updating failed!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Company updating failed!',
          });
          setOpen(false);
        }, 3000);
      } finally {
        setCallingBackend(false);
      }
    }
  };

  const removeImage = (setFieldValue) => {
    setFieldValue('logo', '');
  };

  const checkExtension = (filename) => {
    return (
      filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
      filename
    );
  };

  const handleImageAsFile = async (e, setFieldValue, type, values) => {
    try {
      const ext = checkExtension(e.target.value);
      if (ext !== 'jpg' && ext !== 'png' && ext !== 'webp' && ext !== 'jpeg') {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Please upload valid logo!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Please upload valid logo!',
          });
          setOpen(false);
        }, 3000);
      } else {
        setDefaultImageUpload(true);
        const image = e.target.files[0];
        const storageRef = storage.ref();
        const fileRef = storageRef.child(
          `logos/${image.name}${image.lastModifiedDate}`
        );
        const uploadTaskSnapshot = await fileRef.put(image);
        const imageUrl = await uploadTaskSnapshot.ref.getDownloadURL();
        if (type === 'logo') {
          setFieldValue('logo', imageUrl);
        } else {
          setFieldValue(`images[${values.images.length}]`, imageUrl);
        }

        setOpen(true);

        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Successfully Uploaded!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Successfully Uploaded!',
          });
          setOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
  };

  const get = async (id) => {
    try {
      const data = await axios.get(`/company/${id}`);

      setInitialValues({
        name: data.data.name,
        email: data.data.email,
        brn: data.data.BRN,
        address: data.data.address,
        logo: data.data.logo,
        contactNumber: data.data.contactNumber,
      });
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Data loading failed!',
        });
        setOpen(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setComponentType('update');
      get(props.location.state.id);
    }
    /* eslint-disable */
  }, [props.location.state]);

  // validation schema
  let validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Company Name Required!')
      .min(3, 'Min length for the name is 3')
      .max(35, 'Max length for the name is 35.'),
    email: Yup.string()
      .email()
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        'Please enter valid Email'
      ),
    contactNumber: Yup.string().matches(
      /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/,
      'Please enter valid Australian phone number'
    ),
    brn: Yup.string()
      .min(3, 'Min length for the name is 3')
      .max(35, 'Max length for the name is 35.'),
    address: Yup.string().max(300, 'Max length for the name is 300.'),
    logo: Yup.string().required('Logo Required!'),
  });

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    {componentType === 'create'
                      ? 'Create an Insurance Company'
                      : 'Update the Insurance Company'}
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='name'
                          label='Company Name'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='contactNumber'
                          label='Contact Number [xxxxxxxxxx, +61xxxxxxxx]'
                          component={TextField}
                          variant='outlined'
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='email'
                          label='Company Email'
                          component={TextField}
                          variant='outlined'
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='brn'
                          label='ABN'
                          component={TextField}
                          variant='outlined'
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='address'
                          label='Company Address'
                          component={TextField}
                          variant='outlined'
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {defaultImageUpload ? (
                          'Uploading...'
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor='upload-photo'>
                                <input
                                  style={{ display: 'none' }}
                                  id='upload-photo'
                                  name='upload-photo'
                                  type='file'
                                  accept='image/jpeg,image/png'
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile(
                                      e,
                                      setFieldValue,
                                      'logo',
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  color='secondary'
                                  variant='contained'
                                  component='span'
                                  size='small'
                                  style={{ fontFamily: 'Overpass, sans-serif' }}
                                >
                                  Upload Logo
                                </Button>
                              </label>
                              {componentType === 'update' ? (
                                <Box ml={2} display='inline'>
                                  <Button
                                    color='inherit'
                                    variant='contained'
                                    component='span'
                                    size='small'
                                    onClick={(e) => removeImage(setFieldValue)}
                                  >
                                    Remove Logo
                                  </Button>
                                </Box>
                              ) : (
                                ''
                              )}
                              <div>
                                {values.logo ? (
                                  <img
                                    src={values.logo}
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1rem',
                                    }}
                                    onError={addDefaultSrc}
                                  ></img>
                                ) : (
                                  <img
                                    src='https://via.placeholder.com/150/#C0C0C0/000000?Text=WebsiteBuilders.com%20C/O%20https://placeholder.com/'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1rem',
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Typography
                        style={{
                          marginLeft: 6,
                          fontFamily: 'Overpass, sans-serif',
                        }}
                      >
                        * Company logo is required
                      </Typography>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          className={classes.button}
                          color={
                            componentType === 'create' ? 'primary' : 'secondary'
                          }
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          {componentType === 'create' ? 'create' : 'update'}
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>
                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
