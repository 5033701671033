import React, { Fragment } from 'react';
import { TableCell, Button, Box } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import ReusableTable from '../../components/layouts/ReusableTable';
import axios from '../../lib/axios';
import { useSelector } from 'react-redux';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

export default function UnderwriterView(props) {
  let role = useSelector((state) => state.auth.role);
  let fetchUrl;
  if (role === 'admin') {
    fetchUrl = 'underwriter';
  }
  if (role === 'advisor') {
    fetchUrl = 'underwriter/getMyUnderWriters';
  }

  let headers = [
    {
      text: 'Logo',
      value: 'name',
    },
    {
      text: 'Underwriter',
      value: 'name',
    },
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Contact Number',
      value: 'contactNumber',
    },
    {
      text: 'Company',
      value: 'company',
    },

    {
      text: 'State',
      value: 'state',
    },
    {
      text: 'Currents Status',
      value: 'currentStatus',
    },
    {
      text: 'Change Status',
      value: 'status',
    },
    {
      text: 'Update',
      value: 'update',
    },
    {
      text: 'Preference',
      value: 'preference',
    },
  ];

  if (role === 'admin') {
    headers = [
      {
        text: 'Logo',
        value: 'name',
      },
      {
        text: 'Underwriter',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Contact Number',
        value: 'contactNumber',
      },
      {
        text: 'Company',
        value: 'company',
      },
      {
        text: 'Advisor',
        value: 'Advisor',
      },

      {
        text: 'State',
        value: 'state',
      },
      {
        text: 'Currents Status',
        value: 'currentStatus',
      },
      {
        text: 'Change Status',
        value: 'status',
      },
      {
        text: 'Update',
        value: 'update',
      },
    ];
  }

  if (role === 'advisor') {
    headers = [
      {
        text: 'Logo',
        value: 'name',
      },
      {
        text: 'Underwriter',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Contact Number',
        value: 'contactNumber',
      },
      {
        text: 'Company',
        value: 'company',
      },

      {
        text: 'State',
        value: 'state',
      },
      {
        text: 'Currents Status',
        value: 'currentStatus',
      },

      {
        text: 'Preference',
        value: 'Preference',
      },
    ];
  }

  const TableB = ({
    item,
    changeStatus,
    classes,
    onUpdate,
    loadData,
    setAlert,
  }) => {
    const setPreference = async (id) => {
      try {
        await axios.put('/underwriter/advisor-favorite', {
          underwriterId: id,
        });
        loadData();
      } catch (error) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Underwriter updating failed!',
        });
      }
    };

    return (
      <Fragment>
        <TableCell key='image' style={{ fontFamily: 'Overpass, sans-serif' }}>
          <img
            src={item.companyId.logo}
            style={{ fontFamily: 'Overpass, sans-serif', maxHeight: '100px' }}
            alt=' '
          ></img>
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.name}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.email}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.contactNumber}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.companyId && item.companyId.name}
        </TableCell>
        {role === 'admin' ? (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            {item.advisorId && item.advisorId.fullName}
          </TableCell>
        ) : (
          ''
        )}

        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.state}
        </TableCell>
        {item.status ? (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            {' '}
            <Chip label='Active' className={classes.green} />
          </TableCell>
        ) : (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            <Chip label='Inactive' className={classes.red} />
          </TableCell>
        )}

        {role === 'admin' && (
          <TableCell key='status'>
            {item.status ? (
              <Button
                style={{ fontFamily: 'Overpass, sans-serif' }}
                variant='contained'
                size='small'
                className={classes.red}
                onClick={() => changeStatus(item._id, item.status)}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                style={{ fontFamily: 'Overpass, sans-serif' }}
                variant='contained'
                size='small'
                className={classes.green}
                onClick={() => changeStatus(item._id, item.status)}
              >
                Activate
              </Button>
            )}
          </TableCell>
        )}

        {role === 'admin' && (
          <TableCell key='update'>
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              size='small'
              className={classes.grey}
              onClick={() => onUpdate(item._id)}
            >
              update
            </Button>
          </TableCell>
        )}

        {role === 'advisor' && (
          <TableCell
            key='preference'
            style={{ fontFamily: 'Overpass, sans-serif' }}
          >
            <Box textAlign='center'>
              {item.isPreferred ? (
                <StarIcon
                  onClick={() => setPreference(item._id)}
                  style={{ color: 'yellow', cursor: 'pointer' }}
                />
              ) : (
                <StarOutlineIcon
                  onClick={() => setPreference(item._id)}
                  style={{ color: 'grey', cursor: 'pointer' }}
                />
              )}
            </Box>
          </TableCell>
        )}
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title='View Underwriters'
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl={fetchUrl} //change
      statusChangeUrl='/underwriter/status'
      updatePath='/underwriter-update'
      {...props}
    />
  );
}
