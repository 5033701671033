import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import axios from '../../lib/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    backgroundColor: '#004AAD',
    color: 'white',
    fontFamily: 'Overpass, sans-serif',
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function SetPassword(props) {
  const classes = useStyles();

  const { email, verificationToken } = useParams();
  const [open, setOpen] = useState(false);

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      await axios.put('/auth/reset-password', {
        email: email,
        PRT: verificationToken,
        password: e.newPassword,
      });

      setOpen(true);

      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Password changed successfully!',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Password changed successfully!',
        });
        setOpen(false);
        const path = '/login';
        props.history.push(path);
      }, 3000);
    } catch (error) {
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Password change invalid!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Password change invalid!',
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Password change invalid!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Password change invalid!',
          });
          setOpen(false);
        }, 3000);
      }
    }
  };

  // validation schema
  let validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is required!')
      .min(8, 'Min length for the Password is 8'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required!')
      .min(8, 'Min length for the Password is 8')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });
  return (
    <div>
      <Grid
        container
        justifycontent='center'
        className={classes.root}
        spacing={1}
      >
        <Grid item xs={12} sm={10} md={8}>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isValid, dirty }) => {
              return (
                <Form>
                  <Card>
                    <CardHeader
                      style={{ fontFamily: 'holiday' }}
                      title='Set New Password'
                    />
                    <CardContent>
                      <Grid container item spacing={2} xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            name='newPassword'
                            label='New Password'
                            component={TextField}
                            variant='outlined'
                            fullWidth
                            inputProps={{
                              style: { fontFamily: 'Overpass, sans-serif' },
                            }}
                            required
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            name='confirmPassword'
                            label='Confirm Password'
                            component={TextField}
                            variant='outlined'
                            fullWidth
                            inputProps={{
                              style: { fontFamily: 'Overpass, sans-serif' },
                            }}
                            required
                          ></Field>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          className={classes.button}
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          Change
                        </Button>
                      </ThemeProvider>
                    </CardActions>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={() =>
            setAlert({
              ...alert,
              showAlert: false,
            })
          }
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={alert.severity}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
