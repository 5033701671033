import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const timeList = [
  {
    label: "No time off work",
    value: "noTimeOffWork",
  },
  {
    label: "Less than 1 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "1-2 weeks",
    value: "oneTwoWeeks",
  },
  {
    label: "3-4 weeks",
    value: "threeFourWeeks",
  },
  {
    label: "+4 weeks",
    value: "moreThanFourWeeks",
  },
];

const affectedList = [
  {
    label: "Skull fracture",
    value: "SkullFracture",
  },
  {
    label: "Spinal Fracture",
    value: "SpinalFracture",
  },
  {
    label: "Hip",
    value: "Hip",
  },
  {
    label: "Shoulder",
    value: "SHoulder",
  },
  {
    label: "Left Arm",
    value: "leftArm",
  },
  {
    label: "Right Arm",
    value: "rightArm",
  },
  {
    label: "Left Leg",
    value: "leftLeg",
  },
  {
    label: "Right Leg",
    value: "rightLeg",
  },
  {
    label: "Right foot",
    value: "rightFoot",
  },
  {
    label: "Left foot",
    value: "leftFoot",
  },
  {
    label: "Ribs",
    value: "Ribs",
  },
  {
    label: "Finger (s)",
    value: "Finger",
  },
  {
    label: "Toe (s)",
    value: "Toe",
  },
  {
    label: "Hand(s)",
    value: "Hand",
  },
  {
    label: "Clavicle / Collarbone",
    value: "ClavicleCollarbone",
  },
  {
    label: "other",
    value: "other",
  },
];

const affectedArmList = [
  {
    label: "Humerus",
    value: "Humerus",
  },
  {
    label: "Radius",
    value: "Radius",
  },
  {
    label: "Ulna",
    value: "Ulna",
  },
];

const affectedLegList = [
  {
    label: "Femur",
    value: "Femur",
  },
  {
    label: "Tibia",
    value: "Tibia",
  },
  {
    label: "Fibula",
    value: "Fibula",
  },
];
const affectedFootList = [
  {
    label: "Tarsal Bones",
    value: "TarsalBones",
  },
  {
    label: "Phalanges",
    value: "Phalanges",
  },
];

const resetArray = [
  { name: "affectedJoint", type: [] },
  { name: "affectedArm", type: "" },
  { name: "affectedLeg", type: "" },
  { name: "affectedFoot", type: "" },
  { name: "JointDisorder", type: "" },
  { name: "whenOccur", type: "" },
  { name: "whatTreatment", type: "" },
  { name: "BoneFracturesAnyRestrictedYes", type: "" },
  { name: "BoneFracturesDetails", type: "" },
  { name: "BoneFracturesTimeHadOffWork", type: "" },
];

export default function SubFormFour({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Bone fractures from Trauma or accident",
            value: "boneFractures",
          },
        ]}
        name="subFormsOfFourteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFourteen.includes("boneFractures") && (
        <CheckBox
          question="Which joints were affected?"
          list={affectedList}
          name={"affectedJoint"}
          isSubForm
        />
      )}

      {(values.affectedJoint.includes("rightArm") ||
        values.affectedJoint.includes("leftArm")) &&
        values.subFormsOfFourteen.includes("boneFractures") && (
          <CheckBox
            question="Which arm bone?"
            list={affectedArmList}
            name={"affectedArm"}
            isSubForm
          />
        )}
      {(values.affectedJoint.includes("rightLeg") ||
        values.affectedJoint.includes("leftLeg")) &&
        values.subFormsOfFourteen.includes("boneFractures") && (
          <CheckBox
            question="Which leg bone?"
            list={affectedLegList}
            name={"affectedLeg"}
            isSubForm
          />
        )}
      {(values.affectedJoint.includes("rightFoot") ||
        values.affectedJoint.includes("leftFoot")) &&
        values.subFormsOfFourteen.includes("boneFractures") && (
          <CheckBox
            question="Which foot bone?"
            list={affectedFootList}
            name={"affectedFoot"}
            isSubForm
          />
        )}
      {(values.affectedFoot.length !== 0 ||
        values.affectedLeg.length !== 0 ||
        values.affectedArm.length !== 0 ||
        values.affectedJoint.length !== 0) &&
        values.subFormsOfFourteen.includes("boneFractures") && (
          <AnswerBox label="When did this occur?" name="whenOccur" isSubForm />
        )}
      {values.subFormsOfFourteen.includes("boneFractures") &&
        values.whenOccur && (
          <AnswerBox
            label="What was the treatment?"
            name="whatTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("boneFractures") &&
        values.whatTreatment && (
          <RadioMulti
            question="Are you in any way restricted in your daily activities as a consequence of this condition?"
            name={"BoneFracturesAnyRestrictedYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("boneFractures") &&
        values.BoneFracturesAnyRestrictedYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="BoneFracturesDetails"
            isSubForm
          />
        )}
      {(values.BoneFracturesDetails ||
        values.BoneFracturesAnyRestrictedYes === "no") &&
        values.subFormsOfFourteen.includes("boneFractures") && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"BoneFracturesTimeHadOffWork"}
            items={timeList}
            isSubForm
          />
        )}
    </Fragment>
  );
}
