import React from 'react';
import { useStyles } from './classes';

import Grid from '@material-ui/core/Grid';

import Img from '../../assets/images/videos.PNG';
import Img1 from '../../assets/images/image1.png';

const Videopage = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems='stretch' style={{ marginTop: '2rem' }}>
      <Grid xs={12} sm={12} md={3} lg={3} xl={3} item className={classes.hide}>
        <img
          className={classes.img1}
          src={Img}
          alt='Adviser adds client details'
        />
      </Grid>
      <Grid md={6} item sm={12} xs={12}>
        <div
          style={{
            textAlign: 'center',
            height: '100%',
            minHeight: '30rem',
            padding: '2rem',
          }}
        >
          <iframe
            width='100%'
            height='100%'
            src='https://www.youtube.com/embed/VkjXjNcQ53M?autoplay=1&mute=0'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
        {/* <YouTube
            videoId="FFEcq7xJXMg"
            opts={{ playerVars: { autoplay: 0 } }}
          /> */}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.hide}>
        <img
          className={classes.img}
          src={Img1}
          alt='Adviser adds client details'
        />
      </Grid>
    </Grid>
  );
};

export default Videopage;
