import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "2rem",
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },

  root: {
    flexGrow: 1,
    marginTop: 10,
  },

  box: {
    display: "flex",
    justifyContent: "center",
  },

  img: {
    width: "90%",
    height: "90%",
  },

  form: {
    marginLeft: 100,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 30,
      justifyContent: "center",
    },
  },
  size: {
    [theme.breakpoints.down("xl")]: {
      width: 1200,
      height: 780,
      alignself: "center",
    },
    [theme.breakpoints.down("lg")]: {
      width: 700,
      height: 380,
      alignself: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: 600,
      height: 350,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: 700,
      height: 400,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: 350,
      height: 200,
      alignself: "center",
      marginTop: -70,
      marginLeft: 22,
    },
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hidemb: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  displayFlex: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  field: {
    marginTop: "1.5rem",
    alignself: "center",
    borderRadius: "25px",
    marginBottom: 29,
    width: "85%",
    height: "30px",
  },
  button: {
    fontFamily: "Overpass, sans-serif",
    textTransform: "capitalize",
    fontSize: "1.2rem",
    backgroundColor: "#000000",
    color: "#fff",
    width: "160px",
    fontWeight: 400,
    height: 40,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
  },
  text: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "1.4rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  text1: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "1rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  alginLeft: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
    },
  },
}));
export const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#e45826",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e45826",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        height: "50px",
        fontSize: "1.2.6rem",
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // backgroundColor: "#ffffff",
        color: "#000000",
        width: 450,
        [theme.breakpoints.down("xl")]: {
          width: 750,
        },
        [theme.breakpoints.down("lg")]: {
          width: 450,
        },
        [theme.breakpoints.down("md")]: {
          height: "50px",
          fontSize: "1.2rem",
          width: 450,
        },
        [theme.breakpoints.down("sm")]: {
          height: "50px",
          fontSize: "1.1rem",
          width: 350,
        },
        [theme.breakpoints.down("xs")]: {
          height: "45px",
          fontSize: "1rem",
          width: 250,
        },
      },
      "&:hover fieldset": {
        borderColor: "#000000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
  },
}))(TextField);

export const CssTextField2 = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#e45826",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e45826",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        height: "80px",
        fontSize: "1.2.6rem",
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // backgroundColor: "#ffffff",
        color: "#000000",
        width: 450,
        [theme.breakpoints.down("xl")]: {
          width: 750,
        },
        [theme.breakpoints.down("lg")]: {
          width: 450,
        },
        [theme.breakpoints.down("md")]: {
          height: "80px",
          fontSize: "1.2rem",
          width: 450,
        },
        [theme.breakpoints.down("sm")]: {
          height: "80px",
          fontSize: "1.1rem",
          width: 350,
        },
        [theme.breakpoints.down("xs")]: {
          height: "60px",
          fontSize: "1rem",
          width: 250,
        },
      },
      "&:hover fieldset": {
        borderColor: "#000000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000",
      },
    },
  },
}))(TextField);
