import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import styles from "../../styles/form.module.css";

export default function AnswerBox({ label, name, rows = 2, required = true, isSubForm = false }) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box className={ isSubForm ? styles.subFormBox : styles.FormBox }>
        <p className={ styles.question }>
          { label }
        </p>
        <Field
          component={TextField}
          name={ name }
          size="small"
          fullWidth
          multiline
          rows={ rows }
          label={ required ? "Required field" : "Optional field" }
          variant={"outlined"}
          inputProps={{
            style: { fontFamily: "Overpass, sans-serif" },
          }}
          required={ required }
        />
      </Box>
    </Grid>
  );
}
