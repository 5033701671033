import React, { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const resetArray = [
  { name: "growthWhenTumourDetected", type: "" },
  { name: "growthWhatTreatment", type: "" },
  { name: "growthAnyOtherDetails", type: "" },
];
export default function SubFormSix({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Growth", value: "growth" }]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfSixteen.includes("growth") && (
        <AnswerBox
          label="When was the growth detected?"
          name="growthWhenTumourDetected"
          isSubForm
        />
      )}
      {values.subFormsOfSixteen.includes("growth") &&
        values.growthWhenTumourDetected !== "" && (
          <AnswerBox
            label="What treatment was recommended?"
            name="growthWhatTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("growth") &&
        values.growthWhatTreatment !== "" && (
          <AnswerBox
            label="Please provide any other details pertaining to this growth?"
            name="growthAnyOtherDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
