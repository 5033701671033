import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import axios from '../../lib/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#004AAD',
    color: 'white',
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function User(props) {
  const classes = useStyles();

  const componentType = 'create';
  const [callingBackend, setCallingBackend] = useState(false);
  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState('');

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put('/auth/change-password', {
        email: email,
        currentPassword: e.currentPassword,
        newPassword: e.newPassword,
      });
      setOpen(true);

      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Password changed successfully!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Password changed successfully!',
        });
        setOpen(false);
      }, 3000);
    } catch (error) {
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Current Password invalid!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Current Password invalid!',
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Current change invalid!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Current change invalid!',
          });
          setOpen(false);
        }, 3000);
      }
    } finally {
      setCallingBackend(false);
    }
  };

  // validation schema
  let validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required!')
      .min(8, 'Min length for the Password is 8'),
    newPassword: Yup.string()
      .required('New Password is required!')
      .min(8, 'Min length for the Password is 8'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required!')
      .min(8, 'Min length for the Password is 8')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const loadData = async () => {
    try {
      const data = await axios.get('/auth/me');
      console.log('sasffs', data);
      setEmail(data.data.email);
    } catch (error) {
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'error!',
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User loading failed!',
        });
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    Change Password
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='currentPassword'
                          label='Current Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='newPassword'
                          label='New Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='confirmPassword'
                          label='Confirm Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          style={{ fontFamily: 'Overpass, sans-serif' }}
                          className={classes.button}
                          // color={
                          //   componentType === 'create' ? 'primary' : 'secondary'
                          // }
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          {componentType === 'create' ? 'Change' : 'update'}
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>

                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
