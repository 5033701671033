import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "fitsOfAnyKindChildhood", type: "" },
  { name: "fitsOfAnyKindTreatment", type: "" },
  { name: "fitsOfAnyKindstartDate", type: "" },
  { name: "fitsOfAnyKindRestrictedDuties", type: "" },
  { name: "fitsOfAnyKindDetails", type: "" },
  
];
export default function FitsOfAnyKind({ values, setFieldValue, setFieldTouched  }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Fits of any kind", value: "fitsofanykind" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("fitsofanykind") && (
        <YesNoQuestion
          question="Were your fits restricted to your childhood?"
          name="fitsOfAnyKindChildhood"
          isSubForm
        />
      )}
      {values.fitsOfAnyKindChildhood === "true" && (
        <AnswerBox
          label="What treatment was provided?"
          name="fitsOfAnyKindTreatment"
          isSubForm
        />
      )}
      {values.fitsOfAnyKindChildhood === "true" && (
        <AnswerBox
          label="When did this occur?"
          name="fitsOfAnyKindstartDate"
          isSubForm
        />
      )}
      {values.fitsOfAnyKindChildhood === "true" && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="fitsOfAnyKindRestrictedDuties"
          isSubForm
        />
      )}
      {values.fitsOfAnyKindRestrictedDuties === "true" && (
        <AnswerBox 
        label="Please provide details"
        name="fitsOfAnyKindDetails"
        isSubForm
        />
      )}
    </Fragment>
  );
}
