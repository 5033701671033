import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const MultipleItems = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  {
    label: "No treatment",
    value: "noTreatment",
  },
];
const BodyAreaList = [
  {
    label: "Scalp",
    value: "scalp",
  },
  {
    label: "Elbow Joint",
    value: "elbowJoint",
  },
  {
    label: "Knee Joints",
    value: "kneeJoints",
  },
  {
    label: "Other",
    value: "other",
  },
];
const resetArray = [
  { name: "whichBodyArea", type: [] },
  { name: "psoriasisBodyAreaDetails", type: "" },
  { name: "psoriasisAnyJointPain", type: "" },
  { name: "psoriasisTreatmentLimited", type: "" },
  { name: "psoriasisHadAnyConsecutiveDays", type: "" },
];
export default function SubFormOne({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Psoriasis", value: "psoriasis" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("psoriasis") && (
        <CheckBox
          question="Which areas of the body are affected by psoriasis?"
          list={BodyAreaList}
          name="whichBodyArea"
          isSubForm
        />
      )}
      {values.subFormsOfFormFifteen.includes("psoriasis") &&
        values.whichBodyArea.includes("other") && (
          <AnswerBox
            label="Please provide details?"
            name="psoriasisBodyAreaDetails"
            isSubForm
          />
        )}
      {(values.whichBodyArea.includes("scalp") ||
        values.whichBodyArea.includes("elbowJoint") ||
        values.whichBodyArea.includes("kneeJoints") ||
        values.psoriasisBodyAreaDetails !== "") &&
        values.subFormsOfFormFifteen.includes("psoriasis") && (
          <RadioMulti
            question="Have you had joint pain or been diagnosed with psoriasis arthritis?"
            name={"psoriasisAnyJointPain"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("psoriasis") &&
        values.psoriasisAnyJointPain && (
          <RadioMulti
            question="Is your treatment limited to cream/ointment/lotions?"
            name={"psoriasisTreatmentLimited"}
            items={MultipleItems}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("psoriasis") &&
        values.psoriasisTreatmentLimited && (
          <RadioMulti
            question="Have you had 3 consecutive days off work in the last 2 years due to psoriasis?"
            name={"psoriasisHadAnyConsecutiveDays"}
            items={items}
            isSubForm
          />
        )}
    </Fragment>
  );
}
