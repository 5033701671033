import React, { useState } from "react";
import { Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubForm23 from "./subForms/SubForm23";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import styles from "../../styles/form.module.css";
import { useStyles } from "./classes";

export default function FormTwentyThree() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    //SubForm23
    isAccidentHappend: "",
    whenAccident: "",
    workReducedWhenAccident: "",
    isAccidentRecovered: "",
    accidentNotRecoveredInguries: "",
  });
  const validationSchema = Yup.object().shape({
    //SubForm23
    isAccidentHappend: Yup.string().required(),
    whenAccident: Yup.string().when("isAccidentHappend", {
      is: "true",
      then: Yup.string().required(),
    }),
    workReducedWhenAccident: Yup.string().when("isAccidentHappend", {
      is: "true",
      then: Yup.string().required(),
    }),
    isAccidentRecovered: Yup.string().when("isAccidentHappend", {
      is: "true",
      then: Yup.string().required(),
    }),
    accidentNotRecoveredInguries: Yup.string().when("isAccidentRecovered", {
      is: "false",
      then: Yup.string().required(),
    }),
  });

  const submit = async (e) => {
    try {
      await axios.put("/form/formtwentythree", {
        formTwentyThree: e,
        pointer: 24,
        formid: id,
      });
      dispatch(setPage(24));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched , resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ marginBottom: "6.5rem", padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you ever been involved in an accident that has
                        caused you to be off work or reduce your working
                        capacity of greater than 10 consecutive days?
                      </p>
                      <SubForm23
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
