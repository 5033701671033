import React from "react";
import { CheckboxWithLabel as CheckBox } from "formik-material-ui";
import { Field } from "formik";

export default function NoneOfTheAbove({
  values,
  setFieldValue,
  setFieldTouched,
  exceptionArray,
  resetForm,
  setErrorsFun,
}) {
  const resetFold = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setTimeout(() => {
        const matchingArray = Object.keys(values)
          .filter((key) => Object.keys(exceptionArray).includes(key))
          .reduce((obj, key) => {
            obj[key] = values[key];
            return obj;
          }, {});
        const output = Object.entries(matchingArray).map(([key, value]) => ({
          key,
          value,
        }));

        console.log(output);
        resetForm();
        setErrorsFun();
        setFieldValue("noneOfTheAbove", true, false);
        for (const item of output) {
          setFieldValue(item.key, item.value, false);
          setFieldTouched(item.key, false);
        }

      }, 0);
    }
  };
  return (
    <>
      <Field
        type="checkbox"
        component={CheckBox}
        name="noneOfTheAbove"
        Label={{
          label: (
            <span
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              None of the above
            </span>
          ),
        }}
        onChange={(e) => {
          setFieldValue("noneOfTheAbove", e.target.checked);
          resetFold(e);
        }}
      />
    </>
  );
}
