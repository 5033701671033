import React from "react";
import { Button, Col } from "react-bootstrap";

const Heading = (props) => {
  return (
    <Col xs={12} className="d-flex justify-content-between mb-4">
      <h4 className="d-inline-block my-2">{props.heading}</h4>
      {props.button ? (
        <a href={props.buttonUrl}>
          <Button className="mx-3 px-4" variant="primary" onClick={props.buttonOnClick}>
            {props.button}
          </Button>
        </a>
      ) : (
        ""
      )}
    </Col>
  );
};

export default Heading;
