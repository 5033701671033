import { FIRST, SECOND } from "../actions/types";

const initialState = {
  email: "",
  name: "",
  arNumber: "",
  adFirm: "",
  group: "",
  state: "Northern Territory",
  contactNumber: "",
  postalAddress: "",
  password: "",
  logo: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case FIRST:
      localStorage.setItem(
        "profile",
        JSON.stringify({ ...action.payload.data })
      );
      return { ...state, ...action.payload.data };

    case SECOND:
      localStorage.setItem(
        "next",
        JSON.stringify({ ...state, ...action.payload.data })
      );
      return { ...state, ...action.payload.data };

    default:
      return state;
  }
}
