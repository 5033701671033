import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const CancerTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container id="formview-cancer-tabs" defaultActiveKey="#cancer-1">
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formSixteen.subFormsOfSixteen.map(
                (illness, index) => (
                  <ListGroup.Item action href={`#cancer-${index + 1}`}>
                    {formatFormData(illness)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      formatFormData(illness)
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1)}
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formSixteen.subFormsOfSixteen.map(
                (illness, index) => (
                  <Tab.Pane eventKey={`#cancer-${index + 1}`}>
                    <Table
                      id="form-view-cancer-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {illness === "cancerSkin" ? (
                        <tbody>
                          <tr>
                            <td>How was the skin growth treated?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formSixteen.cancerSkinTreatment
                              )}
                            </td>
                          </tr>
                          {props.formData.formSixteen.cancerSkinTreatment.includes(
                            "excised"
                          ) ? (
                            <>
                              <tr>
                                <td>When was the cancer removed?</td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formSixteen
                                      .whenWasCancerRemoved
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>What did the biopsy confirm?</td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formSixteen
                                      .whatDiBiopsyConfirm
                                  )}
                                </td>
                              </tr>
                              {props.formData.formSixteen.whatDiBiopsyConfirm.includes(
                                "BCC"
                              ) ? (
                                <tr>
                                  <td>
                                    Have you had 3 or more Basal cell
                                    carcinomas?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formSixteen
                                        .BasalCellCarcinomasYes
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              {props.formData.formSixteen
                                .BasalCellCarcinomasYes === "yes" ? (
                                <tr>
                                  <td>Basal cell carcinoma details</td>
                                  <td>
                                    {
                                      props.formData.formSixteen
                                        .BasalCellCarcinomasDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              {props.formData.formSixteen.whatDiBiopsyConfirm.includes(
                                "melanoma"
                              ) ||
                              props.formData.formSixteen.whatDiBiopsyConfirm.includes(
                                "SCC"
                              ) ||
                              props.formData.formSixteen.whatDiBiopsyConfirm.includes(
                                "OtherUnknown"
                              ) ||
                              props.formData.formSixteen.whatDiBiopsyConfirm.includes(
                                "noBiopsy"
                              ) ? (
                                <tr>
                                  <td>
                                    Other than having the skin cancer cut out
                                    did you have or were you advised to have any
                                    other treatment? (eg.radiotherapy)
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formSixteen
                                        .anyOtherTreatmentYes
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              {props.formData.formSixteen
                                .anyOtherTreatmentYes === "yes" ? (
                                <tr>
                                  <td>Other treatment details</td>
                                  <td>
                                    {
                                      props.formData.formSixteen
                                        .OtherTreatmentDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.formSixteen.cancerSkinTreatment.includes(
                            "creamOintment"
                          ) ? (
                            <tr>
                              <td>Cream or ointment details</td>
                              <td>
                                {props.formData.formSixteen.treatmentDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {props.formData.formSixteen.cancerSkinTreatment.includes(
                            "notRemoved"
                          ) ? (
                            <>
                              <tr>
                                <td>
                                  When was the skin cancer that was not removed
                                  first detected?
                                </td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .whenWasCancerNotRemoved
                                  }
                                </td>
                              </tr>
                              {props.formData.formSixteen
                                .whenWasCancerNotRemoved === "yes" ? (
                                <tr>
                                  <td>Non-removed cancer details</td>
                                  <td>
                                    {
                                      props.formData.formSixteen
                                        .notRemovedDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "cancerOtherThanSkin" ? (
                        <tbody>
                          <tr>
                            <td>Other cancer details</td>
                            <td>
                              {
                                props.formData.formSixteen
                                  .CancerOtherThanSkinDetails
                              }
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "cyst" ? (
                        <tbody>
                          <tr>
                            <td>Was the cyst on the skin only?</td>
                            <td>
                              {formatFormData(
                                props.formData.formSixteen.onTheSkinOnlyYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formSixteen.onTheSkinOnlyYes ===
                          "yes" ? (
                            <>
                              <tr>
                                <td>
                                  Was any other treatment required other than
                                  removal, drainage or antibiotics?
                                </td>
                                <td>
                                  {formatFormData(
                                    props.formData.formSixteen
                                      .CystAnyOtherTreatmentYes
                                  )}
                                </td>
                              </tr>
                              {props.formData.formSixteen
                                .CystAnyOtherTreatmentYes === "yes" ? (
                                <tr>
                                  <td>Other treatment details</td>
                                  <td>
                                    {
                                      props.formData.formSixteen
                                        .CystOtherTreatmentDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              Have there been further recurrences of this cyst?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formSixteen
                                  .recurrencesOfThisCystYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formSixteen
                            .recurrencesOfThisCystYes === "yes" ? (
                            <tr>
                              <td>Recurrence details</td>
                              <td>
                                {
                                  props.formData.formSixteen
                                    .recurrencesOfThisCystDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "breastCyst" ? (
                        <tbody>
                          <tr>
                            <td>How many Breast lumps have you had?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formSixteen.howManyBreastLumps
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>How was lump treated?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formSixteen.howWasLumpTreated
                              )}
                            </td>
                          </tr>
                          {props.formData.formSixteen.howWasLumpTreated ===
                          "surgery" ? (
                            <>
                              <tr>
                                <td>When were the lumps detected?</td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .surgeryLumpsDetected
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What procedure did you have and when was it
                                  operated on?
                                </td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .surgeryWhenWasItOperatedOn
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.formSixteen.howWasLumpTreated ===
                          "aspirated" ? (
                            <>
                              <tr>
                                <td>When was the lump aspirated?</td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formSixteen
                                      .AspiratedWhenWasTheLumpAspirated
                                  )}
                                </td>
                              </tr>
                              {props.formData.formSixteen
                                .AspiratedWhenWasTheLumpAspirated ===
                              "lessThan5years" ? (
                                <>
                                  <tr>
                                    <td>When were the lumps detected?</td>
                                    <td>
                                      {
                                        props.formData.formSixteen
                                          .AspiratedWhenWereTheLumpsDetected
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      What procedure did you have and when was
                                      it operated on?
                                    </td>
                                    <td>
                                      {
                                        props.formData.formSixteen
                                          .AspiratedWhenWasItOperatedOn
                                      }
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}
                              <tr>
                                <td>Was the lump confirmed as benign?</td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .AspiratedLumpConfirmedYes
                                  }
                                </td>
                              </tr>
                              {props.formData.formSixteen
                                .AspiratedLumpConfirmedYes === "yes" ? (
                                <tr>
                                  <td>Benign lump details</td>
                                  <td>
                                    {
                                      props.formData.formSixteen
                                        .AspiratedLumpConfirmedDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              <tr>
                                <td>
                                  Have your results of subsequent mammograms all
                                  been normal?
                                </td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .AspiratedSubsequentMammogramsYes
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.formSixteen.howWasLumpTreated ===
                            "noTreatment" ||
                          props.formData.formSixteen.howWasLumpTreated ===
                            "RegularCheckups" ? (
                            <>
                              <tr>
                                <td>When were the lumps detected?</td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .regularCheckupsLumpsDetected
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What procedure did you have and when was it
                                  operated on?
                                </td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .regularCheckupsWhenWasItOperatedOn
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Have your results of subsequent mammograms all
                                  been normal?
                                </td>
                                <td>
                                  {
                                    props.formData.formSixteen
                                      .regularCheckupsSubsequentMammogramsYes
                                  }
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "tumourOfAnyKind" ? (
                        <tbody>
                          <tr>
                            <td>When was the tumour detected?</td>
                            <td>
                              {
                                props.formData.formSixteen
                                  .tumourWhenTumourDetected
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What treatment was recommended?</td>
                            <td>
                              {props.formData.formSixteen.tumourWhatTreatment}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Please provide any other details pertaining to
                              this tumour?
                            </td>
                            <td>
                              {props.formData.formSixteen.tumourAnyOtherDetails}
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "growth" ? (
                        <tbody>
                          <tr>
                            <td>When was the growth detected?</td>
                            <td>
                              {
                                props.formData.formSixteen
                                  .growthWhenTumourDetected
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What treatment was recommended?</td>
                            <td>
                              {props.formData.formSixteen.growthWhatTreatment}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Please provide any other details pertaining to
                              this growth?
                            </td>
                            <td>
                              {props.formData.formSixteen.growthAnyOtherDetails}
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "lump" ? (
                        <tbody>
                          <tr>
                            <td>When was the lump detected?</td>
                            <td>
                              {
                                props.formData.formSixteen
                                  .lumpWhenTumourDetected
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What treatment was recommended?</td>
                            <td>
                              {props.formData.formSixteen.lumpWhatTreatment}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Please provide any other details pertaining to
                              this lump?
                            </td>
                            <td>
                              {props.formData.formSixteen.lumpAnyOtherDetails}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default CancerTab;
