import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useStyles } from "./classes";
import { Box, Typography } from "@material-ui/core";
import axios from "../../lib/axios";
// import PaypalButton from "../paypal/PaypalButton";

export default function Planpage() {
  const classes = useStyles();
  const [packs, setPacks] = useState([]);

  const fetchData = async () => {
    const pack = await axios.get("/package/package-web");
    console.log(process.env.REACT_APP_BASE_URL);
    setPacks(pack.data);
  };
  // const tranSuccess = async (payment) => {
  //   // const { paymentID, address } = payment;
  // };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Typography className={classes.header} id="pricing">
        Plans and Pricing
      </Typography>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around" style={{ padding: 60 }}>
            <Grid item alignself="center">
              <Paper className={classes.paper}>
                <Box className={classes.box}>
                  <Typography className={classes.text}>Free trial*</Typography>
                  <Typography className={classes.paragraph}>
                    Your First 5 Pre-Assessments are on us!
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    1. Create an Account
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    2. Upload your company logo so your pre-assessments are sent
                    from your company
                  </Typography>
                  <Typography className={classes.paragraph1}>
                    3. Submit your first five clients for pre-assessment to see
                    how easy the process is!
                  </Typography>
                  <Typography className={classes.paragraph2}>
                    *1 free trial per AR code
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            {packs.map((pack) => (
              <Grid item key={pack.id}>
                <Paper className={classes.paper}>
                  <Box className={classes.box}>
                    <Typography className={classes.text}>
                      {pack.name}
                    </Typography>
                    <Typography className={classes.paragraph}>
                      {pack.description}
                    </Typography>
                    <Typography className={classes.paragraph3}>
                      ${pack.price}.00 for {pack.numberOfClients} client
                    </Typography>
                    <Typography className={classes.paragraph4}>
                      pre-assessments
                    </Typography>
                    {/* <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 40,
                    }}
                  >
                    <PaypalButton total={150} tranSuccess={tranSuccess} />
                  </Box> */}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
