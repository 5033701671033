import React from "react";
import { Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const GeneralTab = (props) => {
  return (
    <Table
      id="form-view-general-table"
      striped
      bordered
      responsive
      className="m-0"
    >
      <thead>
        <tr className="border-0">
          <th id="question-heading" className="border-0 p-0"></th>
          <th id="answer-heading" className="border-0 p-0"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Gender</td>
          <td>
            {props.clientData.gender ? props.clientData.gender : "Loading..."}
          </td>
        </tr>
        <tr>
          <td>Age</td>
          <td>{props.clientData.dob ? props.clientAge : "Loading..."}</td>
        </tr>
        <tr>
          <td>Occupation</td>
          <td>
            {props.clientData.occupation
              ? props.clientData.occupation
              : "Loading..."}
          </td>
        </tr>
        <tr>
          <td>Height</td>
          <td>
            {props.formData.formOne
              ? `${props.formData.formOne.height}cm`
              : "Loading..."}
          </td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>
            {props.formData.formOne
              ? `${props.formData.formOne.weigth}kg`
              : "Loading..."}
          </td>
        </tr>
        <tr>
          <td>BMI</td>
          <td>{props.clientBMI}</td>
        </tr>
        <tr>
          <td>
            Have you ever been declined or accepted on special terms for your
            life, disability or trauma insurance cover?
          </td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.disability === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.disability === "true" && (
            <tr>
              <td>
                Why was cover declined or what special terms were offered?
              </td>
              <td>{props.formData.formOne.disabilityYes}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>
            Have you ever claimed from any source (excluding unemployment) eg:
            accident, sickness, workers compensation, disability pension or
            income protection?
          </td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.claimed === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.claimed === "true" && (
            <tr>
              <td>
                Provide details of claimed benefits, including the date when
                claimed
              </td>
              <td>{props.formData.formOne.claimedYes}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>
            In the last 12 months have you smoked tobacco or any other substance
            including e-cigarettes?
          </td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.smoker === "true"
                ? "Yes"
                : props.formData.formOne.smoker === "false"
                ? "No"
                : formatFormData(props.formData.formOne.smoker)
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.smoker === "true" && (
            <tr>
              <td>
                Please provide substance and details of quantity eg:- 4
                cigarettes a day, E-cigarettes
              </td>
              <td>{props.formData.formOne.smokerQuantity}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        {props.formData.formOne ? (
          props.formData.formOne.smoker === "false" && (
            <tr>
              <td>When did you last smoke?</td>
              <td>{props.formData.formOne.lastSmoke}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>How many standard drinks would you have per week on average?</td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.standardDrinks
              : "Loading..."}
          </td>
        </tr>
        <tr>
          <td>
            Have you ever used illicit drugs or received advice, treatment or
            counselling for the use of alcohol or illicit drugs?
          </td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.illicitDrugs === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.illicitDrugs === "true" && (
            <tr>
              <td>
                Provide details of the substance and the date of when you last
                used it?
              </td>
              <td>{props.formData.formOne.illicitDrugsYes}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        {props.formData.formOne ? (
          props.formData.formOne.pregnant !== "" && (
            <tr>
              <td>Are you pregnant?</td>
              <td>
                {props.formData.formOne.pregnant === "true" ? "Yes" : "No"}
              </td>
            </tr>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>Do you have any definite plans to travel or reside overseas?</td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.overseas === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.overseas === "true" && (
            <>
              <tr>
                <td>What is the city that you are travelling to?</td>
                <td>{props.formData.formOne.overseasCity}</td>
              </tr>
              <tr>
                <td>How long do you intend to be away?</td>
                <td>{props.formData.formOne.overseasDuration}</td>
              </tr>
              <tr>
                <td>What is the reason for travel</td>
                <td>{props.formData.formOne.overseasReason}</td>
              </tr>
            </>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>Have you travelled outside of Australia in the past month?</td>
          <td>
            {props.formData.formOne
              ? props.formData.formOne.travelOutside === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formOne ? (
          props.formData.formOne.travelOutside === "true" && (
            <tr>
              <td>Where did you travel to and for how long were you away?</td>
              <td>{props.formData.formOne.travelOutsideYes}</td>
            </tr>
          )
        ) : (
          <></>
        )}
        <tr>
          <td>Have you been Covid-19 tested?</td>
          <td>
            {props.formData.formTwo
              ? props.formData.formTwo.covid19 === "true"
                ? "Yes"
                : "No"
              : "Loading..."}
          </td>
        </tr>
        {props.formData.formTwo ? (
          props.formData.formTwo.covid19 === "true" && (
            <tr>
              <td>What was the result of the test?</td>
              <td>
                {props.formData.formTwo.covid19Result === "true"
                  ? "Positive"
                  : "Negative"}
              </td>
            </tr>
          )
        ) : (
          <></>
        )}
        {props.formData.formTwo ? (
          props.formData.formTwo.covid19 === "true" && (
            <tr>
              <td>Why did you have the test?</td>
              <td>
                <ul>
                  {props.formData.formTwo.covidList.map((listItem) => (
                    <li>
                      {listItem
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()[0]
                        .toUpperCase() +
                        listItem
                          .replace(/([A-Z]+)/g, " $1")
                          .trim()
                          .substring(1)}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          )
        ) : (
          <></>
        )}
      </tbody>
    </Table>
  );
};

export default GeneralTab;
