import React, { useState } from "react";
import { Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubForm22 from "./subForms/SubForm22";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import styles from "../../styles/form.module.css";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormTwentyTwo() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    //SubForm22
    consultations: [],
    chiropractor: "",
    osteopath: "",
    physiotherapist: "",
    acupuncturist: "",

    isDisclosed: "",
    whichBodyPartconsultant: [],
    consultantDetails: "",

    noneOfTheAbove: false,
  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    //SubForm22

    isDisclosed: Yup.string().when("consultations", {
      is: (val) => val.length > 0,
      then: Yup.string().required(),
    }),
    whichBodyPartconsultant: Yup.string().when("isDisclosed", {
      is: "false",
      then: Yup.string().required(),
    }),
    consultantDetails: Yup.string().when("whichBodyPartconsultant", {
      is: (val) => val.length > 0,
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    noneOfTheAbove: Yup.string().when('consultations', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });

  const submit = async (e) => {
    try {
      await axios.put("/form/formtwentytwo", {
        formTwentyTwo: e,
        pointer: 23,
        formid: id,
      });
      dispatch(setPage(23));
    } catch (error) {}
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched , resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ marginBottom: "6.5rem", padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you consulted any of the following?
                      </p>
                      <SubForm22
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <NoneOfTheAbove
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        exceptionArray={exceptionArray}
                        setLoading={setLoading}
                        loading={loading}
                        resetForm={resetForm}
                        setErrorsFun={() => setErrors({})}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
