import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const WomensHealthTab = (props) => {
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-womens-health-tabs"
          defaultActiveKey="#womens-health-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formTwentyOne.illnessWomen &&
                props.formData.formTwentyOne.illnessWomen[0] !== "" &&
                props.formData.formTwentyOne.illnessWomen.map(
                  (illness, index) => (
                    <ListGroup.Item action href={`#womens-health-${index + 1}`}>
                      {illness
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()[0]
                        .toUpperCase() +
                        illness
                          .replace(/([A-Z]+)/g, " $1")
                          .trim()
                          .substring(1)}
                    </ListGroup.Item>
                  )
                )}
              {props.formData.formTwentyOne.illness &&
                props.formData.formTwentyOne.illness.map((illness, index) => (
                  <ListGroup.Item action href={`#womens-health-${index + 10}`}>
                    {illness
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      illness
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1)}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formTwentyOne.illnessWomen &&
                props.formData.formTwentyOne.illnessWomen[0] !== "" &&
                props.formData.formTwentyOne.illnessWomen.map(
                  (illness, index) => (
                    <Tab.Pane eventKey={`#womens-health-${index + 1}`}>
                      <Table
                        id="form-view-womens-health-table"
                        striped
                        bordered
                        responsive
                      >
                        <thead>
                          <tr className="border-0">
                            <th
                              id="question-heading"
                              className="border-0 p-0"
                            ></th>
                            <th
                              id="answer-heading"
                              className="border-0 p-0"
                            ></th>
                          </tr>
                        </thead>
                        {illness === "abnormalMammogram" ? (
                          <tbody>
                            <tr>
                              <td>When was the abnormal mammogram?</td>
                              <td>
                                {
                                  props.formData.formTwentyOne
                                    .whnAbnormalMammogram
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Did you require further investigations? (biopsy
                                or aspiration)
                              </td>
                              <td>
                                {props.formData.formTwentyOne
                                  .reqBiopsyOrAspiration
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formTwentyOne
                              .reqBiopsyOrAspiration ? (
                              <>
                                <tr>
                                  <td>
                                    What procedure did you have and when was it
                                    operated on?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formTwentyOne
                                        .proceBiopsyOrAspiration
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Was the lump confirmed as benign?</td>
                                  <td>
                                    {props.formData.formTwentyOne
                                      .isLumpConfirmed
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                {props.formData.formTwentyOne
                                  .isLumpConfirmed ? (
                                  <tr>
                                    <td>Benign lump details</td>
                                    <td>
                                      {props.formData.formTwentyOne.lumpDetails}
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have your results of subsequent mammograms all
                                been normal?
                              </td>
                              <td>
                                {props.formData.formTwentyOne.isMammogramsNormal
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          </tbody>
                        ) : illness === "abnormalBreastUltrasound" ? (
                          <tbody>
                            <tr>
                              <td>When was the abnormal breast ultrasound?</td>
                              <td>
                                {
                                  props.formData.formTwentyOne
                                    .whnBreastUltrasound
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Did you require further investigations? (biopsy
                                or aspiration)
                              </td>
                              <td>
                                {props.formData.formTwentyOne
                                  .reqBiopsyOrAspiration
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formTwentyOne
                              .reqBiopsyOrAspiration ? (
                              <>
                                <tr>
                                  <td>
                                    What procedure did you have and when was it
                                    operated on?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formTwentyOne
                                        .proceBiopsyOrAspiration
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Was the lump confirmed as benign?</td>
                                  <td>
                                    {props.formData.formTwentyOne
                                      .isLumpConfirmed
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                {props.formData.formTwentyOne
                                  .isLumpConfirmed ? (
                                  <tr>
                                    <td>Benign lump details</td>
                                    <td>
                                      {props.formData.formTwentyOne.lumpDetails}
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have your results of subsequent mammograms all
                                been normal?
                              </td>
                              <td>
                                {props.formData.formTwentyOne.isMammogramsNormal
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </Tab.Pane>
                  )
                )}
              {props.formData.formTwentyOne.illness &&
                props.formData.formTwentyOne.illness.map((illness, index) => (
                  <Tab.Pane eventKey={`#womens-health-${index + 10}`}>
                    <Table
                      id="form-view-womens-health-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {illness === "papSmear" ? (
                        <tbody>
                          <tr>
                            <td>
                              What was the diagnosis of the abnormal smear?
                            </td>
                            <td>
                              {props.formData.formTwentyOne.diagAbnormalSmear}
                            </td>
                          </tr>
                          <tr>
                            <td>How long ago was your last abnormal smear?</td>
                            <td>
                              {
                                props.formData.formTwentyOne
                                  .huwLongAbnormalSmear
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How many normal follow up smears have you had?
                            </td>
                            <td>
                              {
                                props.formData.formTwentyOne
                                  .followupAbnormalSmear
                              }
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "abnormalityOvaries" ? (
                        <tbody>
                          <tr>
                            <td>
                              When was the ovarian abnormality first detected?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTwentyOne
                                  .whenAbnormalityOvaries
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>What was the diagnosis?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTwentyOne
                                  .diagAbnormalityOvaries
                              )}
                            </td>
                          </tr>
                          {props.formData.formTwentyOne
                            .diagAbnormalityOvaries === "simpleCyst" ||
                          props.formData.formTwentyOne
                            .diagAbnormalityOvaries === "fibroid" ? (
                            <>
                              <tr>
                                <td>
                                  Did you require further investigations?
                                  (biopsy or surgery)
                                </td>
                                <td>
                                  {props.formData.formTwentyOne
                                    .reqBiopsyOrSurgery
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                              {props.formData.formTwentyOne
                                .reqBiopsyOrSurgery ? (
                                <>
                                  <tr>
                                    <td>
                                      What procedure did you have and when was
                                      it operated on?
                                    </td>
                                    <td>
                                      {
                                        props.formData.formTwentyOne
                                          .whatOperatedProcedure
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Was the cyst confirmed as benign?</td>
                                    <td>
                                      {props.formData.formTwentyOne
                                        .wasCystConfirmed
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  </tr>
                                  {props.formData.formTwentyOne
                                    .wasCystConfirmed ? (
                                    <tr>
                                      <td>Benign cyst details</td>
                                      <td>
                                        {
                                          props.formData.formTwentyOne
                                            .confirmedCystDetails
                                        }
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {props.formData.formTwentyOne
                            .diagAbnormalityOvaries === "other" ? (
                            <tr>
                              <td>Please provide details</td>
                              <td>
                                {
                                  props.formData.formTwentyOne
                                    .otherAbnormalityOvaries
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "abnormalVaginalBleeding" ? (
                        <tbody>
                          <tr>
                            <td>
                              Have you consulted a doctor and had the symptoms
                              investigated?
                            </td>
                            <td>
                              {props.formData.formTwentyOne
                                .consultAbnormalVaginalBleeding
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          {props.formData.formTwentyOne
                            .consultAbnormalVaginalBleeding ? (
                            <>
                              <tr>
                                <td>What was the cause of the symptoms?</td>
                                <td>
                                  {formatFormData(
                                    props.formData.formTwentyOne
                                      .causeAbnormalVaginalBleeding
                                  )}
                                </td>
                              </tr>
                              {props.formData.formTwentyOne
                                .causeAbnormalVaginalBleeding === "fibroids" ? (
                                <>
                                  <tr>
                                    <td>
                                      Did you require further investigations?
                                      (biopsy or surgery)
                                    </td>
                                    <td>
                                      {props.formData.formTwentyOne
                                        .reqFibroidsInvestigation
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  </tr>
                                  {props.formData.formTwentyOne
                                    .reqFibroidsInvestigation ? (
                                    <tr>
                                      <td>
                                        What procedure did you have and when was
                                        it operated on?
                                      </td>
                                      <td>
                                        {
                                          props.formData.formTwentyOne
                                            .proceFibroidsInvestigation
                                        }
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {props.formData.formTwentyOne
                                .causeAbnormalVaginalBleeding === "IUD" ? (
                                <>
                                  <tr>
                                    <td>
                                      Have your symptoms been treated and have
                                      you made a full recovery?
                                    </td>
                                    <td>
                                      {props.formData.formTwentyOne
                                        .isRecoveredIUD
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  </tr>
                                  {props.formData.formTwentyOne
                                    .isRecoveredIUD ? (
                                    <></>
                                  ) : (
                                    <tr>
                                      <td>Symptoms details</td>
                                      <td>
                                        {
                                          props.formData.formTwentyOne
                                            .iudRecoverdNoDetails
                                        }
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {props.formData.formTwentyOne
                                .causeAbnormalVaginalBleeding === "polyps" ||
                              props.formData.formTwentyOne
                                .causeAbnormalVaginalBleeding === "other" ? (
                                <tr>
                                  <td>Cause details</td>
                                  <td>
                                    {
                                      props.formData.formTwentyOne
                                        .otherOrPolypsDetails
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <tr>
                              <td>
                                Please provide details including symptoms and
                                commencement date
                              </td>
                              <td>
                                {
                                  props.formData.formTwentyOne
                                    .notConsultedDetails
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      ) : illness === "endometriosis" ? (
                        <tbody>
                          <tr>
                            <td>How long have you been symptom free?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTwentyOne
                                  .symFreeEndometriosis
                              )}
                            </td>
                          </tr>
                          {props.formData.formTwentyOne.symFreeEndometriosis ===
                          "lt3MonthsAgo" ? (
                            <>
                              <tr>
                                <td>When were you diagnosed?</td>
                                <td>
                                  {
                                    props.formData.formTwentyOne
                                      .whenDiagEndometriosis
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What was the treatment that was recommended by
                                  a medical professional?
                                </td>
                                <td>
                                  {
                                    props.formData.formTwentyOne
                                      .treatEndometriosis
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Is there any ongoing treatment?</td>
                                <td>
                                  {props.formData.formTwentyOne
                                    .isOngTreatEndometriosis
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                              {props.formData.formTwentyOne
                                .isOngTreatEndometriosis ? (
                                <tr>
                                  <td>Treatment details</td>
                                  <td>
                                    {
                                      props.formData.formTwentyOne
                                        .detailsEndometriosis
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default WomensHealthTab;
