import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";
const resetArray = [
  { name: "anaemiaCheck", type: "" },
  { name: "anaemOtherTreat", type: "" },
  { name: "anaemiaDetails", type: "" },
];
export default function FormAnemia({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Anaemia", value: "anaemia" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("anaemia") && (
        <Fragment>
          <YesNoQuestion
            question="Has your anaemia been diagnosed as iron deficient anaemia?"
            name="anaemiaCheck"
            isSubForm
          />
          <YesNoQuestion
            question="Is there any other treatment required other than iron tablets or diet management?"
            name="anaemOtherTreat"
            isSubForm
          />
          {values.anaemOtherTreat === "true" && (
            <AnswerBox
              name="anaemiaDetails"
              label="Please provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
