import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioGroup from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const annualAttakcs = [
  {
    label: "More than 12",
    value: "moreThanTwelve",
  },
  {
    label: "Less than 12",
    value: "lessThanTwelve",
  },
];
const lastAttack = [
  {
    label: "Less than 3 years ago",
    value: "lessThanThreeYears",
  },
  {
    label: "3-7 years ago",
    value: "threeToSevenYears",
  },
  {
    label: "More than 7 Years ago",
    value: "moreThanSevenYears",
  },
];
const cause = [
  {
    label: "Head trauma occuring in the last 2 years",
    value: "headTraumaless",
  },
  {
    label: "Head trauma occured more than 2 years ago",
    value: "headTraumamore",
  },
  {
    label: "Tumour",
    value: "tumour",
  },
  {
    label: "None of the above",
    value: "none",
  },
];

const resetArray = [
  { name: "annualAttakcs", type: "" },
  { name: "lastAttack", type: "" },
  { name: "cause", type: "" },
  { name: "dutyRestrictions", type: "" },
  { name: "details", type: "" },
];
export default function EpilepsyPetitMal({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Epilepsy - Petit Mal", value: "epilepsymal" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.symptoms.includes("epilepsymal") && (
        <RadioGroup
          question="How many attacks do you suffer annually on average?"
          name="annualAttakcs"
          items={annualAttakcs}
          isSubForm
        />
      )}
      {values.annualAttakcs && (
        <RadioGroup
          question="When was your last attack?"
          name="lastAttack"
          items={lastAttack}
          isSubForm
        />
      )}
      {values.lastAttack && (
        <RadioGroup
          question="Specify the cause of epilepsy"
          name="cause"
          items={cause}
          isSubForm
        />
      )}
      {values.lastAttack && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="dutyRestrictions"
          isSubForm
        />
      )}
      {values.dutyRestrictions === "true" && (
        <AnswerBox 
          label="Please Provide Details"
          name="details"
          isSubForm
        />
      )}
    </Fragment>
  );
}
