import React, { useEffect, useState } from "react";
import axios from "axios";

// Bootstrap component imports
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import PageItem from "react-bootstrap/PageItem";
import Tooltip from "@material-ui/core/Tooltip";

// Custom component imports
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import Footer from "../../components/Footer/Footer";
import MobileViewport from "../mobileViewport/MobileViewport";
import LoadingSpinner from "../../components/layouts/LoadingSpinner";
import AssessmentFullDetailsModal from "../../components/modals/AssessmentFullDetailsModal";

// Utilities imports
import formatDate from "../../utils/formatDate";

// Icons
import SpeakerNotesRoundedIcon from "@mui/icons-material/SpeakerNotesRounded";
import ReplyAllRoundedIcon from "@mui/icons-material/ReplyAllRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

// Custom assessment status page CSS
import "./assessmentStatus.css";

const AssessmentStatus = () => {
  const [assessments, setAssessments] = useState([]);
  const [assessmentsSlice, setAssessmentsSlice] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [assessmentsPerPage, setAssessmentsPerPage] = useState(12);
  const [paginationItems, setPaginationItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  // Fetch logged in advisor's client assessment data and set in state
  const fetchAssessmentData = async () => {
    const response = await axios
      .get("/auth/client2")
      .catch((err) => console.log(err));
    if (response) {
      setAssessments(response.data);
    }
  };

  // Slice assessments array for table based on assessmentsPerPage value
  const sliceAssessments = () => {
    let assessmentArray = assessments.slice(
      paginationPage * assessmentsPerPage - assessmentsPerPage,
      paginationPage * assessmentsPerPage
    );

    // Add pre assessment status to each assessment object in sliced array
    for (
      let arrayCount = 0;
      arrayCount < assessmentArray.length;
      arrayCount++
    ) {
      if (assessmentArray[arrayCount].isRes) {
        assessmentArray[arrayCount].status = "Underwriter replies received";
      } else if (assessmentArray[arrayCount].isShare) {
        assessmentArray[arrayCount].status = "Awaiting underwriter replies";
      } else if (assessmentArray[arrayCount].isSubmit) {
        assessmentArray[arrayCount].status = "Assessment received";
      } else {
        assessmentArray[arrayCount].status = "Awaiting client response";
      }
    }

    setAssessmentsSlice(assessmentArray);

    // Pagination configuration
    let items = [];
    for (
      let pageNum = 1;
      pageNum <= Math.ceil(assessments.length / assessmentsPerPage);
      pageNum++
    ) {
      items.push(
        <PageItem
          key={pageNum}
          active={pageNum === paginationPage}
          onClick={() => {
            setPaginationPage(pageNum);
            handleClickableRow(); // Recreate event listeners for new set of table rows
          }}
        >
          {pageNum}
        </PageItem>
      );
    }
    setPaginationItems(items);
  };

  const handleClickableRow = () => {
    const rows = document.getElementsByClassName("assessments-data-row");
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].classList.contains("expanded")) {
        rows[i].classList.remove("expanded");
        rows[i].classList.add("collapsed");
      }
      rows[i].addEventListener("click", (e) => {
        console.log(e);
        if (
          e.target.type === "button" ||
          e.target.parentNode.type === "button" ||
          e.target.parentNode.parentNode.type === "button"
        ) {
          return; // No row expansion if child button is clicked
        } else {
          if (rows[i].classList.contains("collapsed")) {
            rows[i].classList.remove("collapsed");
            rows[i].classList.add("expanded");
          } else if (rows[i].classList.contains("expanded")) {
            rows[i].classList.remove("expanded");
            rows[i].classList.add("collapsed");
          }
          for (let j = 0; j < rows.length; j++) {
            if (i !== j && rows[j].classList.contains("expanded")) {
              rows[j].classList.remove("expanded");
              rows[j].classList.add("collapsed");
            }
          }
        }
      });
    }
  };

  const handleModalShow = (assessment) => {
    setModalData(assessment);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  useEffect(() => {
    fetchAssessmentData();
  }, []);

  useEffect(() => {
    sliceAssessments();
  }, [assessments, paginationPage]);

  useEffect(() => {
    handleClickableRow();
  }, [assessmentsSlice]);

  return (
    <>
      <AssessmentFullDetailsModal
        modalShow={modalShow}
        handleModalShow={handleModalShow}
        handleModalClose={handleModalClose}
        modalData={modalData}
      />
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="h-100">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col className="p-4">
                <Heading heading="View assessments" />
                <Col xs={12}>
                  <Card>
                    {assessmentsSlice.length === 0 ? (
                      <Card.Body
                        className="d-flex flex-column justify-content-center align-items-center p-0"
                        style={{ height: "37.5rem" }}
                      >
                        <LoadingSpinner />
                      </Card.Body>
                    ) : (
                      <>
                        <Card.Body className="d-flex flex-column justify-content-between p-0">
                          <Table
                            id="assessments-table"
                            className="m-0"
                            striped
                            hover
                          >
                            <thead>
                              <tr style={{ verticalAlign: "top" }}>
                                <th
                                  className="ps-3"
                                  style={{ width: "16.66%" }}
                                >
                                  Client
                                </th>
                                <th style={{ width: "16.66%" }}>
                                  Client Email
                                </th>
                                <th style={{ width: "16.66%" }}>Mobile</th>
                                <th style={{ width: "25%" }}>Status</th>
                                <th style={{ width: "25%" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assessmentsSlice.map((assessment) => (
                                <tr className="assessments-data-row collapsed">
                                  <td colSpan="5">
                                    <Container
                                      fluid
                                      className="assessments-data-container m-0 overflow-hidden"
                                    >
                                      <Row>
                                        <Col
                                          className="d-flex align-items-center text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          {assessment.client.name}
                                        </Col>

                                        <Col
                                          className="d-flex align-items-center text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          {assessment.client.email}
                                        </Col>

                                        <Col
                                          className="d-flex align-items-center text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          {assessment.client.mobile}
                                        </Col>

                                        <Col
                                          className="d-flex align-items-center text-nowrap overflow-hidden"
                                          xs="3"
                                        >
                                          {assessment.status}
                                        </Col>

                                        <Col
                                          className="d-flex justify-content-end gap-2 text-nowrap overflow-hidden"
                                          xs="3"
                                        >
                                          <Tooltip
                                            title="View full details"
                                            placement="top"
                                            arrow
                                          >
                                            <Button
                                              variant="primary"
                                              className="py-2 px-3 d-flex align-items-center justify-content-center"
                                              onClick={() => {
                                                handleModalShow(assessment);
                                              }}
                                            >
                                              <InfoRoundedIcon fontSize="small" />
                                            </Button>
                                          </Tooltip>

                                          {/* Disable buttons when status dictates that the links are not ready */}
                                          {assessment.status ===
                                          "Awaiting client response" ? (
                                            <Tooltip
                                              title="View assessment"
                                              placement="top"
                                              arrow
                                            >
                                              <Button
                                                variant="secondary"
                                                className="py-2 px-3 d-flex align-items-center justify-content-center pe-auto"
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                                disabled
                                              >
                                                <SpeakerNotesRoundedIcon fontSize="small" />
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            <a
                                              href={
                                                "/formview/" + assessment.formid
                                              }
                                            >
                                              <Tooltip
                                                title="View assessment"
                                                placement="top"
                                                arrow
                                              >
                                                <Button
                                                  variant="primary"
                                                  className="py-2 px-3 d-flex align-items-center justify-content-center"
                                                >
                                                  <SpeakerNotesRoundedIcon fontSize="small" />
                                                </Button>
                                              </Tooltip>
                                            </a>
                                          )}

                                          {assessment.status ===
                                          "Underwriter replies received" ? (
                                            <a
                                              href={
                                                "/view-underwriter-replies/" +
                                                assessment.formid
                                              }
                                            >
                                              <Tooltip
                                                title="View underwriter replies"
                                                placement="top"
                                                arrow
                                              >
                                                <Button
                                                  variant="success"
                                                  className="py-2 px-3 d-flex align-items-center justify-content-center"
                                                >
                                                  <ReplyAllRoundedIcon fontSize="small" />
                                                </Button>
                                              </Tooltip>
                                            </a>
                                          ) : (
                                            <Tooltip
                                              title="View underwriter replies"
                                              placement="top"
                                              arrow
                                            >
                                              <Button
                                                variant="secondary"
                                                className="py-2 px-3 d-flex align-items-center justify-content-center pe-auto"
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                                disabled
                                              >
                                                <ReplyAllRoundedIcon fontSize="small" />
                                              </Button>
                                            </Tooltip>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="expanded-data">
                                        <Col
                                          className="text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          <small>
                                            <strong
                                              style={{ whiteSpace: "pre" }}
                                            >
                                              Assessment created:{"\n"}
                                            </strong>
                                            {formatDate(assessment.createdAt)}
                                          </small>
                                        </Col>

                                        <Col
                                          className="text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          <small>
                                            <strong
                                              style={{ whiteSpace: "pre" }}
                                            >
                                              Client submitted:{"\n"}
                                            </strong>
                                            {assessment.dateClientSubmitted
                                              ? formatDate(
                                                  assessment.dateClientSubmitted
                                                )
                                              : "N/A"}
                                          </small>
                                        </Col>

                                        <Col
                                          className="text-nowrap overflow-hidden"
                                          xs="2"
                                        >
                                          <small>
                                            <strong
                                              style={{ whiteSpace: "pre" }}
                                            >
                                              Sent to underwriter(s):{"\n"}
                                            </strong>
                                            {assessment.dateSentToUnderwriters
                                              ? formatDate(
                                                  assessment.dateSentToUnderwriters
                                                )
                                              : "N/A"}
                                          </small>
                                        </Col>


                                        <Col
                                          className="text-nowrap overflow-hidden"
                                          xs="4"
                                        ></Col>
                                      </Row>
                                    </Container>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </>
                    )}
                  </Card>
                  <Pagination className="d-flex align-content-center justify-content-center my-3">
                    {paginationItems}
                  </Pagination>
                </Col>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>{" "}
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default AssessmentStatus;
