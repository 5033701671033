import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const resetArray = [
  { name: "dermatitisAnyRestricted", type: "" },
  { name: "dermatitisRestrictedYesDetails", type: "" },
  { name: "dermatitisConditionTriggered", type: "" },
  { name: "dermatitisConditionTriggeredYesDetails", type: "" },
];
export default function SubFormTwo({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Dermatitis", value: "dermatitis" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("dermatitis") && (
        <RadioMulti
          question="Are you in any way restricted in your occupation or daily activities as a result of this dermatitis?"
          name={"dermatitisAnyRestricted"}
          items={items}
          isSubForm
        />
      )}

      {values.subFormsOfFormFifteen.includes("dermatitis") &&
        values.dermatitisAnyRestricted === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="dermatitisRestrictedYesDetails"
            isSubForm
          />
        )}

      {(values.dermatitisRestrictedYesDetails !== "" ||
        values.dermatitisAnyRestricted === "no") &&
        values.subFormsOfFormFifteen.includes("dermatitis") && (
          <RadioMulti
            question="Is your condition triggered or aggravated by substances used at your job?"
            name={"dermatitisConditionTriggered"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("dermatitis") &&
        values.dermatitisConditionTriggered === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="dermatitisConditionTriggeredYesDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
