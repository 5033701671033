import React, { useState } from "react";
import { Grid, Typography, Box, Button, Snackbar } from "@material-ui/core";
import { useStyles } from "./classes";
import { Formik, Form, Field } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import Img1 from "../../assets/images/image10.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { TextField } from "formik-material-ui";
import { TextField as TF } from "@mui/material/";
import { useHistory } from "react-router-dom";
import enAuLocale from "date-fns/locale/en-AU";

import axios from "../../lib/axios";

export default function Security(props) {
  const classes = useStyles();
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem("next"));
  let user2 = JSON.parse(localStorage.getItem("profile"));
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  if (user.arNumber === "") {
    const path = "/signup";
    props.history.push(path);
  }
  let initialValues = {
    dob: null,
    mobile: "",
    madenName: "",
    psa: "",
  };

  let SignUpSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Mobile Number is required.")
      .matches(
        /^(\+61|0)4\d{8}$/g,
        "Please enter valid Australian mobile phone number (digits only)"
      ),
    madenName: Yup.string()
      .required("Mothers Maiden Name is required!")
      .max(35, "Max length for the Mothers Maiden Name is 35."),
    psa: Yup.string()
      .required("Primary School Attended is required!")
      .max(150, "Max length for the Mothers Primary School Attended is 150."),
    dob: Yup.date().required("Date of Birth is required!"),
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    console.log(user);
    console.log(user2);
    console.log(e);
    try {
      const data = await axios.post("/auth/register", {
        firstName: user2.name,
        email: user2.email,
        arNumber: user.arNumber,
        adviceFirm: user.adFirm,
        dealerGroup: user.group,
        stateResidence: user.state,
        // contactNumber: user.contactNumber,
        postalAddress: user.postalAddress,
        logo: user.logo,
        password: user.password,
        DOB: e.dob,
        mobileNumber: e.mobile,
        madenName: e.madenName,
        PSA: e.psa,
        accountType: "trail",
      });
      localStorage.setItem("newuser", JSON.stringify(data));

      resetForm();
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: "success",
        message:
          "Almost there! Thanks for registering with Pre Assess Me. We are super-excited to have you on board. A verification email was sent successfully. Please check your Spam or Junk folder",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message:
            "Almost there! Thanks for registering with Pre Assess Me. We are super-excited to have you on board. A verification email was sent successfully. Please check your Spam or Junk folder",
        });
        setOpen(false);
        const path = "/payment";
        props.history.push(path);
      }, 12000);
    } catch (error) {
      if (error.response.status === 401) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Unauthorized!",
          });
          setOpen(false);
        }, 3000);
      }
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Check form Validation and retry!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Check form Validation and retry!",
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Server error!",
          });
          setOpen(false);
        }, 3000);
      }
    }
  };

  const resourceDirect = () => {
    history.push("/details");
  };

  return (
    <div>
      <Grid container style={{ paddingRight: "3%" }}>
        <Grid item lg={6} xl={6} className={classes.hide}>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
          <Typography className={classes.tittle}>Security Questions</Typography>
          <Typography className={classes.description}>
            Just incase you ever get
          </Typography>
          <Typography className={classes.description}>
            locked out of your account,
          </Typography>
          <Typography className={classes.description}>
            please provide the following details
          </Typography>
          <Typography className={classes.description}>
            for authentication
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={SignUpSchema}
          >
            {({ dirty, isValid, handleChange, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Date of Birth
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={enAuLocale}
                      >
                        <DatePicker
                          label="Select Date of Birth"
                          value={value}
                          disableOpenPicker
                          maxDate={moment().subtract(18, "year").toDate()}
                          inputProps={{
                            placeholder: "dd/mm/yyyy",
                            format: "dd/mm/yyyy",
                          }}
                          onChange={(newValue) => {
                            setValue(newValue);
                            setFieldValue("dob", newValue);
                          }}
                          renderInput={(params) => (
                            <TF
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                width: "90%",
                              }}
                              fullWidth
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Mobile Number
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="mobile"
                        placeholder="Mobile Number *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Mothers Maiden Name
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="madenName"
                        placeholder="Mothers Maiden Name *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Primary School Attended
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="psa"
                        placeholder="Primary School Attended *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifycontent="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}></Grid>
                    <Grid
                      item
                      lg={8}
                      xl={8}
                      md={8}
                      sm={8}
                      xs={8}
                      justifycontent="space-between"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          className={classes.nextButton}
                          style={{
                            width: "20%",
                            height: 50,
                            fontSize: "1.2.6rem",
                            fontFamily: "Overpass, sans-serif",
                          }}
                          onClick={resourceDirect}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          className={classes.nextButton}
                          style={{
                            width: "20%",
                            height: 50,
                            fontSize: "1.2.6rem",
                            marginRight: 50,
                            fontFamily: "Overpass, sans-serif",
                          }}
                          disabled={!dirty || !isValid}
                          type="submit"
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={3000}
            onClose={() =>
              setAlert({
                ...alert,
                showAlert: false,
              })
            }
          >
            <Alert
              onClose={() => setOpen(false)}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </div>
  );
}
