import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Form as BootstrapForm } from "react-bootstrap-formik";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import "./Preferences.css";
import PreferredUnderwritersModal from "../../components/modals/PreferredUnderwritersModal";
import DashboardToast from "../../components/Toast/DashboardToast";
import Footer from "../../components/Footer/Footer";
import MobileViewport from "../mobileViewport/MobileViewport";

const Preferences = () => {
  const [searchParamsString, setSearchParamsString] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  const loadData = async () => {
    try {
      const data = await axios.get("/auth/me");
      setInitialValues({
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        email: data.data.email,
        logo: data.data.logo,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Display toast message based on URL search params, triggered by setting state
  const getSearchParamsString = () => {
    setSearchParamsString(window.location.search);
  };

  const submitForm = async (e) => {
    console.log(e);
    try {
      await axios.put("/auth/change-profile", {
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        logo: e.logo,
      });
      window.location.href = "/preferences/?userDetailsUpdated=success";
    } catch (error) {
      console.log(error);
      window.location.href = "/preferences/?error=apiRequestFail";
    }
  };

  useEffect(() => {
    loadData();
    getSearchParamsString();
  }, []);

  return (
    <>
      {searchParamsString ? (
        <DashboardToast
          searchParamsString={searchParamsString}
        ></DashboardToast>
      ) : (
        ""
      )}
      
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <PreferredUnderwritersModal
          modalShow={modalShow}
          handleModalShow={handleModalShow}
          handleModalClose={handleModalClose}
        />
        <Row className="h-100">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col className="p-4">
                <Heading heading={"Account preferences"} />
                <Row className="mb-5">
                  {Object.entries(initialValues).length > 0 ? (
                    <>
                      <Col xs={4}>
                        <h5 className="mb-3">Update user details:</h5>
                        <Formik
                          initialValues={initialValues}
                          // validationSchema={preferencesSchema}
                          onSubmit={submitForm}
                        >
                          {(formik) => (
                            <Form
                              id="updateDetailsForm"
                              onSubmit={formik.handleSubmit}
                            >
                              <Row className="g-3 mb-3">
                                <Col xs={12}>
                                  <BootstrapForm.Input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    placeholder="First name"
                                  />
                                </Col>
                                <Col xs={12}>
                                  <BootstrapForm.Input
                                    className="form-control"
                                    name="lastName"
                                    placeholder="Last name"
                                  />
                                </Col>
                                <Col xs={12}>
                                  <BootstrapForm.Input
                                    className="form-control"
                                    disabled
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                  />
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                        <Row>
                          <Col xs={12}>
                            <Button
                              variant="primary"
                              type="submit"
                              form="updateDetailsForm"
                            >
                              Save details
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={8}>
                        <Alert variant="info">
                          <h5>How are your details used?</h5>
                          <p>
                            Your name, as well as your company name and logo are
                            used in correspondence to your clients when a new
                            assessment questionnaire is submitted. Your details
                            are also used in correspondence to the underwriters
                            you select to submit to.
                          </p>
                          <p>
                            You can update your details on this page which will
                            reflect in any correspondence going forward.
                          </p>
                        </Alert>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={4}>
                        <h5 className="mb-3">Update user details:</h5>
                        <Form>
                          <Row className="g-3 mb-3">
                            <Col xs={12}>
                              <Form.Control disabled placeholder="First name" />
                            </Col>
                            <Col xs={12}>
                              <Form.Control disabled placeholder="Last name" />
                            </Col>
                            <Col xs={12}>
                              <Form.Control
                                disabled
                                placeholder="Email address"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <Button disabled>Save details</Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                      <Col xs={8}>
                        <Alert variant="info">
                          <h5>How are your details used?</h5>
                          <p>
                            Your name, as well as your company name and logo,
                            are used in correspondence to your clients when a
                            new assessment questionnaire is submitted. Your
                            details are also used in correspondence to the
                            underwriters you select to submit to.
                          </p>
                          <p>
                            You can update your details on this page which will
                            reflect in any correspondence going forward.
                          </p>
                        </Alert>
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="py-3">
                  <Col xs={4}>
                    <Row>
                      <Col xs={12}>
                        <h5 className="mb-3">Update preferred underwriters:</h5>
                        <Button variant="primary" onClick={handleModalShow}>
                          Open dialog
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={8}>
                    <Alert variant="info">
                      <h5>Preferred underwriters</h5>
                      <p>
                        Your preferred underwriters will display when submitting
                        a client's assessment. You can update your preferred
                        underwriters and add additional email addresses using
                        this menu.
                      </p>
                    </Alert>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default Preferences;
