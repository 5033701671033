import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioGroup from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const abseilingItems = [
  {
    label: "Less than 2",
    value: "Less than 2",
  },
  {
    label: "2-5 times",
    value: "2-5 times",
  },
  {
    label: "5-10 times",
    value: "5-10 times",
  },
  {
    label: "11+ times",
    value: "11+ times",
  },
];

const abseilingEngage = [
  {
    label: "Alone",
    value: "Alone",
  },
  {
    label: "Sometimes alone, sometimes in a group",
    value: "Sometimes alone, sometimes in a group",
  },
  {
    label: "Always in a group",
    value: "Always in a group",
  },
];

const resetArray = [
  { name: "abseilingTimeLastYear", type: "" },
  { name: "abseilingQualifications", type: "" },
  { name: "abseilingHowLong", type: "" },
  { name: "abseilingLocations", type: "" },
  { name: "abseilingEngage", type: "" },
];

export default function Abseiling({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Abseiling", value: "abseiling" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.activities.includes("abseiling") && (
        <RadioGroup
          question="How many times have you abseiled this in the last 12 months?"
          name="abseilingTimeLastYear"
          items={abseilingItems}
          isSubForm
        />
      )}

      {values.activities.includes("abseiling") &&
        values.abseilingTimeLastYear && (
          <AnswerBox
            label="What Qualifications,certificates, licences, or club memberships do you have?"
            name="abseilingQualifications"
            isSubForm
          />
        )}
      {values.activities.includes("abseiling") &&
        values.abseilingTimeLastYear && (
          <AnswerBox
            label="How long have you been abseiling?"
            name="abseilingHowLong"
            isSubForm
          />
        )}
      {values.activities.includes("abseiling") &&
        values.abseilingTimeLastYear && (
          <AnswerBox
            label="Where do you abseil? (locations)"
            name="abseilingLocations"
            isSubForm
          />
        )}

      {values.activities.includes("abseiling") &&
        values.abseilingTimeLastYear && (
          <RadioGroup
            question="Do you engage in abseiling alone or are you always with a group?"
            name="abseilingEngage"
            items={abseilingEngage}
            isSubForm
          />
        )}

      {values.activities.includes("abseiling") && values.abseilingEngage && (
        <AnswerBox 
        label="What is the maximum height?"
        name="abseilingHeight"
        isSubForm
        />
      )}
      {values.activities.includes("abseiling") && values.abseilingHeight && (
        <YesNoQuestion
          question="Are all recognized safety measures and precautions followed?"
          name="abseilingSafety"
          isSubForm
        />
      )}
      {values.activities.includes("abseiling") && values.abseilingHeight && (
        <YesNoQuestion
          question="Have you ever been involved in an accident whilst abseiling? If yes please provide details"
          name="abseilingAccident"
          isSubForm
        />
      )}
      {values.activities.includes("abseiling") &&
        values.abseilingAccident === "true" && (
          <AnswerBox
            label="Please provide any relevant details"
            name="abseilingDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
