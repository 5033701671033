import React from "react";
import { Fragment } from "react";
import RadioMulti from "../RadioMulti";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

export default function FormHepatitisOrHIV({ 
  values, setFieldValue, setFieldTouched }) {
  return (
    <Fragment>
      <RadioMulti
        name="otherIllness"
        question=""
        items={[
          { label: "Hepatitis B", value: "HepatitisB" },
          { label: "Hepititis C", value: "HepatitisC" },
          { label: "Hepatitis B Carrier", value: "HepatitisBCr" },
          { label: "Hepatitis C Carrier", value: "HepatitisCCr" },
          {
            label: "Acquired Immune Deficiency Syndrome (AIDS) or HIV",
            value: "AidsOrHiv",
          },
        ]}
        onClick={() => {
          setFieldValue("noneOfTheAbove", false, false);
          setFieldTouched("noneOfTheAbove", false);
        }}
      />
      {values.otherIllness.length > 0 && (
        <Fragment>
          <AnswerBox name="hepatitisOrHIV" label="When were you diagnosed?" />
          <AnswerBox
            name="treatHepatitisOrHIV"
            label="What was the treatment that was recommended by a medical professional?"
            isSubForm
          />
          <YesNoQuestion
            question="Is there any ongoing treatment"
            name="isOngTreatHepatitisOrHIV"
            isSubForm
          />
          {values.isOngTreatHepatitisOrHIV === "true" && (
            <AnswerBox
              name="detailsHepatitisOrHIV"
              label="Please provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
