import React, { Fragment, useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import axios from "../../lib/axios";
import moment from "moment";

import ReusableTable from "../../components/layouts/ReusableTable";

const headers = [
  {
    text: "Activity",
    value: "activity",
  },
  {
    text: "Date/Time",
    value: "time",
  },
];

export default function Dashboard(props) {
  const [counts, setCounts] = useState({});
  const getCounts = async () => {
    try {
      const data = await axios.get("/analytics/admin");
      setCounts(data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getCounts();
    // eslint-disable-next-line
  }, []);

  const TableB = ({ item, changeStatus, classes, onUpdate }) => {
    return (
      <Fragment>
        <TableCell key="type" style={{ fontFamily: "Overpass, sans-serif" }}>
          {item.eventName === "signup"
            ? `${item.adname} signed up with the system (AR ${item.arNumber})`
            : item.eventName === "underwriter"
            ? `${item.adname} add new underwriter named ${item.pname}`
            : item.eventName === "client"
            ? `${item.adname} add new underwriter named ${item.pname}`
            : `${item.adname} bought ${item.client} clients for $${item.amount}`}
        </TableCell>
        <TableCell
          key="createdAt"
          style={{ fontFamily: "Overpass, sans-serif" }}
        >{`${moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}`}</TableCell>
      </Fragment>
    );
  };
  return (
    <Grid container md={12}>
      <Grid item sm={12} md={12} style={{ backgroundColor: "red" }}>
        <Card>
          <CardContent>
            <Typography
              style={{
                fontFamily: "holiday",
                fontSize: "2rem",
                marginLeft: 15,
                marginBottom: 15,
              }}
            >
              {" "}
              Dashboard
            </Typography>
            <Grid container justifycontent="space-around">
              <Grid
                container
                style={{
                  flexDirection: "column",
                  backgroundImage: "linear-gradient(to top, #5DADE2 , #3498DB)",
                  height: 185,
                  borderRadius: 5,
                  marginTop: 10,
                }}
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                    fontFamily: "Overpass, sans-serif",
                  }}
                >
                  Total Advisors
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                  }}
                >
                  {counts.advisorCount}
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  flexDirection: "column",
                  backgroundImage: "linear-gradient(to top, #58D68D , #2ECC71)",
                  height: 185,
                  borderRadius: 5,
                  marginTop: 10,
                }}
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                    fontFamily: "Overpass, sans-serif",
                  }}
                >
                  Total Clients
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                  }}
                >
                  {counts.clientCount}
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  flexDirection: "column",
                  backgroundImage: "linear-gradient(to top,#F7DC6F ,#F4D03F )",
                  height: 185,
                  borderRadius: 5,
                  marginTop: 10,
                }}
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                    fontFamily: "Overpass, sans-serif",
                  }}
                >
                  Revenue
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: "black",
                    marginLeft: 20,
                    marginTop: 30,
                  }}
                >
                  ${counts.totalRevenue}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontFamily: "holiday",
                fontSize: "2rem",
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              {" "}
              System Activities
            </Typography>
            <ReusableTable
              headers={headers}
              items={[]}
              TableB={TableB}
              fetchDataUrl="/analytics/adminactivities" //change
              statusChangeUrl="/company/update-status"
              updatePath="/company-update"
              {...props}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
