import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";

const resetArray = [
  //ChestPains
  { name: "heartCondition", type: "" },
  { name: "chestPainCon", type: "" },
  { name: "reqHospitalAdmin", type: "" },
  { name: "hospitalTime", type: [] },
  { name: "chestPainCheck", type: "" },
  { name: "chestPainYear", type: "" },
  { name: "chestPainDet", type: "" },
];

export default function FormChestPains({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Chest pains", value: "ChestPains" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("ChestPains") && (
        <Fragment>
          <YesNoQuestion
            question="Was the chest pain related to a heart condition?"
            name="heartCondition"
            isSubForm
          />
          {values.heartCondition === "true" && (
            <AnswerBox
              label="What was the heart condition?"
              name="chestPainCon"
              isSubForm
            />
          )}
          <YesNoQuestion
            question="Did you require hospital admission?"
            name="reqHospitalAdmin"
            isSubForm
          />
          {values.reqHospitalAdmin === "true" && (
            <RadioMulti
              question="How long were you in hospital?"
              name={"hospitalTime"}
              items={[
                {
                  label: "Overnight",
                  value: "overnight",
                },
                {
                  label: "More than one night",
                  value: "More than one night",
                },
              ]}
              isSubForm
            />
          )}
          <YesNoQuestion
            question="Have there been any other instances of chest pains?"
            name="chestPainCheck"
            isSubForm
          />
          <YesNoQuestion
            question="Was the chest pain within the last 12months?"
            name="chestPainYear"
            isSubForm
          />
          {values.chestPainYear === "true" && (
            <AnswerBox
              label="Please provide details"
              name="chestPainDet"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
