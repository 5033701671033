import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubFormSeven from "./SubFormsOfFormThirteen/SubFormSeven";
import SubFormFive from "./SubFormsOfFormThirteen/SubFormFive";
import SubFormSix from "./SubFormsOfFormThirteen/SubFormSix";
import SubFormOne from "./SubFormsOfFormThirteen/SubFormOne";
import SubFormTow from "./SubFormsOfFormThirteen/SubFormTwo";
import SubFormThree from "./SubFormsOfFormThirteen/SubFormThree";
import SubFormFour from "./SubFormsOfFormThirteen/SubFormFour";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormThirteen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    subFormsOfFormThirteen: [],

    // form one
    OsteoarthritisJointsList: "",
    OsteoarthritisWhichSide: "",
    OsteoarthritisHowLongArthritis: "",
    OsteoarthritisTreatment: "",
    OsteoarthritisOngoingSymptoms: "",

    // form two
    RheumatoidArthritisJointsList: [],
    RheumatoidArthritisWhichSide: "",
    RheumatoidArthritisHowLongArthritis: "",
    RheumatoidArthritisTreatment: "",
    RheumatoidArthritisOngoingSymptoms: "",

    //form three
    PsoriaticArthritisHowLongArthritis: "",
    PsoriaticArthritisTreatment: "",
    PsoriaticArthritisOngoingSymptoms: "",

    // form four
    ArthritisHowLongArthritis: "",
    ArthritisTreatment: "",
    ArthritisOngoingSymptoms: "",

    // ForFive
    howLongSymptomsHas: [],
    restrictedDailyActivitiesYes: "",
    timeHadOffWork: "",
    numberOfReoccurrences: "",
    affectedJoint: [],
    RSIDetails: "",
    affectedJointDetails: "",

    // form six
    whenLastSymptoms: "",
    howLongChronicFatigue: "",
    treatment: "",
    ongoingSymptoms: "",
    timeOffWork: "",

    // Form Seven
    howLongFibromyalgia: "",
    FibromyalgiaTreatment: "",
    FibromyalgiaOngoingSymptoms: "",
    FibromyalgiaTimeOffWork: "",
    anyOngoingSymptoms: "",
    FibromyalgiaDetails: "",

    noneOfTheAbove:false,

  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };

  const validationSchema = Yup.object().shape({
    //form One

    // OsteoarthritisJointsList: Yup.string().required(),
    OsteoarthritisWhichSide: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("osteoarthritis")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    OsteoarthritisHowLongArthritis: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("osteoarthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    OsteoarthritisTreatment: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("osteoarthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    OsteoarthritisOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("osteoarthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form two
    RheumatoidArthritisWhichSide: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("rheumatoidArthritis")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    RheumatoidArthritisHowLongArthritis: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    RheumatoidArthritisTreatment: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    RheumatoidArthritisOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen", "OsteoarthritisWhichSide"],
      (subFormsOfFormThirteen, OsteoarthritisWhichSide) => {
        if (
          subFormsOfFormThirteen.includes("rheumatoidArthritis") &&
          OsteoarthritisWhichSide !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form three

    PsoriaticArthritisHowLongArthritis: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("psoriaticArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    PsoriaticArthritisTreatment: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("psoriaticArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    PsoriaticArthritisOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("psoriaticArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form four

    ArthritisHowLongArthritis: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("otherArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    ArthritisTreatment: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("otherArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    ArthritisOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("otherArthritis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    // Form Five
    howLongSymptomsHas: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("rsi")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    restrictedDailyActivitiesYes: Yup.string().when(
      ["subFormsOfFormThirteen", "howLongSymptomsHas"],
      (subFormsOfFormThirteen, howLongSymptomsHas) => {
        if (
          subFormsOfFormThirteen.includes("rsi") &&
          howLongSymptomsHas !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    RSIdetails: Yup.string().when(
      [
        "subFormsOfFormThirteen",
        "howLongSymptomsHas",
        "restrictedDailyActivitiesYes",
      ],
      (
        subFormsOfFormThirteen,
        howLongSymptomsHas,
        restrictedDailyActivitiesYes
      ) => {
        if (
          subFormsOfFormThirteen.includes("rsi") &&
          howLongSymptomsHas !== "" &&
          restrictedDailyActivitiesYes === "yes"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    timeHadOffWork: Yup.string().when(
      [
        "subFormsOfFormThirteen",
        "howLongSymptomsHas",
        "restrictedDailyActivitiesYes",
      ],
      (
        subFormsOfFormThirteen,
        howLongSymptomsHas,
        restrictedDailyActivitiesYes
      ) => {
        if (
          subFormsOfFormThirteen.includes("rsi") &&
          howLongSymptomsHas !== "" &&
          restrictedDailyActivitiesYes !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    numberOfReoccurrences: Yup.string().when(
      [
        "subFormsOfFormThirteen",
        "howLongSymptomsHas",
        "restrictedDailyActivitiesYes",
        "timeHadOffWork",
      ],
      (
        subFormsOfFormThirteen,
        howLongSymptomsHas,
        restrictedDailyActivitiesYes,
        timeHadOffWork
      ) => {
        if (
          subFormsOfFormThirteen.includes("rsi") &&
          howLongSymptomsHas !== "" &&
          restrictedDailyActivitiesYes !== "" &&
          timeHadOffWork !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form six

    whenLastSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("chronic")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    howLongChronicFatigue: Yup.string().when(
      ["subFormsOfFormThirteen", "whenLastSymptoms"],
      (subFormsOfFormThirteen, whenLastSymptoms) => {
        if (
          subFormsOfFormThirteen.includes("chronic") &&
          whenLastSymptoms !== ""
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    treatment: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("chronic")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    ongoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("chronic")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    timeOffWork: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("chronic")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //Form Seven

    howLongFibromyalgia: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("fibromyalgia")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    FibromyalgiaTreatment: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("fibromyalgia")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    FibromyalgiaOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("fibromyalgia")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    FibromyalgiaTimeOffWork: Yup.string().when(
      ["subFormsOfFormThirteen"],
      (subFormsOfFormThirteen) => {
        if (subFormsOfFormThirteen.includes("fibromyalgia")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    anyOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormThirteen", "FibromyalgiaTimeOffWork"],
      (subFormsOfFormThirteen, FibromyalgiaTimeOffWork) => {
        if (
          subFormsOfFormThirteen.includes("fibromyalgia") &&
          FibromyalgiaTimeOffWork !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    FibromyalgiaDetails: Yup.string().when(
      ["subFormsOfFormThirteen", "anyOngoingSymptoms"],
      (subFormsOfFormThirteen, anyOngoingSymptoms) => {
        if (
          subFormsOfFormThirteen.includes("fibromyalgia") &&
          anyOngoingSymptoms === "yes"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    noneOfTheAbove: Yup.string().when('subFormsOfFormThirteen', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formten", {
        formTen: e,
        pointer: 11,
        formid: id,
      });
      dispatch(setPage(11));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values , setFieldValue, setFieldTouched,resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following?
                    </p>

                    <SubFormOne values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormTow values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormThree values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFour values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFive values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSix values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSeven values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                     <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
