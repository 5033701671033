import { LOGIN, LOGOUT, TOGGLE, NAME } from "../actions/types";

const initialState = {
  token: sessionStorage.getItem("token"),
  role: sessionStorage.getItem("role"),
  name: sessionStorage.getItem("name"),
  routes: JSON.parse(sessionStorage.getItem("routes")) || [],
  isDrawerOpen: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload };

    case LOGOUT:
      localStorage.clear();
      return {
        token: null,
        role: null,
        name: null,
        routes: [],
        isDrawerOpen: false,
      };

    case TOGGLE:
      return { ...state, isDrawerOpen: !state.isDrawerOpen };
    case NAME:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
