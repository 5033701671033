import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";

const consulted = [
  { label: "Chiropractor", value: "chiropractor" },
  { label: "Osteopath", value: "osteopath" },
  { label: "Physiotherapist", value: "physiotherapist" },
  { label: "Acupuncturist", value: "Acupuncturist" },
];
const bodyPart = [
  { label: "Joint or fracture", value: "Joint or fracture" },
  { label: "Back or neck", value: "Back or neck" },
  { label: "Muscle / Ligament / Tendon", value: "Muscle / Ligament / Tendon" },
  { label: "Arthritis/RSI", value: "Arthritis/RSI" },
  { label: "Headache", value: "Headache" },
  { label: "Pregnancy related", value: "Pregnancy related" },
  { label: "IVF or Fertility related", value: "IVF or Fertility related" },
  { label: "Other not musculoskeletal", value: "Other not musculoskeletal" },
];

export default function SubForm22({ values, setFieldValue, setFieldTouched }) {
  return (
    <Fragment>
      <CheckBox
        question=""
        name="consultations"
        list={consulted}
        onClick={() => {
          setFieldValue("noneOfTheAbove", false, false);
          setFieldTouched("noneOfTheAbove", false);
        }}
      />

      {values.consultations.length > 0 && (
        <Fragment>
          <YesNoQuestion
            name="isDisclosed"
            question="Have you already disclosed this anywhere else in this survey?"
            isSubForm
          />
          {values.isDisclosed === "false" && (
            <Fragment>
              <CheckBox
                question="Which Part of the body did you attend for?"
                name="whichBodyPartconsultant"
                list={bodyPart}
                isSubForm
              />
              <AnswerBox
                label="Please provide details"
                name="consultantDetails"
                isSubForm
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
