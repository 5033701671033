import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./classes";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Img1 from "../../assets/images/img1.png";
import Img2 from "../../assets/images/img2.png";
import Img3 from "../../assets/images/image.png";
import Img4 from "../../assets/images/img4.png";
import Img5 from "../../assets/images/img5.png";
import Img6 from "../../assets/images/img6.png";
import Img7 from "../../assets/images/img15.png";
import Img8 from "../../assets/images/img8.png";

const Workpage = () => {
  const classes = useStyles();

  function FormRow1() {
    return (
      <React.Fragment>
        <Grid
          // direction="row"
          // alignItems="center"
          // alignself="center"
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={3}
          className={classes.pbox}
        >
          <Typography className={classes.text}>
            1. Adviser adds client details
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            2. Sends client a text with survey link
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img2}
              alt="Sends client a text with surver link"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            3. Client completes survey
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img3}
              alt="Client completes survey"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            4. Adviser reviews and selects insurers for pre-assessment to be
            sent to
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img4}
              alt="Adviser reviews and selects insurers for pre-assessment to be
            sent to"
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  function FormRow2() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            5. Pre-assessment sent to underwriters
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img5}
              alt="re-assessment sent to underwriters"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            6. Underwriter/s completes assessment
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img6}
              alt="underwriter/s completes assessment"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text} style={{ marginBottom: "1rem" }}>
            7. Adviser receives a summary of outcomes
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img7}
              alt="Adviser receives a summary of outcomes"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.pbox}>
          <Typography className={classes.text}>
            8. Adviser makes Recommendations
          </Typography>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img8}
              alt="Adviser makes Recommendations"
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div id="how">
      <Typography className={classes.header}>How Does it Work?</Typography>

      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12}>
            <FormRow1 />
          </Grid>
          <Grid container item xs={12}>
            <FormRow2 />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Workpage;
