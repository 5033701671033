import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const MentalIllnessTab = (props) => {
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-mental-illness-tabs"
          defaultActiveKey="#mentalIllness-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formEight.anxiety.map((illness, index) => (
                <ListGroup.Item action href={`#mentalIllness-${index + 1}`}>
                  {formatFormData(illness)
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    formatFormData(illness)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formEight.anxiety.map((illness, index) => (
                <Tab.Pane eventKey={`#mentalIllness-${index + 1}`}>
                  <Table
                    id="form-view-mental-illness-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {illness === "depression" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressionDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {props.formData.formEight.depressionStartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {props.formData.formEight.depressionTimePeriod}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.depressionReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.depressionReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {props.formData.formEight.depressionFactors}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.depressionThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.depressionThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {props.formData.formEight.depressionWhatTriggered}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressionSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .depressionReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .depressionReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .depressionHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .depressionCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .depressionCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .depressionPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .depressionPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight.depressionMedicationDose ? (
                          <>
                            <tr>
                              <td>Have you taken medication for depression?</td>
                              <td>
                                {props.formData.formEight
                                  .depressionMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressionMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .depressionConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressionConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .depressionDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .depressionDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressionDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .depressionWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .depressionLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressionLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {props.formData.formEight.depressionDetails}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "eatingDisorder" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .eatingDisorderDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {props.formData.formEight.eatingDisorderStartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {props.formData.formEight.eatingDisorderTimePeriod}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.eatingDisorderReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.eatingDisorderReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {props.formData.formEight.eatingDisorderFactors}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.eatingDisorderThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.eatingDisorderThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .eatingDisorderWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .eatingDisorderSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .eatingDisorderReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .eatingDisorderReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .eatingDisorderHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .eatingDisorderCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .eatingDisorderPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .eatingDisorderMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for an eating disorder?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .eatingDisorderMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .eatingDisorderConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .eatingDisorderDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .eatingDisorderDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .eatingDisorderWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .eatingDisorderLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .eatingDisorderLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .eatingDisorderDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "generalAnxiety" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .generalAnxietyDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {props.formData.formEight.generalAnxietyStartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {props.formData.formEight.generalAnxietyTimePeriod}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.generalAnxietyReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.generalAnxietyReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {props.formData.formEight.generalAnxietyFactors}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.generalAnxietyThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.generalAnxietyThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .generalAnxietyWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .generalAnxietySymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .generalAnxietyReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .generalAnxietyReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .generalAnxietyHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .generalAnxietyCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .generalAnxietyPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .generalAnxietyMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for general anxiety?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .generalAnxietyMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .generalAnxietyConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .generalAnxietyDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .generalAnxietyDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .generalAnxietyWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .generalAnxietyLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .generalAnxietyLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .generalAnxietyDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "depressiveIllness" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressiveIllnessDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressiveIllnessStartDate
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressiveIllnessTimePeriod
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.depressiveIllnessReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.depressiveIllnessReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .depressiveIllnessFactors
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.depressiveIllnessThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.depressiveIllnessThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .depressiveIllnessWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .depressiveIllnessSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .depressiveIllnessReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .depressiveIllnessReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .depressiveIllnessHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .depressiveIllnessCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .depressiveIllnessPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .depressiveIllnessMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for a depressive illness?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .depressiveIllnessMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressiveIllnessConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .depressiveIllnessDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressiveIllnessDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .depressiveIllnessWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .depressiveIllnessLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .depressiveIllnessLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .depressiveIllnessDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "addiction" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {props.formData.formEight.addictionDescribeSymptoms}
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>{props.formData.formEight.addictionStartDate}</td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {props.formData.formEight.addictionTimePeriod}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.addictionReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.addictionReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>{props.formData.formEight.addictionFactors}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.addictionThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.addictionThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {props.formData.formEight.addictionWhatTriggered}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .addictionSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .addictionReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .addictionReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .addictionHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .addictionCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .addictionCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .addictionPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .addictionPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight.addictionMedicationDose ? (
                          <>
                            <tr>
                              <td>Have you taken medication for an addiction?</td>
                              <td>
                                {props.formData.formEight
                                  .addictionMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .addictionMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .addictionConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .addictionConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .addictionDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .addictionDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .addictionDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .addictionWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .addictionLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .addictionLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {props.formData.formEight.addictionDetails}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "traumaticStress" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .traumaticStressDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {props.formData.formEight.traumaticStressStartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {props.formData.formEight.traumaticStressTimePeriod}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.traumaticStressReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.traumaticStressReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {props.formData.formEight.traumaticStressFactors}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.traumaticStressThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.traumaticStressThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .traumaticStressWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .traumaticStressSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .traumaticStressReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .traumaticStressReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .traumaticStressHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .traumaticStressCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .traumaticStressPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .traumaticStressMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for traumatic stress?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .traumaticStressMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .traumaticStressConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .traumaticStressDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .traumaticStressDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .traumaticStressWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .traumaticStressLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .traumaticStressLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .traumaticStressDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "psychoticDisorder" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .psychoticDisorderDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {
                              props.formData.formEight
                                .psychoticDisorderStartDate
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {
                              props.formData.formEight
                                .psychoticDisorderTimePeriod
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.psychoticDisorderReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.psychoticDisorderReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .psychoticDisorderFactors
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.psychoticDisorderThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.psychoticDisorderThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .psychoticDisorderWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .psychoticDisorderSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .psychoticDisorderReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .psychoticDisorderReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .psychoticDisorderHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .psychoticDisorderCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .psychoticDisorderPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .psychoticDisorderMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for a psychotic disorder?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .psychoticDisorderMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .psychoticDisorderConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .psychoticDisorderDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .psychoticDisorderDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .psychoticDisorderWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .psychoticDisorderLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .psychoticDisorderLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .psychoticDisorderDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "chronicTiredness" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {
                              props.formData.formEight
                                .chronicTirednessDescribeSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>
                            {props.formData.formEight.chronicTirednessStartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>
                            {
                              props.formData.formEight
                                .chronicTirednessTimePeriod
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.chronicTirednessReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.chronicTirednessReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>
                              {props.formData.formEight.chronicTirednessFactors}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.chronicTirednessThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.chronicTirednessThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .chronicTirednessWhatTriggered
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {
                              props.formData.formEight
                                .chronicTirednessSymptomsCommenced
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .chronicTirednessReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .chronicTirednessReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .chronicTirednessHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .chronicTirednessCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .chronicTirednessPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .chronicTirednessMedicationDose ? (
                          <>
                            <tr>
                              <td>
                                Have you taken medication for chronic tiredness?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessMedicationDose
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .chronicTirednessMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .chronicTirednessConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .chronicTirednessDosageOften
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .chronicTirednessDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .chronicTirednessWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .chronicTirednessLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .chronicTirednessLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .chronicTirednessDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "other" ? (
                      <tbody>
                        <tr>
                          <td>Describe your symptoms</td>
                          <td>
                            {props.formData.formEight.otherDescribeSymptoms}
                          </td>
                        </tr>
                        <tr>
                          <td>What date did they start?</td>
                          <td>{props.formData.formEight.otherStartDate}</td>
                        </tr>
                        <tr>
                          <td>How long did they last?</td>
                          <td>{props.formData.formEight.otherTimePeriod}</td>
                        </tr>
                        <tr>
                          <td>
                            Was there a reason identified or a trigger that was
                            a cause if these details?
                          </td>
                          <td>
                            {props.formData.formEight.otherReasons === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.otherReasons === "true" ? (
                          <tr>
                            <td>
                              Please provide details of the trigger or factors?
                            </td>
                            <td>{props.formData.formEight.otherFactors}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Have you ever had any suicidal thoughts, threatened
                            to self-harm or engaged in self-harm?
                          </td>
                          <td>
                            {props.formData.formEight.otherThoughts === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight.otherThoughts === "true" ? (
                          <tr>
                            <td>
                              Provide details including what triggered the
                              suicidal attempt or self-harm threats or actual,
                              provide details, or how often and the last
                              occurrence of them.
                            </td>
                            <td>
                              {props.formData.formEight.otherWhatTriggered}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>What was the date the symptoms commenced?</td>
                          <td>
                            {props.formData.formEight.otherSymptomsCommenced}
                          </td>
                        </tr>
                        <tr>
                          <td>Have you had reoccurrences of this condition?</td>
                          <td>
                            {props.formData.formEight
                              .otherReoccurrencesOfCondition === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formEight
                          .otherReoccurrencesOfCondition ? (
                          <tr>
                            <td>
                              State how many times and when these reoccurrences
                              have happened?
                            </td>
                            <td>
                              {
                                props.formData.formEight
                                  .otherHowManyReoccurrences
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight.otherCounsellingWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received counselling?</td>
                              <td>
                                {props.formData.formEight
                                  .otherCounsellingWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherCounsellingWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the counselling end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherCounsellingEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Counselling details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherCounsellingTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight
                          .otherPsychotherapyWhenStart ? (
                          <>
                            <tr>
                              <td>Have you received psychotherapy?</td>
                              <td>
                                {props.formData.formEight
                                  .otherPsychotherapyWhenStart
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy start?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherPsychotherapyWhenStart
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>When did the Psychotherapy end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherPsychotherapyEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Psychotherapy details</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherPsychotherapyTherapyDetails
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {props.formData.formEight.otherMedicationDose ? (
                          <>
                            <tr>
                              <td>Have you taken medication for this illness?</td>
                              <td>
                                {props.formData.formEight.otherMedicationDose
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>What was/is the medication and dose?</td>
                              <td>
                                {props.formData.formEight.otherMedicationDose}
                              </td>
                            </tr>
                            <tr>
                              <td>When did this end?</td>
                              <td>
                                {
                                  props.formData.formEight
                                    .otherMedicationEndDate
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Do you continue to take this medication?</td>
                              <td>
                                {props.formData.formEight
                                  .otherConditionMedication
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .otherConditionMedication ? (
                              <tr>
                                <td>
                                  Please provide details of how often and dosage
                                </td>
                                <td>
                                  {props.formData.formEight.otherDosageOften}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you ever been off work or been on
                                restricted duties due to your condition?
                              </td>
                              <td>
                                {props.formData.formEight
                                  .otherDutiesRestrictionCondition
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight
                              .otherDutiesRestrictionCondition ? (
                              <tr>
                                <td>Work restriction details</td>
                                <td>
                                  {
                                    props.formData.formEight
                                      .otherWorkRestriction
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had any ongoing effects or restrictions
                                on your life and activities of any kind?
                              </td>
                              <td>
                                {props.formData.formEight.otherLifeRestriction
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {props.formData.formEight.otherLifeRestriction ? (
                              <tr>
                                <td>Life restriction details</td>
                                <td>{props.formData.formEight.otherDetails}</td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : (
                      <></>
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default MentalIllnessTab;
