import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "80%",
    textAlign: "left",
    paddingLeft: 8,
  },
  qty: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow1 = ({ user }) => {
  const rows = user.map((item) => (
    <View style={styles.row} key={item.quantity.toString()}>
      <Text style={styles.qty}>{item.quantity}</Text>
      <Text style={styles.description}>{item.description}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow1;
