import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "2rem",
    display: "flex",
    justifyContent: "center",
    alignself: "center",
    marginTop: 35,
  },

  root: {
    marginTop: 50,
  },

  box: {
    display: "flex",
    justifyContent: "center",
  },

  img: {
    width: "100%",
    height: "100%",
  },

  img1: {
    width: 300,
    height: 180,
  },
  video: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
  },
  size: {
    [theme.breakpoints.down("xl")]: {
      width: 1280,
      height: 650,
      alignself: "center",
    },
    [theme.breakpoints.down("lg")]: {
      width: 720,
      height: 360,
      alignself: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: 540,
      height: 280,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: 700,
      height: 400,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: 350,
      height: 200,
      alignself: "center",
      marginTop: -50,
    },
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  youtube: {
    [theme.breakpoints.down("xl")]: {
      width: 1280,
      height: 650,
      alignself: "center",
    },
    [theme.breakpoints.down("lg")]: {
      width: 720,
      height: 360,
      alignself: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: 540,
      height: 280,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: 700,
      height: 400,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: 350,
      height: 200,
      alignself: "center",
      marginTop: -50,
    },
  },
}));
