import React, { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const resetArray = [
  { name: "tumourWhenTumourDetected", type: "" },
  { name: "tumourWhatTreatment", type: "" },
  { name: "tumourAnyOtherDetails", type: "" },
];

export default function SubFormFive({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Tumour of any kind", value: "tumourOfAnyKind" }]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfSixteen.includes("tumourOfAnyKind") && (
        <AnswerBox
          label="When was the tumour detected?"
          name="tumourWhenTumourDetected"
          isSubForm
        />
      )}
      {values.subFormsOfSixteen.includes("tumourOfAnyKind") &&
        values.tumourWhenTumourDetected !== "" && (
          <AnswerBox
            label="What treatment was recommended?"
            name="tumourWhatTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("tumourOfAnyKind") &&
        values.tumourWhatTreatment !== "" && (
          <AnswerBox
            label="Please provide any other details pertaining to this tumour?"
            name="tumourAnyOtherDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
