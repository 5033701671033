import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";

const resetArray = [
  { name: "chronicTirednessDescribeSymptoms", type: "" },
  { name: "chronicTirednessStartDate", type: "" },
  { name: "chronicTirednessTimePeriod", type: "" },
  { name: "chronicTirednessReasons", type: "" },
  { name: "chronicTirednessFactors", type: "" },
  { name: "chronicTirednessThoughts", type: "" },
  { name: "chronicTirednessWhatTriggered", type: "" },
  { name: "chronicTirednessSymptomsCommenced", type: "" },
  { name: "chronicTirednessReoccurrencesOfCondition", type: "" },
  { name: "chronicTirednessHowManyReoccurrences", type: "" },
  { name: "chronicTirednessTreatment", type: [] },
  { name: "chronicTirednessCounsellingWhenStart", type: "" },
  { name: "chronicTirednessCounsellingEndDate", type: "" },
  { name: "chronicTirednessCounsellingTherapyDetails", type: "" },
  { name: "chronicTirednessPsychotherapyWhenStart", type: "" },
  { name: "chronicTirednessPsychotherapyEndDate", type: "" },
  { name: "chronicTirednessPsychotherapyTherapyDetails", type: "" },
  { name: "chronicTirednessMedicationDose", type: "" },
  { name: "chronicTirednessMedicationEndDate", type: "" },
  { name: "chronicTirednessConditionMedication", type: "" },
  { name: "chronicTirednessDosageOften", type: "" },
  { name: "chronicTirednessDutiesRestrictionCondition", type: "" },
  { name: "chronicTirednessWorkRestriction", type: "" },
  { name: "chronicTirednessLifeRestriction", type: "" },
  { name: "chronicTirednessDetails", type: "" },
];

export default function ChronicTiredness({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Stress, sleeplessness, chronic tiredness",
            value: "chronicTiredness",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("chronicTiredness") && (
        <AnswerBox
          label="Describe your symptoms"
          name="chronicTirednessDescribeSymptoms"
          isSubForm
        />
      )}

      {values.chronicTirednessDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="chronicTirednessStartDate"
          isSubForm
        />
      )}
      {values.chronicTirednessStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="chronicTirednessTimePeriod"
          isSubForm
        />
      )}
      {values.chronicTirednessTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause if these details? "
          name="chronicTirednessReasons"
          isSubForm
        />
      )}
      {values.chronicTirednessReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="chronicTirednessFactors"
          isSubForm
        />
      )}
      {(values.chronicTirednessReasons === "false" ||
        values.chronicTirednessFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts , threatened to self harm or engaged in self harm?"
          name="chronicTirednessThoughts"
          isSubForm
        />
      )}
      {values.chronicTirednessThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="chronicTirednessWhatTriggered"
          isSubForm
        />
      )}
      {(values.chronicTirednessThoughts === "false" ||
        values.chronicTirednessWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="chronicTirednessSymptomsCommenced"
          isSubForm
        />
      )}
      {values.chronicTirednessSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="chronicTirednessReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.chronicTirednessReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="chronicTirednessHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.chronicTirednessReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="chronicTirednessTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.chronicTirednessTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="chronicTirednessCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="chronicTirednessCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="chronicTirednessCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.chronicTirednessReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="chronicTirednessTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}

      {values.chronicTirednessTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="chronicTirednessPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="chronicTirednessPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="chronicTirednessPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.chronicTirednessReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="chronicTirednessTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.chronicTirednessTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="chronicTirednessMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="chronicTirednessMedicationEndDate"
            isSubForm
          />

          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="chronicTirednessConditionMedication"
            isSubForm
          />

          {values.chronicTirednessConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage"
              name="chronicTirednessDosageOften"
              isSubForm
            />
          )}
          {(values.chronicTirednessDosageOften ||
            values.chronicTirednessConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="chronicTirednessDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.chronicTirednessDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="chronicTirednessWorkRestriction"
              isSubForm
            />
          )}

          {(values.chronicTirednessDutiesRestrictionCondition === "false" ||
            values.chronicTirednessWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="chronicTirednessLifeRestriction"
              isSubForm
            />
          )}
          {values.chronicTirednessLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="chronicTirednessDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
