import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "typeOfHernia", type: [] },
  { name: "tratmentAndOutcome", type: "" },
];

export default function FormHernia({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Hernia", value: "Hernia" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Hernia") && (
        <Fragment>
          <RadioMulti
            question="Was your hernia confirmed as any of the following?"
            name="typeOfHernia"
            items={[
              { label: "Inguinal Hernia", value: "InguinalHernia" },
              { label: "Femoral Hernia", value: "FemoralHernia" },
              { label: "Umbilical Hernia", value: "UmbilicalHernia" },
              { label: "Incisional Hernia", value: "IncisionalHernia" },
              { label: "Hiatus Hernia", value: "HiatusHernia" },
              { label: "Unknown Type", value: "UnknownType" },
            ]}
            isSubForm
          />

          <AnswerBox
            name="tratmentAndOutcome"
            label="Please provide details on the treatment and the outcome of the treatment
                              including the date of issue."
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
