import React, { Fragment } from "react";
import { TableCell, Chip } from "@material-ui/core";
import moment from "moment";

import ReusableTable from "../../components/layouts/PreusableTable";

const headers = [
  {
    text: "Package Names",
    value: "email",
  },
  {
    text: "Advisor",
    value: "arNumber",
  },
  {
    text: "AR Number",
    value: "arNumber",
  },
  {
    text: "Paid Amount",
    value: "amount",
  },
  {
    text: "Paypal Ref",
    value: "paypal ref",
  },
  {
    text: "Transaction Date",
    value: "Date",
  },
  {
    text: "Payment Status",
    value: "currentStatus",
  },
  // {
  //   text: "Change Status",
  //   value: "status",
  // },
];
export default function paypalView(props) {
  const TableB = ({ item, changeStatus, classes }) => {
    return (
      <Fragment>
        <TableCell key="name" style={{ fontFamily: "Overpass, sans-serif" }}>
          {" "}
          {item.items.map((data) => (
            <p>{data.description},</p>
          ))}
        </TableCell>
        <TableCell key="email" style={{ fontFamily: "Overpass, sans-serif" }}>
          {item.items[0].name}
        </TableCell>
        <TableCell key="email" style={{ fontFamily: "Overpass, sans-serif" }}>
          {item.arNumber}
        </TableCell>
        <TableCell key="amount" style={{ fontFamily: "Overpass, sans-serif" }}>
          ${item.amount}.00
        </TableCell>
        <TableCell
          key="paymentId"
          style={{ fontFamily: "Overpass, sans-serif" }}
        >
          {item.paymentId}
        </TableCell>
        <TableCell
          key="createdAt"
          style={{ fontFamily: "Overpass, sans-serif" }}
        >{`${moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}`}</TableCell>
        {item.status ? (
          <TableCell key="type" style={{ fontFamily: "Overpass, sans-serif" }}>
            {" "}
            <Chip label="Sucsses" className={classes.green} />
          </TableCell>
        ) : (
          <TableCell key="type" style={{ fontFamily: "Overpass, sans-serif" }}>
            <Chip label="Fail" className={classes.red} />
          </TableCell>
        )}
        {/* <TableCell key="status">
          {item.status ? (
            <Button
              variant="contained"
              className={classes.red}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.green}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Activate
            </Button>
          )}
        </TableCell> */}
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View Payment History"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/paypal/" //change
      start="2021-09-28 08:29:23 AM"
      end="2021-09-29 08:29:23 AM"
      // statusChangeUrl="/paypal/update-status"
      {...props}
    />
  );
}
