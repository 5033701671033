import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useStyles } from "./classes";

import Grid from "@material-ui/core/Grid";

import Img9 from "../../assets/images/img9.jpg";

const DesignerPage = () => {
  const classes = useStyles();

  function FormRow1() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Typography className={classes.text}>Rachael Ooi</Typography>
          <Box className={classes.box}>
            <Typography className={classes.paragraph}>
              Financial Adviser
            </Typography>
            <Typography className={classes.paragraph}>
              Designer and Creator of PreAssessMe - Insurance Advice Tool
            </Typography>
            <Typography className={classes.italic}>
              As the principal adviser at a Brisbane financial planning firm, I
              have found the changes to the industry have meant that the cost to
              provide compliant insurance advice well exceeds the commissions
              that are now available. It seems as well, that no-one is a clean
              skin anymore! Therefore after experiencing the issues and costs
              associated with providing insurance advice, to then have it
              derailed by unfavourable underwriting assessment, I decided that
              there had to be a better way! And so was born the idea to
              revolutionise insurance advice via the preassessment process....
            </Typography>
          </Box>
        </Grid>
        <Grid
          // justifycontent="center"
          // alignContent="center"
          // alignItems="center"
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
        >
          <Box className={classes.box}>
            <img className={classes.image} src={Img9} alt="" />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div style={{ marginTop: 70 }}>
      <div id="advisor"></div>
      <Typography className={classes.header}>
        PreAssessMe Designed by an Adviser for Advisers
      </Typography>
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} justifycontent="center">
            <FormRow1 />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DesignerPage;
