import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubFormOne from "./SubFormsOfFormSixteen/SubFormOne";
import SubFormTwo from "./SubFormsOfFormSixteen/SubFormTwo";
import SubFormThree from "./SubFormsOfFormSixteen/SubFormThree";
import SubFormFour from "./SubFormsOfFormSixteen/SubFormFour";
import SubFormFive from "./SubFormsOfFormSixteen/SubFormFive";
import SubFormSix from "./SubFormsOfFormSixteen/SubFormSix";
import SubFormSeven from "./SubFormsOfFormSixteen/SubFormSeven";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormSixteen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    subFormsOfSixteen: [],

    //Form One

    cancerSkinTreatment: [],
    whenWasCancerRemoved: [],
    treatmentDetails: "",
    whenWasCancerNotRemoved: "",
    notRemovedDetails: "",
    whatDiBiopsyConfirm: [],
    BasalCellCarcinomasYes: "",
    BasalCellCarcinomasDetails: "",
    anyOtherTreatmentYes: "",
    OtherTreatmentDetails: "",

    //form two

    CancerOtherThanSkinDetails: "",

    //Form Three
    CystYes: "",
    onTheSkinOnlyYes: "",
    CystAnyOtherTreatmentYes: "",
    CystOtherTreatmentDetails: "",
    recurrencesOfThisCystYes: "",
    recurrencesOfThisCystDetails: "",

    //form four

    howManyBreastLumps: [],
    howWasLumpTreated: [],
    surgeryLumpsDetected: "",
    surgeryWhenWasItOperatedOn: "",
    AspiratedWhenWasTheLumpAspirated: [],
    AspiratedWhenWereTheLumpsDetected: "",
    AspiratedWhenWasItOperatedOn: "",
    AspiratedLumpConfirmedYes: "",
    AspiratedLumpConfirmedDetails: "",
    AspiratedSubsequentMammogramsYes: "",
    regularCheckupsLumpsDetected: "",
    regularCheckupsWhenWasItOperatedOn: "",
    regularCheckupsSubsequentMammogramsYes: "",

    //form five

    tumourWhenTumourDetected: "",
    tumourWhatTreatment: "",
    tumourAnyOtherDetails: "",

    //form Six
    growthWhenTumourDetected: "",
    growthWhatTreatment: "",
    growthAnyOtherDetails: "",

    //form Seven

    lumpWhenTumourDetected: "",
    lumpWhatTreatment: "",
    lumpAnyOtherDetails: "",

    noneOfTheAbove: false,

  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    //form One

    treatmentDetails: Yup.string().when(
      ["subFormsOfSixteen", "cancerSkinTreatment"],
      (subFormsOfSixteen, cancerSkinTreatment) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          cancerSkinTreatment.includes("creamOintment")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    whenWasCancerNotRemoved: Yup.string().when(
      ["subFormsOfSixteen", "cancerSkinTreatment"],
      (subFormsOfSixteen, cancerSkinTreatment) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          cancerSkinTreatment.includes("notRemoved")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    notRemovedDetails: Yup.string().when(
      ["subFormsOfSixteen", "cancerSkinTreatment", "whenWasCancerNotRemoved"],
      (
        subFormsOfSixteen,

        cancerSkinTreatment,
        whenWasCancerNotRemoved
      ) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          cancerSkinTreatment.includes("notRemoved") &&
          whenWasCancerNotRemoved === "lessThan10years"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    BasalCellCarcinomasYes: Yup.string().when(
      ["subFormsOfSixteen", "cancerSkinTreatment", "whatDiBiopsyConfirm"],
      (
        subFormsOfSixteen,

        cancerSkinTreatment,
        whatDiBiopsyConfirm
      ) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          cancerSkinTreatment.includes("excised") &&
          whatDiBiopsyConfirm.includes("BCC")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    BasalCellCarcinomasDetails: Yup.string().when(
      [
        "subFormsOfSixteen",

        "cancerSkinTreatment",
        "whatDiBiopsyConfirm",
        "BasalCellCarcinomasYes",
      ],
      (
        subFormsOfSixteen,

        cancerSkinTreatment,
        whatDiBiopsyConfirm,
        BasalCellCarcinomasYes
      ) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          cancerSkinTreatment.includes("excised") &&
          whatDiBiopsyConfirm.includes("BCC") &&
          BasalCellCarcinomasYes === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    anyOtherTreatmentYes: Yup.string().when(
      [
        "subFormsOfSixteen",

        "cancerSkinTreatment",
        // 'whatDiBiopsyConfirm',
        "BasalCellCarcinomasDetails",
        "BasalCellCarcinomasYes",
      ],
      (
        subFormsOfSixteen,

        cancerSkinTreatment,
        // whatDiBiopsyConfirm,
        BasalCellCarcinomasDetails,
        BasalCellCarcinomasYes
      ) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          BasalCellCarcinomasYes === "yes" &&
          cancerSkinTreatment.includes("excised") &&
          // !whatDiBiopsyConfirm.includes('BCC')) ||
          BasalCellCarcinomasDetails !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    OtherTreatmentDetails: Yup.string().when(
      [
        "subFormsOfSixteen",

        "cancerSkinTreatment",
        "anyOtherTreatmentYes",
        "BasalCellCarcinomasYes",
      ],
      (
        subFormsOfSixteen,

        cancerSkinTreatment,
        anyOtherTreatmentYes,
        BasalCellCarcinomasYes
      ) => {
        if (
          subFormsOfSixteen.includes("cancerSkin") &&
          BasalCellCarcinomasYes === "yes" &&
          cancerSkinTreatment.includes("excised") &&
          anyOtherTreatmentYes === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form Two

    CancerOtherThanSkinDetails: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("cancerOtherThanSkin")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //Form Three

    onTheSkinOnlyYes: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("cyst")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    CystAnyOtherTreatmentYes: Yup.string().when(
      ["subFormsOfSixteen", "onTheSkinOnlyYes"],
      (subFormsOfSixteen, onTheSkinOnlyYes) => {
        if (subFormsOfSixteen.includes("cyst") && onTheSkinOnlyYes === "yes") {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    CystOtherTreatmentDetails: Yup.string().when(
      ["subFormsOfSixteen", "onTheSkinOnlyYes", "CystAnyOtherTreatmentYes"],
      (
        subFormsOfSixteen,

        onTheSkinOnlyYes,
        CystAnyOtherTreatmentYes
      ) => {
        if (
          subFormsOfSixteen.includes("cyst") &&
          onTheSkinOnlyYes === "yes" &&
          CystAnyOtherTreatmentYes === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    recurrencesOfThisCystYes: Yup.string().when(
      [
        "subFormsOfSixteen",

        "onTheSkinOnlyYes",
        "CystAnyOtherTreatmentYes",
        "CystOtherTreatmentDetails",
      ],
      (
        subFormsOfSixteen,

        onTheSkinOnlyYes,
        CystAnyOtherTreatmentYes,
        CystOtherTreatmentDetails
      ) => {
        if (
          (subFormsOfSixteen.includes("cyst") &&
            onTheSkinOnlyYes === "yes" &&
            CystOtherTreatmentDetails !== "") ||
          CystAnyOtherTreatmentYes === "no"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    recurrencesOfThisCystDetails: Yup.string().when(
      ["subFormsOfSixteen", "onTheSkinOnlyYes", "recurrencesOfThisCystYes"],
      (
        subFormsOfSixteen,

        onTheSkinOnlyYes,
        recurrencesOfThisCystYes
      ) => {
        if (
          subFormsOfSixteen.includes("cyst") &&
          onTheSkinOnlyYes === "yes" &&
          recurrencesOfThisCystYes === "yes"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form four

    howManyBreastLumps: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("breastCyst")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    surgeryLumpsDetected: Yup.string().when(
      ["subFormsOfSixteen", "howWasLumpTreated"],
      (subFormsOfSixteen, howWasLumpTreated) => {
        if (
          subFormsOfSixteen.includes("breastCyst") &&
          howWasLumpTreated.includes("surgery")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    surgeryWhenWasItOperatedOn: Yup.string().when(
      ["subFormsOfSixteen", "howWasLumpTreated"],
      (subFormsOfSixteen, howWasLumpTreated) => {
        if (
          subFormsOfSixteen.includes("breastCyst") &&
          howWasLumpTreated.includes("surgery")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    regularCheckupsLumpsDetected: Yup.string().when(
      ["subFormsOfSixteen", "howWasLumpTreated"],
      (subFormsOfSixteen, howWasLumpTreated) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("noTreatment")) ||
          howWasLumpTreated.includes("RegularCheckups")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    regularCheckupsWhenWasItOperatedOn: Yup.string().when(
      ["subFormsOfSixteen", "howWasLumpTreated"],
      (subFormsOfSixteen, howWasLumpTreated) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("noTreatment")) ||
          howWasLumpTreated.includes("RegularCheckups")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    regularCheckupsSubsequentMammogramsYes: Yup.string().when(
      ["subFormsOfSixteen", "howWasLumpTreated"],
      (subFormsOfSixteen, howWasLumpTreated) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("noTreatment")) ||
          howWasLumpTreated.includes("RegularCheckups")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    AspiratedWhenWereTheLumpsDetected: Yup.string().when(
      [
        "subFormsOfSixteen",

        "howWasLumpTreated",
        "AspiratedWhenWasTheLumpAspirated",
      ],
      (
        subFormsOfSixteen,

        howWasLumpTreated,
        AspiratedWhenWasTheLumpAspirated
      ) => {
        if (
          subFormsOfSixteen.includes("breastCyst") &&
          howWasLumpTreated.includes("aspirated") &&
          AspiratedWhenWasTheLumpAspirated.includes("lessThan5years")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    AspiratedWhenWasItOperatedOn: Yup.string().when(
      [
        "subFormsOfSixteen",

        "howWasLumpTreated",
        "AspiratedWhenWasTheLumpAspirated",
      ],
      (
        subFormsOfSixteen,

        howWasLumpTreated,
        AspiratedWhenWasTheLumpAspirated
      ) => {
        if (
          subFormsOfSixteen.includes("breastCyst") &&
          howWasLumpTreated.includes("aspirated") &&
          AspiratedWhenWasTheLumpAspirated.includes("lessThan5years")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    AspiratedLumpConfirmedYes: Yup.string().when(
      [
        "subFormsOfSixteen",

        "howWasLumpTreated",
        "AspiratedWhenWasTheLumpAspirated",
      ],
      (
        subFormsOfSixteen,

        howWasLumpTreated,
        AspiratedWhenWasTheLumpAspirated
      ) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("aspirated") &&
            AspiratedWhenWasTheLumpAspirated.includes("between5And10Years")) ||
          AspiratedWhenWasTheLumpAspirated.includes("moreThan10Years")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    AspiratedLumpConfirmedDetails: Yup.string().when(
      [
        "subFormsOfSixteen",

        "howWasLumpTreated",
        "AspiratedWhenWasTheLumpAspirated",
        "AspiratedLumpConfirmedYes",
      ],
      (
        subFormsOfSixteen,

        howWasLumpTreated,
        AspiratedWhenWasTheLumpAspirated,
        AspiratedLumpConfirmedYes
      ) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("aspirated") &&
            AspiratedLumpConfirmedYes === "yes" &&
            AspiratedWhenWasTheLumpAspirated.includes("between5And10Years")) ||
          AspiratedWhenWasTheLumpAspirated.includes("moreThan10Years")
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),

    AspiratedSubsequentMammogramsYes: Yup.string().when(
      [
        "subFormsOfSixteen",

        "howWasLumpTreated",
        "AspiratedWhenWasTheLumpAspirated",
        "AspiratedLumpConfirmedYes",
        "AspiratedLumpConfirmedDetails",
      ],
      (
        subFormsOfSixteen,

        howWasLumpTreated,
        AspiratedWhenWasTheLumpAspirated,
        AspiratedLumpConfirmedYes,
        AspiratedLumpConfirmedDetails
      ) => {
        if (
          (subFormsOfSixteen.includes("breastCyst") &&
            howWasLumpTreated.includes("aspirated") &&
            AspiratedLumpConfirmedYes === "no" &&
            AspiratedWhenWasTheLumpAspirated.includes("between5And10Years")) ||
          AspiratedWhenWasTheLumpAspirated.includes("moreThan10Years")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form five

    tumourWhenTumourDetected: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("tumourOfAnyKind")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    tumourWhatTreatment: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("tumourOfAnyKind")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    tumourAnyOtherDetails: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("tumourOfAnyKind")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form six

    growthWhenTumourDetected: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("growth")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    growthWhatTreatment: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("growth")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    growthAnyOtherDetails: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("growth")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form seven

    lumpWhenTumourDetected: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("lump")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    lumpWhatTreatment: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("lump")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    lumpAnyOtherDetails: Yup.string().when(
      ["subFormsOfSixteen"],
      (subFormsOfSixteen) => {
        if (subFormsOfSixteen.includes("lump")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    noneOfTheAbove: Yup.string().when('subFormsOfSixteen', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formsixteen", {
        formSixteen: e,
        pointer: 17,
        formid: id,
      });
      dispatch(setPage(17));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched , resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following?
                    </p>

                    <SubFormOne
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormTwo
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormThree
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFour
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFive
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSix
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSeven
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
