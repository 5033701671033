import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const Message = (props) => {
  const token = useSelector((state) => state.auth.token);
  const history = useHistory();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Grid>
        <Typography
          style={{
            fontSize: "2rem",
            textAlign: "center",
            fontFamily: "Overpass, sans-serif",
            marginTop: "8rem",
            marginBottom: "3rem",
            padding: "0 15%"
          }}
        >
          {props.location?.state
            ? props.location.state.msg
            : "Submit successfully!"}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              fontSize: "1rem",
              backgroundColor: "#004aad",
              fontFamily: "Overpass, sans-serif",
              color: "white",
            }}
            onClick={() => {window.location.href=`/`;}}
          >
            Return to homepage
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default Message;
