import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";

const accidentTime = [
  { label: "Within the last 12 months", value: "Within the last 12 months" },
  { label: "Within 1-2 years", value: "Within 1-2 years" },
  { label: "Within 2-3 years", value: "Within 2-3 years" },
  { label: "Within 3-5 years", value: "Within 3-5 years" },
  { label: "Over 5 years ago", value: "Over 5 years ago" },
];
const accidentReducedWork = [
  { label: "10 days - 1 month", value: "10 days - 1 month" },
  { label: "1 month - 3 months", value: "1 month - 3 months" },
  { label: "Over 3 months", value: "Over 3 months" },
];
const resetArray = [
  { name: "whenAccident", type: "" },
  { name: "workReducedWhenAccident", type: "" },
  { name: "isAccidentRecovered", type: "" },
  { name: "accidentNotRecoveredInguries", type: "" },
];
export default function SubForm23({ values, setFieldValue, setFieldTouched }) {
  const resetFoldYesNo = (e, val, setFieldValue, setFieldTouched, arr) => {
    if (e.target.value === val) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    }
  };
  return (
    <Fragment>
      <YesNoQuestion
        name="isAccidentHappend"
        question=""
        onClick={(e) =>
          resetFoldYesNo(e, "false", setFieldValue, setFieldTouched, resetArray)
        }
      />
      {values.isAccidentHappend === "true" && (
        <Fragment>
          <RadioMulti
            question="When did your accident occur?"
            name="whenAccident"
            items={accidentTime}
            isSubForm
          />
          <RadioMulti
            question="How much time were you off work or had a reduced working capacity?"
            name="workReducedWhenAccident"
            items={accidentReducedWork}
            isSubForm
          />
          <YesNoQuestion
            name="isAccidentRecovered"
            question="Have you fully recovered from the inguries sustained in your accident?"
            isSubForm
          />
          {values.isAccidentRecovered === "false" && (
            <AnswerBox
              name="accidentNotRecoveredInguries"
              label="Please provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
