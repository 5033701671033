import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Col, Container, ListGroup, Row, Tab } from "react-bootstrap";
import "./ViewUnderwriterReplies.css";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import UnderwriterReplyTable from "../../components/ViewUnderwriterReplies/UnderwriterReplyTable";
import MobileViewport from "../mobileViewport/MobileViewport";

const ViewUnderwriterReplies = () => {
  const { assessmentID } = useParams();
  const [underwriterReplies, setUnderwriterReplies] = useState([]);
  const [clientData, setClientData] = useState({});

  const getClientData = async () => {
    try {
      const response = await axios.post("/form/pointer", {
        formid: assessmentID,
      });
      if (response.data) {
        setClientData(response.data.client);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUnderwriterReplies = async () => {
    const response = await axios.post("/underwriterReply/", {
      assessmentID: assessmentID,
    });
    setUnderwriterReplies(response.data);
  };

  useEffect(() => {
    getUnderwriterReplies();
    getClientData();
  }, [assessmentID]);

  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="h-100">
          <NavBar />
          <Col className="p-4">
            <Heading
              heading={`${clientData.name}'s underwriter preassessment outcomes`}
            />
            <Row>
              <Tab.Container
                id="underwriter-replies-tabs"
                defaultActiveKey="#reply-1"
              >
                <Col xs={3}>
                  <ListGroup className="scrollable-element">
                    {underwriterReplies.map((reply, index) => (
                      <ListGroup.Item action href={`#reply-${index + 1}`}>
                        {`${reply.underwriterReplyForm.underwriterName} - ${reply.underwriterReplyForm.underwriterCompany}`}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col xs={9}>
                  <Tab.Content className="scrollable-element">
                    {underwriterReplies.map((reply, index) => (
                      <Tab.Pane eventKey={`#reply-${index + 1}`}>
                        <UnderwriterReplyTable reply={reply} />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default ViewUnderwriterReplies;
