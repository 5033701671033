import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "isChestDiagnosed", type: "" },
  { name: "isAntibioticsLimited", type: "" },
  { name: "isReqHospital", type: "" },
  { name: "conditionDiagTreatment", type: "" },
  { name: "is3AttPerYr", type: "" },
];

export default function FormOtherRespiratoryDisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Other respiratory disorder",
            value: "OtherRespiratoryDisorder",
          },
        ]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("OtherRespiratoryDisorder") && (
        <Fragment>
          <YesNoQuestion
            question="Was your respiratory complaint diagnosed as a chest infection?"
            name="isChestDiagnosed"
            isSubForm
          />
          <YesNoQuestion
            question="Was your medication limited to antibiotics?"
            name="isAntibioticsLimited"
            isSubForm
          />
          <YesNoQuestion
            question="Was hospitalisation required?"
            name="isReqHospital"
            isSubForm
          />
          {values.isReqHospital === "true" &&
            values.illness.includes("OtherRespiratoryDisorder") && (
              <AnswerBox
                name="conditionDiagTreatment"
                label="Please provide details of the condition and diagnosis as well as any treatment provided"
                isSubForm
              />
            )}
          <YesNoQuestion
            question="Do you suffer more than 3 attacks per year?"
            name="is3AttPerYr"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
