import React, { Suspense } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Login from "./components/layouts/Login.js";
import NotFound from "./components/layouts/PageNotFound";
import PermissionDenied from "./components/layouts/PermissionDenied";
import ForgotPassword from "./components/layouts/ForgotPassword";
import Account from "./components/Account/Account";
import Contact from "./components/contact/Contact";
import Signup from "./pages/signup/Signup";
import SignupNext from "./pages/signupnext/SignupNext";
import Security from "./pages/security/Security";
import Preferences from "./pages/preferences/Preferences";
import "../src/index.css";

// Redux
import { Provider } from "react-redux";
import store from "./store/store";

// protected route
import ProtectedRoute from "./components/common/ProtectedRoute";
import { routes } from "./routes";
import Welcomepage from "./pages/welcome/Welcomepage";
import HeaderTop from "./components/headerTop/HeaderTop";
import Drawer from "./components/layouts/Drawer";
import Resourcespage from "./components/resources/Resourcespage";
import Payment from "./pages/payment/Payment";
import Success from "./components/success/Success";
import Cancel from "./components/cancel/Cancel";
import Auth from "./components/common/Auth";
import VerfyAccount from "./components/verfyAccount/VerfyAccount";
import SetPassword from "./pages/setPassword/SetPassword";
import GoTop from "./components/scrollTop/ScrollToTop";
import FormFour from "./components/forms/FormFour";
import FormView from "./pages/formView/FormView";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import UnderwriterReply from "./pages/underwriter/UnderwriterReply";
import ViewUnderwriterReply from "./pages/advisorDashboard/ViewUnderwriterReplies";
import Form from "./pages/Form/Form";
import Videopage from "./components/howto/Videopage";
import Message from "./components/message/Message";
import Conversation from "./pages/conversation/Conversation";
import EmailTemplate from "./pages/emailTemplate/EmailTemplate";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spdiv: {
    height: 178,
    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
    [theme.breakpoints.down("xs")]: {
      height: 25,
    },
  },
}));

function App(props) {
  const classes = useStyles();
  return (
    <>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Auth>
            <Router history={history}>
              <Suspense fallback={<div>Please wait...</div>}>
                <GoTop scrollStepInPx="50" delayInMs="30" />
                <Switch>
                  <Route path="/" exact component={Welcomepage}></Route>
                  <Route path="/login" exact component={Login}></Route>
                  <Route path="/contact" exact component={Contact}></Route>
                  <Route path="/signup" exact component={Signup}></Route>
                  <Route path="/details" exact component={SignupNext}></Route>
                  <Route
                    path="/preferences"
                    exact
                    component={Preferences}
                  ></Route>
                  <Route
                    path="/success-message"
                    exact
                    component={Message}
                  ></Route>
                  <Route
                    path="/formview/:assessmentID"
                    exact
                    component={FormView}
                  ></Route>
                  <Route
                    path="/verify-account/:email/:verificationToken/:arNumber/:firstName"
                    component={VerfyAccount}
                    exact
                  ></Route>
                  <Route
                    path="/set-password/:email/:verificationToken/"
                    component={SetPassword}
                    exact
                  ></Route>
                  <Route path="/payment" component={Payment} exact></Route>
                  <Route path="/success" component={Success} exact></Route>
                  <Route path="/cancel" component={Cancel} exact></Route>
                  <Route
                    path="/form/:id/:advisor/:client"
                    component={Form}
                    exact
                  ></Route>
                  <Route
                    path="/underwriter-reply/:assessmentID/:underwriterID"
                    component={UnderwriterReply}
                    exact
                  ></Route>
                  <Route
                    path="/view-underwriter-replies/:assessmentID"
                    component={ViewUnderwriterReply}
                    exact
                  ></Route>
                  <Route
                    path="/securityQuestions"
                    exact
                    component={Security}
                  ></Route>
                  <Route
                    path="/how-to-videos"
                    exact
                    component={Videopage}
                  ></Route>
                  <Route
                    path="/resources"
                    exact
                    component={Resourcespage}
                  ></Route>
                  <Route
                    path="/privacy-policy"
                    exact
                    component={PrivacyPolicy}
                  ></Route>
                  <Route
                    path="/terms-and-conditions"
                    exact
                    component={TermsAndConditions}
                  ></Route>
                  <Route
                    path="/conversation"
                    exact
                    component={Conversation}
                  ></Route>
                  <Route
                    path="/emailTemplate"
                    exact
                    component={EmailTemplate}
                  ></Route>
                  {routes
                    .flatMap((items) => items.children)
                    .map(({ component, path }) => {
                      return (
                        <ProtectedRoute
                          component={component}
                          exact
                          path={path}
                          key={path}
                        />
                      );
                    })}
                  <Route
                    path="/permission-denied"
                    exact
                    component={PermissionDenied}
                  ></Route>
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPassword}
                  ></Route>
                  <Route path="/account" exact component={Account}></Route>
                  <Route component={NotFound}></Route>
                </Switch>
              </Suspense>
            </Router>
          </Auth>
        </MuiPickersUtilsProvider>
      </Provider>
    </>
  );
}

export default App;
