import React, { Fragment, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { toggleDrawer } from "../../store/actions/authActions";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
// import FaceIcon from "@material-ui/icons/Face";

import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axios";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  self: {
    backgroundColor: "blue",
  },
  menuButton: {},
  flexGrow: {
    marginLeft: "auto",
  },
  chipStyles: {
    backgroundColor: "white",
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  rehide: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textsize: {
    fontFamily: "Overpass, sans-serif",
  },
}));

function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();

  // eslint-disable-next-line
  let [image, setImage] = useState("");
  let [role, setRole] = useState("");
  let dispatch = useDispatch();

  let token = useSelector((state) => state.auth.token);
  const [prefred, setPrefred] = useState(false);

  let uname = useSelector((state) => state.auth.name);

  const toggle = () => {
    dispatch(toggleDrawer());
  };

  const loadData = async () => {
    try {
      const data = await axios.get("/auth/me");
      setImage(data.data.logo);
      setRole(data.data.role);
      setPrefred(data.data.prefred);
    } catch {
      console.log("User not authenticated");
    }
  };

  useEffect(() => {
    loadData();
  }, [token, uname]);
  // check ci cd

  const advisorDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#advisor`;
  };
  const videoDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#videodemo`;
  };
  const howDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#how`;
  };
  const whyDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#why`;
  };
  const planDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#pricing`;
  };
  const clientDirect = () => {
    const path = "/client-create";
    history.push(path);
  };
  const UnderwritersDirect = () => {
    const path = "/clients-submitted";
    history.push(path);
  };
  const ReviewDirect = () => {
    const path = "/assessment-status";
    history.push(path);
  };
  const PreferencesDirect = () => {
    const path = "/preferences";
    history.push(path);
  };
  const videonDirect = () => {
    const path = "/how-to-videos";
    history.push(path);
  };
  return (
    <Fragment>
      {token && role === "advisor" && !prefred ? (
        <></>
      ) : (
        <AppBar position="relative">
          <Toolbar style={{ backgroundColor: "#000000" }} variant="dense">
            {!(!token && !isMobile) && (
              <IconButton
                onClick={toggle}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            {token && role === "advisor" && (
              <Fragment>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                  }}
                >
                  <Grid className={classes.rehide}>
                    <a
                      href="/"
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Pre Assess Me{" "}
                      </Typography>
                    </a>
                  </Grid>{" "}
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={clientDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Add a New Client{" "}
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={UnderwritersDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Underwriters Responses
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={ReviewDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Review Assessment Status
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={PreferencesDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Your Preferences
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={videonDirect}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        How-to Videos
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Fragment>
            )}
            {!token && (
              <Fragment>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                  }}
                >
                  <Grid className={classes.rehide}>
                    <a
                      href="/"
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Pre Assess Me{" "}
                      </Typography>
                    </a>
                  </Grid>{" "}
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={videoDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Video Demonstration{" "}
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={advisorDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Designed by an Adviser
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={howDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        How Does It Work?
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={whyDirect}
                      className={classes.textsize}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Why Should I Use It?
                      </Typography>
                    </a>
                  </Grid>
                  <Grid className={classes.hide}>
                    <a
                      href="/"
                      onClick={planDirect}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Typography className={classes.textsize}>
                        Plans & Pricing
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Toolbar>
        </AppBar>
      )}
    </Fragment>
  );
}

export default NavBar;
