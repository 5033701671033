// Utility function used on 'formview' pages to display cleanly formatted and readable strings in place of database returned strings

export default (data) => {
  switch (data) {
    // Generic data
    case "yes":
      return "Yes";
    case "no":
      return "No";
    case "true":
      return "Yes";
    case "false":
      return "No";
    case "left":
      return "Left";
    case "right":
      return "Right";
    case "both":
      return "Both";
    case "other":
      return "Other";
    case "NoneOfTheAbove":
      return "None";

    // Lengths of time
    case "lessThanThreeYears":
      return "Less than 3 years ago";
    case "threeToSevenYears":
      return "3-7 years ago";
    case "moreThanSevenYears":
      return "More than 7 Years ago";
    case "moreThanTwelve":
      return "More than 12";
    case "lessThanTwelve":
      return "Less than 12";
    case "morethantwo":
      return "More than 2 years ago";
    case "lessthantwo":
      return "Less than 2 years ago";
    case "lessthansix":
      return "Less than 6 months ago";
    case "sixtotwelve":
      return "6-12 months ago";
    case "morethantwelve":
      return "More than 12 months ago";
    case "between6and12Months":
      return "Between 6 and 12 months";
    case "lt3MonthsAgo":
      return "Less than 3 months ago";
    case "3to12Months":
      return "3-12 months ago";
    case "over12Months":
      return "Over 12 months ago";
    case "lessThan3Months":
      return "Less than 3 months";
    case "between3and8Months":
      return "3-6 months";
    case "between6and12Months":
      return "6-12 months";
    case "towelMonthsTo5Years":
      return "12 months to 5 years";
    case "between2And5Years":
      return "Two to five years ago";
    case "moreThan5Years":
      return "More than 5 years";
    case "noTimeOffWork":
      return "No time off work";
    case "noTime":
      return "No time off work";
    case "lessThan2Weeks":
      return "Less than 2 weeks";
    case "moreThan2Weeks":
      return "More than 2 weeks";
    case "lessThan2YearsAgo":
      return "Less than 2 years ago";
    case "MoreThan2YearsAgo":
      return "More than 2 years ago";
    case "WithinLast6Months":
      return "Within the last 6 months";
    case "6Months2YearsAgo":
      return "6 months to 2 years ago";
    case "TowFiveYearsAgo":
      return "Two to five years ago";
    case "moreThanFiveYears":
      return "More than 5 years ago";
    case "oneTwoWeeks":
      return "1-2 weeks";
    case "threeFourWeeks":
      return "3-4 weeks";
    case "moreThanFourWeeks":
      return "+4 weeks";
    case "lessThan12Months":
      return "Less than 12 months";
    case "OneTwoYears":
      return "1-2 years";
    case "TowThreeYears":
      return "2-3 years";
    case "ThreeFiveYears":
      return "3-5 years";
    case "lessThanTwo":
      return "Less than 2 years ago";
    case "betweenTwoAndFive":
      return "2-5 years ago";
    case "moreThanFive":
      return "5+ years ago";
    case "withinPast12Months":
      return "Within the past 12 months";
    case "moreThan12Months":
      return "More than 12 months ago";
    case "lessThan5years":
      return "Less than 5 years ago";
    case "between5And10Years":
      return "5-10 years ago";
    case "lessThan10years":
      return "Less than 10 years ago";
    case "moreThan10Years":
      return "More than 10 years ago";
    case "withinLast12m":
      return "Within the last 12 months";
    case "12mTo2Yr":
      return "12 months to 2 years ago";
    case "moreThan2Yrs":
      return "More than 2 years ago";

    // General health headings
    case "AsthmaVal":
      return "Asthma";
    case "Childhoodasthma":
      return "Childhood Asthma";

    // Neurological headings
    case "epilepsymal":
      return "Epilepsy - Petit mal";
    case "epilepsygrandmal":
      return "Epilepsy - Grand mal";
    case "epilepsyother":
      return "Epilepsy - Other types";
    case "epilepsyunknown":
      return "Epilepsy - Type unknown";
    case "fitsofanykind":
      return "Fits of any kind";

    // Mentall illness headings
    case "depression":
      return "Mild Depression/Postnatal depression";
    case "eatingDisorder":
      return "Eating Disorder including Anorexia, Nervosa or Bulimia";
    case "generalAnxiety":
      return "Anxiety including generalised anxiety, panic or phobic disorder";
    case "depressiveIllness":
      return "Manic Depressive illness, major depression, bipolar disorder";
    case "addiction":
      return "Alcohol, other substance abuse or addiction";
    case "traumaticStress":
      return "Post-traumatic stress";
    case "psychoticDisorder":
      return "Schizophrenia or any other psychotic disorder";
    case "chronicTiredness":
      return "Stress, sleeplessness, chronic tiredness";

    // Musculoskeletal headings
    case "rsi":
      return "RSI";
    case "Back_or_neck_disorder":
      return "Back or neck disorder";
    case "trauma_or_accident":
      return "Trauma or accident";
    case "muscle_Disorder":
      return "Muscle disorder";

    // Cancer headings
    case "cancerSkin":
      return "Skin Cancer";

    // Blood headings
    case "Otherblooddisorder":
      return "Other Blood Disorder";

    // General responses
    case "lifelongNonSmoker":
      return "Life long non-smoker";

    // General health responses
    case "pending":
      return "Considering genetic testing or awaiting results";
    case "1To5Attacks":
      return "1 - 5 attacks";
    case "6To10Attacks":
      return "6 - 10 attacks";
    case "mT10Attacks":
      return "More than 10 attacks";
    case "BarrettsOesophagus":
      return "Barrett's oesophagus";
    case "oesophagealCancer":
      return "Oesophageal cancer";
    case "AesophagealStricture":
      return "Oesophageal stricture";
    case "1To5Attacks":
      return "One to five attacks";
    case "6To10Attacks":
      return "Six to ten attacks";
    case "mT10Attacks":
      return "More than ten attacks";

    // Musculoskeletal responses
    case "rightWrist":
      return "Right wrist";
    case "leftWrist":
      return "Left Wrist";
    case "rightShoulder":
      return "Right shoulder";
    case "leftShoulder":
      return "Left Shoulder";
    case "rightElbow":
      return "Right elbow";
    case "leftElbow":
      return "Left Elbow";
    case "rightHand":
      return "Right hand";
    case "leftHand":
      return "Left hand";
    case "other":
      return "Other";
    case "hands":
      return "Hands";
    case "wrists":
      return "Wrists";
    case "smallJointsOfTheFeet":
      return "Small joints of the fee";
    case "shoulders":
      return "Shoulders";
    case "elbows":
      return "Elbows";
    case "knees":
      return "Knees";
    case "ankles":
      return "Ankles";
    case "SkullFracture":
      return "Skull fracture";
    case "SpinalFracture":
      return "Spinal Fracture";
    case "Hip":
      return "Hip";
    case "SHoulder":
      return "Shoulder";
    case "leftArm":
      return "Left Arm";
    case "rightArm":
      return "Right Arm";
    case "leftLeg":
      return "Left Leg";
    case "rightLeg":
      return "Right Leg";
    case "rightFoot":
      return "Right foot";
    case "leftFoot":
      return "Left foot";
    case "Ribs":
      return "Ribs";
    case "Finger":
      return "Finger(s)";
    case "Toe":
      return "Toe(s)";
    case "Hand":
      return "Hand(s)";
    case "ClavicleCollarbone":
      return "Clavicle / Collarbone";
    case "noReoccurrences":
      return "No reoccurrences";
    case "one&Tow":
      return "1 to 2";
    case "tow&five":
      return "2 to 5";
    case "moreThan5":
      return "More than 5";
    case "SymptomsAreOngoing":
      return "Symptoms are ongoing";
    case "Humerus":
      return "Humerus";
    case "Radius":
      return "Radius";
    case "Ulna":
      return "Ulna";
    case "Femur":
      return "Femur";
    case "Tibia":
      return "Tibia";
    case "Fibula":
      return "Fibula";
    case "OneOccupance":
      return "One-off occurrence only";
    case "OneTwoReoccurrences":
      return "One to two reoccurrences";
    case "MoreThanThreeTRoccurrences":
      return "More than three reoccurrences";

    // Organs responses
    case "prostateEnlargement":
      return "Prostate enlargement";
    case "prostateInflammation&prostatitis":
      return "Prostate inflammation/prostatitis";
    case "prostateCancer":
      return "Prostate cancer";
    case "one&TwoAttacks":
      return "1-3 attacks";
    case "moreThan3":
      return "More than 3 attacks";

    // Skin responses
    case "birthDefect":
      return "Birth Defect";
    case "industrial":
      return "Industrial";
    case "age":
      return "Age";
    case "tumour":
      return "Tumour";
    case "acne":
      return "Acne";
    case "ingrownHair":
      return "Ingrown hair";
    case "ringworm":
      return "Tinea (ringworm)";
    case "vitiligo":
      return "Vitiligo";
    case "warts":
      return "Warts";
    case "skinCancer":
      return "Skin cancer";
    case "cancerOtherThanSkin":
      return "Cancer other than skin (eg:BCC or SCC)";
    case "cryotherapy":
      return "Burnt or frozen off (cryotherapy)";
    case "excised":
      return "Cut out (excised)";
    case "creamOrOintment":
      return "Cream/ointment";
    case "notRemoved":
      return "Not removed";
    case "scalp":
      return "Scalp";
    case "elbowJoint":
      return "Elbow Joint";
    case "kneeJoints":
      return "Knee Joints";
    case "noSpecificCauseIdentified":
      return "No specific cause identified";
    case "anotherMedicalIssue":
      return "another medical issue (diabetes mellitus)";
    case "SideEffectOfMedication":
      return "Side effect of medication";
    case "TraumaInjuryOrAccident":
      return "Trauma; injury or accident";
    case "noSpecificCauseIdentified":
      return "No specific cause identified";
    case "anotherMedicalIssue":
      return "another medical issue (diabetes mellitus)";

    // Cancer responses
    case "one":
      return "1";
    case "moreThan1":
      return "More than 1";
    case "BCC":
      return "Basal Cell Carcinoma (BCC)";
    case "melanoma":
      return "Melanoma";
    case "SCC":
      return "Squamous Cell Carcinoma (SCC)";
    case "OtherUnknown":
      return "Other /unknown";
    case "noBiopsy":
      return "no biopsy";
    case "cryotherapy":
      return "Burnt or frozen off (cryotherapy)";
    case "creamOintment":
      return "Cream/ointment";
    case "surgery":
      return "Surgery - lumpectomy or mastectomy";
    case "aspirated":
      return "Aspirated (drained)";
    case "noTreatment":
      return "No treatment necessary";
    case "RegularCheckups":
      return "Still present with regular checkups";

    // Viruses responses
    case "HepatitisB":
      return "Hepatitis B";
    case "HepatitisC":
      return "Hepatitis C";
    case "HepatitisBCr":
      return "Hepatitis B Carrier";
    case "HepatitisCCr":
      return "Hepatitis C Carrier";
    case "AidsOrHiv":
      return "Acquired Immune Deficiency Syndrome (AIDS) or HIV";

    // Women's health responses
    case "simpleCyst":
      return "Simple Cyst";
    case "fibroid":
      return "Fibroid";
    case "PCOS":
      return "Polycystic Ovary Syndrome (PCOS)";
    case "hormonalInbalance":
      return "Hormonal imbalance";
    case "menopause":
      return "Menopausal/menopause";
    case "fibroids":
      return "Fibroids";
    case "IUD":
      return "IUD (intrauterine device)";
    case "polyps":
      return "Polyps";

    // Misc responses
    case "noTreatment":
      return "No treatment";
    case "longSightedness":
      return "Long-sightedness";
    case "shortSightedness":
      return "Short-sightedness";
    case "astigmatism":
      return "Astigmatism";
    case "colourBlindness":
      return "Colour Blindness";
    case "cataract":
      return "Cataract";
    case "glaucoma":
      return "Glaucoma";

    // Employment responses
    case "employee":
      return "Employee";
    case "soleTrader":
      return "Self employed - sole trader";
    case "partnership":
      return "Self employed - partnership";
    case "ownCompany":
      return "Self employed - employee of own company";
    case "trust":
      return "Self employed - trust";
    case "homeDuties":
      return "Home duties";
    case "student":
      return "Student";
    case "unemployed":
      return "Unemployed";
    case "tradeQualification":
      return "Trade Qualification";
    case "professionalQualification":
      return "Professional Qualification";
    case "tertiaryQualification":
      return "Tertiary Qualification";
    case "lessThan35Weeks":
      return "Less than 35 weeks";
    case "moreThan35Weeks":
      return "More than 35 weeks";
    case "between0And100Km":
      return "0-100km per week";
    case "between100And300km":
      return "100-300km per week";
    case "between300And500km":
      return "300-500km per week";
    case "moreThan500km":
      return "500km+ per week";
    case "between1%-20%":
      return "1%-20%";
    case "between20%-30%":
      return "20%-30%";
    case "moreThan30%":
      return "30%+";
    case "between5-10%":
      return "5-10%";
    case "between10-25%":
      return "10-25%";
    case "moreThan25%":
      return "25%+";
    case "airline":
      return "Aviation / Airline";
    case "eventsManagement":
      return "Events management";
    case "tourism":
      return "Tourism";
    case "corporateConferencingPublicSpeaking":
      return "Corporate Training, conferencing, public speaking";
    case "one":
      return "One";
    case "two":
      return "Two";
    case "three":
      return "Three";
    case "fourOrMore":
      return "Four or more";
    case "wasMoreThanFiveYear":
      return "Nil - I have been at my job for more than 5 years";
    case "wasStudent":
      return "Nil - I was a student";
    case "wasHousePerson":
      return "Nil - I was a houseperson";
    case "was travelling":
      return "Nil - I was travelling";
    case "Nil - I was unemployed":
      return "One";

    default:
      return data;
  }
};
