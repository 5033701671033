import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const causes = [
  {label:"Hormonal inbalance",value:"hormonalInbalance"},
  {label:"Menopausal/menopause",value:"menopause"},
  {label:"Fibroids",value:"fibroids"},
  {label:"IUD (intrauterine device)",value:"IUD"},
  {label:"Polyps",value:"polyps"},
  {label:"Other",value:"other"},
]
const resetArray =
[
  { name: 'consultAbnormalVaginalBleeding', type: '' },
  { name: 'causeAbnormalVaginalBleeding', type: '' },
  { name: 'reqFibroidsInvestigation', type: '' },
  { name: 'proceFibroidsInvestigation', type: '' },
  { name: 'isRecoveredIUD', type: '' },
  { name: 'iudRecoverdNoDetails', type: '' },
  { name: 'otherOrPolypsDetails', type: '' },
  { name: 'notConsultedDetails', type: '' }
];
  export default function FormAbnormalVaginalBleeding({ values, setFieldValue, setFieldTouched }) {
    const resetFold = (e,arr) => {
      if (!e.target.checked) {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      } else {
        setFieldValue("noneOfTheAbove", false, false);
        setFieldTouched("noneOfTheAbove", false);
      }
    };
    return (
       
      <Fragment>
        <CheckBox
          list={[
            {label: "Abnormal vaginal bleeding" ,value: "abnormalVaginalBleeding"},
          ]}
          name="illness"
          question=""
          onClick={(e) => resetFold(e,resetArray)}

      />
        {values.illness.includes("abnormalVaginalBleeding") && (
          <Fragment>
            
              <YesNoQuestion
                question="Have you consulted a doctor and had the symptoms investigated?"
                name="consultAbnormalVaginalBleeding"
                isSubForm
              />
              {values.consultAbnormalVaginalBleeding === "true" && (
                <Fragment>
                  <RadioMulti 
                    name="causeAbnormalVaginalBleeding"
                    question="What was the cause of the symptoms"
                    items={causes}
                    isSubForm
                  />
                  {values.causeAbnormalVaginalBleeding === "fibroids" && (
                    <Fragment>
                      <YesNoQuestion 
                        name="reqFibroidsInvestigation"
                        question="Did you require further investigations? (biopsy or surgery)"
                        isSubForm
                      />
                      {values.reqFibroidsInvestigation === "true" && (
                        <AnswerBox 
                          name="proceFibroidsInvestigation"
                          label="What procedure did you have and when was it operated on?"
                          isSubForm
                        />
                      )}
                    </Fragment>
                  )}

                  {values.causeAbnormalVaginalBleeding === "IUD" && (
                    <Fragment>
                      <YesNoQuestion
                        name="isRecoveredIUD"
                        question="Have your symptoms been treated and have you made a full recovery?"
                        isSubForm
                      />
                      {values.isRecoveredIUD === "false" && (
                        <AnswerBox
                          name="iudRecoverdNoDetails"
                          label="Please provide details"
                          isSubForm
                        />
                      )}
                    </Fragment>
                  )}

                  {(values.causeAbnormalVaginalBleeding === "polyps" || 
                    values.causeAbnormalVaginalBleeding === "other" ) && (
                      <AnswerBox
                        name="otherOrPolypsDetails"
                        label="Please provide details"
                        isSubForm
                      />
                  )}
                </Fragment>
              )}
              {values.consultAbnormalVaginalBleeding === "false" && (
                <AnswerBox
                name="notConsultedDetails"
                label="Please provide details including symptoms and commencement date"
                  isSubForm
                />
              )}
          </Fragment> 
          )}
      </Fragment>
    )
  }