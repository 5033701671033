import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  img: {
    width: "90%",
    height: "90%",
  },
  header: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "2rem",
    marginLeft: 38,
    marginBottom: 80,
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  paragraph: {
    marginLeft: 40,
    fontFamily: "Overpass, sans-serif",
  },
  text: {
    marginTop: 30,
    fontSize: "1rem",
    textAlign: "justify",
    fontFamily: "Overpass, sans-serif",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
