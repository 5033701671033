import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const itemsList = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  {
    label: "No treatment",
    value: "noTreatment",
  },
];

const resetArray = [
  { name: "infectionOnly", type: "" },
  { name: "bladderDisorderSymptoms", type: "" },
  { name: "bladderDisorderWhenThisOccur", type: "" },
  { name: "bladderDisorderWhatTreatments", type: "" },
  { name: "treatmentLimitedToAntibiotics", type: "" },
  { name: "treatmentLimitedToAntibioticsDetails", type: "" },
  { name: "withinLast2YearsAnyMedicalInvestigation", type: "" },
  { name: "investigationDetails", type: "" },
];
export default function SubFormFive({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Bladder Disorder", value: "bladderDisorder" }]}
        name="subFormsOfFormSeventeen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormSeventeen.includes("bladderDisorder") && (
        <RadioMulti
          question="Was the condition diagnosed as an infection only?"
          name={"infectionOnly"}
          items={items}
          isSubForm
        />
      )}
      {values.subFormsOfFormSeventeen.includes("bladderDisorder") &&
        values.infectionOnly === "no" && (
          <AnswerBox
            label="What were your symptoms?"
            name="bladderDisorderSymptoms"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("bladderDisorder") &&
        values.bladderDisorderSymptoms && (
          <AnswerBox
            label="When did this occur?"
            name="bladderDisorderWhenThisOccur"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("bladderDisorder") &&
        values.bladderDisorderWhenThisOccur && (
          <AnswerBox
            label="What was the treatment that was recommended by a medical professional?"
            name="bladderDisorderWhatTreatments"
            isSubForm
          />
        )}
      {(values.bladderDisorderWhatTreatments ||
        values.infectionOnly === "yes") &&
        values.subFormsOfFormSeventeen.includes("bladderDisorder") && (
          <RadioMulti
            question="Was the treatment limited to antibiotics?"
            name={"treatmentLimitedToAntibiotics"}
            items={itemsList}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("bladderDisorder") &&
        values.treatmentLimitedToAntibiotics === "no" && (
          <AnswerBox
            label="Please provide details?"
            name="treatmentLimitedToAntibioticsDetails"
            isSubForm
          />
        )}
      {(values.treatmentLimitedToAntibiotics === "yes" ||
        values.treatmentLimitedToAntibioticsDetails !== "" ||
        values.treatmentLimitedToAntibiotics === "noTreatment") &&
        values.subFormsOfFormSeventeen.includes("bladderDisorder") && (
          <RadioMulti
            question="Within the last 2 years did a medical practitioner recommend further investigations?"
            name={"withinLast2YearsAnyMedicalInvestigation"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("bladderDisorder") &&
        values.withinLast2YearsAnyMedicalInvestigation === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="investigationDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
