import React, { Fragment, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Button, Box } from "@material-ui/core";
import photo from "../../assets/preAssesLogo.png";
import Imge3 from "../../assets/images/im4.PNG";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../layouts/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";
import axios from "../../lib/axios";
import { display } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "10px",
    fontSize: "1rem",
    backgroundColor: "#000000",
    color: "#fff",
    fontFamily: "Overpass, sans-serif",
    // width: "29.5%",
    fontWeight: 100,
    height: 50,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
  buttonblue: {
    borderRadius: "10px",
    fontSize: "1rem",
    backgroundColor: "#004aad",
    textTransform: "capitalize",
    fontFamily: "Overpass, sans-serif",
    color: "#fff",
    marginLeft: theme.spacing(2),
    // width: "33%",
    fontWeight: 100,
    height: 50,
    "&:hover": {
      backgroundColor: "#004aad",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hide: {
    display: "flex",
    justifyContent: "space-between",
    height: 130,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function HeaderTop() {
  const token = useSelector((state) => state.auth.token);
  let dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const role = sessionStorage.getItem("role");
  console.log("role", role);
  const [prefred, setPrefred] = useState(false);

  const loadData = async () => {
    try {
      const data = await axios.get("/auth/me");
      setPrefred(data.data.prefred);
    } catch (error) {
      console.log("err", error);
    }
  };

  if (token) {
    loadData();
  }
  const loginDirect = () => {
    history.push("/login");
  };
  const signInDirect = () => {
    history.push("/signup");
  };
  const resourceDirect = () => {
    history.push("/resources");
  };
  const contactDirect = () => {
    history.push("/contact");
  };
  const handleLogout = () => {
    console.log("type of", typeof dispatch(logout()));
    history.push("/");
  };
  return (
    <>
      <Box
        className={classes.hide}
      >
        <NavLink
          to={
            token && role === "admin"
              ? "/dashboard"
              : token && role === "advisor"
              ? "/assessment-status"
              : "/"
          }
        >
          {token && role === "advisor" && !prefred ? (
            <img src={Imge3} className={classes.hide} alt="login" />
          ) : (
            <img src={photo} className={classes.hide} alt="login" />
          )}
        </NavLink>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
            padding: 10,
            height: 70,
          }}
        >
          {token ? (
            <Fragment>
              <Button
                variant="contained"
                className={classes.button}
                onClick={resourceDirect}
              >
                Resources
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={contactDirect}
              >
                Contact Us
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                variant="contained"
                className={classes.button}
                onClick={contactDirect}
              >
                SUPPORT
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                onClick={loginDirect}
              >
                SIGN IN
              </Button>

              <Button
                variant="contained"
                className={classes.buttonblue}
                onClick={signInDirect}
              >
                GET STARTED!
              </Button>
            </Fragment>
          )}
        </Box>
      </Box>
      <NavBar />
    </>
  );
}
