import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import RadioGroup from "../RadioMulti";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "qualificationsList", type: [] },
  { name: "divingAreas", type: [] },
  { name: "divingItems", type: [] },
  { name: "divingDepth", type: "" },
  { name: "divingFrequency", type: "" },
];


export default function Football({ values , setFieldValue, setFieldTouched }) {
  const scubaDivingItems = [
    {
      label: "Less than or equal to 30 m",
      value: "Less than or equal to 30 m",
    },
    {
      label: "31-40 metres",
      value: "31-40 metres",
    },
    {
      label: "Over 40 metres",
      value: "Over 40 metres",
    },
  ];

  const depth = [
    {
      label: "Less than 4 times a year",
      value: "Less than 4 times a year",
    },
    {
      label: "More than 5 times a year",
      value: "More than 5 times a year",
    },
  ];

  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Scuba Diving", value: "scubaDiving" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.activities.includes("scubaDiving") && (
        <Grid item md={12} sm={12} xs={12}>
          <CheckBox
            question=" Do you have any diving qualifications ? "
            list={[
              {
                label: "PADI",
                value: "PADI",
              },
              { label: "NAUIl", value: "NAUI" },
              {
                label: "SSI",
                value: "SSI",
              },
              {
                label: "None of the above",
                value: "None of the above",
              },
            ]}
            name="qualificationsList"
            isSubForm
          />
        </Grid>
      )}

      {values.activities.includes("scubaDiving") && (
        <Grid item md={12} sm={12} xs={12}>
          <CheckBox
            question="What
            areas do
            you dive
            in ? "
            list={[
              {
                label:
                  "Coastal waters/lakes/rivers/pits/Quarries /Sheltered waters",
                value:
                  "Coastal waters/lakes/rivers/pits/Quarries /Sheltered waters",
              },
              { label: "caves & Potholes", value: "Caves & Pot holes" },
              {
                label: "Exploration of wrecks",
                value: "Exploration of wrecks",
              },
              {
                label: "Diving for treasure or expeditions",
                value: "Diving for treasure of expeditions",
              },
            ]}
            name="divingAreas"
            isSubForm
          />
        </Grid>
      )}
      {values.activities.includes("scubaDiving") && (
        <Grid item md={12} sm={12} xs={12}>
          <CheckBox
            question="Do you
            do or
            use
            any of
            the
            following ? "
            list={[
              {
                label: "Diving Bell",
                value: "Diving Bell",
              },
              { label: "Free diving", value: "Free diving" },
              {
                label: "Hookah",
                value: "Hookah",
              },
              {
                label: "None of the above",
                value: "None of the above",
              },
            ]}
            name="divingItems"
            isSubForm
          />
        </Grid>
      )}
      {values.activities.includes("scubaDiving") && (
        <RadioGroup
          question="To what depths
              do you usually
              dive to in
              metres?"
          name="divingDepth"
          items={scubaDivingItems}
          isSubForm
        />
      )}
      {values.activities.includes("scubaDiving") && (
        <RadioGroup
          question="How many
          times a year do
          your dive these
          depths?"
          name="divingFrequency"
          items={depth}
          isSubForm
        />
      )}
    </Fragment>
  );
}
