import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const howLong = [
  {
    label: "Symptoms are ongoing",
    value: "SymptomsAreOngoing",
  },
  {
    label: "Less than 12 months",
    value: "lessThan12Months",
  },
  {
    label: "1-2 years",
    value: "OneTwoYears",
  },
  {
    label: "2-3 years",
    value: "TowThreeYears",
  },
  {
    label: "3-5 years",
    value: "ThreeFiveYears",
  },
  {
    label: "5+ years ago",
    value: "moreThanFiveYears",
  },
];

const timeList = [
  {
    label: "No time off work",
    value: "noTimeOffWork",
  },
  {
    label: "Less than 2 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "More than 2 weeks",
    value: "moreThan2Weeks",
  },
];
const resetArray = [
  { name: "disorderHowLongSymptomsHas", type: "" },
  { name: "surgicalTreatmentYes", type: "" },
  { name: "dateAndProcedureNames", type: "" },
  { name: "anyRestrictedYes", type: "" },
  { name: "disorderDetails", type: "" },
  { name: "disorderTimeHadOffWork", type: "" },
];

export default function SubFormOne({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Back or neck disorder", value: "Back_or_neck_disorder" },
        ]}
        name="subFormsOfFourteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFourteen.includes("Back_or_neck_disorder") && (
        <RadioMulti
          question="How long has it been since you last had symptoms or pain?"
          name="disorderHowLongSymptomsHas"
          items={howLong}
          isSubForm
        />
      )}
      {values.subFormsOfFourteen.includes("Back_or_neck_disorder") &&
        values.disorderHowLongSymptomsHas && (
          <RadioMulti
            question="Have you had any surgical treatment?"
            name={"surgicalTreatmentYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("Back_or_neck_disorder") &&
        values.surgicalTreatmentYes === "yes" && (
          <AnswerBox
            label="Please provide details including dates and procedure names?"
            name="dateAndProcedureNames"
            isSubForm
          />
        )}
      {(values.dateAndProcedureNames || values.surgicalTreatmentYes === "no") &&
        values.subFormsOfFourteen.includes("Back_or_neck_disorder") && (
          <RadioMulti
            question="Are you in any way restricted in your daily activities as a consequence of this condition?"
            name={"anyRestrictedYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("Back_or_neck_disorder") &&
        values.anyRestrictedYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="disorderDetails"
            isSubForm
          />
        )}
      {(values.disorderDetails || values.anyRestrictedYes === "no") &&
        values.subFormsOfFourteen.includes("Back_or_neck_disorder") && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"disorderTimeHadOffWork"}
            items={timeList}
            isSubForm
          />
        )}
    </Fragment>
  );
}
