import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <Row>
      <Col as="footer" xs={12} className="d-flex gap-3 px-4 py-2 justify-content-end bg-light">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-and-conditions">Terms and conditions</a>
      </Col>
    </Row>
  );
};

export default Footer;
