import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "chronicLungDiagnosis", type: "" },
  { name: "howLongCondition", type: "" },
  { name: "requiredTreatment", type: "" },
];

export default function FormChronicLungDisease({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Chronic Lung Disease", value: "ChronicLungDisease" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("ChronicLungDisease") && (
        <Fragment>
          <AnswerBox
            label="Provide details of chronic lung disease diagnosis"
            name="chronicLungDiagnosis"
            isSubForm
          />
          <AnswerBox
            label="How long did you have this condition?"
            name="howLongCondition"
            isSubForm
          />
          <AnswerBox
            label="What treatment was required?"
            name="requiredTreatment"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
