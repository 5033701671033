import React, { Fragment, useState } from 'react';
import { TableCell, Button, Chip } from '@material-ui/core';
import AdvisorDetailsModal from '../../components/advisorDetails/AdvisorDetailsModal';
import axios from '../../lib/axios';

import ReusableTable from '../../components/layouts/ReusableTable';

const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'AR Number',
    value: 'arNumber',
  },
  {
    text: 'Logo',
    value: 'image',
  },
  {
    text: 'Contact Number',
    value: 'mobile',
  },
  {
    text: 'Current Status',
    value: 'currentStatus',
  },
  {
    text: 'Change Status',
    value: 'status',
  },
  {
    text: 'View',
    value: 'view',
  },
];
export default function AdviserView(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ViewDetails = async (id) => {
    const data = await axios.get(`/user/${id}`);
    setData(data.data);
    handleClickOpen();
  };

  const TableB = ({ item, changeStatus, classes, onUpdate }) => {
    return (
      <Fragment>
        <TableCell key='name' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.firstName}
        </TableCell>
        <TableCell key='email' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.email}
        </TableCell>
        <TableCell
          key='arNumber'
          style={{ fontFamily: 'Overpass, sans-serif' }}
        >
          {item.arNumber}
        </TableCell>
        <TableCell key='image' style={{ fontFamily: 'Overpass, sans-serif' }}>
          <img
            src={item.logo}
            style={{ fontFamily: 'Overpass, sans-serif', maxHeight: '100px' }}
            alt=' '
          ></img>
        </TableCell>
        <TableCell key='mobile' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.contactNumber}
        </TableCell>
        {item.status ? (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            {' '}
            <Chip label='Active' className={classes.green} />
          </TableCell>
        ) : (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            <Chip label='Inactive' className={classes.red} />
          </TableCell>
        )}
        <TableCell key='status'>
          {item.status ? (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              className={classes.red}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              className={classes.green}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Activate
            </Button>
          )}
        </TableCell>
        <TableCell key='update'>
          <Button
            style={{ fontFamily: 'Overpass, sans-serif' }}
            variant='contained'
            className={classes.black}
            onClick={() => ViewDetails(item._id)}
          >
            view
          </Button>
        </TableCell>
        <AdvisorDetailsModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          data={data}
        />
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title='View Advisers'
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl='/user/getAdviser' //change
      statusChangeUrl='/user/update-status'
      updatePath='/client-update'
      {...props}
    />
  );
}
