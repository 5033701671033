import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDLptj1im4fMhIkTj9O3hsyQXYXhtRAaAI",
  authDomain: "pre-assess-me-live.firebaseapp.com",
  projectId: "pre-assess-me-live",
  storageBucket: "pre-assess-me-live.appspot.com",
  messagingSenderId: "1081654669495",
  appId: "1:1081654669495:web:ad53c32f9ba8b0c1c69d32",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
