import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const whichSide = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
  {
    label: "Both",
    value: "both",
  },
];
const causesOfHearing = [
  {
    label: "Birth Defect",
    value: "birthDefect",
  },
  {
    label: "Industrial",
    value: "industrial",
  },
  {
    label: "Age",
    value: "age",
  },
  {
    label: "Tumour",
    value: "tumour",
  },
  {
    label: "Other",
    value: "other",
  },
];

const resetArray = [
  { name: "causeOfHearing", type: [] },
  { name: "causeOfHearingDetails", type: "" },
  { name: "whichSideOFEars", type: "" },
  { name: "defectInHearingAnyRestricted", type: "" },
  { name: "defectInHearingAnyRestrictedDetails", type: "" },
  { name: "isYourHaringDeteriorating", type: "" },
];
export default function SubFormFive({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Defect in hearing", value: "defectInHearing" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("defectInHearing") && (
        <CheckBox
          question="What is the cause of your hearing problem?"
          list={causesOfHearing}
          name="causeOfHearing"
          isSubForm
        />
      )}
      {(values.causeOfHearing.includes("tumour") ||
        values.causeOfHearing.includes("other")) &&
        values.subFormsOfFormFifteen.includes("defectInHearing") && (
          <AnswerBox
            label="Please provide details?"
            name="causeOfHearingDetails"
            isSubForm
          />
        )}
      {(values.causeOfHearingDetails !== "" ||
        values.causeOfHearing.includes("birthDefect") ||
        values.causeOfHearing.includes("industrial") ||
        values.causeOfHearing.includes("age")) &&
        values.subFormsOfFormFifteen.includes("defectInHearing") && (
          <RadioMulti
            question="Indicate the ears affected?"
            name={"whichSideOFEars"}
            items={whichSide}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInHearing") &&
        values.whichSideOFEars && (
          <RadioMulti
            question="Are you restricted in your work or daily activities by this condition?"
            name={"defectInHearingAnyRestricted"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInHearing") &&
        values.defectInHearingAnyRestricted === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="defectInHearingAnyRestrictedDetails"
            isSubForm
          />
        )}
      {(values.defectInHearingAnyRestricted === "no" ||
        values.defectInHearingAnyRestrictedDetails !== "") &&
        values.subFormsOfFormFifteen.includes("defectInHearing") && (
          <RadioMulti
            question="Is your hearing deteriorating?"
            name={"isYourHaringDeteriorating"}
            items={items}
            isSubForm
          />
        )}
    </Fragment>
  );
}
