import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "2rem",
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },

  img: {
    width: "70%",
    height: "70%",
  },
  button: {
    borderRadius: "10px",
    fontSize: "1.2rem",
    backgroundColor: "#000000",
    color: "#fff",
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 100,
    height: 50,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
}));
