import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const listOfSymptoms = [
  {
    label: "Symptoms of heart or circulatory system disorder",
    value: "Symptoms of heart or circulatory system disorder",
  },
  {
    label: "Kidney disorder or protein in the urine",
    value: "Kidney disorder or protein in the urine",
  },
  {
    label: "Eye disorder other than short or long sightednesse",
    value: "Eye disorder other than short or long sightednesse",
  },
  {
    label: "Diziness, fainting or stroke",
    value: "Diziness, fainting or stroke",
  },
];

const resetArray = [
  //HighBloodPressure
  { name: "HighBloodPressure", type: "" },
  { name: "firstDiagnosedDate", type: "" },
  { name: "bloodPreassureReading", type: "" },
  { name: "medicationAndDosage", type: "" },
  { name: "heartTreatment", type: "" },
  { name: "whenDiagnosedHBP", type: "" },
  { name: "HowOftenDoYouFollowUp", type: "" },
  { name: "LastBloodPressureReading", type: "" },
  { name: "medicationAndDosage2", type: "" },
  { name: "listOfSymptoms", type: [] },
  { name: "hBPsymtoms", type: [] },
];
export default function FormHighBloodPressure({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "High blood pressure", value: "HighBloodPressure" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("HighBloodPressure") && (
        <Fragment>
          <AnswerBox
            label="When was high blood pressure first diagnosed?"
            name="firstDiagnosedDate"
            isSubForm
          />
          <AnswerBox
            label="What was the blood pressure reading?"
            name="bloodPreassureReading"
            isSubForm
          />
          <AnswerBox
            label="What is your current medication and dosage?"
            name="medicationAndDosage"
            isSubForm
          />
          <YesNoQuestion
            question="Are you still on this treatment?"
            name="heartTreatment"
            isSubForm
          />
          {values.heartTreatment === "false" && (
            <AnswerBox
              label="When did you discontinue the treatment and why?"
              name="whenDiagnosedHBP"
              isSubForm
            />
          )}
          {values.heartTreatment && (
            <Grid container md={12} sm={12} xs={12}>
              <AnswerBox
                label="How often do you follow up?"
                name="HowOftenDoYouFollowUp"
                isSubForm
              />
              <AnswerBox
                label="What was the blood pressure reading?"
                name="LastBloodPressureReading"
                isSubForm
              />
              <AnswerBox
                label="What is your current medication and dosage?"
                name="medicationAndDosage2"
                isSubForm
              />
              <CheckBox
                question="Have you suffered any of the following?"
                name="hBPsymtoms"
                list={listOfSymptoms}
                isSubForm
              />
            </Grid>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
