import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "tremorDiagnosisDetails", type: "" },
  { name: "tremorSymptoms", type: "" },
  { name: "tremorWorkStatus", type: "" },
  { name: "tremorDailyLife", type: "" },
  { name: "tremorDetails", type: "" },
  
];
export default function Tremor({ values , setFieldValue, setFieldTouched}) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Tremor", value: "tremor" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("tremor") && (
        <AnswerBox
          label="Please provide details of the diagnosis."
          name="tremorDiagnosisDetails"
          isSubForm
        />
      )}
      {values.tremorDiagnosisDetails && (
        <AnswerBox
          label="What were your symptoms?"
          name="tremorSymptoms"
          isSubForm
        />
      )}
      {values.tremorSymptoms && (
        <AnswerBox
          label="Were you required to be off work? If yes, please provide the length of time."
          name="tremorWorkStatus"
          isSubForm
        />
      )}
      {values.tremorWorkStatus && (
        <YesNoQuestion
          question="Does this condition restrict your activities or daily life in any way?"
          name="tremorDailyLife"
          isSubForm
        />
      )}
      {values.tremorDailyLife === "true" && (
        <AnswerBox
          label="Please provide details"
          name="tremorDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
