import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "2.1rem",
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",

    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      paddingLeft: 10,
    },
  },

  img: {
    width: "90%",
    height: "90%",
  },
  img2: {
    width: "80%",
    height: "80%",
  },

  form: {
    marginLeft: 100,

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  box: {
    display: "flex",
    justifyContent: "flex-end",
  },
  size: {
    [theme.breakpoints.down("xl")]: {
      width: 1200,
      height: 780,
      alignself: "center",
    },
    [theme.breakpoints.down("lg")]: {
      width: 700,
      height: 380,
      alignself: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: 600,
      height: 350,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: 700,
      height: 400,
      marginTop: -50,
      alignself: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: 350,
      height: 200,
      alignself: "center",
      marginTop: -70,
      marginLeft: 22,
    },
  },
  hide: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  displayFlex: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
  },
  field: {
    marginTop: "1.5rem",
    alignself: "center",
    borderRadius: "25px",
    marginBottom: 29,
    width: "85%",
    height: "30px",
  },
  button: {
    fontFamily: "Gilroy-Light, serif",
    textTransform: "capitalize",
    fontSize: "1rem",
    backgroundColor: "#000000",
    color: "#fff",
    width: "160px",
    fontWeight: 400,
    height: 40,
    marginLeft: 88,
    marginTop: 60,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  signupButton: {
    width: "60%",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "1rem",
    },
  },
  headerText: {
    fontFamily: "holiday",
    fontSize: "3rem",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.7rem",
    },
  },
  text: {
    fontFamily: "Overpass, sans-serif",
    color: "#fff",
    fontSize: "1rem",
    textAlign: "left",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  text1: {
    fontFamily: "Overpass, sans-serif",
    color: "#fff",
    fontSize: "0.9rem",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  loginbox: {
    backgroundColor: "#000000",
    justifyContent: "center",
    borderRadius: 60,
    alignContent: "center",
    alignItems: "center",
    height: 340,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 300,
    },
  },
  root: {
    marginTop: theme.spacing(1),
    height: "100%",
  },
  center: {
    textAlign: "center",
  },
  padding: {
    padding: theme.spacing(3),
  },
  paragraph: {
    textAlign: "center",
    color: "#000",
    fontSize: "1.5rem",
    fontFamily: "Overpass, sans-serif",
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  paragraph1: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontFamily: "Overpass, sans-serif",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}));

export const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#f4f4ee",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontSize: "1.2.6rem",
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // backgroundColor: "#ffffff",
        color: "#000000",
        width: "100%",
        // [theme.breakpoints.down("xl")]: {
        //   width: 750,
        // },
        // [theme.breakpoints.down("lg")]: {
        //   width: 450,
        // },
        // [theme.breakpoints.down("md")]: {
        //   height: "50px",
        //   fontSize: "1.2rem",
        //   width: 450,
        // },
        // [theme.breakpoints.down("sm")]: {
        //   height: "50px",
        //   fontSize: "1.1rem",
        //   width: 350,
        // },
        // [theme.breakpoints.down("xs")]: {
        //   height: "45px",
        //   fontSize: "1rem",
        //   width: 250,
        // },
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
}))(TextField);
