import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioGroup from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const annualAttakcs = [
  {
    label: "More than 12",
    value: "More than 12 attacks",
  },
  {
    label: "Less than 12",
    value: "Less than 12 attacks",
  },
];
const lastAttack = [
  {
    label: "Less than 3 years ago",
    value: "lessThanThreeYears",
  },
  {
    label: "3-7 years ago",
    value: "threeToSevenYears",
  },
  {
    label: "More than 7 Years ago",
    value: "moreThanSevenYears",
  },
];
const cause = [
  {
    label: "Head trauma occurring in the last 2 years",
    value: "headTraumaless",
  },
  {
    label: "Head trauma occurred more than 2 years ago",
    value: "headTraumamore",
  },
  {
    label: "Tumour",
    value: "tumour",
  },
  {
    label: "None of the above",
    value: "none",
  },
];
const resetArray = [
  { name: "epilepsyGradMalAnnualAttakcs", type: "" },
  { name: "epilepsyGradMalLastAttack", type: "" },
  { name: "epilepsyGradMalCause", type: "" },
  { name: "epilepsyGradMalDutyRestrictions", type: "" },
  { name: "epilepsyGradMalDetails", type: "" },
];

export default function EpilepsyGrandMal({ values, setFieldValue, setFieldTouched  }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Epilepsy - Grand Mal", value: "epilepsygrandmal" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("epilepsygrandmal") && (
        <RadioGroup
          question="How many attacks do you suffer annually on average?"
          name="epilepsyGradMalAnnualAttakcs"
          items={annualAttakcs}
          isSubForm
        />
      )}
      {values.epilepsyGradMalAnnualAttakcs && (
        <RadioGroup
          question="When was your last attack?"
          name="epilepsyGradMalLastAttack"
          items={lastAttack}
          isSubForm
        />
      )}
      {values.epilepsyGradMalLastAttack && (
        <RadioGroup
          question="Specify the cause of epilepsy"
          name="epilepsyGradMalCause"
          items={cause}
          isSubForm
        />
      )}
      {values.epilepsyGradMalLastAttack && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="epilepsyGradMalDutyRestrictions"
          isSubForm
        />
      )}
      {values.epilepsyGradMalDutyRestrictions === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="epilepsyGradMalDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
