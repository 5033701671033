import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "epilepsyUnknownTreatment", type: "" },
  { name: "epilepsyUnknownstartDate", type: "" },
  { name: "epilepsyUnknownRestrictedDuties", type: "" },
  { name: "epilepsyUnknownDetails", type: "" },
];

export default function EpilepsyUnknown({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Epilepsy - Type unknown", value: "epilepsyunknown" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("epilepsyunknown") && (
        <AnswerBox
          label="What treatment was provided?"
          name="epilepsyUnknownTreatment"
          isSubForm
        />
      )}

      {values.epilepsyUnknownTreatment && (
        <AnswerBox
          label="When did this occur?"
          name="epilepsyUnknownstartDate"
          isSubForm
        />
      )}
      {values.epilepsyUnknownstartDate && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="epilepsyUnknownRestrictedDuties"
          isSubForm
        />
      )}
      {values.epilepsyUnknownRestrictedDuties === "true" && (
        <AnswerBox
          label="Please provide details"
          name="epilepsyUnknownDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
