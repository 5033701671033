import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import axios from '../../lib/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#004AAD',
    color: 'white',
    fontFamily: 'Overpass, sans-serif',
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function Package(props) {
  const classes = useStyles();

  const [componentType, setComponentType] = useState('create');
  const [callingBackend, setCallingBackend] = useState(false);
  const [open, setOpen] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: '',
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      if (componentType === 'create') {
        await axios.post('/occupation', {
          name: e.name,
        });
        resetForm();
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Occupation created successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Company created successfully!',
          });
          setOpen(false);
          const path = '/occupation';
          props.history.push(path);
        }, 3000);
      } else {
        await axios.put(`/occupation/${props.location.state.id}`, e);
        props.history.push('/occupation');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Occupation already exists!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Occupation already exists!',
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Occupation creation failed!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Occupation creation failed!',
          });
          setOpen(false);
        }, 3000);
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const get = async (id) => {
    try {
      const data = await axios.get(`/occupation/${id}`);

      setInitialValues({
        name: data.data.name,
      });
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Data loading failed!',
        });
        setOpen(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setComponentType('update');
      get(props.location.state.id);
    }
    /* eslint-disable */
  }, [props.location.state]);

  // validation schema
  let validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Occupation Name Required!')
      .min(3, 'Min length for the name is 3')
      .max(35, 'Max length for the name is 35.'),
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    {componentType === 'create'
                      ? 'Create Occupation'
                      : 'Update Occupation'}
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='name'
                          label='Occupation Name'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          className={classes.button}
                          style={{ fontFamily: 'Overpass, sans-serif' }}
                          color={
                            componentType === 'create' ? 'primary' : 'secondary'
                          }
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          {componentType === 'create' ? 'create' : 'update'}
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>
                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
