import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Table } from "react-bootstrap";
import "./Modals.css";

// Utilities imports
import formatDate from "../../utils/formatDate";
import axios from "axios";

const AssessmentFullDetailsModal = (props) => {
  const [underwriterDetails, setUnderwriterDetails] = useState([]);

  const handleUnderwriterDetails = async () => {
    let underwriterDetailsResponses = [];
    if (props.modalShow && props.modalData.notifiedUnderwriters) {
      for (let i = 0; i < props.modalData.notifiedUnderwriters.length; i++) {
        const response = await axios
          .get(
            `/underwriter/${props.modalData.notifiedUnderwriters[i].underwriter}`
          )
          .catch((err) => console.log(err));
        if (response) {
          underwriterDetailsResponses.push(response.data);
        }
      }
    }
    setUnderwriterDetails(underwriterDetailsResponses);
  };

  console.log(underwriterDetails);

  useEffect(() => {
    handleUnderwriterDetails();
  }, [props]);

  return (
    <>
      {Object.keys(props.modalData).length > 0 ? (
        <Modal
          size="xl"
          show={props.modalShow}
          onHide={props.handleModalClose}
          scrollable
        >
          <Modal.Header>
            <h3 className="px-3 m-0">{props.modalData.client.name}</h3>
          </Modal.Header>
          <Modal.Body>
            <Container fluid style={{ minHeight: "400px" }}>
              <Row>
                <Col xs={12}>
                  <h5 className="px-1 fw-bold">Assessment Details</h5>
                  <Table id="assessment-info-table" className="mb-3">
                    <tbody>
                      <tr>
                        <th scope="row">Form ID</th>
                        <td>{props.modalData.formid}</td>
                      </tr>
                      <tr>
                        <th scope="row">Created date</th>
                        <td>
                          {props.modalData.createdAt
                            ? formatDate(props.modalData.createdAt)
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Client submission date</th>
                        <td>
                          {props.modalData.dateClientSubmitted
                            ? formatDate(props.modalData.dateClientSubmitted)
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Date sent to underwriter(s)</th>
                        <td>
                          {props.modalData.dateSentToUnderwriters
                            ? formatDate(props.modalData.dateSentToUnderwriters)
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Client mobile number</th>
                        <td>{props.modalData.client.mobile}</td>
                      </tr>
                      <tr>
                        <th scope="row">Client email</th>
                        <td>{props.modalData.client.email}</td>
                      </tr>
                      <tr>
                        <th scope="row">Form URL</th>
                        <td>
                          <span className="text-truncate">
                            {props.modalData.client.smsLink
                              ? props.modalData.client.smsLink
                              : "N/A"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Shared to client?</th>
                        <td>{props.modalData.isShare ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th scope="row">Submitted to underwriter(s)?</th>
                        <td>{props.modalData.isSubmit ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th scope="row">Underwriter response(s) received?</th>
                        <td>{props.modalData.isRes ? "Yes" : "No"}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <h5 className="px-1 fw-bold">Underwriter Submissions</h5>
                  <Table id="underwriter-submissions" className="m-0">
                    <tbody>
                      {underwriterDetails.map((i) => (
                        <tr>
                          <th scope="row">{i.companyId.name}</th>
                          <td>{i.name}</td>
                          <td>{i.email}</td>
                          <td>{i.contactNumber}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssessmentFullDetailsModal;
