import React from "react";
import { Page, Document, StyleSheet, Image } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceNBandDate from "./InvoiceNBandDate";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import logo from "../../assets/preAssesLogo.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 200,
    height: 100,
    position: "absolute",
    top: 50,
    left: 55,
    marginLeft: "auto",
    marginRight: "auto",
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const Invoice = ({ invoice, Mytitle, MsgThanks, user }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <InvoiceNBandDate invoice={invoice} />
      <Image style={styles.logo} src={logo} />
      <InvoiceTitle title={Mytitle} />
      <InvoiceItemsTable invoice={invoice} user={user} />
      <InvoiceThankYouMsg msgthanks={MsgThanks} />
    </Page>
  </Document>
);

export default Invoice;
