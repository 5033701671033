import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: 10,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ items }) => {
  const total = items
    .map((item) => item.quantity * item.price)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Total Ex GST</Text>
        <Text style={styles.total}>
          ${Number.parseFloat(total - total / 11).toFixed(2)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>GST</Text>
        <Text style={styles.total}>
          ${Number.parseFloat(total / 11).toFixed(2)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total Inc GST</Text>
        <Text style={styles.total}>${Number.parseFloat(total).toFixed(2)}</Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
