import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const Cancel = () => {
  // eslint-disable-next-line
  const [session, setSession] = useState({});
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();
  const history = useHistory();
  const queryLocation = location.search;
  useEffect(() => {
    async function fetchSession() {
      const products = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/paypal/cancel${queryLocation}`
      ).then((res) => res.json());
      setSession(products);
    }
    fetchSession();
  }, [queryLocation]);

  const loginDirect = () => {
    if (token) {
      history.push("/assessment-status");
    } else {
      history.push("/login");
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Grid>
        <Typography
          style={{
            fontSize: "2rem",
            textAlign: "center",
            fontFamily: "Overpass, sans-serif",
            marginTop: "8rem",
            marginBottom: 20,
          }}
        >
          Paypal payment is canceled!
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              fontSize: "1rem",
              backgroundColor: "#004aad",
              fontFamily: "Overpass, sans-serif",
              color: "white",
            }}
            onClick={loginDirect}
          >
            Return to Dashboard
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default Cancel;
