import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const resetArray = [
  //HighCholesterol
  { name: "CholControlActivities", type: [] },
  { name: "cholesterolReading", type: [] },
];

export default function FormHighCholesterol({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "High Cholesterol", value: "HighCholesterol" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("HighCholesterol") && (
        <CheckBox
          name="CholControlActivities"
          question="How do your control your cholesterol?"
          list={[
            { label: "Diet", value: "Diet" },
            { label: "Exercise", value: "Exercise" },
            { label: "No treatment needed", value: "No treatment needed" },
          ]}
          isSubForm
        />
      )}
      {values.illness.includes("HighCholesterol") && (
        <RadioMulti
          name={"cholesterolReading"}
          question="What was your last cholesterol reading?"
          items={[
            { label: "Less than 6.5", value: "Less than 6.5" },
            { label: "6.5-7.0", value: "6.5-7.0" },
            { label: "More than 7.0", value: "More than 7.0" },
            { label: "I don't know", value: "I don't know" },
          ]}
          isSubForm
        />
      )}
    </Fragment>
  );
}
