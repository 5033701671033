import { Grid } from "@material-ui/core";
import React from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const questions = [
  {
    name: "football1",
    label: "What specific code of football do you play?",
  },
  {
    name: "football2",
    label: "How many games have you played in the last 12 months?",
  },
  {
    name: "football3",
    label: "How long have you been playing?",
  },
  {
    name: "football4",
    label:
      "Do you compete in this game in a league, what grade do you compete in?",
  },
  {
    name: "football5",
    label:
      "Have you ever been involved in an accident or hurt yourself playing football? (details)",
  },
];

const resetArray = [
  { name: "football1", type: "" },
  { name: "football2", type: "" },
  { name: "football3", type: "" },
  { name: "football4", type: "" },
  { name: "football5", type: "" },
];

export default function Football({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e) => {
    if (!e.target.checked) {
      for (let item of resetArray) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Grid container md={12} sm={12} xs={12}>
      <CheckBox
        list={[{ label: "Football (all codes)", value: "football" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("football") &&
        questions.map((question) => (
          <AnswerBox
            label={question.label}
            name={question.name}
            key={question.name}
            isSubForm
          />
        ))}
    </Grid>
  );
}
