import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FamilyCommon from "./FamilyCommon";


import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";



export default function FormOne() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    heart: [],
    heartRadio: "",
    heartDeath: "",

    diseases: [],

    breastCancer: [],
    breastRadio: "",
    breastDeath: "",

    colonCancer: [],
    colonRadio: "",
    colonDeath: "",

    kidneyDisease: [],
    kidneyRadio: "",
    kidneyDeath: "",

    diabetes: [],
    diabetesRadio: "",
    diabetesDeath: "",

    mentalDisorder: [],
    mentalRadio: "",
    mentalDeath: "",

    stroke: [],
    strokeRadio: "",
    strokeDeath: "",

    huntingtonDisease: [],
    huntingRadio: "",
    huntingDeath: "",

    // non of the above
    noneOfTheAbove: false,
  });

  const validationSchema = Yup.object().shape({
    // Heart Disease
    heartRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Heart disease")) {
        return Yup.string().required("This field is required");
      }
    }),

    heart: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Heart disease")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    breastCancer: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Breast Cancer")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    colonCancer: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Colon (bowel) cancer")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    kidneyDisease: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Polycystic Kidney disease")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    diabetes: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Diabetes")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    mentalDisorder: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Mental Disorder")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    stroke: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Stroke")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    huntingtonDisease: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Huntington's Disease")) {
        return Yup.array().min(1).required("This field is required");
      }
    }),

    breastRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Breast Cancer")) {
        return Yup.string().required("This field is required");
      }
    }),

    colonRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Colon (bowel) cancer")) {
        return Yup.string().required("This field is required");
      }
    }),

    kidneyRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Polycystic Kidney disease")) {
        return Yup.string().required("This field is required");
      }
    }),

    diabetesRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Diabetes")) {
        return Yup.string().required("This field is required");
      }
    }),

    strokeRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Stroke")) {
        return Yup.string().required("This field is required");
      }
    }),

    huntingRadio: Yup.string().when("diseases", (diseases) => {
      if (diseases.includes("Huntington's Disease")) {
        return Yup.string().required("This field is required");
      }
    }),

    noneOfTheAbove: Yup.string().when('diseases', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]

  const diseases = [
    {
      label: "Heart disease",
      name: "heart",
      radioName: "heartRadio",
      deathName: "heartDeath",
    },
    {
      label: "Breast Cancer",
      name: "breastCancer",
      radioName: "breastRadio",
      deathName: "breastDeath",
    },
    {
      label: "Colon (bowel) cancer",
      name: "colonCancer",
      radioName: "colonRadio",
      deathName: "colonDeath",
    },
    {
      label: "Polycystic Kidney disease",
      name: "kidneyDisease",
      radioName: "kidneyRadio",
      deathName: "kidneyDeath",
    },
    {
      label: "Diabetes",
      name: "diabetes",
      radioName: "diabetesRadio",
      deathName: "diabetesDeath",
    },
    {
      label: "Mental Disorder",
      name: "mentalDisorder",
      radioName: "mentalRadio",
      deathName: "mentalDeath",
    },
    {
      label: "Stroke",
      name: "stroke",
      radioName: "strokeRadio",
      deathName: "strokeDeath",
    },
    {
      label: "Huntington's Disease",
      name: "huntingtonDisease",
      radioName: "huntingRadio",
      deathName: "huntingDeath",
    },
  ];
  const exceptionArray = {
    // noneOfTheAbove: [],    
  };

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formthree", {
        formThree: e,
        pointer: 5,
        formid: id,
      });
      dispatch(setPage(5));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values,setFieldValue,setFieldTouched ,resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Has any of your immediate family prior to age 60 (living
                      or dead) ever suffered from any of the following?
                    </p>
                    {diseases.map((disease) => {
                      return (
                        <FamilyCommon
                          name={disease.name}
                          radioName={disease.radioName}
                          deathName={disease.deathName}
                          disease={disease.label}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      );
                    })}
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
