import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

const resetArray = [
  //FormRheumaticFever
  { name: "diagnosedWithRheumatic", type: "" },
  { name: "rheumaticTreatment", type: "" },
];

export default function FormRheumaticFever({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Rheumatic Fever", value: "RheumaticFever" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("RheumaticFever") && (
        <Grid>
          <AnswerBox
            label="When were you diagnosed with rheumatic fever?"
            name="diagnosedWithRheumatic"
            isSubForm
          />
          <AnswerBox
            label="What ongoing treatment or investigation is required?"
            name="rheumaticTreatment"
            isSubForm
          />
        </Grid>
      )}
    </Fragment>
  );
}
