import { combineReducers } from "redux";
import authReducer from "./authReducer";
import otherReducers from "./otherReducers";
import signupReducer from "./signupReducer";
import formReducer from "./formReducers";


export default combineReducers({
  auth: authReducer,
  other: otherReducers,
  signup: signupReducer,
  form:formReducer
});
