import React, { useState, Fragment } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  Snackbar,
} from "@material-ui/core";
import { useStyles } from "./classes";
import { Formik, Form, Field } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import Img1 from "../../assets/images/image10.png";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { storage } from "../../lib/firebase";
import { TextField } from "formik-material-ui";

import { useDispatch, useSelector } from "react-redux";
import { switchSecond } from "../../store/actions/signupAction";
import axios from "../../lib/axios";

export default function SignupNext(props) {
  let dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  let user = useSelector((state) => state.signup);
  if (user.email === "") {
    const path = "/signup";
    props.history.push(path);
  }
  const classes = useStyles();
  const [defaultImageUpload, setDefaultImageUpload] = useState(false);

  let initialValues = {
    arNumber: user.arNumber,
    adFirm: user.adFirm,
    group: user.group,
    state: "Australia wide",
    contactNumber: user.contactNumber,
    postalAddress: user.postalAddress,
    password: user.password,
    logo: user.logo,
  };

  let SignUpSchema = Yup.object().shape({
    arNumber: Yup.string().required(),
    adFirm: Yup.string()
      .required("Advice Firm is required!")
      .max(35, "Max length for the Advice Firm is 35."),
    group: Yup.string()
      .required("Dealer Group is required!")
      .max(35, "Max length for the Dealer Group is 35."),
    state: Yup.string().required("State is required."),
    postalAddress: Yup.string()
      .required("Postal Address is required.")
      .max(150, "Max length for the Postal Address is 150"),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Min length for the Password is 8")
      .max(15, "Max length for the Password is 15."),
    logo: Yup.string().required("Logo Required!"),
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.post("/auth/checkar", {
        arNumber: e.arNumber,
      });
      dispatch(switchSecond(e));
      const path = "/securityQuestions";
      props.history.push(path);
    } catch (error) {
      if (error.response.status === 401) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Unauthorized!!",
          });
          setOpen(false);
        }, 3000);
      }
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "AR Number should be unique!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "AR Number should be unique!!",
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Server error!!",
          });
          setOpen(false);
        }, 3000);
      }
    }
  };

  const states = [
    "Australia wide",
    "Australian Capital Territory",
    "New South Wales",
    "Northern Territory",
    "Queensland",
    "South Australia",
    "Tasmania",
    "Victoria",
    "Western Australia",
  ];

  const addDefaultSrc = (ev) => {
    ev.target.src = "some default image url";
  };

  const checkExtension = (filename) => {
    return (
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename
    );
  };

  const handleImageAsFile = async (e, setFieldValue, type, values) => {
    try {
      console.log(process.env);
      console.log(storage);
      console.log(storage.ref());
      const ext = checkExtension(e.target.value);
      if (ext !== "jpg" && ext !== "png" && ext !== "webp" && ext !== "jpeg") {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please upload valid logo!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Please upload valid logo!!",
          });
          setOpen(false);
        }, 3000);
      } else {
        setDefaultImageUpload(true);
        const image = e.target.files[0];
        const storageRef = storage.ref();
        const fileRef = storageRef.child(
          `logos/${image.name}${image.lastModifiedDate}`
        );
        const uploadTaskSnapshot = await fileRef.put(image);
        const imageUrl = await uploadTaskSnapshot.ref.getDownloadURL();
        if (type === "logo") {
          setFieldValue("logo", imageUrl);
        } else {
          setFieldValue(`images[${values.images.length}]`, imageUrl);
        }

        setOpen(true);

        setAlert({
          showAlert: true,
          severity: "success",
          message: "Successfully Uploaded!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Successfully Uploaded!!",
          });
          setOpen(false);
        }, 3000);
      }

      // setArtWorkUpload(imageUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
    // setImageAsFile(imageFile => (image))
  };

  return (
    <div>
      <Grid container style={{ paddingRight: "3%" }}>
        <Grid item lg={5} xl={5} className={classes.hide}>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid item lg={7} xl={7} md={12} sm={12} xs={12}>
          <Typography className={classes.tittle}>Your Details</Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={SignUpSchema}
          >
            {({ dirty, isValid, handleChange, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                  >
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        AR Number
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="arNumber"
                        placeholder="AR Number * (9 digits)"
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        required
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Advice Firm
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="adFirm"
                        placeholder="Advice Firm *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Dealer Group
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="group"
                        placeholder="Dealer Group *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        State of Residence
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <FormControl variant="outlined" style={{ width: "90%" }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          State *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.state}
                          onChange={handleChange}
                          label="State"
                          name="state"
                          fullWidth
                          defaultValue="Victoria"
                          required
                          size="small"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          {states.map((e) => (
                            <MenuItem value={e}>{e}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Postal Address
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="postalAddress"
                        placeholder="Postal Address*"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>Password</Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={8}>
                      <Field
                        name="password"
                        placeholder="Password *"
                        required
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        type="password"
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          width: "90%",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                      <Typography className={classes.text}>
                        Company Logo
                      </Typography>
                    </Grid>
                    <Grid item lg={8} xl={8} md={8} sm={8} xs={4}>
                      <Grid container justifycontent="space-between">
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={open}
                          autoHideDuration={3000}
                          onClose={() =>
                            setAlert({
                              ...alert,
                              showAlert: false,
                            })
                          }
                        >
                          <Alert
                            onClose={() => setOpen(false)}
                            severity={alert.severity}
                            sx={{ width: "100%" }}
                          >
                            {alert.message}
                          </Alert>
                        </Snackbar>
                        <Grid item lg={8} xl={8} md={8} sm={8} xs={12}>
                          {defaultImageUpload ? (
                            "Uploading..."
                          ) : (
                            <Fragment>
                              <div className={classes.viewButton}>
                                <label htmlFor="upload-photo">
                                  <input
                                    style={{ display: "none" }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    accept="image/jpeg,image/png"
                                    onChange={(e) => {
                                      e.persist();
                                      handleImageAsFile(
                                        e,
                                        setFieldValue,
                                        "logo",
                                        values
                                      );
                                    }}
                                  />

                                  <Button
                                    className={classes.uploadButton}
                                    variant="contained"
                                    component="span"
                                  >
                                    Upload *
                                  </Button>
                                </label>
                                <div>
                                  {values.logo ? (
                                    <img
                                      src={values.logo}
                                      alt="advisor logo"
                                      style={{
                                        maxHeight: "200px",
                                        marginTop: "1rem",
                                      }}
                                      onError={addDefaultSrc}
                                    ></img>
                                  ) : (
                                    <img
                                      alt="advisor logo"
                                      src="https://via.placeholder.com/150/#C0C0C0/000000?Text=WebsiteBuilders.com%20C/O%20https://placeholder.com/"
                                      style={{
                                        maxHeight: "200px",
                                        marginTop: "1rem",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          xl={4}
                          md={4}
                          sm={4}
                          xs={12}
                          alignItems="center"
                        >
                          <div style={{ marginRight: 50 }}>
                            <Button
                              variant="contained"
                              fullWidth
                              className={classes.nextButton}
                              style={{
                                width: "100%",
                                height: 50,
                                fontSize: "1rem",
                                fontFamily: "Overpass, sans-serif",
                              }}
                              disabled={!isValid}
                              type="submit"
                            >
                              Next
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
}
