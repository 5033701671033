import { SET_PAGE } from "../actions/types";

const initialState = {
  page: 1,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  console.log("action.payload",action.payload);
  switch (action.type) {
    case SET_PAGE:
      return {
        page: action.payload,
      };

    default:
      return state;
  }
}
