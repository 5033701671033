import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import NavBar from "../../components/layouts/AdvisorNavBar";
import Heading from "../../components/layouts/AdvisorHeading";
import PackageCard from "../../components/layouts/PackageCard";
import Footer from "../../components/Footer/Footer";
import MobileViewport from "../mobileViewport/MobileViewport";

const BuyPackages = () => {
  const [packages, setPackages] = useState();

  const getPackages = async () => {
    const response = await axios
      .get("/package/package-web")
      .catch((err) => console.log(err));

    if (response) {
      setPackages(response.data);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="h-100">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col className="p-4">
                <Heading heading="Assessment packages" />
                {packages ? (
                  <Row className="row-cols-1 row-cols-lg-2 row-cols-xxl-3 g-4">
                    {packages.map((singlePackage) => (
                      <Col>
                        <PackageCard
                          header={singlePackage.name}
                          description={singlePackage.description}
                          price={singlePackage.price}
                          id={singlePackage.id}
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <h5>No data from server</h5>
                )}
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default BuyPackages;
