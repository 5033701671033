import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField as TF,
  FormControl,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// import { Autocomplete, ToggleButtonGroup } from "formik-material-ui-lab";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// validation
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../lib/axios';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#004AAD',
    color: 'white',
    fontFamily: 'Overpass, sans-serif',
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function Addpackage(props) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [name, setName] = useState([]);
  const [email, setEmail] = useState([]);

  const [packs, setPacks] = useState([]);
  const [price, setPrice] = useState([]);
  const [client, setClient] = useState([]);
  const [open, setOpen] = useState(false);

  let initialValues = {
    user: {},
    userId: '',
    pack: {},
    packId: '',
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    console.log(e);
    try {
      await axios.post('/auth/addpackage', {
        arNumber: e.userId,
        count: client,
      });
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Add package successfully!',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Add package successfully!',
        });
        setOpen(false);
        resetForm();
        setName('');
        setEmail('');
        setClient('');
        setPrice('');
      }, 3000);
    } catch (error) {
      if (error.response.status === 401) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'unauthorized!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'unauthorized!',
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Package add failed!',
        });

        setTimeout(() => {
          setOpen(false);
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Package add failed!',
          });
        }, 3000);
      }
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await axios.get('/user/');

      const activeusers = data.filter((e) => e.status);

      setUsers(activeusers);
      console.log('user', users);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Users loading failed!',
      });
    }
  };

  const getPackages = async () => {
    try {
      const pack = await axios.get('/package/package-web');

      setPacks(pack.data);
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Package loading failed!',
      });

      setTimeout(() => {
        setOpen(false);
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Package loading failed!',
        });
      }, 3000);
    }
  };
  useEffect(() => {
    getUsers();
    getPackages();
    // eslint-disable-next-line
  }, []);

  // validation schema
  let validationSchema = Yup.object().shape({
    userId: Yup.string().required('AR Number is required'),
    packId: Yup.string().required('package is required.'),
  });

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
          }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    Assign Package
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth variant='outlined'>
                          <Autocomplete
                            options={users}
                            // name="userId"
                            value={values.user}
                            getOptionLabel={(option) => option.arNumber}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const { arNumber, email, firstName } = newValue;
                                setFieldValue('userId', arNumber);
                                setTimeout(() =>
                                  setFieldTouched('userId', true)
                                );
                                setName(firstName);
                                setEmail(email);
                                setFieldValue('user', newValue);
                              } else {
                                setFieldValue('user', null);
                                setFieldValue('userId', null);
                              }
                            }}
                            renderInput={(params) => (
                              <TF
                                {...params}
                                error={errors.userId}
                                helperText={
                                  errors.userId ? 'AR Number is required' : ''
                                }
                                label='AR Number'
                                variant='outlined'
                                required
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'blod',
                            color: '#000',
                            fontFamily: 'Overpass, sans-serif',
                          }}
                        >
                          Name : {name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'blod',
                            color: '#000',
                            fontFamily: 'Overpass, sans-serif',
                          }}
                        >
                          Email : {email}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth variant='outlined'>
                          <Autocomplete
                            name='packId'
                            options={packs}
                            value={values.pack}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const { _id, numberOfClients, price } =
                                  newValue;
                                setFieldValue('packId', _id);
                                // formik.setFieldValue(name, value);
                                setTimeout(() =>
                                  setFieldTouched('packId', true)
                                );
                                setClient(numberOfClients);
                                setPrice(price);
                                setFieldValue('pack', newValue);
                              } else {
                                setFieldValue('pack', null);
                                setFieldValue('packId', null);
                              }
                            }}
                            renderInput={(params) => (
                              <TF
                                error={errors.packId}
                                helperText={
                                  errors.packId ? 'Package is required' : ''
                                }
                                {...params}
                                label='Package'
                                variant='outlined'
                                required
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'blod',
                            color: '#000',
                            fontFamily: 'Overpass, sans-serif',
                          }}
                        >
                          {`Client Count : ${client}`}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'blod',
                            color: '#000',
                            fontFamily: 'Overpass, sans-serif',
                          }}
                        >
                          {`Amount : $${price}.00`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant='contained'
                        className={classes.button}
                        color='primary'
                        type='submit'
                        disabled={!dirty || !isValid}
                      >
                        Add Package
                      </Button>
                    </ThemeProvider>
                  </CardActions>
                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
