import React, { useState } from "react";
import { Fragment } from "react";
import AnswerBox from "../forms/AnswerBox";
import YesNoQuestion from "../forms/YesNoQuestion";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";
import RadioMulti from "../forms/RadioMulti";
import CheckBox from "../forms/CheckBox";

import styles from "../../styles/form.module.css";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import { useStyles } from "./classes";

export default function FormTwentyFour() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const examinationTypeList = [
    {
      label: "X-ray",
      value: "xray",
    },
    {
      label: "Pathology tests",
      value: "pathologyTests",
    },
    {
      label: "Medical procedures",
      value: "medicalProcedures",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const items = [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];
  const reasonList = [
    {
      label:
        "Health screening as part of executive health or a requirement of an application (e.g. migration, insurance, blood donation etc.)",
      value: "healthScreening",
    },
    {
      label:
        "Sexual health screening (as recommended by your doctor or a medical practitioner)",
      value: "sexualHealthScreening ",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    //SubForm24
    isAnyIllness: "",
    IllnessDetails: "",

    //SubForm25
    isAnyMedicalTest: "",
    examinationType: [],
    WhenTest: "",
    recommendedTreatment: "",
    MedicalTestAnyOngoingTreatment: "",

    //SubForm26
    isTakeAnyActivities: "",
    whatTake: "",
    whatDoes: "",
    howLong: "",
    whatCondition: "",

    //SubForm27
    isCurrentlyUndergoing: "",
    whatAndWhenDiagnosed: "",
    treatment: "",
    anyOngoingTreatment: "",
    treatmentDetails: "",

    //SubForm28
    isTestAnyConditions: "true",
    HIV: "",
    STIs: "",
    awaitingResult: "",
    whatWasReason: [],
    haveYouAnySexualIntercourse: "",
    haveYouDiagnosed: "",
    aveYouDiagnosedDetails: "",
  });
  const validationSchema = Yup.object().shape({
    //SubForm24
    isAnyIllness: Yup.string().required(),
    IllnessDetails: Yup.string().when("isAnyIllness", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    //SubForm25
    isAnyMedicalTest: Yup.string().required(),

    WhenTest: Yup.string().when(
      ["isAnyMedicalTest", "examinationType"],
      (isAnyMedicalTest, examinationType) => {
        if (isAnyMedicalTest === "true" && examinationType) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    recommendedTreatment: Yup.string().when(
      ["isAnyMedicalTest", "examinationType", "WhenTest"],
      (isAnyMedicalTest, examinationType, WhenTest) => {
        if (isAnyMedicalTest === "true" && examinationType && WhenTest) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    MedicalTestAnyOngoingTreatment: Yup.string().when(
      [
        "isAnyMedicalTest",
        "examinationType",
        "WhenTest",
        "recommendedTreatment",
      ],
      (isAnyMedicalTest, examinationType, WhenTest, recommendedTreatment) => {
        if (
          isAnyMedicalTest === "true" &&
          examinationType &&
          WhenTest &&
          recommendedTreatment
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //SubForm26
    isTakeAnyActivities: Yup.string().required(),
    whatTake: Yup.string().when("isTakeAnyActivities", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    whatDoes: Yup.string().when(
      ["isTakeAnyActivities", "whatTake"],
      (isTakeAnyActivities, whatTake) => {
        if (isTakeAnyActivities === "true" && whatTake) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    howLong: Yup.string().when(
      ["isTakeAnyActivities", "whatTake", "whatDoes"],
      (isTakeAnyActivities, whatTake, whatDoes) => {
        if (isTakeAnyActivities === "true" && whatTake && whatDoes) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    whatCondition: Yup.string().when(
      ["isTakeAnyActivities", "whatTake", "whatDoes", "howLong"],
      (isTakeAnyActivities, whatTake, whatDoes, howLong) => {
        if (isTakeAnyActivities === "true" && whatTake && whatDoes && howLong) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //SubForm27
    isCurrentlyUndergoing: Yup.string().required(),
    whatAndWhenDiagnosed: Yup.string().when("isCurrentlyUndergoing", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatment: Yup.string().when(
      ["isCurrentlyUndergoing", "whatAndWhenDiagnosed"],
      (isCurrentlyUndergoing, whatAndWhenDiagnosed) => {
        if (isCurrentlyUndergoing === "true" && whatAndWhenDiagnosed) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    anyOngoingTreatment: Yup.string().when(
      ["isCurrentlyUndergoing", "whatAndWhenDiagnosed", "treatment"],
      (isCurrentlyUndergoing, whatAndWhenDiagnosed, treatment) => {
        if (
          isCurrentlyUndergoing === "true" &&
          whatAndWhenDiagnosed &&
          treatment
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    treatmentDetails: Yup.string().when(
      [
        "isCurrentlyUndergoing",
        "whatAndWhenDiagnosed",
        "treatment",
        "anyOngoingTreatment",
      ],
      (
        isCurrentlyUndergoing,
        whatAndWhenDiagnosed,
        treatment,
        anyOngoingTreatment
      ) => {
        if (
          isCurrentlyUndergoing === "true" &&
          whatAndWhenDiagnosed &&
          treatment &&
          anyOngoingTreatment === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //SubForm28
    isTestAnyConditions: Yup.string().required(),

    haveYouAnySexualIntercourse: Yup.string().when(
      ["isTestAnyConditions", "HIV", "STIs", "awaitingResult", "whatWasReason"],
      (isTestAnyConditions, HIV, STIs, awaitingResult, whatWasReason) => {
        if (
          isTestAnyConditions === "true" &&
          (HIV === "yes" || STIs === "yes" || awaitingResult === "yes") &&
          whatWasReason
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    haveYouDiagnosed: Yup.string().when(
      [
        "isTestAnyConditions",
        "HIV",
        "STIs",
        "awaitingResult",
        "whatWasReason",
        "haveYouAnySexualIntercourse",
      ],
      (
        isTestAnyConditions,
        HIV,
        STIs,
        awaitingResult,
        whatWasReason,
        haveYouAnySexualIntercourse
      ) => {
        if (
          isTestAnyConditions === "true" &&
          (HIV === "yes" || STIs === "yes" || awaitingResult === "yes") &&
          whatWasReason &&
          haveYouAnySexualIntercourse
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    aveYouDiagnosedDetails: Yup.string().when(
      [
        "isTestAnyConditions",
        "HIV",
        "STIs",
        "awaitingResult",
        "whatWasReason",
        "haveYouAnySexualIntercourse",
        "haveYouDiagnosed",
      ],
      (
        isTestAnyConditions,
        HIV,
        STIs,
        awaitingResult,
        whatWasReason,
        haveYouAnySexualIntercourse,
        haveYouDiagnosed
      ) => {
        if (
          isTestAnyConditions === "true" &&
          (HIV === "yes" || STIs === "yes" || awaitingResult === "yes") &&
          whatWasReason &&
          haveYouAnySexualIntercourse &&
          haveYouDiagnosed === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
  });

  // reset values
  const resetFoldYesNo = (e, val, setFieldValue, setFieldTouched, arr) => {
    if (e.target.value === val) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    }
  };
  const resetArray1 = [{ name: "IllnessDetails", type: "" }];
  const resetArray2 = [
    { name: "examinationType", type: [] },
    { name: "WhenTest", type: "" },
    { name: "recommendedTreatment", type: "" },
    { name: "MedicalTestAnyOngoingTreatment", type: "" },
  ];
  const resetArray3 = [
    { name: "whatTake", type: "" },
    { name: "whatDoes", type: "" },
    { name: "howLong", type: "" },
    { name: "whatCondition", type: "" },
  ];

  const resetArray4 = [
    { name: "whatAndWhenDiagnosed", type: "" },
    { name: "treatment", type: "" },
    { name: "anyOngoingTreatment", type: "" },
    { name: "treatmentDetails", type: "" },
  ];

  const resetArray5 = [
    { name: "whatWasReason", type: [] },
    { name: "haveYouAnySexualIntercourse", type: "" },
    { name: "haveYouDiagnosed", type: "" },
    { name: "aveYouDiagnosedDetails", type: "" },
  ];

  const resetRadios = (e, setFieldValue, setFieldTouched, arr, values) => {
    const array = ["HIV", "STIs", "awaitingResult"];
    if (e.target.value === "no") {
      array.splice(array.indexOf(e.target.name), 1);
      let hmm = false;
      for (let item of array) {
        if (values[item] === "no") {
          hmm = true;
        } else {
          hmm = false;
        }
      }
      if (hmm) {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }
    }
  };
  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formtwentyfour", {
        formTwentyFour: e,
        pointer: 25,
        formid: id,
      });
      dispatch(setPage(25));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
          }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      {/* Sub form 24 */}
                      <Fragment>
                        <p className={styles.question}>
                          Have you suffered any other illness, disease or
                          disorder?
                        </p>
                        <YesNoQuestion
                          name="isAnyIllness"
                          question=""
                          onClick={(e) =>
                            resetFoldYesNo(
                              e,
                              "false",
                              setFieldValue,
                              setFieldTouched,
                              resetArray1
                            )
                          }
                        />
                        {values.isAnyIllness === "true" && (
                          <Fragment>
                            <AnswerBox
                              name="IllnessDetails"
                              label="Provide details (do not include; colds, flu, hayfever, dental related matters, uncomplicated pregnancies[including caesarean sections, miscarriage], abortions and menopause.)"
                            />
                          </Fragment>
                        )}
                      </Fragment>
                      {/* Sub form 25 */}
                      <Fragment>
                        <p className={styles.question}>
                          Have you had any medical examinations, consultations,
                          X-rays, pathology tests or procedures in the last 5
                          years relating to a matter not previously disclosed in
                          this application?
                        </p>
                        <YesNoQuestion
                          name="isAnyMedicalTest"
                          question=""
                          onClick={(e) =>
                            resetFoldYesNo(
                              e,
                              "false",
                              setFieldValue,
                              setFieldTouched,
                              resetArray2
                            )
                          }
                        />

                        <Fragment>
                          {values.isAnyMedicalTest === "true" && (
                            <CheckBox
                              question="What was the examination?"
                              list={examinationTypeList}
                              name="examinationType"
                            />
                          )}
                          {values.examinationType.length !== 0 && (
                            <AnswerBox
                              name="WhenTest"
                              label="When was the test?"
                            />
                          )}
                          {values.WhenTest && (
                            <AnswerBox
                              name="recommendedTreatment"
                              label="What was the treatment that was recommended by a medical professional?"
                            />
                          )}
                          {values.recommendedTreatment && (
                            <RadioMulti
                              question="Is there any ongoing treatment?"
                              name={"MedicalTestAnyOngoingTreatment"}
                              items={items}
                            />
                          )}
                        </Fragment>
                      </Fragment>

                      {/* Sub form 26 */}
                      <Fragment>
                        <p className={styles.question}>
                          Do you currently take any stimulants, sedatives,
                          medications or prescribed drugs? (not previously
                          mentioned in this survey)
                        </p>
                        <YesNoQuestion
                          name="isTakeAnyActivities"
                          question=""
                          onClick={(e) =>
                            resetFoldYesNo(
                              e,
                              "false",
                              setFieldValue,
                              setFieldTouched,
                              resetArray3
                            )
                          }
                        />

                        <Fragment>
                          {values.isTakeAnyActivities === "true" && (
                            <AnswerBox
                              name="whatTake"
                              label="What do you take? "
                            />
                          )}
                          {values.whatTake && (
                            <AnswerBox
                              name="whatDoes"
                              label="What is the dose?"
                            />
                          )}
                          {values.whatDoes && (
                            <AnswerBox
                              name="howLong"
                              label="How long have you been taking it?"
                            />
                          )}
                          {values.howLong && (
                            <AnswerBox
                              name="whatCondition"
                              label="What condition is it for?"
                            />
                          )}
                        </Fragment>
                      </Fragment>
                      {/* Sub form 27 */}
                      <Fragment>
                        <p className={styles.question}>
                          Are you currently undergoing monitoring, consultation
                          or review for any condition not mentioned previously
                          in this survey?
                        </p>
                        <YesNoQuestion
                          name="isCurrentlyUndergoing"
                          question=""
                          onClick={(e) =>
                            resetFoldYesNo(
                              e,
                              "false",
                              setFieldValue,
                              setFieldTouched,
                              resetArray4
                            )
                          }
                        />

                        <Fragment>
                          {values.isCurrentlyUndergoing === "true" && (
                            <AnswerBox
                              name="whatAndWhenDiagnosed"
                              label="What and When were you diagnosed? "
                            />
                          )}
                          {values.whatAndWhenDiagnosed && (
                            <AnswerBox
                              name="treatment"
                              label="What was the treatment that was recommended by a medical professional?"
                            />
                          )}
                          {values.treatment && (
                            <RadioMulti
                              question="Is there any ongoing treatment"
                              name={"anyOngoingTreatment"}
                              items={items}
                            />
                          )}
                          {values.anyOngoingTreatment === "yes" && (
                            <AnswerBox
                              name="treatmentDetails"
                              label="Please provide details"
                            />
                          )}
                        </Fragment>
                      </Fragment>
                      {/* Sub form 28 */}
                      <Fragment>
                        <p className={styles.question}>
                          In the last 5 years, have you been tested for any of
                          the following conditions?
                        </p>
                        {/* <YesNoQuestion name="isTestAnyConditions" question="" /> */}

                        <Fragment>
                          <>
                            <RadioMulti
                              question="Human Immunodeficiency Virus(HIV)"
                              name="HIV"
                              items={items}
                              onClick={(e) =>
                                resetRadios(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  resetArray5,
                                  values
                                )
                              }
                            />
                            <RadioMulti
                              question="Sexually Transmitted Infections (STIs)"
                              name="STIs"
                              items={items}
                              onClick={(e) =>
                                resetRadios(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  resetArray5,
                                  values
                                )
                              }
                            />
                            <RadioMulti
                              question="Awaiting result"
                              name="awaitingResult"
                              items={items}
                              onClick={(e) =>
                                resetRadios(
                                  e,
                                  setFieldValue,
                                  setFieldTouched,
                                  resetArray5,
                                  values
                                )
                              }
                            />
                          </>

                          {(values.HIV === "yes" ||
                            values.STIs === "yes" ||
                            values.awaitingResult === "yes") && (
                            <>
                              <CheckBox
                                question="What was the reason for the test?"
                                name={"whatWasReason"}
                                list={reasonList}
                              />

                              {values.whatWasReason.length !== 0 && (
                                <>
                                  <RadioMulti
                                    question='In the last 5 years, have you had sexual intercourse without a condom with the following persons: -Someone who might have exposed you to the Human Immunodeficiency Virus (HIV) infection (this may include unprotected sexual intercourse with someone other than your regular partner whose HIV status is unknown to you.) -Someone who injects non-prescribed drugs -Someone who is a sex worker-Someone who is infected with an HIV infection -Someone who is infected with Hepatitis B(you may answer "no" if you are vaccinated and have immunity for Hepatitis B)-Someone who is infected with Hepatitis C'
                                    name={"haveYouAnySexualIntercourse"}
                                    items={items}
                                  />
                                  {values.haveYouAnySexualIntercourse && (
                                    <>
                                      <RadioMulti
                                        question="In the last 5 years, have you been diagnosed with or
                            experienced symptoms of Sexually Transmitted
                            Infection(s) (STIs) (examples, chlamydia,
                            gonorrhoea,syphilis)?"
                                        name={"haveYouDiagnosed"}
                                        items={items}
                                      />
                                      {values.haveYouDiagnosed === "yes" && (
                                        <AnswerBox
                                          name="aveYouDiagnosedDetails"
                                          label="Please provide details"
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Fragment>
                      </Fragment>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
