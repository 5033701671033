import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const episodes = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "1-3 attacks",
    value: "one&TwoAttacks",
  },
  {
    label: "More than 3 attacks",
    value: "moreThan3",
  },
];
const resetArray = [
  { name: "totallyRemovedOrPassed", type: "" },
  { name: "howManyEpisodes", type: "" },
  { name: "ongoingProblemsWithKidneys", type: "" },
  { name: "ongoingProblemsWithKidneysDetails", type: "" },
];
export default function SubFormSix({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Renal colic or kidney stone",
            value: "renalColicOrKidneyStone",
          },
        ]}
        name="subFormsOfFormSeventeen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") && (
        <RadioMulti
          question="Have the stones been totally removed or passed?"
          name={"totallyRemovedOrPassed"}
          items={items}
          isSubForm
        />
      )}
      {values.subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
        values.totallyRemovedOrPassed !== "" && (
          <RadioMulti
            question="How many episodes have you experienced in the last 2 years?"
            name={"howManyEpisodes"}
            items={episodes}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
        values.howManyEpisodes !== "" && (
          <RadioMulti
            question="Do you have ongoing problems with your kidneys? eg. blood in urine?"
            name={"ongoingProblemsWithKidneys"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
        values.ongoingProblemsWithKidneys === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="ongoingProblemsWithKidneysDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
