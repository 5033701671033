import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";
const resetArray = [
  { name: "depressionDescribeSymptoms", type: "" },
  { name: "depressionStartDate", type: "" },
  { name: "depressionTimePeriod", type: "" },
  { name: "depressionReasons", type: "" },
  { name: "depressionFactors", type: "" },
  { name: "depressionThoughts", type: "" },
  { name: "depressionWhatTriggered", type: "" },
  { name: "depressionSymptomsCommenced", type: "" },
  { name: "depressionReoccurrencesOfCondition", type: "" },
  { name: "depressionHowManyReoccurrences", type: "" },
  { name: "depressionTreatment", type: [] },
  { name: "depressionCounsellingWhenStart", type: "" },
  { name: "depressionCounsellingEndDate", type: "" },
  { name: "depressionCounsellingTherapyDetails", type: "" },
  { name: "depressionPsychotherapyWhenStart", type: "" },
  { name: "depressionPsychotherapyEndDate", type: "" },
  { name: "depressionPsychotherapyTherapyDetails", type: "" },
  { name: "depressionMedicationDose", type: "" },
  { name: "depressionMedicationEndDate", type: "" },
  { name: "depressionConditionMedication", type: "" },
  { name: "depressionDosageOften", type: "" },
  { name: "depressionDutiesRestrictionCondition", type: "" },
  { name: "depressionWorkRestriction", type: "" },
  { name: "depressionLifeRestriction", type: "" },
  { name: "depressionDetails", type: "" },
];

export default function Depression({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      for (let item of arr) {
        let foo = async () => {
          await setFieldValue(item.name, item.type, false);
        };
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Mild Depression/Postnatal depression",
            value: "depression",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("depression") && (
        <AnswerBox
          label="Describe your symptoms"
          name="depressionDescribeSymptoms"
          isSubForm
        />
      )}

      {values.depressionDescribeSymptoms && (
        <AnswerBox
          label="What date did they start?"
          name="depressionStartDate"
          isSubForm
        />
      )}
      {values.depressionStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="depressionTimePeriod"
          isSubForm
        />
      )}
      {values.depressionTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause of these details?"
          name="depressionReasons"
          isSubForm
        />
      )}
      {values.depressionReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="depressionFactors"
          isSubForm
        />
      )}
      {(values.depressionReasons === "false" || values.depressionFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts, threatened to self-harm or
          engaged in self-harm?"
          name="depressionThoughts"
          isSubForm
        />
      )}
      {values.depressionThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self-harm
          threats or actual, provide details, or how often and the last occurrence of
          them."
          name="depressionWhatTriggered"
          isSubForm
        />
      )}
      {(values.depressionThoughts === "false" ||
        values.depressionWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="depressionSymptomsCommenced"
          isSubForm
        />
      )}
      {values.depressionSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="depressionReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.depressionReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="depressionHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.depressionReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="depressionTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.depressionTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="depressionCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressionCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="depressionCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.depressionReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="depressionTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}

      {values.depressionTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="depressionPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressionPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="depressionPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.depressionReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="depressionTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.depressionTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="depressionMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressionMedicationEndDate"
            isSubForm
          />

          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="depressionConditionMedication"
            isSubForm
          />

          {values.depressionConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage"
              name="depressionDosageOften"
              isSubForm
            />
          )}
          {(values.depressionDosageOften ||
            values.depressionConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="depressionDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.depressionDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="depressionWorkRestriction"
              isSubForm
            />
          )}

          {(values.depressionDutiesRestrictionCondition === "false" ||
            values.depressionWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="depressionLifeRestriction"
              isSubForm
            />
          )}
          {values.depressionLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="depressionDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
