import React, { useEffect, useState } from "react";
import { Typography, Grid, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import { useStyles } from "./classes";

export default function VerfyAccount(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { email, verificationToken, arNumber, firstName } = useParams();

  console.log("arNumber", email, arNumber);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const loadData = async () => {
    try {
      await axios.post("/auth/verify-account", {
        AVT: verificationToken,
        email: email,
      });
      await axios.post("/analytics/adduser", {
        code: "VISIT",
        eventName: "signup",
        arNumber: arNumber,
        adname: firstName,
      });
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Account Verify successfully!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Account Verify successfully!!",
        });
        setOpen(false);
        const path = "/login";
        props.history.push(path);
      }, 3000);
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Account Verify faild!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Account Verify faild!!",
        });
        setOpen(false);
        const path = "/signup";
        props.history.push(path);
      }, 3000);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Grid item alignItems="center" lg={12}>
        <Typography className={classes.header}>
          Please Wait for Verfication....
        </Typography>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={() =>
            setAlert({
              ...alert,
              showAlert: false,
            })
          }
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
