import React, { useState } from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const ActivitiesTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-activities-tabs"
          defaultActiveKey="#activity-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formTwo.activities.map((activity, index) => (
                <ListGroup.Item action href={`#activity-${index + 1}`}>
                  {activity
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    activity
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formTwo.activities.map((activity, index) => (
                <Tab.Pane eventKey={`#activity-${index + 1}`}>
                  <Table
                    id="form-view-activities-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {activity === "abseiling" ? (
                      // Abseiling responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            How many times have you abseiled this in the last 12
                            months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingTimeLastYear
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What qualifications, certificates, licences, or club
                            memberships do you have?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingQualifications
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long have you been abseiling?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingHowLong
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Where do you abseil?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingLocations
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you engage in abseiling alone or are you always
                            with a group?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingEngage
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What is the maximum height?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingHeight
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are all recognized safety measures and precautions
                            followed?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingSafety ===
                                "true"
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident whilst
                            abseiling?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingAccident ===
                                "true"
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Please provide any relevant details</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.abseilingDetails
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "aviation" ? (
                      // Aviation responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Are your flying activities recreational or required
                            for your occupation?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.recreational
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What licences do you hold?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.licencesList
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Name the types and models of aircraft you fly in?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.aircraftList
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How many hours have you flown per year</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.aviationhours
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many hours do you expect to fly in the next 12
                            months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.aviationexpecthours
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "football" ? (
                      // Football responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>What specific code of football do you play?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.football1
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many games have you played in the last 12
                            months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.football2
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long have you been playing?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.football3
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you compete in this game in a league, what grade
                            do you compete in?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.football4
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident or hurt
                            yourself playing football?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.football5
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "sailing" ? (
                      // Sailing responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>What type of sailing do you participate in?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.sailingTypesParticipate
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formTwo ? (
                          props.formData.formTwo.raceSeries !== "" && (
                            <tr>
                              <td>What is the race/race series?</td>
                              <td>{props.formData.formTwo.raceSeries}</td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Do you receive any payments for your involvement in
                            this activity?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.payments
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Are you involved in any record attempts?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.recordAttempts
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are all recognized safety measures and precautions
                            followed?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.safetyMeasures
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What type and size of boat do you use?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.boatSize
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident whilst
                            sailing long distances?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.accident
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "hangGliding" ? (
                      // Hang gliding responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>How often do you go hang gliding?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding1
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many times have you done this in the last 12
                            months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding2
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What Qualifications, certificates, licences, or club
                            memberships do you have?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding3
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Where do you engage in hang gliding and in what
                            locations?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding4
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you engage in hang gliding alone or in a group?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding5
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What is the maximum height you get to?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding6
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Are you involved in any record attempts?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding7
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are all recognized safety measures and precautions
                            followed?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding8
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident whilst
                            hang gliding?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.hangGliding9
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "scubaDiving" ? (
                      // Scuba diving responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Do you have any diving qualifications?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.qualificationsList
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What areas do you dive in?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.divingAreas
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Do you do or use any of the following?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.divingItems
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>To what depths do you usually dive in metres?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.divingDepth
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many times a year do your dive these depths?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.divingFrequency
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "motorRacing" ? (
                      // Motor racing responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>What type of vehicle do you race with?</td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo.vehicleTypes
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Is your racing limited to track experience one-off
                            day?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.limitedToTrack === true
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formTwo.limitedToTrack === false ? (
                          <>
                            <tr>
                              <td>What events do you compete in?</td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.competedEvents
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>Are you a member of a Motor racing Club?</td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo
                                      .memberDetailsOFRacingClub
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a C.A.M.S licence? If yes, what
                                classification?
                              </td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.camsClassification
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                How long have you been competing in motor
                                sports?
                              </td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.durationOfMotorSport
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you take part in motor sports as a
                                (professional, semi professional, sponsored
                                driver, unsponsored amateur)?
                              </td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.professionalism
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Have you or do you anticipate engaging in any
                                demonstration of test vehicles or accessories?
                              </td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.engagementOfDemo
                                  : "Loading..."}
                              </td>
                            </tr>
                            <tr>
                              <td>Please provide any relevant details.</td>
                              <td>
                                {props.formData.formTwo
                                  ? props.formData.formTwo.relevantDetails
                                  : "Loading..."}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    ) : activity === "parachuting" ? (
                      // Parachuting responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>How many times have you been parachuting?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting1
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many times have you been parachuting in the last
                            12 months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting2
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What Qualifications, certificates,licences, or club
                            memberships do you have?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting3
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long have you been parachuting?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting4
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Where do you engage in this? What locations?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting5
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you engage in parachuting alone or are you always
                            with a group?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting6
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What is the maximum height you parachute at?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting7
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are all recognized safety measures and precautions
                            followed?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting8
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident whilst
                            parachuting?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.parachuting9
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "powerboatRacing" ? (
                      // Powerboat racing responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>How often do you race powerboats?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing1
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many times have you raced powerboats in the last
                            12 months?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing2
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What Qualifications, certificates,licences, or club
                            memberships do you have?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing3
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long have you been racing powerboats?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing4
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Where do you race?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing5
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Do you receive payment for racing?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing6
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What is the maximum speed you reach?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing7
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are all recognized safety measures and precautions
                            followed?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing8
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What size engine do you race?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing9
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you ever been involved in an accident whilst
                            racing?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.powerboatRacing10
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "mountaineering" ? (
                      // Mountaineering responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            For how many years have you been climbing regularly?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering1
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How often do you climb?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering2
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Are you a member of a club?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering3
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you climb outside of Australia? If yes, where?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering4
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What type of terrain do you climb? (rock, snow/ice,
                            artificial climbing walls, abseiling)
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering5
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What is the maximum height you have climbed to?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering6
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you ever climb without a rope? if yes provide
                            details
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.mountaineering7
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : activity === "martialArts" ? (
                      // Martial arts conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            What type of Martial arts do you participate in?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? arrayToFormattedList(
                                  props.formData.formTwo
                                    .martialArtTypesParticipate
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formTwo.martialArtTypesParticipate.includes(
                          "other"
                        ) ? (
                          <tr>
                            <td>Provide details</td>
                            <td>
                              {props.formData.formTwo
                                ? props.formData.formTwo
                                    .otherMartialArtTypeDetails
                                : "Loading..."}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>What type of martial arts do you practice?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.martialArtTypesPractice
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you had any injuries resulting from martial
                            arts?
                          </td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.injuries
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Are you involved in competitive martial arts?</td>
                          <td>
                            {props.formData.formTwo
                              ? props.formData.formTwo.competitiveMartial
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default ActivitiesTab;
