import React, { useEffect, useState, Fragment } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { storage } from '../../lib/firebase';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { changeName } from '../../store/actions/authActions';

import axios from '../../lib/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#004AAD',
    color: 'white',
    fontFamily: 'Overpass, sans-serif',
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function User(props) {
  const classes = useStyles();
  let dispatch = useDispatch();

  const [callingBackend, setCallingBackend] = useState(false);
  const [defaultImageUpload, setDefaultImageUpload] = useState(false);
  const [open, setOpen] = useState(false);

  const [counts, setCounts] = useState({});
  const getCounts = async () => {
    try {
      const data = await axios.get('/analytics/advisor');
      setCounts(data.data);
    } catch (error) {}
  };

  let [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    logo: '',
    email: '',
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put('/auth/change-profile', {
        firstName: e.firstName,
        lastName: e.lastName,
        logo: e.logo,
        email: e.email,
      });
      dispatch(changeName(e.firstName));
      sessionStorage.setItem('name', e.firstName);
      loadData();
      setOpen(true);

      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Change profile successfully!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Change profile successfully!',
        });

        setOpen(false);
      }, 3000);
    } catch (error) {
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Change profile failed',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Change profile failed',
          });

          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Change profile failed!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Change profile failed!',
          });

          setOpen(false);
        }, 3000);
      }
    } finally {
      setCallingBackend(false);
    }
  };

  // validation schema
  let validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required!')
      .max(35, 'Min length for the Password is 35'),
    lastName: Yup.string()
      .required('Last Name is required!')
      .max(35, 'Min length for the Password is 35'),
    logo: Yup.string().required('Logo is required!'),
    email: Yup.string()
      .email()
      .required('Email is required!')
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        'Please enter valid Email'
      ),
  });

  const loadData = async () => {
    try {
      const data = await axios.get('/auth/me');
      setInitialValues({
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        logo: data.data.logo,
        email: data.data.email,
      });
    } catch (error) {
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User loading failed!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'User loading failed!',
          });

          setOpen(false);
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User loading failed!',
        });
      }
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = 'some default image url';
  };

  const checkExtension = (filename) => {
    return (
      filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
      filename
    );
  };

  const handleImageAsFile = async (e, setFieldValue, type, values) => {
    try {
      const ext = checkExtension(e.target.value);
      if (ext !== 'jpg' && ext !== 'png' && ext !== 'webp' && ext !== 'jpeg') {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Please upload valid logo!',
        });
      } else {
        setDefaultImageUpload(true);
        const image = e.target.files[0];
        const storageRef = storage.ref();
        const fileRef = storageRef.child(
          `logos/${image.name}${image.lastModifiedDate}`
        );
        const uploadTaskSnapshot = await fileRef.put(image);
        const imageUrl = await uploadTaskSnapshot.ref.getDownloadURL();
        if (type === 'logo') {
          setFieldValue('logo', imageUrl);
        } else {
          setFieldValue(`images[${values.images.length}]`, imageUrl);
        }

        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Successfully Uploaded!',
        });
      }

      // setArtWorkUpload(imageUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setDefaultImageUpload(false);
    }
    // setImageAsFile(imageFile => (image))
  };
  useEffect(() => {
    loadData();
    getCounts();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, handleChange, values, setFieldValue }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    Change my profile
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Typography
                        style={{
                          fontFamily: 'Overpass, sans-serif',
                          fontSize: '1.5rem',
                          marginLeft: 15,
                          marginBottom: 10,
                        }}
                      >
                        {' '}
                        Client Quota - {counts.clientquota}
                      </Typography>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='email'
                          component={TextField}
                          label='Email '
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='firstName'
                          label='First Name'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='lastName'
                          label='Last Name'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {defaultImageUpload ? (
                          'Uploading...'
                        ) : (
                          <Fragment>
                            <div className={classes.viewButton}>
                              <label htmlFor='upload-photo'>
                                <input
                                  style={{ display: 'none' }}
                                  id='upload-photo'
                                  name='upload-photo'
                                  type='file'
                                  accept='image/jpeg,image/png'
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile(
                                      e,
                                      setFieldValue,
                                      'logo',
                                      values
                                    );
                                  }}
                                />

                                <Button
                                  className={classes.uploadButton}
                                  variant='contained'
                                  component='span'
                                  style={{ fontFamily: 'Overpass, sans-serif' }}
                                >
                                  Logo Upload
                                </Button>
                              </label>
                              <div>
                                {values.logo ? (
                                  <img
                                    src={values.logo}
                                    alt='advisor logo'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1rem',
                                    }}
                                    onError={addDefaultSrc}
                                  ></img>
                                ) : (
                                  <img
                                    alt='advisor logo'
                                    src='https://via.placeholder.com/150/#C0C0C0/000000?Text=WebsiteBuilders.com%20C/O%20https://placeholder.com/'
                                    style={{
                                      maxHeight: '200px',
                                      marginTop: '1rem',
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                      <Typography
                        style={{
                          marginLeft: 6,
                          fontFamily: 'Overpass, sans-serif',
                        }}
                      >
                        * Logo is required
                      </Typography>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          className={classes.button}
                          color='primary'
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          save
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>
                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
