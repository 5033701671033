import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import MobileViewport from "../mobileViewport/MobileViewport";

const PrivacyPolicy = () => {
  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col xs={12} className="p-4">
                <Heading heading="PreAssessMe website privacy policy" />
                <p className="mb-2">
                  This Privacy Policy applies to all personal information
                  collected by Preassess Me Pty Ltd via the website located at
                  https://preassessme.com.au/.
                </p>
                <h5 className="pt-3 pb-1">
                  1. What is "personal information"?
                </h5>
                <p className="mb-2">
                  (a) The Privacy Act 1988 (Cth) currently defines "personal
                  information" as meaning information or an opinion about an
                  identified individual or an individual who is reasonably
                  identifiable:
                </p>
                <p className="mb-2 ms-4">
                  (i) whether the information or opinion is true or not; and
                </p>
                <p className="mb-2 ms-4">
                  (ii) whether the information or opinion is recorded in a
                  material form or not.
                </p>
                <p className="mb-2">
                  (b) If the information does not disclose your identity or
                  enable your identity to be ascertained, it will in most cases
                  not be classified as "personal information" and will not be
                  subject to this privacy policy.
                </p>
                <h5 className="pt-3 pb-1">
                  2. What information do we collect?
                </h5>
                <p className="mb-2">
                  (a) The kind of personal information that we collect from you
                  will depend on how you use the website. The personal
                  information which we collect and hold about you may include:
                  Health and Pursuits Information.
                </p>
                <h5 className="pt-3 pb-1">
                  3. How we collect your personal information
                </h5>
                <p className="mb-2">
                  (a) We may collect personal information from you whenever you
                  input such information into the website.
                </p>
                <p className="mb-2">
                  (b) We also collect cookies from your computer which enable us
                  to tell when you use the website and also to help customise
                  your website experience. As a general rule, however, it is not
                  possible to identify you personally from our use of cookies.
                </p>
                <h5 className="pt-3 pb-1">4. Purpose of collection</h5>
                <p className="mb-2">
                  (a) The purpose for which we collect personal information is
                  to provide you with the best service experience possible on
                  the website.
                </p>
                <p className="mb-2">
                  (b) We customarily disclose personal information only to our
                  service providers who assist us in operating the website. Your
                  personal information may also be exposed from time to time to
                  maintenance and support personnel acting in the normal course
                  of their duties.
                </p>
                <p className="mb-2">
                  (c) By using our website, you consent to the receipt of direct
                  marketing material. We will only use your personal information
                  for this purpose if we have collected such information direct
                  from you, and if it is material of a type which you would
                  reasonably expect to receive from us. We do not use sensitive
                  personal information in direct marketing activity. Our direct
                  marketing material will include a simple means by which you
                  can request not to receive further communications of this
                  nature.
                </p>
                <h5 className="pt-3 pb-1">5. Access and correction</h5>
                <p className="mb-2">
                  Australian Privacy Principle 12 permits you to obtain access
                  to the personal information we hold about you in certain
                  circumstances, and Australian Privacy Principle 13 allows you
                  to correct inaccurate personal information subject to certain
                  exceptions. If you would like to obtain such access, please
                  contact us as set out below.
                </p>
                <h5 className="pt-3 pb-1">6. Complaint procedure</h5>
                <p className="mb-2">
                  If you have a complaint concerning the manner in which we
                  maintain the privacy of your personal information, please
                  contact us as set out below. All complaints will be considered
                  by Rachael Ooi and we may seek further information from you to
                  clarify your concerns. If we agree that your complaint is well
                  founded, we will, in consultation with you, take appropriate
                  steps to rectify the problem. If you remain dissatisfied with
                  the outcome, you may refer the matter to the Office of the
                  Australian Information Commissioner.
                </p>
                <h5 className="pt-3 pb-1">7. Overseas transfer</h5>
                <p className="mb-2">
                  Your personal information will not be disclosed to recipients
                  outside Australia unless you expressly request us to do so. If
                  you request us to transfer your personal information to an
                  overseas recipient, the overseas recipient will not be
                  required to comply with the Australian Privacy Principles and
                  we will not be liable for any mishandling of your information
                  in such circumstances.
                </p>
                <h5 className="pt-3 pb-1">
                  8. How to contact us about privacy
                </h5>
                <p className="mb-2">
                  If you have any queries, or if you seek access to your
                  personal information, or if you have a complaint about our
                  privacy practices, you can contact us through:{" "}
                  <a href="mailto:info@preassessme.com.au">
                    info@preassessme.com.au
                  </a>
                </p>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default PrivacyPolicy;
