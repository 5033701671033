import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";

const resetArray = [
  { name: "therapyCheck", type: "" },
  { name: "therapyTypes", type: [] },
  { name: "howLongHospital", type: "" },
  { name: "treatmentTime", type: "" },
  { name: "asthmaAttacks", type: [] },
];

export default function FormAsthmaVal({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Asthma", value: "AsthmaVal" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("AsthmaVal") && (
        <Fragment>
          <YesNoQuestion
            question="In the last 12 months have you required hospitalisation, nebuliser or steroid therapy for asthma?"
            name="therapyCheck"
            isSubForm
          />
          {values.therapyCheck === "true" && (
            <CheckBox
              question="Select which apply?"
              name="therapyTypes"
              list={[
                { label: "Hospitalisation", value: "Hospitalisation" },
                { label: "Nebuliser", value: "Nebuliser" },
                {
                  label: "Steriod therapy tablet or syrup",
                  value: "Steriod therapy tablet or syrup",
                },
              ]}
              isSubForm
            />
          )}
          {values.therapyTypes.includes("Hospitalisation") && (
            <AnswerBox
              label="How long were you in the hospital?"
              name="howLongHospital"
              isSubForm
            />
          )}
          {(values.therapyTypes.includes("Nebuliser") ||
            values.therapyTypes.includes(
              "Steriod therapy tablet or syrup"
            )) && (
            <AnswerBox
              label="When did you receive this treatment and for how long?"
              name="treatmentTime"
              isSubForm
            />
          )}
          {values.illness.includes("AsthmaVal") && (
            <RadioMulti
              question="How many asthma attacks have you suffered in the last 12 months?"
              name={"asthmaAttacks"}
              items={[
                { label: "None", value: "None" },
                { label: "1-5 attacks", value: "1To5Attacks" },
                { label: "6-10 attacks", value: "6To10Attacks" },
                { label: "More than 10 attacks", value: "mT10Attacks" },
              ]}
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
