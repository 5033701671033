import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField as TF, Box } from "@mui/material/";
import enAuLocale from "date-fns/locale/en-AU";

import styles from "../../../styles/form.module.css";

import moment from "moment";

const resetArray = [
  { name: "addictionDescribeSymptoms", type: "" },
  { name: "addictionStartDate", type: "" },
  { name: "addictionTimePeriod", type: "" },
  { name: "addictionReasons", type: "" },
  { name: "addictionFactors", type: "" },
  { name: "addictionThoughts", type: "" },
  { name: "addictionWhatTriggered", type: "" },
  { name: "addictionSymptomsCommenced", type: "" },
  { name: "addictionReoccurrencesOfCondition", type: "" },
  { name: "addictionHowManyReoccurrences", type: "" },
  { name: "addictionTreatment", type: [] },
  { name: "addictionCounsellingWhenStart", type: "" },
  { name: "addictionCounsellingEndDate", type: "" },
  { name: "addictionCounsellingTherapyDetails", type: "" },
  { name: "addictionPsychotherapyWhenStart", type: "" },
  { name: "addictionPsychotherapyEndDate", type: "" },
  { name: "addictionPsychotherapyTherapyDetails", type: "" },
  { name: "addictionMedicationDose", type: "" },
  { name: "addictionMedicationEndDate", type: "" },
  { name: "addictionConditionMedication", type: "" },
  { name: "addictionDosageOften", type: "" },
  { name: "addictionDutiesRestrictionCondition", type: "" },
  { name: "addictionWorkRestriction", type: "" },
  { name: "addictionLifeRestriction", type: "" },
  { name: "addictionDetails", type: "" },
];
export default function Addiction({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Alcohol, other substance abuse or addiction",
            value: "addiction",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.anxiety.includes("addiction") && (
        <AnswerBox
          label="Describe your symptoms"
          name="addictionDescribeSymptoms"
          isSubForm
        />
      )}
      {values.addictionDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="addictionStartDate"
          isSubForm
        />
      )}
      {values.addictionStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="addictionTimePeriod"
          isSubForm
        />
      )}
      {values.addictionTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause of these details?"
          name="addictionReasons"
          isSubForm
        />
      )}
      {values.addictionReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="addictionFactors"
          isSubForm
        />
      )}
      {(values.addictionReasons === "false" || values.addictionFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts , threatened to self harm or engaged in self harm?"
          name="addictionThoughts"
          isSubForm
        />
      )}
      {values.addictionThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self-harm
          threats or actual, provide details, or how often and the last occurrence of
          them."
          name="addictionWhatTriggered"
          isSubForm
        />
      )}
      {values.addictionThoughts === "false" ||
        (values.addictionWhatTriggered && (
          <AnswerBox
            label="What was the date the symptoms commenced?"
            name="addictionSymptomsCommenced"
            isSubForm
          />
        ))}
      {values.addictionSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="addictionReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.addictionReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="addictionHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.addictionReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="addictionTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.addictionTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="addictionCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="addictionCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="addictionCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.addictionReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="addictionTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}

      {values.addictionTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="addictionPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="addictionPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="addictionPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.addictionReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="addictionTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.addictionTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="addictionMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="addictionMedicationEndDate"
            isSubForm
          />

          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="addictionConditionMedication"
            isSubForm
          />

          {values.addictionConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="addictionDosageOften"
              isSubForm
            />
          )}
          {(values.addictionDosageOften ||
            values.addictionConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="addictionDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.addictionDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="addictionWorkRestriction"
              isSubForm
            />
          )}

          {(values.addictionDutiesRestrictionCondition === "false" ||
            values.addictionWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="addictionLifeRestriction"
              isSubForm
            />
          )}
          {values.addictionLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="addictionDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
