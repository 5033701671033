import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";

const items = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I don't know", value: "I don't know" },
];
const resetArray = [
  //HeartMurmurs
  { name: "murmur", type: [] },
  { name: "ValvularOrHeart", type: [] },
  { name: "murmurTreat", type: "" },
  { name: "murmurTreatDet", type: "" },
  { name: "ongoingTreat", type: "" },
  { name: "murmurTreatMonitoringDet", type: "" },
];

export default function FormHeartMurmurs({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Heart Murmurs", value: "HeartMurmurs" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("HeartMurmurs") && (
        <RadioMulti
          name={"murmur"}
          question="Has your heart murmur been confirmed as innocent or benign by GP or cardiologist?"
          items={items}
          isSubForm
        />
      )}
      {values.illness.includes("HeartMurmurs") && (
        <RadioMulti
          name={"ValvularOrHeart"}
          question="Do you have any associated Valvular or heart disease?"
          items={items}
          isSubForm
        />
      )}
      {values.illness.includes("HeartMurmurs") && (
        <YesNoQuestion
          name="murmurTreat"
          question="Have you had or do you need to have treatment for your heart murmur?"
          isSubForm
        />
      )}
      {values.illness.includes("HeartMurmurs") &&
        values.murmurTreat === "true" && (
          <AnswerBox
            label="Please provide details of treatment"
            name="murmurTreatDet"
            isSubForm
          />
        )}
      {values.illness.includes("HeartMurmurs") && (
        <YesNoQuestion
          name="ongoingTreat"
          question="Has your doctor recommended you receive ongoing treatment or monitoring?"
          isSubForm
        />
      )}
      {values.illness.includes("HeartMurmurs") &&
        values.ongoingTreat === "true" && (
          <AnswerBox
            label="Please provide details"
            name="murmurTreatMonitoringDet"
            isSubForm
          />
        )}
    </Fragment>
  );
}
