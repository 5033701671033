import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";

const resetArray = [
  { name: "whenSleepApnoea", type: "" },
  { name: "isConfirmedDiagnosis", type: "" },
  { name: "typeOfSleepApnoea", type: [] },
  { name: "sleepApnoeaClassified", type: [] },
  { name: "isConfirmedDiagnosis", type: "" },
];

export default function FormSleepApnoea({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Sleep Apnoea", value: "SleepApnoea" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("SleepApnoea") && (
        <Fragment>
          <AnswerBox
            name="whenSleepApnoea"
            label="When were you diagnosed with sleep apnoea?"
            isSubForm
          />
          <YesNoQuestion
            question="Was your Diagnosis confirmed with a sleep study?"
            name="isConfirmedDiagnosis"
            isSubForm
          />
          <RadioMulti
            question="What type of sleep apnoea have you been diagnosed with?"
            name="typeOfSleepApnoea"
            items={[
              {
                label: "Obstructive Sleep Apnoea",
                value: "ObstructiveSleepApnoea",
              },
              {
                label: "Mixed/Complex sleep apnoea",
                value: "MixedOrComplexSleepApnoea",
              },
              { label: "Central Sleep Apnoea", value: "Central Sleep Apnoea" },
              { label: "I don't know", value: "IDK" },
            ]}
            isSubForm
          />
          <RadioMulti
            question="How has your sleep apnoea been classified?"
            name="sleepApnoeaClassified"
            items={[
              { label: "Mild", value: "Mild" },
              { label: "Moderate", value: "Moderate" },
              { label: "Severe", value: "Severe" },
              { label: "I don't know", value: "IDK" },
            ]}
            isSubForm
          />
          <YesNoQuestion
            question="Do you use a CPAP machine every night to control your symptoms?"
            name="useCPAP"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
