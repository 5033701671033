import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";
import YesNoQuestion from "../YesNoQuestion";
const resetArray = [
  { name: "diagOtherblooddisorder", type: "" },
  { name: "treatOtherblooddisorder", type: "" },
  { name: "isOngTreatOtherblooddisorder", type: "" },
  { name: "detailsOtherblooddisorder", type: "" },
];
export default function FormOtherblooddisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Any other blood disorders", value: "Otherblooddisorder" },
        ]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Otherblooddisorder") && (
        <Fragment>
          <AnswerBox
            name="diagOtherblooddisorder"
            label="What was the diagnosis?"
            isSubForm
          />
          <AnswerBox
            name="treatOtherblooddisorder"
            label="What was the treatment that was recommended by a medical professional?"
            isSubForm
          />
          <YesNoQuestion
            question="Is there any ongoing treatment for Blood Disorder?"
            name="isOngTreatOtherblooddisorder"
            isSubForm
          />
          {values.isOngTreatOtherblooddisorder === "true" && (
            <AnswerBox
              name="detailsOtherblooddisorder"
              label="Please provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
