import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const EmploymentTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-employment-tabs"
          defaultActiveKey="#employment-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              <ListGroup.Item action href={`#employment-1`}>
                Employment
              </ListGroup.Item>
              <ListGroup.Item action href={`#employment-2`}>
                Income
              </ListGroup.Item>
              <ListGroup.Item action href={`#employment-3`}>
                Work activities
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              <Tab.Pane eventKey={`#employment-1`}>
                <Table
                  id="form-view-employment-table"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr className="border-0">
                      <th id="question-heading" className="border-0 p-0"></th>
                      <th id="answer-heading" className="border-0 p-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Select your employment status</td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentyFive.employeeStatement
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        How long have you been in your current role or
                        employment status?
                      </td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentyFive.howLongYourCurrentJob
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>How much sick leave do you have owning?</td>
                      <td>{props.formData.formTwentyFive.howMuchSickLeave}</td>
                    </tr>
                    <tr>
                      <td>
                        Apart from your current job, how many jobs have you had
                        in the past 5 years?
                      </td>
                      <td>
                        {arrayToFormattedList(
                          props.formData.formTwentyFive.howManyJobsHave
                        )}
                      </td>
                    </tr>
                    {props.formData.formTwentyFive.howManyJobsHave &&
                    props.formData.formTwentyFive.howManyJobsHave.length > 0 ? (
                      <>
                        <tr>
                          <td>Please provide details of the jobs</td>
                          <td>{props.formData.formTwentyFive.jobsDetails}</td>
                        </tr>
                        <tr>
                          <td>Do you have another occupation?</td>
                          <td>
                            {props.formData.formTwentyFive.AnyOccupation === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you have any definite plans to change your
                            occupation, work duties, work hours or employment
                            status?
                          </td>
                          <td>
                            {props.formData.formTwentyFive.haveAnyDefinitePlans === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwentyFive.haveAnyDefinitePlans ? (
                          <>
                            <tr>
                              <td>Please provide details of the jobs</td>
                              <td>{props.formData.formTwentyFive.aboutJobs}</td>
                            </tr>
                            <tr>
                              <td>
                                Do you work in any of the following industries?
                              </td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formTwentyFive
                                    .workingIndustries
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Have your hours or work been reduced or to your
                                knowledge will they be affected by the
                                Coronavirus outbreak?
                              </td>
                              <td>
                                {
                                  props.formData.formTwentyFive
                                    .ReducedAnyHoursOtWork
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td>
                        Are you or any business with which you are associated,
                        contemplating, voluntary administration, or ever been
                        made bankrupt or placed in receivership, involuntary
                        liquidation or under administration?
                      </td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentyFive.isAnyBusiness
                        )}
                      </td>
                    </tr>
                    {props.formData.formTwentyFive.isAnyBusiness == "true" ? (
                      <tr>
                        <td>
                          Advise when
                          bankruptcy/receivership/liquidation/administration
                          occurred and date of discharge (if applicable)
                        </td>
                        <td>{props.formData.formTwentyFive.advises}</td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey={`#employment-2`}>
                <Table
                  id="form-view-employment-table"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr className="border-0">
                      <th id="question-heading" className="border-0 p-0"></th>
                      <th id="answer-heading" className="border-0 p-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        What is your income from your current occupation?
                        (Personal income is income earned by your personal
                        exertion. Do not include investments.)
                      </td>
                      <td>{props.formData.formTwentySix.income}</td>
                    </tr>
                    <tr>
                      <td>
                        What was your reportable employment income for the last
                        financial year?
                      </td>
                      <td>
                        {
                          props.formData.formTwentySix
                            .reportableIncomeForLastYear
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        What was your reportable employment income from the
                        previous financial year?
                      </td>
                      <td>
                        {
                          props.formData.formTwentySix
                            .reportableIncomeForPreviousYear
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey={`#employment-3`}>
                <Table
                  id="form-view-employment-table"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr className="border-0">
                      <th id="question-heading" className="border-0 p-0"></th>
                      <th id="answer-heading" className="border-0 p-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Do you have any qualifications?</td>
                      <td>
                        {arrayToFormattedList(
                          props.formData.formTwentySix.anyQualification
                        )}
                      </td>
                    </tr>
                    {props.formData.formTwentySix.anyQualification &&
                    props.formData.formTwentySix.anyQualification.length > 0 ? (
                      <tr>
                        <td>Provide qualification details</td>
                        <td>
                          {props.formData.formTwentySix.qualificationDetails}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td>
                        How many weeks a year do your work in the aforementioned
                        occupation?
                      </td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentySix.howManyWeeks
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        How much driving do you do for work (not travelling to
                        and from workplace)
                      </td>
                      <td>
                        {arrayToFormattedList(
                          props.formData.formTwentySix.howMuchDriving
                        )}
                      </td>
                    </tr>
                    {props.formData.formTwentySix.howMuchDriving > 0 ? (
                      <tr>
                        <td>
                          How much time in your workday do you spend driving?
                        </td>
                        <td>
                          {formatFormData(
                            props.formData.formTwentySix.howMuchTime
                          )}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td>How much time do you spend working from home?</td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentySix.spendTimeWorkingFromHome
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Do you do any manual work?</td>
                      <td>
                        {formatFormData(
                          props.formData.formTwentySix.anyManualWork
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>How much time do you spend kneeling in a workday?</td>
                      <td>{props.formData.formTwentySix.kneeling}%</td>
                    </tr>
                    <tr>
                      <td>How much time do you spend sitting in a workday?</td>
                      <td>{props.formData.formTwentySix.sitting}%</td>
                    </tr>
                    <tr>
                      <td>How much time do you spend standing in a workday?</td>
                      <td>{props.formData.formTwentySix.standing}%</td>
                    </tr>
                    <tr>
                      <td>How much time do you spend walking in a workday?</td>
                      <td>{props.formData.formTwentySix.walking}%</td>
                    </tr>
                    <tr>
                      <td>How much time do you spend bending in a workday?</td>
                      <td>{props.formData.formTwentySix.bending}%</td>
                    </tr>
                    <tr>
                      <td>How much time do you spend climbing in a workday?</td>
                      <td>{props.formData.formTwentySix.climbing}%</td>
                    </tr>
                  </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default EmploymentTab;
