import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import UnderwriterReplyForm from "../../components/UnderwriterReplyForm/UnderwriterReplyForm";
import formatCurrency from "../../utils/formatCurrency";
import MobileViewport from "../mobileViewport/MobileViewport";

const UnderwriterReply = () => {
  const { assessmentID, underwriterID } = useParams();
  const [formData, setFormData] = useState({});
  const [underwriterData, setUnderwriterData] = useState({});
  const [advisorData, setAdvisorData] = useState({});

  const loadFormData = async () => {
    try {
      const { data } = await axios.post("form/pointer", {
        formid: assessmentID,
      });
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUnderwriterData = async () => {
    try {
      const { data } = await axios.get(`underwriter/${underwriterID}`);
      setUnderwriterData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAdvisorData = async () => {
    try {
      const { data } = await axios.get(`user/${formData.client.advisor}`);
      setAdvisorData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInsuranceSum = (filter) => {
    let sum = 0;
    for (
      let i = 0;
      i < Object.keys(formData.client.insuranceType).length;
      i++
    ) {
      console.log(filter);
      if (formData.client.insuranceType[i].inType === filter) {
        sum = formData.client.insuranceType[i].sum;
        break;
      }
    }
    return formatCurrency(parseInt(sum));
  };

  useEffect(() => {
    loadFormData();
    loadUnderwriterData();
  }, []);

  useEffect(() => {
    loadAdvisorData();
  }, [formData]);

  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="h-100">
          <NavBar />
          <Col className="p-4">
            <Heading heading={`Underwriter reply`} />
            <Row>
              <Col xs={6}>
                {formData.client ? (
                  <UnderwriterReplyForm
                    assessmentID={assessmentID}
                    advisorID={formData.client.advisor}
                    advisorName={advisorData.fullName}
                    advisorFirm={advisorData.adviceFirm}
                    underwriterID={underwriterData._id}
                    underwriterName={underwriterData.name}
                    underwriterCompany={underwriterData.companyId.name}
                    underwriterEmail={underwriterData.email}
                    underwriterNumber={underwriterData.contactNumber}
                    pid={formData.client.pid}
                  />
                ) : (
                  "Loading..."
                )}
              </Col>
              <Col xs={6}>
                <Alert variant="info">
                  <h5>Assessment details</h5>
                  {formData.client ? (
                    <>
                      <p>
                        This assessment has been provided by{" "}
                        {advisorData.fullName} from {advisorData.adviceFirm}.
                      </p>
                      <p>
                        The PreAssessMe reference for this submission is{" "}
                        <strong>{assessmentID}</strong>
                      </p>
                      <p>
                        To view the full questionnaire response, use the below
                        button
                      </p>
                      <a href={`/formview/${assessmentID}`}>
                        <Button className="mb-3" variant="primary">
                          View questionnaire
                        </Button>
                      </a>
                      <p>The following sums have been requested:</p>
                      <Table striped>
                        <thead>
                          <tr>
                            <td>Life insurance</td>
                            <td>TPD insurance</td>
                            <td>Trauma insurance</td>
                            <td>Income protection insurance</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{getInsuranceSum("life")}</td>
                            <td>{getInsuranceSum("tpd")}</td>
                            <td>{getInsuranceSum("trauma")}</td>
                            <td>{getInsuranceSum("incp")}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    ""
                  )}
                </Alert>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default UnderwriterReply;
