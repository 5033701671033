import React from "react";
import { Grid, FormControlLabel, Radio, Box } from "@material-ui/core";
import styles from "../../styles/form.module.css";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";

export default function YesNoQuestion({
  question,
  name,
  labels = { yes: "Yes", no: "No" },
  onClick,
  isSubForm = false,
}) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box className={isSubForm ? styles.subFormBox : styles.FormBox}>
        <p className={styles.question}>{question}</p>
        <Field component={RadioGroup} name={name} row>
          <FormControlLabel
            style={{ fontFamily: "Overpass, sans-serif", fontSize: "0.9rem" }}
            control={<Radio />}
            label={
              <span
                style={{
                  fontFamily: "Overpass, sans-serif",
                }}
              >
                {labels.yes}{" "}
              </span>
            }
            value="true"
            onClick={onClick}
            color="blue"
          />
          <FormControlLabel
            style={{ fontFamily: "Overpass, sans-serif", fontSize: "0.9rem" }}
            control={<Radio />}
            label={
              <span
                style={{
                  fontFamily: "Overpass, sans-serif",
                }}
              >
                {labels.no}{" "}
              </span>
            }
            value="false"
            onClick={onClick}
            color="red"
          />
        </Field>
      </Box>
    </Grid>
  );
}
