import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const VirusesTab = (props) => {
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container id="formview-viruses-tabs" defaultActiveKey="#viruses-1">
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formTwenty.illness.map((illness, index) => (
                <ListGroup.Item action href={`#viruses-${index + 1}`}>
                  {illness
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    illness
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formTwenty.otherIllness.map((illness, index) => (
                <Tab.Pane eventKey={`#viruses-${index + 1}`}>
                  <Table
                    id="form-view-viruses-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {illness === "HepatitisB" ? (
                      <tbody>
                        <tr>
                          <td>When were you diagnosed?</td>
                          <td>{props.formData.formTwenty.hepatitisOrHIV}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formTwenty.treatHepatitisOrHIV}
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formTwenty.isOngTreatHepatitisOrHIV === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwenty.isOngTreatHepatitisOrHIV ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formTwenty.detailsHepatitisOrHIV}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "HepatitisC" ? (
                      <tbody>
                        <tr>
                          <td>When were you diagnosed?</td>
                          <td>{props.formData.formTwenty.hepatitisOrHIV}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formTwenty.treatHepatitisOrHIV}
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formTwenty.isOngTreatHepatitisOrHIV === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwenty.isOngTreatHepatitisOrHIV ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formTwenty.detailsHepatitisOrHIV}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "HepatitisBCr" ? (
                      <tbody>
                        <tr>
                          <td>When were you diagnosed?</td>
                          <td>{props.formData.formTwenty.hepatitisOrHIV}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formTwenty.treatHepatitisOrHIV}
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formTwenty.isOngTreatHepatitisOrHIV === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwenty.isOngTreatHepatitisOrHIV ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formTwenty.detailsHepatitisOrHIV}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "HepatitisCCr" ? (
                      <tbody>
                        <tr>
                          <td>When were you diagnosed?</td>
                          <td>{props.formData.formTwenty.hepatitisOrHIV}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formTwenty.treatHepatitisOrHIV}
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formTwenty.isOngTreatHepatitisOrHIV === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwenty.isOngTreatHepatitisOrHIV ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formTwenty.detailsHepatitisOrHIV}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "AidsOrHiv" ? (
                      <tbody>
                        <tr>
                          <td>When were you diagnosed?</td>
                          <td>{props.formData.formTwenty.hepatitisOrHIV}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formTwenty.treatHepatitisOrHIV}
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formTwenty.isOngTreatHepatitisOrHIV === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formTwenty.isOngTreatHepatitisOrHIV ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formTwenty.detailsHepatitisOrHIV}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : (
                      <></>
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default VirusesTab;
