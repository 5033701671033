import { Grid } from "@material-ui/core";
import React from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const questions = [
  {
    name: "hangGliding1",
    label: "How often do you go Hang Gliding?",
  },
  {
    name: "hangGliding2",
    label: "How many times have you done this in the last 12 months?",
  },
  {
    name: "hangGliding3",
    label:
      "What Qualifications, certificates, licences, or club memberships do you have?",
  },
  {
    name: "hangGliding4",
    label: "Where do you engage in hang gliding and in what locations?",
  },
  {
    name: "hangGliding5",
    label: "Do you engage in hang gliding alone or in a group?",
  },
  {
    name: "hangGliding6",
    label: "What is the maximum height you get to?",
  },
  {
    name: "hangGliding7",
    label: "Are you involved in any record attempts?",
  },
  {
    name: "hangGliding8",
    label:
      "Are all recognized safety measures and precautions followed? Provide details.",
  },
  {
    name: "hangGliding9",
    label:
      "Have you ever been involved in an accident whilst hang gliding? (details)",
  },
];

const resetArray = [
  { name: "hangGliding1", type: "" },
  { name: "hangGliding2", type: "" },
  { name: "hangGliding3", type: "" },
  { name: "hangGliding4", type: "" },
  { name: "hangGliding5", type: "" },
  { name: "hangGliding6", type: "" },
  { name: "hangGliding7", type: "" },
  { name: "hangGliding8", type: "" },
  { name: "hangGliding9", type: "" },
];

export default function Football({ values, setFieldValue, setFieldTouched}) {
  const resetFold = (e) => {
    if (!e.target.checked) {
      for (let item of resetArray) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Grid container md={12} sm={12} xs={12}>
      <CheckBox
        list={[{ label: "Hang Gliding", value: "hangGliding" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e)}
      />

      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("hangGliding") &&
        questions.map((question) => (
          <AnswerBox
            label={question.label}
            name={question.name}
            key={question.name}
            isSubForm
          />
        ))}
    </Grid>
  );
}
