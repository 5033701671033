import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

export const useStyles = makeStyles((theme) => ({
  img: {
    width: "40%",
    height: 700,
  },

  box: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tittle: {
    fontFamily: "holiday",
    textAlign: "center",
    fontSize: "3rem",
    marginTop: 20,
  },
  text: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "1rem",
    textAlign: "center",
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "2.5rem",
    // },
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "1.2rem",
    // },
    // [theme.breakpoints.down("md")]: {
    //   fontSize: "1rem",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "1rem",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: "1rem",
    //   display: "none",
    // },
  },
  hide: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  uploadButton: {
    width: "50%",
    height: 50,
    fontSize: "1rem",
    backgroundColor: "#004aad",
    fontFamily: "Overpass, sans-serif",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  nextButton: {
    backgroundColor: "#004aad",
    fontFamily: "Overpass, sans-serif",
    color: "white",
  },
}));

export const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontSize: "1.2.6rem",
        color: "#000000",
        width: "90%",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000",
      },
    },
  },
}))(TextField);
