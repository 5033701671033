import * as Yup from "yup";

// Add or subtract time from today's date and return calculated date
function createDate(days, months, years) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export const newAssessmentSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Exceeded max length of 50 characters")
    .required("Name required"),
  lastName: Yup.string()
    .max(50, "Exceeded max length of 50 characters")
    .required("Name required"),
  gender: Yup.string().required("Gender required"),
  dob: Yup.date()
    .min(createDate(0, 0, -150), "Please enter valid birth date")
    .max(createDate(0, 0, -10), "Please enter valid birth date")
    .required("Date of birth required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email address required"),
  occupation: Yup.string().required("Occupation required"),
  mobile: Yup.string()
    .matches(/04\d{8}/, "Please enter an Australian mobile number")
    .required("Australian mobile number required"),
  lifeInsuranceSum: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid whole number")
    .positive("Please enter valid sum"),
  tpdInsuranceSum: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid whole number")
    .positive("Please enter valid sum"),
  traumaInsuranceSum: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid whole number")
    .positive("Please enter valid sum"),
  incomeProtectionInsuranceSum: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid whole number")
    .positive("Please enter a valid income"),
});

export const underwriterReplySchema = Yup.object().shape({
  //--Form section one - Underwriter details--//
  underwriterName: Yup.string()
    .max(50, "Exceeded max length of 50 characters")
    .required("Name required"),
  underwriterCompany: Yup.string()
    .max(50, "Exceeded max length of 50 characters")
    .required("Company required"),
  underwriterEmail: Yup.string()
    .email("Please check email format")
    .max(320, "Exceeded max length of 320 characters")
    .required("Email address required"),
  underwriterNumber: Yup.number().required("Phone number required"),
  underwriterCompany: Yup.string()
    .max(50, "Exceeded max length of 50 characters")
    .required("Reference required"),

  //--Form section two - Insurance acceptances--//
  lifeCovered: Yup.string().required("Response required"),
  tpdCovered: Yup.string().required("Response required"),
  traumaCovered: Yup.string().required("Response required"),
  incomeProtectionCovered: Yup.string().required("Response required"),

  //--Form section three - Terms tabs (Life)--//
  lifeTermsOffered: Yup.string().when("lifeCovered", {
    is: "lifeCoveredFalse",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  lifeLoading: Yup.string()
    .when("lifeTermsOffered", {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  lifeLoadingTimeFrame: Yup.string().when("lifeTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  lifeLoadingTimeFrameDetails: Yup.string()
    .when("lifeLoadingTimeFrame", {
      is: "lifeLoadingTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  lifeLoadingFurtherInfo: Yup.string().when("lifeTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  lifeLoadingFurtherInfoDetails: Yup.string()
    .when("lifeLoadingFurtherInfo", {
      is: "lifeLoadingFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  lifeExclusion: Yup.string()
    .when("lifeTermsOffered", {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  lifeExclusionTimeFrame: Yup.string().when("lifeTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  lifeExclusionTimeFrameDetails: Yup.string()
    .when("lifeExclusionTimeFrame", {
      is: "lifeExclusionTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  lifeExclusionFurtherInfo: Yup.string().when("lifeTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  lifeExclusionFurtherInfoDetails: Yup.string()
    .when("lifeExclusionFurtherInfo", {
      is: "lifeExclusionFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  lifeNoTermsReason: Yup.string().when("lifeTermsOffered", {
    is: "None",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),

  //--Form section three - Terms tabs (TPD)--//
  tpdTermsOffered: Yup.string().when("tpdCovered", {
    is: "tpdCoveredFalse",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  tpdLoading: Yup.string()
    .when("tpdTermsOffered", {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  tpdLoadingTimeFrame: Yup.string().when("tpdTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  tpdLoadingTimeFrameDetails: Yup.string()
    .when("tpdLoadingTimeFrame", {
      is: "tpdLoadingTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  tpdLoadingFurtherInfo: Yup.string().when("tpdTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  tpdLoadingFurtherInfoDetails: Yup.string()
    .when("tpdLoadingFurtherInfo", {
      is: "tpdLoadingFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  tpdExclusion: Yup.string()
    .when("tpdTermsOffered", {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  tpdExclusionTimeFrame: Yup.string().when("tpdTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  tpdExclusionTimeFrameDetails: Yup.string()
    .when("tpdExclusionTimeFrame", {
      is: "tpdExclusionTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  tpdExclusionFurtherInfo: Yup.string().when("tpdTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  tpdExclusionFurtherInfoDetails: Yup.string()
    .when("tpdExclusionFurtherInfo", {
      is: "tpdExclusionFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  tpdNoTermsReason: Yup.string().when("tpdTermsOffered", {
    is: "None",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),

  //--Form section three - Terms tabs (Trauma)--//
  traumaTermsOffered: Yup.string().when("traumaCovered", {
    is: "traumaCoveredFalse",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  traumaLoading: Yup.string()
    .when("traumaTermsOffered", {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  traumaLoadingTimeFrame: Yup.string().when("traumaTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  traumaLoadingTimeFrameDetails: Yup.string()
    .when("traumaLoadingTimeFrame", {
      is: "traumaLoadingTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  traumaLoadingFurtherInfo: Yup.string().when("traumaTermsOffered", {
    is: "Loading",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  traumaLoadingFurtherInfoDetails: Yup.string()
    .when("traumaLoadingFurtherInfo", {
      is: "traumaLoadingFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  traumaExclusion: Yup.string()
    .when("traumaTermsOffered", {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  traumaExclusionTimeFrame: Yup.string().when("traumaTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  traumaExclusionTimeFrameDetails: Yup.string()
    .when("traumaExclusionTimeFrame", {
      is: "traumaExclusionTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  traumaExclusionFurtherInfo: Yup.string().when("traumaTermsOffered", {
    is: "Exclusion",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  traumaExclusionFurtherInfoDetails: Yup.string()
    .when("traumaExclusionFurtherInfo", {
      is: "traumaExclusionFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  traumaNoTermsReason: Yup.string().when("traumaTermsOffered", {
    is: "None",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),

  //--Form section three - Terms tabs (Income protection)--//
  incomeProtectionTermsOffered: Yup.string().when("incomeProtectionCovered", {
    is: "incomeProtectionCoveredFalse",
    then: (underwriterReplySchema) =>
      underwriterReplySchema.required("Response required"),
  }),
  incomeProtectionLoading: Yup.string()
    .when("incomeProtectionTermsOffered", {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  incomeProtectionLoadingTimeFrame: Yup.string().when(
    "incomeProtectionTermsOffered",
    {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    }
  ),
  incomeProtectionLoadingTimeFrameDetails: Yup.string()
    .when("incomeProtectionLoadingTimeFrame", {
      is: "incomeProtectionLoadingTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  incomeProtectionLoadingFurtherInfo: Yup.string().when(
    "incomeProtectionTermsOffered",
    {
      is: "Loading",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    }
  ),
  incomeProtectionLoadingFurtherInfoDetails: Yup.string()
    .when("incomeProtectionLoadingFurtherInfo", {
      is: "incomeProtectionLoadingFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  incomeProtectionExclusion: Yup.string()
    .when("incomeProtectionTermsOffered", {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  incomeProtectionExclusionTimeFrame: Yup.string().when(
    "incomeProtectionTermsOffered",
    {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    }
  ),
  incomeProtectionExclusionTimeFrameDetails: Yup.string()
    .when("incomeProtectionExclusionTimeFrame", {
      is: "incomeProtectionExclusionTimeFrameTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),
  incomeProtectionExclusionFurtherInfo: Yup.string().when(
    "incomeProtectionTermsOffered",
    {
      is: "Exclusion",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    }
  ),
  incomeProtectionExclusionFurtherInfoDetails: Yup.string()
    .when("incomeProtectionExclusionFurtherInfo", {
      is: "incomeProtectionExclusionFurtherInfoTrue",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    })
    .max(500, "Exceeded max length of 500 characters"),

  incomeProtectionNoTermsReason: Yup.string().when(
    "incomeProtectionTermsOffered",
    {
      is: "None",
      then: (underwriterReplySchema) =>
        underwriterReplySchema.required("Response required"),
    }
  ),
});

export const preferencesSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required!")
    .max(35, "Min length for the Password is 35"),
  lastName: Yup.string()
    .required("Last Name is required!")
    .max(35, "Min length for the Password is 35"),
  logo: Yup.string().required("Logo is required!"),
  email: Yup.string()
    .email()
    .required("Email is required!")
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      "Please enter valid Email"
    ),
});
