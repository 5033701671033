export default function formatDate(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true,
  };

  let newDate = new Date(date);

  return newDate.toLocaleString("en-GB", options);
}
