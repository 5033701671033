import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import RadioGroup from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "martialArtTypesParticipate", type: [] },
  { name: "otherMartialArtTypeDetails", type: "" },
  { name: "martialArtTypesPractice", type: "" },
  { name: "injuries", type: "" },
  { name: "competitiveMartial", type: "" },
];

export default function MartialArts({ values, setFieldValue, setFieldTouched }) {
  const martialArtItems = [
    {
      label: "Contact",
      value: "Contact",
    },
    {
      label: "Non-contact",
      value: "Non-contact",
    },
  ];
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Martial Arts", value: "martialArts" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      <Grid item md={12} sm={12} xs={12}>
        {values.activities.includes("martialArts") && (
          <Grid item md={12} sm={12} xs={12}>
            <CheckBox
              question="What type of
            Martial arts do
            you
            participate in?"
              list={[
                {
                  label: "Karate",
                  value: "Karate",
                },
                { label: "Jujitsu", value: "Jujitsu" },
                {
                  label: "Judo",
                  value: "Judo",
                },
                {
                  label: "other",
                  value: "other",
                },
              ]}
              name="martialArtTypesParticipate"
              isSubForm
            />
          </Grid>
        )}
      </Grid>

      {values.activities.includes("martialArts") &&
        values.martialArtTypesParticipate.includes("other") && (
          <AnswerBox
            label="Provide details"
            name="otherMartialArtTypeDetails"
            isSubForm
          />
        )}

      {values.activities.includes("martialArts") && (
        <RadioGroup
          question="What type of martial arts do you
         practice?"
          name="martialArtTypesPractice"
          items={martialArtItems}
          isSubForm
        />
      )}

      {values.activities.includes("martialArts") &&
        values.martialArtTypesPractice !== "" && (
          <div>
            <AnswerBox
              label="Have you had any injuries resulting from martial arts? If yes provide details"
              name="injuries"
              isSubForm
            />
            <AnswerBox
              label="Are you involved in competitive martial arts? If yes, provide the level you compete at and the frequency of competitions."
              name="competitiveMartial"
              isSubForm
            />
          </div>
        )}
    </Fragment>
  );
}
