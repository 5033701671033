
import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "aBDiagnosis", type: "" },
  { name: "completedTests", type: "" },
  { name: "resultCheck", type: "" },
  { name: "furtherDetailsSug", type: "" },

];
  export default function FormAbnormalBloodSugar({ values,
    setFieldValue,
    setFieldTouched, }) {
      const resetFold = (e, arr) => {
        if (!e.target.checked) {
          for (let item of arr) {
            setFieldValue(item.name, item.type, false);
            setFieldTouched(item.name, false);
          }
        } else {
          setFieldValue("noneOfTheAbove", false, false);
          setFieldTouched("noneOfTheAbove", false);
        }
      };

    return (
       
      <Fragment>
        <CheckBox
          list={[{label: "Abnormal blood sugar" ,value: "AbnormalBloodSugar"}]}
          name="illness"
          question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
        {values.illness.includes("AbnormalBloodSugar") && (
          <Fragment>
            <AnswerBox
              label="What was your diagnosis?"
              name="aBDiagnosis"
              isSubForm
            />
            <AnswerBox
              label="What tests were completed?"
              name="completedTests"
              isSubForm
            />
            <YesNoQuestion
                question="Were the results normal?"
                name="resultCheck"
                isSubForm
            />
            {values.resultCheck === "false" && (
              <AnswerBox
                label = "What were the results? Please provide further details."
                name = "furtherDetailsSug" 
                isSubForm
              />
            )}
          </Fragment> 
        )}
      </Fragment>
    )
  }