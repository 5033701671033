import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubFormFive from "./SubFormOfFormSeventeen/SubFormFive";
import SubFormSix from "./SubFormOfFormSeventeen/SubFormSix";
import SubFormOne from "./SubFormOfFormSeventeen/SubFormOne";
import SubFormTwo from "./SubFormOfFormSeventeen/SubFormTwo";
import SubFormThree from "./SubFormOfFormSeventeen/SubFormThree";
import SubFormFour from "./SubFormOfFormSeventeen/SubFormFour";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";


export default function FormSeventeen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    subFormsOfFormSeventeen: [],

    // form one

    liverDisorderSymptoms: "",
    liverDisorderWhenThisOccur: "",
    liverDisorderWhatTreatments: "",
    liverDisorderOngoingSymptoms: "",
    liverDisorderOngoingSymptomsDetails: "",

    //form Two

    anyPancreasEver: "",
    pancreasDisorderSymptoms: "",
    pancreasDisorderWhenThisOccur: "",
    pancreasDisorderWhatTreatments: "",
    pancreasDisorderOngoingSymptoms: "",
    pancreasDisorderOngoingSymptomsDetails: "",

    //form three

    prostateDisorderDiagnosedAs: [],
    prostateDisorderWhenThisOccur: "",
    prostateDisorderWhatTreatments: "",
    otherDetails: "",

    //form four

    kidneyDisorderSymptoms: "",
    kidneyDisorderWhenThisOccur: "",
    kidneyDisorderWhatTreatments: "",
    kidneyDisorderOngoingSymptoms: "",
    kidneyDisorderOngoingSymptomsDetails: "",

    //form five

    infectionOnly: "",
    bladderDisorderSymptoms: "",
    bladderDisorderWhenThisOccur: "",
    bladderDisorderWhatTreatments: "",
    treatmentLimitedToAntibiotics: "",
    treatmentLimitedToAntibioticsDetails: "",
    withinLast2YearsAnyMedicalInvestigation: "",
    investigationDetails: "",

    //form six

    totallyRemovedOrPassed: "",
    howManyEpisodes: "",
    ongoingProblemsWithKidneys: "",
    ongoingProblemsWithKidneysDetails: "",

    noneOfTheAbove: false,

  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    //form One

    liverDisorderSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("liverDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    liverDisorderWhenThisOccur: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("liverDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    liverDisorderWhatTreatments: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("liverDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    liverDisorderOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("liverDisorder")) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    liverDisorderOngoingSymptomsDetails: Yup.string().when(
      ["subFormsOfFormSeventeen", "liverDisorderOngoingSymptoms"],
      (
        subFormsOfFormSeventeen,

        liverDisorderOngoingSymptoms
      ) => {
        if (
          subFormsOfFormSeventeen.includes("liverDisorder") &&
          liverDisorderOngoingSymptoms === "yes"
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    //form Two

    anyPancreasEver: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("pancreasDisorder")) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    pancreasDisorderSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen", "anyPancreasEver"],
      (subFormsOfFormSeventeen, anyPancreasEver) => {
        if (
          subFormsOfFormSeventeen.includes("pancreasDisorder") &&
          anyPancreasEver === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    pancreasDisorderWhenThisOccur: Yup.string().when(
      ["subFormsOfFormSeventeen", "anyPancreasEver"],
      (subFormsOfFormSeventeen, anyPancreasEver) => {
        if (
          subFormsOfFormSeventeen.includes("pancreasDisorder") &&
          anyPancreasEver === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    pancreasDisorderWhatTreatments: Yup.string().when(
      ["subFormsOfFormSeventeen", "anyPancreasEver"],
      (subFormsOfFormSeventeen, anyPancreasEver) => {
        if (
          subFormsOfFormSeventeen.includes("pancreasDisorder") &&
          anyPancreasEver === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    pancreasDisorderOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen", "anyPancreasEver"],
      (subFormsOfFormSeventeen, anyPancreasEver) => {
        if (
          subFormsOfFormSeventeen.includes("pancreasDisorder") &&
          anyPancreasEver === "yes"
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    pancreasDisorderOngoingSymptomsDetails: Yup.string().when(
      [
        "subFormsOfFormSeventeen",

        "anyPancreasEver",
        "pancreasDisorderOngoingSymptoms",
      ],
      (
        subFormsOfFormSeventeen,

        anyPancreasEver,
        pancreasDisorderOngoingSymptoms
      ) => {
        if (
          subFormsOfFormSeventeen.includes("pancreasDisorder") &&
          anyPancreasEver === "yes" &&
          pancreasDisorderOngoingSymptoms === "yes"
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    //form Three

    prostateDisorderWhenThisOccur: Yup.string().when(
      ["subFormsOfFormSeventeen", "prostateDisorderDiagnosedAs"],
      (
        subFormsOfFormSeventeen,

        prostateDisorderDiagnosedAs
      ) => {
        if (
          subFormsOfFormSeventeen.includes("prostateDisorder") &&
          prostateDisorderDiagnosedAs.includes("prostateCancer")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    otherDetails: Yup.string().when(
      ["subFormsOfFormSeventeen", "prostateDisorderDiagnosedAs"],
      (
        subFormsOfFormSeventeen,

        prostateDisorderDiagnosedAs
      ) => {
        if (
          subFormsOfFormSeventeen.includes("prostateDisorder") &&
          prostateDisorderDiagnosedAs.includes("other")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    prostateDisorderWhatTreatments: Yup.string().when(
      ["subFormsOfFormSeventeen", "prostateDisorderDiagnosedAs"],
      (
        subFormsOfFormSeventeen,

        prostateDisorderDiagnosedAs
      ) => {
        if (
          subFormsOfFormSeventeen.includes("prostateDisorder") &&
          prostateDisorderDiagnosedAs.includes("prostateCancer")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    //form four

    kidneyDisorderSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("kidneyDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    kidneyDisorderWhenThisOccur: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("kidneyDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    kidneyDisorderWhatTreatments: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("kidneyDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    kidneyDisorderOngoingSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("kidneyDisorder")) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    kidneyDisorderOngoingSymptomsDetails: Yup.string().when(
      ["subFormsOfFormSeventeen", "kidneyDisorderOngoingSymptoms"],
      (
        subFormsOfFormSeventeen,

        kidneyDisorderOngoingSymptoms
      ) => {
        if (
          subFormsOfFormSeventeen.includes("kidneyDisorder") &&
          kidneyDisorderOngoingSymptoms === "yes"
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    //form five

    infectionOnly: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("bladderDisorder")) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    bladderDisorderSymptoms: Yup.string().when(
      ["subFormsOfFormSeventeen", "infectionOnly"],
      (subFormsOfFormSeventeen, infectionOnly) => {
        if (
          subFormsOfFormSeventeen.includes("bladderDisorder") &&
          infectionOnly === "no"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    bladderDisorderWhenThisOccur: Yup.string().when(
      ["subFormsOfFormSeventeen", "infectionOnly"],
      (subFormsOfFormSeventeen, infectionOnly) => {
        if (
          subFormsOfFormSeventeen.includes("bladderDisorder") &&
          infectionOnly === "no"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    bladderDisorderWhatTreatments: Yup.string().when(
      ["subFormsOfFormSeventeen", "infectionOnly"],
      (subFormsOfFormSeventeen, infectionOnly) => {
        if (
          subFormsOfFormSeventeen.includes("bladderDisorder") &&
          infectionOnly === "no"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    treatmentLimitedToAntibiotics: Yup.string().when(
      ["subFormsOfFormSeventeen", "bladderDisorderWhatTreatments"],
      (
        subFormsOfFormSeventeen,

        bladderDisorderWhatTreatments
      ) => {
        if (
          subFormsOfFormSeventeen.includes("bladderDisorder") &&
          bladderDisorderWhatTreatments !== ""
        ) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    treatmentLimitedToAntibioticsDetails: Yup.string().when(
      [
        "subFormsOfFormSeventeen",

        "infectionOnly",
        "treatmentLimitedToAntibiotics",
      ],
      (
        subFormsOfFormSeventeen,

        infectionOnly,
        treatmentLimitedToAntibiotics
      ) => {
        if (
          subFormsOfFormSeventeen.includes("bladderDisorder") &&
          infectionOnly === "no" &&
          treatmentLimitedToAntibiotics === "no"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    withinLast2YearsAnyMedicalInvestigation: Yup.string().when(
      [
        "subFormsOfFormSeventeen",

        "infectionOnly",
        "treatmentLimitedToAntibiotics",
        "treatmentLimitedToAntibioticsDetails",
      ],
      (
        subFormsOfFormSeventeen,

        infectionOnly,
        treatmentLimitedToAntibiotics,
        treatmentLimitedToAntibioticsDetails
      ) => {
        if (
          (subFormsOfFormSeventeen.includes("bladderDisorder") &&
            infectionOnly === "no" &&
            treatmentLimitedToAntibioticsDetails !== "") ||
          treatmentLimitedToAntibiotics === "yes" ||
          treatmentLimitedToAntibiotics === "noTreatment"
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    investigationDetails: Yup.string().when(
      [
        "subFormsOfFormSeventeen",

        "infectionOnly",
        "treatmentLimitedToAntibiotics",
        "treatmentLimitedToAntibioticsDetails",
        "withinLast2YearsAnyMedicalInvestigation",
      ],
      (
        subFormsOfFormSeventeen,

        infectionOnly,
        treatmentLimitedToAntibiotics,
        treatmentLimitedToAntibioticsDetails,
        withinLast2YearsAnyMedicalInvestigation
      ) => {
        if (
          (subFormsOfFormSeventeen.includes("bladderDisorder") &&
            infectionOnly === "no" &&
            treatmentLimitedToAntibioticsDetails !== "") ||
          treatmentLimitedToAntibiotics === "yes" ||
          (treatmentLimitedToAntibiotics === "noTreatment" &&
            withinLast2YearsAnyMedicalInvestigation === "yes")
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    //form six

    totallyRemovedOrPassed: Yup.string().when(
      ["subFormsOfFormSeventeen"],
      (subFormsOfFormSeventeen) => {
        if (subFormsOfFormSeventeen.includes("renalColicOrKidneyStone")) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    howManyEpisodes: Yup.string().when(
      ["subFormsOfFormSeventeen", "totallyRemovedOrPassed"],
      (
        subFormsOfFormSeventeen,

        totallyRemovedOrPassed
      ) => {
        if (
          subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
          totallyRemovedOrPassed !== ""
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),
    ongoingProblemsWithKidneys: Yup.string().when(
      ["subFormsOfFormSeventeen", "totallyRemovedOrPassed", "howManyEpisodes"],
      (
        subFormsOfFormSeventeen,

        totallyRemovedOrPassed,
        howManyEpisodes
      ) => {
        if (
          subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
          totallyRemovedOrPassed !== "" &&
          howManyEpisodes !== ""
        ) {
          return Yup.string().required("This field is required !");
        }
      }
    ),

    ongoingProblemsWithKidneysDetails: Yup.string().when(
      [
        "subFormsOfFormSeventeen",

        "totallyRemovedOrPassed",
        "howManyEpisodes",
        "ongoingProblemsWithKidneys",
      ],
      (
        subFormsOfFormSeventeen,

        totallyRemovedOrPassed,
        howManyEpisodes,
        ongoingProblemsWithKidneys
      ) => {
        if (
          subFormsOfFormSeventeen.includes("renalColicOrKidneyStone") &&
          totallyRemovedOrPassed !== "" &&
          howManyEpisodes !== "" &&
          ongoingProblemsWithKidneys === "yes"
        ) {
          return Yup.string().required("This field is required");
        }
      }
    ),

    noneOfTheAbove: Yup.string().when('subFormsOfFormSeventeen', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formseventeen", {
        FormSeventeen: e,
        pointer: 19,
        formid: id,
      });
      dispatch(setPage(19));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values , setFieldValue, setFieldTouched ,resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following ?
                    </p>
                    <SubFormOne values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormTwo values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormThree values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFour values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFive values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSix values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <NoneOfTheAbove
                     values={values}
                     setFieldValue={setFieldValue}
                     setFieldTouched={setFieldTouched}
                     initialValues={initialValues}
                     exceptionArray={exceptionArray}
                     setLoading={setLoading}
                     loading={loading}
                     resetForm={resetForm}
                     setErrorsFun={() => setErrors({})}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
