import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const aboTimes = [
  {label:"Within the last 12 months",value:"withinLast12m"},
  {label:"12 months to 2 years",value:"12mTo2Yr"},
  {label:"More than 2 years ",value:"moreThan2Yrs"},
  
]

const aboDiagnosis = [
  {label:"Simple Cyst",value:"simpleCyst"},
  {label:"Fibroid",value:"fibroid"},
  {label:"Polycystic Ovary Syndrome (PCOS)",value:"PCOS"},
  {label:"Other",value:"other"},

]
const resetArray =
[
  { name: 'whenAbnormalityOvaries', type: '' },
  { name: 'diagAbnormalityOvaries', type: '' },
  { name: 'reqBiopsyOrSurgery', type: '' },
  { name: 'whatOperatedProcedure', type: '' },
  { name: 'wasCystConfirmed', type: '' },
  { name: 'confirmedCystDetails', type: '' },
  { name: 'otherAbnormalityOvaries', type: '' }
];
  export default function FormAbnormalityOfTheOvaries({ values, setFieldValue, setFieldTouched }) {
    const resetFold = (e,arr) => {
      if (!e.target.checked) {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      } else {
        setFieldValue("noneOfTheAbove", false, false);
        setFieldTouched("noneOfTheAbove", false);
      }
    };
    return (
      <Fragment>
        <CheckBox
          list={[
            {label: "Abnormality of the ovaries" ,value: "abnormalityOvaries"},
          ]}
          name="illness"
          question=""
          onClick={(e) => resetFold(e,resetArray)}
      />
        {values.illness.includes("abnormalityOvaries") && (
          <Fragment>
            <RadioMulti 
              question="When was the ovarian abnormality first detected?"
              name="whenAbnormalityOvaries"
              items={aboTimes}
              isSubForm
            />
            <RadioMulti 
              question="What was the diagnosis?"
              name="diagAbnormalityOvaries"
              items={aboDiagnosis}
              isSubForm
            />
            {(values.diagAbnormalityOvaries == "simpleCyst" ||
              values.diagAbnormalityOvaries == "fibroid") && (
                <Fragment>
                  <YesNoQuestion 
                    question="Did you require further investigations? (biopsy or surgery)"
                    name="reqBiopsyOrSurgery"
                    isSubForm
                  />
                  {values.reqBiopsyOrSurgery == "true" &&(
                    <Fragment>
                      <AnswerBox 
                        name="whatOperatedProcedure"
                        label="What procedure did you have and when was it operated on?"
                        isSubForm
                      />
                      <YesNoQuestion 
                        question="Was the cyst confirmed as benign?"
                        name="wasCystConfirmed"
                        isSubForm
                      />
                      {values.wasCystConfirmed == "true" && (
                        <AnswerBox 
                          name="confirmedCystDetails"
                          label="Please provide details"
                          isSubForm
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
              {values.diagAbnormalityOvaries == "other" &&(
                <AnswerBox 
                  name="otherAbnormalityOvaries"
                  label="Please provide details"
                  isSubForm
                />
              )}
          </Fragment> 
          )}
      </Fragment>
    )
  }