import React, { useState } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
// validation
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import YesNoQuestion from './YesNoQuestion';
import CheckBox from './CheckBox';

import Abseiling from './activities/Abseiling';
import Aviation from './activities/Aviation';
import Football from './activities/Football';
import HangGliding from './activities/HangGliding';
import ScubaDiving from './activities/ScubaDiving';
import MotorRacing from './activities/MotorRacing';
import Parachuting from './activities/Parachuting';
import Powerboat from './activities/Powerboat';
import Mountaineering from './activities/Mountaineering';
import MartialArts from './activities/MartialArts';
import Sailing from './activities/Sailing';
import { useParams } from 'react-router-dom';
import NoneOfTheAbove from './NoneOfTheAbove';

import { useDispatch } from 'react-redux';
import { setPage } from '../../store/actions/formActions';

import { useStyles } from './classes';
import styles from '../../styles/form.module.css';
import axios from '../../lib/axios';

export default function FormOne() {
  const classes = useStyles();

  const { id } = useParams();

  const resetArray = [
    { name: 'covid19Result', type: '' },
    { name: 'covidList', type: [] },
  ];

  const resetArrayNegative = [{ name: 'covidList', type: [] }];

  const exceptionArray = {
    covid19: 'false',
    covid19Result: '',
    covidList: [],
    // noneOfTheAbove: true,
  };
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    covid19: '',
    covid19Result: '',
    covidList: [],

    activities: [],

    // Aviation
    licencesList: [],
    aviationhours: '',
    aviationexpecthours: '',
    aircraftList: [],
    recreational: '',

    // Scuba Diving
    qualificationsList: [],
    divingAreas: [],
    divingItems: [],
    divingDepth: '',
    divingFrequency: '',

    // Motor Racing
    limitedToTrack: '',
    vehicleTypes: [],
    competedEvents: '',
    memberDetailsOFRacingClub: '',
    camsClassification: '',
    durationOfMotorSport: '',
    professionalism: '',
    engagementOfDemo: '',
    relevantDetails: '',

    //  abseiling

    abseilingTimeLastYear: '',
    abseilingQualifications: '',
    abseilingHowLong: '',
    abseilingLocations: '',
    abseilingEngage: '',

    // martial Arts
    martialArtTypesParticipate: [],
    otherMartialArtTypeDetails: '',
    martialArtTypesPractice: '',
    injuries: '',
    competitiveMartial: '',

    // Sailing
    sailingTypesParticipate: [],
    raceSeries: '',
    payments: '',
    recordAttempts: '',
    safetyMeasures: '',
    accident: '',
    boatSize: '',

    hangGliding1: '',
    hangGliding2: '',
    hangGliding3: '',
    hangGliding4: '',
    hangGliding5: '',
    hangGliding6: '',
    hangGliding7: '',
    hangGliding8: '',
    hangGliding9: '',

    powerboatRacing1: '',
    powerboatRacing2: '',
    powerboatRacing3: '',
    powerboatRacing4: '',
    powerboatRacing5: '',
    powerboatRacing6: '',
    powerboatRacing7: '',
    powerboatRacing8: '',
    powerboatRacing9: '',
    powerboatRacing10: '',

    mountaineering1: '',
    mountaineering2: '',
    mountaineering3: '',
    mountaineering4: '',
    mountaineering5: '',
    mountaineering6: '',
    mountaineering7: '',

    // Parachuting
    parachuting1: '',
    parachuting2: '',
    parachuting3: '',
    parachuting4: '',
    parachuting5: '',
    parachuting6: '',
    parachuting7: '',
    parachuting8: '',
    parachuting9: '',

    // football
    football1: '',
    football2: '',
    football3: '',
    football4: '',
    football5: '',

    // noneOfTheAbove
    noneOfTheAbove: false,
  });
  const validationSchema = Yup.object().shape({
    covid19: Yup.string().required(),

    covid19Result: Yup.string().when('covid19', {
      is: 'true',
      then: Yup.string().required(),
    }),
    
    covidList: Yup.array(Yup.string()).when('covid19', {
      is: 'true',
      then: Yup.array(Yup.string()),
    }),

    licencesList: Yup.array(Yup.string()).when(
      'recreational',
      (recreational) => {
        if (recreational) return Yup.array(Yup.string());
      }
    ),

    aircraftList: Yup.array(Yup.string()).when(
      'licencesList',
      (licencesList) => {
        if (licencesList.length !== 0) return Yup.array(Yup.string());
      }
    ),

    aviationhours: Yup.string().when('aircraftList', (aircraftList) => {
      if (aircraftList.length !== 0)
        return Yup.string().required('This field is required');
    }),

    aviationexpecthours: Yup.string().when('aircraftList', (aircraftList) => {
      if (aircraftList.length !== 0)
        return Yup.string().required('This field is required');
    }),

    // Motor Racing Validation

    competedEvents: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    memberDetailsOFRacingClub: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    camsClassification: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    durationOfMotorSport: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    professionalism: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    engagementOfDemo: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('motorRacing') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    relevantDetails: Yup.string().when(
      ['activities', 'limitedToTrack'],
      (activities, limitedToTrack) => {
        if (activities.includes('martialArts') && limitedToTrack === 'false') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    // End of Motor Racing Validation ---------------------------------

    // Martial Art Validation

    martialArtTypesPractice: Yup.string().when(['activities'], (e) => {
      if (e.includes('martialArts')) {
        return Yup.string().required();
      }
    }),

    otherMartialArtTypeDetails: Yup.string().when(
      ['activities', 'martialArtTypesParticipate'],
      (activities, martialArtTypesParticipate) => {
        if (
          activities.includes('martialArts') &&
          martialArtTypesParticipate.includes('other')
        ) {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    injuries: Yup.string().when(
      ['activities', 'martialArtTypesParticipate'],
      (activities, martialArtTypesPractice) => {
        if (
          activities.includes('martialArts') &&
          martialArtTypesPractice !== ''
        ) {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    competitiveMartial: Yup.string().when(
      ['activities', 'martialArtTypesParticipate'],
      (activities, martialArtTypesPractice) => {
        if (
          activities.includes('martialArts') &&
          martialArtTypesPractice !== ''
        ) {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    // End of Martial Art Validation ---------------------------------

    // Sailing Validation------------------------

    raceSeries: Yup.string().when(
      ['activities', 'sailingTypesParticipate'],
      (activities, sailingTypesParticipate) => {
        if (
          activities.includes('martialArts') &&
          (sailingTypesParticipate.includes('Racing (offshore)') ||
            sailingTypesParticipate.includes('Racing (trans-ocean)'))
        ) {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    payments: Yup.string().when(['activities'], (e) => {
      if (e.includes('sailing')) {
        return Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required');
      }
    }),

    recordAttempts: Yup.string().when(['activities'], (e) => {
      if (e.includes('sailing')) {
        return Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required');
      }
    }),

    safetyMeasures: Yup.string().when(['activities'], (e) => {
      if (e.includes('sailing')) {
        return Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required');
      }
    }),

    boatSize: Yup.string().when(['activities'], (e) => {
      if (e.includes('sailing')) {
        return Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required');
      }
    }),

    accident: Yup.string().when(['activities'], (e) => {
      if (e.includes('sailing')) {
        return Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required');
      }
    }),

    // End of Sailing Validation ---------------------------------

    divingDepth: Yup.string().when('activities', (e) => {
      if (e.includes('scubaDiving'))
        return Yup.string().required('This field is required ');
    }),

    divingFrequency: Yup.string().when('activities', (e) => {
      if (e.includes('scubaDiving'))
        return Yup.string().required('This field is required ');
    }),

    recreational: Yup.string().when('activities', (e) => {
      if (e.includes('aviation'))
        return Yup.string().required('This field is required ');
    }),

    abseilingTimeLastYear: Yup.string().when('activities', (e) => {
      if (e.includes('abseiling'))
        return Yup.string().required('This field is required ');
    }),

    abseilingQualifications: Yup.string().when(
      ['activities', 'abseilingTimeLastYear'],
      (activities, abseilingTimeLastYear) => {
        if (activities.includes('abseiling') && abseilingTimeLastYear !== '') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    abseilingHowLong: Yup.string().when(
      ['activities', 'abseilingTimeLastYear'],
      (activities, abseilingTimeLastYear) => {
        if (activities.includes('abseiling') && abseilingTimeLastYear !== '') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    abseilingLocations: Yup.string().when(
      ['activities', 'abseilingTimeLastYear'],
      (activities, abseilingTimeLastYear) => {
        if (activities.includes('abseiling') && abseilingTimeLastYear !== '') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    abseilingHeight: Yup.string().when(
      ['activities', 'abseilingTimeLastYear'],
      (activities, abseilingTimeLastYear) => {
        if (activities.includes('abseiling') && abseilingTimeLastYear !== '') {
          return Yup.string()
            .max(300, 'Maximum length is 300')
            .required('This field is required');
        }
      }
    ),

    football1: Yup.string().when('activities', (e) => {
      if (e.includes('football'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    football2: Yup.string().when('activities', (e) => {
      if (e.includes('football'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    football3: Yup.string().when('activities', (e) => {
      if (e.includes('football'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    football4: Yup.string().when('activities', (e) => {
      if (e.includes('football'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    football5: Yup.string().when('activities', (e) => {
      if (e.includes('football'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding1: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding2: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding3: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding4: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding5: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding6: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding7: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding8: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    hangGliding9: Yup.string().when('activities', (e) => {
      if (e.includes('hangGliding'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting1: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting2: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting3: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting4: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting5: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting6: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting7: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting8: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    parachuting9: Yup.string().when('activities', (e) => {
      if (e.includes('parachuting'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing1: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing2: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing3: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing4: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing5: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing6: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing7: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing8: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    powerboatRacing9: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),
    
    powerboatRacing10: Yup.string().when('activities', (e) => {
      if (e.includes('powerboatRacing'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering1: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering2: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering3: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering4: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering5: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering6: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    mountaineering7: Yup.string().when('activities', (e) => {
      if (e.includes('mountaineering'))
        return Yup.string()
          .required('This field is required')
          .max(300, 'Maximum length is 300');
    }),

    noneOfTheAbove: Yup.string().when('activities', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });

  const dispatch = useDispatch();

  const submit = async (e) => {
    await axios.put('/form/formtwo', {
      formTwo: e,
      pointer: 4,
      formid: id,
    });
    dispatch(setPage(4));
  };

  const resetFoldYesNo = (e, val, setFieldValue, setFieldTouched, arr) => {
    if (e.target.value === val) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    }
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
          }) => {
            return (
              <Form>
                <Card style={{ padding: '2rem' }}>
                  <CardContent>
                    <YesNoQuestion
                      question='Have you been Covid-19 tested?'
                      name='covid19'
                      onClick={(e) => {
                        resetFoldYesNo(
                          e,
                          'false',
                          setFieldValue,
                          setFieldTouched,
                          resetArray
                        );
                        // setFieldValue('noneOfTheAbove', '', false);
                        // setFieldTouched('noneOfTheAbove', false);
                      }}
                    />

                    {values.covid19 === 'true' && (
                      <YesNoQuestion
                        question='What was the result of the test?'
                        name='covid19Result'
                        labels={{
                          yes: 'Positive',
                          no: 'Negative',
                        }}
                        onClick={(e) =>
                          resetFoldYesNo(
                            e,
                            'false',
                            setFieldValue,
                            setFieldTouched,
                            resetArrayNegative
                          )
                        }
                        isSubForm
                      />
                    )}

                    {values.covid19 === 'true' &&
                      values.covid19Result === 'true' && (
                        <CheckBox
                          question='Why did you have the test?'
                          list={[
                            {
                              label: 'Due to Covid Symptoms',
                              value: 'covidSymptoms',
                            },
                            { label: 'Precautionary', value: 'precautionary' },
                            {
                              label: 'Returned from Hospital',
                              value: 'hospital',
                            },
                            {
                              label: 'Required to return into the state',
                              value: 'required',
                            },
                          ]}
                          name='covidList'
                          isSubForm
                        />
                      )}

                    <p className={styles.question}>
                      Do you engage in any of the following activities?
                    </p>

                    <Abseiling
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Aviation
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Football
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Sailing
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <HangGliding
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <ScubaDiving
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <MotorRacing
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Parachuting
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Powerboat
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <Mountaineering
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <MartialArts
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />

                  </CardContent>
                  <CardActions>
                    <Button
                      variant='contained'
                      className={classes.btn}
                      type='submit'
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: 'Overpass, sans-serif',
                        fontSize: '1rem',
                      }}
                    >
                      next
                    </Button>

                    {/* <p>
                      dirty:{JSON.stringify(dirty)}, isValid:
                      {JSON.stringify(isValid)}
                      error: {JSON.stringify(errors)},
                      NoneOfThe Above:{JSON.stringify(values.noneOfTheAbove)}
                    </p> */}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
