import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubFormOne from "./subFormsOfFormFifteen/SubFormOne";
import SubFormTwo from "./subFormsOfFormFifteen/SubFormTwo";
import SubFormThree from "./subFormsOfFormFifteen/SubFormThree";
import SubFormFour from "./subFormsOfFormFifteen/SubFormFour";
import SubFormFive from "./subFormsOfFormFifteen/SubFormFive";
import SubFormSix from "./subFormsOfFormFifteen/SubFormSix";
import SubFormSeven from "./subFormsOfFormFifteen/SubFormSeven";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormFifteen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    subFormsOfFormFifteen: [],

    //form one

    whichBodyArea: [],
    psoriasisBodyAreaDetails: "",
    psoriasisAnyJointPain: "",
    psoriasisTreatmentLimited: "",
    psoriasisHadAnyConsecutiveDays: "",

    //form two

    dermatitisAnyRestricted: "",
    dermatitisRestrictedYesDetails: "",
    dermatitisConditionTriggered: "",
    dermatitisConditionTriggeredYesDetails: "",

    //form Three
    moleOrSunSpotsHowSkinGrowthTreated: [],
    moleOrSunSpotsWhenWasItRemoved: "",
    moleOrSunSpotsCreamOintmentDetails: "",
    moleOrSunSpotsWhenSkinGrowthFirst: "",
    moleOrSunSpotsConfirmedAsBenign: "",
    moleOrSunSpotsSCC: "",
    moleOrSunSpotsTreatmentRecommendedOrRequired: "",
    moleOrSunSpotsTreatmentRecommendedOrRequiredDetails: "",
    moleOrSunSpotsNeedOtherTreatmentForSkinGrowth: "",
    moleOrSunSpotsNeedOtherTreatmentForSkinGrowthDetails: "",

    //form four
    otherSkinDisorderSelectedDisorder: [],
    otherSkinDisorderSelectedOtherDisorderDetails: "",
    otherSkinDisorderWhatDisorder: [],
    otherSkinDisorderHowSkinGrowthTreated: [],
    otherSkinDisorderWhenWasItRemoved: "",
    otherSkinDisorderCreamOintmentDetails: "",
    otherSkinDisorderWhenSkinGrowthFirst: "",
    otherSkinDisorderConfirmedAsBenign: "",
    otherSkinDisorderSCC: "",
    otherSkinDisorderTreatmentRecommendedOrRequired: "",
    otherSkinDisorderTreatmentRecommendedOrRequiredDetails: "",
    otherSkinDisorderNeedOtherTreatmentForSkinGrowth: "",
    otherSkinDisorderNeedOtherTreatmentForSkinGrowthDetails: "",

    //form five
    causeOfHearing: [],
    causeOfHearingDetails: "",
    whichSideOFEars: "",
    defectInHearingAnyRestricted: "",
    defectInHearingAnyRestrictedDetails: "",
    isYourHaringDeteriorating: "",

    //form six

    yourCondition: [],
    cataractSymptomsBeenIdentified: [],
    glaucomaSymptomsBeenIdentified: "",
    bornWithASightDefect: "",
    detailsForSyncomsBeenIdentified: "",
    visionDeterioratedOverThePast2Years: "",
    defectInSighRestricted: "",
    defectInSighAnySurgicalTreatment: "",
    detailsForSurgicalTreatment: "",
    detailsForRestricted: "",
    isVisionNormalWithOutGlasses: "",
    whichEyeISDeflected: "",

    //form seven

    comprehensiveDetailsAboutSpeech: "",
    anyRestricted: "",
    DetailsAboutRestricted: "",

    noneOfTheAbove: false,
  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };

  const validationSchema = Yup.object().shape({
    // form one

    psoriasisBodyAreaDetails: Yup.string().when(
      ["subFormsOfFormFifteen", "whichBodyArea"],
      (subFormsOfFormFifteen, whichBodyArea) => {
        if (
          subFormsOfFormFifteen.includes("psoriasis") &&
          whichBodyArea.includes("other")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    psoriasisAnyJointPain: Yup.string().when(
      ["subFormsOfFormFifteen", "whichBodyArea", "psoriasisBodyAreaDetails"],
      (
        subFormsOfFormFifteen,

        whichBodyArea,
        psoriasisBodyAreaDetails
      ) => {
        if (
          (subFormsOfFormFifteen.includes("psoriasis") &&
            whichBodyArea.includes("scalp")) ||
          whichBodyArea.includes("elbowJoint") ||
          whichBodyArea.includes("kneeJoints") ||
          psoriasisBodyAreaDetails
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    psoriasisTreatmentLimited: Yup.string().when(
      ["subFormsOfFormFifteen", "whichBodyArea", "psoriasisAnyJointPain"],
      (
        subFormsOfFormFifteen,

        whichBodyArea,
        psoriasisAnyJointPain
      ) => {
        if (
          (subFormsOfFormFifteen.includes("psoriasis") &&
            whichBodyArea.includes("scalp")) ||
          whichBodyArea.includes("elbowJoint") ||
          (whichBodyArea.includes("kneeJoints") && psoriasisAnyJointPain !== "")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    psoriasisHadAnyConsecutiveDays: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "whichBodyArea",
        "psoriasisAnyJointPain",
        "psoriasisTreatmentLimited",
      ],
      (
        subFormsOfFormFifteen,

        whichBodyArea,
        psoriasisAnyJointPain,
        psoriasisTreatmentLimited
      ) => {
        if (
          (subFormsOfFormFifteen.includes("psoriasis") &&
            whichBodyArea.includes("scalp")) ||
          whichBodyArea.includes("elbowJoint") ||
          (whichBodyArea.includes("kneeJoints") &&
            psoriasisAnyJointPain !== "" &&
            psoriasisTreatmentLimited !== "")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //form two

    dermatitisAnyRestricted: Yup.string().when(
      ["subFormsOfFormFifteen"],
      (subFormsOfFormFifteen) => {
        if (subFormsOfFormFifteen.includes("dermatitis")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    dermatitisRestrictedYesDetails: Yup.string().when(
      ["subFormsOfFormFifteen", "dermatitisAnyRestricted"],
      (subFormsOfFormFifteen, dermatitisAnyRestricted) => {
        if (
          subFormsOfFormFifteen.includes("dermatitis") &&
          dermatitisAnyRestricted === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    dermatitisConditionTriggered: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "dermatitisAnyRestricted",
        "dermatitisRestrictedYesDetails",
      ],
      (
        subFormsOfFormFifteen,

        dermatitisAnyRestricted,
        dermatitisRestrictedYesDetails
      ) => {
        if (
          (subFormsOfFormFifteen.includes("dermatitis") &&
            dermatitisAnyRestricted === "no") ||
          dermatitisRestrictedYesDetails
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    dermatitisConditionTriggeredYesDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "dermatitisAnyRestricted",
        "dermatitisConditionTriggered",
      ],
      (
        subFormsOfFormFifteen,

        dermatitisAnyRestricted,
        dermatitisConditionTriggered
      ) => {
        if (
          (subFormsOfFormFifteen.includes("dermatitis") &&
            dermatitisAnyRestricted === "no") ||
          dermatitisConditionTriggered === "yes"
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    //form Three

    moleOrSunSpotsHowSkinGrowthTreated: Yup.string().when(
      ["subFormsOfFormFifteen"],
      (subFormsOfFormFifteen) => {
        if (subFormsOfFormFifteen.includes("moleOrSunSpots")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsWhenWasItRemoved: Yup.string().when(
      ["subFormsOfFormFifteen", "moleOrSunSpotsHowSkinGrowthTreated"],
      (subFormsOfFormFifteen, moleOrSunSpotsHowSkinGrowthTreated) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          (moleOrSunSpotsHowSkinGrowthTreated.includes("excised") ||
            moleOrSunSpotsHowSkinGrowthTreated.includes("cryotherapy"))
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsConfirmedAsBenign: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "moleOrSunSpotsHowSkinGrowthTreated",
        "moleOrSunSpotsWhenWasItRemoved",
      ],
      (
        subFormsOfFormFifteen,
        moleOrSunSpotsHowSkinGrowthTreated,
        moleOrSunSpotsWhenWasItRemoved
      ) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          (moleOrSunSpotsHowSkinGrowthTreated.includes("excised") ||
            moleOrSunSpotsHowSkinGrowthTreated.includes("cryotherapy")) &&
          moleOrSunSpotsWhenWasItRemoved !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsCreamOintmentDetails: Yup.string().when(
      ["subFormsOfFormFifteen", "moleOrSunSpotsHowSkinGrowthTreated"],
      (subFormsOfFormFifteen, moleOrSunSpotsHowSkinGrowthTreated) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          moleOrSunSpotsHowSkinGrowthTreated.includes("creamOrOintment")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    moleOrSunSpotsWhenSkinGrowthFirst: Yup.string().when(
      ["subFormsOfFormFifteen", "moleOrSunSpotsHowSkinGrowthTreated"],
      (subFormsOfFormFifteen, moleOrSunSpotsHowSkinGrowthTreated) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          moleOrSunSpotsHowSkinGrowthTreated.includes("notRemoved")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsNeedOtherTreatmentForSkinGrowth: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "moleOrSunSpotsHowSkinGrowthTreated",
        "moleOrSunSpotsWhenSkinGrowthFirst",
      ],
      (
        subFormsOfFormFifteen,
        moleOrSunSpotsHowSkinGrowthTreated,
        moleOrSunSpotsWhenSkinGrowthFirst
      ) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          moleOrSunSpotsHowSkinGrowthTreated.includes("notRemoved") &&
          moleOrSunSpotsWhenSkinGrowthFirst !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsNeedOtherTreatmentForSkinGrowthDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "moleOrSunSpotsHowSkinGrowthTreated",
        "moleOrSunSpotsWhenSkinGrowthFirst",
        "moleOrSunSpotsNeedOtherTreatmentForSkinGrowth",
      ],
      (
        subFormsOfFormFifteen,
        moleOrSunSpotsHowSkinGrowthTreated,
        moleOrSunSpotsWhenSkinGrowthFirst,
        moleOrSunSpotsNeedOtherTreatmentForSkinGrowth
      ) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          moleOrSunSpotsHowSkinGrowthTreated.includes("notRemoved") &&
          moleOrSunSpotsWhenSkinGrowthFirst !== "" &&
          moleOrSunSpotsNeedOtherTreatmentForSkinGrowth === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    moleOrSunSpotsSCC: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "moleOrSunSpotsHowSkinGrowthTreated",
        "moleOrSunSpotsWhenWasItRemoved",
        "moleOrSunSpotsConfirmedAsBenign",
      ],
      (
        subFormsOfFormFifteen,
        moleOrSunSpotsHowSkinGrowthTreated,
        moleOrSunSpotsWhenWasItRemoved,
        moleOrSunSpotsConfirmedAsBenign
      ) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          (moleOrSunSpotsHowSkinGrowthTreated.includes("excised") ||
            moleOrSunSpotsHowSkinGrowthTreated.includes("cryotherapy")) &&
          moleOrSunSpotsWhenWasItRemoved !== "" &&
          moleOrSunSpotsConfirmedAsBenign === "no"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsTreatmentRecommendedOrRequired: Yup.string().when(
      ["subFormsOfFormFifteen", "moleOrSunSpotsWhenWasItRemoved"],
      (subFormsOfFormFifteen, moleOrSunSpotsWhenWasItRemoved) => {
        if (
          subFormsOfFormFifteen.includes("moleOrSunSpots") &&
          moleOrSunSpotsWhenWasItRemoved !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    moleOrSunSpotsTreatmentRecommendedOrRequiredDetails: Yup.string().when(
      ["moleOrSunSpotsTreatmentRecommendedOrRequired"],
      (moleOrSunSpotsTreatmentRecommendedOrRequired) => {
        if (moleOrSunSpotsTreatmentRecommendedOrRequired === "yes") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    //form four

    otherSkinDisorderSelectedOtherDisorderDetails: Yup.string().when(
      ["subFormsOfFormFifteen", "otherSkinDisorderSelectedDisorder"],
      (
        subFormsOfFormFifteen,

        otherSkinDisorderSelectedDisorder
      ) => {
        if (
          subFormsOfFormFifteen.includes("otherSkinDisorder") &&
          otherSkinDisorderSelectedDisorder.includes("other")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    otherSkinDisorderWhenWasItRemoved: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("excised") &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderCreamOintmentDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          subFormsOfFormFifteen.includes("otherSkinDisorder") &&
          otherSkinDisorderHowSkinGrowthTreated.includes("creamOrOintment") &&
          (otherSkinDisorderSelectedDisorder.includes("BCC") ||
            otherSkinDisorderSelectedDisorder.includes("SCC")) &&
          (otherSkinDisorderWhatDisorder.includes("skinCancer") ||
            otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin"))
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    otherSkinDisorderWhenSkinGrowthFirst: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          subFormsOfFormFifteen.includes("otherSkinDisorder") &&
          otherSkinDisorderHowSkinGrowthTreated.includes("notRemoved") &&
          (otherSkinDisorderSelectedDisorder.includes("BCC") ||
            otherSkinDisorderSelectedDisorder.includes("SCC")) &&
          (otherSkinDisorderWhatDisorder.includes("skinCancer") ||
            otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin"))
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderNeedOtherTreatmentForSkinGrowth: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenSkinGrowthFirst",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenSkinGrowthFirst,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("notRemoved") &&
            otherSkinDisorderWhenSkinGrowthFirst !== "" &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderNeedOtherTreatmentForSkinGrowthDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenSkinGrowthFirst",
        "otherSkinDisorderNeedOtherTreatmentForSkinGrowth",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenSkinGrowthFirst,
        otherSkinDisorderNeedOtherTreatmentForSkinGrowth,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("notRemoved") &&
            otherSkinDisorderWhenSkinGrowthFirst !== "" &&
            otherSkinDisorderNeedOtherTreatmentForSkinGrowth === "yes" &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    otherSkinDisorderConfirmedAsBenign: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenWasItRemoved",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenWasItRemoved,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("excised") &&
            otherSkinDisorderWhenWasItRemoved !== "" &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderSCC: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenWasItRemoved",
        "otherSkinDisorderConfirmedAsBenign",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenWasItRemoved,
        otherSkinDisorderConfirmedAsBenign,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("excised") &&
            otherSkinDisorderWhenWasItRemoved !== "" &&
            otherSkinDisorderConfirmedAsBenign === "no" &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderTreatmentRecommendedOrRequired: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenWasItRemoved",
        "otherSkinDisorderConfirmedAsBenign",
        "otherSkinDisorderSCC",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenWasItRemoved,
        otherSkinDisorderConfirmedAsBenign,
        otherSkinDisorderSCC,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("excised") &&
            otherSkinDisorderWhenWasItRemoved !== "" &&
            otherSkinDisorderConfirmedAsBenign === "yes") ||
          (otherSkinDisorderSCC &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    otherSkinDisorderTreatmentRecommendedOrRequiredDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "otherSkinDisorderHowSkinGrowthTreated",
        "otherSkinDisorderWhenWasItRemoved",
        "otherSkinDisorderConfirmedAsBenign",
        "otherSkinDisorderSCC",
        "otherSkinDisorderTreatmentRecommendedOrRequired",
        "otherSkinDisorderSelectedDisorder",
        "otherSkinDisorderWhatDisorder",
      ],
      (
        subFormsOfFormFifteen,
        otherSkinDisorderHowSkinGrowthTreated,
        otherSkinDisorderWhenWasItRemoved,
        otherSkinDisorderConfirmedAsBenign,
        otherSkinDisorderSCC,
        otherSkinDisorderTreatmentRecommendedOrRequired,
        otherSkinDisorderSelectedDisorder,
        otherSkinDisorderWhatDisorder
      ) => {
        if (
          (subFormsOfFormFifteen.includes("otherSkinDisorder") &&
            otherSkinDisorderHowSkinGrowthTreated.includes("excised") &&
            otherSkinDisorderWhenWasItRemoved !== "" &&
            otherSkinDisorderConfirmedAsBenign === "yes") ||
          (otherSkinDisorderSCC !== "" &&
            otherSkinDisorderTreatmentRecommendedOrRequired === "yes" &&
            otherSkinDisorderSelectedDisorder.includes("BCC")) ||
          (otherSkinDisorderSelectedDisorder.includes("SCC") &&
            otherSkinDisorderWhatDisorder.includes("skinCancer")) ||
          otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    //form five

    causeOfHearingDetails: Yup.string().when(
      ["subFormsOfFormFifteen", "causeOfHearing"],
      (subFormsOfFormFifteen, causeOfHearing) => {
        if (
          (subFormsOfFormFifteen.includes("defectInHearing") &&
            causeOfHearing.includes("tumour")) ||
          causeOfHearing.includes("other")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    whichSideOFEars: Yup.string().when(
      ["subFormsOfFormFifteen", "causeOfHearing", "causeOfHearingDetails"],
      (
        subFormsOfFormFifteen,

        causeOfHearing,
        causeOfHearingDetails
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInHearing") &&
            causeOfHearing.includes("birthDefect")) ||
          causeOfHearing.includes("industrial") ||
          causeOfHearing.includes("age") ||
          causeOfHearingDetails
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    defectInHearingAnyRestricted: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "causeOfHearing",
        "causeOfHearingDetails",
        "whichSideOFEars",
      ],
      (
        subFormsOfFormFifteen,

        causeOfHearing,
        causeOfHearingDetails,
        whichSideOFEars
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInHearing") &&
            causeOfHearing.includes("birthDefect")) ||
          causeOfHearing.includes("industrial") ||
          causeOfHearing.includes("age") ||
          (causeOfHearingDetails && whichSideOFEars !== "")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    defectInHearingAnyRestrictedDetails: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "causeOfHearing",
        "causeOfHearingDetails",
        "whichSideOFEars",
        "defectInHearingAnyRestricted",
      ],
      (
        subFormsOfFormFifteen,

        causeOfHearing,
        causeOfHearingDetails,
        whichSideOFEars,
        defectInHearingAnyRestricted
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInHearing") &&
            causeOfHearing.includes("birthDefect")) ||
          causeOfHearing.includes("industrial") ||
          causeOfHearing.includes("age") ||
          (causeOfHearingDetails &&
            whichSideOFEars !== "" &&
            defectInHearingAnyRestricted === "yes")
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    isYourHaringDeteriorating: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "causeOfHearing",
        "causeOfHearingDetails",
        "whichSideOFEars",
        "defectInHearingAnyRestricted",
        "defectInHearingAnyRestrictedDetails",
      ],
      (
        subFormsOfFormFifteen,
        causeOfHearing,
        causeOfHearingDetails,
        whichSideOFEars,
        defectInHearingAnyRestricted,
        defectInHearingAnyRestrictedDetails
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInHearing") &&
            causeOfHearing.includes("birthDefect")) ||
          causeOfHearing.includes("industrial") ||
          causeOfHearing.includes("age") ||
          (causeOfHearingDetails &&
            whichSideOFEars !== "" &&
            defectInHearingAnyRestricted === "yes" &&
            defectInHearingAnyRestrictedDetails)
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //form Six

    detailsForSyncomsBeenIdentified: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "yourCondition",
        "cataractSymptomsBeenIdentified",
      ],
      (
        subFormsOfFormFifteen,
        yourCondition,
        cataractSymptomsBeenIdentified
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInSight") &&
            yourCondition.includes("cataract") &&
            cataractSymptomsBeenIdentified.includes("anotherMedicalIssue")) ||
          cataractSymptomsBeenIdentified.includes("SideEffectOfMedication") ||
          cataractSymptomsBeenIdentified.includes("TraumaInjuryOrAccident")
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    defectInSighAnySurgicalTreatment: Yup.string().when(
      [
        "subFormsOfFormFifteen",
        "yourCondition",
        "detailsForSyncomsBeenIdentified",
        "cataractSymptomsBeenIdentified",
      ],
      (
        subFormsOfFormFifteen,
        yourCondition,
        detailsForSyncomsBeenIdentified,
        cataractSymptomsBeenIdentified
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInSight") &&
            yourCondition.includes("cataract") &&
            cataractSymptomsBeenIdentified.includes(
              "noSpecificCauseIdentified"
            )) ||
          detailsForSyncomsBeenIdentified
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    glaucomaSymptomsBeenIdentified: Yup.string().when(
      ["subFormsOfFormFifteen", "yourCondition"],
      (subFormsOfFormFifteen, yourCondition) => {
        if (
          subFormsOfFormFifteen.includes("defectInSight") &&
          yourCondition.includes("glaucoma")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    bornWithASightDefect: Yup.string().when(
      ["subFormsOfFormFifteen", "yourCondition"],
      (subFormsOfFormFifteen, yourCondition) => {
        if (
          subFormsOfFormFifteen.includes("defectInSight") &&
          yourCondition.includes("other")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    defectInSighRestricted: Yup.string().when(
      ["subFormsOfFormFifteen", "yourCondition", "bornWithASightDefect"],
      (
        subFormsOfFormFifteen,

        yourCondition,
        bornWithASightDefect
      ) => {
        if (
          subFormsOfFormFifteen.includes("defectInSight") &&
          yourCondition.includes("other") &&
          bornWithASightDefect !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    detailsForRestricted: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "yourCondition",
        "bornWithASightDefect",
        "defectInSighRestricted",
      ],
      (
        subFormsOfFormFifteen,

        yourCondition,
        bornWithASightDefect,
        defectInSighRestricted
      ) => {
        if (
          subFormsOfFormFifteen.includes("defectInSight") &&
          yourCondition.includes("other") &&
          bornWithASightDefect !== "" &&
          defectInSighRestricted === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    whichEyeISDeflected: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "yourCondition",
        "bornWithASightDefect",
        "defectInSighRestricted",
        "detailsForRestricted",
      ],
      (
        subFormsOfFormFifteen,

        yourCondition,
        bornWithASightDefect,
        defectInSighRestricted,
        detailsForRestricted
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInSight") &&
            yourCondition.includes("other") &&
            bornWithASightDefect !== "" &&
            defectInSighRestricted === "no") ||
          detailsForRestricted
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    visionDeterioratedOverThePast2Years: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "yourCondition",
        "glaucomaSymptomsBeenIdentified",
      ],
      (
        subFormsOfFormFifteen,

        yourCondition,
        glaucomaSymptomsBeenIdentified
      ) => {
        if (
          subFormsOfFormFifteen.includes("defectInSight") &&
          yourCondition.includes("glaucoma") &&
          glaucomaSymptomsBeenIdentified !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    detailsForSurgicalTreatment: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "yourCondition",
        "detailsForSyncomsBeenIdentified",
        "defectInSighAnySurgicalTreatment",
        "cataractSymptomsBeenIdentified",
      ],
      (
        subFormsOfFormFifteen,

        yourCondition,
        detailsForSyncomsBeenIdentified,
        defectInSighAnySurgicalTreatment,
        cataractSymptomsBeenIdentified
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInSight") &&
            yourCondition.includes("cataract") &&
            cataractSymptomsBeenIdentified.includes(
              "noSpecificCauseIdentified"
            )) ||
          (detailsForSyncomsBeenIdentified &&
            defectInSighAnySurgicalTreatment === "yes")
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    isVisionNormalWithOutGlasses: Yup.string().when(
      [
        "subFormsOfFormFifteen",

        "yourCondition",
        "detailsForSyncomsBeenIdentified",
        "defectInSighAnySurgicalTreatment",
        "detailsForSurgicalTreatment",
        "cataractSymptomsBeenIdentified",
      ],
      (
        subFormsOfFormFifteen,

        yourCondition,
        detailsForSyncomsBeenIdentified,
        defectInSighAnySurgicalTreatment,
        detailsForSurgicalTreatment,
        cataractSymptomsBeenIdentified
      ) => {
        if (
          (subFormsOfFormFifteen.includes("defectInSight") &&
            yourCondition.includes("cataract") &&
            cataractSymptomsBeenIdentified.includes(
              "noSpecificCauseIdentified"
            )) ||
          (detailsForSyncomsBeenIdentified &&
            (defectInSighAnySurgicalTreatment === "no" ||
              detailsForSurgicalTreatment))
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    //form seven

    comprehensiveDetailsAboutSpeech: Yup.string().when(
      ["subFormsOfFormFifteen"],
      (subFormsOfFormFifteen) => {
        if (subFormsOfFormFifteen.includes("defectInSpeech")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    anyRestricted: Yup.string().when(
      ["subFormsOfFormFifteen"],
      (subFormsOfFormFifteen) => {
        if (subFormsOfFormFifteen.includes("defectInSpeech")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    DetailsAboutRestricted: Yup.string().when(
      ["subFormsOfFormFifteen", "anyRestricted"],
      (subFormsOfFormFifteen, anyRestricted) => {
        if (
          subFormsOfFormFifteen.includes("defectInSpeech") &&
          anyRestricted === "yes"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    noneOfTheAbove: Yup.string().when("subFormsOfFormFifteen", (e) => {
      if (e.length === 0) {
        return Yup.boolean()
          .required("This field is required")
          .oneOf([true], "This field is required");
      }
    }),
  });
  // FORM [2,2]

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formfifteen", {
        formFiffteen: e,
        pointer: 16,
        formid: id,
      });
      dispatch(setPage(16));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
          }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following?
                    </p>

                    <SubFormOne
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormTwo
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormThree
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFour
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFive
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSix
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSeven
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
