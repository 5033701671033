import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./classes";

import Grid from "@material-ui/core/Grid";

import Img from "../../assets/images/image1.png";
import Img2 from "../../assets/images/res.png";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const Resourcespage = () => {
  const classes = useStyles();
  const history = useHistory();

  const videoDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}privacy-policy/#privacy`;
  };
  const termDirect = () => {
    history.push("/terms");
  };
  const conversationDirect = () => {
    history.push("/conversation");
  };
  const emailTemplateDirect = () => {
    history.push("/emailTemplate");
  };
  function FormRow1() {
    return (
      <React.Fragment>
        <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
          <img src={Img2} alt="Adviser adds client details" />
          <Typography className={classes.text}>
            Please see below resources you can use to send to your client to
            prepare them for the Preassessment Questionnaire
          </Typography>
          <Box className={classes.paragraph}>
            <FiberManualRecordIcon />
            <a
              href={() => false}
              onClick={emailTemplateDirect}
              className={classes.text1}
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              Preparation Email template for client
            </a>
          </Box>
          <Box className={classes.paragraph}>
            <FiberManualRecordIcon />
            <a
              href={() => false}
              onClick={conversationDirect}
              className={classes.text1}
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              Positioning script of conversation with client
            </a>
          </Box>
          <Box className={classes.paragraph}>
            <FiberManualRecordIcon />
            <a
              href={() => false}
              onClick={termDirect}
              className={classes.text1}
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              Positioning script for non-standard terms
            </a>
          </Box>
          <Box className={classes.paragraph}>
            <FiberManualRecordIcon />
            <a
              href={() => false}
              onClick={videoDirect}
              className={classes.text1}
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              Privacy Policy
            </a>
          </Box>
          <Box className={classes.paragraph}>
            <FiberManualRecordIcon />
            <Typography className={classes.text1}>
              Data Storage Policy{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <>
      <Grid container>
        <Grid container item xs={12} className={classes.grid}>
          <FormRow1 />
        </Grid>
      </Grid>
    </>
  );
};

export default Resourcespage;
