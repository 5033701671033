import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import "./FormView.css";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import UnderwriterModal from "../../components/modals/UnderwriterModal";

// Form view tab components
import GeneralTab from "../../components/formViewTabs/GeneralTab";
import ActivitiesTab from "../../components/formViewTabs/ActivitiesTab";
import FamilyHistoryTab from "../../components/formViewTabs/FamilyHistoryTab";
import GeneralHealthTab from "../../components/formViewTabs/GeneralHealthTab";
import NeurologicalTab from "../../components/formViewTabs/NeurologicalTab";
import MentalIllnessTab from "../../components/formViewTabs/MentalIllnessTab";
import MusculoskeletalTab from "../../components/formViewTabs/MusculoskeletalTab";
import SkinTab from "../../components/formViewTabs/SkinTab";
import MiscTab from "../../components/formViewTabs/MiscTab";
import CancerTab from "../../components/formViewTabs/CancerTab";
import OrgansTab from "../../components/formViewTabs/OrgansTab";
import BloodTab from "../../components/formViewTabs/BloodTab";
import VirusesTab from "../../components/formViewTabs/VirusesTab";
import WomensHealthTab from "../../components/formViewTabs/WomensHealthTab";
import EmploymentTab from "../../components/formViewTabs/EmploymentTab";
import Footer from "../../components/Footer/Footer";
import MobileViewport from "../mobileViewport/MobileViewport";

const FormView = () => {
  let { assessmentID } = useParams();
  const [formData, setFormData] = useState({});
  const [clientData, setClientData] = useState({});
  const [clientBMI, setClientBMI] = useState("Loading...");
  const [clientAge, setClientAge] = useState("Loading...");
  const [modalShow, setModalShow] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("/form/pointer", {
          formid: assessmentID,
        });
        if (response.data) {
          console.log("Form data:", response.data);
          setClientData(response.data.client);
          setFormData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [assessmentID]);

  useEffect(() => {
    const calculateBMI = () => {
      // BMI = (weight)kg/(height)m^2
      let heightM = formData.formOne
        ? Number(formData.formOne.height) / 100
        : 0;
      let weightKG = formData.formOne ? Number(formData.formOne.weigth) : 0;

      let bmi = weightKG / heightM ** 2;

      setClientBMI(
        isNaN(bmi) || bmi === 0
          ? "Loading..."
          : `${bmi.toFixed(2)} (${
              bmi < 18.5
                ? "Under weight"
                : bmi > 18.5 && bmi < 25
                ? "Healthy weight"
                : bmi > 25
                ? "Over weight"
                : ""
            })`
      );
    };
    calculateBMI();
  }, [formData]);

  useEffect(() => {
    const calculateAge = () => {
      let today = new Date();
      let formattedDOB = new Date(clientData.dob);

      let age = today.getFullYear() - formattedDOB.getFullYear();
      let birthdayPassed =
        today.getMonth() - formattedDOB.getMonth() > 0 ||
        (today.getMonth() - formattedDOB.getMonth() === 0 &&
          today.getDate() - formattedDOB.getDate() > 0)
          ? true
          : false;
      if (!birthdayPassed) {
        age--;
      }

      setClientAge(age);
    };
    calculateAge();
  }, [clientData]);

  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <UnderwriterModal
          assessmentID={assessmentID}
          modalShow={modalShow}
          handleModalShow={handleModalShow}
          handleModalClose={handleModalClose}
        />
        <Row className="h-100">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col className="p-4">
                {Object.keys(formData).length > 0 ? (
                  <>
                    {role === "advisor" ? (
                      <Heading
                        heading={`${clientData.firstName} ${clientData.lastName}'s assessment submission`}
                        button="Submit to underwriters"
                        buttonOnClick={handleModalShow}
                      />
                    ) : (
                      <Heading
                        heading={`Assessment submission - #${assessmentID} `}
                        button="Advisor sign in"
                        buttonOnClick={() => {
                          window.location.href = "/login";
                        }}
                      />
                    )}

                    <Tabs defaultActiveKey="general" id="view-assessment">
                      <Tab eventKey="general" title="General">
                        <GeneralTab
                          formData={formData}
                          clientData={clientData}
                          clientBMI={clientBMI}
                          clientAge={clientAge}
                        />
                      </Tab>

                      {formData.formTwo.activities.length !== 0 ? ( // Hides tab when no relevant data is returned
                        <Tab eventKey="activities" title="Activities">
                          <ActivitiesTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formThree.diseases.length !== 0 ? (
                        <Tab eventKey="familyHistory" title="Family History">
                          <FamilyHistoryTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formSix.illness.length !== 0 ? (
                        <Tab eventKey="generalHealth" title="General Health">
                          <GeneralHealthTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formSeven.symptoms.length !== 0 ? (
                        <Tab eventKey="neurological" title="Neurological">
                          <NeurologicalTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formEight.anxiety.length !== 0 ? (
                        <Tab eventKey="mentalIllness" title="Mental Illness">
                          <MentalIllnessTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formTen.subFormsOfFormThirteen.length !== 0 ||
                      formData.formEleven.subFormsOfFourteen.length !== 0 ||
                      formData.formTwentyTwo.consultations.length !== 0 ? (
                        <Tab eventKey="musculoskeletal" title="Musculoskeletal">
                          <MusculoskeletalTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formFiffteen.subFormsOfFormFifteen.length !==
                      0 ? (
                        <Tab eventKey="skinDisorders" title="Skin">
                          <SkinTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formSixteen.subFormsOfSixteen.length !== 0 ? (
                        <Tab eventKey="cancer" title="Cancer">
                          <CancerTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.FormSeventeen.subFormsOfFormSeventeen.length !==
                      0 ? (
                        <Tab eventKey="organDisorders" title="Organs">
                          <OrgansTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formNineteen.illness.length !== 0 ? (
                        <Tab eventKey="bloodDisorder" title="Blood">
                          <BloodTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formTwenty.illness.length !== 0 ? (
                        <Tab eventKey="viruses" title="Viruses">
                          <VirusesTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {(formData.formTwentyOne.illnessWomen.length !== 0 &&
                        formData.formTwentyOne.illnessWomen[0] !== "") ||
                      formData.formTwentyOne.illness.length !== 0 ? (
                        <Tab eventKey="womensHealth" title="Women's Health">
                          <WomensHealthTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      {formData.formFiffteen.subFormsOfFormFifteen.length !==
                      0 ? (
                        <Tab eventKey="misc" title="Misc">
                          <MiscTab formData={formData} />
                        </Tab>
                      ) : (
                        <></>
                      )}
                      <Tab eventKey="employment" title="Employment">
                        <EmploymentTab formData={formData} />
                      </Tab>
                    </Tabs>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default FormView;
