import React, { useState } from "react";
import { Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";

import styles from "../../styles/form.module.css";

import FormMammOrUltrasound from "./illnesses/FormMammOrUltrasound";
import FormPapSmear from "./illnesses/FormPapSmear";
import FormAbnormalityOfTheOvaries from "./illnesses/FormAbnormalityOfTheOvaries";
import FormAbnormalVaginalBleeding from "./illnesses/FormAbnormalVaginalBleeding";
import FormEndometriosis from "./illnesses/FormEndometriosis";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormTwentyOne() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    illness: [],

    //MammOrUltrasound
    illnessWomen: [],
    whnAbnormalMammogram: "",
    whnBreastUltrasound: "",
    reqBiopsyOrAspiration: "",
    proceBiopsyOrAspiration: "",
    isLumpConfirmed: "",
    lumpDetails: "",
    isMammogramsNormal: "",

    //PapSmear
    diagAbnormalSmear: "",
    huwLongAbnormalSmear: "",
    followupAbnormalSmear: "",

    //AbnormalityOfTheOvaries
    whenAbnormalityOvaries: "",
    diagAbnormalityOvaries: "",
    reqBiopsyOrSurgery: "",
    whatOperatedProcedure: "",
    wasCystConfirmed: "",
    confirmedCystDetails: "",
    otherAbnormalityOvaries: "",

    //AbnormalVaginalBleeding
    consultAbnormalVaginalBleeding: "",
    causeAbnormalVaginalBleeding: "",
    reqFibroidsInvestigation: "",
    proceFibroidsInvestigation: "",
    isRecoveredIUD: "",
    iudRecoverdNoDetails: "",
    otherOrPolypsDetails: "",
    notConsultedDetails: "",

    //Endometriosis
    symFreeEndometriosis: "",
    whenDiagEndometriosis: "",
    treatEndometriosis: "",
    isOngTreatEndometriosis: "",
    detailsEndometriosis: "",
    noneOfTheAbove: false,

  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    //MammOrUltrasound
    whnAbnormalMammogram: Yup.string().when("illnessWomen", {
      is: (val) => val === "abnormalMammogram",
      then: Yup.string().required(),
    }),
    whnBreastUltrasound: Yup.string().when("illnessWomen", {
      is: (val) => val === "abnormalBreastUltrasound",
      then: Yup.string().required(),
    }),
    reqBiopsyOrAspiration: Yup.string().when("illnessWomen", {
      is: (val) =>
        val === "abnormalMammogram" || val === "abnormalBreastUltrasound",
      then: Yup.string().required(),
    }),
    proceBiopsyOrAspiration: Yup.string().when("reqBiopsyOrAspiration", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isLumpConfirmed: Yup.string().when("reqBiopsyOrAspiration", {
      is: "true",
      then: Yup.string().required(),
    }),
    lumpDetails: Yup.string().when("isLumpConfirmed", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isMammogramsNormal: Yup.string().when("illnessWomen", {
      is: (val) =>
        val === "abnormalMammogram" || val === "abnormalBreastUltrasound",
      then: Yup.string().required(),
    }),
    //End of MammOrUltrasound

    //PapSmear
    diagAbnormalSmear: Yup.string().when("illness", {
      is: (val) => val.includes("papSmear"),
      then: Yup.string().required(),
    }),
    huwLongAbnormalSmear: Yup.string().when("illness", {
      is: (val) => val.includes("papSmear"),
      then: Yup.string().required(),
    }),
    followupAbnormalSmear: Yup.string().when("illness", {
      is: (val) => val.includes("papSmear"),
      then: Yup.string().required(),
    }),
    // End of PapSmear

    //AbnormalityOfTheOvaries
    whenAbnormalityOvaries: Yup.string().when("illness", {
      is: (val) => val.includes("abnormalityOvaries"),
      then: Yup.string().required(),
    }),
    diagAbnormalityOvaries: Yup.string().when("illness", {
      is: (val) => val.includes("abnormalityOvaries"),
      then: Yup.string().required(),
    }),
    reqBiopsyOrSurgery: Yup.string().when("diagAbnormalityOvaries", {
      is: (val) => val === "simpleCyst" || val === "fibroid",
      then: Yup.string().required(),
    }),
    whatOperatedProcedure: Yup.string().when("reqBiopsyOrSurgery", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    wasCystConfirmed: Yup.string().when("reqBiopsyOrSurgery", {
      is: "true",
      then: Yup.string().required(),
    }),
    confirmedCystDetails: Yup.string().when("wasCystConfirmed", {
      is: "true",
      then: Yup.string().required(),
    }),
    otherAbnormalityOvaries: Yup.string().when("diagAbnormalityOvaries", {
      is: (val) => val === "other",
      then: Yup.string().required(),
    }),
    // End of AbnormalityOfTheOvaries

    //AbnormalVaginalBleeding
    consultAbnormalVaginalBleeding: Yup.string().when("illness", {
      is: (val) => val.includes("abnormalVaginalBleeding"),
      then: Yup.string().required(),
    }),
    causeAbnormalVaginalBleeding: Yup.string().when(
      "consultAbnormalVaginalBleeding",
      {
        is: "true",
        then: Yup.string().required(),
      }
    ),
    reqFibroidsInvestigation: Yup.string().when(
      "causeAbnormalVaginalBleeding",
      {
        is: (val) => val === "fibroids",
        then: Yup.string().required(),
      }
    ),
    proceFibroidsInvestigation: Yup.string().when("reqFibroidsInvestigation", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isRecoveredIUD: Yup.string().when("causeAbnormalVaginalBleeding", {
      is: (val) => val === "IUD",
      then: Yup.string().required(),
    }),
    iudRecoverdNoDetails: Yup.string().when("isRecoveredIUD", {
      is: "false",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    otherOrPolypsDetails: Yup.string().when("causeAbnormalVaginalBleeding", {
      is: (val) => val === "polyps" || val === "other",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    notConsultedDetails: Yup.string().when("consultAbnormalVaginalBleeding", {
      is: "false",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    // End of AbnormalVaginalBleeding

    //Endometriosis
    symFreeEndometriosis: Yup.string().when("illness", {
      is: (val) => val.includes("endometriosis"),
      then: Yup.string().required(),
    }),
    whenDiagEndometriosis: Yup.string().when("symFreeEndometriosis", {
      is: (val) => val === "lt3MonthsAgo",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatEndometriosis: Yup.string().when("symFreeEndometriosis", {
      is: (val) => val === "lt3MonthsAgo",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isOngTreatEndometriosis: Yup.string().when("symFreeEndometriosis", {
      is: (val) => val === "lt3MonthsAgo",
      then: Yup.string().required(),
    }),
    detailsEndometriosis: Yup.string().when("isOngTreatEndometriosis", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    noneOfTheAbove: Yup.string().when('illness', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]
  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formtwentyone", {
        formTwentyOne: e,
        pointer: 22,
        formid: id,
      });
      dispatch(setPage(22));
    } catch (error) {}
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched,resetForm,setErrors }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>
                      <FormMammOrUltrasound
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <FormPapSmear
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormAbnormalityOfTheOvaries
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormAbnormalVaginalBleeding
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormEndometriosis
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <NoneOfTheAbove
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        exceptionArray={exceptionArray}
                        setLoading={setLoading}
                        loading={loading}
                        resetForm={resetForm}
                        setErrorsFun={() => setErrors({})}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
