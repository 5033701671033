import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubFormOne from "./SubFormsOfFourteen/SubFormOne";
import SubFormTwo from "./SubFormsOfFourteen/SubFormTwo";
import SubFormThree from "./SubFormsOfFourteen/SubFormThree";
import SubFormFour from "./SubFormsOfFourteen/SubFormFour";
import SubFormFive from "./SubFormsOfFourteen/SubFormFive";
import SubFormSix from "./SubFormsOfFourteen/SubFormSix";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";


export default function FormFourteen() {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    subFormsOfFourteen: [],
    //Form One

    disorderHowLongSymptomsHas: "",
    surgicalTreatmentYes: "",
    dateAndProcedureNames: "",
    anyRestrictedYes: "",
    disorderDetails: "",
    disorderTimeHadOffWork: "",

    //form two

    SciaticaHowLongSymptomsHas: "",
    SciaticaHowManyReoccurrences: "",
    SciaticaAnyRestrictedYes: "",
    SciaticaDetails: "",
    SciaticaTimeHadOffWork: "",

    //form three

    traumaOrAccidentJointDisorder: "",
    traumaOrAccidentAffectedJoint: [],
    traumaOrAccidentHowLongSymptomsHas: "",
    traumaOrAccidentAnyRestrictedYes: "",
    traumaOrAccidentDetails: "",
    traumaOrAccidentHowManyReoccurrences: "",
    traumaOrAccidentTimeHadOffWork: "",

    // //form four

    affectedJoint: [],
    affectedArm: [],
    affectedLeg: [],
    affectedFoot: [],
    JointDisorder: "",
    whenOccur: "",
    whatTreatment: "",
    BoneFracturesAnyRestrictedYes: "",
    BoneFracturesDetails: "",
    BoneFracturesTimeHadOffWork: "",

    //form five
    MuscleDisorderConditions: "",
    MuscleDisorderTreatment: "",
    MuscleDisorderOngoingTreatmentOrPain: "",
    MuscleDisorderDetails: "",

    //form six

    JointBoneOrMuscleConditions: "",
    JointBoneOrMuscleTreatment: "",
    JointBoneOrMuscleOngoingTreatmentOrPain: "",
    JointBoneOrMuscleDetails: "",

    noneOfTheAbove:false,
  });

  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  
  const validationSchema = Yup.object().shape({
    //form One

    disorderHowLongSymptomsHas: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("Back_or_neck_disorder")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    surgicalTreatmentYes: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("Back_or_neck_disorder")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    dateAndProcedureNames: Yup.string().when(
      ["subFormsOfFourteen", "surgicalTreatmentYes"],
      (subFormsOfFourteen, surgicalTreatmentYes) => {
        if (
          subFormsOfFourteen.includes("Back_or_neck_disorder") &&
          surgicalTreatmentYes === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    anyRestrictedYes: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("Back_or_neck_disorder")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    disorderDetails: Yup.string().when(
      ["subFormsOfFourteen", "surgicalTreatmentYes", "anyRestrictedYes"],
      (
        subFormsOfFourteen,

        surgicalTreatmentYes,
        anyRestrictedYes
      ) => {
        if (
          subFormsOfFourteen.includes("Back_or_neck_disorder") &&
          surgicalTreatmentYes === "yes" &&
          anyRestrictedYes === "yes"
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    disorderTimeHadOffWork: Yup.string().when(
      [
        "subFormsOfFourteen",

        "disorderHowLongSymptomsHas",
        "surgicalTreatmentYes",
        "anyRestrictedYes",
      ],
      (
        subFormsOfFourteen,

        disorderHowLongSymptomsHas,
        surgicalTreatmentYes,
        anyRestrictedYes
      ) => {
        if (
          subFormsOfFourteen.includes("Back_or_neck_disorder") &&
          disorderHowLongSymptomsHas !== "" &&
          surgicalTreatmentYes === "yes" &&
          anyRestrictedYes === "yes"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form two

    SciaticaHowLongSymptomsHas: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("sciatica")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    SciaticaHowManyReoccurrences: Yup.string().when(
      ["subFormsOfFourteen", "SciaticaHowLongSymptomsHas"],
      (subFormsOfFourteen, SciaticaHowLongSymptomsHas) => {
        if (
          subFormsOfFourteen.includes("sciatica") &&
          SciaticaHowLongSymptomsHas !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    SciaticaAnyRestrictedYes: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("sciatica")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    SciaticaDetails: Yup.string().when(
      [
        "subFormsOfFourteen",

        "SciaticaHowLongSymptomsHas",
        "SciaticaHowManyReoccurrences",
        "SciaticaAnyRestrictedYes",
      ],
      (
        subFormsOfFourteen,

        SciaticaHowLongSymptomsHas,
        SciaticaHowManyReoccurrences,
        SciaticaAnyRestrictedYes
      ) => {
        if (
          subFormsOfFourteen.includes("sciatica") &&
          SciaticaHowLongSymptomsHas !== "" &&
          SciaticaHowManyReoccurrences !== "" &&
          SciaticaAnyRestrictedYes === "yes"
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    SciaticaTimeHadOffWork: Yup.string().when(
      [
        "subFormsOfFourteen",

        "SciaticaHowLongSymptomsHas",
        "SciaticaHowManyReoccurrences",
        "SciaticaAnyRestrictedYes",
      ],
      (
        subFormsOfFourteen,

        SciaticaHowLongSymptomsHas,
        SciaticaHowManyReoccurrences,
        SciaticaAnyRestrictedYes
      ) => {
        if (
          subFormsOfFourteen.includes("sciatica") &&
          SciaticaHowLongSymptomsHas !== "" &&
          SciaticaHowManyReoccurrences !== "" &&
          SciaticaAnyRestrictedYes === "no"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form three
    traumaOrAccidentJointDisorder: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("trauma_or_accident")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    traumaOrAccidentHowLongSymptomsHas: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("trauma_or_accident")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    traumaOrAccidentHowManyReoccurrences: Yup.string().when(
      ["subFormsOfFourteen", "traumaOrAccidentHowLongSymptomsHas"],
      (
        subFormsOfFourteen,

        traumaOrAccidentHowLongSymptomsHas
      ) => {
        if (
          subFormsOfFourteen.includes("trauma_or_accident") &&
          traumaOrAccidentHowLongSymptomsHas !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    traumaOrAccidentAnyRestrictedYes: Yup.string().when(
      ["subFormsOfFourteen", "traumaOrAccidentHowLongSymptomsHas"],
      (
        subFormsOfFourteen,

        traumaOrAccidentHowLongSymptomsHas
      ) => {
        if (
          subFormsOfFourteen.includes("trauma_or_accident") &&
          traumaOrAccidentHowLongSymptomsHas !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    traumaOrAccidentDetails: Yup.string().when(
      [
        "subFormsOfFourteen",

        "traumaOrAccidentHowLongSymptomsHas",
        "traumaOrAccidentAnyRestrictedYes",
      ],
      (
        subFormsOfFourteen,

        traumaOrAccidentHowLongSymptomsHas,
        traumaOrAccidentAnyRestrictedYes
      ) => {
        if (
          subFormsOfFourteen.includes("trauma_or_accident") &&
          traumaOrAccidentHowLongSymptomsHas !== "" &&
          traumaOrAccidentAnyRestrictedYes === "yes"
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    traumaOrAccidentTimeHadOffWork: Yup.string().when(
      [
        "subFormsOfFourteen",

        "traumaOrAccidentHowLongSymptomsHas",
        "traumaOrAccidentAnyRestrictedYes",
        "traumaOrAccidentDetails",
      ],
      (
        subFormsOfFourteen,

        traumaOrAccidentHowLongSymptomsHas,
        traumaOrAccidentAnyRestrictedYes,
        traumaOrAccidentDetails
      ) => {
        if (
          subFormsOfFourteen.includes("trauma_or_accident") &&
          traumaOrAccidentHowLongSymptomsHas !== "" &&
          traumaOrAccidentAnyRestrictedYes === "no"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form four

    whenOccur: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("boneFractures")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    whatTreatment: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("boneFractures")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    BoneFracturesAnyRestrictedYes: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("boneFractures")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    BoneFracturesDetails: Yup.string().when(
      ["subFormsOfFourteen", "BoneFracturesAnyRestrictedYes"],
      (subFormsOfFourteen, BoneFracturesAnyRestrictedYes) => {
        if (
          subFormsOfFourteen.includes("boneFractures") &&
          BoneFracturesAnyRestrictedYes === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    BoneFracturesTimeHadOffWork: Yup.string().when(
      [
        "subFormsOfFourteen",

        "BoneFracturesAnyRestrictedYes",
        "BoneFracturesDetails",
      ],
      (
        subFormsOfFourteen,

        BoneFracturesAnyRestrictedYes,
        BoneFracturesDetails
      ) => {
        if (
          subFormsOfFourteen.includes("boneFractures") &&
          BoneFracturesAnyRestrictedYes !== "" &&
          BoneFracturesDetails !== ""
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //form five

    MuscleDisorderConditions: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("muscle_Disorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    MuscleDisorderTreatment: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("muscle_Disorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    MuscleDisorderOngoingTreatmentOrPain: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("muscle_Disorder")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    MuscleDisorderDetails: Yup.string().when(
      ["subFormsOfFourteen", "MuscleDisorderOngoingTreatmentOrPain"],
      (
        subFormsOfFourteen,

        MuscleDisorderOngoingTreatmentOrPain
      ) => {
        if (
          subFormsOfFourteen.includes("muscle_Disorder") &&
          MuscleDisorderOngoingTreatmentOrPain === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    //form fix

    JointBoneOrMuscleConditions: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("jointBoneOrMuscle")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    JointBoneOrMuscleTreatment: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("jointBoneOrMuscle")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    JointBoneOrMuscleOngoingTreatmentOrPain: Yup.string().when(
      ["subFormsOfFourteen"],
      (subFormsOfFourteen) => {
        if (subFormsOfFourteen.includes("jointBoneOrMuscle")) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    JointBoneOrMuscleDetails: Yup.string().when(
      ["subFormsOfFourteen", "JointBoneOrMuscleOngoingTreatmentOrPain"],
      (
        subFormsOfFourteen,

        JointBoneOrMuscleOngoingTreatmentOrPain
      ) => {
        if (
          subFormsOfFourteen.includes("jointBoneOrMuscle") &&
          JointBoneOrMuscleOngoingTreatmentOrPain === "yes"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),

    noneOfTheAbove: Yup.string().when('subFormsOfFourteen', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]

  const dispatch = useDispatch();

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formeleven", {
        formEleven: e,
        pointer: 15,
        formid: id,
      });
      dispatch(setPage(15));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched, resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following?
                    </p>

                    <SubFormOne values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormTwo values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormThree values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFour values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormFive values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <SubFormSix values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}/>
                    <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                     <div
                      style={{
                        backgroundColor: "rgb(240,240,240)",
                        height: 1,
                        width: "100%",
                      }}
                    ></div>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
