import { Grid } from "@material-ui/core";
import React from "react";

import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const questions = [
  {
    name: "mountaineering1",
    label: "For how many years have you been climbing regularly?",
  },
  {
    name: "mountaineering2",
    label: "How often do you climb?",
  },
  {
    name: "mountaineering3",
    label: "Are you a member of a club?",
  },
  {
    name: "mountaineering4",
    label: "Do you climb outside of Australia? If yes, where?",
  },
  {
    name: "mountaineering5",
    label:
      "What type of terrain do you climb? (rock, snow/ice, artificial climbing walls, abseiling)",
  },
  {
    name: "mountaineering6",
    label: "What is the maximum height you have climbed to?",
  },
  {
    name: "mountaineering7",
    label: "Do you ever climb without a rope? if yes provide details",
  },
];
const resetArray = [
  { name: "mountaineering1", type: "" },
  { name: "mountaineering2", type: "" },
  { name: "mountaineering3", type: "" },
  { name: "mountaineering4", type: "" },
  { name: "mountaineering5", type: "" },
  { name: "mountaineering6", type: "" },
  { name: "mountaineering7", type: "" },
];
export default function Mountaineering({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Grid container md={12} sm={12} xs={12}>
      <CheckBox
        list={[{ label: "Mountaineering", value: "mountaineering" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("mountaineering") &&
        questions.map((question) => (
          <AnswerBox
            label={question.label}
            name={question.name}
            key={question.name}
            isSubForm
          />
        ))}
    </Grid>
  );
}
