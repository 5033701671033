import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../lib/axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF } from "@mui/material/";
import Chip from "@mui/material/Chip";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ReusableTable({
  headers,
  TableB,
  fetchDataUrl,
  fetchDataUrl2,
  statusChangeUrl,
  updatePath,
  title,
  view,
  ...props
}) {
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");

  const [filteredItems, setFilteredItems] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    // const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  //alerting
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [items, setData] = useState([]);

  //use effect
  useEffect(() => {
    loadData();
    // loadData2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load data to the table
  const loadData = async () => {
    try {
      if (fetchDataUrl) {
        const { data } = await axios.get(fetchDataUrl);

        setFilteredItems(data);

        setData(data);
      }
    } catch (error) {
      setOpenAlert(true);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Data loading failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Data loading failed!",
        });

        setOpenAlert(false);
      }, 3000);
    }
  };

  // activate and deactivate status
  const changeStatus = async (id, status) => {
    try {
      await axios.put(statusChangeUrl, {
        id,
        status: !status,
      });
      loadData();
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Status changing failed!",
      });
    }
  };

  //update
  const onUpdate = (id) => {
    props.history.push(updatePath, { update: true, id });
  };

  const onView = (id) => {
    props.history.push(view, { id });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    green: {
      backgroundColor: "green",
      color: "white",
    },
    red: {
      backgroundColor: "red",
      color: "white",
    },
    yellow: {
      backgroundColor: "orange",
      color: "white",
    },
    textCenter: {
      textAlign: "center",
    },
  }));

  const classes = useStyles();

  const [searchText, setSearchText] = useState("");

  //search
  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);
    if (text) {
      // eslint-disable-next-line
      const result = items.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(items);
    }
  };

  const submit = async (e, { resetForm }) => {
    console.log(e);
    try {
      const { data } = await axios.post("/paypal/month", {
        start: e.start,
        end: e.end,
      });

      setFilteredItems(data);

      setData(data);
      setInitialValues({ start: e.start, end: e.end });
      setOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        setOpenAlert(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "unauthorized!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "unauthorized!",
          });
          setOpenAlert(false);
        }, 3000);
      } else {
        setOpenAlert(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Payment loading failed!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Payment loading failed!",
          });
          setOpenAlert(false);
        }, 3000);
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      await axios.put(`/purchaseOrder/${id}`, {
        status,
      });
      loadData();
    } catch (error) {
      setOpenAlert(true);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Status changing failed!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Status changing failed!",
        });
        setOpenAlert(false);
      }, 3000);
    }
  };

  const [initialValues, setInitialValues] = useState({
    start: null,
    end: null,
  });

  let validationSchema = Yup.object().shape({
    start: Yup.date().required("Date of Birth is required!"),
    end: Yup.date().required("Date of Birth is required!"),
  });

  const handleDelete = () => {
    setInitialValues({
      start: null,
      end: null,
    });
    loadData();
  };

  return (
    <Grid container>
      <Grid item className={classes.root}>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "holiday",
                fontSize: "2rem",
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              {" "}
              {title}
            </Typography>
            <Typography
              style={{
                fontFamily: "Overpass, sans-serif",
                fontSize: "2rem",
                marginRight: 200,
                marginTop: 30,
              }}
            >
              {" "}
              <FilterAltIcon
                style={{ fontSize: 40 }}
                onClick={handleClickOpen}
              />
            </Typography>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              fullWidth="md"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                Payment Reports
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Formik
                  initialValues={initialValues}
                  onSubmit={submit}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  {({
                    isValid,
                    dirty,
                    values,
                    setFieldValue,
                    errors,
                    setFieldTouched,
                    handleChange,
                  }) => {
                    return (
                      <Form>
                        <Grid item xs={12} sm={12} md={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAuLocale}
                          >
                            <DatePicker
                              label="What was the date the symptoms commenced?"
                              value={start}
                              disableOpenPicker
                              maxDate={moment().toDate()}
                              inputProps={{
                                placeholder: "dd/mm/yyyy",
                                format: "dd/mm/yyyy",
                              }}
                              onChange={(newValue) => {
                                setStart(newValue);
                                setFieldValue("start", newValue);
                              }}
                              renderInput={(params) => (
                                <TF
                                  fullWidth
                                  variant="outlined"
                                  helperText={params?.inputProps?.placeholder}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAuLocale}
                          >
                            <DatePicker
                              label="What was the date the symptoms commenced?"
                              value={end}
                              disableOpenPicker
                              maxDate={moment().toDate()}
                              inputProps={{
                                placeholder: "dd/mm/yyyy",
                                format: "dd/mm/yyyy",
                              }}
                              onChange={(newValue) => {
                                setEnd(newValue);
                                setFieldValue("end", newValue);
                              }}
                              renderInput={(params) => (
                                <TF
                                  style={{ marginTop: 15 }}
                                  fullWidth
                                  variant="outlined"
                                  helperText={params?.inputProps?.placeholder}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          {/* <Field
                            component={DatePicker}
                            name="start"
                            inputVariant="outlined"
                            emptyLabel="Select Start Date *"
                            maxDate={moment().toDate()}
                            disableFuture={true}
                            format="DD-MM-YYYY"
                            fullWidth
                            inputProps={{
                              style: { fontFamily: "Overpass, sans-serif" },
                            }}
                          />
                          <Field
                            style={{ marginTop: 15 }}
                            component={DatePicker}
                            name="end"
                            inputVariant="outlined"
                            emptyLabel="Select End Date *"
                            maxDate={moment().toDate()}
                            disableFuture={true}
                            format="DD-MM-YYYY"
                            fullWidth
                            inputProps={{
                              style: { fontFamily: "Overpass, sans-serif" },
                            }}
                          /> */}
                        </Grid>
                        <Button
                          variant="contained"
                          className={classes.green}
                          style={{
                            marginTop: 20,
                            backgroundColor: "green",
                            color: "white",
                          }}
                          type="submit"
                          disabled={!dirty || !isValid}
                        >
                          Generate Report
                        </Button>
                        <Typography style={{ marginTop: 20 }}>
                          * Required field
                        </Typography>
                      </Form>
                    );
                  }}
                </Formik>
              </DialogContent>
            </BootstrapDialog>
          </div>

          <CardContent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Search..."
                variant="outlined"
                className={classes.marginBottom}
                onChange={onSearch}
                value={searchText}
              />

              {initialValues.start && (
                <Chip
                  label={`From ${moment(initialValues.start).format(
                    "DD-MM-YYYY"
                  )} To ${moment(initialValues.end).format("DD-MM-YYYY")}`}
                  style={{
                    fontFamily: "Overpass, sans-serif",
                    fontSize: "1rem",
                    marginRight: 180,
                  }}
                  variant="outlined"
                  onDelete={handleDelete}
                />
              )}
            </div>

            <TableContainer
              component={Paper}
              style={{ fontFamily: "Overpass, sans-serif" }}
            >
              <Table
                size="small"
                style={{ fontFamily: "Overpass, sans-serif" }}
              >
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell
                        key={i}
                        sortDirection={orderDirection}
                        style={{
                          fontFamily: "Overpass, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        <TableSortLabel
                          active={header.value === valueToOrderBy}
                          onClick={createSortHandler(header.value)}
                          direction={orderDirection}
                        >
                          {header.text.toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? sortedRowInformation(
                        filteredItems,
                        getComparator(orderDirection, valueToOrderBy)
                      ).slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : sortedRowInformation(
                        filteredItems,
                        getComparator(orderDirection, valueToOrderBy)
                      )
                  ).map((item, i) => (
                    <TableRow key={i}>
                      <TableB
                        item={item}
                        changeStatus={changeStatus}
                        classes={classes}
                        onUpdate={onUpdate}
                        onView={onView}
                        loadData={loadData}
                        updateStatus={updateStatus}
                        setAlert={setAlert}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
