import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const resetArray = [
  { name: "kidneyDisorderSymptoms", type: "" },
  { name: "kidneyDisorderWhenThisOccur", type: "" },
  { name: "kidneyDisorderWhatTreatments", type: "" },
  { name: "kidneyDisorderOngoingSymptoms", type: "" },
  { name: "kidneyDisorderOngoingSymptomsDetails", type: "" },
];
export default function SubFormFour({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Kidney Disorder", value: "kidneyDisorder" }]}
        name="subFormsOfFormSeventeen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormSeventeen.includes("kidneyDisorder") && (
        <AnswerBox
          label="What were your symptoms?"
          name="kidneyDisorderSymptoms"
          isSubForm
        />
      )}
      {values.subFormsOfFormSeventeen.includes("kidneyDisorder") &&
        values.kidneyDisorderSymptoms && (
          <AnswerBox
            label="When did this occur?"
            name="kidneyDisorderWhenThisOccur"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("kidneyDisorder") &&
        values.kidneyDisorderWhenThisOccur && (
          <AnswerBox
            label="What was the treatment that was recommended by a medical professional?"
            name="kidneyDisorderWhatTreatments"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("kidneyDisorder") &&
        values.kidneyDisorderWhatTreatments && (
          <RadioMulti
            question="Is this still an ongoing condition/do you have ongoing symptoms?"
            name={"kidneyDisorderOngoingSymptoms"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("kidneyDisorder") &&
        values.kidneyDisorderOngoingSymptoms === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="kidneyDisorderOngoingSymptomsDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
