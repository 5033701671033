import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNBContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 130,
  },
});

const InvoiceNBandDate = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceNBContainer}>
      <Text>Preassess Me Pty Ltd</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text>{invoice.phone}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text>info@preassessme.com.au</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text>www.preassessme.com.au</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text>ABN 31 643 784 767</Text>
    </View>
  </Fragment>
);

export default InvoiceNBandDate;
