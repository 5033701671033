import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import EpilepsyPetitMal from "./activities/EpilepsyPetitMal";
import EpilepsyGrandMal from "./activities/EpilepsyGrandMal";
import EpilepsyOther from "./activities/EpilepsyOther";
import EpilepsyUnknown from "./activities/EpilepsyUnknown";
import FitsOfAnyKind from "./activities/FitsOfAnyKind";
import Paralysis from "./activities/Paralysis";
import Migraines from "./activities/Migraines";
import Tinnitus from "./activities/Tinnitus";
import Dizziness from "./activities/Dizziness";
import RecurrentHeadaches from "./activities/RecurrentHeadaches";
import MultipleSclerosis from "./activities/MultipleSclerosis";
import NeurologicalDisorder from "./activities/NeurologicalDisorder";
import Tremor from "./activities/Tremor";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormSeven() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    symptoms: [],

    annualAttakcs: "",
    lastAttack: "",
    cause: "",
    dutyRestrictions: "",
    details: "",

    epilepsyGradMalAnnualAttakcs: "",
    epilepsyGradMalLastAttack: "",
    epilepsyGradMalCause: "",
    epilepsyGradMalDutyRestrictions: "",
    epilepsyGradMalDetails: "",

    epilepsyOtherTreatment: "",
    epilespsyOtherstartDate: "",
    epilepsyOtherRestrictedDuties: "",
    epilepsyOtherDetails: "",

    epilepsyUnknownTreatment: "",
    epilepsyUnknownstartDate: "",
    epilepsyUnknownRestrictedDuties: "",
    epilepsyUnknownDetails: "",

    fitsOfAnyKindChildhood: "",
    fitsOfAnyKindTreatment: "",
    fitsOfAnyKindstartDate: "",
    fitsOfAnyKindRestrictedDuties: "",
    fitsOfAnyKindDetails: "",

    paralysisSymptoms: "",
    paralysisTreatment: "",
    paralysisOccur: "",
    paralysisOccupationRestriction: "",
    paralysisDetails: "",

    migraineStart: "",
    migraineFurtherInvestigation: "",
    migraineDetails: "",

    tinnitusStart: "",
    tinnitusDetails: "",
    tinnitusDeafness: "",
    tinnitusDeafnessDetails: "",
    tinnitusDoctorRecommended: "",
    tinnitusDetailsInvestigation: "",
    tinnitusDailyLife: "",
    tinnitusDailyLifeDetails: "",

    dizzinessSymptoms: "",
    dizzinessTreatment: "",
    dizzinessStart: "",
    dizzinessDutyRestrictions: "",
    dizzinessDetails: "",

    recurrentHeadachesStart: "",
    recurrentHeadachesDetails: "",
    recurrentHeadachesStatus: "",
    recurrentHeadachesStatusDetails: "",
    recurrentHeadachesDoctorRecommended: "",
    recurrentHeadachesDetailsInvestigation: "",
    recurrentHeadachesDailyLife: "",

    multipleSclerosisSymptoms: "",
    multipleSclerosisSymptomsDetails: "",

    neurologicalDisorderDiagnosisDetails: "",
    neurologicalDisorderSymptoms: "",
    neurologicalDisorderWorkStatus: "",
    neurologicalDisorderDailyLife: "",
    neurologicalDisorderDetails: "",

    tremorDiagnosisDetails: "",
    tremorSymptoms: "",
    tremorWorkStatus: "",
    tremorDailyLife: "",
    tremorDetails: "",

    noneOfTheAbove:false,

  });

  const exceptionArray = {
    // noneOfTheAbove: [],
  };

  const validationSchema = Yup.object().shape({
    annualAttakcs: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("epilepsymal")) {
        return Yup.string().required("This field is required");
      }
    }),
    lastAttack: Yup.string().when("annualAttakcs", {
      is: "moreThanTwelve" || "lessThanTwelve",
      then: Yup.string().required("This field is required"),
    }),
    cause: Yup.string().when("lastAttack", {
      is: "threeToSevenYears",
      then: Yup.string().required("This field is required"),
    }),
    dutyRestrictions: Yup.string().when("cause", {
      is: "headTraumaless" || "headTraumamore" || "tumour" || "none",
      then: Yup.string().required("This field is required"),
    }),
    details: Yup.string().when("dutyRestrictions", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    epilepsyGradMalAnnualAttakcs: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("epilepsygrandmal")) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    epilepsyGradMalCause: Yup.string().when("epilepsyGradMalLastAttack", {
      is: "threeToSevenYears",
      then: Yup.string().required("This field is required"),
    }),
    epilepsyGradMalDutyRestrictions: Yup.string().when("epilepsyGradMalCause", {
      is: "headTraumaless" || "headTraumamore" || "tumour" || "none",
      then: Yup.string().required("This field is required"),
    }),
    epilepsyGradMalDetails: Yup.string().when(
      "epilepsyGradMalDutyRestrictions",
      {
        is: "true",
        then: Yup.string().required("This field is required"),
      }
    ),
    epilepsyOtherTreatment: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("epilepsyother")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    epilespsyOtherstartDate: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("epilepsyother")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    epilepsyOtherRestrictedDuties: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("epilepsyother")) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    epilepsyOtherDetails: Yup.string().when("epilepsyOtherRestrictedDuties", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    epilepsyUnknownTreatment: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("epilepsyunknown")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    epilepsyUnknownstartDate: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("epilepsyunknown")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    epilepsyUnknownRestrictedDuties: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("epilepsyunknown")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    epilepsyUnknownDetails: Yup.string().when(
      "epilepsyUnknownRestrictedDuties",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),
    fitsOfAnyKindChildhood: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("fitsofanykind")) {
        return Yup.string().required("This field is required");
      }
    }),
    fitsOfAnyKindTreatment: Yup.string().when("fitsOfAnyKindChildhood", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    fitsOfAnyKindstartDate: Yup.string().when("fitsOfAnyKindChildhood", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    fitsOfAnyKindRestrictedDuties: Yup.string().when("fitsOfAnyKindChildhood", {
      is: "true",
      then: Yup.string().required("This field is required"),
    }),
    fitsOfAnyKindDetails: Yup.string().when("fitsOfAnyKindRestrictedDuties", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    paralysisSymptoms: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("paralysis")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    paralysisTreatment: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("paralysis")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    paralysisOccur: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("paralysis")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    paralysisOccupationRestriction: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("paralysis")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    paralysisDetails: Yup.string().when("paralysisOccupationRestriction", {
      is: "true",
      then: Yup.string().required("This field is required"),
    }),
    migraineStart: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("migraines")) {
        return Yup.string().required("This field is required");
      }
    }),
    migraineFurtherInvestigation: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("migraines")) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    migraineDetails: Yup.string().when("migraineFurtherInvestigation", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    tinnitusStart: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tinnitus")) {
        return Yup.string().required("This field is required");
      }
    }),
    tinnitusDetails: Yup.string().when("tinnitusStart", {
      is: "ongoing",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    tinnitusDeafness: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tinnitus")) {
        return Yup.string().required("This field is required");
      }
    }),
    tinnitusDeafnessDetails: Yup.string().when("tinnitusDeafness", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    tinnitusDoctorRecommended: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tinnitus")) {
        return Yup.string().required("This field is required");
      }
    }),
    tinnitusDetailsInvestigation: Yup.string().when(
      "tinnitusDoctorRecommended",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),
    tinnitusDailyLife: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tinnitus")) {
        return Yup.string().required("This field is required");
      }
    }),
    tinnitusDailyLifeDetails: Yup.string().when("tinnitusDailyLife", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    dizzinessSymptoms: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("dizziness")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    dizzinessTreatment: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("dizziness")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    dizzinessStart: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("dizziness")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    dizzinessDutyRestrictions: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("dizziness")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    dizzinessDetails: Yup.string().when("dizzinessDutyRestrictions", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    recurrentHeadachesStart: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("recurrentHeadaches")) {
        return Yup.string().required("This field is required");
      }
    }),
    recurrentHeadachesDetails: Yup.string().when("recurrentHeadachesStart", {
      is: "lessthansix",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    recurrentHeadachesStatus: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("recurrentHeadaches")) {
        return Yup.string().required("This field is required");
      }
    }),
    recurrentHeadachesStatusDetails: Yup.string().when(
      "recurrentHeadachesStatus",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),
    recurrentHeadachesDoctorRecommended: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("recurrentHeadaches")) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    recurrentHeadachesDetailsInvestigation: Yup.string().when(
      "recurrentHeadachesDoctorRecommended",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),

    multipleSclerosisSymptoms: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("multipleSclerosis")) {
        return Yup.string().required("This field is required");
      }
    }),
    multipleSclerosisSymptomsDetails: Yup.string().when(
      "multipleSclerosisSymptoms",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),

    neurologicalDisorderDiagnosisDetails: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("neurologicalDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    neurologicalDisorderSymptoms: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("neurologicalDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    neurologicalDisorderWorkStatus: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("neurologicalDisorder")) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    neurologicalDisorderDailyLife: Yup.string().when(
      ["symptoms"],
      (symptoms) => {
        if (symptoms.includes("neurologicalDisorder")) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    neurologicalDisorderDetails: Yup.string().when(
      "neurologicalDisorderDailyLife",
      {
        is: "true",
        then: Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required"),
      }
    ),
    tremorDiagnosisDetails: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tremor")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    tremorSymptoms: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tremor")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    tremorWorkStatus: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tremor")) {
        return Yup.string()
          .max(300, "Maximum length is 300")
          .required("This field is required");
      }
    }),
    tremorDailyLife: Yup.string().when(["symptoms"], (symptoms) => {
      if (symptoms.includes("tremor")) {
        return Yup.string().required("This field is required");
      }
    }),
    tremorDetails: Yup.string().when("tremorDailyLife", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    noneOfTheAbove: Yup.string().when('symptoms', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });

  // FORM [2,2]
  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formseven", {
        formSeven: e,
        pointer: 8,
        formid: id,
      });
      dispatch(setPage(8));
    } catch (error) {}
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched , resetForm,setErrors}) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following
                    </p>
                    <EpilepsyPetitMal values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}
                    />
                    <EpilepsyGrandMal
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <EpilepsyOther
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <EpilepsyUnknown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <FitsOfAnyKind
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Paralysis
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Migraines
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Tinnitus
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Dizziness
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <RecurrentHeadaches
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <MultipleSclerosis
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <NeurologicalDisorder
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Tremor
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                 
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
