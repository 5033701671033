import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const conditionList = [
  {
    label: "Long-sightedness",
    value: "longSightedness",
  },
  {
    label: "Short-sightedness",
    value: "shortSightedness",
  },
  {
    label: "Astigmatism",
    value: "astigmatism",
  },
  {
    label: "Colour Blindness",
    value: "colourBlindness",
  },
  {
    label: "Cataract",
    value: "cataract",
  },
  {
    label: "Glaucoma",
    value: "glaucoma",
  },
  {
    label: "Other",
    value: "other",
  },
];

const CataractSymptomsIdentifiedList = [
  {
    label: "No specific cause identified",
    value: "noSpecificCauseIdentified",
  },
  {
    label: "another medical issue (diabetes mellitus)",
    value: "anotherMedicalIssue",
  },
  {
    label: "Side effect of medication",
    value: "SideEffectOfMedication",
  },
  {
    label: "Trauma, injury or accident",
    value: "TraumaInjuryOrAccident",
  },
];
const glaucomaIdentifiedList = [
  {
    label: "No specific cause identified",
    value: "noSpecificCauseIdentified",
  },
  {
    label: "another medical issue (diabetes mellitus)",
    value: "anotherMedicalIssue",
  },
];
const sideOfEye = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
  {
    label: "Both",
    value: "both",
  },
];
const resetArray = [
  { name: "yourCondition", type: [] },
  { name: "cataractSymptomsBeenIdentified", type: [] },
  { name: "glaucomaSymptomsBeenIdentified", type: "" },
  { name: "bornWithASightDefect", type: "" },
  { name: "detailsForSyncomsBeenIdentified", type: "" },
  { name: "visionDeterioratedOverThePast2Years", type: "" },
  { name: "defectInSighRestricted", type: "" },
  { name: "defectInSighAnySurgicalTreatment", type: "" },
  { name: "detailsForSurgicalTreatment", type: "" },
  { name: "detailsForRestricted", type: "" },
  { name: "isVisionNormalWithOutGlasses", type: "" },
  { name: "whichEyeISDeflected", type: "" },
];
export default function SubFormSix({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Defect in Sight", value: "defectInSight" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("defectInSight") && (
        <CheckBox
          question="What is your condition?"
          list={conditionList}
          name="yourCondition"
          isSubForm
        />
      )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.yourCondition.includes("cataract") && (
          <CheckBox
            question="Has a cause for the symptoms been identified?"
            list={CataractSymptomsIdentifiedList}
            name="cataractSymptomsBeenIdentified"
            isSubForm
          />
        )}
      {(values.cataractSymptomsBeenIdentified.includes("anotherMedicalIssue") ||
        values.cataractSymptomsBeenIdentified.includes(
          "SideEffectOfMedication"
        ) ||
        values.cataractSymptomsBeenIdentified.includes(
          "TraumaInjuryOrAccident"
        )) &&
        values.subFormsOfFormFifteen.includes("defectInSight") && (
          <AnswerBox
            label="Please provide details?"
            name="detailsForSyncomsBeenIdentified"
            isSubForm
          />
        )}
      {(values.cataractSymptomsBeenIdentified.includes(
        "noSpecificCauseIdentified"
      ) ||
        values.detailsForSyncomsBeenIdentified !== "") &&
        values.subFormsOfFormFifteen.includes("defectInSight") && (
          <RadioMulti
            question="Have you had surgical treatment for this condition?"
            name={"defectInSighAnySurgicalTreatment"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.defectInSighAnySurgicalTreatment === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="detailsForSurgicalTreatment"
            isSubForm
          />
        )}
      {(values.defectInSighAnySurgicalTreatment === "no" ||
        values.detailsForSurgicalTreatment !== "") &&
        values.subFormsOfFormFifteen.includes("defectInSight") && (
          <RadioMulti
            question="Is your vision now normal with or without the use of glasses?"
            name={"isVisionNormalWithOutGlasses"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.yourCondition.includes("glaucoma") && (
          <CheckBox
            question="Has a cause for the symptoms been identified?"
            list={glaucomaIdentifiedList}
            name="glaucomaSymptomsBeenIdentified"
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.glaucomaSymptomsBeenIdentified && (
          <RadioMulti
            question="Has your vision deteriorated over the past 2 years?"
            name={"visionDeterioratedOverThePast2Years"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.yourCondition.includes("other") && (
          <RadioMulti
            question="Were you born with a sight defect?"
            name={"bornWithASightDefect"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.bornWithASightDefect !== "" && (
          <RadioMulti
            question="Are you restricted in your work or daily activities by this condition?"
            name={"defectInSighRestricted"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("defectInSight") &&
        values.defectInSighRestricted === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="detailsForRestricted"
            isSubForm
          />
        )}
      {(values.defectInSighRestricted !== "" ||
        values.detailsForRestricted !== "") &&
        values.subFormsOfFormFifteen.includes("defectInSight") && (
          <RadioMulti
            question="Which eye is affected?"
            name={"whichEyeISDeflected"}
            items={sideOfEye}
            isSubForm
          />
        )}
    </Fragment>
  );
}
