import { FIRST, SECOND } from "./types.js";

export const switchFirst = (data) => (dispatch) => {
  dispatch({
    type: FIRST,
    payload: { data },
  });
};

export const switchSecond = (data) => (dispatch) => {
  dispatch({
    type: SECOND,
    payload: { data },
  });
};
