import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";
const resetArray = [
  { name: "diagHaemophilia", type: "" },
  { name: "treatHaemophilia", type: "" },
];
export default function FormHaemophilia({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Haemophilia", value: "Haemophilia" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Haemophilia") && (
        <Fragment>
          <AnswerBox
            name="diagHaemophilia"
            label="What age was your diagnosis and onset of haemophilia"
            isSubForm
          />
          <AnswerBox
            name="treatHaemophilia"
            label="What was the treatment that was recommended by a medical professional?"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
