import { Grid } from "@material-ui/core";
import React from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const questions = [
  {
    name: "parachuting1",
    label: "How many times have you been parachuting?",
  },
  {
    name: "parachuting2",
    label: "How many times have you been parachuting in the last 12 months?",
  },
  {
    name: "parachuting3",
    label:
      "What Qualifications, certificates,licences, or club memberships do you have?",
  },
  {
    name: "parachuting4",
    label: "How long have you been parachuting?",
  },
  {
    name: "parachuting5",
    label: "Where do you engage in this? What locations?",
  },
  {
    name: "parachuting6",
    label: "Do you engage in parachuting alone or are you always with a group?",
  },
  {
    name: "parachuting7",
    label: "What is the maximum height you parachute at?",
  },
  {
    name: "parachuting8",
    label: "Are all recognized safety measures and precautions followed?",
  },
  {
    name: "parachuting9",
    label:
      "Have you ever been involved in an accident whilst parachuting? Details",
  },
];
const resetArray = [
  { name: "parachuting1", type: "" },
  { name: "parachuting2", type: "" },
  { name: "parachuting3", type: "" },
  { name: "parachuting4", type: "" },
  { name: "parachuting5", type: "" },
  { name: "parachuting6", type: "" },
  { name: "parachuting7", type: "" },
  { name: "parachuting8", type: "" },
  { name: "parachuting9", type: "" },
];
export default function Parachuting({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Grid container md={12} sm={12} xs={12}>
      <CheckBox
        list={[{ label: "Parachuting", value: "parachuting" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("parachuting") &&
        questions.map((question) => (
          <AnswerBox
            label={question.label}
            name={question.name}
            key={question.name}
            isSubForm
          />
        ))}
    </Grid>
  );
}
