import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const resetArray = [
  { name: "anyPancreasEver", type: "" },
  { name: "pancreasDisorderSymptoms", type: "" },
  { name: "pancreasDisorderWhenThisOccur", type: "" },
  { name: "pancreasDisorderWhatTreatments", type: "" },
  { name: "pancreasDisorderOngoingSymptoms", type: "" },
  { name: "pancreasDisorderOngoingSymptomsDetails", type: "" },
];

export default function SubFormTwo({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Pancreas Disorder", value: "pancreasDisorder" }]}
        name="subFormsOfFormSeventeen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") && (
        <RadioMulti
          question="Was your pancreas ever diagnosed as pancreatitis?"
          name={"anyPancreasEver"}
          items={items}
          isSubForm
        />
      )}
      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") &&
        values.anyPancreasEver === "yes" && (
          <AnswerBox
            label="What were your symptoms?"
            name="pancreasDisorderSymptoms"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") &&
        values.pancreasDisorderSymptoms && (
          <AnswerBox
            label="When did this occur?"
            name="pancreasDisorderWhenThisOccur"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") &&
        values.pancreasDisorderWhenThisOccur && (
          <AnswerBox
            label="What was the treatment that was recommended by a medical professional?"
            name="pancreasDisorderWhatTreatments"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") &&
        values.pancreasDisorderWhatTreatments && (
          <RadioMulti
            question="Is this still an ongoing condition/do you have ongoing symptoms?"
            name={"pancreasDisorderOngoingSymptoms"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("pancreasDisorder") &&
        values.pancreasDisorderOngoingSymptoms === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="pancreasDisorderOngoingSymptomsDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
