import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "../../styles/form.module.css";
import FormHepatitisOrHIV from "./illnesses/FormHepatitisOrHIV";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import { useStyles } from "./classes";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormSix() {
  const { id, client } = useParams();
  const classes = useStyles();
  const [gender, setGender] = useState("");

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    illness: [],

    //HepatitisOrHIV
    otherIllness: "",
    hepatitisOrHIV: "",
    treatHepatitisOrHIV: "",
    isOngTreatHepatitisOrHIV: "",
    detailsHepatitisOrHIV: "",

    noneOfTheAbove: false,
  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    //HepatitisOrHIV
    hepatitisOrHIV: Yup.string().when("otherIllness", {
      is: (val) =>
       val?.length > 0,
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatHepatitisOrHIV: Yup.string().when("otherIllness", {
      is: (val) =>
        val?.length > 0,
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isOngTreatHepatitisOrHIV: Yup.string().when("otherIllness", {
      is: (val) =>
        val?.length > 0,
      then: Yup.string().required(),
    }),
    detailsHepatitisOrHIV: Yup.string().when("isOngTreatHepatitisOrHIV", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    // End of HepatitisOrHIV


    // COMMENTED FOR REFERENCE IF NONEOFTHEABOVE BOOLEAN VALIDATION DOES NOT WORK
    // noneOfTheAbove: Yup.string().when("otherIllness", {
    //   is: (val) => val === "" || val === null || val === undefined,
    //   then: Yup.string().required("This field is required"),
    // }),

    noneOfTheAbove: Yup.string().when('otherIllness', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]
  const submit = async (e) => {
    console.log("e", e);
    try {
      if (gender === "Female") {
        await axios.put("/form/formtwenty", {
          formTwenty: e,
          pointer: 21,
          formid: id,
        });
        dispatch(setPage(21));
      } else {
        await axios.put("/form/formtwenty", {
          formTwenty: e,
          pointer: 22,
          formid: id,
        });
        dispatch(setPage(22));
      }
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      const user = await axios.get(`/client/get/${client}`);
      console.log(user);
      setGender(user.data.gender);
    } catch (error) {}
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
            errors,
          }) => {
            return (
              <Form>
                <Card style={{ marginBottom: "6.5rem", padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>
                      <FormHepatitisOrHIV
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <NoneOfTheAbove
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        exceptionArray={exceptionArray}
                        resetForm={resetForm}
                        setErrorsFun={() => setErrors({})}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
