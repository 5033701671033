import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({ handleClose, open, data }) {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{
              fontFamily: "holiday",
              fontSize: "2rem",
              marginLeft: 15,
              marginTop: 15,
            }}
          >
            {" "}
            Advisor Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Name</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.firstName}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Email</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.email}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>AR Number</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.arNumber}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Contact Number</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.contactNumber}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Currents Status</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>
                {data.status ? "active" : "Inactive"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>DOB</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>
                {`${moment(data.DOB).format("DD-MM-YYYY")}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Account Type</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.accountType}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Advice Firm</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.adviceFirm}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Clients Count</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.clientsCount}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Dealer Group</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.dealerGroup}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Maden Name</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.madenName}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Mobile Number</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.mobileNumber}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Postal Address</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.postalAddress}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>State Residence</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom>{`${data.stateResidence}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography>Logo</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img src={data.logo} style={{ maxHeight: "100px" }} alt=" "></img>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
