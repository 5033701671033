import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const timeList = [
  {
    label: "No time off work",
    value: "noTimeOffWork",
  },
  {
    label: "Less than 1 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "1-2 weeks",
    value: "oneTwoWeeks",
  },
  {
    label: "3-4 weeks",
    value: "threeFourWeeks",
  },
  {
    label: "+4 weeks",
    value: "moreThanFourWeeks",
  },
];

const howLong = [
  {
    label: "Symptoms are ongoing",
    value: "SymptomsAreOngoing",
  },
  {
    label: "Less than 12 months",
    value: "lessThan12Months",
  },
  {
    label: "1-2 years",
    value: "OneTwoYears",
  },
  {
    label: "2-3 years",
    value: "TowThreeYears",
  },
  {
    label: "3-5 years",
    value: "ThreeFiveYears",
  },
  {
    label: "5+ years ago",
    value: "moreThanFiveYears",
  },
];

const howManyOccupance = [
  {
    label: "One-off occupance only",
    value: "OneOccupance",
  },
  {
    label: "1-2 reoccurrences",
    value: "OneTwoReoccurrences",
  },
  {
    label: "3+ reoccurrences",
    value: "MoreThanThreeTRoccurrences",
  },
];

const disorderList = [
  {
    label: "Right wrist",
    value: "rightWrist",
  },
  {
    label: "Left Wrist",
    value: "leftWrist",
  },
  {
    label: "Right shoulder",
    value: "rightShoulder",
  },
  {
    label: "Left Shoulder",
    value: "leftShoulder",
  },
  {
    label: "Right elbow",
    value: "rightElbow",
  },
  {
    label: "Left Elbow",
    value: "leftElbow",
  },
  {
    label: "Right hand",
    value: "rightHand",
  },
  {
    label: "Left hand",
    value: "leftHand",
  },
  {
    label: "other",
    value: "other",
  },
];
const resetArray = [
  { name: "traumaOrAccidentJointDisorder", type: "" },
  { name: "traumaOrAccidentAffectedJoint", type: [] },
  { name: "traumaOrAccidentHowLongSymptomsHas", type: "" },
  { name: "traumaOrAccidentAnyRestrictedYes", type: "" },
  { name: "traumaOrAccidentDetails", type: "" },
  { name: "traumaOrAccidentHowManyReoccurrences", type: "" },
  { name: "traumaOrAccidentTimeHadOffWork", type: "" },
];
export default function SubFormThree({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Joint disorders relating to trauma or accident",
            value: "trauma_or_accident",
          },
        ]}
        name="subFormsOfFourteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFourteen.includes("trauma_or_accident") && (
        <AnswerBox
          label="What was the joint disorder?"
          name="traumaOrAccidentJointDisorder"
          isSubForm
        />
      )}
      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        values.traumaOrAccidentJointDisorder && (
          <CheckBox
            question="Which joints were affected?"
            name={"traumaOrAccidentAffectedJoint"}
            list={disorderList}
            isSubForm
          />
        )}

      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        values.traumaOrAccidentAffectedJoint.length !== 0 && (
          <RadioMulti
            question="How long has it been since you last had symptoms or pain?"
            name={"traumaOrAccidentHowLongSymptomsHas"}
            items={howLong}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        values.traumaOrAccidentHowLongSymptomsHas && (
          <RadioMulti
            question="How many reoccurrences have you had since symptoms first commenced?"
            name={"traumaOrAccidentHowManyReoccurrences"}
            items={howManyOccupance}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        values.traumaOrAccidentHowManyReoccurrences && (
          <RadioMulti
            question="Are you in any way restricted in your daily activities as a consequence of this condition?"
            name={"traumaOrAccidentAnyRestrictedYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        values.traumaOrAccidentAnyRestrictedYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="traumaOrAccidentDetails"
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("trauma_or_accident") &&
        (values.traumaOrAccidentDetails ||
          values.traumaOrAccidentAnyRestrictedYes === "no") && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"traumaOrAccidentTimeHadOffWork"}
            items={timeList}
            isSubForm
          />
        )}
    </Fragment>
  );
}
