import React from "react";
import { Grid } from "@material-ui/core";
// validation
import { Field } from "formik";

import RadioMulti from "./RadioMulti";

import AnswerBox from "./AnswerBox";
import CheckBox from "./CheckBox";
import { CheckboxWithLabel } from "formik-material-ui";

import { Fragment } from "react";

const items = [
  {
    label: "Mum",
    value: "mum",
  },
  {
    label: "Dad",
    value: "Dad",
  },
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Sister",
    value: "Sister",
  },
];

const ages = [
  {
    label: "Under 18",
    value: "Under 18",
  },
  {
    label: "18-30",
    value: "18-30",
  },
  {
    label: "31-64",
    value: "31-64",
  },
  {
    label: "65+",
    value: "65+",
  },
];

export default function FamilyCommon({
  name,
  radioName,
  deathName,
  disease,
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e) => {
    console.log("e", e.target);
    if (!e.target.checked) {
      setFieldValue(name, [], false);
      setFieldValue(radioName, "", false);
      setFieldValue(deathName, "", false);
      setFieldTouched(name, false);
      setFieldTouched(radioName, false);
      setFieldTouched(deathName, false);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Grid item>
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={"diseases"}
        value={disease}
        onClick={(e) => resetFold(e)}
        Label={{
          label: (
            <span
              style={{
                fontFamily: "Overpass, sans-serif",
              }}
            >
              {disease}{" "}
            </span>
          ),
        }}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.diseases.includes(disease) && (
        <Fragment>
          <CheckBox
            question="Who was the family member?"
            name={name}
            list={items}
            isSubForm
          />
          <RadioMulti
            question="What was the onset age?"
            name={radioName}
            items={ages}
            isSubForm
          />
          <AnswerBox
            label="What was the age of death if applicable"
            name={deathName}
            isSubForm
          />
        </Fragment>
      )}
    </Grid>
  );
}
