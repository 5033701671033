import React from "react";
import { Fragment } from "react";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "isFullyRecovered", type: "" },
  { name: "pneumoniaEpisodes", type: "" },
];
export default function FormPneumonia({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Pneumonia", value: "Pneumonia" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Pneumonia") && (
        <Fragment>
          <YesNoQuestion
            question="Have your systems been treated fully and a full recovery made"
            name="isFullyRecovered"
            isSubForm
          />
          <RadioMulti
            question="How many episodes of Pneumonia have your had per annum on average"
            name="pneumoniaEpisodes"
            items={[
              { label: "Less than 4 episodes", value: "Less than 4 episodes" },
              { label: "4 or more episodes", value: "4 or more episodes" },
            ]}
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
