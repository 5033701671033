import axios from "axios";
import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";

const PackageCard = (props) => {
  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    try {
      const response = await axios.get("/auth/me");
      console.log(`userData received:\n${JSON.stringify(response.data)}`);
      setUserData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Convert price int to formatted currency string
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  const formattedPrice = formatter.format(props.price);

  // Paypal payment API POST request
  const handlePayment = async () => {
    try {
      const response = await axios.post("/paypal/payment", {
        value: [
          {
            name: userData.fullName,
            description: props.description,
            quantity: 1,
            price: props.price,
            tax: "0.0",
            currency: "AUD",
            sku: props.id,
          },
        ],
        details: {
          total: props.price,
          arNumber: userData.arNumber,
        },
      });
      console.log(JSON.stringify(response));
      window.location.assign(response.data.link);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Card className="h-100">
      <Card.Header as="h5" className="py-3">
        {props.header}
      </Card.Header>
      <Card.Body>
        <p>{props.description}</p>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between align-items-center py-3">
        <h5 className="m-0">{formattedPrice}</h5>
        <a href="#">
          <Button variant="primary" onClick={handlePayment}>
            Purchase bundle
          </Button>
        </a>
      </Card.Footer>
    </Card>
  );
};

export default PackageCard;
