import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";
const examinationTypeList = [
  { label: "Gastro", value: "Gastro" },
  { label: "Diarrhoea", value: "Diarrhoea" },
  { label: "Haemorrhoids", value: "Haemorrhoids" },
  { label: "Constipation", value: "Constipation" },
  {
    label: "Irritable Bowel Syndrome",
    value: "IrritableBowelSyndrome",
  },
  { label: "Food Poisoning", value: "FoodPoisoning" },
  { label: "Coeliac Disease", value: "CoeliacDisease" },
  { label: "Crohns Disease", value: "CrohnsDisease" },
  {
    label: "Colitis including Ulcerative Colitis",
    value: "ColitisincludingUlcerativeColitis",
  },
  { label: "Other", value: "Other" },
];

const resetArray = [
  { name: "selectConditions", type: [] },
  { name: "isBloodPassed", type: "" },
  { name: "tretAndOutcome", type: "" },
  { name: "howLongLastBleeding", type: [] },
  { name: "doneColonoscopy", type: "" },
  { name: "colonoscopyDetails", type: "" },
  { name: "hasIBSinvestigated", type: "" },
  { name: "IBStreatment", type: "" },
  { name: "diagnosedInYear", type: "" },
  { name: "coeliacDiseaseTreat", type: "" },
  { name: "howManageCoeliacDisease", type: [] },
  { name: "currentlySymptomFree", type: "" },
  { name: "whatMeditationCoeliacDisease", type: "" },
];
export default function FormAnyBowelDisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Any Bowel Disorder", value: "AnyBowelDisorder" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("AnyBowelDisorder") && (
        <Fragment>
          <CheckBox
            question="Please select the condition/s"
            list={examinationTypeList}
            name="selectConditions"
            isSubForm
          />

          {values.selectConditions.includes("Diarrhoea") && (
            <Fragment>
              <YesNoQuestion
                question="Was any blood passed?"
                name="isBloodPassed"
                isSubForm
              />
              {values.isBloodPassed === "true" && (
                <AnswerBox
                  label="Please provide details on the treatment and the outcome of the treatment
                  including the date of issue."
                  name="tretAndOutcome"
                  isSubForm
                />
              )}
              <RadioMulti
                question="How long did symptoms last?"
                name="howLongLastBleeding"
                items={[
                  { label: "Up to a week", value: "UpToAweek" },
                  { label: "1-2 weeks", value: "1-2Weeks" },
                  { label: "3 weeks or more", value: "3WeeksOrMore" },
                ]}
                isSubForm
              />
              <YesNoQuestion
                question="Have you been advised to have any investigations done such as a colonoscopy in the last 2 years?"
                name="doneColonoscopy"
                isSubForm
              />
              {values.doneColonoscopy === "true" && (
                <AnswerBox
                  label="Please provide details of the condition and diagnosis as well as any treatment provided"
                  name="colonoscopyDetails"
                  isSubForm
                />
              )}
            </Fragment>
          )}
          {values.selectConditions.includes("IrritableBowelSyndrome") && (
            <Fragment>
              <YesNoQuestion
                question="Has the IBS been fully investigated, and the diagnosis confirmed by a medical
                practitioner?"
                name="hasIBSinvestigated"
                isSubForm
              />
              {values.hasIBSinvestigated === "true" && (
                <AnswerBox
                  label="Please provide details on the treatment and the outcome of the treatment
                  including the date of issue."
                  name="IBStreatment"
                  isSubForm
                />
              )}
            </Fragment>
          )}
          {values.selectConditions.includes("CoeliacDisease") && (
            <Fragment>
              <YesNoQuestion
                question="Was the condition diagnosed in the last 12 months?"
                name="diagnosedInYear"
                isSubForm
              />
              {values.diagnosedInYear === "true" && (
                <AnswerBox
                  label="Please provide details on the treatment and the outcome of the treatment
                  including the date of issue."
                  name="coeliacDiseaseTreat"
                  isSubForm
                />
              )}
              <RadioMulti
                name="howManageCoeliacDisease"
                question="How is your condition being managed?"
                items={[
                  { label: "Gluten-Free Diet", value: "glutenFree" },
                  { label: "Medication", value: "medication" },
                  {
                    label: "Gluten-Free diet and Medication",
                    value: "GlutenFreeAndMedication",
                  },
                  { label: "None of the above", value: "NoneOfTheAbove" },
                ]}
                isSubForm
              />
              {(values.howManageCoeliacDisease.includes("glutenFree") ||
                values.howManageCoeliacDisease.includes("NoneOfTheAbove")) && (
                <YesNoQuestion
                  question="Are you currently Symptom free"
                  name="currentlySymptomFree"
                  isSubForm
                />
              )}
              {(values.howManageCoeliacDisease.includes("medication") ||
                values.howManageCoeliacDisease.includes(
                  "GlutenFreeAndMedication"
                )) && (
                <AnswerBox
                  label="What medication are you currently on?"
                  name="whatMeditationCoeliacDisease"
                  isSubForm
                />
              )}
            </Fragment>
          )}
          {(values.selectConditions.includes("CrohnsDisease") ||
            values.selectConditions.includes(
              "ColitisincludingUlcerativeColitis"
            )) && (
            <Fragment>
              <AnswerBox
                label="When was your condition diagnosed?"
                name="whnDiagCrohnColitis"
                isSubForm
              />
              <AnswerBox
                label="When was your last attack?"
                name="whnAttCrohnColitis"
                isSubForm
              />
              <AnswerBox
                label="Please provide details on the treatment and the outcome of the treatment
                including the date of issue."
                name="treatmentCrohnColitis"
                isSubForm
              />
            </Fragment>
          )}
          {values.selectConditions.includes("Other") && (
            <AnswerBox
              label="Please provide details on the treatment and the outcome of the treatment
              including the date of issue."
              name="otherTreatAndOutcome"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
