import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const yearsList = [
  {
    label: "Less than 2 years ago",
    value: "lessThanTwo",
  },
  {
    label: "2-5 years ago",
    value: "betweenTwoAndFive",
  },
  {
    label: "5+ years ago",
    value: "moreThanFive",
  },
];
const years = [
  {
    label: "Within the past 12 months",
    value: "withinPast12Months",
  },
  {
    label: "More than 12 months ago",
    value: "moreThan12Months",
  },
];
const disorderList = [
  {
    label: "Acne",
    value: "acne",
  },
  {
    label: "Ingrown hair",
    value: "ingrownHair",
  },
  {
    label: "Tinea (ringworm)",
    value: "ringworm",
  },
  {
    label: "Vitiligo",
    value: "vitiligo",
  },
  {
    label: "Warts",
    value: "warts",
  },
  // {
  //   label: "Basal Cell Carcinoma (BCC)",
  //   value: "BCC",
  // },
  // {
  //   label: "Squamous cell carcinoma (SCC)",
  //   value: "SCC",
  // },
  {
    label: "Other",
    value: "other",
  },
];
const conditionList = [
  {
    label: "Skin cancer",
    value: "skinCancer",
  },
  {
    label: "Cancer other than skin (eg:BCC or SCC)",
    value: "cancerOtherThanSkin",
  },
];
const treatedList = [
  {
    label: "Burnt or frozen off (cryotherapy)",
    value: "cryotherapy",
  },
  {
    label: "Cut out (excised)",
    value: "excised",
  },
  {
    label: "Cream/ointment",
    value: "creamOrOintment",
  },
  {
    label: "Not removed",
    value: "notRemoved",
  },
];
const resetArray = [
  { name: "otherSkinDisorderSelectedDisorder", type: [] },
  { name: "otherSkinDisorderSelectedOtherDisorderDetails", type: "" },
  { name: "otherSkinDisorderWhatDisorder", type: [] },
  { name: "otherSkinDisorderHowSkinGrowthTreated", type: [] },
  { name: "otherSkinDisorderWhenWasItRemoved", type: "" },
  { name: "otherSkinDisorderCreamOintmentDetails", type: "" },
  { name: "otherSkinDisorderWhenSkinGrowthFirst", type: "" },
  { name: "otherSkinDisorderConfirmedAsBenign", type: "" },
  { name: "otherSkinDisorderSCC", type: "" },
  { name: "otherSkinDisorderTreatmentRecommendedOrRequired", type: "" },
  {
    name: "otherSkinDisorderTreatmentRecommendedOrRequiredDetails",
    type: "",
  },
  {
    name: "otherSkinDisorderNeedOtherTreatmentForSkinGrowth",
    type: "",
  },
  {
    name: "otherSkinDisorderNeedOtherTreatmentForSkinGrowthDetails",
    type: "",
  },
];
export default function SubFormFour({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Other skin disorder", value: "otherSkinDisorder" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") && (
        <CheckBox
          question="Select skin disorder?"
          list={disorderList}
          name="otherSkinDisorderSelectedDisorder"
          isSubForm
        />
      )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderSelectedDisorder.includes("other") && (
          <AnswerBox
            label="Please provide details?"
            name="otherSkinDisorderSelectedOtherDisorderDetails"
            isSubForm
          />
        )}
      {(values.otherSkinDisorderSelectedDisorder.includes("BCC") ||
        values.otherSkinDisorderSelectedDisorder.includes("SCC")) &&
        values.subFormsOfFormFifteen.includes("otherSkinDisorder") && (
          <CheckBox
            question="What is the condition?"
            list={conditionList}
            name="otherSkinDisorderWhatDisorder"
            isSubForm
          />
        )}
      {(values.otherSkinDisorderWhatDisorder.includes("skinCancer") ||
        values.otherSkinDisorderWhatDisorder.includes("cancerOtherThanSkin")) &&
        values.subFormsOfFormFifteen.includes("otherSkinDisorder") && (
          <CheckBox
            question="How was the skin growth treated?"
            list={treatedList}
            name="otherSkinDisorderHowSkinGrowthTreated"
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderHowSkinGrowthTreated.includes("excised") && (
          <RadioMulti
            question="When was it removed?"
            name={"otherSkinDisorderWhenWasItRemoved"}
            items={yearsList}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderWhenWasItRemoved && (
          <RadioMulti
            question="Was it confirmed as being Benign (noncancerous)?"
            name={"otherSkinDisorderConfirmedAsBenign"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderConfirmedAsBenign === "no" && (
          <RadioMulti
            question="Was it a Squamous Cell Carcinoma (SCC)?"
            name={"otherSkinDisorderSCC"}
            items={items}
            isSubForm
          />
        )}
      {(values.otherSkinDisorderConfirmedAsBenign === "yes" ||
        values.otherSkinDisorderSCC !== "") &&
        values.subFormsOfFormFifteen.includes("otherSkinDisorder") && (
          <RadioMulti
            question="Other than being cut out was there any other treatment recommended or required?"
            name={"otherSkinDisorderTreatmentRecommendedOrRequired"}
            items={items}
            isSubForm
          />
        )}

      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderTreatmentRecommendedOrRequired === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="otherSkinDisorderTreatmentRecommendedOrRequiredDetails"
            isSubForm
          />
        )}

      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderHowSkinGrowthTreated.includes(
          "creamOrOintment"
        ) && (
          <AnswerBox
            label="Please provide details?"
            name="otherSkinDisorderCreamOintmentDetails"
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderHowSkinGrowthTreated.includes("notRemoved") && (
          <RadioMulti
            question="When was the skin growth first checked by a medical practitioner?"
            name={"otherSkinDisorderWhenSkinGrowthFirst"}
            items={years}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderWhenSkinGrowthFirst && (
          <RadioMulti
            question="Other than regular checks have you been advised you need other treatment for the skin growth?"
            name={"otherSkinDisorderNeedOtherTreatmentForSkinGrowth"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormFifteen.includes("otherSkinDisorder") &&
        values.otherSkinDisorderNeedOtherTreatmentForSkinGrowth === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="otherSkinDisorderNeedOtherTreatmentForSkinGrowthDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
