import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import MobileViewport from "../mobileViewport/MobileViewport";

const TermsAndConditions = () => {
  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col xs={12} className="p-4">
                <Heading heading="PreAssessMe website terms and conditions of use" />
                <h5 className="pt-3 pb-1">1. About the Website</h5>
                <p className="mb-2">
                  (a) Welcome to https://preassessme.com.au/ (the 'Website').
                  The Website life insurance preassessment tool (the
                  'Services').
                </p>
                <p className="mb-2">
                  (b) The Website is operated by Preassess Me Pty Ltd(ACN
                  643784767). Access to and use of the Website, or any of its
                  associated Products or Services, is provided by Preassess Me
                  Pty Ltd. Please read these terms and conditions (the 'Terms')
                  carefully. By using, browsing and/or reading the Website, this
                  signifies that you have read, understood and agree to be bound
                  by the Terms. If you do not agree with the Terms, you must
                  cease usage of the Website, or any of Services, immediately.
                </p>
                <p className="mb-2">
                  (c) Preassess Me Pty Ltd reserves the right to review and
                  change any of the Terms by updating this page at its sole
                  discretion. When Preassess Me Pty Ltd updates the Terms, it
                  will use reasonable endeavours to provide you with notice of
                  updates to the Terms. Any changes to the Terms take immediate
                  effect from the date of their publication. Before you
                  continue, we recommend you keep a copy of the Terms for your
                  records.
                </p>
                <h5 className="pt-3 pb-1">2. Acceptance of the Terms</h5>
                <p className="mb-2">
                  (a) You accept the Terms by remaining on the Website. You may
                  also accept the Terms by clicking to accept or agree to the
                  Terms where this option is made available to you by Preassess
                  Me Pty Ltd in the user interface.
                </p>
                <h5 className="pt-3 pb-1">
                  3. Subscription to use the Services
                </h5>
                <p className="mb-2">
                  (a) In order to access the Services, you must first purchase a
                  subscription through the Website (the 'Subscription') and pay
                  the applicable fee for the selected Subscription (the
                  'Subscription Fee').
                </p>
                <p className="mb-2">
                  (b) In purchasing the Subscription, you acknowledge and agree
                  that it is your responsibility to ensure that the Subscription
                  you elect to purchase is suitable for your use.
                </p>
                <p className="mb-2">
                  (c) Once you have purchased the Subscription, you will then be
                  required to register for an account through the Website before
                  you can access the Services (the 'Account').
                </p>
                <p className="mb-2">
                  (d) As part of the registration process, or as part of your
                  continued use of the Services, you may be required to provide
                  personal information about yourself (such as identification or
                  contact details), including:
                </p>
                <p className="mb-2 ms-4">(i) Email address</p>
                <p className="mb-2 ms-4">(ii) Preferred username</p>
                <p className="mb-2 ms-4">(iii) Mailing address</p>
                <p className="mb-2 ms-4">(iv) Telephone number</p>
                <p className="mb-2 ms-4">(v) Password</p>
                <p className="mb-2 ms-4">
                  (vi) Authorised Representative Number
                </p>
                <p className="mb-2">
                  (e) You warrant that any information you give to Preassess Me
                  Pty Ltd in the course of completing the registration process
                  will always be accurate, correct and up to date.
                </p>
                <p className="mb-2">
                  (f) Once you have completed the registration process, you will
                  be a registered member of the Website ('Member') and agree to
                  be bound by the Terms. As a Member you will be granted
                  immediate access to the Services from the time you have
                  completed the registration process until the subscription
                  period expires (the 'Subscription Period').
                </p>
                <p className="mb-2">
                  (g) You may not use the Services and may not accept the Terms
                  if:
                </p>
                <p className="mb-2 ms-4">
                  (i) you are not of legal age to form a binding contract with
                  Preassess Me Pty Ltd; or
                </p>
                <p className="mb-2 ms-4">
                  (ii) you are a person barred from receiving the Services under
                  the laws of Australia or other countries including the country
                  in which you are resident or from which you use the Services.
                </p>
                <h5 className="pt-3 pb-1">4. Your obligations as a Member</h5>
                <p className="mb-2">
                  (a) As a Member, you agree to comply with the following:
                </p>
                <p className="mb-2 ms-4">
                  (i) you will use the Services only for purposes that are
                  permitted by:
                  <p className="mb-2 mt-2 ms-4">(A) the Terms; and</p>
                  <p className="mb-2 ms-4">
                    (B) any applicable law, regulation or generally accepted
                    practices or guidelines in the relevant jurisdictions;
                    <p className="mb-2 mt-2 ms-4">
                      (ii) you have the sole responsibility for protecting the
                      confidentiality of your password and/or email address. Use
                      of your password by any other person may result in the
                      immediate cancellation of the Services;
                    </p>
                    <p className="mb-2 ms-4">
                      (iii) any use of your registration information by any
                      other person, or third parties, is strictly prohibited.
                      You agree to immediately notify Preassess Me Pty Ltd of
                      any unauthorised use of your password or email address or
                      any breach of security of which you have become aware;
                    </p>
                    <p className="mb-2 ms-4">
                      (iv) access and use of the Website is limited,
                      non-transferable and allows for the sole use of the
                      Website by you for the purposes of Preassess Me Pty Ltd
                      providing the Services;
                    </p>
                    <p className="mb-2 ms-4">
                      (v) you will not use the Services or the Website in
                      connection with any commercial endeavours except those
                      that are specifically endorsed or approved by the
                      management of Preassess Me Pty Ltd;
                    </p>
                    <p className="mb-2 ms-4">
                      (vi) you will not use the Services or Website for any
                      illegal and/or unauthorised use which includes collecting
                      email addresses of Members by electronic or other means
                      for the purpose of sending unsolicited email or
                      unauthorised framing of or linking to the Website;
                    </p>
                    <p className="mb-2 ms-4">
                      (vii) you agree that commercial advertisements, affiliate
                      links, and other forms of solicitation may be removed from
                      the Website without notice and may result in termination
                      of the Services. Appropriate legal action will be taken by
                      Preassess Me Pty Ltd for any illegal or unauthorised use
                      of the Website; and
                    </p>
                    <p className="mb-2 ms-4">
                      (viii) you acknowledge and agree that any automated use of
                      the Website or its Services is prohibited.
                    </p>
                  </p>
                </p>
                <h5 className="pt-3 pb-1">5. Payment</h5>
                <p className="mb-2">
                  (a) Where the option is given to you, you may make payment of
                  the Subscription Fee by way of:
                </p>
                <p className="mb-2 ms-4">
                  (i) Credit Card Payment ('Credit Card')
                </p>
                <p className="mb-2 ms-4">(ii) PayPal ('PayPal')</p>
                <p className="mb-2">
                  (b) All payments made in the course of your use of the
                  Services are made using Payflow Gateway. In using the Website,
                  the Services or when making any payment in relation to your
                  use of the Services, you warrant that you have read,
                  understood and agree to be bound by the Payflow Gateway terms
                  and conditions which are available on their website.
                </p>
                <p className="mb-2">
                  (c) You acknowledge and agree that where a request for the
                  payment of the Subscription Fee is returned or denied, for
                  whatever reason, by your financial institution or is unpaid by
                  you for any other reason, then you are liable for any costs,
                  including banking fees and charges, associated with the
                  Subscription Fee .
                </p>
                <p className="mb-2">
                  (d) You agree and acknowledge that Preassess Me Pty Ltd can
                  vary the Subscription Fee at any time and that the varied
                  Subscription Fee will come into effect following the
                  conclusion of the existing Subscription Period.
                </p>
                <h5 className="pt-3 pb-1">6. Refund Policy</h5>
                <p className="mb-2">
                  Preassess Me Pty Ltd will only provide you with a refund of
                  the Subscription Fee in the event they are unable to continue
                  to provide the Services or if the manager of Preassess Me Pty
                  Ltd makes a decision, at its absolute discretion, that it is
                  reasonable to do so under the circumstances. Where this
                  occurs, the refund will be in the proportional amount of the
                  Subscription Fee that remains unused by the Member (the
                  'Refund').
                </p>
                <h5 className="pt-3 pb-1">
                  7. Copyright and Intellectual Property
                </h5>
                <p className="mb-2">
                  (a) The Website, the Services and all of the related products
                  of Preassess Me Pty Ltd are subject to copyright. The material
                  on the Website is protected by copyright under the laws of
                  Australia and through international treaties. Unless otherwise
                  indicated, all rights (including copyright) in the Services
                  and compilation of the Website (including but not limited to
                  text, graphics, logos, button icons, video images, audio
                  clips, Website code, scripts, design elements and interactive
                  features) or the Services are owned or controlled for these
                  purposes, and are reserved by Preassess Me Pty Ltd or its
                  contributors.
                </p>
                <p className="mb-2">
                  (b) All trademarks, service marks and trade names are owned,
                  registered and/or licensed by Preassess Me Pty Ltd, who grants
                  to you a worldwide, non-exclusive, royalty-free, revocable
                  license whilst you are a Member to:
                </p>
                <p className="mb-2 ms-4">
                  (i) use the Website pursuant to the Terms;
                </p>
                <p className="mb-2 ms-4">
                  (ii) copy and store the Website and the material contained in
                  the Website in your device's cache memory; and
                </p>
                <p className="mb-2 ms-4">
                  (iii) print pages from the Website for your own personal and
                  non-commercial use.
                </p>
                <p className="mb-2">
                  Preassess Me Pty Ltd does not grant you any other rights
                  whatsoever in relation to the Website or the Services. All
                  other rights are expressly reserved by Preassess Me Pty Ltd.
                </p>
                <p className="mb-2">
                  (c) Preassess Me Pty Ltd retains all rights, title and
                  interest in and to the Website and all related Services.
                  Nothing you do on or in relation to the Website will transfer
                  any:
                </p>
                <p className="mb-2 ms-4">
                  (i) business name, trading name, domain name, trade mark,
                  industrial design, patent, registered design or copyright, or
                </p>
                <p className="mb-2 ms-4">
                  (ii) a right to use or exploit a business name, trading name,
                  domain name, trade mark or industrial design, or
                </p>
                <p className="mb-2 ms-4">
                  (iii) a thing, system or process that is the subject of a
                  patent, registered design or copyright (or an adaptation or
                  modification of such a thing, system or process), to you.
                </p>
                <p className="mb-2">
                  (d) You may not, without the prior written permission of
                  Preassess Me Pty Ltd and the permission of any other relevant
                  rights owners: broadcast, republish, up-load to a third party,
                  transmit, post, distribute, show or play in public, adapt or
                  change in any way the Services or third party Services for any
                  purpose, unless otherwise provided by these Terms. This
                  prohibition does not extend to materials on the Website, which
                  are freely available for re-use or are in the public domain.
                </p>
                <h5 className="pt-3 pb-1">8. Privacy</h5>
                <p className="mb-2">
                  (a) Preassess Me Pty Ltd takes your privacy seriously and any
                  information provided through your use of the Website and/or
                  Services are subject to Preassess Me Pty Ltd's Privacy Policy,
                  which is available on the Website.
                </p>
                <h5 className="pt-3 pb-1">9. General Disclaimer</h5>
                <p className="mb-2">
                  (a) Nothing in the Terms limits or excludes any guarantees,
                  warranties, representations or conditions implied or imposed
                  by law, including the Australian Consumer Law (or any
                  liability under them) which by law may not be limited or
                  excluded.
                </p>
                <p className="mb-2">
                  (b) Subject to this clause, and to the extent permitted by
                  law:
                </p>
                <p className="mb-2 ms-4">
                  (i) all terms, guarantees, warranties, representations or
                  conditions which are not expressly stated in the Terms are
                  excluded; and
                </p>
                <p className="mb-2 ms-4">
                  (ii) Preassess Me Pty Ltd will not be liable for any special,
                  indirect or consequential loss or damage (unless such loss or
                  damage is reasonably foreseeable resulting from our failure to
                  meet an applicable Consumer Guarantee), loss of profit or
                  opportunity, or damage to goodwill arising out of or in
                  connection with the Services or these Terms (including as a
                  result of not being able to use the Services or the late
                  supply of the Services), whether at common law, under
                  contract, tort (including negligence), in equity, pursuant to
                  statute or otherwise.
                </p>
                <p className="mb-2">
                  (c) Use of the Website and the Services is at your own risk.
                  Everything on the Website and the Services is provided to you
                  "as is" and "as available" without warranty or condition of
                  any kind. None of the affiliates, directors, officers,
                  employees, agents, contributors and licensors of Preassess Me
                  Pty Ltd make any express or implied representation or warranty
                  about the Services or any products or Services (including the
                  products or Services of Preassess Me Pty Ltd) referred to on
                  the Website. This includes (but is not restricted to) loss or
                  damage you might suffer as a result of any of the following:
                </p>
                <p className="mb-2 ms-4">
                  (i) failure of performance, error, omission, interruption,
                  deletion, defect, failure to correct defects, delay in
                  operation or transmission, computer virus or other harmful
                  component, loss of data, communication line failure, unlawful
                  third party conduct, or theft, destruction, alteration or
                  unauthorised access to records;
                </p>
                <p className="mb-2 ms-4">
                  (ii) the accuracy, suitability or currency of any information
                  on the Website, the Services, or any of its Services related
                  products (including third party material and advertisements on
                  the Website);
                </p>
                <p className="mb-2 ms-4">
                  (iii) costs incurred as a result of you using the Website, the
                  Services or any of the products of Preassess Me Pty Ltd; and
                </p>
                <p className="mb-2 ms-4">
                  (iv) the Services or operation in respect to links which are
                  provided for your convenience.
                </p>
                <h5 className="pt-3 pb-1">10. Limitation of liability</h5>
                <p className="mb-2">
                  (a) Preassess Me Pty Ltd's total liability arising out of or
                  in connection with the Services or these Terms, however
                  arising, including under contract, tort (including
                  negligence), in equity, under statute or otherwise, will not
                  exceed the resupply of the Services to you.
                </p>
                <p className="mb-2">
                  (b) You expressly understand and agree that Preassess Me Pty
                  Ltd, its affiliates, employees, agents, contributors and
                  licensors shall not be liable to you for any direct, indirect,
                  incidental, special consequential or exemplary damages which
                  may be incurred by you, however caused and under any theory of
                  liability. This shall include, but is not limited to, any loss
                  of profit (whether incurred directly or indirectly), any loss
                  of goodwill or business reputation and any other intangible
                  loss.
                </p>
                <h5 className="pt-3 pb-1">11. Termination of Contract</h5>
                <p className="mb-2">
                  (a) The Terms will continue to apply until terminated by
                  either you or by Preassess Me Pty Ltd as set out below.
                </p>
                <p className="mb-2">
                  (b) If you want to terminate the Terms, you may do so by:
                </p>
                <p className="mb-2 ms-4">
                  (i) providing Preassess Me Pty Ltd with notice of your
                  intention to terminate; and
                </p>
                <p className="mb-2 ms-4">
                  (ii) closing your accounts for all of the services which you
                  use, where Preassess Me Pty Ltd has made this option available
                  to you.
                </p>
                <p className="mb-2">
                  Your notice should be sent, in writing, to Preassess Me Pty
                  Ltd via the 'Contact Us' link on our homepage.
                </p>
                <p className="mb-2">
                  (c) Preassess Me Pty Ltd may at any time, terminate the Terms
                  with you if:
                </p>
                <p className="mb-2 ms-4">
                  (i) you have breached any provision of the Terms or intend to
                  breach any provision;
                </p>
                <p className="mb-2 ms-4">
                  (ii) Preassess Me Pty Ltd is required to do so by law;
                </p>
                <p className="mb-2 ms-4">
                  (iii) the provision of the Services to you by Preassess Me Pty
                  Ltd is, in the opinion of Preassess Me Pty Ltd, no longer
                  commercially viable.
                </p>
                <p className="mb-2">
                  (d) Subject to local applicable laws, Preassess Me Pty Ltd
                  reserves the right to discontinue or cancel your membership at
                  any time and may suspend or deny, in its sole discretion, your
                  access to all or any portion of the Website or the Services
                  without notice if you breach any provision of the Terms or any
                  applicable law or if your conduct impacts Preassess Me Pty
                  Ltd's name or reputation or violates the rights of those of
                  another party.
                </p>
                <h5 className="pt-3 pb-1">12. Indemnity</h5>
                <p className="mb-2">
                  (a) You agree to indemnify Preassess Me Pty Ltd, its
                  affiliates, employees, agents, contributors, third party
                  content providers and licensors from and against:
                </p>
                <p className="mb-2 ms-4">
                  (i) all actions, suits, claims, demands, liabilities, costs,
                  expenses, loss and damage (including legal fees on a full
                  indemnity basis) incurred, suffered or arising out of or in
                  connection with your content;
                </p>
                <p className="mb-2 ms-4">
                  (ii) any direct or indirect consequences of you accessing,
                  using or transacting on the Website or attempts to do so;
                  and/or
                </p>
                <p className="mb-2 ms-4">(iii) any breach of the Terms.</p>
                <h5 className="pt-3 pb-1">13. Dispute Resolution</h5>
                <p className="mb-2">(a) Compulsory:</p>
                <p className="mb-2">
                  If a dispute arises out of or relates to the Terms, either
                  party may not commence any Tribunal or Court proceedings in
                  relation to the dispute, unless the following clauses have
                  been complied with (except where urgent interlocutory relief
                  is sought).
                </p>
                <p className="mb-2">(b) Notice:</p>
                <p className="mb-2">
                  A party to the Terms claiming a dispute ('Dispute') has arisen
                  under the Terms, must give written notice to the other party
                  detailing the nature of the dispute, the desired outcome and
                  the action required to settle the Dispute.
                </p>
                <p className="mb-2">(c) Resolution:</p>
                <p className="mb-2">
                  On receipt of that notice ('Notice') by that other party, the
                  parties to the Terms ('Parties') must:
                </p>
                <p className="mb-2 ms-4">
                  (i) Within 30 days of the Notice endeavour in good faith to
                  resolve the Dispute expeditiously by negotiation or such other
                  means upon which they may mutually agree;
                </p>
                <p className="mb-2 ms-4">
                  (ii) If for any reason whatsoever, 60 days after the date of
                  the Notice, the dispute has not been resolved, the Parties
                  must either agree upon selection of a mediator or request that
                  an appropriate mediator be appointed by the President of the
                  Australian Mediation Association or his or her nominee;
                </p>
                <p className="mb-2 ms-4">
                  (iii) The Parties are equally liable for the fees and
                  reasonable expenses of a mediator and the cost of the venue of
                  the mediation and without limiting the foregoing undertake to
                  pay any amounts requested by the mediator as a pre-condition
                  to the mediation commencing. The Parties must each pay their
                  own costs associated with the mediation;
                </p>
                <p className="mb-2 ms-4">
                  (iv) The mediation will be held in Brisbane, Australia.
                </p>
                <p className="mb-2">(d) Confidential:</p>
                <p className="mb-2">
                  All communications concerning negotiations made by the Parties
                  arising out of and in connection with this dispute resolution
                  clause are confidential and to the extent possible, must be
                  treated as "without prejudice" negotiations for the purpose of
                  applicable laws of evidence.
                </p>
                <p className="mb-2">(e) Termination of Mediation:</p>
                <p className="mb-2">
                  If 9 months have elapsed after the start of a mediation of the
                  Dispute and the Dispute has not been resolved, either Party
                  may ask the mediator to terminate the mediation and the
                  mediator must do so.
                </p>
                <h5 className="pt-3 pb-1">14. Venue and Jurisdiction</h5>
                <p className="mb-2">
                  (a) The Services offered by Preassess Me Pty Ltd is intended
                  to be viewed by residents of Australia. In the event of any
                  dispute arising out of or in relation to the Website, you
                  agree that the exclusive venue for resolving any dispute shall
                  be in the courts of Queensland, Australia.
                </p>
                <h5 className="pt-3 pb-1">15. Governing Law</h5>
                <p className="mb-2">
                  (a) The Terms are governed by the laws of Queensland,
                  Australia. Any dispute, controversy, proceeding or claim of
                  whatever nature arising out of or in any way relating to the
                  Terms and the rights created hereby shall be governed,
                  interpreted and construed by, under and pursuant to the laws
                  of Queensland, Australia, without reference to conflict of law
                  principles, notwithstanding mandatory rules. The validity of
                  this governing law clause is not contested. The Terms shall be
                  binding to the benefit of the parties hereto and their
                  successors and assigns.
                </p>
                <h5 className="pt-3 pb-1">16. Independent Legal Advice</h5>
                <p className="mb-2">
                  (a) Both parties confirm and declare that the provisions of
                  the Terms are fair and reasonable and both parties having
                  taken the opportunity to obtain independent legal advice and
                  declare the Terms are not against public policy on the grounds
                  of inequality or bargaining power or general grounds of
                  restraint of trade.
                </p>
                <h5 className="pt-3 pb-1">17. Severance</h5>
                <p className="mb-2">
                  (a) If any part of these Terms is found to be void or
                  unenforceable by a Court of competent jurisdiction, that part
                  shall be severed and the rest of the Terms shall remain in
                  force.
                </p>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default TermsAndConditions;
