import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { login } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import "../../styles/login.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useStyles, CssTextField } from "./classes";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";

// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Img from "../../assets/images/img11.png";
import Img1 from "../../assets/images/image1.png";

import axios from "../../lib/axios";
import HeaderTop from "../../components/headerTop/HeaderTop";
import Drawer from "../../components/layouts/Drawer";
import Footer from "../Footer/Footer_OLD";

let initialValues = {
  email: "",
  password: "",
};

let SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Email is required!")
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      "Please enter valid Email"
    ),
  password: Yup.string()
    .required("Password is required!")
    .min(8, "Min length for the password is 8.")
    .max(20, "Max length for the password is 20."),
});

function Login(props) {
  const classes = useStyles();

  let token = useSelector((state) => state.auth.token);
  let dispatch = useDispatch();

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  useEffect(() => {
    if (token) {
      dispatch(logout());
    }
    // eslint-disable-next-line
  }, []);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      const data = await axios.post("/auth/login", {
        email: e.email,
        password: e.password,
      });
      const { fullName, role, accessToken, permittedRoutes } = data.data;

      if (data.data) {
        props.login(fullName, role, accessToken, permittedRoutes);
        if (role === "admin") {
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/advisor-dashboard";
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please enter valid user name or password!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Please enter valid user name or password!",
          });
        }, 3000);
      }
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Forbidden!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Forbidden!",
          });
        }, 3000);
      }
      if (error.response.status === 406) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Not Acceptable. First, you need to confirm your account!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Not Acceptable. First, you need to confirm your account!",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please enter valid user name or password!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Please enter valid user name or password!!",
          });
        }, 3000);
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          top: 0,
          zIndex: 10,
        }}
      >
        <HeaderTop />
        <Drawer />
      </div>
      {/* <div className={classes.spdiv}></div> */}
      <Grid container>
        <Grid item md={3} lg={3} xl={3} className={classes.hide}>
          <Box className={classes.box} style={{ marginTop: 50 }}>
            <img
              className={classes.img2}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={6}
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Box>
              <Typography className={classes.headerText}>Login</Typography>
            </Box>
            <Box variant="outlined" className={classes.loginbox}>
              <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={SignUpSchema}
              >
                {({ dirty, isValid }) => {
                  return (
                    <Form style={{ padding: "10%" }}>
                      <div className={classes.displayFlex}>
                        <Typography className={classes.text}>
                          USERNAME
                        </Typography>
                        <Field
                          name="email"
                          component={CssTextField}
                          placeholder="Email *"
                          variant="outlined"
                          fullWidth
                          required
                          inputProps={{
                            style: { fontFamily: "Overpass, sans-serif" },
                          }}
                          style={{
                            backgroundColor: "white",
                            color: "black",
                          }}
                        ></Field>
                      </div>

                      <div className={classes.displayFlex}>
                        <Typography className={classes.text}>
                          PASSWORD
                        </Typography>
                        <Field
                          name="password"
                          component={CssTextField}
                          placeholder="Password *"
                          required
                          variant="outlined"
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          style={{ backgroundColor: "white", color: "black" }}
                          InputProps={{
                            style: { fontFamily: "Overpass, sans-serif" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    handleClickShowPassword(values)
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        ></Field>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          style={{
                            width: "80%",
                            height: 40,
                            fontSize: "1rem",
                            backgroundColor: "#004aad",
                            fontFamily: "Overpass, sans-serif",
                            color: "white",
                          }}
                          disabled={!dirty || !isValid}
                          type="submit"
                        >
                          Sign In
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>

            <Box className={classes.paragraph}>
              {alert.showAlert && (
                <Grid item md={12}>
                  <Alert
                    severity={alert.severity}
                    onClose={() =>
                      setAlert({
                        ...alert,
                        showAlert: false,
                      })
                    }
                  >
                    {alert.message}
                  </Alert>
                </Grid>
              )}
              <Typography className={classes.paragraph1}>
                Don't have an account? <Link to="/signup">Sign up now</Link>
              </Typography>
              <Typography className={classes.paragraph1}>
                By signing up and creating an account, you agree to
              </Typography>
              <Typography className={classes.paragraph1}>the&nbsp;
                <Link to="/terms-and-conditions">PreAssessMe Terms</Link>&nbsp;&&nbsp;
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Typography>
              <Typography className={classes.paragraph1}>
                <Link to="/forgot-password">Forgot password?</Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} lg={4} xl={3} className={classes.hide}>
          <Box className={classes.box}>
            <img
              className={classes.img1}
              src={Img}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default connect(null, { login })(Login);
