import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";

const resetArray = [
  { name: "eatingDisorderDescribeSymptoms", type: "" },
  { name: "eatingDisorderStartDate", type: "" },
  { name: "eatingDisorderTimePeriod", type: "" },
  { name: "eatingDisorderReasons", type: "" },
  { name: "eatingDisorderFactors", type: "" },
  { name: "eatingDisorderThoughts", type: "" },
  { name: "eatingDisorderWhatTriggered", type: "" },
  { name: "eatingDisorderSymptomsCommenced", type: "" },
  { name: "eatingDisorderReoccurrencesOfCondition", type: "" },
  { name: "eatingDisorderHowManyReoccurrences", type: "" },
  { name: "eatingDisorderTreatment", type: [] },
  { name: "eatingDisorderCounsellingWhenStart", type: "" },
  { name: "eatingDisorderCounsellingEndDate", type: "" },
  { name: "eatingDisorderCounsellingTherapyDetails", type: "" },
  { name: "eatingDisorderPsychotherapyWhenStart", type: "" },
  { name: "eatingDisorderPsychotherapyEndDate", type: "" },
  { name: "eatingDisorderPsychotherapyTherapyDetails", type: "" },
  { name: "eatingDisorderMedicationDose", type: "" },
  { name: "eatingDisorderMedicationEndDate", type: "" },
  { name: "eatingDisorderConditionMedication", type: "" },
  { name: "eatingDisorderDosageOften", type: "" },
  { name: "eatingDisorderDutiesRestrictionCondition", type: "" },
  { name: "eatingDisorderWorkRestriction", type: "" },
  { name: "eatingDisorderLifeRestriction", type: "" },
  { name: "eatingDisorderDetails", type: "" },
];

export default function EatingDisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Eating Disorder including Anorexia, Nervosa or Bulimia",
            value: "eatingDisorder",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("eatingDisorder") && (
        <AnswerBox
          label="Describe your symptoms"
          name="eatingDisorderDescribeSymptoms"
          isSubForm
        />
      )}

      {values.eatingDisorderDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="eatingDisorderStartDate"
          isSubForm
        />
      )}
      {values.eatingDisorderStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="eatingDisorderTimePeriod"
          isSubForm
        />
      )}
      {values.eatingDisorderTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause of these details?"
          name="eatingDisorderReasons"
          isSubForm
        />
      )}
      {values.eatingDisorderReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="eatingDisorderFactors"
          isSubForm
        />
      )}
      {(values.eatingDisorderReasons === "false" ||
        values.eatingDisorderFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts, threatened to self-harm or
          engaged in self-harm?"
          name="eatingDisorderThoughts"
          isSubForm
        />
      )}
      {values.eatingDisorderThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="eatingDisorderWhatTriggered"
          isSubForm
        />
      )}
      {(values.eatingDisorderThoughts === "false" ||
        values.eatingDisorderWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="eatingDisorderSymptomsCommenced"
          isSubForm
        />
      )}
      {values.eatingDisorderSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="eatingDisorderReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.eatingDisorderReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="eatingDisorderHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.eatingDisorderReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="eatingDisorderTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.eatingDisorderTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="eatingDisorderCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="eatingDisorderCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="eatingDisorderCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.eatingDisorderReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="eatingDisorderTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}

      {values.eatingDisorderTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="eatingDisorderPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="eatingDisorderPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="eatingDisorderPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.eatingDisorderReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="eatingDisorderTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.eatingDisorderTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="eatingDisorderMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="eatingDisorderMedicationEndDate"
            isSubForm
          />
          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="eatingDisorderConditionMedication"
            isSubForm
          />
          {values.eatingDisorderConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="eatingDisorderDosageOften"
              isSubForm
            />
          )}
          {(values.eatingDisorderDosageOften ||
            values.eatingDisorderConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="eatingDisorderDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.eatingDisorderDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="eatingDisorderWorkRestriction"
              isSubForm
            />
          )}

          {(values.eatingDisorderDutiesRestrictionCondition === "false" ||
            values.eatingDisorderWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind"
              name="eatingDisorderLifeRestriction"
              isSubForm
            />
          )}
          {values.eatingDisorderLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="eatingDisorderDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
