import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const resetArray = [
  { name: "JointBoneOrMuscleConditions", type: "" },
  { name: "JointBoneOrMuscleTreatment", type: "" },
  { name: "JointBoneOrMuscleOngoingTreatmentOrPain", type: "" },
  { name: "JointBoneOrMuscleDetails", type: "" },
];
export default function SubFormSix({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Joint/bone or muscle disease",
            value: "jointBoneOrMuscle",
          },
        ]}
        name="subFormsOfFourteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFourteen.includes("jointBoneOrMuscle") && (
        <AnswerBox
          label="What was the condition?"
          name="JointBoneOrMuscleConditions"
          isSubForm
        />
      )}
      {values.subFormsOfFourteen.includes("jointBoneOrMuscle") &&
        values.JointBoneOrMuscleConditions && (
          <AnswerBox
            label="What was the treatment?"
            name="JointBoneOrMuscleTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("jointBoneOrMuscle") &&
        values.JointBoneOrMuscleTreatment && (
          <RadioMulti
            question="Do you have ongoing treatment or pain from this condition?"
            name={"JointBoneOrMuscleOngoingTreatmentOrPain"}
            items={items}
            isSubForm
          />
        )}

      {values.subFormsOfFourteen.includes("jointBoneOrMuscle") &&
        values.JointBoneOrMuscleOngoingTreatmentOrPain === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="JointBoneOrMuscleDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
