import React from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useStyles } from "./classes";
import { Typography } from "@material-ui/core";

export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container justifycontent="space-around" style={{ padding: 10 }}>
          <Grid item alignself="center">
            <Paper style={{ padding: 40 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "justify",
                  fontSize: "2rem",
                }}
                className={classes.paragraph}
              >
                Preparation Email template for client
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                {"Dear <CLIENT>,"}
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                This is just a short, courtesy email to let you know you will
                receive a SMS from Preassessme which will contain your personal
                link to complete a thorough health and pursuits questionnaire.
                To ensure we can obtain insurance terms that are in your best
                interest we ask you to complete the questionnaire as soon as
                possible. Your information will be kept secure and used for
                preassessment purposes only. It is important to note this is not
                an insurance application, but answers collected may be used in
                conjunction with your application.
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                {
                  "<CLIENT>, if you are having trouble completing the questionnaire or have any questions please contact us."
                }
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                Regards,
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
