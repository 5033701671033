import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const when = [
  {
    label: "less than 2 years ago",
    value: "lessThan2YearsAgo",
  },
  {
    label: "More than 2 years ago",
    value: "MoreThan2YearsAgo",
  },
];
const time = [
  {
    label: "No time off work",
    value: "noTime",
  },
  {
    label: "Less than 2 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "More than 2 weeks",
    value: "MoreThan2Weeks",
  },
];
const resetArray = [
  { name: "whenLastSymptoms", type: "" },
  { name: "howLongChronicFatigue", type: "" },
  { name: "treatment", type: "" },
  { name: "ongoingSymptoms", type: "" },
  { name: "timeOffWork", type: "" },
];
export default function SubFormSix({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Chronic Fatigue Syndrome", value: "chronic" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormThirteen.includes("chronic") && (
        <RadioMulti
          question="When did you last experience symptoms?"
          name={"whenLastSymptoms"}
          items={when}
          isSubForm
        />
      )}
      {(values.whenLastSymptoms === "lessThan2YearsAgo" ||
        values.whenLastSymptoms === "MoreThan2YearsAgo") &&
        values.subFormsOfFormThirteen.includes("chronic") && (
          <AnswerBox
            label="How long have you had Chronic fatigue syndrome?"
            name="howLongChronicFatigue"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("chronic") &&
        values.howLongChronicFatigue && (
          <AnswerBox
            label="What is the treatment you are receiving?"
            name="treatment"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("chronic") &&
        values.treatment && (
          <AnswerBox
            label="What are your ongoing symptoms?"
            name="ongoingSymptoms"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("chronic") &&
        values.ongoingSymptoms && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"timeOffWork"}
            items={time}
            isSubForm
          />
        )}
    </Fragment>
  );
}
