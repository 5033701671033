import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableRow1 from "./InvoiceTableRow1";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const tableRowsCount = 2;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#000",
  },
  bot: {
    marginBottom: 10,
  },
});

const InvoiceItemsTable = ({ invoice, user }) => (
  <>
    <View style={styles.tableContainer}>
      <InvoiceTableRow1 style={styles.bot} user={user} />
    </View>

    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={invoice.items} />
      <InvoiceTableBlankSpace rowsCount={tableRowsCount} />
      <InvoiceTableFooter items={invoice.items} />
    </View>
  </>
);

export default InvoiceItemsTable;
