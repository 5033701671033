import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import pamLogo from "../../assets/preAssesLogo.png";
import officeTeam from "../../assets/images/office-team.png";
import "./MobileViewport.css";

const MobileViewport = () => {
  return (
    <div id="mobile-viewport">
      <Container fluid>
        <Row>
          <Col xs={12} className="d-flex justify-content-center bg-light">
            <img
              id="mobile-pam-logo"
              src={pamLogo}
              alt="PreAssessMe logo"
              className="my-3"
            ></img>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} className="d-flex flex-column">
            <h2 className="my-4">Thanks for visiting PreAssessMe!</h2>
            <p>
              We are working on our mobile experience of the Insurance Advice
              Tool.
            </p>
            <p>
              In the meantime, please visit us on a laptop or PC to access the
              app.
            </p>
            <img
              src={officeTeam}
              alt="Office team image"
              className="mt-5"
            ></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MobileViewport;
