import { Grid } from "@material-ui/core";
import React from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const questions = [
  { name: "powerboatRacing1", label: "How often do you race powerboats?" },
  {
    name: "powerboatRacing2",
    label: "How many times have you raced powerboats in the last 12 months?",
  },
  {
    name: "powerboatRacing3",
    label:
      "What Qualifications, certificates,licences, or club memberships do you have?",
  },
  {
    name: "powerboatRacing4",
    label: "How long have you been racing powerboats?",
  },
  { name: "powerboatRacing5", label: "Where do you race? (locations)" },
  { name: "powerboatRacing6", label: "Do you receive payment for racing?" },
  { name: "powerboatRacing7", label: "What is the maximum speed you reach?" },
  {
    name: "powerboatRacing8",
    label: "Are all recognized safety measures and precautions followed?",
  },
  { name: "powerboatRacing9", label: "What size engine do you race?" },
  {
    name: "powerboatRacing10",
    label: "Have you ever been involved in an accident whilst racing?",
  },
];

const resetArray = [
  { name: "powerboatRacing1", type: "" },
  { name: "powerboatRacing2", type: "" },
  { name: "powerboatRacing3", type: "" },
  { name: "powerboatRacing4", type: "" },
  { name: "powerboatRacing5", type: "" },
  { name: "powerboatRacing6", type: "" },
  { name: "powerboatRacing7", type: "" },
  { name: "powerboatRacing8", type: "" },
  { name: "powerboatRacing9", type: "" },
  { name: "powerboatRacing10", type: "" },
];

export default function Powerboat({ values, setFieldValue, setFieldTouched  }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Grid container md={12} sm={12} xs={12}>
      <CheckBox
        list={[{ label: "Powerboat racing", value: "powerboatRacing" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("powerboatRacing") &&
        questions.map((question) => (
          <AnswerBox
            label={question.label}
            name={question.name}
            key={question.name}
            isSubForm
          />
        ))}
    </Grid>
  );
}
