import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const NeurologicalTab = (props) => {
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-neurological-tabs"
          defaultActiveKey="#neurological-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formSeven.symptoms.map((symptom, index) => (
                <ListGroup.Item action href={`#neurological-${index + 1}`}>
                  {formatFormData(symptom)
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    formatFormData(symptom)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formSeven.symptoms.map((symptom, index) => (
                <Tab.Pane eventKey={`#neurological-${index + 1}`}>
                  <Table
                    id="form-view-neurological-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {symptom === "epilepsymal" ? (
                      // Responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            How many attacks do you suffer annually on average?
                          </td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven.annualAttakcs
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>When was your last attack?</td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven.lastAttack
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Specify the cause of epilepsy</td>
                          <td>
                            {props.formData.formSeven
                              ? props.formData.formSeven.cause
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven.dutyRestrictions === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formSeven.dutyRestrictions ? (
                          <tr>
                            <td>Duty restriction details</td>
                            <td>{props.formData.formSeven.details}</td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    ) : symptom === "epilepsygrandmal" ? (
                      <tbody>
                        <tr>
                          <td>
                            How many attacks do you suffer annually on average?
                          </td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven
                                .epilepsyGradMalAnnualAttakcs
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>When was your last attack?</td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven.epilepsyGradMalLastAttack
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Specify the cause of epilepsy</td>
                          <td>
                            {props.formData.formSeven
                              ? props.formData.formSeven.epilepsyGradMalCause
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .epilepsyGradMalDutyRestrictions === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          {props.formData.formSeven
                            .epilepsyGradMalDutyRestrictions ? (
                            <>
                              <td>Duty restriction details</td>
                              <td>
                                {
                                  props.formData.formSeven
                                    .epilepsyGradMalDetails
                                }
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </tbody>
                    ) : symptom === "epilepsyother" ? (
                      <tbody>
                        <tr>
                          <td>What treatment was provided?</td>
                          <td>
                            {props.formData.formSeven.epilepsyOtherTreatment}
                          </td>
                        </tr>
                        <tr>
                          <td>When did this occur?</td>
                          <td>
                            {props.formData.formSeven.epilespsyOtherstartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .epilepsyOtherRestrictedDuties === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>
                            {props.formData.formSeven.epilepsyOtherDetails}
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "epilepsyunknown" ? (
                      <tbody>
                        <tr>
                          <td>What treatment was provided?</td>
                          <td>
                            {props.formData.formSeven.epilepsyUnknownTreatment}
                          </td>
                        </tr>
                        <tr>
                          <td>When did this occur?</td>
                          <td>
                            {props.formData.formSeven.epilepsyUnknownstartDate}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .epilepsyUnknownRestrictedDuties === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>
                            {props.formData.formSeven.epilepsyUnknownDetails}
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "fitsofanykind" ? (
                      <tbody>
                        <tr>
                          <td>Were your fits restricted to your childhood?</td>
                          <td>
                            {props.formData.formSeven.fitsOfAnyKindChildhood}
                          </td>
                        </tr>
                        <tr>
                          <td>What treatment was provided?</td>
                          <td>
                            {props.formData.formSeven.fitsOfAnyKindTreatment}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .fitsOfAnyKindRestrictedDuties === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>
                            {props.formData.formSeven.fitsOfAnyKindDetails}
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "paralysis" ? (
                      <tbody>
                        <tr>
                          <td>What were your symptoms?</td>
                          <td>{props.formData.formSeven.paralysisSymptoms}</td>
                        </tr>
                        <tr>
                          <td>What treatment was provided?</td>
                          <td>{props.formData.formSeven.paralysisTreatment}</td>
                        </tr>
                        <tr>
                          <td>When did this occur?</td>
                          <td>{props.formData.formSeven.paralysisOccur}</td>
                        </tr>
                        <tr>
                          <td>
                            Are you in any way restricted in the occupational
                            duties or activities of daily living from this
                            condition?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .paralysisOccupationRestriction === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>{props.formData.formSeven.paralysisDetails}</td>
                        </tr>
                      </tbody>
                    ) : symptom === "migraines" ? (
                      <tbody>
                        <tr>
                          <td>When did you first experience Migraines?</td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven.migraineStart
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            In the last 2 years has your doctor recommended that
                            you have further investigations?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .migraineFurtherInvestigation === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>{props.formData.formSeven.migraineDetails}</td>
                        </tr>
                      </tbody>
                    ) : symptom === "tinnitus" ? (
                      <tbody>
                        <tr>
                          <td>When did you last experience symptoms?</td>
                          <td>{props.formData.formSeven.tinnitusStart}</td>
                        </tr>
                        <tr>
                          <td>Symptom details</td>
                          <td>{props.formData.formSeven.tinnitusDetails}</td>
                        </tr>
                        <tr>
                          <td>Do you suffer any form of deafness?</td>
                          <td>
                            {props.formData.formSeven.tinnitusDeafness === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Deafness details</td>
                          <td>
                            {props.formData.formSeven.tinnitusDeafnessDetails}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            In the last 2 years has your doctor recommended you
                            have further investigations completed?
                          </td>
                          <td>
                            {props.formData.formSeven.tinnitusDoctorRecommended === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Please provide details of when the tests were
                            conducted and the outcome of the investigation.
                          </td>
                          <td>
                            {
                              props.formData.formSeven
                                .tinnitusDetailsInvestigation
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Does this condition restrict your activities or
                            daily life in any way?
                          </td>
                          <td>
                            {props.formData.formSeven.tinnitusDailyLife === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Restriction details</td>
                          <td>
                            {props.formData.formSeven.tinnitusDailyLifeDetails}
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "dizziness" ? (
                      <tbody>
                        <tr>
                          <td>What were your symptoms?</td>
                          <td>{props.formData.formSeven.dizzinessSymptoms}</td>
                        </tr>
                        <tr>
                          <td>What treatment was provided?</td>
                          <td>{props.formData.formSeven.dizzinessTreatment}</td>
                        </tr>
                        <tr>
                          <td>Treatment details</td>
                          <td>{props.formData.formSeven.dizzinessDetails}</td>
                        </tr>
                      </tbody>
                    ) : symptom === "recurrentHeadaches" ? (
                      <tbody>
                        <tr>
                          <td>When did you first begin to suffer headaches?</td>
                          <td>
                            {formatFormData(
                              props.formData.formSeven.recurrentHeadachesStart
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Provide details when this commenced and any external
                            factors
                          </td>
                          <td>
                            {props.formData.formSeven.recurrentHeadachesDetails}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are they becoming more frequent or more severe?
                          </td>
                          <td>
                            {props.formData.formSeven.recurrentHeadachesStatus}
                          </td>
                        </tr>
                        <tr>
                          <td>Frequency details</td>
                          <td>
                            {
                              props.formData.formSeven
                                .recurrentHeadachesStatusDetails
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            In the last 2 years has your doctor recommended you
                            have further investigations completed?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .recurrentHeadachesDoctorRecommended === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Investigation details</td>
                          <td>
                            {
                              props.formData.formSeven
                                .recurrentHeadachesDetailsInvestigation
                            }
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "multipleSclerosis" ? (
                      <tbody>
                        <tr>
                          <td>
                            Have you had a definitive diagnosis of multiple
                            sclerosis or had symptoms that mean a diagnosis may
                            be possible?
                          </td>
                          <td>
                            {props.formData.formSeven.multipleSclerosisSymptoms === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Details</td>
                          <td>
                            {
                              props.formData.formSeven
                                .multipleSclerosisSymptomsDetails
                            }
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "neurologicalDisorder" ? (
                      <tbody>
                        <tr>
                          <td>Please provide details of the diagnosis</td>
                          <td>
                            {
                              props.formData.formSeven
                                .neurologicalDisorderDiagnosisDetails
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>What were your symptoms?</td>
                          <td>
                            {
                              props.formData.formSeven
                                .neurologicalDisorderSymptoms
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Were you required to be off work? If yes, please
                            provide the length of time.
                          </td>
                          <td>
                            {
                              props.formData.formSeven
                                .neurologicalDisorderWorkStatus
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Does this condition restrict your activities or
                            daily life in any way?
                          </td>
                          <td>
                            {props.formData.formSeven
                              .neurologicalDisorderDailyLife === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Restriction details</td>
                          <td>
                            {
                              props.formData.formSeven
                                .neurologicalDisorderDetails
                            }
                          </td>
                        </tr>
                      </tbody>
                    ) : symptom === "tremor" ? (
                      <tbody>
                        <tr>
                          <td>Please provide details of the diagnosis</td>
                          <td>
                            {props.formData.formSeven.tremorDiagnosisDetails}
                          </td>
                        </tr>
                        <tr>
                          <td>What were your symptoms?</td>
                          <td>{props.formData.formSeven.tremorSymptoms}</td>
                        </tr>
                        <tr>
                          <td>
                            Were you required to be off work? If yes, please
                            provide the length of time
                          </td>
                          <td>{props.formData.formSeven.tremorWorkStatus}</td>
                        </tr>
                        <tr>
                          <td>
                            Does this condition restrict your activities or
                            daily life in any way?
                          </td>
                          <td>
                            {props.formData.formSeven.tremorDailyLife === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formSeven.tremorDailyLife ? (
                          <tr>
                            <td>Restriction details</td>
                            <td>{props.formData.formSeven.tremorDetails}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : (
                      <></>
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default NeurologicalTab;
