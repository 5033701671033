import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";

const resetArray = [
  { name: "depressiveIllnessDescribeSymptoms", type: "" },
  { name: "depressiveIllnessStartDate", type: "" },
  { name: "depressiveIllnessTimePeriod", type: "" },
  { name: "depressiveIllnessReasons", type: "" },
  { name: "depressiveIllnessFactors", type: "" },
  { name: "depressiveIllnessThoughts", type: "" },
  { name: "depressiveIllnessWhatTriggered", type: "" },
  { name: "depressiveIllnessSymptomsCommenced", type: "" },
  { name: "depressiveIllnessReoccurrencesOfCondition", type: "" },
  { name: "depressiveIllnessHowManyReoccurrences", type: "" },
  { name: "depressiveIllnessTreatment", type: [] },
  { name: "depressiveIllnessCounsellingWhenStart", type: "" },
  { name: "depressiveIllnessCounsellingEndDate", type: "" },
  { name: "depressiveIllnessCounsellingTherapyDetails", type: "" },
  { name: "depressiveIllnessPsychotherapyWhenStart", type: "" },
  { name: "depressiveIllnessPsychotherapyEndDate", type: "" },
  { name: "depressiveIllnessPsychotherapyTherapyDetails", type: "" },
  { name: "depressiveIllnessMedicationDose", type: "" },
  { name: "depressiveIllnessMedicationEndDate", type: "" },
  { name: "depressiveIllnessConditionMedication", type: "" },
  { name: "depressiveIllnessDosageOften", type: "" },
  { name: "depressiveIllnessDutiesRestrictionCondition", type: "" },
  { name: "depressiveIllnessWorkRestriction", type: "" },
  { name: "depressiveIllnessLifeRestriction", type: "" },
  { name: "depressiveIllnessDetails", type: "" },
];

export default function DepressiveIllness({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label:
              "Manic Depressive illness, major depression, bipolar disorder",
            value: "depressiveIllness",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("depressiveIllness") && (
        <AnswerBox
          label="Describe your symptoms"
          name="depressiveIllnessDescribeSymptoms"
          isSubForm
        />
      )}

      {values.depressiveIllnessDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="depressiveIllnessStartDate"
          isSubForm
        />
      )}
      {values.depressiveIllnessStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="depressiveIllnessTimePeriod"
          isSubForm
        />
      )}
      {values.depressiveIllnessTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause of these details?"
          name="depressiveIllnessReasons"
          isSubForm
        />
      )}
      {values.depressiveIllnessReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors"
          name="depressiveIllnessFactors"
          isSubForm
        />
      )}
      {(values.depressiveIllnessReasons === "false" ||
        values.depressiveIllnessFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts, threatened to self-harm or
          engaged in self-harm?"
          name="depressiveIllnessThoughts"
          isSubForm
        />
      )}
      {values.depressiveIllnessThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self-harm
          threats or actual, provide details, or how often and the last occurrence of
          them."
          name="depressiveIllnessWhatTriggered"
          isSubForm
        />
      )}
      {(values.depressiveIllnessThoughts === "false" ||
        values.depressiveIllnessWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="depressiveIllnessSymptomsCommenced"
          isSubForm
        />
      )}
      {values.depressiveIllnessSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="depressiveIllnessReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.depressiveIllnessReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="depressiveIllnessHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.depressiveIllnessReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="depressiveIllnessTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.depressiveIllnessTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="depressiveIllnessCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressiveIllnessCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="depressiveIllnessCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.depressiveIllnessReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="depressiveIllnessTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}

      {values.depressiveIllnessTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="depressiveIllnessPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressiveIllnessPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="depressiveIllnessPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.depressiveIllnessReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="depressiveIllnessTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.depressiveIllnessTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="depressiveIllnessMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="depressiveIllnessMedicationEndDate"
            isSubForm
          />

          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="depressiveIllnessConditionMedication"
            isSubForm
          />

          {values.depressiveIllnessConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="depressiveIllnessDosageOften"
              isSubForm
            />
          )}
          {(values.depressiveIllnessDosageOften ||
            values.depressiveIllnessConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="depressiveIllnessDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.depressiveIllnessDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="depressiveIllnessWorkRestriction"
              isSubForm
            />
          )}

          {(values.depressiveIllnessDutiesRestrictionCondition === "false" ||
            values.depressiveIllnessWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of
any kind?"
              name="depressiveIllnessLifeRestriction"
              isSubForm
            />
          )}
          {values.depressiveIllnessLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="depressiveIllnessDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
