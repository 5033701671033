import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const items = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I don't know", value: "I don't know" },
];
const resetArray = [
  { name: "diagHaemochromatosis", type: "" },
  { name: "isIronTestNormal", type: "" },
  { name: "isVenesection", type: "" },
];
export default function FormHaemochromatosis({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Haemochromatosis", value: "Haemochromatosis" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Haemochromatosis") && (
        <Fragment>
          <AnswerBox
            name="diagHaemochromatosis"
            label="When were you diagnosed with Haemochromatosis?"
            isSubForm
          />
          <RadioMulti
            question="Has your doctor confirmed that your latest iron studies and liver function tests were normal?"
            name="isIronTestNormal"
            items={items}
            isSubForm
          />
          <YesNoQuestion
            question="Is your haemochromatosis treated with venesection (removal of blood) and are you compliant with this treatment?"
            name="isVenesection"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
