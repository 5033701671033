import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "diagLeukaemia", type: "" },
  { name: "treatLeukaemia", type: "" },
  { name: "isOngTreatLeukaemia", type: "" },
  { name: "detailsLeukaemia", type: "" },
];
export default function FormLeukaemia({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Leukaemia", value: "Leukaemia" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Leukaemia") && (
        <Fragment>
          <AnswerBox
            name="diagLeukaemia"
            label="When was leukaemia diagnosed?"
            isSubForm
          />
          <AnswerBox
            name="treatLeukaemia"
            label="What was the treatment that was recommended by a medical professional?"
            isSubForm
          />
          <YesNoQuestion
            question="Is there any ongoing treatment for Leukaemia?"
            name="isOngTreatLeukaemia"
            isSubForm
          />
          {values.isOngTreatLeukaemia === "true" && (
            <AnswerBox
              name="detailsLeukaemia"
              label="Please provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
