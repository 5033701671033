import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const side = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
  {
    label: "Both",
    value: "both",
  },
];
const OsteoarthritisJointsItems = [
  {
    label: "Hands",
    value: "hands",
  },
  { label: "Wrists", value: "wrists" },
  {
    label: "Small joints of the feet",
    value: "smallJointsOfTheFeet",
  },
  {
    label: "Shoulders",
    value: "shoulders",
  },
  {
    label: "Elbows",
    value: "elbows",
  },
  {
    label: "Knees",
    value: "knees",
  },
  {
    label: "Ankles",
    value: "ankles",
  },
];
const resetArray = [
  { name: "OsteoarthritisJointsList", type: "" },
  { name: "OsteoarthritisWhichSide", type: "" },
  { name: "OsteoarthritisHowLongArthritis", type: "" },
  { name: "OsteoarthritisTreatment", type: "" },
  { name: "OsteoarthritisOngoingSymptoms", type: "" },
];

export default function SubFormOne({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Osteoarthritis", value: "osteoarthritis" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormThirteen.includes("osteoarthritis") && (
        <CheckBox
          question="Which joints were affected?"
          list={OsteoarthritisJointsItems}
          name="OsteoarthritisJointsList"
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("osteoarthritis") && (
        <RadioMulti
          question="Which side?"
          name={"OsteoarthritisWhichSide"}
          items={side}
          isSubForm
        />
      )}
      {values.OsteoarthritisWhichSide &&
        values.subFormsOfFormThirteen.includes("osteoarthritis") && (
          <AnswerBox
            label="How long have you had arthritis?"
            name="OsteoarthritisHowLongArthritis"
            isSubForm
          />
        )}
      {values.OsteoarthritisHowLongArthritis &&
        values.subFormsOfFormThirteen.includes("osteoarthritis") && (
          <AnswerBox
            label="What is the treatment you are receiving?"
            name="OsteoarthritisTreatment"
            isSubForm
          />
        )}
      {values.OsteoarthritisTreatment &&
        values.subFormsOfFormThirteen.includes("osteoarthritis") && (
          <AnswerBox
            label="What are your ongoing symptoms?"
            name="OsteoarthritisOngoingSymptoms"
            isSubForm
          />
        )}
    </Fragment>
  );
}
