import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioGroup from "../RadioMulti";

const recurrentHeadachesStart = [
  {
    label: "Less than 6 months ago",
    value: "lessthansix",
  },
  {
    label: "6-12 months ago",
    value: "sixtotwelve",
  },
  {
    label: "More than 12 months ago",
    value: "morethantwelve",
  },
];
const resetArray = [
  { name: "recurrentHeadachesStart", type: "" },
  { name: "recurrentHeadachesDetails", type: "" },
  { name: "recurrentHeadachesStatus", type: "" },
  { name: "recurrentHeadachesStatusDetails", type: "" },
  { name: "recurrentHeadachesDoctorRecommended", type: "" },
  { name: "recurrentHeadachesDetailsInvestigation", type: "" },
  { name: "recurrentHeadachesDailyLife", type: "" },
];

export default function RecurrentHeadaches({ values, setFieldValue, setFieldTouched  }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Recurrent Headaches", value: "recurrentHeadaches" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("recurrentHeadaches") && (
        <RadioGroup
          question="When did you first begin to suffer headaches?"
          name="recurrentHeadachesStart"
          items={recurrentHeadachesStart}
          isSubForm
        />
      )}
      {values.recurrentHeadachesStart === "lessthansix" && (
        <AnswerBox
          label="Provide details when this commenced and any external factors"
          name="recurrentHeadachesDetails"
          isSubForm
        />
      )}
      {(values.recurrentHeadachesDetails ||
        values.recurrentHeadachesStart === "sixtotwelve" ||
        values.recurrentHeadachesStart === "morethantwelve") && (
        <YesNoQuestion
          question="Are they becoming more frequent or more severe?"
          name="recurrentHeadachesStatus"
          isSubForm
        />
      )}
      {values.recurrentHeadachesStatus === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="recurrentHeadachesStatusDetails"
          isSubForm
        />
      )}
      {(values.recurrentHeadachesStatusDetails ||
        values.recurrentHeadachesStatus === "false") && (
        <YesNoQuestion
          question="In the last 2 years has your doctor recommended you have further investigations completed? Eg. CT scan"
          name="recurrentHeadachesDoctorRecommended"
          isSubForm
        />
      )}
      {values.recurrentHeadachesDoctorRecommended === "true" && (
        <AnswerBox
          label="Please provide details of when the tests were conducted and the outcome of the investigation."
          name="recurrentHeadachesDetailsInvestigation"
          isSubForm
        />
      )}
    </Fragment>
  );
}
