import React, { Fragment } from "react";
import { TableCell, Chip } from "@material-ui/core";
import moment from "moment";

import ReusableTable from "../../components/layouts/ReusableTable";

const headers = [
  {
    text: "Package Names",
    value: "email",
  },
  {
    text: "Paid Amount",
    value: "arNumber",
  },
  {
    text: "Paypal Ref",
    value: "paypal ref",
  },
  {
    text: "Transaction Date",
    value: "Date",
  },
  {
    text: "Status",
    value: "Date",
  },
];
export default function paypalView(props) {
  const TableB = ({ item, changeStatus, classes }) => {
    return (
      <Fragment>
        <Fragment>
          <TableCell key="name" style={{ fontFamily: "Overpass, sans-serif" }}>
            {" "}
            {item.items.map((data) => (
              <p>{data.description},</p>
            ))}
          </TableCell>
          <TableCell key="price" style={{ fontFamily: "Overpass, sans-serif" }}>
            ${item.amount}.00
          </TableCell>
          <TableCell
            key="paymentId"
            style={{ fontFamily: "Overpass, sans-serif" }}
          >
            {item.paymentId}
          </TableCell>
          <TableCell
            key="createdAt"
            style={{ fontFamily: "Overpass, sans-serif" }}
          >{`${moment(item.createdAt).format(
            "DD-MM-YYYY HH:mm:ss"
          )}`}</TableCell>
          {item.status ? (
            <TableCell
              key="type"
              style={{ fontFamily: "Overpass, sans-serif" }}
            >
              {" "}
              <Chip label="Sucsses" className={classes.green} />
            </TableCell>
          ) : (
            <TableCell
              key="type"
              style={{ fontFamily: "Overpass, sans-serif" }}
            >
              <Chip label="Fail" className={classes.red} />
            </TableCell>
          )}
        </Fragment>
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title="View My Payment History"
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl="/paypal/me" //change
      // statusChangeUrl="/paypal/update-status"
      {...props}
    />
  );
}
