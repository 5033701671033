import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  TextField,
  Typography,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import axios from '../../lib/axios';
export default function ReusableTable({
  headers,
  TableB,
  fetchDataUrl,
  fetchDataUrl2,
  statusChangeUrl,
  updatePath,
  title,
  imgs,
  view,
  ...props
}) {
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');

  const [filteredItems, setFilteredItems] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    // const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };

  //alerting
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [items, setData] = useState([]);

  //use effect
  useEffect(() => {
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load data to the table
  const loadData = async () => {
    try {
      if (fetchDataUrl) {
        const { data } = await axios.get(fetchDataUrl);

        setFilteredItems(data);

        setData(data);
      }
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Data loading failed!',
        });

        setOpen(false);
      }, 3000);
    }
  };

  // activate and deactivate status
  const changeStatus = async (id, status) => {
    try {
      await axios.put(statusChangeUrl, {
        id,
        status: !status,
      });
      loadData();
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Status changing failed!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Status changing failed!',
        });

        setOpen(false);
      }, 3000);
    }
  };

  //update
  const onUpdate = (id) => {
    props.history.push(updatePath, { update: true, id });
  };

  const onView = (id) => {
    props.history.push(view, { id });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    green: {
      backgroundColor: 'green',
      color: 'white',
    },
    red: {
      backgroundColor: 'red',
      color: 'white',
    },
    yellow: {
      backgroundColor: '#0d0e0e',
      color: 'white',
      fontFamily: 'Overpass, sans-serif',
    },
    grey: {
      backgroundColor: '#829192',
      color: 'white',
      fontFamily: 'Overpass, sans-serif',
    },
    black: {
      backgroundColor: '#0d0e0e',
      color: 'white',
      fontFamily: 'Overpass, sans-serif',
    },
    blue: {
      backgroundColor: '#004AAD',
      color: 'white',
      fontFamily: 'Overpass, sans-serif',
    },
    textCenter: {
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  const [searchText, setSearchText] = useState('');

  //search
  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);
    if (text) {
      // eslint-disable-next-line
      const result = items.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(items);
    }
  };

  const updateStatus = async (id, status) => {
    try {
      await axios.put(`/purchaseOrder/${id}`, {
        status,
      });
      loadData();
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Status changing failed!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Status changing failed!',
        });

        setOpen(false);
      }, 3000);
    }
  };

  return (
    <Grid container>
      <Grid item className={classes.root}>
        <Card>
          <Typography
            style={{
              fontFamily: 'holiday',
              fontSize: '2rem',
              marginLeft: 15,
              marginTop: 15,
            }}
          >
            {' '}
            {title}
          </Typography>

          <CardContent>
            <TextField
              label='Search...'
              variant='outlined'
              inputProps={{
                style: { fontFamily: 'Overpass, sans-serif' },
              }}
              className={classes.marginBottom}
              onChange={onSearch}
              value={searchText}
            />
            <TableContainer
              component={Paper}
              style={{ fontFamily: 'Overpass, sans-serif' }}
            >
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell
                        key={i}
                        sortDirection={orderDirection}
                        style={{
                          fontFamily: 'Overpass, sans-serif',
                          fontWeight: 'bold',
                        }}
                      >
                        <TableSortLabel
                          active={header.value === valueToOrderBy}
                          onClick={createSortHandler(header.value)}
                          direction={orderDirection}
                        >
                          {header.text.toUpperCase()}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ fontFamily: 'Overpass, sans-serif' }}>
                  {(rowsPerPage > 0
                    ? sortedRowInformation(
                        filteredItems,
                        getComparator(orderDirection, valueToOrderBy)
                      ).slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : sortedRowInformation(
                        filteredItems,
                        getComparator(orderDirection, valueToOrderBy)
                      )
                  ).map((item, i) => (
                    <TableRow
                      key={i}
                      style={{ fontFamily: 'Overpass, sans-serif' }}
                    >
                      <TableB
                        item={item}
                        changeStatus={changeStatus}
                        classes={classes}
                        onUpdate={onUpdate}
                        onView={onView}
                        loadData={loadData}
                        updateStatus={updateStatus}
                        setAlert={setAlert}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
