import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 25,
  },
  text: {
    padding: theme.spacing(1),
    fontFamily: "Overpass, sans-serif",
    textAlign: "center",
    fontSize: "1.6rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  text1: {
    padding: theme.spacing(1.5),
    textAlign: "strat",
    color: "#1a75ff",
    fontSize: "1.5rem",
    fontFamily: "Overpass, sans-serif",
    textDecorationLine: "underline",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  box: {
    display: "flex",
    justifyContent: "center",
  },

  img: {
    width: "90%",
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  paragraph: {
    display: "flex",
    alignItems: "center",
    marginLeft: 65,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 35,
    },
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
}));
