import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const time = [
  {
    label: "No time off work",
    value: "noTime",
  },
  {
    label: "Less than 2 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "More than 2 weeks",
    value: "MoreThan2Weeks",
  },
];
const resetArray = [
  { name: "howLongFibromyalgia", type: "" },
  { name: "FibromyalgiaTreatment", type: "" },
  { name: "FibromyalgiaOngoingSymptoms", type: "" },
  { name: "FibromyalgiaTimeOffWork", type: "" },
  { name: "anyOngoingSymptoms", type: "" },
  { name: "FibromyalgiaDetails", type: "" },
];
export default function SubFormSeven({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Fibromyalgia", value: "fibromyalgia" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.subFormsOfFormThirteen.includes("fibromyalgia") && (
        <AnswerBox
          label="How long have you had Fibromyalgia?"
          name="howLongFibromyalgia"
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("fibromyalgia") &&
        values.howLongFibromyalgia && (
          <AnswerBox
            label="What is the treatment you are receiving?"
            name="FibromyalgiaTreatment"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("fibromyalgia") &&
        values.FibromyalgiaTreatment && (
          <AnswerBox
            label="What are your ongoing symptoms?"
            name="FibromyalgiaOngoingSymptoms"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("fibromyalgia") &&
        values.FibromyalgiaOngoingSymptoms && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"FibromyalgiaTimeOffWork"}
            items={time}
            isSubForm
          />
        )}

      {values.subFormsOfFormThirteen.includes("fibromyalgia") &&
        values.FibromyalgiaTimeOffWork && (
          <RadioMulti
            question="Do you have ongoing symptoms?"
            name={"anyOngoingSymptoms"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("fibromyalgia") &&
        values.anyOngoingSymptoms === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="FibromyalgiaDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
