import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";

const resetArray = [
  //HeartComplaintOrStroke
  { name: "WhenDetected", type: "" },
  { name: "investigations", type: "" },
  { name: "diagnosis", type: "" },
  { name: "treatment", type: "" },
  { name: "ongoingIssue", type: "" },
];

export default function HeartComplaintOrStroke({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Heart complaint/stroke", value: "HeartComplaintOrStroke" },
        ]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("HeartComplaintOrStroke") && (
        <Grid>
          <AnswerBox
            label="When was the heart complaint detected?"
            name="WhenDetected"
            isSubForm
          />
          <AnswerBox
            label="What investigations were carried out?"
            name="investigations"
            isSubForm
          />
          <AnswerBox
            label="What was the diagnosis?"
            name="diagnosis"
            isSubForm
          />
          <AnswerBox 
            label="What was the treatment?"
            name="treatment"
            isSubForm
          />
          <YesNoQuestion
            question="Is this still an ongoing issue?"
            name="ongoingIssue"
            isSubForm
          />
        </Grid>
      )}
    </Fragment>
  );
}
