import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const timeList = [
  {
    label: "No time off work",
    value: "noTimeOffWork",
  },
  {
    label: "Less than 2 weeks",
    value: "lessThan2Weeks",
  },
  {
    label: "More than 2 weeks",
    value: "moreThan2Weeks",
  },
];

const numberOfList = [
  {
    label: "No reoccurrences",
    value: "noReoccurrences",
  },
  {
    label: "1-2",
    value: "one&Tow",
  },
  {
    label: "2-5",
    value: "tow&five",
  },
  {
    label: "More than 5",
    value: "moreThan5",
  },
];

const time_List = [
  {
    label: "Symptoms are ongoing",
    value: "SymptomsAreOngoing",
  },
  {
    label: "Within the last 6 months",
    value: "WithinLast6Months",
  },
  {
    label: "6 months - 2 years ago",
    value: "6Months2YearsAgo",
  },
  {
    label: "2-5 years ago",
    value: "TowFiveYearsAgo",
  },
  {
    label: "5+ years ago",
    value: "moreThanFiveYears",
  },
];
const resetArray = [
  { name: "howLongSymptomsHas", type: [] },
  { name: "restrictedDailyActivitiesYes", type: "" },
  { name: "timeHadOffWork", type: "" },
  { name: "numberOfReoccurrences", type: "" },
  { name: "affectedJoint", type: [] },
  { name: "RSIDetails", type: "" },
  { name: "affectedJointDetails", type: "" },
];
export default function SubFormFive({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "RSI", value: "rsi" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormThirteen.includes("rsi") && (
        <CheckBox
          question="How long has it been since you last had symptoms?"
          list={time_List}
          name="howLongSymptomsHas"
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("rsi") &&
        values.howLongSymptomsHas.length !== 0 && (
          <RadioMulti
            question="Are you any way restricted in your daily activities as a consequence of this condition?"
            name={"restrictedDailyActivitiesYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("rsi") &&
        values.restrictedDailyActivitiesYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="RSIdetails"
            isSubForm
          />
        )}
      {(values.restrictedDailyActivitiesYes === "yes" ||
        values.restrictedDailyActivitiesYes === "no") &&
        values.subFormsOfFormThirteen.includes("rsi") && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"timeHadOffWork"}
            items={timeList}
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("rsi") &&
        values.timeHadOffWork && (
          <RadioMulti
            question="How many reoccurrences have you had since the symptoms first commenced?"
            name={"numberOfReoccurrences"}
            items={numberOfList}
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("rsi") && values.timeHadOffWork && (
        <CheckBox
          question="Which joints were affected?"
          list={[
            {
              label: "Right wrist",
              value: "rightWrist",
            },
            {
              label: "Left Wrist",
              value: "leftWrist",
            },
            {
              label: "Right shoulder",
              value: "rightShoulder",
            },
            {
              label: "Left Shoulder",
              value: "leftShoulder",
            },
            {
              label: "Right elbow",
              value: "rightElbow",
            },
            {
              label: "Left Elbow",
              value: "leftElbow",
            },
            {
              label: "Right hand",
              value: "rightHand",
            },
            {
              label: "Left hand",
              value: "leftHand",
            },
            {
              label: "other",
              value: "other",
            },
          ]}
          name="affectedJoint"
          isSubForm
        />
      )}
      {values.affectedJoint.map(
        (e) =>
          e === "other" && (
            <AnswerBox
              label="Please provide details?"
              name="affectedJointDetails"
              isSubForm
            />
          )
      )}
    </Fragment>
  );
}
