import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";

const items = [
  { label: "Less than 5 years ago", value: "Less than 5 years ago" },
  { label: "More than 5 years ago", value: "More than 5 years ago" },
];
const resetArray = [
  { name: "illnessWomen", type: "" },
  { name: "whnAbnormalMammogram", type: "" },
  { name: "whnBreastUltrasound", type: "" },
  { name: "reqBiopsyOrAspiration", type: "" },
  { name: "proceBiopsyOrAspiration", type: "" },
  { name: "isLumpConfirmed", type: "" },
  { name: "lumpDetails", type: "" },
  { name: "isMammogramsNormal", type: "" },
];
export default function FormMammOrUltrasound({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFoldCheck = (arr, check) => {
    const oops = values.illness.every((val) => check.indexOf(val) < 0);
    if (!oops) {
      for (const item of arr) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        /* items={[
          { label: "Abnormal Mammogram", value: "abnormalMammogram" },
          {
            label: "Abnormal Breast Ultrasound",
            value: "abnormalBreastUltrasound",
          },
        ]} */
        list={[
          { label: "Abnormal Mammogram", value: "abnormalMammogram" },
          {
            label: "Abnormal Breast Ultrasound",
            value: "abnormalBreastUltrasound",
          },
        ]}
        name="illnessWomen"
        question=""
        onClick={() =>
          resetFoldCheck(resetArray, [
            "abnormalMammogram",
            "abnormalBreastUltrasound",
          ])
        }
      />
      {values.illnessWomen.length > 0 && (
        <Fragment>
          {values.illnessWomen.includes("abnormalMammogram") && (
            <RadioMulti
              question="When was the abnormal mamogram?"
              name="whnAbnormalMammogram"
              items={items}
              isSubForm
            />
          )}
          {values.illnessWomen.includes("abnormalBreastUltrasound") && (
            <RadioMulti
              question="When was the l breast ultrasound?"
              name="whnBreastUltrasound"
              items={items}
              isSubForm
            />
          )}
          <YesNoQuestion
            question="Did you require further investigations? (biopsy or aspiration)"
            name="reqBiopsyOrAspiration"
            isSubForm
          />
          {values.reqBiopsyOrAspiration === "true" && (
            <Fragment>
              <AnswerBox
                label="What procedure did you have and when was it operated on? "
                name="proceBiopsyOrAspiration"
                isSubForm
              />
              <YesNoQuestion
                question="Was the lump confirmed as benign?"
                name="isLumpConfirmed"
                isSubForm
              />
              {values.isLumpConfirmed === "true" && (
                <AnswerBox
                  label="Please provide details"
                  name="lumpDetails"
                  isSubForm
                />
              )}
            </Fragment>
          )}
          <YesNoQuestion
            question="Have your results of subsequent mammograms all been normal?"
            name="isMammogramsNormal"
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
