import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "../invoice/Invoice";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Img from "../../assets/images/paypal.png";
import { useStyles } from "./classes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
function ConvertJsonToInvoice(
  test,
  MyCompany,
  EmailCompany,
  PhoneCompany,
  AddressCompany
) {
  let MyBalance =
    test.payment.transactions[0].amount.total +
    " " +
    test.payment.transactions[0].amount.currency;
  let invoice = {
    id: test.payment.id,
    invoice_no: test.payment.transactions[0].related_resources[0].sale.id,
    balance: MyBalance,
    company: MyCompany,
    email: EmailCompany,
    phone: PhoneCompany,
    address: AddressCompany,
    date: test.payment.create_time,
    due_date: test.payment.update_time,
    items: test.payment.transactions[0].item_list.items,
  };
  return invoice;
}

const Success = () => {
  const classes = useStyles();
  const [session, setSession] = useState({});
  const [user, setUser] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const queryLocation = location.search;
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function fetchSession() {
      const products = await fetch(
        `${process.env.REACT_APP_BASE_URL}paypal/success${queryLocation}`
      ).then((res) => res.json());
      console.log(`paypal/success response:\n${products}`);
      setSession(products);
      setUser([
        {
          quantity: "Customer",
          description: `${products.payment.payer.payer_info.first_name} ${products.payment.payer.payer_info.last_name}`,
        },
        {
          quantity: "Address",
          description: `${products.payment.payer.payer_info.shipping_address.line1},${products.payment.payer.payer_info.shipping_address.city},${products.payment.payer.payer_info.shipping_address.state}.`,
        },
        {
          quantity: "Email",
          description: products.payment.payer.payer_info.email,
        },
      ]);
      console.log("test", products.payment.payer.payer_info);
    }
    fetchSession();
  }, [queryLocation]);

  const loginDirect = () => {
    if (token) {
      history.push("/advisorDashboard");
    } else {
      history.push("/login");
    }
  };

  return (
    <Grid container justifycontent="center" style={{ marginTop: 50 }}>
      <Grid item>
        {session.status === "success" ? (
          <Grid>
            <Typography className={classes.header}>
              Payment Succeeded
            </Typography>
            <Typography className={classes.header}>
              View Paypal Checkout Invoice
            </Typography>
          </Grid>
        ) : session.message === "Session already create " ? (
          <div className="sr-payment-summary completed-view">
            <h1>Your payment Already Validate </h1>
            <h4>View CheckoutSession response:</h4>
          </div>
        ) : (
          <Grid>
            <Typography className={classes.header}>
              Payment status pending...
            </Typography>
            <Typography className={classes.header}>
              View Paypal Checkout Invoice
            </Typography>
          </Grid>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {Object.keys(session).length === 0 ? (
              <div></div>
            ) : (
              <PDFDownloadLink
                style={{ fontSize: 25 }}
                document={
                  <Invoice
                    invoice={ConvertJsonToInvoice(
                      session,
                      "PreAssessMe",
                      "PreAssessMe@company.com",
                      "+6136666666",
                      "444 rue du balcon Aus"
                    )}
                    Mytitle={"Tax Invoice"}
                    MsgThanks={"Terms & Conditions"}
                    user={user}
                  />
                }
                fileName="PaymentInvoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink>
            )}
            <Button
              variant="contained"
              className={classes.button}
              onClick={loginDirect}
            >
              Return to Dashboard
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className={classes.img}
            src={Img}
            alt="Adviser adds client details"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Success;
