import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const GeneralHealthTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-general-health-tabs"
          defaultActiveKey="#generalHealth-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              <ListGroup.Item action href={`#generalHealth-1`}>
                Genetic Testing
              </ListGroup.Item>
              {props.formData.formSix.illness.map((illness, index) => (
                <ListGroup.Item action href={`#generalHealth-${index + 2}`}>
                  {formatFormData(illness)
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    formatFormData(illness)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              <Tab.Pane eventKey={`#generalHealth-1`}>
                <Table
                  id="form-view-general-health-table"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr className="border-0">
                      <th id="question-heading" className="border-0 p-0"></th>
                      <th id="answer-heading" className="border-0 p-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Have you ever had a genetic test/are you waiting to
                        receive genetic test results/are you considering having
                        a genetic test?
                      </td>
                      <td>
                        {props.formData.formFive
                          ? props.formData.formFive.geneticTest
                          : "Loading..."}
                      </td>
                    </tr>
                    {props.formData.formFive ? (
                      props.formData.formFive.geneticTest === "yes" && (
                        <tr>
                          <td>What were the results?</td>
                          <td>{props.formData.formFive.geneticTestYes}</td>
                        </tr>
                      )
                    ) : (
                      <></>
                    )}
                    {props.formData.formFive ? (
                      props.formData.formFive.geneticTest === "pending" && (
                        <tr>
                          <td>What is the reason for the test?</td>
                          <td>{props.formData.formFive.geneticTestReason}</td>
                        </tr>
                      )
                    ) : (
                      <></>
                    )}
                    {props.formData.formFive ? (
                      props.formData.formFive.geneticTestReason === "Other" && (
                        <tr>
                          <td>Please provide details</td>
                          <td>{props.formData.formFive.geneticTestOther}</td>
                        </tr>
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </Tab.Pane>
              {props.formData.formSix.illness.map((illness, index) => (
                <Tab.Pane eventKey={`#generalHealth-${index + 2}`}>
                  <Table
                    id="form-view-general-health-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {illness === "HighBloodPressure" ? (
                      // High blood pressure responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>When was high blood pressure first diagnosed?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.firstDiagnosedDate
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the blood pressure reading?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.bloodPreassureReading
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Are you still on this treatment?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.heartTreatment
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          !props.formData.formSix.heartTreatment && (
                            <tr>
                              <td>
                                When did you discontinue the treatment and why?
                              </td>
                              <td>{props.formData.formSix.whenDiagnosedHBP}</td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.heartTreatment && (
                            <>
                              <tr>
                                <td>How often do you follow up?</td>
                                <td>
                                  {props.formData.formSix.HowOftenDoYouFollowUp}
                                </td>
                              </tr>
                              <tr>
                                <td>What was the blood pressure reading?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix
                                        .LastBloodPressureReading
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  What is your current medication and dosage?
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix
                                        .medicationAndDosage2
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>Have you suffered any of the following?</td>
                                <td>
                                  {props.formData.formSix
                                    ? arrayToFormattedList(
                                        props.formData.formSix.hBPsymtoms
                                      )
                                    : "Loading..."}
                                </td>
                              </tr>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "ChestPains" ? (
                      // Chest pains responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Was the chest pain related to a heart condition?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.heartCondition
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.heartCondition && (
                            <tr>
                              <td>What was the heart condition?</td>
                              <td>{props.formData.formSix.chestPainCon}</td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>Did you require hospital admission?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.reqHospitalAdmin
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long were you in the hospital?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.hospitalTime
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have there been any other instances of chest pains?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.chestPainCheck
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Was the chest pain within the last 12months?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.chestPainYear
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Please provide details</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.chestPainDet
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "HighCholesterol" ? (
                      // High cholesterol responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>How do your control your cholesterol?</td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.CholControlActivities
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was your last cholesterol reading?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.cholesterolReading
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "HeartMurmurs" ? (
                      // Heart murmurs responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Has your heart murmur been confirmed as innocent or
                            benign by GP or cardiologist?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.murmur
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you have any associated Valvular or heart
                            disease?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.ValvularOrHeart
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have you had or do you need to have treatment for
                            your heart murmur?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.murmurTreat
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.murmurTreat && (
                            <tr>
                              <td>Please provide details of treatment</td>
                              <td>{props.formData.formSix.murmurTreatDet}</td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            Has your doctor recommended you receive ongoing
                            treatment or monitoring?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.ongoingTreat
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Please provide details</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.murmurTreatMonitoringDet
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "RheumaticFever" ? (
                      // Rheumatic fever responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>When were you diagnosed with rheumatic fever?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.diagnosedWithRheumatic
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What ongoing treatment or investigation is required?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.rheumaticTreatment
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "HeartComplaintOrStroke" ? (
                      // Heart complaint or stroke responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>When was the heart complaint detected</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.WhenDetected
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What investigations were carried out?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.investigations
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the diagnosis?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.diagnosis
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the treatment?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.treatment
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Is this still an ongoing issue?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.ongoingIssue
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "TreatmentFrDiabetes" ? (
                      // Treatment for diabetes responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Which type do you have?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.diabetesType
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What was the date and result of your latest HbA1c
                            reading?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.latestHbA1c
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you require treatment of any of the following?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.diabetesTreatment
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How often do you require medical check ups for your
                            diabetes?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.diabetesCheckups
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            If this was gestational diabetes have your blood
                            glucose levels returned to normal?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.gestationalDiabetes
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Please provide further details of why it has not
                            returned to normal levels.
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.furtherDetailsDia
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "AbnormalBloodSugar" ? (
                      // Abnormal blood sugar responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>What was your diagnosis?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.aBDiagnosis
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What tests were completed?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.completedTests
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Were the results normal?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.resultCheck
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What were the results? Please provide further
                            details.
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.furtherDetailsSug
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "ThyroidDisorder" ? (
                      // Thyroid disorder responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>What type of thyroid condition do you have?</td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.thyroidCondition
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Please provide symptoms</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.thyroidSymptoms
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Any treatment provided</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.thyroidTreatments
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Are you currently on treatment for the thyroid
                            condition?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.thyroidTreatStatus
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Provide details</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.thyroidTreatDetails
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Gout" ? (
                      // Gout responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>When was this first diagnosed?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.goutDiagnose
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What joints were affected?</td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.effectedJoints
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How often do you have an attack?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.goutAttack
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "AsthmaVal" ? (
                      // Asthma responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            In the last 12 months have you required
                            hospitalisation, nebuliser or steroid therapy for
                            asthma?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.therapyCheck
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.therapyCheck && (
                            <tr>
                              <td>Select which apply</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formSix.therapyTypes
                                )}
                              </td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.therapyTypes.includes(
                            "Hospitalisation"
                          ) && (
                            <tr>
                              <td>How long were you in the hospital?</td>
                              <td>{props.formData.formSix.howLongHospital}</td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.therapyTypes.includes(
                            "Nebuliser"
                          ) ||
                          (props.formData.formSix.therapyTypes.includes(
                            "Steriod therapy tablet or syrup"
                          ) && (
                            <tr>
                              <td>
                                When did you receive this treatment and for how
                                long?
                              </td>
                              <td>{props.formData.formSix.treatmentTime}</td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            How many asthma attacks have you suffered in the
                            last 12 months?
                          </td>
                          <td>
                            {arrayToFormattedList(
                              props.formData.formSix.asthmaAttacks
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Childhoodasthma" ? (
                      // Childhood asthma responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Did you experience childhood asthma?</td>
                          <td>Yes</td>
                        </tr>
                      </tbody>
                    ) : illness === "ChronicLungDisease" ? (
                      // Chronic lung disease responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Provide details of chronic lung disease diagnosis
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.chronicLungDiagnosis
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How long did you have this condition?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.howLongCondition
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What treatment was required?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.requiredTreatment
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "SleepApnoea" ? (
                      // Sleep apnoea responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>When were you diagnosed with sleep apnoea?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.whenSleepApnoea
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Was your Diagnosis confirmed with a sleep study?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isConfirmedDiagnosis
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What type of sleep apnoea have you been diagnosed
                            with?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.typeOfSleepApnoea
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>How has your sleep apnoea been classified?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.sleepApnoeaClassified
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Do you use a CPAP machine every night to control
                            your symptoms?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.useCPAP
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Pneumonia" ? (
                      // Pneumonia responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Have your systems been treated fully and a full
                            recovery made
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isFullyRecovered
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            How many episodes of Pneumonia have your had per
                            annum on average
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.pneumoniaEpisodes
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "OtherRespiratoryDisorder" ? (
                      // Other respiratory disorder responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Was your respiratory complaint diagnosed as a chest
                            infection?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isChestDiagnosed
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Was your medication limited to antibiotics?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isAntibioticsLimited
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>Was hospitalisation required?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isReqHospital
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.isReqHospital && (
                            <tr>
                              <td>
                                Please provide details of the condition and
                                diagnosis as well as any treatment provided
                              </td>
                              <td>
                                {props.formData.formSix.conditionDiagTreatment}
                              </td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>Do you suffer more than 3 attacks per year?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.is3AttPerYr
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Indigestion" ? (
                      // Indigestion responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Have you been diagnosed with any of the following?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.indigestionDiagnosis
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.indigestionDiagnosis.includes(
                            "BarrettsOesophagus"
                          ) ||
                          props.formData.formSix.indigestionDiagnosis.includes(
                            "oesophagealCancer"
                          ) ||
                          (props.formData.formSix.indigestionDiagnosis.includes(
                            "AesophagealStricture"
                          ) && (
                            <>
                              <tr>
                                <td>
                                  Please provide details on the treatment and
                                  the outcome of the treatment including the
                                  date of issue.
                                </td>
                                <td>
                                  {
                                    props.formData.formSix
                                      .treatmentAndOutcomeInd
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Are you waiting on any further investigation
                                  for digestion issues?
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.isWaiting
                                      ? "Yes"
                                      : "No"
                                    : "Loading..."}
                                </td>
                              </tr>
                              {props.formData.formSix.isWaiting && (
                                <tr>
                                  <td>
                                    Please provide details of what tests need to
                                    be completed?
                                  </td>
                                  <td>
                                    {props.formData.formSix.comPleatedTests}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>Do you suffer more than 3 attacks per year?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.is3AttPerYr
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "GastricUlcer" ||
                      illness === "DuodenalUlcer" ? (
                      // Ulcer responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Have you had any investigations such as
                            gastroscopy/endoscopy in the past 2 years for this
                            ulcer?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.hadUlcer
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.hadUlcer && (
                            <tr>
                              <td>
                                Please provide details on the treatment and the
                                outcome of the treatment including the date of
                                issue.
                              </td>
                              <td>
                                {props.formData.formSix.treatmentAndOutcomeInd}
                              </td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>When was the ulcer treated?</td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.whenTreated
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Have symptoms ceased since completing/commencing
                            treatment?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.isSymptomsGone
                                ? "Yes"
                                : "No"
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          !props.formData.formSix.isSymptomsGone && (
                            <tr>
                              <td>
                                Please provide details of the condition and
                                diagnosis as well as any treatment provided
                              </td>
                              <td>
                                {props.formData.formSix.symtomsStillThere}
                              </td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "Hernia" ? (
                      // Hernia responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>
                            Was your hernia confirmed as any of the following?
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.typeOfHernia
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Please provide details on the treatment and the
                            outcome of the treatment including the date of
                            issue.
                          </td>
                          <td>
                            {props.formData.formSix
                              ? props.formData.formSix.tratmentAndOutcome
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "AnyBowelDisorder" ? (
                      // Any bowel disorder responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Please select the condition/s?</td>
                          <td>
                            {props.formData.formSix
                              ? arrayToFormattedList(
                                  props.formData.formSix.selectConditions
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "Diarrhoea"
                          ) && (
                            <>
                              <tr>
                                <td>Was blood passed?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.isBloodPassed
                                      ? "Yes"
                                      : "No"
                                    : "Loading..."}
                                </td>
                              </tr>
                              {props.formData.formSix.selectConditions && (
                                <>
                                  <tr>
                                    <td>
                                      Please provide details on the treatment
                                      and the outcome of the treatment including
                                      the date of issue?
                                    </td>
                                    <td>
                                      {props.formData.formSix
                                        ? props.formData.formSix.tretAndOutcome
                                          ? "Yes"
                                          : "No"
                                        : "Loading..."}
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td>How long did symptoms last?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.howLongLastBleeding
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Have you been advised to have any
                                  investigations done such as a colonoscopy in
                                  the last 2 years?
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.doneColonoscopy
                                      ? "Yes"
                                      : "No"
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Please provide details of the condition and
                                  diagnosis as well as any treatment provided
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.colonoscopyDetails
                                    : "Loading..."}
                                </td>
                              </tr>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "IrritableBowelSyndrome"
                          ) && (
                            <>
                              <tr>
                                <td>
                                  Has the IBS been fully investigated and
                                  diagnosis confirmed by a medical practitioner?
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.hasIBSinvestigated
                                      ? "Yes"
                                      : "No"
                                    : "Loading..."}
                                </td>
                              </tr>
                              {props.formData.formSix.hasIBSinvestigated && (
                                <>
                                  <tr>
                                    <td>
                                      Please provide details on the treatment
                                      and the outcome of the treatment including
                                      the date of issue.
                                    </td>
                                    <td>
                                      {props.formData.formSix
                                        ? props.formData.formSix.IBStreatment
                                        : "Loading..."}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "CoeliacDisease"
                          ) && (
                            <>
                              <tr>
                                <td>
                                  Was condition diagnosed in the last 12 months?
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.diagnosedInYear
                                      ? "Yes"
                                      : "No"
                                    : "Loading..."}
                                </td>
                              </tr>
                              {props.formData.formSix.diagnosedInYear && (
                                <>
                                  <tr>
                                    <td>
                                      Please provide details on the treatment
                                      and the outcome of the treatment including
                                      the date of issue.
                                    </td>
                                    <td>
                                      {props.formData.formSix
                                        ? props.formData.formSix
                                            .coeliacDiseaseTreat
                                        : "Loading..."}
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td>How is your condition being managed?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix
                                        .howManageCoeliacDisease
                                    : "Loading..."}
                                </td>
                              </tr>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "glutenFree"
                          ) ||
                          (props.formData.formSix.selectConditions.includes(
                            "NoneOfTheAbove"
                          ) && (
                            <tr>
                              <td>Are you currently symptom free?</td>
                              <td>
                                {props.formData.formSix
                                  ? props.formData.formSix.currentlySymptomFree
                                    ? "Yes"
                                    : "No"
                                  : "Loading..."}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "medication"
                          ) ||
                          (props.formData.formSix.selectConditions.includes(
                            "GlutenFreeAndMedication"
                          ) && (
                            <tr>
                              <td>What medication are you currently on?</td>
                              <td>
                                {props.formData.formSix
                                  ? props.formData.formSix
                                      .whatMeditationCoeliacDisease
                                  : "Loading..."}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "CrohnsDisease"
                          ) ||
                          (props.formData.formSix.selectConditions.includes(
                            "ColitisincludingUlcerativeColitis"
                          ) && (
                            <>
                              <tr>
                                <td>When was your condition diagnosed?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.whnDiagCrohnColitis
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>When was your last attack?</td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix.whnAttCrohnColitis
                                    : "Loading..."}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Please provide details on the treatment and
                                  the outcome of the treatment including the
                                  date of issue.
                                </td>
                                <td>
                                  {props.formData.formSix
                                    ? props.formData.formSix
                                        .treatmentCrohnColitis
                                    : "Loading..."}
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                        {props.formData.formSix ? (
                          props.formData.formSix.selectConditions.includes(
                            "Other"
                          ) && (
                            <tr>
                              <td>
                                Please provide details on the treatment and the
                                outcome of the treatment including the date of
                                issue.
                              </td>
                              <td>
                                {props.formData.formSix
                                  ? props.formData.formSix.otherTreatAndOutcome
                                  : "Loading..."}
                              </td>
                            </tr>
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : (
                      ""
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default GeneralHealthTab;
