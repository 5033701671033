import React, { useState } from "react";
import { Fragment } from "react";
import AnswerBox from "./AnswerBox";
import YesNoQuestion from "./YesNoQuestion";
import RadioMulti from "./RadioMulti";
import CheckBox from "./CheckBox";

import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "../../styles/form.module.css";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";

import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import { useStyles } from "./classes";

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const statementList = [
  {
    label: "Employee",
    value: "employee",
  },
  {
    label: "Self employed - sole trader",
    value: "soleTrader",
  },
  {
    label: "Self employed - partnership",
    value: "partnership",
  },
  {
    label: "Self employed - employee of own company",
    value: "ownCompany",
  },
  {
    label: "Self employed - trust",
    value: "trust",
  },
  {
    label: "Home duties",
    value: "homeDuties",
  },
  {
    label: "Student",
    value: "student",
  },
  {
    label: "Unemployed",
    value: "unemployed",
  },
];
const timeList = [
  {
    label: "Less than 3 months",
    value: "lessThan3Months",
  },
  {
    label: "3-6 months",
    value: "between3and8Months",
  },
  {
    label: "6-12 months",
    value: "between6and12Months",
  },
  {
    label: "12 months to 5 years",
    value: "towelMonthsTo5Years",
  },
  {
    label: "More than 5 years",
    value: "moreThan5Years",
  },
];
const howManyJobs = [
  {
    label: "1",
    value: "one",
  },
  {
    label: "2",
    value: "two",
  },
  {
    label: "3",
    value: "three",
  },
  {
    label: "4 or more",
    value: "fourOrMore",
  },
  {
    label: 'Nil - I have been at my job for more than 5 years',
    value: 'wasMoreThanFiveYear',
  },
  {
    label: "Nil - I was a student",
    value: "wasStudent",
  },
  {
    label: "Nil - I was a houseperson",
    value: "wasHousePerson",
  },
  {
    label: "Nil - I was travelling",
    value: "was travelling",
  },
  {
    label: "Nil - I was unemployed",
    value: "wasUnemployed",
  },
];

const industriesList = [
  {
    label: "Aviation / Airline",
    value: "airline",
  },
  {
    label: "Events management",
    value: "eventsManagement",
  },
  {
    label: "Tourism",
    value: "tourism",
  },
  {
    label: "Corporate Training, conferencing, public speaking",
    value: "corporateConferencingPublicSpeaking",
  },
  {
    label: "None of the above",
    value: "none of the above",
  },
];
export default function FormTwentyFive() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    //SubForm29

    employeeStatement: "",
    howLongYourCurrentJob: "",
    howMuchSickLeave: "",
    howManyJobsHave: [],
    jobsDetails: "",
    AnyOccupation: "",
    haveAnyDefinitePlans: "",
    aboutJobs: "",
    workingIndustries: [],
    ReducedAnyHoursOtWork: "",

    //SubForm30
    isAnyBusiness: "",
    advises: "",
  });
  const validationSchema = Yup.object().shape({
    //SubForm24

    employeeStatement: Yup.string().required("This field is required "),
    howLongYourCurrentJob: Yup.string().when(
      ["employeeStatement"],
      (employeeStatement) => {
        if (
          employeeStatement !== "student" ||
          employeeStatement !== "unemployed" ||
          employeeStatement !== "homeDuties"
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    howMuchSickLeave: Yup.string().when(
      ["employeeStatement", "howLongYourCurrentJob"],
      (employeeStatement, howLongYourCurrentJob) => {
        if (
          (employeeStatement !== "student" ||
            employeeStatement !== "unemployed" ||
            employeeStatement !== "homeDuties") &&
          howLongYourCurrentJob
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    jobsDetails: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave
      ) => {
        if (
          employeeStatement &&
          howLongYourCurrentJob &&
          howMuchSickLeave &&
          (howManyJobsHave.includes("one") ||
            howManyJobsHave.includes("two") ||
            howManyJobsHave.includes("three") ||
            howManyJobsHave.includes("fourOrMore"))
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    AnyOccupation: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
        "jobsDetails",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave,
        jobsDetails
      ) => {
        if (
          employeeStatement &&
          howLongYourCurrentJob &&
          howMuchSickLeave &&
          (howManyJobsHave.includes("one") ||
            howManyJobsHave.includes("two") ||
            howManyJobsHave.includes("three") ||
            howManyJobsHave.includes("fourOrMore")) &&
          jobsDetails
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    haveAnyDefinitePlans: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
        "jobsDetails",
        "AnyOccupation",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave,
        jobsDetails,
        AnyOccupation
      ) => {
        if (
          employeeStatement &&
          howLongYourCurrentJob &&
          howMuchSickLeave &&
          (howManyJobsHave.includes("one") ||
            howManyJobsHave.includes("two") ||
            howManyJobsHave.includes("three") ||
            howManyJobsHave.includes("fourOrMore")) &&
          jobsDetails &&
          AnyOccupation
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    aboutJobs: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
        "jobsDetails",
        "AnyOccupation",
        "haveAnyDefinitePlans",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave,
        jobsDetails,
        AnyOccupation,
        haveAnyDefinitePlans
      ) => {
        if (
          employeeStatement &&
          howLongYourCurrentJob &&
          howMuchSickLeave &&
          (howManyJobsHave.includes("one") ||
            howManyJobsHave.includes("two") ||
            howManyJobsHave.includes("three") ||
            howManyJobsHave.includes("fourOrMore")) &&
          jobsDetails &&
          AnyOccupation === "yes" &&
          haveAnyDefinitePlans === "yes"
        ) {
          return Yup.string().max(300, "Maximum length is 300");
        }
      }
    ),
    workingIndustries: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
        "jobsDetails",
        "AnyOccupation",
        "haveAnyDefinitePlans",
        "aboutJobs",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave,
        jobsDetails,
        AnyOccupation,
        haveAnyDefinitePlans,
        aboutJobs
      ) => {
        if (
          (employeeStatement &&
            howLongYourCurrentJob &&
            howMuchSickLeave &&
            (howManyJobsHave.includes("one") ||
              howManyJobsHave.includes("two") ||
              howManyJobsHave.includes("three") ||
              howManyJobsHave.includes("fourOrMore")) &&
            jobsDetails &&
            AnyOccupation === "yes" &&
            haveAnyDefinitePlans === "no") ||
          aboutJobs
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    ReducedAnyHoursOtWork: Yup.string().when(
      [
        "employeeStatement",
        "howLongYourCurrentJob",
        "howMuchSickLeave",
        "howManyJobsHave",
        "jobsDetails",
        "AnyOccupation",
        "haveAnyDefinitePlans",
        "aboutJobs",
        "workingIndustries",
      ],
      (
        employeeStatement,
        howLongYourCurrentJob,
        howMuchSickLeave,
        howManyJobsHave,
        jobsDetails,
        AnyOccupation,
        haveAnyDefinitePlans,
        aboutJobs,
        workingIndustries
      ) => {
        if (
          (employeeStatement &&
            howLongYourCurrentJob &&
            howMuchSickLeave &&
            (howManyJobsHave.includes("one") ||
              howManyJobsHave.includes("two") ||
              howManyJobsHave.includes("three") ||
              howManyJobsHave.includes("fourOrMore")) &&
            jobsDetails &&
            AnyOccupation === "yes" &&
            haveAnyDefinitePlans === "no") ||
          aboutJobs ||
          workingIndustries
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    //SubForm30
    isAnyBusiness: Yup.string().required(),
    advises: Yup.string().when("isAnyBusiness", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
  });

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formtwentyfive", {
        formTwentyFive: e,
        pointer: 26,
        formid: id,
      });
      dispatch(setPage(26));
    } catch (error) {}
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <Fragment>
                        <RadioMulti
                          question="Select your employment status."
                          name={"employeeStatement"}
                          items={statementList}
                        />

                        {(values.employeeStatement !== "homeDuties" ||
                          values.employeeStatement !== "student" ||
                          values.employeeStatement !== "unemployed") && (
                          <RadioMulti
                            question="How long have you been in your current role or employment status?"
                            name={"howLongYourCurrentJob"}
                            items={timeList}
                          />
                        )}
                        {(values.howLongYourCurrentJob !== "homeDuties" ||
                          values.howLongYourCurrentJob !== "student" ||
                          values.howLongYourCurrentJob !== "unemployed") && (
                          <AnswerBox
                            name="howMuchSickLeave"
                            label="How much sick leave do you have owning?"
                          />
                        )}
                        {values.howMuchSickLeave && (
                          <CheckBox
                            question="Apart from your current job, how many jobs have you had
                            in the past 5 years?"
                            name={"howManyJobsHave"}
                            list={howManyJobs}
                          />
                        )}
                        {(values.howManyJobsHave.includes("one") ||
                          values.howManyJobsHave.includes("two") ||
                          values.howManyJobsHave.includes("three") ||
                          values.howManyJobsHave.includes("fourOrMore")) && (
                          <AnswerBox
                            name="jobsDetails"
                            label="Please provide details of the jobs?"
                          />
                        )}
                        {values.jobsDetails && (
                          <RadioMulti
                            question=" Do you have another occupation?"
                            name={"AnyOccupation"}
                            items={items}
                          />
                        )}
                        {values.AnyOccupation && (
                          <RadioMulti
                            question=" Do you have any definite plans to change your occupation
                            , work duties, work hours or employment status?"
                            name={"haveAnyDefinitePlans"}
                            items={items}
                          />
                        )}
                        {values.haveAnyDefinitePlans === "yes" && (
                          <AnswerBox
                            name="aboutJobs"
                            label="Please provide details of the jobs?"
                          />
                        )}
                        {(values.aboutJobs ||
                          values.haveAnyDefinitePlans === "no") && (
                          <CheckBox
                            question="Do you work in any of the following industries?"
                            name={"workingIndustries"}
                            list={industriesList}
                          />
                        )}
                        {values.workingIndustries.length !== 0 && (
                          <RadioMulti
                            question="Have your hours or work been reduced or to your
                            knowledge will they be affected by the Coronavirus
                            outbreak?"
                            name={"ReducedAnyHoursOtWork"}
                            items={items}
                          />
                        )}
                      </Fragment>
                      <Fragment>
                        <p className={styles.question}>
                          Are you or any business with which you are associated,
                          contemplating, voluntary administration, or ever been
                          made bankrupt or placed in receivership, involuntary
                          liquidation or under administration?
                        </p>
                        <YesNoQuestion name="isAnyBusiness" question="" />
                        {values.isAnyBusiness === "true" && (
                          <Fragment>
                            <AnswerBox
                              name="advises"
                              label="Advise when bankruptcy/receivership/liquidation/administration occurred and date of discharge (if applicable)"
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
