import React, { useState, useEffect } from 'react';
import FormOne from '../../components/forms/FormOne.js';
import FormTwo from '../../components/forms/FormTwo.js';
import FormThree from '../../components/forms/FormThree';
import FormFive from '../../components/forms/FormFive';
import FormSix from '../../components/forms/FormSix';
import FormSeven from '../../components/forms/FormSeven';
import FormTen from '../../components/forms/FormTen';
import FormThirteen from '../../components/forms/FormThirteen.js';
import FormFourteen from '../../components/forms/FormFourteen.js';
import FormFifteen from '../../components/forms/FormFifteen';
import FormSixteen from '../../components/forms/FormSixteen';
import FormSeventeen from '../../components/forms/FormSeventeen';
import FormNineteen from '../../components/forms/FormNineteen';
import FormTwenty from '../../components/forms/FormTwenty';
import FormTwentyOne from '../../components/forms/FormTwentyOne';
import FormTwentyTwo from '../../components/forms/FormTwentyTwo';
import FormTwentyThree from '../../components/forms/FormTwentyThree';
import FormTwentyFour from '../../components/forms/FormTwentyFour';
import FormTwentyFive from '../../components/forms/FormTwentyFive';
import FormTwentySix from '../../components/forms/FormTwentySix';

import Message from '../../components/message/Message.js';
import Agreement from '../../components/agreement/Agreement.js';
import FirstVideo from '../../components/forms/FirstVideo';

import { useParams } from 'react-router-dom';
import axios from '../../lib/axios';
import { useSelector } from 'react-redux';
import Img1 from '../../assets/images/im3.png';

export default function Form() {
  const { id } = useParams();
  const [pointer, setPointer] = useState(1);
  const point = useSelector((state) => state.form.page);
  const submit = async () => {
    try {
      const data = await axios.post('/form/pointer', {
        formid: id,
      });
      if (data.data.pointer) {
        setPointer(data.data.pointer);
      }
    } catch (error) {}
    // dispatch(setPage(2));
  };
  if (point > pointer) {
    setPointer(point);
  }

  useEffect(() => {
    submit();
    // eslint-disable-next-line
  }, []);

  const ShowForm = ({ pointer }) => {
    console.log('pointer', pointer);
    let MyComponent = FirstVideo;
    switch (pointer) {
      case 1:
        MyComponent = FirstVideo;
        break;
      case 2:
        MyComponent = FormOne;
        break;
      case 3:
        MyComponent = FormTwo;
        break;
      case 4:
        MyComponent = FormThree;
        break;
      case 5:
        MyComponent = FormFive;
        break;
      case 6:
        MyComponent = FormSix;
        break;
      case 7:
        MyComponent = FormSeven;
        break;
      case 8:
        MyComponent = FormTen;
        break;
      case 10:
        MyComponent = FormThirteen;
        break;
      case 11:
        MyComponent = FormFourteen;
        break;
      case 15:
        MyComponent = FormFifteen;
        break;
      case 16:
        MyComponent = FormSixteen;
        break;
      case 17:
        MyComponent = FormSeventeen;
        break;
      case 19:
        MyComponent = FormNineteen;
        break;
      case 20:
        MyComponent = FormTwenty;
        break;
      case 21:
        MyComponent = FormTwentyOne;
        break;
      case 22:
        MyComponent = FormTwentyTwo;
        break;
      case 23:
        MyComponent = FormTwentyThree;
        break;
      case 24:
        MyComponent = FormTwentyFour;
        break;
      case 25:
        MyComponent = FormTwentyFive;
        break;
      case 26:
        MyComponent = FormTwentySix;
        break;
      case 27:
        MyComponent = Agreement;
        break;
      case 28:
        MyComponent = Message;
        break;
      default:
        break;
    }
    return <MyComponent />;
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <img
          src={Img1}
          width='20%'
          height='15%'
          alt='Health & Pursuits Questionnaire'
        />
      </div>

      <ShowForm pointer={pointer} />
    </div>
  );
}
