import { LOGIN, LOGOUT, TOGGLE, NAME } from "./types";
import axios from "../../lib/axios";
// import  browserHistory  from '../../history'

export const login =
  (name, role, token, permittedRoutes) => async (dispatch) => {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      axios.interceptors.request.use(
        function (config) {
          config.headers["Authorization"] = "Bearer " + token;
          return config;
        },
        null,
        { synchronous: true }
      );

      // save to the session storage
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("role", role);
      sessionStorage.setItem("routes", JSON.stringify(permittedRoutes));

      console.log('Session storage updated')

      dispatch({
        type: LOGIN,
        payload: { token, name, role, routes: permittedRoutes },
      });
    } catch (error) {
      console.log("err", error);
    }
  };

export const logout = () => {
  // remove items from session storage

  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent

      delete config.headers["Authorization"];
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  sessionStorage.removeItem("token");
  sessionStorage.removeItem("name");
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("routes");

  return {
    type: LOGOUT,
  };
};

export const toggleDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE,
  });
};

export const changeName = (name) => (dispatch) => {
  console.log("name in action", name);
  dispatch({
    type: NAME,
    payload: { name },
  });
};
//reset password

export const resetPass = () => (dispatch) => {
  dispatch({});
};
