import React from "react";
import { Table } from "react-bootstrap";
import "./UnderwriterReplyTable.css";

const UnderwriterReplyTable = (props) => {
  return (
    <Table
      id="underwriter-reply-table"
      striped
      bordered
      responsive
      className="m-0"
    >
      <thead>
        <tr>
          <th id="question-heading">Assessment question</th>
          <th id="answer-heading">Underwriter supplied response</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Underwriter's reference:
          </td>
          <td>
            {props.reply.underwriterReplyForm.underwriterReference}
          </td>
        </tr>
        {/* ---- Life cover section ---- */}
        <>
          <tr>
            <td>Is life cover accepted at standard rates and terms?</td>
            <td>
              {props.reply.underwriterReplyForm
                ? props.reply.underwriterReplyForm.lifeCovered ===
                  "lifeCoveredTrue"
                  ? "Yes"
                  : "No"
                : "Loading..."}
            </td>
          </tr>
          {props.reply.underwriterReplyForm.lifeCovered ===
          "lifeCoveredFalse" ? (
            <tr>
              <td>What are the terms offered for life insurance?</td>
              <td>{props.reply.underwriterReplyForm.lifeTermsOffered}</td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for life insurance loading(s) */}
          {props.reply.underwriterReplyForm.lifeTermsOffered === "Loading" ? (
            <tr>
              <td>What is the loading?</td>
              <td>{props.reply.underwriterReplyForm.lifeLoading}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.lifeLoadingFurtherInfo ===
          "lifeLoadingFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {props.reply.underwriterReplyForm.lifeLoadingFurtherInfoDetails}
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.lifeLoadingTimeFrame ===
          "lifeLoadingTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                loading?
              </td>
              <td>
                {props.reply.underwriterReplyForm.lifeLoadingTimeFrameDetails}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for life insurance exclusion(s) */}
          {props.reply.underwriterReplyForm.lifeTermsOffered === "Exclusion" ? (
            <tr>
              <td>What are the exclusions?</td>
              <td>{props.reply.underwriterReplyForm.lifeExclusion}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.lifeExclusionFurtherInfo ===
          "lifeExclusionFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .lifeExclusionFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.lifeExclusionTimeFrame ===
          "lifeExclusionTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                exclusions?
              </td>
              <td>
                {props.reply.underwriterReplyForm.lifeExclusionTimeFrameDetails}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional reply for life insurance rejection */}
          {props.reply.underwriterReplyForm.lifeTermsOffered === "None" ? (
            <tr>
              <td>Why have no terms been offered?</td>
              <td>{props.reply.underwriterReplyForm.lifeNoTermsReason}</td>
            </tr>
          ) : (
            ""
          )}
        </>

        {/* ---- TPD cover section ---- */}
        <>
          <tr>
            <td>Is TPD cover accepted at standard rates and terms?</td>
            <td>
              {props.reply.underwriterReplyForm
                ? props.reply.underwriterReplyForm.tpdCovered ===
                  "tpdCoveredTrue"
                  ? "Yes"
                  : "No"
                : "Loading..."}
            </td>
          </tr>
          {props.reply.underwriterReplyForm.tpdCovered === "tpdCoveredFalse" ? (
            <tr>
              <td>What are the terms offered for tpd insurance?</td>
              <td>{props.reply.underwriterReplyForm.tpdTermsOffered}</td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for tpd insurance loading(s) */}
          {props.reply.underwriterReplyForm.tpdTermsOffered === "Loading" ? (
            <tr>
              <td>What is the loading?</td>
              <td>{props.reply.underwriterReplyForm.tpdLoading}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.tpdLoadingFurtherInfo ===
          "tpdLoadingFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {props.reply.underwriterReplyForm.tpdLoadingFurtherInfoDetails}
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.tpdLoadingTimeFrame ===
          "tpdLoadingTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                loading?
              </td>
              <td>
                {props.reply.underwriterReplyForm.tpdLoadingTimeFrameDetails}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for tpd insurance exclusion(s) */}
          {props.reply.underwriterReplyForm.tpdTermsOffered === "Exclusion" ? (
            <tr>
              <td>What are the exclusions?</td>
              <td>{props.reply.underwriterReplyForm.tpdExclusion}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.tpdExclusionFurtherInfo ===
          "tpdExclusionFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .tpdExclusionFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.tpdExclusionTimeFrame ===
          "tpdExclusionTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                exclusions?
              </td>
              <td>
                {props.reply.underwriterReplyForm.tpdExclusionTimeFrameDetails}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional reply for tpd insurance rejection */}
          {props.reply.underwriterReplyForm.tpdTermsOffered === "None" ? (
            <tr>
              <td>Why have no terms been offered?</td>
              <td>{props.reply.underwriterReplyForm.tpdNoTermsReason}</td>
            </tr>
          ) : (
            ""
          )}
        </>

        {/* ---- Trauma cover section ---- */}
        <>
          <tr>
            <td>Is trauma cover accepted at standard rates and terms?</td>
            <td>
              {props.reply.underwriterReplyForm
                ? props.reply.underwriterReplyForm.traumaCovered ===
                  "traumaCoveredTrue"
                  ? "Yes"
                  : "No"
                : "Loading..."}
            </td>
          </tr>
          {props.reply.underwriterReplyForm.traumaCovered ===
          "traumaCoveredFalse" ? (
            <tr>
              <td>What are the terms offered for trauma insurance?</td>
              <td>{props.reply.underwriterReplyForm.traumaTermsOffered}</td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for trauma insurance loading(s) */}
          {props.reply.underwriterReplyForm.traumaTermsOffered === "Loading" ? (
            <tr>
              <td>What is the loading?</td>
              <td>{props.reply.underwriterReplyForm.traumaLoading}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.traumaLoadingFurtherInfo ===
          "traumaLoadingFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .traumaLoadingFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.traumaLoadingTimeFrame ===
          "traumaLoadingTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                loading?
              </td>
              <td>
                {props.reply.underwriterReplyForm.traumaLoadingTimeFrameDetails}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for trauma insurance exclusion(s) */}
          {props.reply.underwriterReplyForm.traumaTermsOffered ===
          "Exclusion" ? (
            <tr>
              <td>What are the exclusions?</td>
              <td>{props.reply.underwriterReplyForm.traumaExclusion}</td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.traumaExclusionFurtherInfo ===
          "traumaExclusionFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .traumaExclusionFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.traumaExclusionTimeFrame ===
          "traumaExclusionTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                exclusions?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .traumaExclusionTimeFrameDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional reply for trauma insurance rejection */}
          {props.reply.underwriterReplyForm.traumaTermsOffered === "None" ? (
            <tr>
              <td>Why have no terms been offered?</td>
              <td>{props.reply.underwriterReplyForm.traumaNoTermsReason}</td>
            </tr>
          ) : (
            ""
          )}
        </>

        {/* ---- Income protection cover section ---- */}
        <>
          <tr>
            <td>Is income protection accepted at standard rates and terms?</td>
            <td>
              {props.reply.underwriterReplyForm
                ? props.reply.underwriterReplyForm.incomeProtectionCovered ===
                  "incomeProtectionCoveredTrue"
                  ? "Yes"
                  : "No"
                : "Loading..."}
            </td>
          </tr>
          {props.reply.underwriterReplyForm.incomeProtectionCovered ===
          "incomeProtectionCoveredFalse" ? (
            <tr>
              <td>
                What are the terms offered for income protection insurance?
              </td>
              <td>
                {props.reply.underwriterReplyForm.incomeProtectionTermsOffered}
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for income protection insurance loading(s) */}
          {props.reply.underwriterReplyForm.incomeProtectionTermsOffered ===
          "Loading" ? (
            <tr>
              <td>What is the loading?</td>
              <td>
                {props.reply.underwriterReplyForm.incomeProtectionLoading}
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm
            .incomeProtectionLoadingFurtherInfo ===
          "incomeProtectionLoadingFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .incomeProtectionLoadingFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm.incomeProtectionLoadingTimeFrame ===
          "incomeProtectionLoadingTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                loading?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .incomeProtectionLoadingTimeFrameDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional replies for income protection insurance exclusion(s) */}
          {props.reply.underwriterReplyForm.incomeProtectionTermsOffered ===
          "Exclusion" ? (
            <tr>
              <td>What are the exclusions?</td>
              <td>
                {props.reply.underwriterReplyForm.incomeProtectionExclusion}
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm
            .incomeProtectionExclusionFurtherInfo ===
          "incomeProtectionExclusionFurtherInfoTrue" ? (
            <tr>
              <td>
                Do you require any further information that may change your
                assessment?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .incomeProtectionExclusionFurtherInfoDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}
          {props.reply.underwriterReplyForm
            .incomeProtectionExclusionTimeFrame ===
          "incomeProtectionExclusionTimeFrameTrue" ? (
            <tr>
              <td>
                Is there a time frame or trigger where you will review the
                exclusions?
              </td>
              <td>
                {
                  props.reply.underwriterReplyForm
                    .incomeProtectionExclusionTimeFrameDetails
                }
              </td>
            </tr>
          ) : (
            ""
          )}

          {/* Conditional reply for income protection insurance rejection */}
          {props.reply.underwriterReplyForm.incomeProtectionTermsOffered ===
          "None" ? (
            <tr>
              <td>Why have no terms been offered?</td>
              <td>
                {props.reply.underwriterReplyForm.incomeProtectionNoTermsReason}
              </td>
            </tr>
          ) : (
            ""
          )}
        </>

        {/* ---- Additional info section ---- */}
        <tr>
          <td>Do you have any further notes or comments?</td>
          <td>
            {props.reply.underwriterReplyForm.additionalInfo
              ? props.reply.underwriterReplyForm.additionalInfo
              : "No"}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default UnderwriterReplyTable;
