import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";

import moment from "moment";
import styles from "../../../styles/form.module.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF } from "@mui/material/";
import { Box } from "@material-ui/core";

const types = [
  { label: "Type I (IDDM)", value: "Type I (IDDM)" },
  { label: "Type II (NIDDM)", value: "Type II (NIDDM)" },
];

const items = [
  { label: "3 monthly or less", value: "3 monthly or less" },
  { label: "6 monthly", value: "6 monthly" },
  { label: "Yearly", value: "yearly" },
  { label: "As needed", value: "As needed" },
  { label: "Never", value: "never" },
];

const prob = [
  { label: "Diabetic Coma", value: "Diabetic Coma" },
  { label: "Deterioriation in Vision", value: "Deterioriation in Vision" },
  { label: "Heart problems or concerns", value: "Heart problems or concerns" },
  { label: "High Blood pressure", value: "High Blood pressure" },
  { label: "Feet Problems", value: "Feet Problems" },
  { label: "Kidney or bladder problems", value: "Kidney or blader problems" },
  { label: "Circulation problems", value: "Circulation problems" },
  { label: "None of the above", value: "None of the above" },
];

const resetArray = [
  //TreatmentFrDiabetes
  { name: "diabetesType", type: [] },
  { name: "latestHbA1c", type: "" },
  { name: "diabetesTreatment", type: [] },
  { name: "diabetesCheckups", type: [] },
  { name: "gestationalDiabetes", type: "" },
  { name: "furtherDetailsDia", type: "" },
];
export default function FormTreatmentFrDiabetes({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Treatment for diabetes", value: "TreatmentFrDiabetes" },
        ]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("TreatmentFrDiabetes") && (
        <Fragment>
          <RadioMulti
            name={"diabetesType"}
            question="Which type do you have?"
            items={types}
            isSubForm
          />
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              What was the date and result of your latest HbA1c reading?
            </p>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={enAuLocale}
            >
              <DatePicker
                label="dd/mm/yyyy"
                value={value}
                disableOpenPicker
                maxDate={moment().toDate()}
                inputProps={{
                  placeholder: "dd/mm/yyyy",
                }}
                onChange={(newValue) => {
                  setValue(newValue);
                  setFieldValue("latestHbA1c", newValue);
                }}
                renderInput={(params) => (
                  <TF fullWidth variant="outlined" {...params} isSubForm />
                )}
              />
            </LocalizationProvider>
          </Box>


          <CheckBox
            name={"diabetesTreatment"}
            question="Do you require treatment of any of the following?"
            list={prob}
            isSubForm
          />
          <RadioMulti
            question="How often do you require medical checkups for your diabetes?"
            name="diabetesCheckups"
            items={items}
            isSubForm
          />
          <YesNoQuestion
            question="If this was gestational diabetes have your blood glucose levels returned to normal?"
            name="gestationalDiabetes"
            isSubForm
          />
          {values.gestationalDiabetes !== "true" && (
            <AnswerBox
              label="Please provide further details of why it has not returned to normal levels."
              name="furtherDetailsDia"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
