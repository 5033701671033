import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Field } from 'formik';
import styles from '../../styles/form.module.css';

export default function CheckBox({ 
  list,
  name,
  question,
  onClick,
  isSubForm = false
}) {
  return (
    <Grid item md={12} sm={12} xs={12}>
      <Box className={ isSubForm ? styles.subFormBox : styles.FormBox }>
        {question && <p className={styles.question}>{question}</p>}
        {list.map((item) => (
          <Field
            style={{ fontFamily: 'Overpass, sans-serif', fontSize: '0.9rem' }}
            component={CheckboxWithLabel}
            type='checkbox'
            name={name}
            key={item.value}
            onClick={onClick}
            value={item.value}
            Label={{
              label: (
                <span
                  style={{
                    fontFamily: 'Overpass, sans-serif',
                  }}
                >
                  {item.label}{' '}
                </span>
              ),
            }}
          />
        ))}
      </Box>
    </Grid>
  );
}
