import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const MusculoskeletalTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-musculoskeletal-tabs"
          defaultActiveKey="#musculoskeletal-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formTen.subFormsOfFormThirteen.map(
                (illness, index) => (
                  <ListGroup.Item action href={`#musculoskeletal-${index + 1}`}>
                    {formatFormData(illness)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      formatFormData(illness)
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1)}
                  </ListGroup.Item>
                )
              )}
              {props.formData.formEleven.subFormsOfFourteen.map(
                (disorder, index) => (
                  <ListGroup.Item
                    action
                    href={`#musculoskeletalDisorder-${index + 1}`}
                  >
                    {formatFormData(disorder)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      formatFormData(disorder)
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1)}
                  </ListGroup.Item>
                )
              )}
              {props.formData.formTwentyTwo.consultations.map(
                (consult, index) => (
                  <ListGroup.Item
                    action
                    href={`#musculoskeletalConsult-${index + 1}`}
                  >
                    {formatFormData(consult)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()[0]
                      .toUpperCase() +
                      formatFormData(consult)
                        .replace(/([A-Z]+)/g, " $1")
                        .trim()
                        .substring(1) +
                      ` Consult`}
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formTen.subFormsOfFormThirteen.map(
                (illness, index) => (
                  <Tab.Pane eventKey={`#musculoskeletal-${index + 1}`}>
                    <Table
                      id="form-view-musculoskeletal-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {illness === "osteoarthritis" ? (
                        <tbody>
                          <tr>
                            <td>Which joints were affected?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formTen.OsteoarthritisJointsList
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Which side?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.OsteoarthritisWhichSide
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>How long have you had arthritis?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .OsteoarthritisHowLongArthritis
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>
                              {props.formData.formTen.OsteoarthritisTreatment}
                            </td>
                          </tr>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .OsteoarthritisOngoingSymptoms
                              }
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "rheumatoidArthritis" ? (
                        <tbody>
                          <tr>
                            <td>Which joints were affected?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formTen
                                  .RheumatoidArthritisJointsList
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Which side?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTen
                                  .RheumatoidArthritisWhichSide
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>How long have you had arthritis?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .RheumatoidArthritisHowLongArthritis
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .RheumatoidArthritisTreatment
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .RheumatoidArthritisOngoingSymptoms
                              }
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "psoriaticArthritis" ? (
                        <tbody>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .PsoriaticArthritisOngoingSymptoms
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>How long have you had arthritis?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .PsoriaticArthritisHowLongArthritis
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .PsoriaticArthritisTreatment
                              }
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "otherArthritis" ? (
                        <tbody>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>
                              {props.formData.formTen.ArthritisOngoingSymptoms}
                            </td>
                          </tr>
                          <tr>
                            <td>How long have you had arthritis?</td>
                            <td>
                              {props.formData.formTen.ArthritisHowLongArthritis}
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>{props.formData.formTen.ArthritisTreatment}</td>
                          </tr>
                        </tbody>
                      ) : illness === "rsi" ? (
                        <tbody>
                          <tr>
                            <td>
                              How long has it been since you last had symptoms?
                            </td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formTen.howLongSymptomsHas
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Are you any way restricted in your daily
                              activities as a consequence of this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTen
                                  .restrictedDailyActivitiesYes
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.timeHadOffWork
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How many reoccurrences have you had since the
                              symptoms first commenced?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.numberOfReoccurrences
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Which joints were affected?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formTen.affectedJoint
                              )}
                            </td>
                          </tr>
                          {props.formData.formTen.affectedJoint.includes(
                            "other"
                          ) ? (
                            <tr>
                              <td>Please provide details?</td>
                              <td>
                                {props.formData.formTen.affectedJointDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : illness === "chronic" ? (
                        <tbody>
                          <tr>
                            <td>When did you last experience symptoms?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.whenLastSymptoms
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How long have you had Chronic fatigue syndrome?
                            </td>
                            <td>
                              {props.formData.formTen.howLongChronicFatigue}
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>{props.formData.formTen.treatment}</td>
                          </tr>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>{props.formData.formTen.ongoingSymptoms}</td>
                          </tr>
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.timeOffWork
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ) : illness === "fibromyalgia" ? (
                        <tbody>
                          <tr>
                            <td>How long have you had Fibromyalgia?</td>
                            <td>
                              {props.formData.formTen.howLongFibromyalgia}
                            </td>
                          </tr>
                          <tr>
                            <td>What is the treatment you are receiving?</td>
                            <td>
                              {props.formData.formTen.FibromyalgiaTreatment}
                            </td>
                          </tr>
                          <tr>
                            <td>What are your ongoing symptoms?</td>
                            <td>
                              {
                                props.formData.formTen
                                  .FibromyalgiaOngoingSymptoms
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.FibromyalgiaTimeOffWork
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Do you have ongoing symptoms?</td>
                            <td>
                              {formatFormData(
                                props.formData.formTen.anyOngoingSymptoms
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Details</td>
                            <td>
                              {props.formData.formTen.FibromyalgiaDetails}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                )
              )}
              {props.formData.formEleven.subFormsOfFourteen.map(
                (disorder, index) => (
                  <Tab.Pane eventKey={`#musculoskeletalDisorder-${index + 1}`}>
                    <Table
                      id="form-view-musculoskeletal-disorder-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {disorder === "Back_or_neck_disorder" ? (
                        <tbody>
                          <tr>
                            <td>
                              How long has it been since you last had symptoms
                              or pain?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .disorderHowLongSymptomsHas
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Have you had any surgical treatment?</td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven.surgicalTreatmentYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven.surgicalTreatmentYes ? (
                            <tr>
                              <td>Surgical treatment details</td>
                              <td>
                                {formatFormData(
                                  props.formData.formEleven
                                    .dateAndProcedureNames
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              Are you in any way restricted in your daily
                              activities as a consequence of this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven.anyRestrictedYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven.anyRestrictedYes ? (
                            <tr>
                              <td>Restriction details</td>
                              <td>
                                {props.formData.formEleven.disorderDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven.disorderTimeHadOffWork
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ) : disorder === "sciatica" ? (
                        <tbody>
                          <tr>
                            <td>
                              How long has it been since you last had symptoms
                              or pain?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .SciaticaHowLongSymptomsHas
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How many reoccurrences have you had since symptoms
                              first commenced?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .SciaticaHowManyReoccurrences
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Are you in any way restricted in your daily
                              activities as a consequence of this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .SciaticaAnyRestrictedYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven
                            .SciaticaAnyRestrictedYes ? (
                            <tr>
                              <td>Restriction details</td>
                              <td>
                                {props.formData.formEleven.SciaticaDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven.SciaticaTimeHadOffWork
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ) : disorder === "trauma_or_accident" ? (
                        <tbody>
                          <tr>
                            <td>What was the joint disorder?</td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .traumaOrAccidentJointDisorder
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Which joints were affected?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formEleven
                                  .traumaOrAccidentAffectedJoint
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How long has it been since you last had symptoms
                              or pain?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .traumaOrAccidentHowLongSymptomsHas
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How many reoccurrences have you had since symptoms
                              first commenced?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .traumaOrAccidentHowManyReoccurrences
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Are you in any way restricted in your daily
                              activities as a consequence of this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .traumaOrAccidentAnyRestrictedYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven
                            .traumaOrAccidentAnyRestrictedYes === "yes" ? (
                            <tr>
                              <td>Restriction details</td>
                              <td>
                                {
                                  props.formData.formEleven
                                    .traumaOrAccidentDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .traumaOrAccidentTimeHadOffWork
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ) : disorder === "boneFractures" ? (
                        <tbody>
                          <tr>
                            <td>Which joints were affected?</td>
                            <td>
                              {arrayToFormattedList(
                                props.formData.formEleven.affectedJoint
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven.affectedArm &&
                          props.formData.formEleven.affectedArm[0] !== "" && // Avoiding type error in early form responses where default data was not an empty array
                          props.formData.formEleven.affectedArm.length !== 0 ? (
                            <tr>
                              <td>Which arm bone(s)?</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formEleven.affectedArm
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {props.formData.formEleven.affectedLeg &&
                          props.formData.formEleven.affectedLeg[0] !== "" && // Avoiding type error in early form responses where default data was not an empty array
                          props.formData.formEleven.affectedLeg.length !== 0 ? (
                            <tr>
                              <td>Which leg bone(s)?</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formEleven.affectedLeg
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {props.formData.formEleven.affectedFoot &&
                          props.formData.formEleven.affectedFoot[0] !== "" && // Avoiding type error in early form responses where default data was not an empty array
                          props.formData.formEleven.affectedFoot.length !==
                            0 ? (
                            <tr>
                              <td>Which foot bone(s)?</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formEleven.affectedFoot
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>When did this occur?</td>
                            <td>{props.formData.formEleven.whenOccur}</td>
                          </tr>
                          <tr>
                            <td>What was the treatment?</td>
                            <td>{props.formData.formEleven.whatTreatment}</td>
                          </tr>
                          <tr>
                            <td>
                              Are you in any way restricted in your daily
                              activities as a consequence of this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .BoneFracturesAnyRestrictedYes
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven
                            .BoneFracturesAnyRestrictedYes ? (
                            <tr>
                              <td>Restriction details</td>
                              <td>
                                {props.formData.formEleven.BoneFracturesDetails}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>
                              How much time have you had off work as a result of
                              this condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .BoneFracturesTimeHadOffWork
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ) : disorder === "muscle_Disorder" ? (
                        <tbody>
                          <tr>
                            <td>What was the condition?</td>
                            <td>
                              {
                                props.formData.formEleven
                                  .MuscleDisorderConditions
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What was the treatment?</td>
                            <td>
                              {
                                props.formData.formEleven
                                  .MuscleDisorderTreatment
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do you have ongoing treatment or pain from this
                              condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .MuscleDisorderOngoingTreatmentOrPain
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven
                            .MuscleDisorderOngoingTreatmentOrPain === "yes" ? (
                            <tr>
                              <td>Treatment details</td>
                              <td>
                                {
                                  props.formData.formEleven
                                    .MuscleDisorderDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : disorder === "jointBoneOrMuscle" ? (
                        <tbody>
                          <tr>
                            <td>What was the condition?</td>
                            <td>
                              {
                                props.formData.formEleven
                                  .JointBoneOrMuscleConditions
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>What was the treatment?</td>
                            <td>
                              {
                                props.formData.formEleven
                                  .JointBoneOrMuscleTreatment
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do you have ongoing treatment or pain from this
                              condition?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formEleven
                                  .JointBoneOrMuscleOngoingTreatmentOrPain
                              )}
                            </td>
                          </tr>
                          {props.formData.formEleven
                            .JointBoneOrMuscleOngoingTreatmentOrPain ===
                          "yes" ? (
                            <tr>
                              <td>Treatment details</td>
                              <td>
                                {
                                  props.formData.formEleven
                                    .JointBoneOrMuscleDetails
                                }
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                )
              )}
              {props.formData.formTwentyTwo.consultations.map(
                (consult, index) => (
                  <Tab.Pane eventKey={`#musculoskeletalConsult-${index + 1}`}>
                    <Table
                      id="form-view-musculoskeletal-consult-table"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr className="border-0">
                          <th
                            id="question-heading"
                            className="border-0 p-0"
                          ></th>
                          <th id="answer-heading" className="border-0 p-0"></th>
                        </tr>
                      </thead>
                      {consult.length !== 0 ? (
                        <tbody>
                          <tr>
                            <td>
                              Have you already disclosed this anywhere else in
                              this survey?
                            </td>
                            <td>
                              {formatFormData(
                                props.formData.formTwentyTwo.isDisclosed
                              )}
                            </td>
                          </tr>
                          {props.formData.formTwentyTwo.isDisclosed ===
                            "false" && (
                            <>
                              <tr>
                                <td>
                                  Which Part of the body did you attend for?
                                </td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formTwentyTwo
                                      .whichBodyPartconsultant
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Please provide details</td>
                                <td>
                                  {formatFormData(
                                    props.formData.formTwentyTwo
                                      .consultantDetails
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </Tab.Pane>
                )
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default MusculoskeletalTab;
