import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const howLong = [
  {
    label: "Symptoms are ongoing",
    value: "SymptomsAreOngoing",
  },
  {
    label: "Less than 12 months",
    value: "lessThan12Months",
  },
  {
    label: "1-2 years",
    value: "OneTwoYears",
  },
  {
    label: "2-3 years",
    value: "TowThreeYears",
  },
  {
    label: "3-5 years",
    value: "ThreeFiveYears",
  },
  {
    label: "5+ years ago",
    value: "moreThanFiveYears",
  },
];

const timeList = [
  {
    label: "No time off work",
    value: "noTimeOffWork",
  },
  {
    label: "Less than 1 weeks",
    value: "lessThan1Weeks",
  },
  {
    label: "1-2 weeks",
    value: "oneTwoWeeks",
  },
  {
    label: "3-4 week",
    value: "ThreeFourWeeks",
  },
  {
    label: "4+ weeks",
    value: "moreThanFourWeeks",
  },
];

const howManyOccupance = [
  {
    label: "One-off occupance only",
    value: "OneOccupance",
  },
  {
    label: "1-2 reoccurrences",
    value: "OneTwoReoccurrences",
  },
  {
    label: "3+ reoccurrences",
    value: "MoreThanThreeTRoccurrences",
  },
];

const resetArray = [
  { name: "SciaticaHowLongSymptomsHas", type: "" },
  { name: "SciaticaHowManyReoccurrences", type: "" },
  { name: "SciaticaAnyRestrictedYes", type: "" },
  { name: "SciaticaDetails", type: "" },
  { name: "SciaticaTimeHadOffWork", type: "" },
];
export default function SubFormTwo({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Sciatica", value: "sciatica" }]}
        name="subFormsOfFourteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFourteen.includes("sciatica") && (
        <RadioMulti
          question="How long has it been since you last had symptoms or pain?"
          name="SciaticaHowLongSymptomsHas"
          items={howLong}
          isSubForm
        />
      )}
      {values.subFormsOfFourteen.includes("sciatica") &&
        values.SciaticaHowLongSymptomsHas && (
          <RadioMulti
            question="How many reoccurrences have you had since symptoms first commenced?"
            name={"SciaticaHowManyReoccurrences"}
            items={howManyOccupance}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("sciatica") &&
        values.SciaticaHowManyReoccurrences && (
          <RadioMulti
            question="Are you in any way restricted in your daily activities as a consequence of this condition?"
            name={"SciaticaAnyRestrictedYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfFourteen.includes("sciatica") &&
        values.SciaticaAnyRestrictedYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="SciaticaDetails"
            isSubForm
          />
        )}
      {(values.SciaticaDetails || values.SciaticaAnyRestrictedYes === "no") &&
        values.subFormsOfFourteen.includes("sciatica") && (
          <RadioMulti
            question="How much time have you had off work as a result of this condition?"
            name={"SciaticaTimeHadOffWork"}
            items={timeList}
            isSubForm
          />
        )}
    </Fragment>
  );
}
