import React from "react";
import { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioMulti from "../RadioMulti";
import CheckBox from "../CheckBox";

const listOfSymptoms = [
  { label: "Gastric Ulcer", value: "GastricUlcer", checked: true },
  { label: "Duodenal Ulcer", value: "DuodenalUlcer", checked: true },
];

const resetArray = [
  { name: "hadUlcer", type: "" },
  { name: "treatmentAndOutcomeUl", type: "" },
  { name: "isSymptomsGone", type: "" },
  { name: "symtomsStillThere", type: "" },
  { name: "whenTreated", type: [] },
];

export default function FormUlcer({ values, setFieldValue, setFieldTouched }) {
  const resetFoldCheck = (arr, check) => {
    const oops = values.illness.every((val) => check.indexOf(val) < 0);
    if (!oops) {
      for (const item of arr) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={listOfSymptoms}
        name="illness"
        question=""
        onClick={() =>
          resetFoldCheck(resetArray, ["GastricUlcer", "DuodenalUlcer"])
        }
      />
      {(values.illness.includes("GastricUlcer") ||
        values.illness.includes("DuodenalUlcer")) && (
        <Fragment>
          <YesNoQuestion
            question="Have you had any investigations such as gastroscopy/endoscopy in the past 2 years for this ulcer?"
            name="hadUlcer"
            isSubForm
          />
          {values.hadUlcer === "true" && (
            <AnswerBox
              name="treatmentAndOutcomeUl"
              label="Please provide details on treatment and outcome of treatment including date of the issue"
              isSubForm
            />
          )}
          <RadioMulti
            question="When was the ulcer treated?"
            name="whenTreated"
            items={[
              { label: "Last 6 months", value: "Last 6 months" },
              { label: "6 months to 1 year", value: "6 months to 1 year" },
              { label: "More than 1 year", value: "More than 1 year" },
              { label: "never", value: "never" },
            ]}
            isSubForm
          />
          <YesNoQuestion
            question="Have symptoms ceased since completing/commencing treatment?"
            name="isSymptomsGone"
            isSubForm
          />
          {values.isSymptomsGone === "false" && (
            <AnswerBox
              name="symtomsStillThere"
              label="Please provide details of the condition and diagnosis as well as any treatment provided"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
