import React from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useStyles } from "./classes";
import { Typography } from "@material-ui/core";

export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container justifycontent="space-around" style={{ padding: 10 }}>
          <Grid item alignself="center">
            <Paper style={{ padding: 40 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "justify",
                  fontSize: "2rem",
                }}
                className={classes.paragraph}
              >
                Positioning script of conversation with client
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                To ensure we obtain the best insurance cover for you, we have
                you pre-assessed first. Every insurer assesses an individual
                based on health and pastime activities which may result in
                substandard terms being offered. By completing a preassessment
                before submitting an application, we can understand what terms
                would likely be offered for your specific circumstances. This
                can save a lot of time and trouble down the road if any
                unexpected issues arise. It also means the insurance process is
                sped up and streamlined, so you can rest easy knowing you are
                covered.
              </Typography>
              <Typography
                style={{ color: "#000", textAlign: "justify", paddingTop: 5 }}
                className={classes.paragraph1}
              >
                From here, we will send you a personal link that contains the
                Health and Pursuits Questionnaire. It will ask you questions
                such as your overall health and occupation details. Please make
                sure you answer honestly and if you have any questions, please
                reach out.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
