import React, { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";
const resetArray = [
  { name: 'CancerOtherThanSkinDetails', type: '' }
];

export default function SubFormTwo({ values, setFieldValue, setFieldTouched
}) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[
          { label: "Cancer other than skin", value: "cancerOtherThanSkin" },
        ]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />

      {values.subFormsOfSixteen.includes("cancerOtherThanSkin") && (
        <AnswerBox
          label="Please provide details?"
          name="CancerOtherThanSkinDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
