import axios from "axios";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
} from "react-bootstrap";
import { Form as BootstrapForm } from "react-bootstrap-formik";
import * as Yup from "yup";
import "./Modals.css";

const PreferredUnderwritersModal = (props) => {
  const [userData, setUserData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [underwriters, setUnderwriters] = useState([]);
  const [addingUnderwriter, setAddingUnderwriter] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    contactNumber: "",
    companyId: "",
    state: "",
    advisorCodes: {},
  });

  const getUserData = async () => {
    try {
      const { data } = await axios.get("/auth/me");
      setUserData(data);
      // Populate formik initial values from previous user preferences
      setInitialValues({
        ...initialValues,
        preferredUnderwriters: data.PreferredList,
        advisorCodes: data.advisorCodes,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await axios.get("/company");
      setCompanies(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUnderwriters = async () => {
    try {
      const { data } = await axios.get("/underwriter");
      setUnderwriters(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddingUnderwriterShow = () => {
    setAddingUnderwriter(true);
  };

  const handleAddingUnderwriterHide = () => {
    setAddingUnderwriter(false);
  };

  const preferredUnderwritersSchema = Yup.object().shape({
    advisorCodes: Yup.object({
      test: Yup.object({
        code: Yup.string().required(
          "Please enter your advisor code or toggle off"
        ),
      }),
    }),
  });

  const submitForm = async (e, { setSubmitting }) => {
    try {
      await axios.put("/underwriter/advisor-favourites", {
        underwriter: e.preferredUnderwriters,
        advisorCodes: e.advisorCodes,
      });
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
    window.location.href = "/preferences/?underwritersUpdated=success";
  };

  const submitAddUnderwriterForm = async (e, { setSubmitting, actions }) => {
    console.log(e);
    try {
      await axios.post("/underwriter", {
        name: e.name,
        email: e.email,
        contactNumber: e.contactNumber,
        companyId: e.companyId,
        state: e.state,
      });
      setSubmitting(false);
      getUnderwriters();
      actions.resetForm({
        values: initialValues,
      });
    } catch (error) {
      console.log(error);
    }
    handleAddingUnderwriterHide();
  };

  const states = [
    "Australia wide",
    "Northern Territory",
    "Western Australia",
    "Tasmania",
    "South Australia",
    "Queensland",
    "New South Wales",
    "Victoria",
    "Australian Capital Territory",
  ];

  useEffect(() => {
    getUnderwriters();
    getCompanies();
  }, []);

  useEffect(() => {
    getUserData();
  }, [underwriters]);

  return (
    <>
      {!initialValues.preferredUnderwriters ? ( // Delay form load until initialValues state is populated
        ""
      ) : addingUnderwriter ? ( // Show 'add underwriter form' triggered by modal footer link
        <Formik
          initialValues={initialValues}
          onSubmit={submitAddUnderwriterForm}
        >
          {(formik) => (
            <Form id="addUnderwriterForm" onSubmit={formik.handleSubmit}>
              <Modal
                size="xl"
                show={props.modalShow}
                onHide={props.handleModalClose}
              >
                <Modal.Header>Add underwriter</Modal.Header>
                <Modal.Body>
                  <BootstrapForm.Input label="Underwriter's name" name="name" />
                  <BootstrapForm.Input
                    label="Underwriter's email"
                    name="email"
                  />
                  <BootstrapForm.Input
                    label="Underwriter's phone number"
                    name="contactNumber"
                  />
                  <BootstrapForm.Select
                    className="form-select"
                    label="Insurance company"
                    name="companyId"
                    placeholder="Select from dropdown"
                  >
                    {companies.map((company) => (
                      <option value={company._id}>{company.name}</option>
                    ))}
                  </BootstrapForm.Select>
                  <BootstrapForm.Select
                    className="form-select"
                    label="State"
                    name="state"
                    placeholder="Select from dropdown"
                  >
                    {states.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
                  </BootstrapForm.Select>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleAddingUnderwriterHide}
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    form="addUnderwriterForm"
                  >
                    Add underwriter
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={initialValues}
          // validationSchema={preferredUnderwritersSchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form id="preferredUnderwriterForm" onSubmit={formik.handleSubmit}>
              <Modal
                show={props.modalShow}
                onHide={props.handleModalClose}
                dialogClassName="modal-w90"
                aria-labelledby="preferredUnderwritersModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="preferredUnderwritersModal">
                    Select preferred underwriters
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container fluid>
                    <BootstrapForm.Group name="preferredUnderwriters">
                      <Row>
                        {companies.length !== 0
                          ? companies.map((company, index) => (
                              <>
                                <Col xs={4} className="py-3">
                                  <Row>
                                    <Col xs={3}>
                                      <img
                                        className="company-logos"
                                        src={company.logo}
                                        alt={`${company.name} logo`}
                                      />
                                    </Col>
                                    <Col xs={9}>
                                      <Col
                                        xs={12}
                                        className="d-flex justify-content-between"
                                      >
                                        <h5 className="d-inline-block">
                                          {company.name}
                                        </h5>
                                        <small>
                                          <Form.Switch
                                            className="d-inline-block"
                                            label="Advisor code"
                                            name={`advisorCodesRequired${company._id}`}
                                            checked={
                                              formik.values.advisorCodes?.[
                                                company._id
                                              ]
                                                ? formik.values.advisorCodes?.[
                                                    company._id
                                                  ]?.required
                                                : true
                                            }
                                            {...formik.getFieldProps(
                                              `advisorCodes[${company._id}].required`
                                            )}
                                          />
                                        </small>
                                      </Col>
                                      <Col xs={12} className="pb-2">
                                        <FormControl
                                          className="advisor-code-field"
                                          type="text"
                                          size="sm"
                                          placeholder={`${company.name} advisor code`}
                                          name={`advisorCodes${company._id}`}
                                          disabled={
                                            formik.values.advisorCodes?.[
                                              company._id
                                            ]?.required
                                              ? !formik.values.advisorCodes?.[
                                                  company._id
                                                ]?.required
                                              : true
                                          }
                                          {...formik.getFieldProps(
                                            `advisorCodes[${company._id}].code`
                                          )}
                                        />
                                      </Col>
                                      <Col xs={12}>
                                        {/* Map default (undefined advisorID) and custom underwriters to each company */}
                                        {underwriters.map((underwriter) =>
                                          underwriter.advisorId === undefined ||
                                          (underwriter.advisorId &&
                                            underwriter.advisorId._id ===
                                              userData._id) ? (
                                            <>
                                              {underwriter.companyId._id ===
                                              company._id ? (
                                                <BootstrapForm.Checkbox
                                                  custom
                                                  key={`underwriter${index}`}
                                                  name={underwriter._id}
                                                  label={underwriter.email}
                                                ></BootstrapForm.Checkbox>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )
                                        )}
                                      </Col>
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            ))
                          : ""}
                      </Row>
                    </BootstrapForm.Group>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <a onClick={handleAddingUnderwriterShow} className="me-auto">
                    Add additional underwriters
                  </a>
                  <Button variant="secondary" onClick={props.handleModalClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    form="preferredUnderwriterForm"
                  >
                    Update preferred underwriters
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default PreferredUnderwritersModal;
