import React, { Fragment } from 'react';
import { TableCell, Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import ReusableTable from '../../components/layouts/ReusableTable';

const headers = [
  {
    text: 'Company',
    value: 'name',
  },
  {
    text: 'Contact Number',
    value: 'contactNumber',
  },
  {
    text: 'Logo',
    value: 'price',
  },
  {
    text: 'ABN',
    value: 'brn',
  },
  {
    text: 'email',
    value: 'email',
  },
  {
    text: 'Address',
    value: 'address',
  },
  {
    text: 'Current Status',
    value: 'currentStatus',
  },
  {
    text: 'Change Status',
    value: 'status',
  },
  {
    text: 'Update',
    value: 'update',
  },
];
export default function CompanyView(props) {
  const TableB = ({ item, changeStatus, classes, onUpdate }) => {
    return (
      <Fragment>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.name}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.contactNumber}
        </TableCell>
        <TableCell key='image'>
          <img src={item.logo} style={{ maxHeight: '100px' }} alt=' '></img>
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.BRN}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.email}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.address}
        </TableCell>
        {item.status ? (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            {' '}
            <Chip label='Active' className={classes.green} />
          </TableCell>
        ) : (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            <Chip label='Inactive' className={classes.red} />
          </TableCell>
        )}

        <TableCell key='status'>
          {item.status ? (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              size='small'
              className={classes.red}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              size='small'
              className={classes.green}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Activate
            </Button>
          )}
        </TableCell>
        <TableCell key='update'>
          <Button
            style={{ fontFamily: 'Overpass, sans-serif' }}
            variant='contained'
            className={classes.grey}
            size='small'
            onClick={() => onUpdate(item._id)}
          >
            update
          </Button>
        </TableCell>
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title='View Companies'
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl='/company' //change
      statusChangeUrl='/company/update-status'
      updatePath='/company-update'
      {...props}
    />
  );
}
