import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const FamilyHistoryTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-history-tabs"
          defaultActiveKey="#history-1"
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formThree.diseases.map((disease, index) => (
                <ListGroup.Item action href={`#history-${index + 1}`}>
                  {disease
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    disease
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formThree.diseases.map((disease, index) => (
                <Tab.Pane eventKey={`#history-${index + 1}`}>
                  <Table
                    id="form-view-history-table"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {disease === "Heart disease" ? (
                      // Heart disease responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.heart
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.heartRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.heartDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Breast Cancer" ? (
                      // Breast cancer responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.breastCancer
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.breastRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.breastDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Colon (bowel) cancer" ? (
                      // Colon (bowel) cancer responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.colonCancer
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.colonRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.colonDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Polycystic Kidney disease" ? (
                      // Polycystic kidney disease responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.kidneyDisease
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.kidneyRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.kidneyDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Diabetes" ? (
                      // Diabetes responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.diabetes
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.diabetesRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.diabetesDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Mental Disorder" ? (
                      // Mental disorder responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.mentalDisorder
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.mentalRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.mentalDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Stroke" ? (
                      // Stroke responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.stroke
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.strokeRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.strokeDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : disease === "Huntington's Disease" ? (
                      // Huntington's disease responses conditionally rendered based on array map
                      <tbody>
                        <tr>
                          <td>Who was the family member?</td>
                          <td>
                            {props.formData.formThree
                              ? arrayToFormattedList(
                                  props.formData.formThree.huntingtonDisease
                                )
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the onset age?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.huntingRadio
                              : "Loading..."}
                          </td>
                        </tr>
                        <tr>
                          <td>What was the age of death if applicable?</td>
                          <td>
                            {props.formData.formThree
                              ? props.formData.formThree.huntingDeath
                              : "Loading..."}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default FamilyHistoryTab;
