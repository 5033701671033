import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const yearsList = [
  {
    label: "Less than 2 years ago",
    value: "lessThanTwo",
  },
  {
    label: "2-5 years ago",
    value: "betweenTwoAndFive",
  },
  {
    label: "5+ years ago",
    value: "moreThanFive",
  },
];
const years = [
  {
    label: "Within the past 12 months",
    value: "withinPast12Months",
  },
  {
    label: "More than 12 months ago",
    value: "moreThan12Months",
  },
];
const treatedList = [
  {
    label: "Burnt or frozen off (cryotherapy)",
    value: "cryotherapy",
  },
  {
    label: "Cut out (excised)",
    value: "excised",
  },
  {
    label: "Cream/ointment",
    value: "creamOrOintment",
  },
  {
    label: "Not removed",
    value: "notRemoved",
  },
];
const resetArray = [
  { name: "moleOrSunSpotsHowSkinGrowthTreated", type: [] },
  { name: "moleOrSunSpotsWhenWasItRemoved", type: "" },
  { name: "moleOrSunSpotsCreamOintmentDetails", type: "" },
  { name: "moleOrSunSpotsWhenSkinGrowthFirst", type: "" },
  { name: "moleOrSunSpotsConfirmedAsBenign", type: "" },
  { name: "moleOrSunSpotsSCC", type: "" },
  { name: "moleOrSunSpotsTreatmentRecommendedOrRequired", type: "" },
  {
    name: "moleOrSunSpotsTreatmentRecommendedOrRequiredDetails",
    type: "",
  },
  { name: "moleOrSunSpotsNeedOtherTreatmentForSkinGrowth", type: "" },
  {
    name: "moleOrSunSpotsNeedOtherTreatmentForSkinGrowthDetails",
    type: "",
  },
];
export default function SubFormThree({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Mole or sun spots", value: "moleOrSunSpots" }]}
        name="subFormsOfFormFifteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormFifteen.includes("moleOrSunSpots") && (
        <CheckBox
          question="How was the skin growth treated?"
          list={treatedList}
          name="moleOrSunSpotsHowSkinGrowthTreated"
          isSubForm
        />
      )}
      {(values.moleOrSunSpotsHowSkinGrowthTreated.includes("excised") ||
        values.moleOrSunSpotsHowSkinGrowthTreated.includes("cryotherapy")) && (
        <>
          <RadioMulti
            question="When was it removed?"
            name={"moleOrSunSpotsWhenWasItRemoved"}
            items={yearsList}
            isSubForm
          />
          <RadioMulti
            question="Was it confirmed as being Benign (non cancerous)?"
            name={"moleOrSunSpotsConfirmedAsBenign"}
            items={items}
            isSubForm
          />
          {values.moleOrSunSpotsConfirmedAsBenign === "no" && (
            <RadioMulti
              question="Was it a Squamous Cell Carcinoma (SCC)?"
              name={"moleOrSunSpotsSCC"}
              items={items}
              isSubForm
            />
          )}
          {values.moleOrSunSpotsConfirmedAsBenign && (
            <RadioMulti
              question="Other than being cut out was there any other treatment recommended or required?"
              name={"moleOrSunSpotsTreatmentRecommendedOrRequired"}
              items={items}
              isSubForm
            />
          )}
          {values.moleOrSunSpotsTreatmentRecommendedOrRequired === "yes" && (
            <AnswerBox
              label="Please provide details of the other treatment"
              name="moleOrSunSpotsTreatmentRecommendedOrRequiredDetails"
              isSubForm
            />
          )}
        </>
      )}

      {values.moleOrSunSpotsHowSkinGrowthTreated.includes(
        "creamOrOintment"
      ) && (
        <AnswerBox
          label="Please provide details about the cream or ointment treatment"
          name="moleOrSunSpotsCreamOintmentDetails"
          isSubForm
        />
      )}

      {values.moleOrSunSpotsHowSkinGrowthTreated.includes("notRemoved") && (
        <>
          <RadioMulti
            question="When was the skin growth first checked by a medical practitioner?"
            name={"moleOrSunSpotsWhenSkinGrowthFirst"}
            items={years}
            isSubForm
          />
          <RadioMulti
            question="Other than regular checks have you been advised you need other treatment for the skin growth?"
            name={"moleOrSunSpotsNeedOtherTreatmentForSkinGrowth"}
            items={items}
            isSubForm
          />
          {values.moleOrSunSpotsNeedOtherTreatmentForSkinGrowth === "yes" && (
            <AnswerBox
              label="Please provide details"
              name="moleOrSunSpotsNeedOtherTreatmentForSkinGrowthDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
