import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form, Field } from "formik";

import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import * as Yup from "yup";
import { RadioGroup } from "formik-material-ui";
import YesNoQuestion from "./YesNoQuestion";
import AnswerBox from "./AnswerBox";
import { useParams } from "react-router-dom";
import { useStyles } from "../video/classes";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";

export default function FormOne() {
  const { id, advisor, client } = useParams();
  const classes = useStyles();
  // eslint-disable-next-line
  const [sender, setSender] = useState({});
  const [gender, setGender] = useState("");
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    height: "",
    weigth: "",
    disability: "",
    disabilityYes: "",
    claimed: "",
    claimedYes: "",
    smoker: "",
    standardDrinks: "",
    smokerQuantity: "",
    lastSmoke: "",
    // yesThen: "",
    // noThen: "",
    illicitDrugs: "",
    illicitDrugsYes: "",
    pregnant: "",
    overseas: "",
    overseasCity: "",
    overseasDuration: "",
    overseasReason: "",
    travelOutside: "",
    travelOutsideYes: "",
  });
  const validationSchema = Yup.object().shape({
    height: Yup.number().max(999, "Maximum height is 1000cm").required(),
    weigth: Yup.number()
      .max(999, "Maximum weight is 1000kg")
      .required("This field is required"),
    disability: Yup.string().required(),
    disabilityYes: Yup.string().when("disability", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    claimed: Yup.string().required(),
    claimedYes: Yup.string().when("claimed", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    smoker: Yup.string().required("This field is required"),
    standardDrinks: Yup.string().required("This field is required"),
    smokerQuantity: Yup.string().when("smoker", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    lastSmoke: Yup.string().when("smoker", {
      is: "false",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    illicitDrugs: Yup.string().required(),
    illicitDrugsYes: Yup.string().when("illicitDrugs", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    pregnant: Yup.string().required(),
    overseas: Yup.string().required(),
    overseasCity: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    overseasDuration: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    overseasReason: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    travelOutside: Yup.string().required(),

    travelOutsideYes: Yup.string().when("travelOutside", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
  });

  const validationSchema2 = Yup.object().shape({
    height: Yup.number().max(999, "Maximum height is 1000cm").required(),
    weigth: Yup.number()
      .max(999, "Maximum weight is 1000kg")
      .required("weight is required"),
    disability: Yup.string().required(),
    disabilityYes: Yup.string().when("disability", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    claimed: Yup.string().required(),
    claimedYes: Yup.string().when("claimed", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    smoker: Yup.string().required(),
    standardDrinks: Yup.string().required(),
    smokerQuantity: Yup.string().when("smoker", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    lastSmoke: Yup.string().when("smoker", {
      is: "false",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    illicitDrugs: Yup.string().required(),
    illicitDrugsYes: Yup.string().when("illicitDrugs", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    overseas: Yup.string().required(),
    overseasCity: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    overseasDuration: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    overseasReason: Yup.string().when("overseas", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    travelOutside: Yup.string().required(),

    travelOutsideYes: Yup.string().when("travelOutside", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
  });
  const dispatch = useDispatch();

  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.post("/form/add", {
        advisor,
        formOne: e,
        pointer: 3,
        formid: id,
        client,
      });
      dispatch(setPage(3));
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      const { data } = await axios.post("/auth/me", {
        advisor,
      });
      setSender(data);

      const user = await axios.get(`/client/get/${client}`);
      console.log(user);
      setGender(user.data.gender);
    } catch (error) {}
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={styles.wrapper}>
      <Grid item md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={
            gender === "Female" ? validationSchema : validationSchema2
          }
          enableReinitialize
        >
          {({ isValid, dirty, values }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem", paddingTop: 0 }}>
                  <CardContent>
                    <Grid container item xs={12} sm={12} md={12}>
                      <AnswerBox
                        name="height"
                        label="What is your height? (CM)"
                      />
                      <AnswerBox
                        name="weigth"
                        label="What is your weight? (KG)"
                      />
                      <YesNoQuestion
                        question="Have you ever been declined or accepted on special terms for your life, disability or trauma insurance
                          cover?"
                        name="disability"
                      />

                      {values.disability === "true" && (
                        <AnswerBox
                          name="disabilityYes"
                          label="Why was cover declined or what special terms were
                          offered?"
                          isSubForm
                        />
                      )}

                      <YesNoQuestion
                        question=" Have you ever claimed from any source (excluding
                        unemployment) eg:- accident, sickness, workers
                        compensation, disability pension or income protection?"
                        name="claimed"
                      />

                      {values.claimed === "true" && (
                        <AnswerBox
                          name="claimedYes"
                          label="Provide details of claimed benefits, including the date when claimed"
                          isSubForm
                        />
                      )}

                      <Grid item xs={12} sm={12} md={12}>
                        <p className={styles.question}>
                          In the last 12 months have you smoked tobacco or any
                          other substance including e-cigarettes?
                        </p>
                        <Field component={RadioGroup} name="smoker" row>
                          <FormControlLabel
                            control={<Radio />}
                            label={
                              <span
                                style={{
                                  fontFamily: "Overpass, sans-serif",
                                }}
                              >
                                Yes
                              </span>
                            }
                            value={"true"}
                            color="blue"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio />}
                            label={
                              <span
                                style={{
                                  fontFamily: "Overpass, sans-serif",
                                }}
                              >
                                No
                              </span>
                            }
                            color="red"
                          />
                          <FormControlLabel
                            value={"lifelongNonSmoker"}
                            control={<Radio />}
                            label={
                              <span
                                style={{
                                  fontFamily: "Overpass, sans-serif",
                                }}
                              >
                                Life long non-smoker
                              </span>
                            }
                            color="red"
                          />
                        </Field>
                      </Grid>
                      {values.smoker === "false" && (
                        <AnswerBox
                          name="lastSmoke"
                          label="When did you last smoke?"
                          isSubForm
                        />
                      )}
                      {values.smoker === "true" && (
                        <AnswerBox
                          name="smokerQuantity"
                          label="Please provide substance and details of quantity eg:- 4 cigarettes a day, E-cigarettes"
                          isSubForm
                        />
                      )}
                      <AnswerBox
                        name="standardDrinks"
                        label="How many standard drinks would you have per week on average?"
                      />
                      <YesNoQuestion
                        question="Have you ever used illicit drugs or received advice, treatment or counselling for the use of alcohol or illicit drugs?"
                        name="illicitDrugs"
                      />

                      {values.illicitDrugs === "true" && (
                        <AnswerBox
                          name="illicitDrugsYes"
                          label="Provide details of the substance and the date of when you last used it?"
                          isSubForm
                        />
                      )}
                      {gender === "Female" && (
                        <YesNoQuestion
                          question="Are you pregnant?"
                          name="pregnant"
                        />
                      )}

                      <YesNoQuestion
                        question="Do you have any definite plans to travel or reside overseas?"
                        name="overseas"
                      />

                      {values.overseas === "true" && (
                        <AnswerBox
                          name="overseasCity"
                          label="What is the city that you are travelling to?"
                          rows={1}
                          isSubForm
                        />
                      )}

                      {values.overseas === "true" && (
                        <AnswerBox
                          name="overseasDuration"
                          label="How long do you intend to be away?"
                          rows={1}
                          isSubForm
                        />
                      )}

                      {values.overseas === "true" && (
                        <AnswerBox
                          name="overseasReason"
                          label="What is the reason for travel?"
                          rows={1}
                          isSubForm
                        />
                      )}

                      <YesNoQuestion
                        question="Have you travelled outside of Australia in the past month?"
                        name="travelOutside"
                      />

                      {values.travelOutside === "true" && (
                        <AnswerBox
                          name="travelOutsideYes"
                          label="Where did you travel to, and for how long were you away?"
                          isSubForm
                        />
                      )}
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.btn}
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
