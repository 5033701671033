import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const yearsList = [
  {
    label: "Less than 2 years ago",
    value: "lessThan2years",
  },
  {
    label: "2-5 years ago",
    value: "between2And5Years",
  },
  {
    label: "More than 5 years ago",
    value: "moreThan5Years",
  },
];
const bigYearsList = [
  {
    label: "Less than 10 years ago",
    value: "lessThan10years",
  },
  {
    label: "More than 10 years ago",
    value: "moreThan10Years",
  },
];

const biopsyList = [
  {
    label: "Basal Cell Carcinoma (BCC)",
    value: "BCC",
  },
  {
    label: "Melanoma",
    value: "melanoma",
  },
  {
    label: "Squamous Cell Carcinoma (SCC)",
    value: "SCC",
  },
  {
    label: "Other /unknown",
    value: "OtherUnknown",
  },
  {
    label: "no biopsy",
    value: "noBiopsy",
  },
];

const treatmentList = [
  {
    label: "Burnt or frozen off (cryotherapy)",
    value: "cryotherapy",
  },
  {
    label: "Cut out (excised)",
    value: "excised",
  },
  {
    label: "Cream/ointment",
    value: "creamOintment",
  },
  {
    label: "Not removed",
    value: "notRemoved",
  },
];
const resetArray = [
  { name: "cancerSkinTreatment", type: [] },
  { name: "whenWasCancerRemoved", type: [] },
  { name: "treatmentDetails", type: "" },
  { name: "whenWasCancerNotRemoved", type: "" },
  { name: "notRemovedDetails", type: "" },
  { name: "whatDiBiopsyConfirm", type: [] },
  { name: "BasalCellCarcinomasYes", type: "" },
  { name: "BasalCellCarcinomasDetails", type: "" },
  { name: "anyOtherTreatmentYes", type: "" },
  { name: "OtherTreatmentDetails", type: "" },
];

export default function SubFormOne({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Cancer-skin", value: "cancerSkin" }]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfSixteen.includes("cancerSkin") && (
        <CheckBox
          question="How was the skin growth treated?"
          list={treatmentList}
          name={"cancerSkinTreatment"}
          isSubForm
        />
      )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.cancerSkinTreatment.includes("excised") && (
          <RadioMulti
            question="When was the cancer removed?"
            name={"whenWasCancerRemoved"}
            items={yearsList}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.cancerSkinTreatment.includes("creamOintment") && (
          <AnswerBox
            label="Please provide details?"
            name="treatmentDetails"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.cancerSkinTreatment.includes("notRemoved") && (
          <RadioMulti
            question="When was the skin caner that was not removed 1st detected?"
            name={"whenWasCancerNotRemoved"}
            items={bigYearsList}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.whenWasCancerNotRemoved === "lessThan10years" && (
          <AnswerBox
            label="Please provide details?"
            name="notRemovedDetails"
            isSubForm
          />
        )}
      {(values.whenWasCancerRemoved.includes("lessThan2years") ||
        values.whenWasCancerRemoved.includes("between2And5Years") ||
        values.whenWasCancerRemoved.includes("moreThan5Years")) &&
        values.subFormsOfSixteen.includes("cancerSkin") && (
          <CheckBox
            question="What did the biopsy confirm?"
            name={"whatDiBiopsyConfirm"}
            list={biopsyList}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.whatDiBiopsyConfirm.includes("BCC") && (
          <RadioMulti
            question="Have you had 3 or more Basal cell carcinomas?"
            name={"BasalCellCarcinomasYes"}
            items={items}
            isSubForm
          />
        )}

      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.BasalCellCarcinomasYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="BasalCellCarcinomasDetails"
            isSubForm
          />
        )}
      {(values.whatDiBiopsyConfirm.includes("melanoma") ||
        values.whatDiBiopsyConfirm.includes("SCC") ||
        values.whatDiBiopsyConfirm.includes("creamOintment") ||
        values.whatDiBiopsyConfirm.includes("noBiopsy") ||
        values.BasalCellCarcinomasDetails !== "") &&
        values.subFormsOfSixteen.includes("cancerSkin") && (
          <RadioMulti
            question="Other than having the skin cancer cut out did you have or were you advised to have any other treatment? (eg.radiotherapy)"
            name={"anyOtherTreatmentYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cancerSkin") &&
        values.anyOtherTreatmentYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="OtherTreatmentDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
