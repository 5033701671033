// Auth actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TOGGLE = "TOGGLE";

//signUp Actions
export const FIRST = "FIRST";
export const SECOND = "SECOND";
export const NAME = "NAME";

//Other actions
export const COMPANY_SWITCH = "COMPANY_SWITCH";

//form
export const SET_PAGE = "SET_PAGE";
