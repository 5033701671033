import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Form as BootstrapForm } from "react-bootstrap-formik";
import "./Modals.css";

const UnderwriterModal = (props) => {
  const [underwriters, setUnderwriters] = useState([]);
  const [userData, setUserData] = useState([]);
  const [initialValues, setInitialValues] = useState({
    AIASelections: [],
    AMPSelections: [],
    BTFinancialGroupSelections: [],
    ClearViewSelections: [],
    IntegritySelections: [],
    MLCSelections: [],
    MetlifeSelections: [],
    NEOSSelections: [],
    OnePathSelections: [],
    TALSelections: [],
    ZurichSelections: [],
    EncompassSelections: [],
  });

  const fetchUnderwriters = async () => {
    try {
      const response = await axios.get("/underwriter/getMyUnderWriters2");
      setUnderwriters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get("/auth/me");
      setUserData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    // Merge formik returned values into single array and POST to API
    let underwriterIDs = [];
    Object.values(values).forEach((value) => {
      underwriterIDs.push(...value);
    });
    try {
      await axios.post("/underwriter/sendresponse", {
        underwriters: underwriterIDs,
        formId: props.assessmentID,
      });
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log(error);
    }

    window.location.href = "/advisor-dashboard/?underwriterSent=success";
  };

  useEffect(() => {
    fetchUnderwriters();
    fetchUserData();
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm}>
      {(formik) => (
        <Form id="underwriterSelectForm" onSubmit={formik.handleSubmit}>
          <Modal
            size="xl"
            show={props.modalShow}
            onHide={props.handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select underwriters for submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  {underwriters.length !== 0
                    ? underwriters.map((item) => (
                        <>
                          <Col xs={6} className="py-3">
                            <Row>
                              <Col xs={3}>
                                <img
                                  className="company-logos"
                                  src={item.companyId.logo}
                                  alt={`${item.companyId.name} logo`}
                                />
                              </Col>
                              <Col xs={9}>
                                <Col xs={12}>
                                  <h5 className="mb-0 d-inline-block">
                                    {item.companyId.name}&nbsp;
                                  </h5>
                                  {userData.advisorCodes?.[item.companyId._id]?.required ? (
                                    <span className="small">
                                      - Advisor code:{" "}
                                      {
                                        userData.advisorCodes?.[
                                          item.companyId._id
                                        ]?.code
                                      }
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                                <Col xs={12} className="pt-2">
                                  <BootstrapForm.Group
                                    name={`${item.companyId.name.replace(
                                      /\s/g,
                                      ""
                                    )}Selections`}
                                  >
                                    {item.underwriters.map((underwriter) => (
                                      <BootstrapForm.Checkbox
                                        custom
                                        name={underwriter._id}
                                        label={underwriter.email}
                                      ></BootstrapForm.Checkbox>
                                    ))}
                                  </BootstrapForm.Group>
                                </Col>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ))
                    : ""}
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.handleModalClose}>
                Close
              </Button>
              <Button type="submit" form="underwriterSelectForm">
                Send assessment
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default UnderwriterModal;
