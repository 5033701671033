import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const lumpsTimeList = [
  {
    label: "1",
    value: "one",
  },
  {
    label: "More than 1",
    value: "moreThan1",
  },
];

const timeList = [
  {
    label: "Less than 5 years ago",
    value: "lessThan5years",
  },
  {
    label: "5-10 years ago",
    value: "between5And10Years",
  },
  {
    label: "More than 10 years ago",
    value: "moreThan10Years",
  },
];

const lumpTreatedList = [
  {
    label: "Surgery - lumpectomy or mastectomy",
    value: "surgery",
  },
  {
    label: "Aspirated (drained)",
    value: "aspirated",
  },
  {
    label: "No treatment necessary",
    value: "noTreatment",
  },
  {
    label: "Still present with regular checkups",
    value: "RegularCheckups",
  },
];

const resetArray = [
  { name: "howManyBreastLumps", type: [] },
  { name: "howWasLumpTreated", type: [] },
  { name: "surgeryLumpsDetected", type: "" },
  { name: "surgeryWhenWasItOperatedOn", type: "" },
  { name: "AspiratedWhenWasTheLumpAspirated", type: [] },
  { name: "AspiratedWhenWereTheLumpsDetected", type: "" },
  { name: "AspiratedWhenWasItOperatedOn", type: "" },
  { name: "AspiratedLumpConfirmedYes", type: "" },
  { name: "AspiratedLumpConfirmedDetails", type: "" },
  { name: "AspiratedSubsequentMammogramsYes", type: "" },
  { name: "regularCheckupsLumpsDetected", type: "" },
  { name: "regularCheckupsWhenWasItOperatedOn", type: "" },
  { name: "regularCheckupsSubsequentMammogramsYes", type: "" },
];

export default function SubFormFour({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Breast Cyst", value: "breastCyst" }]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfSixteen.includes("breastCyst") && (
        <CheckBox
          question="How many Breast lumps have you had?"
          list={lumpsTimeList}
          name={"howManyBreastLumps"}
          isSubForm
        />
      )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.howManyBreastLumps.length !== 0 && (
          <CheckBox
            question="How was lump treated?"
            list={lumpTreatedList}
            name={"howWasLumpTreated"}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.howWasLumpTreated.includes("surgery") && (
          <AnswerBox
            label="When were the lumps detected?"
            name="surgeryLumpsDetected"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.surgeryLumpsDetected !== "" && (
          <AnswerBox
            label="What procedure did you have and when was it operated on?"
            name="surgeryWhenWasItOperatedOn"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.howWasLumpTreated.includes("aspirated") && (
          <CheckBox
            question="When was the lump aspirated?"
            list={timeList}
            name={"AspiratedWhenWasTheLumpAspirated"}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.AspiratedWhenWasTheLumpAspirated.includes("lessThan5years") && (
          <AnswerBox
            label="When were the lumps detected?"
            name="AspiratedWhenWereTheLumpsDetected"
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("breastCyst") &&
        values.AspiratedWhenWereTheLumpsDetected !== "" && (
          <AnswerBox
            label="What procedure did you have and when was it operated on?"
            name="AspiratedWhenWasItOperatedOn"
            isSubForm
          />
        )}
      {(values.AspiratedWhenWasTheLumpAspirated.includes(
        "between5And10Years"
      ) ||
        values.AspiratedWhenWasTheLumpAspirated.includes("moreThan10Years") ||
        values.AspiratedWhenWasTheLumpAspirated.includes(
          "between5And10Years"
        ) ||
        values.AspiratedWhenWasItOperatedOn !== "") &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <RadioMulti
            question="Was the lump confirmed as benign?"
            name={"AspiratedLumpConfirmedYes"}
            items={items}
            isSubForm
          />
        )}
      {values.AspiratedLumpConfirmedYes === "yes" &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <AnswerBox
            label="Please provide details?"
            name="AspiratedLumpConfirmedDetails"
            isSubForm
          />
        )}
      {(values.AspiratedLumpConfirmedDetails !== "" ||
        values.AspiratedLumpConfirmedYes === "no") &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <RadioMulti
            question="Have your results of subsequent mammograms all been normal?"
            name={"AspiratedSubsequentMammogramsYes"}
            items={items}
            isSubForm
          />
        )}
      {(values.howWasLumpTreated.includes("noTreatment") ||
        values.howWasLumpTreated.includes("RegularCheckups")) &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <AnswerBox
            label="When were the lumps detected?"
            name="regularCheckupsLumpsDetected"
            isSubForm
          />
        )}
      {values.regularCheckupsLumpsDetected !== "" &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <AnswerBox
            label="What procedure did you have and when was it operated on?"
            name="regularCheckupsWhenWasItOperatedOn"
            isSubForm
          />
        )}
      {values.regularCheckupsWhenWasItOperatedOn !== "" &&
        values.subFormsOfSixteen.includes("breastCyst") && (
          <RadioMulti
            question="Have your results of subsequent mammograms all been normal?"
            name={"regularCheckupsSubsequentMammogramsYes"}
            items={items}
            isSubForm
          />
        )}
    </Fragment>
  );
}
