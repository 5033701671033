import React, { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line
// FORM [2,2]

const disorderList = [
  {
    label: "Prostate enlargement",
    value: "prostateEnlargement",
  },
  {
    label: "Prostate inflammation/prostatitis",
    value: "prostateInflammation&prostatitis",
  },
  {
    label: "Prostate cancer",
    value: "prostateCancer",
  },
  {
    label: "Other",
    value: "other",
  },
];
const resetArray = [
  { name: "prostateDisorderDiagnosedAs", type: [] },
  { name: "prostateDisorderWhenThisOccur", type: "" },
  { name: "prostateDisorderWhatTreatments", type: "" },
  { name: "otherDetails", type: "" },
];
export default function SubFormThree({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Prostate Disorder", value: "prostateDisorder" }]}
        name="subFormsOfFormSeventeen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormSeventeen.includes("prostateDisorder") && (
        <CheckBox
          question="What was your prostate disorder diagnosed as?"
          list={disorderList}
          name="prostateDisorderDiagnosedAs"
          isSubForm
        />
      )}
      {values.subFormsOfFormSeventeen.includes("prostateDisorder") &&
        values.prostateDisorderDiagnosedAs.includes("prostateCancer") && (
          <AnswerBox
            label="When did this occur?"
            name="prostateDisorderWhenThisOccur"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("prostateDisorder") &&
        values.prostateDisorderWhenThisOccur && (
          <AnswerBox
            label="What was the treatment that was recommended by a medical professional?"
            name="prostateDisorderWhatTreatments"
            isSubForm
          />
        )}
      {values.subFormsOfFormSeventeen.includes("prostateDisorder") &&
        values.prostateDisorderDiagnosedAs.includes("other") && (
          <AnswerBox
            label="Please provide details?"
            name="otherDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
