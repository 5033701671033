import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "2rem",
    textAlign: "center",
    marginTop: 35,
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },

  root: {
    flexGrow: 1,
    padding: "5%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  pbox: {
    padding: "2%",
  },
  text: {
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xl")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  box: {
    display: "flex",
    justifyContent: "center",
  },

  img: {
    width: "50%",
    height: "50%",
  },
}));
