import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import { Formik, Form } from "formik";
import { newAssessmentSchema } from "../../schemas";
import { Form as BootstrapForm } from "react-bootstrap-formik";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Alert,
} from "react-bootstrap";
import NavBar from "../../components/layouts/AdvisorNavBar";
import Heading from "../../components/layouts/AdvisorHeading";
import "./NewAssessment.css";
import Footer from "../../components/Footer/Footer";
import { MobileOnlyView } from "react-device-detect";
import MobileViewport from "../mobileViewport/MobileViewport";

const NewAssessment = () => {
  const [initialValues, setInitialValues] = useState({
    firstName: new URLSearchParams(window.location.search).get("firstName")
      ? new URLSearchParams(window.location.search).get("firstName")
      : "",
    lastName: new URLSearchParams(window.location.search).get("lastName")
      ? new URLSearchParams(window.location.search).get("lastName")
      : "",
    email: "",
    dob: "",
    mobile: "",
    gender: "",
    occupation: "",
    requiredInsurance: [],
    lifeInsuranceSum: "",
    tpdInsuranceSum: "",
    traumaInsuranceSum: "",
    incomeProtectionInsuranceSum: "",
  });

  // State referenced by form fields to dictate enabled/disabled parameter
  const [enabledFields, setEnabledFields] = useState({
    life: false,
    tpd: false,
    trauma: false,
    incomeProtection: false,
  });

  const [occupations, setOccupations] = useState([]);

  const getOccupations = async () => {
    const response = await axios.get("/occupation/me");

    let occupationsUnsorted = [];
    for (const occupation in response.data) {
      occupationsUnsorted.push(response.data[occupation].name);
    }

    const occupationsSorted = occupationsUnsorted.sort();
    setOccupations(occupationsSorted);
  };

  const submitForm = async (e) => {
    try {
      let insuranceType = [];
      e.requiredInsurance.forEach((type) => {
        let sum;
        switch (type) {
          case "life":
            sum = e.lifeInsuranceSum;
            break;

          case "tpd":
            sum = e.tpdInsuranceSum;
            break;

          case "trauma":
            sum = e.traumaInsuranceSum;
            break;

          case "incp":
            sum = e.incomeProtectionInsuranceSum;
            break;

          default:
            break;
        }
        insuranceType.push({
          inType: type,
          sum: Number(sum),
        });
      });

      let reformattedMobile;
      if (e.mobile.charAt(0) === "0") {
        reformattedMobile = e.mobile.replace("0", "+61");
      }

      let userData = await axios.post("/client", {
        firstName: e.firstName,
        lastName: e.lastName,
        gender: e.gender,
        dob: e.dob,
        email: e.email,
        occupation: e.occupation,
        mobile: reformattedMobile,
        insuranceType,
      });
      console.log(userData.data);
      SendSms(userData.data);
    } catch (error) {
      if (error.response.status === 403) {
        console.log(error);
      }
    }
  };

  const SendSms = async (userData) => {
    const date = new Date();
    try {
      await axios.put("/client/sms", {
        advisor: userData.advisor,
        formSentDate: date,
        pid: userData.pid,
        id: userData.id,
      });
    } catch (error) {
      console.log(error);
    }
    window.location.href = "advisor-dashboard/?newAssessment=success";
  };

  useEffect(() => {
    getOccupations();
  }, []);

  return (
    <>
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="h-100">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col className="p-4">
                <Row>
                  <Heading heading="Start new assessment" />
                  <Col xs={6}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={submitForm}
                      validationSchema={newAssessmentSchema}
                    >
                      <Form>
                        <Row className="g-4">
                          <Col xs={12} xxl={4}>
                            <BootstrapForm.Input
                              label="First name"
                              name="firstName"
                              placeholder="First name"
                            />
                          </Col>
                          <Col xs={12} xxl={4}>
                            <BootstrapForm.Input
                              label="Last name"
                              name="lastName"
                              placeholder="Last name"
                            />
                          </Col>
                          <Col xs={12} xxl={4}>
                            <BootstrapForm.Select
                              className="form-select"
                              label="Gender"
                              name="gender"
                              placeholder="Select from dropdown"
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </BootstrapForm.Select>
                          </Col>
                          <Col xs={12} xxl={6}>
                            <BootstrapForm.Input
                              type="email"
                              label="Email address"
                              name="email"
                              placeholder="Email address"
                              helpText="An email and sms will be sent with the assessment link"
                            />
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Input
                              type="tel"
                              label="Mobile number"
                              name="mobile"
                              placeholder="Mobile number"
                            />
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Input
                              type="date"
                              label="Date of birth"
                              name="dob"
                            />
                          </Col>
                          <Col xs={12}>
                            <BootstrapForm.Select
                              className="form-select"
                              label="Occupation"
                              name="occupation"
                              placeholder="Select from dropdown"
                            >
                              {occupations.map((occupation) => (
                                <option value={occupation}>{occupation}</option>
                              ))}
                            </BootstrapForm.Select>
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Group name="requiredInsurance">
                              <BootstrapForm.Checkbox
                                custom
                                label="Life"
                                name="life"
                                onClick={() => {
                                  setEnabledFields((prevState) => ({
                                    ...prevState,
                                    life: !enabledFields.life,
                                  }));
                                }}
                              />
                              {enabledFields.life ? (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    name="lifeInsuranceSum"
                                    helpText="Sum insured"
                                  />
                                </InputGroup>
                              ) : (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    disabled
                                    name="lifeInsuranceSum"
                                    helpText="Sum insured"
                                  />{" "}
                                </InputGroup>
                              )}
                            </BootstrapForm.Group>
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Group name="requiredInsurance">
                              <BootstrapForm.Checkbox
                                custom
                                label="TPD"
                                name="tpd"
                                onClick={() => {
                                  setEnabledFields((prevState) => ({
                                    ...prevState,
                                    tpd: !enabledFields.tpd,
                                  }));
                                }}
                              />
                              {enabledFields.tpd ? (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    name="tpdInsuranceSum"
                                    helpText="Sum insured"
                                  />{" "}
                                </InputGroup>
                              ) : (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    disabled
                                    name="tpdInsuranceSum"
                                    helpText="Sum insured"
                                  />{" "}
                                </InputGroup>
                              )}
                            </BootstrapForm.Group>
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Group name="requiredInsurance">
                              <BootstrapForm.Checkbox
                                custom
                                label="Trauma"
                                name="trauma"
                                onClick={() => {
                                  setEnabledFields((prevState) => ({
                                    ...prevState,
                                    trauma: !enabledFields.trauma,
                                  }));
                                }}
                              />
                              {enabledFields.trauma ? (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    name="traumaInsuranceSum"
                                    helpText="Sum insured"
                                  />
                                </InputGroup>
                              ) : (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    disabled
                                    name="traumaInsuranceSum"
                                    helpText="Sum insured"
                                  />
                                </InputGroup>
                              )}
                            </BootstrapForm.Group>
                          </Col>
                          <Col xs={12} xxl={3}>
                            <BootstrapForm.Group name="requiredInsurance">
                              <BootstrapForm.Checkbox
                                custom
                                label="Income protection"
                                name="incp"
                                onClick={() => {
                                  setEnabledFields((prevState) => ({
                                    ...prevState,
                                    incomeProtection:
                                      !enabledFields.incomeProtection,
                                  }));
                                }}
                              />
                              {enabledFields.incomeProtection ? (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    name="incomeProtectionInsuranceSum"
                                    helpText="Annual income"
                                  />
                                </InputGroup>
                              ) : (
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text style={{ height: "38px" }}>
                                    $
                                  </InputGroup.Text>
                                  <BootstrapForm.Input
                                    className="sum-field"
                                    disabled
                                    name="incomeProtectionInsuranceSum"
                                    helpText="Annual income"
                                  />
                                </InputGroup>
                              )}
                            </BootstrapForm.Group>
                          </Col>
                          <Col xs={12}>
                            <Button type="submit">Send questionnaire</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Formik>
                  </Col>
                  <Col xs={6}>
                    <Alert variant="info">
                      <h5>Pre Assess Me process</h5>
                      <p>
                        Please add in your clients basic details and the sums
                        insured you would like to have preassessed and the
                        annual income for income protection purposes. Please
                        note that the client will not be able to see the sums
                        insured you have entered. This will be used to send to
                        the underwriter later in the process.
                      </p>
                      <p>
                        This will send a questionnaire to the client by both
                        email and also via text to the client to complete.
                      </p>
                    </Alert>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default NewAssessment;
