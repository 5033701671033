import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import { underwriterReplySchema } from "../../schemas";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Form as BootstrapForm } from "react-bootstrap-formik";

const UnderwriterReplyForm = (props) => {
  const [initialValues, setInitialValues] = useState({
    //--Hidden state - immutable in form--//
    assessmentID: props.assessmentID,

    //--Form section one - Underwriter details--//
    underwriterName: props.underwriterName,
    underwriterCompany: props.underwriterCompany,
    underwriterEmail: props.underwriterEmail,
    underwriterNumber: props.underwriterNumber,
    underwriterReference: "",

    //--Form section two - Insurance acceptances--//
    lifeCovered: "",
    tpdCovered: "",
    traumaCovered: "",
    incomeProtectionCovered: "",

    //--Form section three - Terms tabs (Life)--//
    lifeTermsOffered: "",
    lifeLoading: "",
    lifeLoadingTimeFrame: "",
    lifeLoadingTimeFrameDetails: "",
    lifeLoadingFurtherInfo: "",
    lifeLoadingFurtherInfoDetails: "",
    lifeExclusion: "",
    lifeExclusionTimeFrame: "",
    lifeExclusionTimeFrameDetails: "",
    lifeExclusionFurtherInfo: "",
    lifeExclusionFurtherInfoDetails: "",
    lifeNoTermsReason: "",

    //--Form section three - Terms tabs (TPD)--//
    tpdTermsOffered: "",
    tpdLoading: "",
    tpdLoadingTimeFrame: "",
    tpdLoadingTimeFrameDetails: "",
    tpdLoadingFurtherInfo: "",
    tpdLoadingFurtherInfoDetails: "",
    tpdExclusion: "",
    tpdExclusionTimeFrame: "",
    tpdExclusionTimeFrameDetails: "",
    tpdExclusionFurtherInfo: "",
    tpdExclusionFurtherInfoDetails: "",
    tpdNoTermsReason: "",

    //--Form section three - Terms tabs (Trauma)--//
    traumaTermsOffered: "",
    traumaLoading: "",
    traumaLoadingTimeFrame: "",
    traumaLoadingTimeFrameDetails: "",
    traumaLoadingFurtherInfo: "",
    traumaLoadingFurtherInfoDetails: "",
    traumaExclusion: "",
    traumaExclusionTimeFrame: "",
    traumaExclusionTimeFrameDetails: "",
    traumaExclusionFurtherInfo: "",
    traumaExclusionFurtherInfoDetails: "",
    traumaNoTermsReason: "",

    //--Form section three - Terms tabs (Income protection)--//
    incomeProtectionTermsOffered: "",
    incomeProtectionLoading: "",
    incomeProtectionLoadingTimeFrame: "",
    incomeProtectionLoadingTimeFrameDetails: "",
    incomeProtectionLoadingFurtherInfo: "",
    incomeProtectionLoadingFurtherInfoDetails: "",
    incomeProtectionExclusion: "",
    incomeProtectionExclusionTimeFrame: "",
    incomeProtectionExclusionTimeFrameDetails: "",
    incomeProtectionExclusionFurtherInfo: "",
    incomeProtectionExclusionFurtherInfoDetails: "",
    incomeProtectionNoTermsReason: "",

    //--Form section four - Additional general info--//
    additionalInfo: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [nonStandardTerms, setNonStandardTerms] = useState({
    life: {
      accepted: true,
      loading: { offered: false, timeFrame: false, furtherInfo: false },
      exclusion: { offered: false, timeFrame: false, furtherInfo: false },
      none: false,
    },
    tpd: {
      accepted: true,
      loading: { offered: false, timeFrame: false, furtherInfo: false },
      exclusion: { offered: false, timeFrame: false, furtherInfo: false },
      none: false,
    },
    trauma: {
      accepted: true,
      loading: { offered: false, timeFrame: false, furtherInfo: false },
      exclusion: { offered: false, timeFrame: false, furtherInfo: false },
      none: false,
    },
    incomeProtection: {
      accepted: true,
      loading: { offered: false, timeFrame: false, furtherInfo: false },
      exclusion: { offered: false, timeFrame: false, furtherInfo: false },
      none: false,
    },
  });

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post("/form/addres", {
        replyID: `${props.assessmentID}-${props.underwriterID}`,
        assessmentID: props.assessmentID,
        underwriterReplyForm: values,
        advisorID: props.advisorID,
        underwriterEmail: props.underwriterEmail,
        pid: props.pid,
      });
      setSubmitting(false);
      resetForm();
      setFormSubmitted(true);
    } catch (error) {
      alert(`Form submission has failed.\n${error}`);
      console.log(error);
    }
  };

  return (
    <>
      {formSubmitted ? (
        <>
          <h4>Assessment outcome form submitted</h4>
          <p>
            Thank you for your submission. {props.advisorName} from {props.advisorFirm} has
            received your response.
          </p>
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={underwriterReplySchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="g-3 mb-3">
                <Col xs={6}>
                  <BootstrapForm.Input
                    name="underwriterName"
                    placeholder="Underwriter's name"
                  />
                </Col>
                <Col xs={6}>
                  <BootstrapForm.Input
                    name="underwriterCompany"
                    placeholder="Underwriter's company"
                  />
                </Col>
                <Col xs={6}>
                  <BootstrapForm.Input
                    type="email"
                    name="underwriterEmail"
                    placeholder="Underwriter's email"
                  />
                </Col>
                <Col xs={6}>
                  <BootstrapForm.Input
                    type="tel"
                    name="underwriterNumber"
                    placeholder="Underwriter's phone number"
                  />
                </Col>
                <Col xs={6}>
                  <BootstrapForm.Input
                    name="underwriterReference"
                    placeholder="Underwriter's reference"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} className="d-flex justify-content-between">
                  <span>
                    Is life cover accepted at standard rates and terms?
                  </span>
                  <BootstrapForm.Group
                    name="lifeCovered"
                    className="inline-group"
                  >
                    <BootstrapForm.Radio
                      custom
                      name="lifeCoveredTrue"
                      label="Yes"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          life: {
                            ...prevState.life,
                            accepted: true,
                            loading: {
                              ...prevState.life.loading,
                              offered: false,
                            },
                            exclusion: {
                              ...prevState.life.exclusion,
                              offered: false,
                            },
                            none: false,
                          },
                        }));
                        formik.setFieldValue("lifeTermsOffered", "");
                        formik.setFieldTouched("lifeTermsOffered", false);
                      }}
                    ></BootstrapForm.Radio>
                    <BootstrapForm.Radio
                      custom
                      name="lifeCoveredFalse"
                      label="No"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          life: { ...prevState.life, accepted: false },
                        }));
                      }}
                    ></BootstrapForm.Radio>
                  </BootstrapForm.Group>
                </Col>
                <Col xs={12} className="d-flex justify-content-between">
                  <span>
                    Is TPD cover accepted at standard rates and terms?
                  </span>
                  <BootstrapForm.Group
                    name="tpdCovered"
                    className="inline-group"
                  >
                    <BootstrapForm.Radio
                      custom
                      name="tpdCoveredTrue"
                      label="Yes"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          tpd: {
                            ...prevState.tpd,
                            accepted: true,
                            loading: {
                              ...prevState.tpd.loading,
                              offered: false,
                            },
                            exclusion: {
                              ...prevState.tpd.exclusion,
                              offered: false,
                            },
                            none: false,
                          },
                        }));
                        formik.setFieldValue("tpdTermsOffered", "");
                        formik.setFieldTouched("tpdTermsOffered", false);
                      }}
                    ></BootstrapForm.Radio>
                    <BootstrapForm.Radio
                      custom
                      name="tpdCoveredFalse"
                      label="No"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          tpd: { ...prevState.tpd, accepted: false },
                        }));
                      }}
                    ></BootstrapForm.Radio>
                  </BootstrapForm.Group>
                </Col>
                <Col xs={12} className="d-flex justify-content-between">
                  <span>
                    Is trauma cover accepted at standard rates and terms?
                  </span>
                  <BootstrapForm.Group
                    name="traumaCovered"
                    className="inline-group"
                  >
                    <BootstrapForm.Radio
                      custom
                      name="traumaCoveredTrue"
                      label="Yes"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          trauma: {
                            ...prevState.trauma,
                            accepted: true,
                            loading: {
                              ...prevState.trauma.loading,
                              offered: false,
                            },
                            exclusion: {
                              ...prevState.trauma.exclusion,
                              offered: false,
                            },
                            none: false,
                          },
                        }));
                        formik.setFieldValue("traumaTermsOffered", "");
                        formik.setFieldTouched("traumaTermsOffered", false);
                      }}
                    ></BootstrapForm.Radio>
                    <BootstrapForm.Radio
                      custom
                      name="traumaCoveredFalse"
                      label="No"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          trauma: { ...prevState.trauma, accepted: false },
                        }));
                      }}
                    ></BootstrapForm.Radio>
                  </BootstrapForm.Group>
                </Col>
                <Col xs={12} className="d-flex justify-content-between">
                  <span>
                    Is income protection accepted at standard rates and terms?
                  </span>
                  <BootstrapForm.Group
                    name="incomeProtectionCovered"
                    className="inline-group"
                  >
                    <BootstrapForm.Radio
                      custom
                      name="incomeProtectionCoveredTrue"
                      label="Yes"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          incomeProtection: {
                            ...prevState.incomeProtection,
                            accepted: true,
                            loading: {
                              ...prevState.incomeProtection.loading,
                              offered: false,
                            },
                            exclusion: {
                              ...prevState.incomeProtection.exclusion,
                              offered: false,
                            },
                            none: false,
                          },
                        }));
                        formik.setFieldValue(
                          "incomeProtectionTermsOffered",
                          ""
                        );
                        formik.setFieldTouched(
                          "incomeProtectionTermsOffered",
                          false
                        );
                      }}
                    ></BootstrapForm.Radio>
                    <BootstrapForm.Radio
                      custom
                      name="incomeProtectionCoveredFalse"
                      label="No"
                      onClick={() => {
                        setNonStandardTerms((prevState) => ({
                          ...prevState,
                          incomeProtection: {
                            ...prevState.incomeProtection,
                            accepted: false,
                          },
                        }));
                      }}
                    ></BootstrapForm.Radio>
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row className="g-3">
                <Col id="underwriterReplyFormTerms" xs={12}>
                  <Tabs
                    defaultActiveKey="lifeTab"
                    id="nonStandardTermsTabs"
                    fill
                  >
                    <Tab
                      eventKey="lifeTab"
                      title="Life insurance terms"
                      disabled={nonStandardTerms.life.accepted}
                    >
                      <Row className="p-3">
                        <Col xs={12}>
                          <BootstrapForm.Group
                            className={
                              nonStandardTerms.life.accepted
                                ? "inline-group disabled-text"
                                : "inline-group"
                            }
                            name="lifeTermsOffered"
                            label="What are the terms offered for life insurance?"
                          >
                            <BootstrapForm.Radio
                              name="Loading"
                              label="Loading"
                              disabled={nonStandardTerms.life.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  life: {
                                    ...prevState.life,
                                    loading: {
                                      ...prevState.life.loading,
                                      offered: true,
                                    },
                                    exclusion: {
                                      ...prevState.life.exclusion,
                                      offered: false,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="Exclusion"
                              label="Exclusion"
                              disabled={nonStandardTerms.life.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  life: {
                                    ...prevState.life,
                                    loading: {
                                      ...prevState.life.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.life.exclusion,
                                      offered: true,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="None"
                              label="No terms offered"
                              disabled={nonStandardTerms.life.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  life: {
                                    ...prevState.life,
                                    loading: {
                                      ...prevState.life.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.life.exclusion,
                                      offered: false,
                                    },
                                    none: true,
                                  },
                                }));
                              }}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        {nonStandardTerms.life.loading.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="lifeLoading"
                                label="What is the loading?"
                                disabled={nonStandardTerms.life.accepted}
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the loading?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.life.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="lifeLoadingTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="lifeLoadingTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        loading: {
                                          ...prevState.life.loading,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="lifeLoadingTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        loading: {
                                          ...prevState.life.loading,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.life.loading.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="lifeLoadingTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.life.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.life.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="lifeLoadingFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="lifeLoadingFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        loading: {
                                          ...prevState.life.loading,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="lifeLoadingFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        loading: {
                                          ...prevState.life.loading,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.life.loading.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="lifeLoadingFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.life.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.life.exclusion.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="lifeExclusion"
                                label="What are the exclusions?"
                                disabled={nonStandardTerms.life.accepted}
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the exclusions?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.life.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="lifeExclusionTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="lifeExclusionTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        exclusion: {
                                          ...prevState.life.exclusion,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="lifeExclusionTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        exclusion: {
                                          ...prevState.life.exclusion,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.life.exclusion.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="lifeExclusionTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.life.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.life.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.life.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="lifeExclusionFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="lifeExclusionFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        exclusion: {
                                          ...prevState.life.exclusion,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="lifeExclusionFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.life.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      life: {
                                        ...prevState.life,
                                        exclusion: {
                                          ...prevState.life.exclusion,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.life.exclusion.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="lifeExclusionFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.life.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.life.none ? (
                          <Col
                            xs={12}
                            className={
                              nonStandardTerms.life.accepted
                                ? "pt-3 disabled-text"
                                : "pt-3"
                            }
                          >
                            <BootstrapForm.Input
                              name="lifeNoTermsReason"
                              label="Why have no terms been offered?"
                              disabled={nonStandardTerms.life.accepted}
                            />
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="tpdTab"
                      title="TPD insurance terms"
                      disabled={nonStandardTerms.tpd.accepted}
                    >
                      <Row className="p-3">
                        <Col xs={12}>
                          <BootstrapForm.Group
                            className={
                              nonStandardTerms.tpd.accepted
                                ? "inline-group disabled-text"
                                : "inline-group"
                            }
                            name="tpdTermsOffered"
                            label="What are the terms offered for TPD insurance?"
                          >
                            <BootstrapForm.Radio
                              name="Loading"
                              label="Loading"
                              disabled={nonStandardTerms.tpd.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  tpd: {
                                    ...prevState.tpd,
                                    loading: {
                                      ...prevState.tpd.loading,
                                      offered: true,
                                    },
                                    exclusion: {
                                      ...prevState.tpd.exclusion,
                                      offered: false,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="Exclusion"
                              label="Exclusion"
                              disabled={nonStandardTerms.tpd.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  tpd: {
                                    ...prevState.tpd,
                                    loading: {
                                      ...prevState.tpd.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.tpd.exclusion,
                                      offered: true,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="None"
                              label="No terms offered"
                              disabled={nonStandardTerms.tpd.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  tpd: {
                                    ...prevState.tpd,
                                    loading: {
                                      ...prevState.tpd.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.tpd.exclusion,
                                      offered: false,
                                    },
                                    none: true,
                                  },
                                }));
                              }}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        {nonStandardTerms.tpd.loading.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="tpdLoading"
                                label="What is the loading?"
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the loading?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.tpd.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="tpdLoadingTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="tpdLoadingTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        loading: {
                                          ...prevState.tpd.loading,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="tpdLoadingTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        loading: {
                                          ...prevState.tpd.loading,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.tpd.loading.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="tpdLoadingTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.tpd.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.tpd.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="tpdLoadingFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="tpdLoadingFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        loading: {
                                          ...prevState.tpd.loading,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="tpdLoadingFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        loading: {
                                          ...prevState.tpd.loading,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.tpd.loading.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="tpdLoadingFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.tpd.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.tpd.exclusion.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="tpdExclusion"
                                label="What are the exclusions?"
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the exclusions?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.tpd.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="tpdExclusionTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="tpdExclusionTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        exclusion: {
                                          ...prevState.tpd.exclusion,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="tpdExclusionTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        exclusion: {
                                          ...prevState.tpd.exclusion,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.tpd.exclusion.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="tpdExclusionTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.tpd.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.tpd.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.tpd.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="tpdExclusionFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="tpdExclusionFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        exclusion: {
                                          ...prevState.tpd.exclusion,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="tpdExclusionFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.tpd.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      tpd: {
                                        ...prevState.tpd,
                                        exclusion: {
                                          ...prevState.tpd.exclusion,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.tpd.exclusion.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="tpdExclusionFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.tpd.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.tpd.none ? (
                          <Col
                            xs={12}
                            className={
                              nonStandardTerms.tpd.accepted
                                ? "pt-3 disabled-text"
                                : "pt-3"
                            }
                          >
                            <BootstrapForm.Input
                              name="tpdNoTermsReason"
                              label="Why have no terms been offered?"
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="traumaTab"
                      title="Trauma insurance terms"
                      disabled={nonStandardTerms.trauma.accepted}
                    >
                      <Row className="p-3">
                        <Col xs={12}>
                          <BootstrapForm.Group
                            className={
                              nonStandardTerms.trauma.accepted
                                ? "inline-group disabled-text"
                                : "inline-group"
                            }
                            name="traumaTermsOffered"
                            label="What are the terms offered for trauma insurance?"
                          >
                            <BootstrapForm.Radio
                              name="Loading"
                              label="Loading"
                              disabled={nonStandardTerms.trauma.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  trauma: {
                                    ...prevState.trauma,
                                    loading: {
                                      ...prevState.trauma.loading,
                                      offered: true,
                                    },
                                    exclusion: {
                                      ...prevState.trauma.exclusion,
                                      offered: false,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="Exclusion"
                              label="Exclusion"
                              disabled={nonStandardTerms.trauma.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  trauma: {
                                    ...prevState.trauma,
                                    loading: {
                                      ...prevState.trauma.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.trauma.exclusion,
                                      offered: true,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="None"
                              label="No terms offered"
                              disabled={nonStandardTerms.trauma.accepted}
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  trauma: {
                                    ...prevState.trauma,
                                    loading: {
                                      ...prevState.trauma.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.trauma.exclusion,
                                      offered: false,
                                    },
                                    none: true,
                                  },
                                }));
                              }}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        {nonStandardTerms.trauma.loading.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="traumaLoading"
                                label="What is the loading?"
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the loading?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.trauma.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="traumaLoadingTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="traumaLoadingTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        loading: {
                                          ...prevState.trauma.loading,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="traumaLoadingTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        loading: {
                                          ...prevState.trauma.loading,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.trauma.loading.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="traumaLoadingTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.trauma.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.trauma.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="traumaLoadingFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="traumaLoadingFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        loading: {
                                          ...prevState.trauma.loading,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="traumaLoadingFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        loading: {
                                          ...prevState.trauma.loading,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.trauma.loading.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="traumaLoadingFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.trauma.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.trauma.exclusion.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="traumaExclusion"
                                label="What are the exclusions?"
                                disabled={nonStandardTerms.trauma.accepted}
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the exclusions?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.trauma.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="traumaExclusionTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="traumaExclusionTimeFrameTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        exclusion: {
                                          ...prevState.trauma.exclusion,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="traumaExclusionTimeFrameFalse"
                                  label="No"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        exclusion: {
                                          ...prevState.trauma.exclusion,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.trauma.exclusion.timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="traumaExclusionTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.trauma.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.trauma.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.trauma.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="traumaExclusionFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="traumaExclusionFurtherInfoTrue"
                                  label="Yes"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        exclusion: {
                                          ...prevState.trauma.exclusion,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="traumaExclusionFurtherInfoFalse"
                                  label="No"
                                  disabled={nonStandardTerms.trauma.accepted}
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      trauma: {
                                        ...prevState.trauma,
                                        exclusion: {
                                          ...prevState.trauma.exclusion,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.trauma.exclusion.furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="traumaExclusionFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={nonStandardTerms.trauma.accepted}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.trauma.none ? (
                          <Col
                            xs={12}
                            className={
                              nonStandardTerms.trauma.accepted
                                ? "pt-3 disabled-text"
                                : "pt-3"
                            }
                          >
                            <BootstrapForm.Input
                              name="traumaNoTermsReason"
                              label="Why have no terms been offered?"
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="incomeProtectionTab"
                      title="Income protection terms"
                      disabled={nonStandardTerms.incomeProtection.accepted}
                    >
                      <Row className="p-3">
                        <Col xs={12}>
                          <BootstrapForm.Group
                            className={
                              nonStandardTerms.incomeProtection.accepted
                                ? "inline-group disabled-text"
                                : "inline-group"
                            }
                            name="incomeProtectionTermsOffered"
                            label="What are the terms offered for income protection insurance?"
                          >
                            <BootstrapForm.Radio
                              name="Loading"
                              label="Loading"
                              disabled={
                                nonStandardTerms.incomeProtection.accepted
                              }
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  incomeProtection: {
                                    ...prevState.incomeProtection,
                                    loading: {
                                      ...prevState.incomeProtection.loading,
                                      offered: true,
                                    },
                                    exclusion: {
                                      ...prevState.incomeProtection.exclusion,
                                      offered: false,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="Exclusion"
                              label="Exclusion"
                              disabled={
                                nonStandardTerms.incomeProtection.accepted
                              }
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  incomeProtection: {
                                    ...prevState.incomeProtection,
                                    loading: {
                                      ...prevState.incomeProtection.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.incomeProtection.exclusion,
                                      offered: true,
                                    },
                                    none: false,
                                  },
                                }));
                              }}
                            />
                            <BootstrapForm.Radio
                              name="None"
                              label="No terms offered"
                              disabled={
                                nonStandardTerms.incomeProtection.accepted
                              }
                              onClick={() => {
                                setNonStandardTerms((prevState) => ({
                                  ...prevState,
                                  incomeProtection: {
                                    ...prevState.incomeProtection,
                                    loading: {
                                      ...prevState.incomeProtection.loading,
                                      offered: false,
                                    },
                                    exclusion: {
                                      ...prevState.incomeProtection.exclusion,
                                      offered: false,
                                    },
                                    none: true,
                                  },
                                }));
                              }}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        {nonStandardTerms.incomeProtection.loading.offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="incomeProtectionLoading"
                                label="What is the loading?"
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the loading?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.incomeProtection.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="incomeProtectionLoadingTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="incomeProtectionLoadingTimeFrameTrue"
                                  label="Yes"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        loading: {
                                          ...prevState.incomeProtection.loading,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="incomeProtectionLoadingTimeFrameFalse"
                                  label="No"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        loading: {
                                          ...prevState.incomeProtection.loading,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.incomeProtection.loading
                              .timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="incomeProtectionLoadingTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.incomeProtection.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="incomeProtectionLoadingFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="incomeProtectionLoadingFurtherInfoTrue"
                                  label="Yes"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        loading: {
                                          ...prevState.incomeProtection.loading,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="incomeProtectionLoadingFurtherInfoFalse"
                                  label="No"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        loading: {
                                          ...prevState.incomeProtection.loading,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.incomeProtection.loading
                              .furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="incomeProtectionLoadingFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.incomeProtection.exclusion
                            .offered ? (
                          <>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "pt-3 disabled-text"
                                  : "pt-3"
                              }
                            >
                              <BootstrapForm.Input
                                name="incomeProtectionExclusion"
                                label="What are the exclusions?"
                                disabled={
                                  nonStandardTerms.incomeProtection.accepted
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "pt-3 d-flex justify-content-between disabled-text"
                                  : "pt-3 d-flex justify-content-between"
                              }
                            >
                              <label>
                                Is there a time frame or trigger where you will
                                review the exclusions?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.incomeProtection.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="incomeProtectionExclusionTimeFrame"
                              >
                                <BootstrapForm.Radio
                                  name="incomeProtectionExclusionTimeFrameTrue"
                                  label="Yes"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        exclusion: {
                                          ...prevState.incomeProtection
                                            .exclusion,
                                          timeFrame: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="incomeProtectionExclusionTimeFrameFalse"
                                  label="No"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        exclusion: {
                                          ...prevState.incomeProtection
                                            .exclusion,
                                          timeFrame: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.incomeProtection.exclusion
                              .timeFrame ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="incomeProtectionExclusionTimeFrameDetails"
                                  placeholder="Please provide details"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              xs={12}
                              className={
                                nonStandardTerms.incomeProtection.accepted
                                  ? "d-flex justify-content-between disabled-text"
                                  : "d-flex justify-content-between"
                              }
                            >
                              <label>
                                Do you require any further information that may
                                change your assessment?
                              </label>
                              <BootstrapForm.Group
                                className={
                                  nonStandardTerms.incomeProtection.accepted
                                    ? "inline-group disabled-text"
                                    : "inline-group"
                                }
                                name="incomeProtectionExclusionFurtherInfo"
                              >
                                <BootstrapForm.Radio
                                  name="incomeProtectionExclusionFurtherInfoTrue"
                                  label="Yes"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        exclusion: {
                                          ...prevState.incomeProtection
                                            .exclusion,
                                          furtherInfo: true,
                                        },
                                      },
                                    }));
                                  }}
                                />
                                <BootstrapForm.Radio
                                  name="incomeProtectionExclusionFurtherInfoFalse"
                                  label="No"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                  onClick={() => {
                                    setNonStandardTerms((prevState) => ({
                                      ...prevState,
                                      incomeProtection: {
                                        ...prevState.incomeProtection,
                                        exclusion: {
                                          ...prevState.incomeProtection
                                            .exclusion,
                                          furtherInfo: false,
                                        },
                                      },
                                    }));
                                  }}
                                />
                              </BootstrapForm.Group>
                            </Col>
                            {nonStandardTerms.incomeProtection.exclusion
                              .furtherInfo ? (
                              <Col xs={12} className="pt-2 pb-4">
                                <BootstrapForm.Input
                                  name="incomeProtectionExclusionFurtherInfoDetails"
                                  placeholder="Please provide details"
                                  disabled={
                                    nonStandardTerms.incomeProtection.accepted
                                  }
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        ) : nonStandardTerms.incomeProtection.none ? (
                          <Col
                            xs={12}
                            className={
                              nonStandardTerms.incomeProtection.accepted
                                ? "pt-3 disabled-text"
                                : "pt-3"
                            }
                          >
                            <BootstrapForm.Input
                              name="incomeProtectionNoTermsReason"
                              label="Why have no terms been offered?"
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Tab>
                  </Tabs>
                </Col>
                <Col xs={12}>
                  <BootstrapForm.Textarea
                    name="additionalInfo"
                    label="Do you have any further notes or comments?"
                  />
                </Col>
                <Col xs={12}>
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UnderwriterReplyForm;
