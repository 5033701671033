import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";
const resetArray = [
  { name: "psychoticDisorderDescribeSymptoms", type: "" },
  { name: "psychoticDisorderStartDate", type: "" },
  { name: "psychoticDisorderTimePeriod", type: "" },
  { name: "psychoticDisorderReasons", type: "" },
  { name: "psychoticDisorderFactors", type: "" },
  { name: "psychoticDisorderThoughts", type: "" },
  { name: "psychoticDisorderWhatTriggered", type: "" },
  { name: "psychoticDisorderSymptomsCommenced", type: "" },
  { name: "psychoticDisorderReoccurrencesOfCondition", type: "" },
  { name: "psychoticDisorderHowManyReoccurrences", type: "" },
  { name: "psychoticDisorderTreatment", type: [] },
  { name: "psychoticDisorderCounsellingWhenStart", type: "" },
  { name: "psychoticDisorderCounsellingEndDate", type: "" },
  { name: "psychoticDisorderCounsellingTherapyDetails", type: "" },
  { name: "psychoticDisorderPsychotherapyWhenStart", type: "" },
  { name: "psychoticDisorderPsychotherapyEndDate", type: "" },
  { name: "psychoticDisorderPsychotherapyTherapyDetails", type: "" },
  { name: "psychoticDisorderMedicationDose", type: "" },
  { name: "psychoticDisorderMedicationEndDate", type: "" },
  { name: "psychoticDisorderConditionMedication", type: "" },
  { name: "psychoticDisorderDosageOften", type: "" },
  { name: "psychoticDisorderDutiesRestrictionCondition", type: "" },
  { name: "psychoticDisorderWorkRestriction", type: "" },
  { name: "psychoticDisorderLifeRestriction", type: "" },
  { name: "psychoticDisorderDetails", type: "" },
];

export default function PsychoticDisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <CheckBox
        list={[
          {
            label: "Schizophrenia or any other psychotic disorder",
            value: "psychoticDisorder",
          },
        ]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.anxiety.includes("psychoticDisorder") && (
        <AnswerBox
          label="Describe your symptoms"
          name="psychoticDisorderDescribeSymptoms"
          isSubForm
        />
      )}

      {values.psychoticDisorderDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="psychoticDisorderStartDate"
          isSubForm
        />
      )}
      {values.psychoticDisorderStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="psychoticDisorderTimePeriod"
          isSubForm
        />
      )}
      {values.psychoticDisorderTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause if these details? "
          name="psychoticDisorderReasons"
          isSubForm
        />
      )}
      {values.psychoticDisorderReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="psychoticDisorderFactors"
          isSubForm
        />
      )}
      {(values.psychoticDisorderReasons === "false" ||
        values.psychoticDisorderFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts , threatened to self harm or engaged in self harm?"
          name="psychoticDisorderThoughts"
          isSubForm
        />
      )}
      {values.psychoticDisorderThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="psychoticDisorderWhatTriggered"
          isSubForm
        />
      )}
      {(values.psychoticDisorderThoughts === "false" ||
        values.psychoticDisorderWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="psychoticDisorderSymptomsCommenced"
          isSubForm
        />
      )}
      {values.psychoticDisorderSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="psychoticDisorderReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.psychoticDisorderReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="psychoticDisorderHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.psychoticDisorderReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="psychoticDisorderTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.psychoticDisorderTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="psychoticDisorderCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="psychoticDisorderCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="psychoticDisorderCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.psychoticDisorderReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="psychoticDisorderTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}
      {values.psychoticDisorderTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="psychoticDisorderPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="psychoticDisorderPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="psychoticDisorderPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.psychoticDisorderReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="psychoticDisorderTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.psychoticDisorderTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="psychoticDisorderMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="psychoticDisorderMedicationEndDate"
            isSubForm
          />
          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="psychoticDisorderConditionMedication"
            isSubForm
          />
          {values.psychoticDisorderConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="psychoticDisorderDosageOften"
              isSubForm
            />
          )}
          {(values.psychoticDisorderDosageOften ||
            values.psychoticDisorderConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="psychoticDisorderDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.psychoticDisorderDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="psychoticDisorderWorkRestriction"
              isSubForm
            />
          )}
          {(values.psychoticDisorderDutiesRestrictionCondition === "false" ||
            values.psychoticDisorderWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="psychoticDisorderLifeRestriction"
              isSubForm
            />
          )}
          {values.psychoticDisorderLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="psychoticDisorderDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
