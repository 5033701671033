import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import axios from "../../lib/axios";
import moment from "moment";
export default function UnderwriterResponse({
  //   headers,
  TableB,
  fetchDataUrl,
  statusChangeUrl,
  updatePath,
  title,
  view,
  ...props
}) {
  //alerting
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [items, setData] = useState([]);
  const [open, setOpen] = useState(false);

  //use effect
  useEffect(() => {
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load data to the table
  const loadData = async () => {
    try {
      const values = {
        formid: props.location.state.formid,
      };

      const { data } = await axios.post("form/getForms", values);

      setData(data);
    } catch (error) {
      setOpen(true);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
        setOpen(false);
      }, 3000);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    green: {
      backgroundColor: "green",
      color: "white",
    },
    red: {
      backgroundColor: "red",
      color: "white",
    },
    yellow: {
      backgroundColor: "orange",
      color: "white",
    },
    textCenter: {
      textAlign: "center",
    },
  }));

  const classes = useStyles();

  //search

  let termsAreOffered;
  // let timeFrameLoading;
  // let timeFrameExclusion;
  // let furtherInfo;

  return (
    <Grid container>
      <Grid item className={classes.root}>
        <Card>
          <Typography
            style={{
              fontFamily: "holiday",
              fontSize: "2rem",
              marginLeft: 15,
              marginTop: 15,
            }}
          >
            {" "}
            {title}
          </Typography>

          <CardContent>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TableRow>
                        <TableCell colSpan={3}>Name of Underwriter </TableCell>

                        {items.map((item) => {
                          return (
                            <TableCell style={{ minWidth: "15rem" }}>
                              {item.formFour?.nameOfUnderwriter}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>Email of Underwriter</TableCell>

                        {items.map((item) => {
                          return <TableCell>{item.formFour?.email}</TableCell>;
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          Date Preassessment Received
                        </TableCell>

                        {items.map((item) => {
                          return (
                            <TableCell style={{ minWidth: "15rem" }}>
                              {moment(item.createdAt).format("MMMM Do YYYY")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ minWidth: "15rem" }}></TableCell>
                        <TableCell style={{ minWidth: "15rem" }}></TableCell>
                        <TableCell style={{ minWidth: "15rem" }}></TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TableCell style={{ minWidth: "15rem" }}>
                        Life Cover
                      </TableCell>
                      <TableCell style={{ Width: "35rem" }}>
                        <TableRow style={{ minWidth: "15rem" }}>
                          <span> What terms are offered</span>

                          {/*maped data*/}
                          {items.map((item) => {
                            let loadingLifeLogic =
                              item.formFour?.lifeTerm === "LifeLoading";
                            let exclusionLifeLogic =
                              item.formFour?.lifeTerm === "LifeExclusion";
                            let noTermOffered =
                              item.formFour?.lifeTerm ===
                              "Life No termed offered";

                            if (
                              item.formFour?.acceptanceOfLifeCover === "false"
                            ) {
                              if (loadingLifeLogic) {
                                termsAreOffered = `Loading - ${item.formFour?.lifeLoading}`;
                              } else if (exclusionLifeLogic) {
                                termsAreOffered = `Exclusion - ${item.formFour?.lifeExclusion}`;
                              } else if (noTermOffered) {
                                termsAreOffered = `No termed offered - ${item.formFour?.lifeReasonToNoTerms}`;
                              } else {
                                termsAreOffered = "Standard Terms";
                              }
                            } else {
                              termsAreOffered = "Standard Terms";
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {termsAreOffered}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a time frame or trigger where loading can be
                          reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.LifeTimeFrameOrTriggerLoading !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.LifeTimeFrameOrTriggerLoading;
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a timeframe or trigger where the exclusion
                          can be reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.LifeTimeFrameOrTriggerExclusion !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.LifeTimeFrameOrTriggerExclusion;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Do you require any further information that may change
                          your assessment?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour
                                ?.LifeFurtherReqToChangeAssessment !== ""
                            ) {
                              timeFrame =
                                item.formFour?.LifeFurtherReqToChangeAssessment;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableCell>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <TableCell style={{ width: "15rem" }}>
                        TPD cover
                      </TableCell>
                      <TableCell>
                        <TableRow>
                          What terms are offered
                          {items.map((item) => {
                            let loadingLifeLogic =
                              item.formFour?.tpdTerm === "tpdLoading";
                            let exclusionLifeLogic =
                              item.formFour?.tpdTerm === "tpdExclusion";
                            let noTermOffered =
                              item.formFour?.tpdTerm ===
                              "Tpd No termed offered";

                            if (
                              item.formFour?.acceptanceOfTpdCover === "false"
                            ) {
                              if (loadingLifeLogic) {
                                termsAreOffered = `Loading - ${item.formFour?.tpdLoading}`;
                              } else if (exclusionLifeLogic) {
                                termsAreOffered = `Exclusion - ${item.formFour?.tpdExclusion}`;
                              } else if (noTermOffered) {
                                termsAreOffered = `No termed offered - ${item.formFour?.tpdReasonToNoTerms}`;
                              } else {
                                termsAreOffered = "Standard Terms";
                              }
                            } else {
                              termsAreOffered = "Standard Terms";
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {termsAreOffered}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a time frame or trigger where loading can be
                          reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.tpdTimeFrameOrTriggerLoading !== ""
                            ) {
                              timeFrame =
                                item.formFour?.tpdTimeFrameOrTriggerLoading;
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a timeframe or trigger where the exclusion
                          can be reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.tpdTimeFrameOrTriggerExclusion !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.tpdTimeFrameOrTriggerExclusion;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Do you require any further information that may change
                          your assessment?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.tpdFurtherReqToChangeAssessment !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.tpdFurtherReqToChangeAssessment;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableCell>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <TableCell style={{ width: "15rem" }}>
                        Trauma cover
                      </TableCell>
                      <TableCell>
                        <TableRow>
                          What terms are offered
                          {items.map((item) => {
                            let loadingLifeLogic =
                              item.formFour?.traumaTerm === "traumaLoading";
                            let exclusionLifeLogic =
                              item.formFour?.traumaTerm === "traumaExclusion";
                            let noTermOffered =
                              item.formFour?.traumaTerm ===
                              "Trauma No termed offered";

                            if (
                              item.formFour?.acceptanceOfTraumaCover === "false"
                            ) {
                              if (loadingLifeLogic) {
                                termsAreOffered = `Loading - ${item.formFour?.traumaLoading}`;
                              } else if (exclusionLifeLogic) {
                                termsAreOffered = `Exclusion - ${item.formFour?.traumaExclusion}`;
                              } else if (noTermOffered) {
                                termsAreOffered = `No termed offered - ${item.formFour?.traumaReasonToNoTerms}`;
                              } else {
                                termsAreOffered = "Standard Terms";
                              }
                            } else {
                              termsAreOffered = "Standard Terms";
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {termsAreOffered}
                              </TableCell>
                            );
                          })}
                          {/*maped data*/}
                        </TableRow>
                        <TableRow>
                          Is there a time frame or trigger where loading can be
                          reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.traumaTimeFrameOrTriggerLoading !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.traumaTimeFrameOrTriggerLoading;
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a timeframe or trigger where the exclusion
                          can be reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour
                                ?.traumaTimeFrameOrTriggerExclusion !== ""
                            ) {
                              timeFrame =
                                item.formFour
                                  ?.traumaTimeFrameOrTriggerExclusion;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Do you require any further information that may change
                          your assessment?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour
                                ?.traumaFurtherReqToChangeAssessment !== ""
                            ) {
                              timeFrame =
                                item.formFour
                                  ?.traumaFurtherReqToChangeAssessment;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableCell>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <TableCell style={{ width: "15rem" }}>
                        Income Protection cover
                      </TableCell>
                      <TableCell>
                        <TableRow>
                          What terms are offered
                          {items.map((item) => {
                            let loadingLifeLogic =
                              item.formFour?.incomeTerm === "incomeLoading";
                            let exclusionLifeLogic =
                              item.formFour?.incomeTerm === "incomeExclusion";
                            let noTermOffered =
                              item.formFour?.incomeTerm ===
                              "Income No termed offered";

                            if (
                              item.formFour?.acceptanceOfIncomeCover === "false"
                            ) {
                              if (loadingLifeLogic) {
                                termsAreOffered = `Loading - ${item.formFour?.incomeLoading}`;
                              } else if (exclusionLifeLogic) {
                                termsAreOffered = `Exclusion - ${item.formFour?.incomeExclusion}`;
                              } else if (noTermOffered) {
                                termsAreOffered = `No termed offered - ${item.formFour?.incomeReasonToNoTerms}`;
                              } else {
                                termsAreOffered = "Standard Terms";
                              }
                            } else {
                              termsAreOffered = "Standard Terms";
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {termsAreOffered}
                              </TableCell>
                            );
                          })}
                          {/*maped data*/}
                        </TableRow>
                        <TableRow>
                          Is there a time frame or trigger where loading can be
                          reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour?.incomeTimeFrameOrTriggerLoading !==
                              ""
                            ) {
                              timeFrame =
                                item.formFour?.incomeTimeFrameOrTriggerLoading;
                            }

                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Is there a timeframe or trigger where the exclusion
                          can be reviewed?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour
                                ?.incomeTimeFrameOrTriggerExclusion !== ""
                            ) {
                              timeFrame =
                                item.formFour
                                  ?.incomeTimeFrameOrTriggerExclusion;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Do you require any further information that may change
                          your assessment?
                          {items.map((item) => {
                            let timeFrame;

                            if (
                              item.formFour
                                ?.incomeFurtherReqToChangeAssessment !== ""
                            ) {
                              timeFrame =
                                item.formFour
                                  ?.incomeFurtherReqToChangeAssessment;
                            }
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {timeFrame}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow>
                          Do you have any further notes or comments?
                          {items.map((item) => {
                            return (
                              <TableCell style={{ width: "15rem" }}>
                                {item.formFour?.furtherComments}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableCell>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
