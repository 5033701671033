import React from "react";

import Workpage from "../../components/Work/Workpage";
import DesignerPage from "../../components/designer/DesignerPage";
import Usepage from "../../components/Use/Usepage";
import Planpage from "../../components/Plans/Planpage";
import Videopage from "../../components/video/Videopage";
import HeaderTop from "../../components/headerTop/HeaderTop";
import Drawer from "../../components/layouts/Drawer";
import Footer from "../../components/Footer/Footer";

export default function Welcomepage() {
  return (
    <>
      <div
        style={{
          width: "100%",
          top: 0,
          zIndex: 10,
        }}
      >
        <HeaderTop />
        <Drawer />
      </div>
      {/* <div className={classes.spdiv}></div> */}
      <Videopage />
      <DesignerPage />
      <Workpage />
      <Usepage />
      <Planpage />
      <Footer />
    </>
  );
}
