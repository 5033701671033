import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "holiday",
    color: "#000",
    fontSize: "2rem",
    textAlign: "center",
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },

  root: {
    flexGrow: 1,
    marginBottom: 170,
  },
  paper: {
    height: 460,
    width: 370,
    [theme.breakpoints.down("md")]: {
      marginTop: 50,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 50,
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  text: {
    fontFamily: "holiday",
    fontSize: "2.5rem",
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#000",
    paddingTop: 30,
  },
  paragraph: {
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
    color: "#000",
    fontSize: "1.5rem",
    marginBottom: 10,
  },
  paragraph1: {
    textAlign: "center",
    fontFamily: "Overpass, sans-serif",
    fontSize: "1rem",
  },
  paragraph2: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontFamily: "holiday",
    fontSize: "0.7rem",
    paddingTop: 10,
  },
  paragraph3: {
    marginTop: "1.5rem",
    fontFamily: "Overpass, sans-serif",
    textAlign: "center",
    color: "#1a75ff",
    fontSize: "1.5rem",
  },
  box: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));
