import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "thyroidCondition", type: [] },
  { name: "thyroidSymptoms", type: "" },
  { name: "thyroidTreatments", type: "" },
  { name: "thyroidTreatStatus", type: "" },
  { name: "thyroidTreatDetails", type: "" },
];

export default function FormThyroidDisorder({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Thyroid disorder", value: "ThyroidDisorder" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("ThyroidDisorder") && (
        <Fragment>
          <CheckBox
            name={"thyroidCondition"}
            question="What type of thyroid condition do you have?"
            list={[
              { label: "Goitre", value: "Goitre" },
              {
                label: "Hypothyroidism (underactive)",
                value: "Hypothyroidism (underactive)",
              },
              {
                label: "Hyperthyroidism (overactive)",
                value: "Hyperthyroidism (overactive)",
              },
              { label: "Unknown", value: "unknown" },
            ]}
            isSubForm
          />
          <AnswerBox
            name="thyroidSymptoms"
            label="Please provide symptoms"
            isSubForm
          />
          <AnswerBox
            name="thyroidTreatments"
            label="Any treatment provided"
            isSubForm
          />
          <YesNoQuestion
            question="Are you currently on treatment for the thyroid condition?"
            name="thyroidTreatStatus"
            isSubForm
          />
          {values.thyroidTreatStatus === "true" && (
            <AnswerBox
              name="thyroidTreatDetails"
              label="Provide details"
              isSubForm
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
