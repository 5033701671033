import React, { Fragment } from "react";

import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]
const resetArray = [
  { name: "PsoriaticArthritisHowLongArthritis", type: "" },
  { name: "PsoriaticArthritisTreatment", type: "" },
  { name: "PsoriaticArthritisOngoingSymptoms", type: "" },
];
export default function SubFormThree({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Psoriatic Arthritis", value: "psoriaticArthritis" }]}
        name="subFormsOfFormThirteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfFormThirteen.includes("psoriaticArthritis") && (
        <AnswerBox
          label="What are your ongoing symptoms?"
          name="PsoriaticArthritisOngoingSymptoms"
          isSubForm
        />
      )}
      {values.subFormsOfFormThirteen.includes("psoriaticArthritis") &&
        values.PsoriaticArthritisOngoingSymptoms && (
          <AnswerBox
            label="How long have you had arthritis?"
            name="PsoriaticArthritisHowLongArthritis"
            isSubForm
          />
        )}
      {values.subFormsOfFormThirteen.includes("psoriaticArthritis") &&
        values.PsoriaticArthritisHowLongArthritis && (
          <AnswerBox
            label="What is the treatment you are receiving?"
            name="PsoriaticArthritisTreatment"
            isSubForm
          />
        )}
    </Fragment>
  );
}
