import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "dizzinessSymptoms", type: "" },
  { name: "dizzinessTreatment", type: "" },
  { name: "dizzinessStart", type: "" },
  { name: "dizzinessDutyRestrictions", type: "" },
  { name: "dizzinessDetails", type: "" },
];

export default function Dizziness({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Dizziness", value: "dizziness" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("dizziness") && (
        <AnswerBox 
          label="What were your symptoms?"
          name="dizzinessSymptoms"
          isSubForm
        />
      )}
      {values.dizzinessSymptoms && (
        <AnswerBox
          label="What treatment was provided? "
          name="dizzinessTreatment"
          isSubForm
        />
      )}
      {values.dizzinessTreatment && (
        <AnswerBox 
          label="When did this occur?"
          name="dizzinessStart"
          isSubForm
        />
      )}
      {values.dizzinessSymptoms &&
        values.dizzinessTreatment &&
        values.dizzinessStart && (
          <YesNoQuestion
            question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
            name="dizzinessDutyRestrictions"
            isSubForm
          />
        )}
      {values.dizzinessDutyRestrictions === "true" && (
        <AnswerBox 
          label="Please Provide Details"
          name="dizzinessDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
