import React, { useEffect, useState } from "react";
import axios from "axios";

// Form library imports
import { Formik, Form } from "formik";
import { Form as bootstrapForm } from "react-bootstrap-formik";

// Bootstrap component imports
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";

// PAM components
import Heading from "../../components/layouts/AdvisorHeading";
import NavBar from "../../components/layouts/AdvisorNavBar";
import DashboardToast from "../../components/Toast/DashboardToast";
import PreferredUnderwritersModal from "../../components/modals/PreferredUnderwritersModal"; // Modal displayed on first login
import Footer from "../../components/Footer/Footer";
import MobileViewport from "../mobileViewport/MobileViewport";

const AdvisorDashboard = () => {
  const [searchParamsString, setSearchParamsString] = useState();
  const [advisorData, setAdvisorData] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [assessmentsSlice, setAssessmentsSlice] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [numOfAssessments, setNumOfAssessments] = useState();

  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  // Fetch logged in advisor's details and set in state for use on dashboard page
  const fetchAdvisorData = async () => {
    const response = await axios
      .get("/auth/me")
      .catch((err) => console.log(err));
    if (response) {
      setAdvisorData(response.data);
    }
  };

  // Fetch logged in advisor's client assessment data and set in state
  const fetchAssessmentData = async () => {
    const response = await axios
      .get("/auth/client2")
      .catch((err) => console.log(err));
    if (response) {
      setAssessments(response.data);
    }
  };

  // Create array of latest assessments based on screen size

  const sliceAssessments = () => {
    const smWindowBreakpoint = 900; // Height breakpoint for reduction of assessment rows
    const lgWindowSlice = 6; // Number of assessment rows for large screens
    const smWindowSlice = 3; // Number of assessment rows for small screens

    // Set number of assessments for initial page load
    window.innerHeight < smWindowBreakpoint
      ? setNumOfAssessments(smWindowSlice)
      : setNumOfAssessments(lgWindowSlice);
    // Update number of assessments on resize events
    window.addEventListener("resize", () => {
      if (window.innerHeight < smWindowBreakpoint) {
        setNumOfAssessments(smWindowSlice);
      } else {
        setNumOfAssessments(lgWindowSlice);
      }
    });

    let assessmentArray = assessments.slice(0, numOfAssessments);

    // Add pre assessment status to each assessment object in sliced array
    for (
      let arrayCount = 0;
      arrayCount < assessmentArray.length;
      arrayCount++
    ) {
      if (assessmentArray[arrayCount].isRes) {
        assessmentArray[arrayCount].status = "Underwriter replies received";
      } else if (assessmentArray[arrayCount].isShare) {
        assessmentArray[arrayCount].status = "Awaiting underwriter replies";
      } else if (assessmentArray[arrayCount].isSubmit) {
        assessmentArray[arrayCount].status = "Assessment received";
      } else {
        assessmentArray[arrayCount].status = "Awaiting client response";
      }
    }

    setAssessmentsSlice(assessmentArray);
  };

  // Display toast message based on URL search params, triggered by setting state
  const getSearchParamsString = () => {
    setSearchParamsString(window.location.search);
  };

  const updateCreditCount = async () => {
    try {
      const response = await axios.get(`/paypal/success/${searchParamsString}`);
      console.log(JSON.stringify(response));
      fetchAdvisorData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAdvisorData();
    fetchAssessmentData();
    getSearchParamsString();
  }, []);

  useEffect(() => {
    sliceAssessments();
  }, [assessments, numOfAssessments]);

  useEffect(() => {
    // Load preferred underwriter modal on first sign-in
    if (advisorData.prefred === false) {
      handleModalShow();
    }
  }, [advisorData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(searchParamsString);
    if (searchParams.get("paymentId") !== null) {
      updateCreditCount();
    }
  }, [searchParamsString]);

  return (
    <>
      {searchParamsString ? (
        <DashboardToast
          searchParamsString={searchParamsString}
        ></DashboardToast>
      ) : (
        ""
      )}
      <PreferredUnderwritersModal
        modalShow={modalShow}
        handleModalShow={handleModalShow}
        handleModalClose={handleModalClose}
      />
      <Container id="standard-viewport" fluid className="flex-grow-1">
        <Row className="">
          <NavBar />
          <Col className="vh-100 d-flex flex-column">
            <Row className="flex-shrink-1 flex-grow-1 scrollable-element">
              <Col xs={12} className="p-4">
                <Heading
                  heading={
                    advisorData.firstName
                      ? `Welcome back ${advisorData.firstName}`
                      : "Welcome back"
                  }
                />
                <Row className="row-cols-1 g-4 mb-4">
                  <Col xs={12}>
                    <Card>
                      <Card.Header as="h5">Latest Assessments</Card.Header>
                      <Card.Body className="d-flex flex-column p-0">
                        <Table className="m-0" striped hover>
                          <thead>
                            <tr>
                              <th className="ps-3" style={{ width: "15%" }}>
                                Client
                              </th>
                              <th style={{ width: "25%" }}>Client Email</th>
                              <th style={{ width: "10%" }}>Mobile</th>
                              <th style={{ width: "20%" }}>Status</th>
                              <th style={{ width: "30%" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {assessmentsSlice.map((assessment) => (
                              <tr
                                key={assessment._id}
                                style={{
                                  height: "3.5rem",
                                  verticalAlign: "middle",
                                }}
                              >
                                <td className="ps-3">
                                  {assessment.client.name}
                                </td>
                                <td>{assessment.client.email}</td>
                                <td>{assessment.client.mobile}</td>
                                <td>{assessment.status}</td>
                                <td>
                                  <div className="d-flex justify-content-end gap-2">
                                    {/* Disable buttons when status dictates that the links are not ready */}
                                    {assessment.status ==
                                    "Awaiting client response" ? (
                                      <Button variant="secondary" disabled>
                                        View assessment
                                      </Button>
                                    ) : (
                                      <a
                                        href={"/formview/" + assessment.formid}
                                      >
                                        <Button variant="primary">
                                          View assessment
                                        </Button>
                                      </a>
                                    )}
                                    {assessment.status ==
                                    "Underwriter replies received" ? (
                                      <a
                                        href={
                                          "/view-underwriter-replies/" +
                                          assessment.formid
                                        }
                                      >
                                        <Button variant="success">
                                          View replies
                                        </Button>
                                      </a>
                                    ) : (
                                      <Button variant="secondary" disabled>
                                        View replies
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="py-2 px-3 mt-auto">
                          <a
                            href="/assessment-status"
                            style={{ color: "blue", textDecoration: "none" }}
                          >
                            View all assessments
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="row-cols-1 row-cols-xl-2 g-4">
                  <Col>
                    <Card>
                      <Card.Header as="h5">Start new assessment</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <Formik
                              initialValues={initialValues}
                              onSubmit={(e) => {
                                window.location.assign(
                                  `/client-create?firstName=${e.firstName}&lastName=${e.lastName}`
                                );
                              }}
                            >
                              <Form>
                                <Row className="g-2">
                                  <Col xs={12} xxl={6}>
                                    <bootstrapForm.Input
                                      label="First name"
                                      name="firstName"
                                      placeholder="First name"
                                    />
                                  </Col>
                                  <Col xs={12} xxl={6}>
                                    <bootstrapForm.Input
                                      label="Last name"
                                      name="lastName"
                                      placeholder="Last name"
                                    />
                                  </Col>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      type="submit"
                                      variant="primary"
                                      className="m-2"
                                    >
                                      Next
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </Formik>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Header as="h5">
                        Available assessment credits
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col
                            xs={6}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <h1>{advisorData.clientsCount}</h1>
                          </Col>
                          <Col xs={6}>
                            <h5>Purchase additional credits</h5>
                            <p>
                              Credits can be purchase in packages from our
                              packages page
                            </p>
                            <a href="/package-buy">
                              <Button variant="primary">
                                Purchase packages
                              </Button>
                            </a>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {/* Mobile viewport displayed through CSS media query when screen with < 1100px */}
      <MobileViewport />
    </>
  );
};

export default AdvisorDashboard;
