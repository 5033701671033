import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const SkinTab = (props) => {
  // Formats array of strings from database to JSX unordered list for use in answer table
  const arrayToFormattedList = (array) => {
    return (
      <ul className="m-0">
        {array.map((item) => (
          <li>
            {formatFormData(item)
              .replace(/([A-Z]+)/g, " $1") // Adds space before each sequence of capital letters
              .trim() // Removes leading and/or trailing spaces
              [0].toUpperCase() + // Capitalise first letter and append remaining string unchanged
              formatFormData(item)
                .replace(/([A-Z]+)/g, " $1")
                .trim()
                .substring(1)}
          </li>
        ))}
      </ul>
    );
  };

  const findFirstIndex = () => {
    const firstIndex =
      props.formData.formFiffteen.subFormsOfFormFifteen.findIndex(
        (illness) => illness.substring(0, 6) !== "defect"
      ) + 1;
    return firstIndex;
  };

  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container
          id="formview-skin-tabs"
          defaultActiveKey={`#skin-${findFirstIndex()}`}
        >
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formFiffteen.subFormsOfFormFifteen.map(
                (illness, index) =>
                  illness.substring(0, 6) !== "defect" ? (
                    <>
                      <ListGroup.Item action href={`#skin-${index + 1}`}>
                        {illness
                          .replace(/([A-Z]+)/g, " $1")
                          .trim()[0]
                          .toUpperCase() +
                          illness
                            .replace(/([A-Z]+)/g, " $1")
                            .trim()
                            .substring(1)}
                      </ListGroup.Item>
                    </>
                  ) : (
                    <></>
                  )
              )}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formFiffteen.subFormsOfFormFifteen.map(
                (illness, index) =>
                  illness.substring(0, 6) !== "defect" ? (
                    <Tab.Pane eventKey={`#skin-${index + 1}`}>
                      <Table
                        id="form-view-skin-table"
                        striped
                        bordered
                        responsive
                      >
                        <thead>
                          <tr className="border-0">
                            <th
                              id="question-heading"
                              className="border-0 p-0"
                            ></th>
                            <th
                              id="answer-heading"
                              className="border-0 p-0"
                            ></th>
                          </tr>
                        </thead>
                        {illness === "psoriasis" ? (
                          <tbody>
                            <tr>
                              <td>
                                Which areas of the body are affected by
                                psoriasis?
                              </td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formFiffteen.whichBodyArea
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen.whichBodyArea ===
                            "other" ? (
                              <tr>
                                <td>Please provide details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .psoriasisBodyAreaDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Have you had joint pain or been diagnosed with
                                psoriasis arthritis?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .psoriasisAnyJointPain
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Is your treatment limited to
                                cream/ointment/lotions?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .psoriasisTreatmentLimited
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Have you had 3 consecutive days off work in the
                                last 2 years due to psoriasis?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .psoriasisHadAnyConsecutiveDays
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ) : illness === "dermatitis" ? (
                          <tbody>
                            <tr>
                              <td>
                                Are you in any way restricted in your occupation
                                or daily activities as a result of this
                                dermatitis?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .dermatitisAnyRestricted
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen
                              .dermatitisAnyRestricted === "yes" ? (
                              <tr>
                                <td>Please provide details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .dermatitisRestrictedYesDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Is your condition triggered or aggravated by
                                substances used at your job?
                              </td>
                              <td>
                                {formatFormData(
                                  props.formData.formFiffteen
                                    .dermatitisConditionTriggered
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen
                              .dermatitisConditionTriggered === "yes" ? (
                              <tr>
                                <td>Please provide details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .dermatitisConditionTriggeredYesDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        ) : illness === "moleOrSunSpots" ? (
                          <tbody>
                            <tr>
                              <td>How was the skin growth treated?</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formFiffteen
                                    .moleOrSunSpotsHowSkinGrowthTreated
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen
                              .moleOrSunSpotsHowSkinGrowthTreated ===
                            "cryotherapy" ? (
                              <>
                                <tr>
                                  <td>When was it removed with cryotherapy?</td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .moleOrSunSpotsWhenWasItRemoved
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Was it confirmed as being Benign
                                    (noncancerous)?
                                  </td>
                                  <td>
                                    {formatFormData(
                                      props.formData.formFiffteen
                                        .moleOrSunSpotsConfirmedAsBenign
                                    )}
                                  </td>
                                </tr>
                                {props.formData.formFiffteen
                                  .moleOrSunSpotsConfirmedAsBenign === "no" ? (
                                  <tr>
                                    <td>
                                      Was it a Squamous Cell Carcinoma (SCC)?
                                    </td>
                                    <td>
                                      {formatFormData(
                                        props.formData.formFiffteen
                                          .moleOrSunSpotsSCC
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                                <tr>
                                  <td>
                                    Other than being cut out was there any other
                                    treatment recommended or required?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .moleOrSunSpotsTreatmentRecommendedOrRequired
                                    }
                                  </td>
                                </tr>
                                {props.formData.formFiffteen
                                  .moleOrSunSpotsTreatmentRecommendedOrRequired ===
                                "yes" ? (
                                  <tr>
                                    <td>Please provide details</td>
                                    <td>
                                      {
                                        props.formData.formFiffteen
                                          .moleOrSunSpotsTreatmentRecommendedOrRequiredDetails
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .moleOrSunSpotsHowSkinGrowthTreated ===
                            "creamOrOintment" ? (
                              <tr>
                                <td>Cream or ointment details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .moleOrSunSpotsCreamOintmentDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .moleOrSunSpotsHowSkinGrowthTreated ===
                            "notRemoved" ? (
                              <>
                                <tr>
                                  <td>
                                    When was the skin growth first checked by a
                                    medical practitioner?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .moleOrSunSpotsWhenSkinGrowthFirst
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Other than regular checks have you been
                                    advised you need other treatment for the
                                    skin growth?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .moleOrSunSpotsNeedOtherTreatmentForSkinGrowth
                                    }
                                  </td>
                                </tr>
                                {props.formData.formFiffteen
                                  .moleOrSunSpotsNeedOtherTreatmentForSkinGrowth ===
                                "yes" ? (
                                  <tr>
                                    <td>Other treatment details</td>
                                    <td>
                                      {
                                        props.formData.formFiffteen
                                          .moleOrSunSpotsNeedOtherTreatmentForSkinGrowthDetails
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        ) : illness === "otherSkinDisorder" ? (
                          <tbody>
                            <tr>
                              <td>Select skin disorder</td>
                              <td>
                                {arrayToFormattedList(
                                  props.formData.formFiffteen
                                    .otherSkinDisorderSelectedDisorder
                                )}
                              </td>
                            </tr>
                            {props.formData.formFiffteen.otherSkinDisorderSelectedDisorder.includes(
                              "other"
                            ) ? (
                              <tr>
                                <td>Please provide details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .otherSkinDisorderSelectedOtherDisorderDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}

                            {/************************************************************/}
                            {/* BCC and SCC cancer questions removed from "Skin" section */}
                            {/************************************************************/}
                            {/* {props.formData.formFiffteen
                              .otherSkinDisorderSelectedDisorder === "BCC" ||
                            props.formData.formFiffteen
                              .otherSkinDisorderSelectedDisorder === "SCC" ? (
                              <tr>
                                <td>What is the condition?</td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formFiffteen
                                      .otherSkinDisorderWhatDisorder
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .otherSkinDisorderSelectedDisorder ===
                              "skinCancer" ||
                            props.formData.formFiffteen
                              .otherSkinDisorderSelectedDisorder ===
                              "cancerOtherThanSkin" ? (
                              <tr>
                                <td>How was the skin growth treated?</td>
                                <td>
                                  {arrayToFormattedList(
                                    props.formData.formFiffteen
                                      .otherSkinDisorderHowSkinGrowthTreated
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .otherSkinDisorderHowSkinGrowthTreated ===
                            "cryotherapy" ? (
                              <>
                                <tr>
                                  <td>When was it removed with cryotherapy?</td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .otherSkinDisorderWhenWasItRemoved
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Was it confirmed as being Benign
                                    (noncancerous)?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .otherSkinDisorderConfirmedAsBenign
                                    }
                                  </td>
                                </tr>
                                {props.formData.formFiffteen
                                  .otherSkinDisorderConfirmedAsBenign ===
                                "no" ? (
                                  <tr>
                                    <td>
                                      Was it a Squamous Cell Carcinoma (SCC)?
                                    </td>
                                    <td>
                                      {
                                        props.formData.formFiffteen
                                          .otherSkinDisorderSCC
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>
                                Other than being cut out was there any other
                                treatment recommended or required?
                              </td>
                              <td>
                                {
                                  props.formData.formFiffteen
                                    .otherSkinDisorderTreatmentRecommendedOrRequired
                                }
                              </td>
                            </tr>
                            {props.formData.formFiffteen
                              .otherSkinDisorderTreatmentRecommendedOrRequired ===
                            "yes" ? (
                              <tr>
                                <td>Other treatment details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .otherSkinDisorderTreatmentRecommendedOrRequiredDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .otherSkinDisorderHowSkinGrowthTreated ===
                            "creamOrOintment" ? (
                              <tr>
                                <td>Cream or ointment details</td>
                                <td>
                                  {
                                    props.formData.formFiffteen
                                      .otherSkinDisorderCreamOintmentDetails
                                  }
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {props.formData.formFiffteen
                              .otherSkinDisorderHowSkinGrowthTreated ===
                            "notRemoved" ? (
                              <>
                                <tr>
                                  <td>
                                    When was the skin growth first checked by a
                                    medical practitioner?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .otherSkinDisorderWhenSkinGrowthFirst
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Other than regular checks have you been
                                    advised you need other treatment for the
                                    skin growth?
                                  </td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .otherSkinDisorderNeedOtherTreatmentForSkinGrowth
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Treatment details</td>
                                  <td>
                                    {
                                      props.formData.formFiffteen
                                        .otherSkinDisorderNeedOtherTreatmentForSkinGrowthDetails
                                    }
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )} */}
                          </tbody>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </Tab.Pane>
                  ) : (
                    <></>
                  )
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default SkinTab;
