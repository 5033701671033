import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { Nav, Image, Button } from "react-bootstrap";
import pamLogo from "../../assets/preAssesLogo.png";

const NavBar = () => {
  let dispatch = useDispatch();
  const [role, setRole] = useState(sessionStorage.getItem("role"));

  const signOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  return (
    <div className="dashboard-navbar d-flex flex-column p-2">
      <Image fluid src={pamLogo} className="pb-3 px-1" />
      <Nav className="d-flex flex-column">
        {role === "advisor" ? (
          <>
            <Nav.Link href="/advisor-dashboard">Dashboard</Nav.Link>
            <Nav.Link href="/assessment-status">Assessments</Nav.Link>
            <Nav.Link href="/client-create">New assessment</Nav.Link>
            <Nav.Link href="/package-buy">Buy packages</Nav.Link>
          </>
        ) : (
          <Nav.Link href="/">Homepage</Nav.Link>
        )}
      </Nav>
      {role === "advisor" ? (
        <>
          <a className="mt-auto" href="/preferences">
            <Button style={{ width: "100%" }}>Preferences</Button>
          </a>
          <Button className="mt-1" onClick={() => signOut()}>
            Sign out
          </Button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default NavBar;
