import React from "react";
import { Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import formatFormData from "../../utils/formatFormData";

const BloodTab = (props) => {
  return (
    <Container fluid>
      <Row className="py-3">
        <Tab.Container id="formview-blood-tabs" defaultActiveKey="#blood-1">
          <Col xs={3}>
            <ListGroup className="scrollable-element">
              {props.formData.formNineteen.illness.map((illness, index) => (
                <ListGroup.Item action href={`#blood-${index + 1}`}>
                  {formatFormData(illness)
                    .replace(/([A-Z]+)/g, " $1")
                    .trim()[0]
                    .toUpperCase() +
                    formatFormData(illness)
                      .replace(/([A-Z]+)/g, " $1")
                      .trim()
                      .substring(1)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={9}>
            <Tab.Content className="scrollable-element">
              {props.formData.formNineteen.illness.map((illness, index) => (
                <Tab.Pane eventKey={`#blood-${index + 1}`}>
                  <Table id="form-view-blood-table" striped bordered responsive>
                    <thead>
                      <tr className="border-0">
                        <th id="question-heading" className="border-0 p-0"></th>
                        <th id="answer-heading" className="border-0 p-0"></th>
                      </tr>
                    </thead>
                    {illness === "anaemia" ? (
                      <tbody>
                        <tr>
                          <td>
                            Has your anaemia been diagnosed as iron deficient
                            anaemia?
                          </td>
                          <td>
                            {props.formData.formNineteen.anaemiaCheck === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Is there any other treatment required other than
                            iron tablets or diet management?
                          </td>
                          <td>
                            {props.formData.formNineteen.anaemOtherTreat === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formNineteen.anaemOtherTreat ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formNineteen.anaemiaDetails}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "Haemochromatosis" ? (
                      <tbody>
                        <tr>
                          <td>Haemochromatosis details</td>
                          <td>
                            {props.formData.formNineteen.diagHaemochromatosis}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Has your doctor confirmed that your latest iron
                            studies and liver function tests were normal?
                          </td>
                          <td>
                            {formatFormData(
                              props.formData.formNineteen.isIronTestNormal
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Is your haemochromatosis treated with venesection
                            (removal of blood) and are you compliant with this
                            treatment?
                          </td>
                          <td>
                            {props.formData.formNineteen.isVenesection === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Haemophilia" ? (
                      <tbody>
                        <tr>
                          <td>
                            What age was your diagnosis and onset of
                            haemophilia?
                          </td>
                          <td>{props.formData.formNineteen.diagHaemophilia}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {props.formData.formNineteen.treatHaemophilia}
                          </td>
                        </tr>
                      </tbody>
                    ) : illness === "Leukaemia" ? (
                      <tbody>
                        <tr>
                          <td>When was leukaemia diagnosed?</td>
                          <td>{props.formData.formNineteen.diagLeukaemia}</td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>{props.formData.formNineteen.treatLeukaemia}</td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formNineteen.isOngTreatLeukaemia === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formNineteen.isOngTreatLeukaemia ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {props.formData.formNineteen.detailsLeukaemia}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : illness === "Otherblooddisorder" ? (
                      <tbody>
                        <tr>
                          <td>What was the diagnosis?</td>
                          <td>
                            {props.formData.formNineteen.diagOtherblooddisorder}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            What was the treatment that was recommended by a
                            medical professional?
                          </td>
                          <td>
                            {
                              props.formData.formNineteen
                                .treatOtherblooddisorder
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Is there any ongoing treatment?</td>
                          <td>
                            {props.formData.formNineteen
                              .isOngTreatOtherblooddisorder === "true"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        {props.formData.formNineteen
                          .isOngTreatOtherblooddisorder ? (
                          <tr>
                            <td>Treatment details</td>
                            <td>
                              {
                                props.formData.formNineteen
                                  .detailsOtherblooddisorder
                              }
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    ) : (
                      <></>
                    )}
                  </Table>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
};

export default BloodTab;
