import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioGroup from "../RadioMulti";
import AnswerBox from "../AnswerBox";

const aviationItems = [
  {
    label: "Recreation",
    value: "Recreation",
  },
  {
    label: "Occupation",
    value: "Occupation",
  },
];

const resetArray = [
  { name: "licencesList", type: [] },
  { name: "aviationhours", type: "" },
  { name: "aviationexpecthours", type: "" },
  { name: "aircraftList", type: [] },
  { name: "recreational", type: "" },

];

export default function Aviation({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e) => {
    // remove the element from values
    if (!e.target.checked) {
      for (let item of resetArray) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Aviation", value: "aviation" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.activities.includes("aviation") && (
        <Grid item md={12} sm={12} xs={12}>
          {values.activities.includes("aviation") && (
            <RadioGroup
              question="Are your flying activities recreational or required for your occupation?"
              name="recreational"
              items={aviationItems}
              isSubForm
            />
          )}
          {values.recreational && (
            <CheckBox
              question="What
                      licences
                      do you
                      hold?"
              list={[
                {
                  label: "Private",
                  value: "Private",
                },
                { label: "Commercial", value: "Commercial" },
                {
                  label: "Senior Commercial",
                  value: "SeniorCommercial",
                },
              ]}
              name="licencesList"
              isSubForm
            />
          )}
          {values.licencesList.length !== 0 && (
            <CheckBox
              question="Name the
              types and
              models of
              aircraft you fly
              in?"
              list={[
                {
                  label: "Fixed wing",
                  value: "Fixed wing",
                },
                { label: "Rotary", value: "Rotary" },
                {
                  label: "Microlights, ultralights/powered hang gliding",
                  value: "Microlights, ultralights/powered hang gliding",
                },
                {
                  label: "Gyrocopter",
                  value: "Gyrocopter",
                },
                { label: "Hot Air balloon", value: "Hot Air balloon" },
                {
                  label: "Aerobatics/stunt flying/exhibitions",
                  value: "Aerobatics/stunt flying/exhibitions",
                },
              ]}
              name="aircraftList"
              isSubForm
            />
          )}
          {values.aircraftList.length !== 0 && (
            <AnswerBox
              label="How many hours have you
              flown per year?"
              name="aviationhours"
              isSubForm
            />
          )}
          {values.aviationhours && (
            <AnswerBox
              label="How many hours do you expect
              to fly in the next 12 months?"
              name="aviationexpecthours"
              isSubForm
            />
          )}
        </Grid>
      )}
    </Fragment>
  );
}
