import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

const DashboardToast = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(false);
  const [paypalSuccess, setPaypalSuccess] = useState(false);
  const [newAssessmentSuccess, setNewAssessmentSuccess] = useState(false);
  const [underwritersUpdatedSuccess, setUnderwritersUpdatedSuccess] =
    useState(false);
  const [userDetailsUpdatedSuccess, setUserDetailsUpdatedSuccess] =
    useState(false);
  const [underwriterSentSuccess, setUnderwriterSentSuccess] = useState(false);

  const triggerToast = (searchParamsString) => {
    const searchParams = new URLSearchParams(searchParamsString);

    if (searchParams.get("paymentId") !== null) {
      setPaypalSuccess(true);
      setShowToast(true);
    } else if (searchParams.get("newAssessment") !== null) {
      setNewAssessmentSuccess(true);
      setShowToast(true);
    } else if (searchParams.get("underwriterSent") !== null) {
      setUnderwriterSentSuccess(true);
      setShowToast(true);
    } else if (searchParams.get("underwritersUpdated") !== null) {
      setUnderwritersUpdatedSuccess(true);
      setShowToast(true);
    } else if (searchParams.get("userDetailsUpdated") !== null) {
      setUserDetailsUpdatedSuccess(true);
      setShowToast(true);
    } else if (searchParams.get("error") !== null) {
      setError(true);
      setShowToast(true);
    } else {
      setShowToast(true);
    }
    window.history.replaceState(null, null, window.location.pathname); // Removes URL params after toast has displayed
  };

  useEffect(() => {
    triggerToast(props.searchParamsString);
  }, []);

  return (
    <Toast
      className="m-3 position-absolute align-self-center"
      show={showToast}
      onClose={() => setShowToast(false)}
      delay={6000}
      autohide
    >
      {paypalSuccess ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">Purchase successful</h6>
          </Toast.Header>
          <Toast.Body>
            <p>Assessments have been added to your account</p>
          </Toast.Body>
        </>
      ) : newAssessmentSuccess ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">New assessment sent</h6>
          </Toast.Header>
          <Toast.Body>
            <p>Your client has been notified via email and sms</p>
          </Toast.Body>
        </>
      ) : underwriterSentSuccess ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">Assessment sent to underwriter(s)</h6>
          </Toast.Header>
          <Toast.Body>
            <p>
              Your selected underwriter(s) have been notified of the pending
              assessment
            </p>
          </Toast.Body>
        </>
      ) : underwritersUpdatedSuccess ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">Preferred underwriters updated</h6>
          </Toast.Header>
          <Toast.Body>
            <p>
              Your preferred underwriters will be displayed when submitting an
              assessment.
            </p>
          </Toast.Body>
        </>
      ) : userDetailsUpdatedSuccess ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">User details updated</h6>
          </Toast.Header>
          <Toast.Body>
            <p>Your details have been successfully updated.</p>
          </Toast.Body>
        </>
      ) : error ? (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">Error</h6>
          </Toast.Header>
          <Toast.Body>
            <p>An error has occurred. Your changes have not been made.</p>
          </Toast.Body>
        </>
      ) : (
        <>
          <Toast.Header>
            <h6 className="me-auto my-1">Error</h6>
          </Toast.Header>
          <Toast.Body>
            <p>Toast notification parameters missing</p>
          </Toast.Body>
        </>
      )}
    </Toast>
  );
};

export default DashboardToast;

// PayPal success search params
// ?paymentId=PAYID-MMCHYWI17L04933LM8272622&token=EC-7ND544559S9124132&PayerID=BHCP55HJB66JC
