import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioGroup from "../RadioMulti";

const tinnitusStart = [
  {
    label: "Symptoms are ongoing",
    value: "ongoing",
  },
  {
    label: "Less than 6 months ago",
    value: "lessthansix",
  },
  {
    label: "More than 6 months ago",
    value: "morethansix",
  },
];
const resetArray = [
  { name: "tinnitusStart", type: "" },
  { name: "tinnitusDetails", type: "" },
  { name: "tinnitusDeafness", type: "" },
  { name: "tinnitusDeafnessDetails", type: "" },
  { name: "tinnitusDoctorRecommended", type: "" },
  { name: "tinnitusDetailsInvestigation", type: "" },
  { name: "tinnitusDailyLife", type: "" },
  { name: "tinnitusDailyLifeDetails", type: "" },
];
export default function Tinnitus({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Tinnitus", value: "tinnitus" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("tinnitus") && (
        <RadioGroup
          question="When did you last experience symptoms?"
          name="tinnitusStart"
          items={tinnitusStart}
          isSubForm
        />
      )}
      {values.tinnitusStart === "ongoing" && (
        <AnswerBox
          label="Please Provide Details"
          name="tinnitusDetails"
          isSubForm
        />
      )}
      {(values.tinnitusDetails ||
        values.tinnitusStart === "lessthansix" ||
        values.tinnitusStart === "morethansix") && (
        <YesNoQuestion
          question="Do you suffer any form of deafness? "
          name="tinnitusDeafness"
          isSubForm
        />
      )}
      {values.tinnitusDeafness === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="tinnitusDeafnessDetails"
          isSubForm
        />
      )}
      {(values.tinnitusDeafnessDetails ||
        values.tinnitusDeafness === "false") && (
        <YesNoQuestion
          question="In the last 2 years has your doctor recommended you have further investigations completed? Eg. CT scan"
          name="tinnitusDoctorRecommended"
          isSubForm
        />
      )}
      {values.tinnitusDoctorRecommended === "true" && (
        <AnswerBox
          label="Please provide details of when the tests were conducted and the outcome of
          the investigation."
          name="tinnitusDetailsInvestigation"
          isSubForm
        />
      )}
      {(values.tinnitusDetailsInvestigation ||
        values.tinnitusDoctorRecommended === "false") && (
        <YesNoQuestion
          question="Does this condition restrict your activities or daily life in any way?"
          name="tinnitusDailyLife"
          isSubForm
        />
      )}
      {values.tinnitusDailyLife === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="tinnitusDailyLifeDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
