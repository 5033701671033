import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { List, Collapse } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import { toggleDrawer } from "../../store/actions/authActions";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import photo from "../../assets/preAssesLogo.png";

import { useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";

import { withRouter } from "react-router";

const role = sessionStorage.getItem("role");

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  rehide: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  hide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function TemporaryDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  let dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const token = useSelector((state) => state.auth.token);

  const [openIndex, setOpenIndex] = useState(0);

  let history = useHistory();

  const handleDrawerClose = () => {
    props.toggleDrawer();
  };

  const handleClick = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const onToggle = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.toggleDrawer();
  };

  const onRoute = (path) => {
    // props.history.push(path);
    history.push(path);

    props.toggleDrawer();
  };

  const advisorDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#advisor`;
  };
  const videoDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#videodemo`;
  };
  const howDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#how`;
  };
  const whyDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#why`;
  };
  const planDirect = () => {
    window.location.href = `${process.env.REACT_APP_CLIENT}#pricing`;
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      {token ? (
        <List>
          <div style={{ backgroundColor: "white" }} className={classes.rehide}>
            <img
              className="logo"
              src={photo}
              alt="Login"
              style={{ width: "250px" }}
            />
          </div>
          {props.permissions &&
            props.permissions.map((route, index) => (
              <Fragment key={index}>
                <ListItem button onClick={(e) => handleClick(index)}>
                  <ListItemText
                    primary={route.name}
                    style={{ fontFamily: "Overpass, sans-serif" }}
                  />
                  {index === openIndex ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {route.children.length && (
                  <Collapse
                    in={openIndex === index ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {route.children
                        .filter((e) => !e.isHidden)
                        .map((child, idx) => (
                          <ListItem
                            button
                            className={classes.nested}
                            key={idx}
                            selected={selectedIndex === idx}
                            onClick={(e) => {
                              onRoute(child.path);
                              handleListItemClick(e, idx);
                            }}
                          >
                            <ListItemText
                              style={{ fontFamily: "Overpass, sans-serif" }}
                              primary={child.name}
                            />
                          </ListItem>
                        ))}
                    </List>
                    <Divider />
                  </Collapse>
                )}
              </Fragment>
            ))}
          <ListItem button className={classes.hide} onClick={handleLogout}>
            <ListItemText primary="LOGOUT" />
          </ListItem>
        </List>
      ) : (
        <List>
          <Fragment>
            <div
              style={{ backgroundColor: "white" }}
              className={classes.rehide}
            >
              <img
                className="logo"
                src={photo}
                alt="Login"
                style={{ width: "250px" }}
              />
            </div>
            <ListItem button onClick={() => onRoute("#why")}>
              <a
                href="/"
                onClick={videoDirect}
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontFamily: "Overpass, sans-serif",
                }}
              >
                <ListItemText
                  style={{ fontFamily: "Overpass, sans-serif" }}
                  primary="Video Demonstration"
                />{" "}
              </a>
            </ListItem>
            <ListItem button onClick={() => onRoute("#why")}>
              <a
                href="/"
                onClick={advisorDirect}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText
                  style={{ fontFamily: "Overpass, sans-serif" }}
                  primary="Designed by an Adviser"
                />{" "}
              </a>
            </ListItem>
            <ListItem button>
              <a
                href="/"
                onClick={howDirect}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText
                  style={{ fontFamily: "Overpass, sans-serif" }}
                  primary="How Does It Work?"
                />
              </a>
            </ListItem>
            <ListItem button onClick={() => onRoute("#why")}>
              <a
                href="/"
                onClick={whyDirect}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemText
                  style={{ fontFamily: "Overpass, sans-serif" }}
                  primary="Why Should I Use It? "
                />{" "}
              </a>
            </ListItem>
            <ListItem button onClick={() => onRoute("#why")}>
              <a
                href="/"
                onClick={planDirect}
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                <ListItemText
                  style={{ fontFamily: "Overpass, sans-serif" }}
                  primary="Plans & Pricing"
                />
              </a>
            </ListItem>
            <ListItem button onClick={() => onRoute("/contact")}>
              <ListItemText
                style={{ fontFamily: "Overpass, sans-serif" }}
                primary=" SUPPORT"
              />
            </ListItem>
            <ListItem button onClick={() => onRoute("/login")}>
              <ListItemText
                style={{ fontFamily: "Overpass, sans-serif" }}
                primary="SIGN IN"
              />
            </ListItem>
            <ListItem button onClick={() => onRoute("/")}>
              <ListItemText
                style={{ fontFamily: "Overpass, sans-serif" }}
                primary="GET STARTED!"
              />
            </ListItem>
          </Fragment>
        </List>
      )}
    </div>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={props.isDrawerOpen}
        onClose={onToggle("left", false)}
        variant="persistent"
        style={{
          // display: token && role === "advisor" ? "none" : "flex",
          display: "flex",
        }}
      >
        {list("left")}
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.auth.isDrawerOpen,
    token: state.auth.token,
    permissions: state.auth.routes,
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleDrawer })(TemporaryDrawer)
);
