import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField as TF,
  FormControl,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import axios from '../../lib/axios';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#004AAD',
    color: 'white',
    fontFamily: 'Overpass, sans-serif',
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});

export default function Underwriter(props) {
  const classes = useStyles();

  const [componentType, setComponentType] = useState('create');
  const [callingBackend, setCallingBackend] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    company: {},
    companyId: '',
    advisor: {},
    advisorId: '',
    state: 'Australia wide',
    contactNumber: '',
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    if (componentType === 'create') {
      try {
        setCallingBackend(true);
        await axios.post('/underwriter', {
          name: e.name,
          email: e.email,
          contactNumber: e.contactNumber,
          companyId: e.companyId,
          advisorId: e.advisorId,
          state: e.state,
        });
        await axios.post('/analytics/addclient', {
          code: 'ADD',
          eventName: 'underwriter',
          arNumber: users.arNumber,
          pname: e.name,
          adname: users.firstName,
        });
        resetForm();
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Underwriter created successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Underwriter created successfully!',
          });
          setOpen(false);
          const path = '/preferences';
          props.history.push(path);
        }, 3000);
      } catch (error) {
        if (error.response.status === 403) {
          setOpen(true);
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Underwriter Email already exists!',
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Underwriter Email already exists!',
            });
            setOpen(false);
          }, 3000);
        } else {
          setOpen(true);
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Underwriter creation failed!',
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Underwriter creation failed!',
            });
            setOpen(false);
          }, 3000);
        }
      } finally {
        setCallingBackend(false);
      }
    } else {
      try {
        setCallingBackend(true);
        await axios.put(`/underwriter/${props.location.state.id}`, e);
        props.history.push('/preferences');
      } catch (error) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Underwriter updating failed!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Underwriter updating failed!',
          });
          setOpen(false);
        }, 3000);
      } finally {
        setCallingBackend(false);
      }
    }
  };

  const get = async (id) => {
    try {
      const data = await axios.get(`/underwriter/${id}`);

      setInitialValues({
        name: data.data.name,
        email: data.data.email,
        contactNumber: data.data.contactNumber,
        companyId: data.data.companyId._id,
        company: data.data.companyId,
        state: data.data.state,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setComponentType('update');
      get(props.location.state.id);
    }
    /* eslint-disable */
  }, [props.location.state]);

  // Get Insurance companies

  const getCompanies = async () => {
    try {
      const { data } = await axios.get('/company');

      const activeCompanies = data.filter((e) => e.status);

      setCompanies(activeCompanies);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Company loading failed!',
      });
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await axios.get('/auth/me');

      console.log('user', data);
      setUsers(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Users loading failed!',
      });
    }
  };

  useEffect(() => {
    getCompanies();
    getUsers();
    // eslint-disable-next-line
  }, []);

  // validation schema
  let validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Underwriter name is required!')
      .min(3, 'Min length for the name is 3')
      .max(35, 'Max length for the name is 35.'),
    email: Yup.string().required('Email is required.').email(),
    contactNumber: Yup.string().matches(
      /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/,
      'Please enter valid Australian phone number'
    ),
    // companyId: Yup.string().required("Company is required."),
    state: Yup.string().required('State is required.'),
    companyId: Yup.string().required('Comapny is required.'),
  });

  const states = [
    'Australia wide',
    'Northern Territory',
    'Western Australia',
    'South Australia',
    'Queensland',
    'Victoria',
    'Australian Capital Territory',
    'Tasmania',
  ];

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            errors,
            setFieldTouched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    {componentType === 'create'
                      ? 'Create an Underwriter'
                      : 'Update Underwriter'}
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='email'
                          label='Email'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='name'
                          label='Name'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          name='contactNumber'
                          label='Contact Number [xxxxxxxxxx, +61xxxxxxxx]'
                          component={TextField}
                          variant='outlined'
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          fullWidth
                        ></Field>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth variant='outlined'>
                          <Autocomplete
                            id='combo-box-demo'
                            options={companies}
                            value={values.company}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              console.log('auto complete', event, newValue);
                              if (newValue) {
                                const { _id } = newValue;
                                setFieldValue('companyId', _id);
                                setTimeout(() =>
                                  setFieldTouched('companyId', true)
                                );
                                setFieldValue('company', newValue);
                              } else {
                                setFieldValue('company', null);
                                setFieldValue('companyId', null);
                              }
                            }}
                            renderInput={(params) => (
                              <TF
                                error={errors.companyId}
                                helperText={
                                  errors.companyId ? 'Company is required' : ''
                                }
                                {...params}
                                label='Insurance Company'
                                variant='outlined'
                                required
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl variant='outlined' fullWidth>
                          <InputLabel id='demo-simple-select-outlined-label'>
                            State
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-outlined-label'
                            id='demo-simple-select-outlined'
                            value={values.state}
                            onChange={handleChange}
                            label='State'
                            name='state'
                            fullWidth
                            defaultValue='Victoria'
                            required
                          >
                            {states.map((e) => (
                              <MenuItem value={e}>{e}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          className={classes.button}
                          color={
                            componentType === 'create' ? 'primary' : 'secondary'
                          }
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          {componentType === 'create' ? 'create' : 'update'}
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>
                  <Typography
                    style={{
                      marginLeft: 15,
                      fontFamily: 'Overpass, sans-serif',
                    }}
                  >
                    * Required field
                  </Typography>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
