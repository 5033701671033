import React, { Fragment } from "react";

import RadioMulti from "../RadioMulti";
import AnswerBox from "../AnswerBox";
import CheckBox from "../CheckBox";

// eslint-disable-next-line

// FORM [2,2]

const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const resetArray = [
  { name: "CystYes", type: "" },
  { name: "onTheSkinOnlyYes", type: "" },
  { name: "CystAnyOtherTreatmentYes", type: "" },
  { name: "CystOtherTreatmentDetails", type: "" },
  { name: "recurrencesOfThisCystYes", type: "" },
  { name: "recurrencesOfThisCystDetails", type: "" },
];
export default function SubFormThree({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Cyst", value: "cyst" }]}
        name="subFormsOfSixteen"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />

      {values.subFormsOfSixteen.includes("cyst") && (
        <RadioMulti
          question="Was the cyst
          on the skin
          only?"
          name={"onTheSkinOnlyYes"}
          items={items}
          isSubForm
        />
      )}
      {values.subFormsOfSixteen.includes("cyst") &&
        values.onTheSkinOnlyYes === "yes" && (
          <RadioMulti
            question="Was any other treatment required other than removal, drainage or antibiotics?"
            name={"CystAnyOtherTreatmentYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cyst") &&
        values.CystAnyOtherTreatmentYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="CystOtherTreatmentDetails"
            isSubForm
          />
        )}
      {(values.CystAnyOtherTreatmentYes === "no" ||
        values.CystOtherTreatmentDetails !== "") &&
        values.subFormsOfSixteen.includes("cyst") && (
          <RadioMulti
            question="Have there been further recurrences of this cyst?"
            name={"recurrencesOfThisCystYes"}
            items={items}
            isSubForm
          />
        )}
      {values.subFormsOfSixteen.includes("cyst") &&
        values.recurrencesOfThisCystYes === "yes" && (
          <AnswerBox
            label="Please provide details?"
            name="recurrencesOfThisCystDetails"
            isSubForm
          />
        )}
    </Fragment>
  );
}
