import React, { useState } from "react";
import { useStyles } from "./classes";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import Img from "../../assets/images/contact.png";
import Img1 from "../../assets/images/image2.png";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "formik-material-ui";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import axios from "../../lib/axios";
import HeaderTop from "../../components/headerTop/HeaderTop";
import Drawer from "../../components/layouts/Drawer";
import Footer from "../Footer/Footer";

export default function Contact() {
  const classes = useStyles();
  let initialValues = {
    name: "",
    email: "",
    phone: "",
    query: "",
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  let contactSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required!")
      .max(35, "Max length for the Name is 15."),
    email: Yup.string()
      .email()
      .required("Email is required!")
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        "Please enter valid Email"
      ),
    phone: Yup.string()
      .required("Phone Number is required!")
      .matches(
        /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/,
        "Please enter valid Australian phone number"
      ),
    query: Yup.string()
      .required("message is required!")
      .max(500, "Max length for the password is 15."),
  });

  const submit = async (e) => {
    try {
      await axios.post("/contactUs", {
        name: e.name,
        email: e.email,
        mobile: e.phone,
        message: e.query,
      });
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (error) {
      if (error) {
        setIsServerError(true);
        setTimeout(() => {
          setIsServerError(false);
          localStorage.clear();
        }, 3000);
      }
    }
  };

  function FormRow1() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            top: 0,
            zIndex: 10,
          }}
        >
          <HeaderTop />
          <Drawer />
        </div>
        {/* <div className={classes.spdiv}></div> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className={classes.hide}
        >
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
          <Typography className={classes.header}>
            Have you got a question....
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={contactSchema}
          >
            {({ dirty, isValid }) => {
              return (
                <Form className={classes.alginLeft}>
                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.hidemb}
                    >
                      <Typography className={classes.text}>NAME*</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                      <Field
                        name="name"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        size="small"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                        required
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.hidemb}
                    >
                      <Typography className={classes.text}>
                        EMAIL* &nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                      <Field
                        name="email"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        style={{ backgroundColor: "white", color: "black" }}
                        size="small"
                        inputProps={{
                          style: {
                            fontFamily: "Overpass, sans-serif",
                          },
                        }}
                        required
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.hidemb}
                    >
                      <Typography className={classes.text}>PH NO.*</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                      <Field
                        name="phone"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        size="small"
                        style={{ backgroundColor: "white", color: "black" }}
                        required
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.hidemb}
                    >
                      <Typography className={classes.text}>
                        QUERY*&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                      <Field
                        name="query"
                        multiline
                        rows={3}
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        size="small"
                        inputProps={{
                          style: { fontFamily: "Overpass, sans-serif" },
                        }}
                        style={{ backgroundColor: "white", color: "black" }}
                        required
                      ></Field>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      className={classes.hidemb}
                    ></Grid>
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        disabled={!dirty || !isValid}
                        type="submit"
                      >
                        Send
                      </Button>

                      {isSuccess && (
                        <>
                          <Alert
                            icon={false}
                            severity="success"
                            style={{ marginTop: 10 }}
                          >
                            Email Sent successfully!
                          </Alert>
                        </>
                      )}

                      {isServerError && (
                        <>
                          <Alert
                            icon={false}
                            severity="error"
                            style={{ marginTop: 10 }}
                          >
                            Email Sent fail!
                          </Alert>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginTop: 30, marginBottom: 65 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.text1}>
                        * Required field
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className={classes.hide}
        >
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Footer />
      </React.Fragment>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} spacing={3}>
            <FormRow1 />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
