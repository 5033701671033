import React, { useState } from "react";
import { Fragment } from "react";
import AnswerBox from "./AnswerBox";
import RadioMulti from "./RadioMulti";
import CheckBox from "./CheckBox";
import Alert from "@material-ui/lab/Alert";
import { setPage } from "../../store/actions/formActions";

import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "../../styles/form.module.css";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "../../lib/axios";
import { useStyles } from "./classes";

const qualificationsList = [
  {
    label: "Trade Qualification",
    value: "tradeQualification",
  },
  {
    label: "Professional Qualification",
    value: "professionalQualification",
  },
  {
    label: "Tertiary Qualification",
    value: "tertiaryQualification",
  },
  {
    label: "None of the above",
    value: "none",
  },
];
const weeksList = [
  {
    label: "Less than 35 weeks",
    value: "lessThan35Weeks",
  },
  {
    label: "More than 35 weeks",
    value: "moreThan35Weeks",
  },
];
const items = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

const KMList = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "0-100Km per week",
    value: "between0And100Km",
  },
  {
    label: "100-300km per week",
    value: "between100And300km",
  },
  {
    label: "300-500km per week",
    value: "between300And500km",
  },
  {
    label: "500km+ per week",
    value: "moreThan500km",
  },
];

const SpendTimeList = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "1%-20%",
    value: "between1%-20%",
  },
  {
    label: "20%-30%",
    value: "between20%-30%",
  },
  {
    label: "30%+",
    value: "moreThan30%",
  },
];
const drivingList = [
  {
    label: "0-5%",
    value: "0-5%",
  },
  {
    label: "5-10%",
    value: "between5-10%",
  },
  {
    label: "10-25%",
    value: "between10-25%",
  },
  {
    label: "25%+",
    value: "moreThan25%",
  },
];
// const spendThingList = [
//   {
//     label: "Kneeling %",
//     value: "kneeling",
//   },
//   {
//     label: "Sitting %",
//     value: "sitting",
//   },
//   {
//     label: "Standing %",
//     value: "standing",
//   },
//   {
//     label: "walking %",
//     value: "walking",
//   },
//   {
//     label: "Bending %",
//     value: "bending",
//   },
//   {
//     label: "Climbing %",
//     value: "climbing",
//   },
// ];
export default function FormTwentySix() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    //SubForm26
    agreement: "true",
    isAnyIncome: "true",
    income: "",
    reportableIncomeForLastYear: "",
    reportableIncomeForPreviousYear: "",
    anyQualification: [],
    qualificationDetails: "",
    howManyWeeks: "",
    howMuchDriving: [],
    howMuchTime: "",
    SpendTimeList: "",
    spendTimeWorkingFromHome: "",
    anyManualWork: "",
    kneeling: "",
    sitting: "",
    standing: "",
    walking: "",
    bending: "",
    climbing: "",
    additionalInfo: "",
  });
  const validationSchema = Yup.object().shape({
    //SubForm26
    isAnyIncome: Yup.string().required(),

    income: Yup.string().when("isAnyIncome", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    reportableIncomeForLastYear: Yup.string().when(
      ["isAnyIncome", "income"],
      (isAnyIncome, income) => {
        if (isAnyIncome === "true" && income) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    reportableIncomeForPreviousYear: Yup.string().when(
      ["isAnyIncome", "income", "reportableIncomeForLastYear"],
      (isAnyIncome, income, reportableIncomeForLastYear) => {
        if (isAnyIncome === "true" && income && reportableIncomeForLastYear) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    qualificationDetails: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification
      ) => {
        if (
          isAnyIncome === "true" &&
          income &&
          reportableIncomeForLastYear &&
          reportableIncomeForPreviousYear &&
          (anyQualification.includes("tradeQualification") ||
            anyQualification.includes("professionalQualification") ||
            anyQualification.includes("tertiaryQualification"))
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    howManyWeeks: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails
      ) => {
        if (
          isAnyIncome === "true" &&
          income &&
          reportableIncomeForLastYear &&
          reportableIncomeForPreviousYear &&
          (anyQualification.includes("tradeQualification") ||
            anyQualification.includes("professionalQualification") ||
            anyQualification.includes("tertiaryQualification")) &&
          qualificationDetails
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    howMuchTime: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving
      ) => {
        if (
          isAnyIncome === "true" &&
          income &&
          reportableIncomeForLastYear &&
          reportableIncomeForPreviousYear &&
          (anyQualification.includes("tradeQualification") ||
            anyQualification.includes("professionalQualification") ||
            anyQualification.includes("tertiaryQualification")) &&
          qualificationDetails &&
          (howMuchDriving.includes("between0And100Km") ||
            howMuchDriving.includes("between100And300km") ||
            howMuchDriving.includes("between300And500km") ||
            howMuchDriving.includes("moreThan500km"))
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    spendTimeWorkingFromHome: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          howMuchDriving.includes("none")
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),

    anyManualWork: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") && spendTimeWorkingFromHome)
        ) {
          return Yup.string().required("This field is required ");
        }
      }
    ),
    kneeling: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
    sitting: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
    standing: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
    walking: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
    bending: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
    climbing: Yup.string().when(
      [
        "isAnyIncome",
        "income",
        "reportableIncomeForLastYear",
        "reportableIncomeForPreviousYear",
        "anyQualification",
        "qualificationDetails",
        "howMuchDriving",
        "howMuchTime",
        "spendTimeWorkingFromHome",
        "anyManualWork",
      ],
      (
        isAnyIncome,
        income,
        reportableIncomeForLastYear,
        reportableIncomeForPreviousYear,
        anyQualification,
        qualificationDetails,
        howMuchDriving,
        howMuchTime,
        spendTimeWorkingFromHome,
        anyManualWork
      ) => {
        if (
          (isAnyIncome === "true" &&
            income &&
            reportableIncomeForLastYear &&
            reportableIncomeForPreviousYear &&
            (anyQualification.includes("tradeQualification") ||
              anyQualification.includes("professionalQualification") ||
              anyQualification.includes("tertiaryQualification")) &&
            qualificationDetails &&
            (howMuchDriving.includes("between0And100Km") ||
              howMuchDriving.includes("between100And300km") ||
              howMuchDriving.includes("between300And500km") ||
              howMuchDriving.includes("moreThan500km")) &&
            howMuchTime) ||
          (howMuchDriving.includes("none") &&
            spendTimeWorkingFromHome &&
            anyManualWork)
        ) {
          return Yup.number()
            .required("This percentage value is required!")
            .positive("Invalid number.")
            .min(0, "Min value 0.")
            .max(100, "Max value 100.");
        }
      }
    ),
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.put("/form/formtwentysix", {
        formTwentySix: e,
        pointer: 27,
        formid: id,
      });

      dispatch(setPage(27));
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Questionnaire submit failed",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Questionnaire submit failed",
        });
      }, 3000);
    }
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values }) => {
            return (
              <Form>
                <Card style={{ marginBottom: "4rem", padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <Fragment>
                        <AnswerBox
                          name="income"
                          label="What is your income from your current occupation?
                          (Personal income is income earned by your personal
                          exertion. Do not include investments.)"
                        />
                        {values.income && (
                          <AnswerBox
                            name="reportableIncomeForLastYear"
                            label="What was your reportable employment income for the last
                            financial year?"
                          />
                        )}
                        {values.reportableIncomeForLastYear && (
                          <AnswerBox
                            name="reportableIncomeForPreviousYear"
                            label="What was your reportable employment income from the
                            previous financial year?"
                          />
                        )}
                        {values.reportableIncomeForPreviousYear && (
                          <CheckBox
                            question="Do you have any qualifications?"
                            name={"anyQualification"}
                            list={qualificationsList}
                          />
                        )}
                        {(values.anyQualification.includes(
                          "tradeQualification"
                        ) ||
                          values.anyQualification.includes(
                            "professionalQualification"
                          ) ||
                          values.anyQualification.includes(
                            "tertiaryQualification"
                          )) && (
                          <AnswerBox
                            name="qualificationDetails"
                            label="Provide qualification details"
                          />
                        )}
                        {values.qualificationDetails && (
                          <RadioMulti
                            question="How many weeks a year do your work in the aforementioned
                            occupation?"
                            name={"howManyWeeks"}
                            items={weeksList}
                          />
                        )}
                        {values.howManyWeeks && (
                          <CheckBox
                            question="How much driving do you do for work (not travelling to
                              and from workplace)"
                            name={"howMuchDriving"}
                            list={KMList}
                          />
                        )}
                        {(values.howMuchDriving.includes(
                          "between100And300km"
                        ) ||
                          values.howMuchDriving.includes(
                            "between300And500km"
                          ) ||
                          values.howMuchDriving.includes("moreThan500km") ||
                          values.howMuchDriving.includes("between0And100Km")) && (
                          <RadioMulti
                            question="How much time in your workday do you spend driving?"
                            name={"howMuchTime"}
                            items={drivingList}
                          />
                        )}
                        {(values.howMuchDriving.includes("none") ||
                          values.howMuchTime) && (
                          <RadioMulti
                            question="How much time do you spend working from home?"
                            name={"spendTimeWorkingFromHome"}
                            items={SpendTimeList}
                          />
                        )}
                        {values.spendTimeWorkingFromHome && (
                          <RadioMulti
                            question="Do you do any manual work?"
                            name={"anyManualWork"}
                            items={items}
                          />
                        )}
                        {values.anyManualWork && (
                          <>
                            <p>
                              How much time do you spend doing the following in
                              a workday?
                            </p>
                            <AnswerBox name="kneeling" label="Kneeling %" />
                            <AnswerBox name="sitting" label="Sitting %" />
                            <AnswerBox name="standing" label="Standing %" />
                            <AnswerBox name="walking" label="Walking %" />
                            <AnswerBox name="bending" label="Bending %" />
                            <AnswerBox name="climbing" label="Climbing %" />
                          </>
                        )}
                        <AnswerBox
                          name="additionalInfo"
                          label="Please provide any additional information that may be relevant to your assessment"
                          rows="6"
                          required={false}
                        />
                      </Fragment>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!dirty || !isValid}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                  {alert.showAlert && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Grid
                        item
                        md={8}
                        alignItems="center"
                        style={{ marginTop: 50, marginBottom: 10 }}
                      >
                        <Alert
                          severity={alert.severity}
                          onClose={() =>
                            setAlert({
                              ...alert,
                              showAlert: false,
                            })
                          }
                        >
                          {alert.message}
                        </Alert>
                      </Grid>
                    </div>
                  )}
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
