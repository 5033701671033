import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "epilepsyOtherTreatment", type: "" },
  { name: "epilespsyOtherstartDate", type: "" },
  { name: "epilepsyOtherRestrictedDuties", type: "" },
  { name: "epilepsyOtherDetails", type: "" },
];

export default function EpilepsyOther({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Epilepsy - other types", value: "epilepsyother" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>
      {values.symptoms.includes("epilepsyother") && (
        <AnswerBox
          label="What treatment was provided?"
          name="epilepsyOtherTreatment"
          isSubForm
        />
      )}

      {values.epilepsyOtherTreatment && (
        <AnswerBox
          label="When did this occur?"
          name="epilespsyOtherstartDate"
          isSubForm
        />
      )}
      {values.epilespsyOtherstartDate && (
        <YesNoQuestion
          question="Are you in any way restricted in the occupational duties or activities of daily living from this condition?"
          name="epilepsyOtherRestrictedDuties"
          isSubForm
        />
      )}
      {values.epilepsyOtherRestrictedDuties === "true" && (
        <AnswerBox 
        label="Please provide details"
        name="epilepsyOtherDetails"
        isSubForm
        />
      )}
    </Fragment>
  );
}
