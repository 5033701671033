import React, { useState } from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

import styles from "../../../styles/form.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enAuLocale from "date-fns/locale/en-AU";
import { TextField as TF, Box } from "@mui/material/";

import moment from "moment";
const resetArray = [
  { name: "otherDescribeSymptoms", type: "" },
  { name: "otherStartDate", type: "" },
  { name: "otherTimePeriod", type: "" },
  { name: "otherReasons", type: "" },
  { name: "otherFactors", type: "" },
  { name: "otherThoughts", type: "" },
  { name: "otherWhatTriggered", type: "" },
  { name: "otherSymptomsCommenced", type: "" },
  { name: "otherReoccurrencesOfCondition", type: "" },
  { name: "otherHowManyReoccurrences", type: "" },
  { name: "otherTreatment", type: [] },
  { name: "otherCounsellingWhenStart", type: "" },
  { name: "otherCounsellingEndDate", type: "" },
  { name: "otherCounsellingTherapyDetails", type: "" },
  { name: "otherPsychotherapyWhenStart", type: "" },
  { name: "otherPsychotherapyEndDate", type: "" },
  { name: "otherPsychotherapyTherapyDetails", type: "" },
  { name: "otherMedicationDose", type: "" },
  { name: "otherMedicationEndDate", type: "" },
  { name: "otherConditionMedication", type: "" },
  { name: "otherDosageOften", type: "" },
  { name: "otherDutiesRestrictionCondition", type: "" },
  { name: "otherWorkRestriction", type: "" },
  { name: "otherLifeRestriction", type: "" },
  { name: "otherDetails", type: "" },
];

export default function Other({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout(() => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
        }
      }, 0);
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  const [value, setValue] = useState(null);

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Other", value: "other" }]}
        name="anxiety"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.anxiety.includes("other") && (
        <AnswerBox
          label="Describe your symptoms"
          name="otherDescribeSymptoms"
          isSubForm
        />
      )}
      {values.otherDescribeSymptoms && (
        <AnswerBox
          label="What date did they start? "
          name="otherStartDate"
          isSubForm
        />
      )}
      {values.otherStartDate && (
        <AnswerBox
          label="How long did they last?"
          name="otherTimePeriod"
          isSubForm
        />
      )}
      {values.otherTimePeriod && (
        <YesNoQuestion
          question="Was there a reason identified or a trigger that was a cause if these details? "
          name="otherReasons"
          isSubForm
        />
      )}
      {values.otherReasons === "true" && (
        <AnswerBox
          label="Please provide details of the trigger or factors?"
          name="otherFactors"
          isSubForm
        />
      )}
      {(values.otherReasons === "false" || values.otherFactors) && (
        <YesNoQuestion
          question="Have you ever had any suicidal thoughts , threatened to self harm or engaged in self harm?"
          name="otherThoughts"
          isSubForm
        />
      )}
      {values.otherThoughts === "true" && (
        <AnswerBox
          label="Provide details including what triggered the suicidal attempt or self harm threats or actual, provide details or how often and the last occurrence"
          name="otherWhatTriggered"
          isSubForm
        />
      )}
      {(values.otherThoughts === "false" || values.otherWhatTriggered) && (
        <AnswerBox
          label="What was the date the symptoms commenced?"
          name="otherSymptomsCommenced"
          isSubForm
        />
      )}
      {values.otherSymptomsCommenced && (
        <YesNoQuestion
          question="Have you had reoccurrences of this condition?"
          name="otherReoccurrencesOfCondition"
          isSubForm
        />
      )}
      {values.otherReoccurrencesOfCondition === "true" && (
        <AnswerBox
          label="State how many times and when these reoccurrences have happened?"
          name="otherHowManyReoccurrences"
          isSubForm
        />
      )}
      {values.otherReoccurrencesOfCondition && (
        <>
          <Box className={styles.subFormBox}>
            <p className={styles.question}>
              Are you receiving any of the following?
            </p>
          </Box>
          <CheckBox
            question=""
            name="otherTreatment"
            list={[{ label: "Counselling?", value: "counselling" }]}
            isSubForm
          />
        </>
      )}
      {values.otherTreatment.includes("counselling") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="otherCounsellingWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="otherCounsellingEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="otherCounsellingTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.otherReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="otherTreatment"
            list={[{ label: "Psychotherapy?", value: "psychotherapy" }]}
            isSubForm
          />
        </>
      )}
      {values.otherTreatment.includes("psychotherapy") && (
        <>
          <AnswerBox
            label="When did this start?"
            name="otherPsychotherapyWhenStart"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="otherPsychotherapyEndDate"
            isSubForm
          />
          <AnswerBox
            label="Please provide details"
            name="otherPsychotherapyTherapyDetails"
            isSubForm
          />
        </>
      )}
      {values.otherReoccurrencesOfCondition && (
        <>
          <CheckBox
            question=""
            name="otherTreatment"
            list={[{ label: "Medication?", value: "medication" }]}
            isSubForm
          />
        </>
      )}
      {values.otherTreatment.includes("medication") && (
        <>
          <AnswerBox
            label="What was/is the medication and dose?"
            name="otherMedicationDose"
            isSubForm
          />
          <AnswerBox
            label="When did this end?"
            name="otherMedicationEndDate"
            isSubForm
          />
          <YesNoQuestion
            question="Do you continue to take this medication?"
            name="otherConditionMedication"
            isSubForm
          />
          {values.otherConditionMedication === "true" && (
            <AnswerBox
              label="Please provide details of how often and dosage "
              name="otherDosageOften"
              isSubForm
            />
          )}
          {(values.otherDosageOften ||
            values.otherConditionMedication === "false") && (
            <YesNoQuestion
              question="Have you ever been off work or been on restricted duties due to your condition?"
              name="otherDutiesRestrictionCondition"
              isSubForm
            />
          )}
          {values.otherDutiesRestrictionCondition === "true" && (
            <AnswerBox
              label="Please provide details on when you were off work or how you were restricted?"
              name="otherWorkRestriction"
              isSubForm
            />
          )}
          {(values.otherDutiesRestrictionCondition === "false" ||
            values.otherWorkRestriction) && (
            <YesNoQuestion
              question="Have you had any ongoing effects or restrictions on your life and activities of any kind?"
              name="otherLifeRestriction"
              isSubForm
            />
          )}
          {values.otherLifeRestriction === "true" && (
            <AnswerBox
              label="Please provide details"
              name="otherDetails"
              isSubForm
            />
          )}
        </>
      )}
    </Fragment>
  );
}
