import React, { Fragment } from 'react';
import { TableCell, Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import ReusableTable from '../../components/layouts/ReusableTable';

const headers = [
  {
    text: 'Package',
    value: 'name',
  },
  {
    text: 'Price',
    value: 'price',
  },
  {
    text: 'Number of Clients',
    value: 'noOfClients',
  },
  {
    text: 'Priority Value',
    value: 'priority',
  },
  {
    text: 'Description',
    value: 'description',
  },
  {
    text: 'Current Status',
    value: 'currentStatus',
  },
  {
    text: 'Change Status',
    value: 'status',
  },
  {
    text: 'Update',
    value: 'update',
  },
];
export default function PackageView(props) {
  const TableB = ({ item, changeStatus, classes, onUpdate }) => {
    return (
      <Fragment>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.name}
        </TableCell>
        <TableCell
          key='type'
          style={{ fontFamily: 'Overpass, sans-serif' }}
        >{`$ ${item.price}`}</TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.numberOfClients}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.displayPriority}
        </TableCell>
        <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.description}
        </TableCell>
        {item.status ? (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            {' '}
            <Chip label='Active' className={classes.green} />
          </TableCell>
        ) : (
          <TableCell key='type' style={{ fontFamily: 'Overpass, sans-serif' }}>
            <Chip label='Inactive' className={classes.red} />
          </TableCell>
        )}

        <TableCell key='status' style={{ fontFamily: 'Overpass, sans-serif' }}>
          {item.status ? (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              size='small'
              className={classes.red}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              style={{ fontFamily: 'Overpass, sans-serif' }}
              variant='contained'
              size='small'
              className={classes.green}
              onClick={() => changeStatus(item._id, item.status)}
            >
              Activate
            </Button>
          )}
        </TableCell>
        <TableCell key='update'>
          <Button
            style={{ fontFamily: 'Overpass, sans-serif' }}
            variant='contained'
            size='small'
            className={classes.grey}
            onClick={() => onUpdate(item._id)}
          >
            update
          </Button>
        </TableCell>
      </Fragment>
    );
  };
  return (
    <ReusableTable
      title='View Packages'
      headers={headers}
      items={[]}
      TableB={TableB}
      fetchDataUrl='/package' //change
      statusChangeUrl='/package/status'
      updatePath='/package-update'
      {...props}
    />
  );
}
