import React, { useState } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
// validation
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import GeneralAnxiety from './subFormsTen/GeneralAnxiety';
import EatingDisorder from './subFormsTen/EatingDisorder';
import Depression from './subFormsTen/Depression';
import DepressiveIllness from './subFormsTen/DepressiveIllness';
import Addiction from './subFormsTen/Addiction';
import TraumaticStress from './subFormsTen/TraumaticStress';
import PsychoticDisorder from './subFormsTen/PsychoticDisorder';
import ChronicTiredness from './subFormsTen/ChronicTiredness';
import Other from './subFormsTen/Other';

import { useDispatch } from 'react-redux';
import { setPage } from '../../store/actions/formActions';

import { useParams } from 'react-router-dom';
import styles from '../../styles/form.module.css';
import axios from '../../lib/axios';
import { useStyles } from './classes';
import NoneOfTheAbove from './NoneOfTheAbove';

export default function FormTen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    anxiety: [],
    generalAnxietyDescribeSymptoms: '',
    generalAnxietyStartDate: '',
    generalAnxietyTimePeriod: '',
    generalAnxietyReasons: '',
    generalAnxietyFactors: '',
    generalAnxietyThoughts: '',
    generalAnxietyWhatTriggered: '',
    generalAnxietySymptomsCommenced: '',
    generalAnxietyReoccurrencesOfCondition: '',
    generalAnxietyHowManyReoccurrences: '',
    generalAnxietyTreatment: [],
    generalAnxietyCounsellingWhenStart: '',
    generalAnxietyCounsellingEndDate: '',
    generalAnxietyCounsellingTherapyDetails: '',
    generalAnxietyPsychotherapyWhenStart: '',
    generalAnxietyPsychotherapyEndDate: '',
    generalAnxietyPsychotherapyTherapyDetails: '',
    generalAnxietyMedicationDose: '',
    generalAnxietyMedicationEndDate: '',
    generalAnxietyConditionMedication: '',
    generalAnxietyDosageOften: '',
    generalAnxietyDutiesRestrictionCondition: '',
    generalAnxietyWorkRestriction: '',
    generalAnxietyLifeRestriction: '',
    generalAnxietyDetails: '',

    eatingDisorderDescribeSymptoms: '',
    eatingDisorderStartDate: '',
    eatingDisorderTimePeriod: '',
    eatingDisorderReasons: '',
    eatingDisorderFactors: '',
    eatingDisorderThoughts: '',
    eatingDisorderWhatTriggered: '',
    eatingDisorderSymptomsCommenced: '',
    eatingDisorderReoccurrencesOfCondition: '',
    eatingDisorderHowManyReoccurrences: '',
    eatingDisorderTreatment: [],
    eatingDisorderCounsellingWhenStart: '',
    eatingDisorderCounsellingEndDate: '',
    eatingDisorderCounsellingTherapyDetails: '',
    eatingDisorderPsychotherapyWhenStart: '',
    eatingDisorderPsychotherapyEndDate: '',
    eatingDisorderPsychotherapyTherapyDetails: '',
    eatingDisorderMedicationDose: '',
    eatingDisorderMedicationEndDate: '',
    eatingDisorderConditionMedication: '',
    eatingDisorderDosageOften: '',
    eatingDisorderDutiesRestrictionCondition: '',
    eatingDisorderWorkRestriction: '',
    eatingDisorderLifeRestriction: '',
    eatingDisorderDetails: '',

    depressionDescribeSymptoms: '',
    depressionStartDate: '',
    depressionTimePeriod: '',
    depressionReasons: '',
    depressionFactors: '',
    depressionThoughts: '',
    depressionWhatTriggered: '',
    depressionSymptomsCommenced: '',
    depressionReoccurrencesOfCondition: '',
    depressionHowManyReoccurrences: '',
    depressionTreatment: [],
    depressionCounsellingWhenStart: '',
    depressionCounsellingEndDate: '',
    depressionCounsellingTherapyDetails: '',
    depressionPsychotherapyWhenStart: '',
    depressionPsychotherapyEndDate: '',
    depressionPsychotherapyTherapyDetails: '',
    depressionMedicationDose: '',
    depressionMedicationEndDate: '',
    depressionConditionMedication: '',
    depressionDosageOften: '',
    depressionDutiesRestrictionCondition: '',
    depressionWorkRestriction: '',
    depressionLifeRestriction: '',
    depressionDetails: '',

    depressiveIllnessDescribeSymptoms: '',
    depressiveIllnessStartDate: '',
    depressiveIllnessTimePeriod: '',
    depressiveIllnessReasons: '',
    depressiveIllnessFactors: '',
    depressiveIllnessThoughts: '',
    depressiveIllnessWhatTriggered: '',
    depressiveIllnessSymptomsCommenced: '',
    depressiveIllnessReoccurrencesOfCondition: '',
    depressiveIllnessHowManyReoccurrences: '',
    depressiveIllnessTreatment: [],
    depressiveIllnessCounsellingWhenStart: '',
    depressiveIllnessCounsellingEndDate: '',
    depressiveIllnessCounsellingTherapyDetails: '',
    depressiveIllnessPsychotherapyWhenStart: '',
    depressiveIllnessPsychotherapyEndDate: '',
    depressiveIllnessPsychotherapyTherapyDetails: '',
    depressiveIllnessMedicationDose: '',
    depressiveIllnessMedicationEndDate: '',
    depressiveIllnessConditionMedication: '',
    depressiveIllnessDosageOften: '',
    depressiveIllnessDutiesRestrictionCondition: '',
    depressiveIllnessWorkRestriction: '',
    depressiveIllnessLifeRestriction: '',
    depressiveIllnessDetails: '',

    addictionDescribeSymptoms: '',
    addictionStartDate: '',
    addictionTimePeriod: '',
    addictionReasons: '',
    addictionFactors: '',
    addictionThoughts: '',
    addictionWhatTriggered: '',
    addictionSymptomsCommenced: '',
    addictionReoccurrencesOfCondition: '',
    addictionHowManyReoccurrences: '',
    addictionTreatment: [],
    addictionCounsellingWhenStart: '',
    addictionCounsellingEndDate: '',
    addictionCounsellingTherapyDetails: '',
    addictionPsychotherapyWhenStart: '',
    addictionPsychotherapyEndDate: '',
    addictionPsychotherapyTherapyDetails: '',
    addictionMedicationDose: '',
    addictionMedicationEndDate: '',
    addictionConditionMedication: '',
    addictionDosageOften: '',
    addictionDutiesRestrictionCondition: '',
    addictionWorkRestriction: '',
    addictionLifeRestriction: '',
    addictionDetails: '',

    traumaticStressDescribeSymptoms: '',
    traumaticStressStartDate: '',
    traumaticStressTimePeriod: '',
    traumaticStressReasons: '',
    traumaticStressFactors: '',
    traumaticStressThoughts: '',
    traumaticStressWhatTriggered: '',
    traumaticStressSymptomsCommenced: '',
    traumaticStressReoccurrencesOfCondition: '',
    traumaticStressHowManyReoccurrences: '',
    traumaticStressTreatment: [],
    traumaticStressCounsellingWhenStart: '',
    traumaticStressCounsellingEndDate: '',
    traumaticStressCounsellingTherapyDetails: '',
    traumaticStressPsychotherapyWhenStart: '',
    traumaticStressPsychotherapyEndDate: '',
    traumaticStressPsychotherapyTherapyDetails: '',
    traumaticStressMedicationDose: '',
    traumaticStressMedicationEndDate: '',
    traumaticStressConditionMedication: '',
    traumaticStressDosageOften: '',
    traumaticStressDutiesRestrictionCondition: '',
    traumaticStressWorkRestriction: '',
    traumaticStressLifeRestriction: '',
    traumaticStressDetails: '',

    psychoticDisorderDescribeSymptoms: '',
    psychoticDisorderStartDate: '',
    psychoticDisorderTimePeriod: '',
    psychoticDisorderReasons: '',
    psychoticDisorderFactors: '',
    psychoticDisorderThoughts: '',
    psychoticDisorderWhatTriggered: '',
    psychoticDisorderSymptomsCommenced: '',
    psychoticDisorderReoccurrencesOfCondition: '',
    psychoticDisorderHowManyReoccurrences: '',
    psychoticDisorderTreatment: [],
    psychoticDisorderCounsellingWhenStart: '',
    psychoticDisorderCounsellingEndDate: '',
    psychoticDisorderCounsellingTherapyDetails: '',
    psychoticDisorderPsychotherapyWhenStart: '',
    psychoticDisorderPsychotherapyEndDate: '',
    psychoticDisorderPsychotherapyTherapyDetails: '',
    psychoticDisorderMedicationDose: '',
    psychoticDisorderMedicationEndDate: '',
    psychoticDisorderConditionMedication: '',
    psychoticDisorderDosageOften: '',
    psychoticDisorderDutiesRestrictionCondition: '',
    psychoticDisorderWorkRestriction: '',
    psychoticDisorderLifeRestriction: '',
    psychoticDisorderDetails: '',

    chronicTirednessDescribeSymptoms: '',
    chronicTirednessStartDate: '',
    chronicTirednessTimePeriod: '',
    chronicTirednessReasons: '',
    chronicTirednessFactors: '',
    chronicTirednessThoughts: '',
    chronicTirednessWhatTriggered: '',
    chronicTirednessSymptomsCommenced: '',
    chronicTirednessReoccurrencesOfCondition: '',
    chronicTirednessHowManyReoccurrences: '',
    chronicTirednessTreatment: [],
    chronicTirednessCounsellingWhenStart: '',
    chronicTirednessCounsellingEndDate: '',
    chronicTirednessCounsellingTherapyDetails: '',
    chronicTirednessPsychotherapyWhenStart: '',
    chronicTirednessPsychotherapyEndDate: '',
    chronicTirednessPsychotherapyTherapyDetails: '',
    chronicTirednessMedicationDose: '',
    chronicTirednessMedicationEndDate: '',
    chronicTirednessConditionMedication: '',
    chronicTirednessDosageOften: '',
    chronicTirednessDutiesRestrictionCondition: '',
    chronicTirednessWorkRestriction: '',
    chronicTirednessLifeRestriction: '',
    chronicTirednessDetails: '',

    otherDescribeSymptoms: '',
    otherStartDate: '',
    otherTimePeriod: '',
    otherReasons: '',
    otherFactors: '',
    otherThoughts: '',
    otherWhatTriggered: '',
    otherSymptomsCommenced: '',
    otherReoccurrencesOfCondition: '',
    otherHowManyReoccurrences: '',
    otherTreatment: [],
    otherCounsellingWhenStart: '',
    otherCounsellingEndDate: '',
    otherCounsellingTherapyDetails: '',
    otherPsychotherapyWhenStart: '',
    otherPsychotherapyEndDate: '',
    otherPsychotherapyTherapyDetails: '',
    otherMedicationDose: '',
    otherMedicationEndDate: '',
    otherConditionMedication: '',
    otherDosageOften: '',
    otherDutiesRestrictionCondition: '',
    otherWorkRestriction: '',
    otherLifeRestriction: '',
    otherDetails: '',

    noneOfTheAbove: false,
  });

  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    generalAnxietyDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    generalAnxietyStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    generalAnxietyTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    generalAnxietyReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string().required('This is required!');
    }),
    generalAnxietyFactors: Yup.string().when('generalAnxietyReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    generalAnxietyThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string().required('This is required!');
    }),
    generalAnxietyWhatTriggered: Yup.string().when('generalAnxietyThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    generalAnxietySymptomsCommenced: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('generalAnxiety'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    generalAnxietyReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('generalAnxiety'))
          return Yup.string().required('This is required!');
      }
    ),
    generalAnxietyHowManyReoccurrences: Yup.string().when(
      'generalAnxietyReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    generalAnxietyCounsellingWhenStart: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyCounsellingEndDate: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyCounsellingTherapyDetails: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyPsychotherapyWhenStart: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyPsychotherapyEndDate: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyPsychotherapyTherapyDetails: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyMedicationDose: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyMedicationEndDate: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    generalAnxietyConditionMedication: Yup.string().when(
      'generalAnxietyTreatment',
      (generalAnxietyTreatment) => {
        if (generalAnxietyTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    generalAnxietyDosageOften: Yup.string().when(
      'generalAnxietyConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    generalAnxietyDutiesRestrictionCondition: Yup.string().when(
      [
        'generalAnxietyDosageOften',
        'generalAnxietyConditionMedication',
        'generalAnxietyTreatment',
      ],
      (
        generalAnxietyDosageOften,
        generalAnxietyConditionMedication,
        generalAnxietyTreatment
      ) => {
        if (
          (generalAnxietyTreatment.includes('medication') &&
            generalAnxietyDosageOften !== '') ||
          generalAnxietyConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    generalAnxietyWorkRestriction: Yup.string().when(
      'generalAnxietyDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    generalAnxietyLifeRestriction: Yup.string().when(
      [
        'generalAnxietyWorkRestriction',
        'generalAnxietyDutiesRestrictionCondition',
        'generalAnxietyTreatment',
      ],
      (
        generalAnxietyWorkRestriction,
        generalAnxietyDutiesRestrictionCondition,
        generalAnxietyTreatment
      ) => {
        if (
          (generalAnxietyTreatment.includes('medication') &&
            generalAnxietyWorkRestriction !== '') ||
          generalAnxietyDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    generalAnxietyDetails: Yup.string().when('generalAnxietyLifeRestriction', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    eatingDisorderDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    eatingDisorderStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    eatingDisorderTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    eatingDisorderReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string().required('This is required!');
    }),
    eatingDisorderFactors: Yup.string().when('eatingDisorderReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    eatingDisorderThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string().required('This is required!');
    }),
    eatingDisorderWhatTriggered: Yup.string().when('eatingDisorderThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    eatingDisorderSymptomsCommenced: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('eatingDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    eatingDisorderReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('eatingDisorder'))
          return Yup.string().required('This is required!');
      }
    ),
    eatingDisorderHowManyReoccurrences: Yup.string().when(
      'eatingDisorderReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    eatingDisorderCounsellingWhenStart: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderCounsellingEndDate: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderCounsellingTherapyDetails: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderPsychotherapyWhenStart: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderPsychotherapyEndDate: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderPsychotherapyTherapyDetails: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderMedicationDose: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderMedicationEndDate: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    eatingDisorderConditionMedication: Yup.string().when(
      'eatingDisorderTreatment',
      (eatingDisorderTreatment) => {
        if (eatingDisorderTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    eatingDisorderDosageOften: Yup.string().when(
      'eatingDisorderConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    eatingDisorderDutiesRestrictionCondition: Yup.string().when(
      [
        'eatingDisorderDosageOften',
        'eatingDisorderConditionMedication',
        'eatingDisorderTreatment',
      ],
      (
        eatingDisorderDosageOften,
        eatingDisorderConditionMedication,
        eatingDisorderTreatment
      ) => {
        if (
          (eatingDisorderTreatment.includes('medication') &&
            eatingDisorderDosageOften !== '') ||
          eatingDisorderConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    eatingDisorderWorkRestriction: Yup.string().when(
      'eatingDisorderDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    eatingDisorderLifeRestriction: Yup.string().when(
      [
        'eatingDisorderWorkRestriction',
        'eatingDisorderDutiesRestrictionCondition',
        'eatingDisorderTreatment',
      ],
      (
        eatingDisorderWorkRestriction,
        eatingDisorderDutiesRestrictionCondition,
        eatingDisorderTreatment
      ) => {
        if (
          (eatingDisorderTreatment.includes('medication') &&
            eatingDisorderWorkRestriction !== '') ||
          eatingDisorderDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    eatingDisorderDetails: Yup.string().when('eatingDisorderLifeRestriction', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressionDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressionStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressionTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressionReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string().required('This is required!');
    }),
    depressionFactors: Yup.string().when('depressionReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressionThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string().required('This is required!');
    }),
    depressionWhatTriggered: Yup.string().when('depressionThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressionSymptomsCommenced: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depression'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressionReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('depression'))
          return Yup.string().required('This is required!');
      }
    ),
    depressionHowManyReoccurrences: Yup.string().when(
      'depressionReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressionCounsellingWhenStart: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionCounsellingEndDate: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionCounsellingTherapyDetails: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionPsychotherapyWhenStart: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionPsychotherapyEndDate: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionPsychotherapyTherapyDetails: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionMedicationDose: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionMedicationEndDate: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressionConditionMedication: Yup.string().when(
      'depressionTreatment',
      (depressionTreatment) => {
        if (depressionTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    depressionDosageOften: Yup.string().when('depressionConditionMedication', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressionDutiesRestrictionCondition: Yup.string().when(
      [
        'depressionDosageOften',
        'depressionConditionMedication',
        'depressionTreatment',
      ],
      (
        depressionDosageOften,
        depressionConditionMedication,
        depressionTreatment
      ) => {
        if (
          (depressionTreatment.includes('medication') &&
            depressionDosageOften !== '') ||
          depressionConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    depressionWorkRestriction: Yup.string().when(
      'depressionDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressionLifeRestriction: Yup.string().when(
      [
        'depressionWorkRestriction',
        'depressionDutiesRestrictionCondition',
        'depressionTreatment',
      ],
      (
        depressionWorkRestriction,
        depressionDutiesRestrictionCondition,
        depressionTreatment
      ) => {
        if (
          (depressionTreatment.includes('medication') &&
            depressionWorkRestriction !== '') ||
          depressionDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    depressionDetails: Yup.string().when('depressionLifeRestriction', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressiveIllnessDescribeSymptoms: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('depressiveIllness'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depressiveIllness'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressiveIllnessTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depressiveIllness'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    depressiveIllnessReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depressiveIllness'))
        return Yup.string().required('This is required!');
    }),
    depressiveIllnessFactors: Yup.string().when('depressiveIllnessReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    depressiveIllnessThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('depressiveIllness'))
        return Yup.string().required('This is required!');
    }),
    depressiveIllnessWhatTriggered: Yup.string().when(
      'depressiveIllnessThoughts',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressiveIllnessSymptomsCommenced: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('depressiveIllness'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('depressiveIllness'))
          return Yup.string().required('This is required!');
      }
    ),
    depressiveIllnessHowManyReoccurrences: Yup.string().when(
      'depressiveIllnessReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressiveIllnessCounsellingWhenStart: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessCounsellingEndDate: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessCounsellingTherapyDetails: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessPsychotherapyWhenStart: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessPsychotherapyEndDate: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessPsychotherapyTherapyDetails: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessMedicationDose: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessMedicationEndDate: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    depressiveIllnessConditionMedication: Yup.string().when(
      'depressiveIllnessTreatment',
      (depressiveIllnessTreatment) => {
        if (depressiveIllnessTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    depressiveIllnessDosageOften: Yup.string().when(
      'depressiveIllnessConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressiveIllnessDutiesRestrictionCondition: Yup.string().when(
      [
        'depressiveIllnessDosageOften',
        'depressiveIllnessConditionMedication',
        'depressiveIllnessTreatment',
      ],
      (
        depressiveIllnessDosageOften,
        depressiveIllnessConditionMedication,
        depressiveIllnessTreatment
      ) => {
        if (
          (depressiveIllnessTreatment.includes('medication') &&
            depressiveIllnessDosageOften !== '') ||
          depressiveIllnessConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    depressiveIllnessWorkRestriction: Yup.string().when(
      'depressiveIllnessDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    depressiveIllnessLifeRestriction: Yup.string().when(
      [
        'depressiveIllnessWorkRestriction',
        'depressiveIllnessDutiesRestrictionCondition',
        'depressiveIllnessTreatment',
      ],
      (
        depressiveIllnessWorkRestriction,
        depressiveIllnessDutiesRestrictionCondition,
        depressiveIllnessTreatment
      ) => {
        if (
          (depressiveIllnessTreatment.includes('medication') &&
            depressiveIllnessWorkRestriction !== '') ||
          depressiveIllnessDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    depressiveIllnessDetails: Yup.string().when(
      'depressiveIllnessLifeRestriction',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    addictionDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('addiction'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    addictionStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('addiction'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    addictionTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('addiction'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    addictionReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('addiction'))
        return Yup.string().required('This is required!');
    }),
    addictionFactors: Yup.string().when('addictionReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    addictionThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('addiction'))
        return Yup.string().required('This is required!');
    }),
    addictionWhatTriggered: Yup.string().when('addictionThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    addictionSymptomsCommenced: Yup.string().when('addictionThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    addictionReoccurrencesOfCondition: Yup.string().when('addictionThoughts', {
      is: 'true',
      then: Yup.string()
        //.max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
     addictionHowManyReoccurrences: Yup.string().when(
      'addictionReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    addictionCounsellingWhenStart: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionCounsellingEndDate: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionCounsellingTherapyDetails: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionPsychotherapyWhenStart: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionPsychotherapyEndDate: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionPsychotherapyTherapyDetails: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionMedicationDose: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionMedicationEndDate: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    addictionConditionMedication: Yup.string().when(
      'addictionTreatment',
      (addictionTreatment) => {
        if (addictionTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    addictionDosageOften: Yup.string().when('addictionConditionMedication', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    addictionDutiesRestrictionCondition: Yup.string().when(
      [
        'addictionDosageOften',
        'addictionConditionMedication',
        'addictionTreatment',
      ],
      (
        addictionDosageOften,
        addictionConditionMedication,
        addictionTreatment
      ) => {
        if (
          (addictionTreatment.includes('medication') &&
            addictionDosageOften !== '') ||
          addictionConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    addictionWorkRestriction: Yup.string().when(
      'addictionDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    addictionLifeRestriction: Yup.string().when(
      [
        'addictionWorkRestriction',
        'addictionDutiesRestrictionCondition',
        'addictionTreatment',
      ],
      (
        addictionWorkRestriction,
        addictionDutiesRestrictionCondition,
        addictionTreatment
      ) => {
        if (
          (addictionTreatment.includes('medication') &&
            addictionWorkRestriction !== '') ||
          addictionDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    addictionDetails: Yup.string().when('addictionLifeRestriction', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    traumaticStressDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    traumaticStressStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    traumaticStressTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    traumaticStressReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string().required('This is required!');
    }),
    traumaticStressFactors: Yup.string().when('traumaticStressReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    traumaticStressThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string().required('This is required!');
    }),
    traumaticStressWhatTriggered: Yup.string().when('traumaticStressThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    traumaticStressSymptomsCommenced: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('traumaticStress'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    traumaticStressReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('traumaticStress'))
          return Yup.string().required('This is required!');
      }
    ),
    traumaticStressHowManyReoccurrences: Yup.string().when(
      'traumaticStressReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    traumaticStressCounsellingWhenStart: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressCounsellingEndDate: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressCounsellingTherapyDetails: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressPsychotherapyWhenStart: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressPsychotherapyEndDate: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressPsychotherapyTherapyDetails: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressMedicationDose: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressMedicationEndDate: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    traumaticStressConditionMedication: Yup.string().when(
      'traumaticStressTreatment',
      (traumaticStressTreatment) => {
        if (traumaticStressTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    traumaticStressDosageOften: Yup.string().when(
      'traumaticStressConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    traumaticStressDutiesRestrictionCondition: Yup.string().when(
      [
        'traumaticStressDosageOften',
        'traumaticStressConditionMedication',
        'traumaticStressTreatment',
      ],
      (
        traumaticStressDosageOften,
        traumaticStressConditionMedication,
        traumaticStressTreatment
      ) => {
        if (
          (traumaticStressTreatment.includes('medication') &&
            traumaticStressDosageOften !== '') ||
          traumaticStressConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    traumaticStressWorkRestriction: Yup.string().when(
      'traumaticStressDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    traumaticStressLifeRestriction: Yup.string().when(
      [
        'traumaticStressWorkRestriction',
        'traumaticStressDutiesRestrictionCondition',
        'traumaticStressTreatment',
      ],
      (
        traumaticStressWorkRestriction,
        traumaticStressDutiesRestrictionCondition,
        traumaticStressTreatment
      ) => {
        if (
          (traumaticStressTreatment.includes('medication') &&
            traumaticStressWorkRestriction !== '') ||
          traumaticStressDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    traumaticStressDetails: Yup.string().when(
      'traumaticStressLifeRestriction',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    psychoticDisorderDescribeSymptoms: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('psychoticDisorder'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('psychoticDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    psychoticDisorderTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('psychoticDisorder'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    psychoticDisorderReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('psychoticDisorder'))
        return Yup.string().required('This is required!');
    }),
    psychoticDisorderFactors: Yup.string().when('psychoticDisorderReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    psychoticDisorderThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('psychoticDisorder'))
        return Yup.string().required('This is required!');
    }),
    psychoticDisorderWhatTriggered: Yup.string().when(
      'psychoticDisorderThoughts',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    psychoticDisorderSymptomsCommenced: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('psychoticDisorder'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('psychoticDisorder'))
          return Yup.string().required('This is required!');
      }
    ),
    psychoticDisorderHowManyReoccurrences: Yup.string().when(
      'psychoticDisorderReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    psychoticDisorderCounsellingWhenStart: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderCounsellingEndDate: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderCounsellingTherapyDetails: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderPsychotherapyWhenStart: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderPsychotherapyEndDate: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderPsychotherapyTherapyDetails: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderMedicationDose: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderMedicationEndDate: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    psychoticDisorderConditionMedication: Yup.string().when(
      'psychoticDisorderTreatment',
      (psychoticDisorderTreatment) => {
        if (psychoticDisorderTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    psychoticDisorderDosageOften: Yup.string().when(
      'psychoticDisorderConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    psychoticDisorderDutiesRestrictionCondition: Yup.string().when(
      [
        'psychoticDisorderDosageOften',
        'psychoticDisorderConditionMedication',
        'psychoticDisorderTreatment',
      ],
      (
        psychoticDisorderDosageOften,
        psychoticDisorderConditionMedication,
        psychoticDisorderTreatment
      ) => {
        if (
          (psychoticDisorderTreatment.includes('medication') &&
            psychoticDisorderDosageOften !== '') ||
          psychoticDisorderConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    psychoticDisorderWorkRestriction: Yup.string().when(
      'psychoticDisorderDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    psychoticDisorderLifeRestriction: Yup.string().when(
      [
        'psychoticDisorderWorkRestriction',
        'psychoticDisorderDutiesRestrictionCondition',
        'psychoticDisorderTreatment',
      ],
      (
        psychoticDisorderWorkRestriction,
        psychoticDisorderDutiesRestrictionCondition,
        psychoticDisorderTreatment
      ) => {
        if (
          (psychoticDisorderTreatment.includes('medication') &&
            psychoticDisorderWorkRestriction !== '') ||
          psychoticDisorderDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    psychoticDisorderDetails: Yup.string().when(
      'psychoticDisorderLifeRestriction',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    chronicTirednessDescribeSymptoms: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('chronicTiredness'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('chronicTiredness'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    chronicTirednessTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('chronicTiredness'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    chronicTirednessReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('chronicTiredness'))
        return Yup.string().required('This is required!');
    }),
    chronicTirednessFactors: Yup.string().when('chronicTirednessReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    chronicTirednessThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('chronicTiredness'))
        return Yup.string().required('This is required!');
    }),
    chronicTirednessWhatTriggered: Yup.string().when(
      'chronicTirednessThoughts',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    chronicTirednessSymptomsCommenced: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('chronicTiredness'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessReoccurrencesOfCondition: Yup.string().when(
      'anxiety',
      (anxiety) => {
        if (anxiety.includes('chronicTiredness'))
          return Yup.string().required('This is required!');
      }
    ),
    chronicTirednessHowManyReoccurrences: Yup.string().when(
      'chronicTirednessReoccurrencesOfCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    chronicTirednessCounsellingWhenStart: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessCounsellingEndDate: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessCounsellingTherapyDetails: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessPsychotherapyWhenStart: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessPsychotherapyEndDate: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessPsychotherapyTherapyDetails: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessMedicationDose: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessMedicationEndDate: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    chronicTirednessConditionMedication: Yup.string().when(
      'chronicTirednessTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    chronicTirednessDosageOften: Yup.string().when(
      'chronicTirednessConditionMedication',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    chronicTirednessDutiesRestrictionCondition: Yup.string().when(
      [
        'chronicTirednessDosageOften',
        'chronicTirednessConditionMedication',
        'chronicTirednessTreatment',
      ],
      (
        chronicTirednessDosageOften,
        chronicTirednessConditionMedication,
        chronicTirednessTreatment
      ) => {
        if (
          (chronicTirednessTreatment.includes('medication') &&
            chronicTirednessDosageOften !== '') ||
          chronicTirednessConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    chronicTirednessWorkRestriction: Yup.string().when(
      'chronicTirednessDutiesRestrictionCondition',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    chronicTirednessLifeRestriction: Yup.string().when(
      [
        'chronicTirednessWorkRestriction',
        'chronicTirednessDutiesRestrictionCondition',
        'chronicTirednessTreatment',
      ],
      (
        chronicTirednessWorkRestriction,
        chronicTirednessDutiesRestrictionCondition,
        chronicTirednessTreatment
      ) => {
        if (
          (chronicTirednessTreatment.includes('medication') &&
            chronicTirednessWorkRestriction !== '') ||
          chronicTirednessDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    chronicTirednessDetails: Yup.string().when(
      'chronicTirednessLifeRestriction',
      {
        is: 'true',
        then: Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This field is required'),
      }
    ),
    otherDescribeSymptoms: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    otherStartDate: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    otherTimePeriod: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    otherReasons: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string().required('This is required!');
    }),
    otherFactors: Yup.string().when('otherReasons', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    otherThoughts: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string().required('This is required!');
    }),
    otherWhatTriggered: Yup.string().when('otherThoughts', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    otherSymptomsCommenced: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string()
          .max(200, 'Maximum length is 200')
          .required('This is required!');
    }),
    otherReoccurrencesOfCondition: Yup.string().when('anxiety', (anxiety) => {
      if (anxiety.includes('other'))
        return Yup.string().required('This is required!');
    }),
    otherHowManyReoccurrences: Yup.string().when('otherReoccurrencesOfCondition', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    otherCounsellingWhenStart: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherCounsellingEndDate: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherCounsellingTherapyDetails: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('counselling'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherPsychotherapyWhenStart: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherPsychotherapyEndDate: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherPsychotherapyTherapyDetails: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('psychotherapy'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherMedicationDose: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherMedicationEndDate: Yup.string().when(
      'otherTreatment',
      (otherTreatment) => {
        if (otherTreatment.includes('medication'))
          return Yup.string()
            .max(200, 'Maximum length is 200')
            .required('This is required!');
      }
    ),
    otherConditionMedication: Yup.string().when(
      'otherTreatment',
      (chronicTirednessTreatment) => {
        if (chronicTirednessTreatment.includes('medication'))
          return Yup.string().required('This is required!');
      }
    ),
    otherDosageOften: Yup.string().when('otherConditionMedication', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    otherDutiesRestrictionCondition: Yup.string().when(
      ['otherDosageOften', 'otherConditionMedication', 'otherTreatment'],
      (otherDosageOften, otherConditionMedication, otherTreatment) => {
        if (
          (otherTreatment.includes('medication') && otherDosageOften !== '') ||
          otherConditionMedication === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    otherWorkRestriction: Yup.string().when('otherDutiesRestrictionCondition', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),
    otherLifeRestriction: Yup.string().when(
      [
        'otherWorkRestriction',
        'otherDutiesRestrictionCondition',
        'otherTreatment',
      ],
      (
        otherWorkRestriction,
        otherDutiesRestrictionCondition,
        otherTreatment
      ) => {
        if (
          (otherTreatment.includes('medication') &&
            otherWorkRestriction !== '') ||
          otherDutiesRestrictionCondition === 'false'
        )
          return Yup.string().required('This is required!');
      }
    ),
    otherDetails: Yup.string().when('otherLifeRestriction', {
      is: 'true',
      then: Yup.string()
        .max(200, 'Maximum length is 200')
        .required('This field is required'),
    }),

    noneOfTheAbove: Yup.string().when('anxiety', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]
  const submit = async (e) => {
    console.log('e', e);
    try {
      await axios.put('/form/formeight', {
        formEight: e,
        pointer: 10,
        formid: id,
      });
      dispatch(setPage(10));
    } catch (error) {}
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
          }) => {
            return (
              <Form>
                <Card style={{ padding: '2rem' }}>
                  <CardContent>
                    <p className={styles.question}>
                      Have you ever suffered symptoms, received advice or
                      treatment for any of the following
                    </p>
                    <GeneralAnxiety
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <EatingDisorder
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Depression
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <DepressiveIllness
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Addiction
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <TraumaticStress
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <PsychoticDisorder
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <ChronicTiredness
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <Other
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <NoneOfTheAbove
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      initialValues={initialValues}
                      exceptionArray={exceptionArray}
                      setLoading={setLoading}
                      loading={loading}
                      resetForm={resetForm}
                      setErrorsFun={() => setErrors({})}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      variant='contained'
                      className={classes.btn}
                      type='submit'
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: 'Overpass, sans-serif',
                        fontSize: '1rem',
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
