import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";

const resetArray = [
  { name: "multipleSclerosisSymptoms", type: "" },
  { name: "multipleSclerosisSymptomsDetails", type: "" },
];

export default function MultipleSclerosis({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Multiple Sclerosis", value: "multipleSclerosis" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("multipleSclerosis") && (
        <YesNoQuestion
          question="Have you had a definitive diagnosis of Multiple sclerosis or had symptoms that mean a diagnosis may be possible?"
          name="multipleSclerosisSymptoms"
          isSubForm
        />
      )}
      {values.multipleSclerosisSymptoms === "true" && (
        <AnswerBox
          label="Please Provide Details"
          name="multipleSclerosisSymptomsDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
