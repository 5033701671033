import React from 'react';
import { useStyles } from './classes';

import { Grid } from '@material-ui/core';

import Img from '../../assets/images/img13.png';
import Img1 from '../../assets/images/img14.png';
import video from './video.MP4';

const Videopage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems='stretch'
      id='videodemo'
      style={{ marginTop: '2rem' }}
    >
      <Grid xs={12} sm={12} md={3} lg={3} xl={3} item className={classes.hide}>
        <img
          className={classes.img}
          src={Img}
          alt='Adviser adds client details'
        />
      </Grid>
      <Grid md={6} item sm={12} xs={12}>
        <div
          style={{
            textAlign: 'center',
            height: '100%',
            minHeight: '30rem',
            padding: '2rem',
          }}
        >
          <video width='100%' height='100%' controls>
            <source src={video} type='video/mp4'></source>
          </video>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.hide}>
        <img
          className={classes.img}
          src={Img1}
          alt='Adviser adds client details'
        />
      </Grid>
    </Grid>
  );
};

export default Videopage;
