import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./classes";

import Grid from "@material-ui/core/Grid";
import Img from "../../assets/images/image1.png";
import Box from "@material-ui/core/Box";

const Usepage = () => {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Typography className={classes.header}>
            Why should I use PreAssessMe ?
          </Typography>
          <Box className={classes.paragraph}>
            <Typography className={classes.text}>
              Insurance Applications = Uncertainty.
            </Typography>
            <Typography className={classes.text}>
              Pre-existing medical conditions often de-rail an application when
              a client fails to provide us with relevant information.
            </Typography>
            <Typography className={classes.text}>
              To combat wasting your time and theirs – why not have them
              complete a comprehensive medical and pursuits questionnaire that
              can be pre-assessed via a streamlined process.
            </Typography>
            <Typography className={classes.text}>
              The client interface is intuitive, and attractive – we know your
              clients will enjoy the process – in their own time.
            </Typography>
            <Typography className={classes.text}>
              Pre-empt potential loadings, exclusions or declines BEFORE they
              happen, and before you invest the time and money into preparing
              your compliant advice document.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.hide}
        >
          <Box className={classes.box}>
            <img
              className={classes.img}
              src={Img}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div id="why">
      <Grid container spacing={3} justifycontent="center">
        <FormRow />
      </Grid>
    </div>
  );
};

export default Usepage;
