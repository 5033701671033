import React, { useState } from 'react';
import { Fragment } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
// validation
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import styles from '../../styles/form.module.css';

import FormAnemia from './illnesses/FormAnemia';
import FormHaemochromatosis from './illnesses/FormHaemochromatosis';
import FormHaemophilia from './illnesses/FormHaemophilia';
import FormLeukaemia from './illnesses/FormLeukaemia';
import FormOtherblooddisorder from './illnesses/FormOtherblooddisorder';

import { useDispatch } from 'react-redux';
import { setPage } from '../../store/actions/formActions';

import { useParams } from 'react-router-dom';
import axios from '../../lib/axios';
import { useStyles } from './classes';
import NoneOfTheAbove from './NoneOfTheAbove';

export default function FormNineteen() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    illness: [],

    // Anaemia
    anaemiaCheck: '',
    anaemOtherTreat: '',
    anaemiaDetails: '',

    //Haemochromatosis
    diagHaemochromatosis: '',
    isIronTestNormal: '',
    isVenesection: '',

    //Haemophilia
    diagHaemophilia: '',
    treatHaemophilia: '',

    //Leukaemia
    diagLeukaemia: '',
    treatLeukaemia: '',
    isOngTreatLeukaemia: '',
    detailsLeukaemia: '',

    //Otherblooddisorder
    diagOtherblooddisorder: '',
    treatOtherblooddisorder: '',
    isOngTreatOtherblooddisorder: '',
    detailsOtherblooddisorder: '',

    noneOfTheAbove:false,
  });
  const exceptionArray = {
    // noneOfTheAbove: [],
  };
  const validationSchema = Yup.object().shape({
    // Anaemia
    anaemiaCheck: Yup.string().when('illness', {
      is: (val) => val.includes('anaemia'),
      then: Yup.string().required(),
    }),
    anaemOtherTreat: Yup.string().when('illness', {
      is: (val) => val.includes('anaemia'),
      then: Yup.string().required(),
    }),
    anaemiaDetails: Yup.string().when('anaemOtherTreat', {
      is: 'true',
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    // End of Anaemia

    // Haemochromatosis
    diagHaemochromatosis: Yup.string().when('illness', {
      is: (val) => val.includes('Haemochromatosis'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    isIronTestNormal: Yup.string().when('illness', {
      is: (val) => val.includes('Haemochromatosis'),
      then: Yup.string().required(),
    }),
    isVenesection: Yup.string().when('illness', {
      is: (val) => val.includes('Haemochromatosis'),
      then: Yup.string().required(),
    }),
    // End of Haemochromatosis

    //Haemophilia
    diagHaemophilia: Yup.string().when('illness', {
      is: (val) => val.includes('Haemophilia'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    treatHaemophilia: Yup.string().when('illness', {
      is: (val) => val.includes('Haemophilia'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    // End of Haemophilia

    //Leukaemia
    diagLeukaemia: Yup.string().when('illness', {
      is: (val) => val.includes('Leukaemia'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    treatLeukaemia: Yup.string().when('illness', {
      is: (val) => val.includes('Leukaemia'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    isOngTreatLeukaemia: Yup.string().when('illness', {
      is: (val) => val.includes('Leukaemia'),
      then: Yup.string().required(),
    }),
    detailsLeukaemia: Yup.string().when('isOngTreatLeukaemia', {
      is: 'true',
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    // End of Leukaemia

    //Otherblooddisorder
    diagOtherblooddisorder: Yup.string().when('illness', {
      is: (val) => val.includes('Otherblooddisorder'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    treatOtherblooddisorder: Yup.string().when('illness', {
      is: (val) => val.includes('Otherblooddisorder'),
      then: Yup.string()
        .max(300, 'Maximum length is 300')
        .required('This field is required'),
    }),
    isOngTreatOtherblooddisorder: Yup.string().when('illness', {
      is: (val) => val.includes('Otherblooddisorder'),
      then: Yup.string().required(),
    }),
    detailsOtherblooddisorder: Yup.string().when(
      'isOngTreatOtherblooddisorder',
      {
        is: 'true',
        then: Yup.string()
          .max(300, 'Maximum length is 300')
          .required('This field is required'),
      }
    ),

    // End of Otherblooddisorder

    noneOfTheAbove: Yup.string().when('illness', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]
  const submit = async (e) => {
    console.log('e', e);
    try {
      await axios.put('/form/formnineteen', {
        formNineteen: e,
        pointer: 21,
        formid: id,
      });
      dispatch(setPage(21));
    } catch (error) {}
  };
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setErrors,
          }) => {
            return (
              <Form>
                <Card style={{ padding: '2rem' }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>
                      <FormAnemia
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                      <FormHaemochromatosis
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                      <FormHaemophilia
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                      <FormLeukaemia
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                      <FormOtherblooddisorder
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                      <NoneOfTheAbove
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        exceptionArray={exceptionArray}
                        setLoading={setLoading}
                        loading={loading}
                        resetForm={resetForm}
                        setErrorsFun={() => setErrors({})}
                      />
                      <div
                        style={{
                          backgroundColor: 'rgb(240,240,240)',
                          height: 1,
                          width: '100%',
                        }}
                      ></div>
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant='contained'
                      className={classes.btn}
                      type='submit'
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: 'Overpass, sans-serif',
                        fontSize: '1rem',
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
