import React, { useState } from "react";
import { Fragment } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
// validation
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CheckBox from "./CheckBox";

import FormHighBloodPressure from "./illnesses/FormHighBloodPressure";
import FormChestPains from "./illnesses/FormChestPains";
import FormHighCholesterol from "./illnesses/FormHighCholesterol";
import FormHeartMurmurs from "./illnesses/FormHeartMurmurs";
import FormRheumaticFever from "./illnesses/FormRheumaticFever";
import FormHeartComplaintOrStroke from "./illnesses/FormHeartComplaintOrStroke";
import FormTreatmentFrDiabetes from "./illnesses/FormTreatmentFrDiabetes";
import FormAbnormalBloodSugar from "./illnesses/FormAbnormalBloodSugar";
import FormThyroidDisorder from "./illnesses/FormThyroidDisorder";
import FormGout from "./illnesses/FormGout";
import FormAsthmaVal from "./illnesses/FormAsthmaVal";
import FormChronicLungDisease from "./illnesses/FormChronicLungDisease";
import FormSleepApnoea from "./illnesses/FormSleepApnoea";
import FormPneumonia from "./illnesses/FormPneumonia";
import FormOtherRespiratoryDisorder from "./illnesses/FormOtherRespiratoryDisorder";
import FormIndigestion from "./illnesses/FormIndigestion";
import FormUlcer from "./illnesses/FormUlcer";
import FormHernia from "./illnesses/FormHernia";
import FormAnyBowelDisorder from "./illnesses/FormAnyBowelDisorder";
import { useStyles } from "./classes";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/actions/formActions";
import styles from "../../styles/form.module.css";
import axios from "../../lib/axios";
import NoneOfTheAbove from "./NoneOfTheAbove";

export default function FormSix() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    illness: [],
    //HighBloodPressure
    HighBloodPressure: "",
    firstDiagnosedDate: "",
    bloodPreassureReading: "",
    medicationAndDosage: "",
    heartTreatment: "",
    whenDiagnosedHBP: "",
    HowOftenDoYouFollowUp: "",
    LastBloodPressureReading: "",
    medicationAndDosage2: "",
    listOfSymptoms: [],
    hBPsymtoms: [],

    //ChestPains
    heartCondition: "",
    chestPainCon: "",
    reqHospitalAdmin: "",
    hospitalTime: [],
    chestPainCheck: "",
    chestPainYear: "",
    chestPainDet: "",

    //HighCholesterol
    CholControlActivities: [],
    cholesterolReading: [],

    //HeartMurmurs
    murmur: [],
    ValvularOrHeart: [],
    murmurTreat: "",
    murmurTreatDet: "",
    ongoingTreat: "",
    murmurTreatMonitoringDet: "",

    //FormRheumaticFever
    diagnosedWithRheumatic: "",
    rheumaticTreatment: "",

    //HeartComplaintOrStroke
    WhenDetected: "",
    investigations: "",
    diagnosis: "",
    treatment: "",
    ongoingIssue: "",

    //TreatmentFrDiabetes
    diabetesType: [],
    latestHbA1c: "",
    diabetesTreatment: [],
    diabetesCheckups: [],
    gestationalDiabetes: "",
    furtherDetailsDia: "",

    //AbnormalBloodSugar
    aBDiagnosis: "",
    completedTests: "",
    resultCheck: "",
    furtherDetailsSug: "",

    //ThyroidDisorder
    thyroidCondition: [],
    thyroidSymptoms: "",
    thyroidTreatments: "",
    thyroidTreatStatus: "",
    thyroidTreatDetails: "",

    //Gout
    goutDiagnose: [],
    effectedJoints: [],
    goutAttack: [],

    //Asthma
    therapyCheck: "",
    therapyTypes: [],
    howLongHospital: "",
    treatmentTime: "",
    asthmaAttacks: [],

    //ChronicLungDisease
    chronicLungDiagnosis: "",
    howLongCondition: "",
    requiredTreatment: "",

    //SleepApnoea
    whenSleepApnoea: "",
    isConfirmedDiagnosis: "",
    typeOfSleepApnoea: [],
    sleepApnoeaClassified: [],
    useCPAP: "",

    //Pneumonia
    isFullyRecovered: "",
    pneumoniaEpisodes: "",

    //OtherRespiratoryDisorder
    isChestDiagnosed: "",
    isAntibioticsLimited: "",
    isReqHospital: "",
    conditionDiagTreatment: "",
    is3AttPerYr: "",

    //Indigestion
    indigestionDiagnosis: [],
    treatmentAndOutcomeInd: "",
    isWaiting: "",
    comPleatedTests: "",

    //Ulcer
    hadUlcer: "",
    treatmentAndOutcomeUl: "",
    whenTreated: [],
    isSymptomsGone: "",
    symtomsStillThere: "",

    //Hernia
    typeOfHernia: [],
    tratmentAndOutcome: "",

    //AnyBowelDisorder
    selectConditions: [],
    isBloodPassed: "",
    tretAndOutcome: "",
    howLongLastBleeding: [],
    doneColonoscopy: "",
    colonoscopyDetails: "",
    hasIBSinvestigated: "",
    IBStreatment: "",
    diagnosedInYear: "",
    coeliacDiseaseTreat: "",
    howManageCoeliacDisease: [],
    currentlySymptomFree: "",
    whatMeditationCoeliacDisease: "",

    // non of the above
    noneOfTheAbove: false,
  });

  const exceptionArray = {
    // noneOfTheAbove: [],
  };

  const validationSchema = Yup.object().shape({
    //illness: Yup.string().required(),
    // HighBloodPressure
    firstDiagnosedDate: Yup.string().when("illness", {
      is: (val) => val.includes("HighBloodPressure"),
      then: Yup.string()
        .max(10, "Maximum length is 10")
        .required("This field is required"),
    }),
    bloodPreassureReading: Yup.string().when("illness", {
      is: (val) => val.includes("HighBloodPressure"),
      then: Yup.string()
        .max(10, "Maximum length is 10")
        .required("This field is required"),
    }),
    medicationAndDosage: Yup.string().when("illness", {
      is: (val) => val.includes("HighBloodPressure"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    heartTreatment: Yup.string().when("illness", {
      is: (val) => val.includes("HighBloodPressure"),
      then: Yup.string().required("This field is required"),
    }),
    //p1-done
    whenDiagnosedHBP: Yup.string().when(
      ["illness", "heartTreatment"],
      (illness, heartTreatment) => {
        if (
          illness.includes("HighBloodPressure") &&
          heartTreatment === "false"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    HowOftenDoYouFollowUp: Yup.string().when("heartTreatment", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    LastBloodPressureReading: Yup.string().when("heartTreatment", {
      is: "true",
      then: Yup.string()
        .max(10, "Maximum length is 10")
        .required("This field is required"),
    }),
    medicationAndDosage2: Yup.string().when("heartTreatment", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 10")
        .required("This field is required"),
    }),
    hBPsymtoms: Yup.string().when(
      ["illness", "heartTreatment"],
      (illness, heartTreatment) => {
        if (
          illness.includes("HighBloodPressure") &&
          heartTreatment === "true"
        ) {
          return Yup.string().required("This field is required");
        }
      }
    ),
    // End Of High Blood Preassure

    // ChestPains
    heartCondition: Yup.string().when("illness", {
      is: (val) => val.includes("ChestPains"),
      then: Yup.string().required(),
    }),
    chestPainCon: Yup.string().when(
      ["illness", "heartCondition"],
      (illness, heartCondition) => {
        if (illness.includes("ChestPains") && heartCondition === "true") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    reqHospitalAdmin: Yup.string().when(["illness"], (illness) => {
      if (illness.includes("ChestPains")) {
        return Yup.string().required();
      }
    }),
    hospitalTime: Yup.string().when(
      ["illness", "reqHospitalAdmin"],
      (illness, reqHospitalAdmin) => {
        if (illness.includes("ChestPains") && reqHospitalAdmin === "true") {
          return Yup.string().required();
        }
      }
    ),
    chestPainCheck: Yup.string().when(["illness"], (illness) => {
      if (illness.includes("ChestPains")) {
        return Yup.string().required();
      }
    }),
    chestPainYear: Yup.string().when(["illness"], (illness) => {
      if (illness.includes("ChestPains")) {
        return Yup.string().required();
      }
    }),
    chestPainDet: Yup.string().when(
      ["illness", "chestPainYear"],
      (illness, chestPainYear) => {
        if (illness.includes("ChestPains") && chestPainYear === "true") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // end Of ChestPains

    // HighCholesterol
    CholControlActivities: Yup.string().when("illness", {
      is: (val) => val.includes("HighCholesterol"),
      then: Yup.string().required(),
    }),
    cholesterolReading: Yup.string().when("illness", {
      is: (val) => val.includes("HighCholesterol"),
      then: Yup.string().required(),
    }),
    // End Of HighCholesterol

    //HeartMurmurs
    murmur: Yup.string().when("illness", {
      is: (val) => val.includes("HeartMurmurs"),
      then: Yup.string().required(),
    }),
    ValvularOrHeart: Yup.string().when("illness", {
      is: (val) => val.includes("HeartMurmurs"),
      then: Yup.string().required(),
    }),
    murmurTreat: Yup.string().when("illness", {
      is: (val) => val.includes("HeartMurmurs"),
      then: Yup.string().required(),
    }),
    murmurTreatDet: Yup.string().when(
      ["illness", "murmurTreat"],
      (illness, murmurTreat) => {
        if (illness.includes("HeartMurmurs") && murmurTreat === "true") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    ongoingTreat: Yup.string().when("illness", {
      is: (val) => val.includes("HeartMurmurs"),
      then: Yup.string().required(),
    }),
    murmurTreatMonitoringDet: Yup.string().when(
      ["illness", "ongoingTreat"],
      (illness, ongoingTreat) => {
        if (illness.includes("HeartMurmurs") && ongoingTreat === "true") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of HeartMurmurs

    //RheumaticFever
    diagnosedWithRheumatic: Yup.string().when("illness", {
      is: (val) => val.includes("RheumaticFever"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    rheumaticTreatment: Yup.string().when("illness", {
      is: (val) => val.includes("RheumaticFever"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    // End Of RheumaticFever

    //HeartComplaintOrStroke
    WhenDetected: Yup.string().when("illness", {
      is: (val) => val.includes("HeartComplaintOrStroke"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    investigations: Yup.string().when("illness", {
      is: (val) => val.includes("HeartComplaintOrStroke"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    diagnosis: Yup.string().when("illness", {
      is: (val) => val.includes("HeartComplaintOrStroke"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatment: Yup.string().when("illness", {
      is: (val) => val.includes("HeartComplaintOrStroke"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    ongoingIssue: Yup.string().when("illness", {
      is: (val) => val.includes("HeartComplaintOrStroke"),
      then: Yup.string().required(),
    }),
    // End Of HeartComplaintOrStroke

    //TreatmentFrDiabetes
    diabetesType: Yup.string().when("illness", {
      is: (val) => val.includes("TreatmentFrDiabetes"),
      then: Yup.string().required(),
    }),
    latestHbA1c: Yup.string().when("illness", {
      is: (val) => val.includes("TreatmentFrDiabetes"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    diabetesTreatment: Yup.string().when("illness", {
      is: (val) => val.includes("TreatmentFrDiabetes"),
      then: Yup.string().required(),
    }),
    diabetesCheckups: Yup.string().when("illness", {
      is: (val) => val.includes("TreatmentFrDiabetes"),
      then: Yup.string().required(),
    }),
    gestationalDiabetes: Yup.string().when("illness", {
      is: (val) => val.includes("TreatmentFrDiabetes"),
      then: Yup.string().required(),
    }),
    furtherDetailsDia: Yup.string().when(
      ["illness", "gestationalDiabetes"],
      (illness, gestationalDiabetes) => {
        if (
          illness.includes("TreatmentFrDiabetes") &&
          gestationalDiabetes !== "true"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of TreatmentFrDiabetes

    //AbnormalBloodSugar
    aBDiagnosis: Yup.string().when("illness", {
      is: (val) => val.includes("AbnormalBloodSugar"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    completedTests: Yup.string().when("illness", {
      is: (val) => val.includes("AbnormalBloodSugar"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    resultCheck: Yup.string().when("illness", {
      is: (val) => val.includes("AbnormalBloodSugar"),
      then: Yup.string().required(),
    }),
    furtherDetailsSug: Yup.string().when(
      ["illness", "resultCheck"],
      (illness, resultCheck) => {
        if (illness.includes("AbnormalBloodSugar") && resultCheck === "false") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of AbnormalBloodSugar

    //ThyroidDisorder
    thyroidCondition: Yup.string().when("illness", {
      is: (val) => val.includes("ThyroidDisorder"),
      then: Yup.string().required(),
    }),
    thyroidSymptoms: Yup.string().when("illness", {
      is: (val) => val.includes("ThyroidDisorder"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    thyroidTreatments: Yup.string().when("illness", {
      is: (val) => val.includes("ThyroidDisorder"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    thyroidTreatStatus: Yup.string().when("illness", {
      is: (val) => val.includes("ThyroidDisorder"),
      then: Yup.string().required(),
    }),
    thyroidTreatDetails: Yup.string().when(
      ["illness", "thyroidTreatStatus"],
      (illness, thyroidTreatStatus) => {
        if (
          illness.includes("ThyroidDisorder") &&
          thyroidTreatStatus === "true"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of ThyroidDisorder

    //Gout
    goutDiagnose: Yup.string().when("illness", {
      is: (val) => val.includes("Gout"),
      then: Yup.string().required(),
    }),
    effectedJoints: Yup.string().when("illness", {
      is: (val) => val.includes("Gout"),
      then: Yup.string().required(),
    }),
    goutAttack: Yup.string().when("illness", {
      is: (val) => val.includes("Gout"),
      then: Yup.string().required(),
    }),
    // End Of Gout

    //Asthma
    therapyCheck: Yup.string().when("illness", {
      is: (val) => val.includes("AsthmaVal"),
      then: Yup.string().required(),
    }),
    therapyTypes: Yup.string().when("therapyCheck", {
      is: "true",
      then: Yup.string().required(),
    }),
    howLongHospital: Yup.string().when("therapyTypes", {
      is: (val) => val.includes("Hospitalisation"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatmentTime: Yup.string().when("therapyTypes", {
      is: (val) =>
        val.includes("Nebuliser") ||
        val.includes("Steriod therapy tablet or syrup"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    asthmaAttacks: Yup.string().when("illness", {
      is: (val) => val.includes("AsthmaVal"),
      then: Yup.string().required(),
    }),
    // End Of Asthma

    //ChronicLungDisease
    chronicLungDiagnosis: Yup.string().when("illness", {
      is: (val) => val.includes("ChronicLungDisease"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    howLongCondition: Yup.string().when("illness", {
      is: (val) => val.includes("ChronicLungDisease"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    requiredTreatment: Yup.string().when("illness", {
      is: (val) => val.includes("ChronicLungDisease"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    // End Of ChronicLungDisease

    //SleepApnoea
    whenSleepApnoea: Yup.string().when("illness", {
      is: (val) => val.includes("SleepApnoea"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isConfirmedDiagnosis: Yup.string().when("illness", {
      is: (val) => val.includes("SleepApnoea"),
      then: Yup.string().required(),
    }),
    typeOfSleepApnoea: Yup.string().when("illness", {
      is: (val) => val.includes("SleepApnoea"),
      then: Yup.string().required(),
    }),
    sleepApnoeaClassified: Yup.string().when("illness", {
      is: (val) => val.includes("SleepApnoea"),
      then: Yup.string().required(),
    }),
    useCPAP: Yup.string().when("illness", {
      is: (val) => val.includes("SleepApnoea"),
      then: Yup.string().required(),
    }),
    // End Of SleepApnoea
    ///-----------------------------------------------------------------------------------------------------------
    //Pneumonia
    isFullyRecovered: Yup.string().when("illness", {
      is: (val) => val.includes("Pneumonia"),
      then: Yup.string().required(),
    }),
    pneumoniaEpisodes: Yup.string().when("illness", {
      is: (val) => val.includes("Pneumonia"),
      then: Yup.string().required(),
    }),
    // End Of Pneumonia

    //OtherRespiratoryDisorder
    isChestDiagnosed: Yup.string().when("illness", {
      is: (val) => val.includes("OtherRespiratoryDisorder"),
      then: Yup.string().required(),
    }),
    isAntibioticsLimited: Yup.string().when("illness", {
      is: (val) => val.includes("OtherRespiratoryDisorder"),
      then: Yup.string().required(),
    }),
    isReqHospital: Yup.string().when("illness", {
      is: (val) => val.includes("OtherRespiratoryDisorder"),
      then: Yup.string().required(),
    }),
    conditionDiagTreatment: Yup.string().when(
      ["illness", "isReqHospital"],
      (illness, isReqHospital) => {
        if (
          illness.includes("OtherRespiratoryDisorder") &&
          isReqHospital === "true"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    is3AttPerYr: Yup.string().when("illness", {
      is: (val) => val.includes("OtherRespiratoryDisorder"),
      then: Yup.string().required(),
    }),
    // End Of OtherRespiratoryDisorder

    //Indigestion
    indigestionDiagnosis: Yup.string().when("illness", {
      is: (val) => val.includes("Indigestion"),
      then: Yup.string().required(),
    }),
    treatmentAndOutcomeInd: Yup.string().when("indigestionDiagnosis", {
      is: (val) =>
        (val.includes("BarrettsOesophagus") ||
          val.includes("oesophagealCancer") ||
          val.includes("AesophagealStricture")) &&
        !val.includes("NoneOfTheAbove"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    isWaiting: Yup.string().when("indigestionDiagnosis", {
      is: (val) =>
        (val.includes("BarrettsOesophagus") ||
          val.includes("oesophagealCancer") ||
          val.includes("AesophagealStricture")) &&
        !val.includes("NoneOfTheAbove"),
      then: Yup.string().required(),
    }),
    comPleatedTests: Yup.string().when(
      ["illness", "isWaiting"],
      (illness, isWaiting) => {
        if (illness.includes("Indigestion") && isWaiting === "true") {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of Indigestion

    //Ulcer
    hadUlcer: Yup.string().when("illness", {
      is: (val) =>
        val.includes("GastricUlcer") || val.includes("DuodenalUlcer"),
      then: Yup.string().required(),
    }),
    treatmentAndOutcomeUl: Yup.string().when(
      ["illness", "hadUlcer"],
      (illness, hadUlcer) => {
        if (
          (illness.includes("GastricUlcer") ||
            illness.includes("DuodenalUlcer")) &&
          hadUlcer === "true"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    whenTreated: Yup.string().when("illness", {
      is: (val) =>
        val.includes("GastricUlcer") || val.includes("DuodenalUlcer"),
      then: Yup.string().required(),
    }),
    isSymptomsGone: Yup.string().when("illness", {
      is: (val) =>
        val.includes("GastricUlcer") || val.includes("DuodenalUlcer"),
      then: Yup.string().required(),
    }),

    symtomsStillThere: Yup.string().when(
      ["illness", "isSymptomsGone"],
      (illness, isSymptomsGone) => {
        if (
          (illness.includes("GastricUlcer") ||
            illness.includes("DuodenalUlcer")) &&
          isSymptomsGone === "false"
        ) {
          return Yup.string()
            .max(300, "Maximum length is 300")
            .required("This field is required");
        }
      }
    ),
    // End Of Ulcer

    //Hernia
    typeOfHernia: Yup.string().when("illness", {
      is: (val) => val.includes("Hernia"),
      then: Yup.string().required(),
    }),
    tratmentAndOutcome: Yup.string().when('illness', {
      is: (val) => val.includes('Hernia'),
      then: Yup.string().required("Treatment and Outcome is a required field."),
    }),
    // End Of Hernia

    //AnyBowelDisorder
    selectConditions: Yup.string().when("illness", {
      is: (val) => val.includes("AnyBowelDisorder"),
      then: Yup.string().required(),
    }),
    isBloodPassed: Yup.string().when("selectConditions", {
      is: (val) => val.includes("Diarrhoea"),
      then: Yup.string().required(),
    }),
    tretAndOutcome: Yup.string().when("isBloodPassed", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),

    howLongLastBleeding: Yup.string().when("selectConditions", {
      is: (val) => val.includes("Diarrhoea"),
      then: Yup.string().required(),
    }),
    doneColonoscopy: Yup.string().when("selectConditions", {
      is: (val) => val.includes("Diarrhoea"),
      then: Yup.string().required(),
    }),
    colonoscopyDetails: Yup.string().when("doneColonoscopy", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    hasIBSinvestigated: Yup.string().when("selectConditions", {
      is: (val) => val.includes("IrritableBowelSyndrome"),
      then: Yup.string().required(),
    }),
    IBStreatment: Yup.string().when("hasIBSinvestigated", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    diagnosedInYear: Yup.string().when("selectConditions", {
      is: (val) => val.includes("CoeliacDisease"),
      then: Yup.string().required(),
    }),
    coeliacDiseaseTreat: Yup.string().when("diagnosedInYear", {
      is: "true",
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    howManageCoeliacDisease: Yup.string().when("selectConditions", {
      is: (val) => val.includes("CoeliacDisease"),
      then: Yup.string().required(),
    }),
    currentlySymptomFree: Yup.string().when("howManageCoeliacDisease", {
      is: (val) => val.includes("glutenFree") || val.includes("NoneOfTheAbove"),
      then: Yup.string().required(),
    }),
    whatMeditationCoeliacDisease: Yup.string().when("howManageCoeliacDisease", {
      is: (val) =>
        val.includes("medication") || val.includes("GlutenFreeAndMedication"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    whnDiagCrohnColitis: Yup.string().when("selectConditions", {
      is: (val) =>
        val.includes("CrohnsDisease") ||
        val.includes("ColitisincludingUlcerativeColitis"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    whnAttCrohnColitis: Yup.string().when("selectConditions", {
      is: (val) =>
        val.includes("CrohnsDisease") ||
        val.includes("ColitisincludingUlcerativeColitis"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    treatmentCrohnColitis: Yup.string().when("selectConditions", {
      is: (val) =>
        val.includes("CrohnsDisease") ||
        val.includes("ColitisincludingUlcerativeColitis"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    otherTreatAndOutcome: Yup.string().when("selectConditions", {
      is: (val) => val.includes("Other"),
      then: Yup.string()
        .max(300, "Maximum length is 300")
        .required("This field is required"),
    }),
    // End Of AnyBowelDisorder

    noneOfTheAbove: Yup.string().when('illness', (e) => {
      if (e.length === 0) {
        return Yup.boolean()
        .required('This field is required')
        .oneOf([true], 'This field is required');
      }
    }),

  });
  // FORM [2,2]
  const submit = async (e) => {
    console.log("e", e);
    try {
      await axios.put("/form/formsix", {
        formSix: e,
        pointer: 7,
        formid: id,
      });
      dispatch(setPage(7));
    } catch (error) { }
  };

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue, setFieldTouched, resetForm, setErrors }) => {
            return (
              <Form>
                <Card style={{ padding: "2rem" }}>
                  <CardContent>
                    <Fragment>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>

                      <FormHighBloodPressure
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormChestPains
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormHighCholesterol
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormHeartMurmurs
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormRheumaticFever
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormHeartComplaintOrStroke
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>
                      <FormTreatmentFrDiabetes
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormAbnormalBloodSugar
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormThyroidDisorder
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormGout
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <p className={styles.question}>
                        Have you ever suffered symptoms, received advice or
                        treatment for any of the following
                      </p>
                      <FormAsthmaVal
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <CheckBox
                        list={[
                          {
                            label: "Childhood asthma",
                            value: "Childhoodasthma",
                          },
                        ]}
                        name="illness"
                        question=""
                        onClick={(e) => {
                          if (e.target.checked) {
                            setFieldValue("noneOfTheAbove", false, false);
                            setFieldTouched("noneOfTheAbove", false);
                          }
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormChronicLungDisease
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormSleepApnoea
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormPneumonia
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormOtherRespiratoryDisorder
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormIndigestion
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormUlcer
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormHernia
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <FormAnyBowelDisorder
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <div
                        style={{
                          backgroundColor: "rgb(240,240,240)",
                          height: 1,
                          width: "100%",
                        }}
                      ></div>
                      <div
                        style={{
                          paddingTop: "1rem"
                        }}
                      ></div>
                      <NoneOfTheAbove
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValues={initialValues}
                        exceptionArray={exceptionArray}
                        setLoading={setLoading}
                        loading={loading}
                        resetForm={resetForm}
                        setErrorsFun={() => setErrors({})}
                      />
                    </Fragment>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      type="submit"
                      disabled={!isValid || !dirty}
                      style={{
                        marginLeft: 5,
                        fontFamily: "Overpass, sans-serif",
                        fontSize: "1rem",
                      }}
                    >
                      next
                    </Button>
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
