import React from "react";
import { Fragment } from "react";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import CheckBox from "../CheckBox";

const resetArray = [
  { name: "indigestionDiagnosis", type: [] },
  { name: "treatmentAndOutcomeInd", type: "" },
  { name: "isWaiting", type: "" },
  { name: "comPleatedTests", type: "" },
];

export default function FormIndigestion({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };

  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Indigestion", value: "Indigestion" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Indigestion") && (
        <Fragment>
          <CheckBox
            question="Have you been diagnosed with any of the following?"
            name="indigestionDiagnosis"
            list={[
              { label: "Barretts oesophagus", value: "BarrettsOesophagus" },
              { label: "Oesophageal cancer", value: "oesophagealCancer" },
              { label: "Aesophageal stricture", value: "AesophagealStricture" },
              { label: "None of the above", value: "NoneOfTheAbove" },
            ]}
            onClick={(e) => {
              if (e.target.value === "NoneOfTheAbove") {
                setFieldValue("treatmentAndOutcomeInd", "", false);
                setFieldTouched("treatmentAndOutcomeInd", false);
                setFieldValue("isWaiting", "", false);
                setFieldTouched("isWaiting", false);
                setFieldValue("comPleatedTests", "", false);
                setFieldTouched("comPleatedTests", false);
              }
            }}
            isSubForm
          />
          {(values.indigestionDiagnosis.includes("BarrettsOesophagus") ||
            values.indigestionDiagnosis.includes("oesophagealCancer") ||
            values.indigestionDiagnosis.includes("AesophagealStricture")) &&
            !values.indigestionDiagnosis.includes("NoneOfTheAbove") && (
              <Fragment>
                <AnswerBox
                  label="Please provide details on treatment and outcome of treatment including date of the issue"
                  name="treatmentAndOutcomeInd"
                  isSubForm
                />
                <YesNoQuestion
                  question="Are you waiting on any further investigation for digestion issues?"
                  name="isWaiting"
                  isSubForm
                />
                {values.isWaiting === "true" && (
                  <AnswerBox
                    label="Please provide details of what tests need to be completed?"
                    name="comPleatedTests"
                    isSubForm
                  />
                )}
              </Fragment>
            )}
        </Fragment>
      )}
    </Fragment>
  );
}
