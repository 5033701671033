import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Grid, Box, Button, Typography, Snackbar } from "@material-ui/core";
import { login } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import "../../styles/login.css";
import { useStyles, CssTextField } from "./classes";

import { useDispatch } from "react-redux";
import { switchFirst } from "../../store/actions/signupAction";
import axios from "../../lib/axios";

// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Img1 from "../../assets/images/image1.png";
import HeaderTop from "../../components/headerTop/HeaderTop";
import Drawer from "../../components/layouts/Drawer";
import Footer from "../../components/Footer/Footer";

// import axios from "../../lib/axios";

let initialValues = {
  email: "",
  name: "",
};

let SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Email is required!")
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      "Please enter valid Email"
    ),
  name: Yup.string()
    .required("User name is required!")
    .max(35, "Max length for the name is 15."),
});

function Signup(props) {
  const classes = useStyles();

  let dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    try {
      await axios.post("/auth/checkemail", {
        email: e.email,
      });

      dispatch(switchFirst(e));
      const path = "/details";
      props.history.push(path);
    } catch (error) {
      if (error.response.status === 403) {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "This Email is already taken!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "This Email is already taken!",
          });
          setOpen(false);
        }, 3000);
      } else {
        setOpen(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Server error!",
          });
          setOpen(false);
        }, 3000);
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          top: 0,
          zIndex: 10,
        }}
      >
        <HeaderTop />
        <Drawer />
      </div>
      {/* <div className={classes.spdiv}></div> */}
      <Grid container>
        <Grid item md={4} lg={4} xl={4} className={classes.hide}>
          <Box className={classes.box}>
            <img
              className={classes.img2}
              src={Img1}
              alt="Adviser adds client details"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={6}
          style={{
            marginTop: 10,
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography className={classes.headerText}>
                Sign Up Now
              </Typography>
            </Box>
            <Box variant="outlined" className={classes.loginbox}>
              <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={SignUpSchema}
              >
                {({ dirty, isValid }) => {
                  return (
                    <Form style={{ padding: "10%" }}>
                      <div className={classes.displayFlex}>
                        <Typography className={classes.text}>NAME</Typography>
                        <Field
                          name="name"
                          component={CssTextField}
                          variant="outlined"
                          fullWidth
                          placeholder="Name *"
                          required
                          inputProps={{
                            style: { fontFamily: "Overpass, sans-serif" },
                          }}
                          style={{ backgroundColor: "white", color: "black" }}
                        ></Field>
                      </div>
                      <div className={classes.displayFlex}>
                        <Typography className={classes.text}>EMAIL</Typography>
                        <Field
                          name="email"
                          component={CssTextField}
                          variant="outlined"
                          placeholder="Email *"
                          required
                          fullWidth
                          inputProps={{
                            style: { fontFamily: "Overpass, sans-serif" },
                          }}
                          style={{ backgroundColor: "white", color: "black" }}
                        ></Field>
                      </div>
                      <Typography className={classes.text1}>
                        MAKE INSURANCE ADVICE SIMPLE
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          className={classes.signupButton}
                          style={{
                            width: "80%",
                            height: 40,
                            fontSize: "1rem",
                            backgroundColor: "#004aad",
                            fontFamily: "Overpass, sans-serif",
                            color: "white",
                          }}
                          disabled={!dirty || !isValid}
                          type="submit"
                        >
                          CREATE ACCOUNT
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>

            <Box className={classes.paragraph}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={3000}
                onClose={() =>
                  setAlert({
                    ...alert,
                    showAlert: false,
                  })
                }
              >
                <Alert
                  onClose={() => setOpen(false)}
                  severity={alert.severity}
                  sx={{ width: "100%" }}
                >
                  {alert.message}
                </Alert>
              </Snackbar>
              <Typography className={classes.paragraph1}>
                By signing up and creating an account, you agree to
              </Typography>
              <Typography className={classes.paragraph1}>
                the PreAssessMe Terms &{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          xl={2}
          className={classes.hide}
          justifycontent="flex-end"
        ></Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default connect(null, { login })(Signup);
