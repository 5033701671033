// Package
import Package from "./pages/package/Package";
import PackageView from "./pages/package/PackageView";
import Occupation from "./pages/occupation/Occupation";
import OccupationView from "./pages/occupation/OccupationView";

// Company
import Company from "./pages/company/Company";
import CompanyView from "./pages/company/CompanyView";

// Underwriter
import Underwriter from "./pages/underwriter/Underwriter";
import UnderwriterView from "./pages/underwriter/UnderwriterView";
import ManageProfile from "./pages/manageProfile/ManageProfile";
import ChangePassword from "./pages/changePassword/ChangePassword";

//Client
import NewAssessment from "./pages/newAssessment/NewAssessment";
import PaymentList from "./pages/paypalPayment/PaymentView";
import PaymentListA from "./pages/advisorPayment/PaymentView";
import UnderwriterResponse from "./pages/underwriterResponse/UnderwriterResponse";

// Adviser
import AdviserList from "./pages/Adviser/AdviserView";
import PackageAdd from "./pages/addPackages/AddPackage";
import Preferences from "./pages/preferences/Preferences";
import Dashboard from "./pages/dashboard/Dashboard";
import AdvisorDashboard from "./pages/advisorDashboard/AdvisorDashboard";
import AssessmentStatus from "./pages/assessmentStatus/AssessmentStatus";
import BuyPackages from "./pages/buyPackages/BuyPackages";

export const routes = [
  {
    name: "ANALYTICS",
    children: [
      {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        role: ["admin"],
      },
    ],
  },
  {
    name: "PROFILE",
    children: [
      {
        name: "Dashboard",
        path: "/advisor-dashboard",
        component: AdvisorDashboard,
        role: ["advisor"],
      },
      {
        name: "Manage Profile",
        path: "/ManageProfile",
        component: ManageProfile,
        role: ["admin", "advisor"],
      },
      {
        name: "Change password",
        path: "/ChangePassword",
        component: ChangePassword,
        role: ["admin", "advisor"],
      },
    ],
  },
  {
    name: "PAYMENT",
    children: [
      {
        name: "View",
        path: "/paymenthistory",
        component: PaymentList,
        role: ["admin"],
      },
      {
        name: "View",
        path: "/history",
        component: PaymentListA,
        role: ["advisor"],
      },
    ],
  },
  {
    name: "ASSIGN",
    children: [
      {
        name: "Assign Package",
        path: "/package-add",
        component: PackageAdd,
        role: ["admin"],
      },
    ],
  },
  {
    name: "PACKAGE",
    children: [
      {
        name: "Create",
        path: "/package-create",
        component: Package,
        role: ["admin"],
      },
      {
        name: "Buy Packages",
        path: "/package-buy",
        component: BuyPackages,
        role: ["advisor"],
      },
      {
        name: "Update",
        path: "/package-update",
        isHidden: true,
        component: Package,
        role: ["admin"],
      },
      {
        name: "View",
        path: "/package",
        component: PackageView,
        role: ["admin"],
      },
    ],
  },

  {
    name: "COMPANY",
    children: [
      {
        name: "Create",
        path: "/company-create",
        component: Company,
        role: ["admin"],
      },

      {
        name: "Update",
        path: "/company-update",
        isHidden: true,
        component: Company,
        role: ["admin"],
      },
      {
        name: "View",
        path: "/company",
        component: CompanyView,
        role: ["admin"],
      },
    ],
  },

  {
    name: "UNDERWRITER",
    children: [
      {
        name: "Create",
        path: "/underwriter-create",
        component: Underwriter,
        role: ["admin", "advisor"],
      },

      {
        name: "Update",
        path: "/underwriter-update",
        isHidden: true,
        component: Underwriter,
        role: ["admin", "advisor"],
      },
      {
        name: "View",
        path: "/underwriter",
        component: UnderwriterView,
        role: ["admin", "advisor"],
      },
      {
        name: "Preferences",
        path: "/preferences",
        component: Preferences,
        role: ["advisor"],
      },
    ],
  },
  {
    name: "OCCUPATION",
    children: [
      {
        name: "Create",
        path: "/occupation-create",
        component: Occupation,
        role: ["admin"],
      },
      {
        name: "Update",
        path: "/occupation-update",
        isHidden: true,
        component: Occupation,
        role: ["admin"],
      },
      {
        name: "View",
        path: "/occupation",
        component: OccupationView,
        role: ["admin"],
      },
    ],
  },
  {
    name: "CLIENT",
    children: [
      {
        name: "Create",
        path: "/client-create",
        component: NewAssessment,
        role: ["advisor"],
      },
      {
        name: "underwriter response",
        path: "/underwriter-response",
        component: UnderwriterResponse,
        isHidden: true,
        role: ["advisor"],
      },
      {
        name: "Review Assessment Status",
        path: "/assessment-status",
        component: AssessmentStatus,
        role: ["advisor"],
      },
    ],
  },
  {
    name: "ADVISER",
    children: [
      {
        name: "View",
        path: "/adviser",
        component: AdviserList,
        role: ["admin"],
      },
    ],
  },
];
