import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";

const resetArray = [
  { name: "sailingTypesParticipate", type: [] },
  { name: "raceSeries", type: "" },
  { name: "payments", type: "" },
  { name: "recordAttempts", type: "" },
  { name: "safetyMeasures", type: "" },
  { name: "accident", type: "" },
  { name: "boatSize", type: "" },
];

export default function Sailing({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e) => {
    if (!e.target.checked) {
      for (let item of resetArray) {
        setFieldValue(item.name, item.type, false);
        setFieldTouched(item.name, false);
      }
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Long Distance Sailing", value: "sailing" }]}
        name="activities"
        question=""
        onClick={(e) => resetFold(e)}
      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.activities.includes("sailing") && (
        <Grid item md={12} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <CheckBox
              question="What type of saling do you participate in?"
              list={[
                {
                  label: "Pleasure (inshore,/offshore)",
                  value: "Pleasure (inshore,/offshore)",
                },
                {
                  label: "Pleasure (trans-ocean)",
                  value: "Pleasure (trans-ocean)",
                },
                {
                  label: "Racing (offshore)",
                  value: "Racing (offshore)",
                },
                {
                  label: "Racing (trans-ocean)",
                  value: "Racing (trans-ocean)",
                },
              ]}
              name="sailingTypesParticipate"
              isSubForm
            />
          </Grid>
        </Grid>
      )}

      {values.activities.includes("sailing") &&
        (values.sailingTypesParticipate.includes("Racing (offshore)") ||
          values.sailingTypesParticipate.includes("Racing (trans-ocean)")) && (
          <AnswerBox
            label="What is the race/race series?"
            name="raceSeries"
            isSubForm
          />
        )}

      {values.activities.includes("sailing") && (
        <div>
          <AnswerBox
            label="Do you receive any payments for your involvement in this activity?"
            name="payments"
            isSubForm
          />
          <AnswerBox
            label="Are you involved in any record
            attempts?"
            name="recordAttempts"
            isSubForm
          />
          <AnswerBox
            label="Are all recognized safety
            measures and precautions
            followed?"
            name="safetyMeasures"
            isSubForm
          />
          <AnswerBox
            label="What type and size of boat do
            you use?"
            name="boatSize"
            isSubForm
          />
          <AnswerBox
            label="Have you ever been involved in an accident whilst sailing long distances? (details)"
            name="accident"
            isSubForm
          />
        </div>
      )}
    </Fragment>
  );
}
