import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import AnswerBox from "../AnswerBox";
import YesNoQuestion from "../YesNoQuestion";
import RadioGroup from "../RadioMulti";

const migraineStart = [
  {
    label: "More than 2 years ago",
    value: "morethantwo",
  },
  {
    label: "Less than 2 years ago",
    value: "lessthantwo",
  },
];

const resetArray = [
  { name: "migraineStart", type: "" },
  { name: "migraineFurtherInvestigation", type: "" },
  { name: "migraineDetails", type: "" },
];

export default function Migraines({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e,arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Migraines", value: "migraines" }]}
        name="symptoms"
        question=""
        onClick={(e) => resetFold(e,resetArray)}

      />
      <div
        style={{
          backgroundColor: "rgb(240,240,240)",
          height: 1,
          width: "100%",
        }}
      ></div>

      {values.symptoms.includes("migraines") && (
        <RadioGroup
          question="When did you first experience Migraines?"
          name="migraineStart"
          items={migraineStart}
          isSubForm
        />
      )}
      {values.migraineStart && (
        <YesNoQuestion
          question="In the last 2 years has your doctor recommended that you have further investigations?"
          name="migraineFurtherInvestigation"
          isSubForm
        />
      )}
      {values.migraineFurtherInvestigation === "true" && (
        <AnswerBox 
          label="Please Provide Details"
          name="migraineDetails"
          isSubForm
        />
      )}
    </Fragment>
  );
}
