import React from "react";
import { Grid, FormControlLabel, Radio, Box } from "@material-ui/core";
import styles from "../../styles/form.module.css";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";
//import { CheckboxWithLabel } from 'formik-material-ui';
export default function RadioMulti({ 
  question,
  name,
  items,
  onClick,
  isSubForm
}) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box className={ isSubForm ? styles.subFormBox : styles.FormBox }>
        <p className={styles.question}>{question}</p>
        <Field component={RadioGroup} name={name} row>
          {items.map((item) => (
            <FormControlLabel
              control={<Radio />}
              onClick={onClick}
              key={item.value}
              label={
                <span
                  style={{
                    fontFamily: "Overpass, sans-serif",
                  }}
                >
                  {item.label}{" "}
                </span>
              }
              value={item.value}
            />
          ))}
        </Field>
      </Box>
    </Grid>
  );
}
