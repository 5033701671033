import React from "react";
import { Fragment } from "react";
import CheckBox from "../CheckBox";
import RadioMulti from "../RadioMulti";

const resetArray = [
  { name: "effectedJoints", type: [] },
  { name: "goutDiagnose", type: [] },
  { name: "goutAttack", type: [] },
];
export default function FormGout({ values, setFieldValue, setFieldTouched }) {
  const resetFold = (e, arr) => {
    if (!e.target.checked) {
      setTimeout( () => {
        for (let item of arr) {
          setFieldValue(item.name, item.type, false);
          setFieldTouched(item.name, false);
          }
        }, 0
      )
    } else {
      setFieldValue("noneOfTheAbove", false, false);
      setFieldTouched("noneOfTheAbove", false);
    }
  };
  return (
    <Fragment>
      <CheckBox
        list={[{ label: "Gout", value: "Gout" }]}
        name="illness"
        question=""
        onClick={(e) => resetFold(e, resetArray)}
      />
      {values.illness.includes("Gout") && (
        <Fragment>
          <RadioMulti
            question="When was this first diagnosed?"
            name={"goutDiagnose"}
            items={[
              {
                label: "More than 12 months ago",
                value: "More than 12 months ago",
              },
              {
                label: "Less than 12 months ago",
                value: "Less than 12 months ago",
              },
            ]}
            isSubForm
          />
          <CheckBox
            question="What joints were affected?"
            name="effectedJoints"
            list={[
              { label: "Toes", value: "toes" },
              { label: "Ankles", value: "ankles" },
              { label: "Elbows", value: "elbows" },
              { label: "Other", value: "other" },
            ]}
            isSubForm
          />
          <RadioMulti
            question="How often do you have an attack?"
            name={"goutAttack"}
            items={[
              {
                label: "One attack and never again",
                value: "One attack and never again",
              },
              {
                label: "Not more than one attack per year",
                value: "Not more than one attack per year",
              },
              { label: "2-3 attacks per year", value: "2-3 attacks per year" },
              {
                label: "More than 3 attacks per year",
                value: "More than 3 attacks per year",
              },
            ]}
            isSubForm
          />
        </Fragment>
      )}
    </Fragment>
  );
}
